import { Component, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { SelectMultiColumnDialogComponent } from '../select-multi-column/select-multi-column-dialog/select-multi-column-dialog.component';
import { FormService } from 'src/app/shared/service/form.service';
import { TresciService } from 'src/app/tresci.service';



@Component({
  selector: 'app-checkselect-multi-column',
  templateUrl: './check-select-multi-column.component.html',
  styleUrls: ['./check-select-multi-column.component.scss']
})


export class CheckSelectMultiColumnComponent implements AfterViewInit, OnDestroy {
  @ViewChild('input') input: ElementRef;

  public TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;
  private subscriptions: Subscription[] = [];

  public EnabledSelect: boolean = false;

  constructor(private formService: FormService, private fb: FormBuilder,public dialog: MatDialog, public tresci: TresciService) { 
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngAfterViewInit(): void {
    if (this.Control.value === '') {  
      this.Group.get(this.Control.name)?.setValue(''); 
      this.Group.get(this.Control.name).clearValidators();
      this.Group.get(this.Control.name).setErrors(null);
      this.Group.get(this.Control.name).updateValueAndValidity();
    } else {
      this.EnabledSelect = true;
    }
    const event = new Event('change');
    this.input.nativeElement.dispatchEvent(event);
  }
  public czyEnabled(){
    return !this.Group.get(this.Control.name)?.enabled;
  }
  openSelectMultiColumnDialog(): void {
    if(this.EnabledSelect){
      if (this.Group.get(this.Control.name)?.enabled) {
        const dialogRef = this.dialog.open(SelectMultiColumnDialogComponent, { minWidth: '80vh', data: { ControlName: this.Control.label, Data: this.Control.optionsSelectMultiColumn } });
        this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.Control.value = result;
            this.input.nativeElement.value = result.DisplayValue;
            const event = new Event('change');
            this.input.nativeElement.dispatchEvent(event);
          }
        }));
      }
    }
    
  }

  checkChanged(event:any): void {
    const control = this.formService.RemoveControlsFromGroup.find((x) => x.name === this.Control.name && x.module === this.Control.module && x.position === this.Control.position);
    let index: number;
    if (control !== null) {
      index = this.formService.RemoveControlsFromGroup.indexOf(control);
    }
    this.EnabledSelect = event.checked;
    // console.log("check", this.EnabledSelect, event.checked);
    if (this.EnabledSelect) {
      this.Control.required = true; 
      const controlSelect = this.fb.control(this.Control);
      this.Group.addControl(this.Control.name, controlSelect);

      this.Group.get(this.Control.name).setValidators(Validators.required);
      this.Group.controls[this.Control.name].setValue(''); 
      this.Group.get(this.Control.name).updateValueAndValidity();

      if (index !== null && index >= 0) {
        this.formService.RemoveControlsFromGroup.splice(index, 1);
      }
      //const event = new Event('change');
      //this.input.nativeElement.dispatchEvent(event);
      
    } else if (!this.EnabledSelect) {
      // this.Group.get(this.Control.name).setValue('');
      this.Control.required = false; 
      this.Control.value = '';
      this.input.nativeElement.value = '';
      this.Group.get(this.Control.name).clearValidators();
      this.Group.get(this.Control.name).setErrors(null);
      this.Group.get(this.Control.name).updateValueAndValidity();
      if (index < 0) {
        this.formService.RemoveControlsFromGroup.push(this.Control);
      }
            const event = new Event('change');
            this.input.nativeElement.dispatchEvent(event);
    }

  }

}
