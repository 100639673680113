<div class="podgladTech vh-100" *ngIf="IsPreviewTech == true">

  <h1 class="titleTechnology w-100 ">Wyniki technologii
    <button mat-icon-button class="float-right" (click)="closeTech(false)">
      <mat-icon>close</mat-icon>
    </button>
  </h1>

  <div id="loading" *ngIf="!IsLoaded"></div>
    <div class="treeResultOne">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree overflow-auto h-100">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <li class="mat-tree-node">
            <button mat-icon-button disabled></button>
            <button class="btn btn-sm" (click)='selectNode(node)'>{{node.name}}</button>
          </li>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <li>
            <div class="mat-tree-node">
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <span style="cursor: pointer;" (click)='selectNode(node)'>{{node.name}}</span>
            </div>
            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </ul>
          </li>
        </mat-nested-tree-node>
      </mat-tree>
    </div>


    <div class="tab">
      <ng-container *ngIf="ResultInfo">
      <button
        class="tablinks"
        *ngIf="ResultInfo.KompilacjaBledy"
        (click)="showParams(ResultInfo.KompilacjaBledy, 'Errors')"
        [class.active]="ActiveButton === 'Errors'"
      >
        Błędy
      </button>
      <button
        class="tablinks"
        *ngIf="ResultInfo.WynikiExist"
        (click)="showResults('Results')"
        [class.active]="ActiveButton === 'Results'"
      >
        Wyniki
      </button>
      <button
        class="tablinks"
        *ngIf="ResultInfo.Parametry"
        (click)="showParams(ResultInfo.Parametry, 'Params')"
        [class.active]="ActiveButton === 'Params'"
      >
        Parametry
      </button>
      <button
        class="tablinks"
        *ngIf="ResultInfo.ParametryWyliczeniowe"
        (click)="showParams(ResultInfo.ParametryWyliczeniowe, 'CountingParams')"
        [class.active]="ActiveButton === 'CountingParams'"
      >
        Parametry wyliczeniowe
      </button>
      <button
        class="tablinks"
        *ngIf="ResultInfo.ParametryZProgramu"
        (click)="showParams(ResultInfo.ParametryZProgramu, 'ProgramParams')"
        [class.active]="ActiveButton === 'ProgramParams'"
      >
        Parametry z programu
      </button>
      <button
        class="tablinks"
        *ngIf="ResultInfo.ParametryTabelaryczneExist"
        (click)="showParams(ResultInfo.ParametryTabelaryczne, 'TabParams')"
        [class.active]="ActiveButton === 'TabParams'"
      >
        Parametry tabelaryczne
      </button>
    </ng-container>
    </div>

    <div id="detailsResult" class="detailsResult">
      <ng-container *ngIf="IsShowResults">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Pozycja</th>
              <th scope="col">Sekcja</th>
              <th scope="col">Kod techniczny</th>
              <th scope="col" style="min-width: 25px; max-width: 25px;">
                Długość
              </th>
              <th scope="col">Informacje dodadtkowe</th>
              <th scope="col">Dane dodatkowe</th>
              <th scope="col" style="min-width: 25px; max-width: 30px;">
                Długość 2
              </th>
            </tr>
          </thead>
          <tbody *ngFor="let result of ResultInfo.WynikiTab">
            <tr>
              <th style=" padding: 1px;" scope="row">{{ result.Pozycja }}</th>
              <td>{{ result.Sekcja }}</td>
              <td
                style="word-wrap: break-word;min-width: 200px;max-width: 200px;"
              >
                {{ result.KodTech }}
              </td>
              <td>{{ result.Dlugosc }}</td>
              <td
                style="word-wrap: break-word;min-width: 250px;max-width: 250px;"
              >
                {{ result.InfoDod }}
              </td>
              <td *ngIf="result.DaneDod">{{ result.DaneDod }}</td>
              <td>{{ result.Dlugosc2 }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngIf="!IsShowResults">
        {{ paramsOnWeb }}
      </ng-container>
    </div>
</div>
