<div class="container">
    <div class="kafelki">
        <div class="kafelka" (click)="routeTo('Dok/poj/cenniki')">
          <div class="foto foto1"></div>
          <div id="cenniki" class="tresc" [translation]="TranslationEnum.Translation">{{'cenniki'|translate}}</div>
        </div>
        <div class="kafelka" (click)="routeTo('Dok/roz/suplementy')">
          <div class="foto foto2"></div>
          <div id="suplementy" class="tresc" [translation]="TranslationEnum.Translation">{{'suplementy'|translate}}</div>
        </div>
        <div class="kafelka" (click)="routeTo('Dok/roz/dte')">
          <div class="foto foto3"></div>
          <div id="dte" class="tresc" [translation]="TranslationEnum.Translation">{{'dte'|translate}}</div>
        </div>
        <div class="kafelka" (click)="routeTo('Dok/roz/dwu')">
          <div class="foto foto4"></div>
          <div id="dwu" class="tresc" [translation]="TranslationEnum.Translation">{{'dwu'|translate}}</div>
        </div>
        <div class="kafelka" (click)="routeTo('Dok/roz/deklaracje_z')">
          <div class="foto foto5"></div>
          <div id="deklaracje_z" class="tresc" [translation]="TranslationEnum.Translation">{{'deklaracje_z'|translate}}</div>
        </div>
        <div class="kafelka" (click)="routeTo('Braki')">
          <div class="foto foto7"></div>
          <div id="braki" class="tresc" [translation]="TranslationEnum.Translation">{{'braki'|translate}}</div>
        </div>
    </div>
</div>