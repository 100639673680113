import { DataSharingService } from './../../shared/service/data-sharing.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../notifications.service';
import { Contractor } from 'src/app/shared/models/user/contractor.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications-history',
  templateUrl: './notifications-history.component.html',
  styleUrls: ['./notifications-history.component.scss'],
})
export class NotificationsHistoryComponent implements OnInit, AfterViewInit, OnDestroy {
  public TranslationEnum = TranslationEnum;
  public serverUrl: string;
  private subscriptions: Subscription[] = [];

  constructor(public notificationsService: NotificationsService, private dataSharingService: DataSharingService) {
    this.serverUrl = environment.apiUrl;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit() {
    const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
    this.notificationsService.getAllNotifications(contractor.ContractorNumber);
  }

  ngAfterViewInit(): void {

    this.subscriptions.push(this.dataSharingService.contractorChanged.subscribe((x) => {
      if (x) {
        const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
        this.notificationsService.getAllNotifications(contractor.ContractorNumber);
      }
    }));

  }

}
