import { Kim } from 'src/app/shared/models/form-model/kim.model';
import { PositionModelComponent } from './../form-model/models/component-edit.model';
import { FormGroup } from '@angular/forms';
import { HtmlControl } from '../form-model/models/html-control.model';
import { ManageModule, Parameter } from '../form-model/models/form-controlls.model';
export class DialogPosition {
  PositionToEdit?: PositionModelComponent;
  KindOper: number;
  PositionId: number;
  Controls?: HtmlControl[];
  Form?: FormGroup;
  HeaderForm: FormGroup;
  CurrentStep?: string;
  ManageModule: ManageModule;
  Module: number;
  MultiStepsForEdit: boolean;
  PreviousPosition?: PositionModelComponent;
  PreviousPositionsForVerification?: PositionModelComponent;
  IsOpenByEdit?: boolean;
  ParametersForEdit?: Parameter[];
  KimForEdit?: Kim[];
  Set: number;
}
