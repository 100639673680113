import { Contractor } from './../../shared/models/user/contractor.model';
import { ContractorsForTraderData } from '../../shared/models/user/contractors-for-trader-data.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractorChooseDialogService {

  constructor(private httpClient: HttpClient) { }

  // public getContractorsForTrader(searchTerm: string, pageIndex: number, pageSize: number): Observable<ContractorsForTraderData> {
  //   return this.httpClient.get<ContractorsForTraderData>('api/trader/GetTraderContractors?searchTerm=' + searchTerm + '&pageNumber=' + (pageIndex + 1) + '&itemPerPage=' + pageSize);
  // }

  public getContractorsForTrader(): Observable<Contractor[]> {
    return this.httpClient.get<Contractor[]>('api/trader/GetTraderContractors'); 
  }
}
