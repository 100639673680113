export enum OrderStatusEnum {
  developed = 1,
  ordered = 2,
  inProduction = 3,
  transport = 4,
  paused = 5,
  processing = 6,
  accepted = 7,
  b2BProcessing = 8,
  completed = 9,
  canceled = 10,
  forVerification = 11,
  orderVerification = 12,
  rejected = 13,
  canceledNoPayment = 14,
  forVerificationByManager = 15,
}
