import { DirectivesService } from './directives.service';
import { AuthorizationService } from '../../module-login/authorization.service';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PermissionEnum } from '../enums/permission-enum';
import { TooltipInformationComponent } from '../components/form-controls/information/tooltip-information/tooltip-information.component';
import { SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';


@Directive({
  selector: '[appInformation]'
})

export class InformationDirective implements OnInit, OnChanges, OnDestroy {

  @Input('appInformation') label: string;
  @Input() imgSrc?: SafeUrl;
  @Input() fileInfoId ?: string;
  private subscriptions: Subscription[] = [];

  private tooltipRef: ComponentRef<TooltipInformationComponent>;
  private permissionValue: boolean;
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay, private overlayPositionBuilder: OverlayPositionBuilder, private elementRef: ElementRef, private authorizationService: AuthorizationService,
    private directivesService: DirectivesService) {
  }

  ngOnInit(): void {
    const permission = this.authorizationService.hasPermission(PermissionEnum.Translation);
    this.permissionValue = permission !== null && permission !== undefined ? permission.Value : false;

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
        { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' }
      ])
      .withPush(false);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
  }

  ngOnChanges(): void {
    if (this.tooltipRef !== undefined) {
      this.tooltipRef.instance.ImgSrc = this.imgSrc;
    }
  }

  ngOnDestroy() {
    this.directivesService.close();
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  @HostListener('mouseover')
  show() {
    if (this.label !== '') {
      this.directivesService.close();
      this.tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipInformationComponent));
      this.tooltipRef.instance.label = this.label;
      this.tooltipRef.instance.ImgSrc = this.imgSrc;
      
      this.tooltipRef.instance.permissionValue = this.permissionValue;
      this.tooltipRef.instance.fileInfoId = this.fileInfoId;
      this.subscriptions.push(this.tooltipRef.instance.closeEvent.subscribe(() => this.overlayRef.detach()));
      this.directivesService.OverlayRef = this.overlayRef;
    }
  }

  @HostListener('mouseout')
  hide() {
    if (!this.permissionValue) {
      this.directivesService.close();
    }
  }
}
