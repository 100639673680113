export class Complaint {
    Id: Number; 					// Id reklamacji 
    ContractorNumber:string;	// Numer kontrahenta
    ComplaintNumber:string;		// Sformatowany numer reklamacji
    ProductionNumber:string;	// Numer produkcyjny
    InvoiceNumber:string;		// Faktura
    Status:string;				// Przetłumaczona nazwa statusu
    StatusId:Number;				// Id statusu 
    ShortDescription:string;
    LongDescription:string;
    LongDescriptionPl:string;	
    ClaimName:string;	
    ClaimContent:string;	
    ClaimId:Number;	
    ClaimIsTextEnabled:boolean;	
    ContactName:string;	
    ContactPhone:string;	
    ContactMail:string;	
    DecisionId:Number;	
    DecisionName:string;	
    InvoiceWarehouseNumber:Number;	
    InvoiceDocumentNumber:Number;	
    Year:string;	
    IsTrader:boolean;
    Positions:Position[];
    Files:Files[];

    B2BNumber:string;
    OrderDetails:string;
    CancelEnable:boolean;
    TraderOrder:boolean;
    IsPrintLabelEnable:boolean;
    Date:any; 
    ComplaintId:number;
   }
   export class Position {
    ReportId:Number;
    ReportName:string;
    OrderLp:Number;
    Module:Number;
    Position:Number;
    QuantityComplaint:Number;
    ProductionNumber:string;
    MaxPoz:Number;

   }
   export class Files {
    Id:Number;
    Name:string;
    Size:number;
    Type:string;
    file:any;
    add:boolean;
    add_button:boolean
   }
