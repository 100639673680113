import { TresciService } from './../tresci.service';
import { HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { serialize } from 'class-transformer';
import { take } from 'rxjs/operators';

export class Sketch {
  public rysunek = null;
  constructor(private tresci: TresciService, private parametry, private id) {
    this.pobierz();
  }
  public pobierz() {
    const dane = this.parametry;

    const body = serialize(dane);
    const request = new HttpRequest('POST',
      '/api/ActiveForm/' + this.id + '?cultureId=' + this.tresci.jezyk_id,
    dane,
    {responseType: 'blob', headers: this.tresci.user.getHeaderJson()});
      this.tresci.http.request(request).pipe(/*take(1)*/)
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Response:
            const urlCreator = window.URL;
            this.rysunek = this.tresci.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(event.body));
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {

            //
            // this.rysunek = this.tresci.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(err.error.text));
          }
        });
  }


}
