<button #forMe mat-icon-button [color]="ColorNotification" [matMenuTriggerFor]="menu">
  <mat-icon [matBadge]="UserNotifications.length" matBadgeColor="warn">notifications</mat-icon>
</button>
<mat-menu #menu="matMenu" class="w-100 pl-3 pb-3 pt-4">
  <h4 class="d-inline-block" id="powiadomienia" [translation]="TranslationEnum.Translation">
    {{'powiadomienia'|translate }}
  </h4>
  <button mat-icon-button class="float-right d-block pr-3 mr-3" [color]="ColorNotification"
    [routerLink]="'notification-history'">
    <mat-icon>history</mat-icon>
  </button>
  <div class="notification-content">
    <ng-container
      *ngIf="UserNotifications !== null && UserNotifications !== undefined && UserNotifications.length > 0; else emptyList">
      <div *ngFor="let notification of UserNotifications">
        <div mat-menu-item>
          <div class="row pr-3 mw-100 mt-3">
            <div class="col-1">
              <mat-icon>priority_high</mat-icon>
            </div>
            <div class="col-10">
              <span [innerHtml]="notification.Content" class="text-wrap mt-3 mb-3 mr-2"></span>
              <div *ngIf="notification.Document" class="mt-2 text-left">
                <mat-icon style="font-size: 17px; line-height: 1.5;" class="text-left">attach_file</mat-icon>
                <a class="text-center mt-auto mb-auto"
                  [href]="ServerUrl + '/api/Notification/GetNotificaionFile?fileId=' + notification.Document.Id"
                  method="POST" target="_blank">{{notification.Document.FileName}}</a>
              </div>
            </div>
            <div class="col-1">
              <span class="float-right answers">
                <button mat-icon-button class="d-block"
                  (click)="$event.stopPropagation(); acceptOrDeclineNotification(true, notification.Id)">
                  <mat-icon style="color: forestgreen">done</mat-icon>
                </button>
                <!-- <button mat-icon-button (click)="acceptOrDeclineNotification(false, notification.Id)"><mat-icon style="color: rgb(250, 16, 16)">close</mat-icon></button> -->
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #emptyList>
      <p id="brak_powiadomien" class="ml-auto mr-auto text-center" [translation]="TranslationEnum.Translation">
        {{'brak_powiadomien'|translate }}
      </p>
    </ng-template>
  </div>
</mat-menu>
