import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TresciService } from '../tresci.service';
import { FormControls, Parameter } from '../shared/models/form-model/models/form-controlls.model';
import { Observable } from 'rxjs';
import { UserService } from '../user.service';
import { Contractor } from '../shared/models/user/contractor.model';
import { KrokStep } from './form-model/StepKrok';
import { FormDataSend } from './form-model/FormDataSend';
import { serialize } from 'class-transformer';
import { FileControl } from '../shared/models/form/file-control.model';
import { Country } from '../shared/models/order-register/order-estimate/country.model';
import { FormGroup } from '@angular/forms';
import { HtmlControl } from '../shared/models/form-model/models/html-control.model';
import { ActiveForm } from '../shared/models/form-model/models/active-form.model';
import { FormDataV2 } from './form-model/FormDataV2';
import { Control } from './form-model/Control';
import { EditForm } from '../shared/models/form/edit-form.model';

@Injectable({
  providedIn: 'root'
})
export class FormV2ServiceService {
  
  constructor(private httpClient: HttpClient, public tresci: TresciService, public user: UserService) { }

  public getOrderParameters(id: number): Observable<Parameter[]> {
    const data = {
      Id: id,
      IsTrader: this.tresci.IsTrader,
      IsComplaint: false,
    };
    return this.httpClient.post<Parameter[]>('api/ActiveForm/GetParameters', data, {
      headers: this.getHeaderJson(),
    });
  }
  public getImg(form: FormDataV2, control: Control): Observable<Blob> {
    const activeForm: ActiveForm = new ActiveForm();
    
    activeForm.Parameters.push({ Key: 'id', Value: control.DefaultValue });
    activeForm.Parameters.push({ Key: 'jezyk', Value: (window as any)['culture'] });

    var param = form.GetAllParams();
    param.forEach(p => {
      activeForm.Parameters.push({ Key: p.Key, Value: p.Value });
    });


/*
    Object.keys(form.controls).forEach((key) => {
      const control = form.controls[key].value;
      if (control !== null && control.inputType !== 'newline') {
        if (control.options !== undefined && control.options.length !== 0) {
          activeForm.Parameters.push({ Key: key, Value: control.value.Key });
        } else {
          activeForm.Parameters.push({ Key: key, Value: control.value });
        }
      }
    }); */

    return this.httpClient.post('/api/ActiveForm/GetImage?typ=' + localStorage.site + '&width=1080', activeForm.Parameters, { responseType: 'blob' });
  }

  public GetAllSteps(ProductPrefix, currentLang, OrderId, IsTrader, parametry, numer_poz): Observable<any> {
    const data = {
      Parameters: parametry,
      ContractorNumber: JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber, // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
      ProductPrefix: ProductPrefix,
      Culture: currentLang,
      IsCache: true,
      IsTrader: IsTrader,
      Type: 'Header',
      OrderId: OrderId,
      ModuleNumber: numer_poz
    };
    // console.log("data", data);
    
    return this.httpClient.post<any>('/api/ActiveForm/GetAllSteps', data, {
      headers: this.getHeaderJson(),
    });
  }
  public getCountriesForEstimateOrder(cultureId): Observable<Country[]> {
    var jezyk = 1;
    // console.log(this.tresci.jezyki);
    this.tresci.jezyki.forEach(j => {
     
    });
    return this.httpClient.get<Country[]>('api/Additives/GetCountries?cultureId=' + cultureId);
  }
  public AddorUpdateActiveFormOrderWithFiles(formData:FormDataSend, Files:FileControl[] = []){

    const dane = serialize(formData);
    const formDataFiles: FormData = new FormData();
    for (const file of Files) {
      if (file.File !== undefined) {
        if (formData.Parameters.findIndex((x) => x.Key === file.Key) > 0) {
          formDataFiles.append(file.Id, file.File, file.FileName);
        }
      }
    }
    formDataFiles.append('ActiveFormOrderVm', dane);

    return this.httpClient.post<any>('api/ActiveForm/AddorUpdateActiveFormOrderWithFiles', formDataFiles, { headers: this.getHeaderFile() });
  }
  

  

  public getMultiStep(ProductPrefix, currentLang, czyCache:boolean, IsTrader, Parameters, CurrentStep, PreviousPositionParameters, Type): Observable<KrokStep[]> {
    this.tresci.ostatnieParametry = Parameters;
    const data = {
      ContractorNumber: JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber, // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
      ProductPrefix: ProductPrefix,
      Culture: currentLang,
      IsCache: czyCache,
      IsTrader: IsTrader,
      Parameters: Parameters,
      PreviousPositionParameters: PreviousPositionParameters,
      CurrentStep: CurrentStep,
      Type: Type
    };

    
    return this.httpClient.post<KrokStep[]>('api/ActiveForm/GetMultiSteps', data, {
      headers: this.getHeaderJson(),
    });
  }
  public getStep(ProductPrefix, currentLang, czyCache:boolean, IsTrader, Parameters, CurrentStep, PreviousPositionParameters): Observable<KrokStep> {
    this.tresci.ostatnieParametry = Parameters;
    const data = {
      ContractorNumber: JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber, // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
      ProductPrefix: ProductPrefix,
      Culture: currentLang,
      IsCache: czyCache,
      IsTrader: IsTrader,
      Parameters: Parameters,
      PreviousPositionParameters: PreviousPositionParameters,
      CurrentStep: CurrentStep
    };

    
    return this.httpClient.post<KrokStep>('api/ActiveForm/GetStep', data, {
      headers: this.getHeaderJson(),
    });
  }

  

  public RemoveContractorAddress(Id): Observable<any> {

    const data = {
      Id: Id,
    };

    return this.httpClient.post<any>('api/Contractor/RemoveContractorAddress', data, {
      headers: this.getHeaderJson(),
    });
  }

  public AddContractorAddress(address:string, zipcode:string, city:string, country:string, comment:string): Observable<any> {

    const data = {
      ContractorNumber: JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber,
      Address: address,
      ZipCode: zipcode,
      City: city,
      Country: country,
      Comment: comment
    };

    return this.httpClient.post<any>('api/Contractor/AddContractorAddress', data, {
      headers: this.getHeaderJson(),
    });
  }
  public getDeliveryAddresses(): Observable<any> {
    const contractor: Contractor = JSON.parse(sessionStorage.getItem('_slc'));
    // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
    return this.httpClient.post<any>('api/Contractor/GetContractorAddresses', contractor.ContractorNumber, { headers: this.getHeaderJson() });
  }
  public GetComplaintActiveFrom(id:Number): Observable<any> {
    // const contractor: Contractor = JSON.parse(sessionStorage.getItem('_slc'));
    const data = {
      ContractorNumber: JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber,
      ComplaintType: id
    };

     
    // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
    return this.httpClient.post<any>('api/Complaint/GetComplaintActiveFrom', data, { headers: this.getHeaderJson() });
  }
  public GetComplaintTypes(): Observable<any> {
    const contractor: Contractor = JSON.parse(sessionStorage.getItem('_slc'));

    // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
    return this.httpClient.get<any>('api/Complaint/GetComplaintType');
  }

  

  public getHeader() {
    const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.user.token).set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }

  public getHeaderJson() {
    const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.user.token).set('Content-Type', 'application/json');
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }

  public getHeaderFile() {
    const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.user.token);
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }

}
