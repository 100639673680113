import { UniversalSnackBarComponent } from './../../shared/components/universal-snack-bar/universal-snack-bar.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NotificationsService } from './../notifications.service';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { NotificationUser } from './../../shared/models/notifications/notification-user.model';
import { RegisterNotificationUser } from 'src/app/shared/models/notifications/register-notification-user.model';
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NotificationTypeEnum } from 'src/app/shared/enums/notification-type-enum';
import { Notification } from './../../shared/models/notifications/notification.model';
import { User } from 'src/app/shared/models/user/user.model';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { distinctUntilChanged, takeWhile, tap } from 'rxjs/operators';
import { NotificationsModalComponent } from '../notifications-modal/notifications-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() notificationData: Notification;
  @Input() notificationRegisterData: RegisterNotificationUser;

  @Output() onBtnActionEvent = new EventEmitter<NotificationUser>();

  public TranslationEnum = TranslationEnum;
  public NotificationTypeEnum = NotificationTypeEnum;
  public ColorNotification = 'accent';
  public UserNotifications: Notification[];
  public numberTest = 5;
  public ServerUrl: string;
  private subscriptions: Subscription[] = [];

  private User: User;
  private notficationIsNotRequired: BehaviorSubject<Notification[]>;
  private notificationWithDocument$: Subscription;

  constructor(
    private authorizationService: AuthorizationService,
    private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    // new Array<Notification>();
    this.getNotification();
    this.notficationIsNotRequired = new BehaviorSubject<Notification[]>(null as any);
    this.ServerUrl = environment.apiUrl;

  }

  ngOnInit() {
    this.UserNotifications = [];
    this.subscriptions.push(this.authorizationService.User.subscribe((x) => (this.User = x)));
    this.initNotificationCheck();

    this.notificationWithDocument$ = this.notficationIsNotRequired
      .asObservable()
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (value !== null) {
          this.openDialog(value);
        }
      });
  }

  public initNotificationCheck(frequency = 1000 * 60 * 15) {
    setInterval(() => {
      this.getNotification();
    }, frequency);
  }

  ngOnDestroy(): void {
    this.notificationWithDocument$.unsubscribe();
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  public acceptOrDeclineNotification(acceptance: boolean, notificationId: number) {
    const notificationModel = new NotificationUser();

    notificationModel.Date = new Date();
    notificationModel.NotificationId = notificationId;
    notificationModel.Acceptance = acceptance;
    notificationModel.Username = this.User.Username;
    const indexToRemove = this.UserNotifications.findIndex((x) => x.Id === notificationId);
    this.UserNotifications.splice(indexToRemove, 1);
    this.notificationsService.saveUserAcceptance(notificationModel);
  }

  getNotification() {
    this.subscriptions.push(this.notificationsService.getNotificationForUser().subscribe(
      (res: Notification[]) => {
        if (res !== null && res.length !== 0) {
          const notficationIsRequired = res.filter((x) => x.IsRequired === false);
          const notficationIsNotRequired = res.filter((x) => x.IsRequired === true);
          if (notficationIsRequired !== null && notficationIsRequired.length > 0) {
            this.UserNotifications = notficationIsRequired;
          }

          if (notficationIsNotRequired !== null && notficationIsNotRequired.length > 0) {
            this.notficationIsNotRequired.next(notficationIsNotRequired);
          }

          this.snackBar.openFromComponent(UniversalSnackBarComponent, {
            data: 'masz_nowe_powiadomienie',
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
        
      },
      (err) => {
      }
    ));
  }

  openDialog(value) {
    let dialogRef: MatDialogRef<NotificationsModalComponent, any>;
    const data = { NotifcationsWithDocument: value, User: this.User };
    dialogRef = this.dialog.open(NotificationsModalComponent, { data: data, minWidth: '500px', disableClose: true });
  }
}
