<div id="detailsResult" class="detailsResult" style="overflow: auto">
  <ng-container *ngIf="!ParamsOnWeb">
    <div class="d-inline-block mt-3 mr-3 test">
      <mat-form-field appearance="outline" class="w-160p">
        <mat-select [(value)]="FilterColumn">
          <mat-option *ngFor="let item of FilterColumns" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="d-inline-block mt-3 mr-3">
      <mat-form-field appearance="outline" class="w-190p" color="accent">
        <input matInput (keyup.enter)="applyFilter(input)" #input placeholder="B2B/2020/03962" />
        <button mat-button *ngIf="input.value" matSuffix mat-icon-button class="d-inline-block" (click)="applyFilter(input)">
          <mat-icon>search</mat-icon>
        </button>
        <button mat-button *ngIf="input.value" matSuffix mat-icon-button class="d-inline-block" (click)="input.value = ''; applyFilter(input)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
   <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Pozycja</th>
              <th scope="col">Sekcja</th>
              <th scope="col">Kod techniczny</th>
              <th scope="col">Ilość</th>
              <th scope="col">Rodzaj</th>
              <th scope="col" style="min-width: 25px; max-width: 25px;">
                Długość
              </th>
              <th scope="col">Informacje dodatkowe</th>
              <th scope="col">Dane dodatkowe</th>
              <th scope="col" style="min-width: 25px; max-width: 30px;">
                Długość 2
              </th>
            </tr>
          </thead>
          <tbody *ngFor="let result of ResultInfo">
            <tr>
              <th style=" padding: 1px;" scope="row">{{ result.Pozycja }}</th>
              <td>{{ result.Sekcja }}</td>
              <td
                style="min-width: 100px;max-width: 100px;"
              >
                {{ result.KodTech }}
              </td>
              <td>{{ result.Ilosc }}</td>
              <td>{{ result.Rodzaj }}</td>
              <td>{{ result.Dlugosc }}</td>
              <td>{{ result.InfoDod }}</td>
              <td>{{ result.DaneDod }}</td>
              <td>{{ result.Dlugosc2 }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngIf="ParamsOnWeb">
        {{ ParamsOnWeb }}
      </ng-container>
</div>
