import { take } from 'rxjs/operators';
import { FilterKim } from '../shared/models/form/filter-kim';
import { TresciService } from './../tresci.service';

export class Kim {
  public otwarty = false;
  public asc = true;
  public sort_id = 'Name';
  public filteredList = [];
  public KimFilterList: FilterKim[] = [];
  private poprzeni_jezyk = '-1';



  constructor(public id, public KeyName, public valuesPL, public valuesDE, public valuesEN,
    public valuesFR, private tresci: TresciService, private ParametryWKim) {

    for (let index = 0; index < valuesPL.length; index++) {
      if (ParametryWKim !== null && ParametryWKim.length > 0) {
        let byl = false;
        ParametryWKim.forEach(element => {
          if (element.Index === valuesPL[index].Index) {
            // valuesPL[index].Quantity = element.Quantity;
            this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName + '_' + this.valuesPL[index].Index, element.Quantity);
            byl = true;
          }
        });
        if (!byl) {
          // this.tresci.zapisz(this.KeyName + '_' + this.id + '_' +  valuesPL[index].Index, 0);
          this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName + '_' + this.valuesPL[index].Index, 0);
        }
      } // else if (this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName + '_' + this.valuesPL[index].Index) !== null) {
      //  valuesPL[index].Quantity = this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName + '_' + this.valuesPL[index].Index);
      // }
    }

    this.tresci.getSystemFilter().pipe(/*take(1)*/).subscribe(filters => this.KimFilterList = filters);
    this.sortuj_po('Name');
  }
  

  public SourceArray() {
    if (this.tresci.jezyk_id.toString() === '1') {
      return this.valuesPL;
    } else if (this.tresci.jezyk_id.toString() === '3') {
      return this.valuesDE;
    } else if (this.tresci.jezyk_id.toString() === '4') {
      return this.valuesFR;
    } else {
      return this.valuesEN;
    }
  }
  public Zamknij() {
    this.filtruj_po(0, '', '', false);
    this.otwarty = false;
  }
  public DisplayArray() {
    if (this.poprzeni_jezyk.toString() !== this.tresci.jezyk_id.toString()) {
      this.poprzeni_jezyk = this.tresci.jezyk_id.toString();
      this.filteredList = this.SourceArray();
      this.sortuj_po(null);
      this.filteredList.forEach(element => {
        if (this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName + '_' + element.Index) !== null) {
          element.Quantity = this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName + '_' + element.Index);
        } else {
          element.Quantity = 0;
          this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName + '_' + element.Index, 0);
        }
      });
    }

    return this.filteredList;
  }

  public getDisplayValue() {
    let wynik = '';
    let licz = 0;
    this.DisplayArray().forEach(element => {
      // wynik += element.Name + ', ';
      if (element.Quantity > 0) {
        wynik += element.Name.replace(/ /g, '.') + ' x ' + element.Quantity + ', ';
        licz++;
      }
    });
    if (licz > 0) {
      return wynik;
    } else {
      return this.tresci.pole_stale_odczyt_bez('---');
    }
  }
  public getList() {
    const Parametry = [];
    this.DisplayArray().forEach(element => {
      if (element.Quantity * 1 > 0) {
        Parametry.push(element);
      }
    });
    return Parametry;
  }

  public sortuj_po(id) {
    if (id == null) {
      id = this.sort_id;
    } else {
      this.asc = !this.asc;
    }
    this.sort_id = id;
    if (id === 'BasicPrice') {
      this.filteredList.sort((a, b) => {
        return this.porownaj(b.BasicPrice, a.BasicPrice, this.asc);
      });
    } else if (id === 'Key') {
      this.filteredList.sort((a, b) => {
        return this.porownaj(b.Key, a.Key, this.asc);
      });
    } else if (id === 'Name') {
      this.filteredList.sort((a, b) => {
        return this.porownaj(b.Name, a.Name, this.asc);
      });
    }
  }

  public filtruj_po(grupa: number, kolumna: string, warunek: string, wybrane: boolean) {
    this.filteredList = this.SourceArray();

    if (grupa.toString() === '0') {
      this.filteredList = this.DisplayArray();
    } else {
      this.filteredList = this.DisplayArray().filter(a => a['Filter' + grupa] === true);
    }

    if (kolumna === 'Kod katalogowy') {
      this.filteredList = this.DisplayArray().filter(a => a.Key.toLowerCase().includes(warunek.trim().toLowerCase()));
    } else if (kolumna === 'Nazwa') {
      this.filteredList = this.DisplayArray().filter(a => a.Name.toLowerCase().includes(warunek.trim().toLowerCase()));
    }
    if (wybrane === true) {
      this.filteredList = this.DisplayArray().filter(a => a.Quantity !== 0);
    }
  }

  public porownaj(a, b, asc = true) {
    if (a < b) { if (asc) { return -1; } else { return 1; } }
    if (a > b) { if (asc) { return 1; } else { return -1; } }
    return 0;
  }
  public dodaj(event, id, ilosc = null) {
    if (ilosc == null) {
      this.filteredList[id].Quantity++;
    } else {
      this.filteredList[id].Quantity = ilosc;
    }
    this.czy_poprawny(id);
    this.tresci.zamowienie.zapisz_Parametry();
    event.stopPropagation();
  }
  public minus(event, id) {
    this.filteredList[id].Quantity--;
    this.czy_poprawny(id);
    this.tresci.zamowienie.zapisz_Parametry();

    event.stopPropagation();
  }
  public czy_poprawny(id) {
    if (isNaN(this.filteredList[id].Quantity) || this.filteredList[id].Quantity < 0) {
      this.filteredList[id].Quantity = 0;
    }
    this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName + '_' + this.filteredList[id].Index, this.filteredList[id].Quantity);
    // this.tresci.zapisz(this.KeyName + '_' + this.id + '_' +  this.valuesPL[id].Index, this.valuesPL[id].Quantity);
  }
}
