import { Subscription } from 'rxjs';
import { DataSharingService } from 'src/app/shared/service/data-sharing.service';
import { FormControlsService } from './../../form-controls.service';
import { RenderPreviewDialogComponent } from './render-preview-dialog/render-preview-dialog.component';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { MatDialog } from '@angular/material/dialog';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';

@Component({
  selector: 'app-render',
  templateUrl: './render.component.html',
  styleUrls: ['./render.component.scss'],
})
export class RenderComponent implements OnInit, OnDestroy {
  public TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;
  public Src: SafeUrl;
  private subscriptions: Subscription[] = [];
  public ValueChangeInControlSubscription: Subscription;

  constructor(private formControlsService: FormControlsService, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef, private dataSharingService: DataSharingService) {
    // super();
    this.Src = '';
  }

  ngOnInit(): void {
    // super.loadScripts();
    this.subscriptions.push(this.formControlsService.getImg(this.Group, this.Control).subscribe(
      (data) => {
        this.createImageFromBlob(data);
      },
      (error) => {
        this.Src = '';
      }
    ));

    this.ValueChangeInControlSubscription = this.dataSharingService.ValueChangeInControl.subscribe(x => {
      if (x !== null && x.ValueChange === true && x.CurrentStep === this.Control.currentStep) {
        this.subscriptions.push(this.formControlsService.getImg(this.Group, this.Control).subscribe(
          (data) => {
            this.createImageFromBlob(data);
          },
          (error) => {
            this.Src = '';
          }
        ));
        this.dataSharingService.ValueChangeInControl.next(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.ValueChangeInControlSubscription.unsubscribe();
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.Src = reader.result ?? '';
        this.changeDetectorRef.detectChanges();
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  openRenderPreviewDialog(): void {
    const dialogRef = this.dialog.open(RenderPreviewDialogComponent, { data: this.Src });

    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
    }));
  }
}


