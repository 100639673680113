import { FormGroup } from '@angular/forms';
import { Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation, } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { TresciService } from 'src/app/tresci.service';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';



@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SelectComponent implements OnInit, AfterViewInit {

  public TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;

  @ViewChild('select', { static: true }) select: MatSelect;
  constructor(public tresci: TresciService) { }

  ngOnInit(): void {
    this.Control.options.forEach((element) => {
      if (element.Key === this.Control.value) {
        this.Control.value = element;
        this.Group.get(this.Control.name).setValue(this.Control);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.Control.value === '' || this.Control.value === undefined) {
      this.Group.get(this.Control.name).setValue('');
    }

    setTimeout(() => {
      if (this.Control.value !== '' && this.Control.value !== undefined) {
        this.select.selectionChange.emit(new MatSelectChange(this.select, this.Control.value));
      }
    });
  }



}
