export class Uwagi {
  public value = '';
  public widoczny = '';
  constructor(value) {
    this.value = value;
  }
  public zmien(value) {
    this.value = value;
    if (this.widoczny !== 'znikajacy') {
      this.widoczny = 'znikajacy';
    } else {
      this.widoczny = 'znikajacy2';
    }
  }
}

