import { PdfComponent } from './form-controls/pdf/pdf.component';
import { TooltipInformationComponent } from './form-controls/information/tooltip-information/tooltip-information.component';
import { TooltipImgComponent } from './tooltip-img/tooltip-img.component';
import { InformationComponent } from './form-controls/information/information.component';
import { CheckboxFileComponent } from './form-controls/checkbox-file/checkbox-file.component';
import { RadioComponent } from './form-controls/radio/radio.component';
import { RadioImgComponent } from './form-controls/radio-img/radio-img.component';
import { CustomTranslateLoader } from './../language-loader/CustomTranslateLoader';
import { SelectImgComponent } from './form-controls/select-img/select-img.component';
import { SelectComponent } from './form-controls/select/select.component';
import { InputComponent } from './form-controls/input/input.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from './form-controls/checkbox/checkbox.component';
import { LabelComponent } from './form-controls/label/label.component';
import { MaterialModule } from 'src/app/material/material.module';
import { ValueObjectDirective } from '../value-object/value-object.directive';
import { TlumaczComponent } from 'src/app/tlumacz/tlumacz.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../directives/directives.module';
import { CheckboxInputComponent } from './form-controls/checkbox-input/checkbox-input.component';
import { CheckboxSelectComponent } from './form-controls/checkbox-select/checkbox-select.component';
import { ImgComponent } from './form-controls/img/img.component';
import { NewLineComponent } from './form-controls/new-line/new-line.component';
import { SelectColorComponent } from './form-controls/select-color/select-color.component';
import { FabricComponent } from './form-controls/fabric/fabric.component';
import { MessageDialogComponent } from './form-controls/message-dialog/message-dialog.component';
import { FileComponent } from './form-controls/file/file.component';
import { KimComponent } from './form-controls/kim/kim.component';
import { KimDialogComponent } from './form-controls/kim/kim-dialog/kim-dialog.component';
import { FabricDialogComponent } from './form-controls/fabric/fabric-dialog/fabric-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { SelectMultiColumnComponent } from './form-controls/select-multi-column/select-multi-column.component';
import { RenderComponent } from './form-controls/render/render.component';
import { RenderPreviewDialogComponent } from './form-controls/render/render-preview-dialog/render-preview-dialog.component';
import { VisualizationComponent } from './form-controls/visualization/visualization.component';
import { CheckFabricComponent } from './form-controls/checkfabric/checkfabric.component';
import { CheckSelectMultiColumnComponent } from './form-controls/select-multi-column-check/check-select-multi-column.component';


export const COMPONENTS = [
  CheckboxComponent,
  CheckboxFileComponent,
  CheckboxInputComponent,
  CheckboxSelectComponent,
  CheckSelectMultiColumnComponent,
  RenderComponent,
  RenderPreviewDialogComponent,
  // CheckSketchComponent,
  FabricComponent,
  FabricDialogComponent,
  CheckFabricComponent,
  FileComponent,
  // HiddenComponent,
  ImgComponent,
  InformationComponent,
  InputComponent,
  KimComponent,
  KimDialogComponent,
  LabelComponent,
  MessageDialogComponent,
  // ModuleComponent,
  NewLineComponent,
  PdfComponent,
  RadioComponent,
  RadioImgComponent,
  SelectColorComponent,
  SelectComponent,
  SelectImgComponent,
  SelectMultiColumnComponent,
  // SketchComponent,
  TlumaczComponent,
  TooltipImgComponent,
  TooltipInformationComponent,
  ValueObjectDirective,
  // VisualizationComponent,
];
@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader, useClass: CustomTranslateLoader
      }
    }),
    DirectivesModule,
    BrowserModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class ComponentsModule { }

