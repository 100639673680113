import { style } from '@angular/animations';
import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';



@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit, AfterViewInit{

  public TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;
  public IsNumber: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.Control.value.toLowerCase();
    if (isNaN(this.Control.value)) {
      this.IsNumber = false;
    } else {
      this.IsNumber = true;
    }


  }


  ngAfterViewInit(): void {
      if (this.Control.backgroundColour !== undefined || this.Control.backgroundColour !== null) {
        const domClassEnd = document.getElementById('atrr' + this.Control.label).getElementsByClassName('mat-form-field-outline-end');
        const domClassStart = document.getElementById('atrr' + this.Control.label).getElementsByClassName('mat-form-field-outline-start');
        Array.from(domClassEnd).forEach((i) => {
          (<HTMLElement>i).style.backgroundColor = this.Control.backgroundColour;
        });

        Array.from(domClassStart).forEach((i) => {
          (<HTMLElement>i).style.backgroundColor = this.Control.backgroundColour;
        });
      }

  }

  autoHeight(elem) {
      elem.style.height = '1px';
      elem.style.height = (elem.scrollHeight) + 'px';
  }

}
