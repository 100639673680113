import { TresciService } from './../../../../tresci.service';
import { Component, ElementRef, OnInit, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { FabricDialogComponent } from './fabric-dialog/fabric-dialog.component';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { take } from 'rxjs/operators';



@Component({
  selector: 'app-fabric',
  templateUrl: './fabric.component.html',
  styleUrls: ['./fabric.component.scss']
})
export class FabricComponent implements OnInit, AfterViewInit {

  public Control: HtmlControl;
  public Group: FormGroup;
  @ViewChild('input') input: ElementRef;
  public src: string;

  public TranslationEnum = TranslationEnum;

  constructor(public dialog: MatDialog, public tresci: TresciService) { }

  ngOnInit(): void {

    this.src = '';
    this.Group.get(this.Control.name).setValue(this.Control.value);

    this.Control.options.forEach(element => {
      if (element.Key === this.Control.value) {
        this.Control.value = element;
        this.Group.get(this.Control.name).setValue(element);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.Control.value === '') {
      this.Group.get(this.Control.name).setValue(this.Control.value);
    }
  }



  openFabricDialog() {
    const dialogRef = this.dialog.open(FabricDialogComponent, { data: this.Control.options, width: '1320px' });
    dialogRef.afterClosed().pipe(/*take(1)*/).subscribe(result => {
      if (result !== undefined) {
        this.Control.value = result;
        this.input.nativeElement.value = result.Value;
        const event = new Event('change');
        this.input.nativeElement.dispatchEvent(event);
        this.src = this.tresci.server_url + '/api/ActiveForm/GetIcon?code=' + result.Key;
      }

    });
  }
}
