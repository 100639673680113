<h2 mat-dialog-title id="nowawiadomosc" [translation]="TranslationEnum.Translation">
  {{'nowawiadomosc'|translate}}
  <!-- <app-tlumacz [id]="'NowaWiadomosc'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
</h2>
<form [formGroup]="mailGroup" (ngSubmit)="onSubmit(mailGroup)" #mailForm="ngForm">
  <div mat-dialog-content>

    <!-- To -->
    <label id="do" [translation]="TranslationEnum.Translation"> {{'do'|translate}}</label>
    <!-- <app-tlumacz [id]="'Do'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    <mat-form-field appearance="outline" class="w-100" color="accent">
      <input matInput formControlName="to" type="text" [errorStateMatcher]="matcher">
      <mat-error
        *ngIf="(mailGroup.controls['to'].hasError('pattern') || mailGroup.controls['to'].hasError('required')) && mailForm.submitted">
        <app-tlumacz [id]="'Proszę wprowadzić poprawny e-mail.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
      </mat-error>
    </mat-form-field>

    <!-- DW -->
    <label id="dw" [translation]="TranslationEnum.Translation"> {{'dw'|translate}}</label>
    <!-- <app-tlumacz [id]="'DW'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    <mat-form-field appearance="outline" class="w-100" color="accent">
      <input matInput formControlName="cc" type="text" [errorStateMatcher]="matcher">
      <mat-error *ngIf="mailGroup.controls['cc'].hasError('pattern') && mailForm.submitted">
        <app-tlumacz [id]="'Proszę wprowadzić poprawny e-mail.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
      </mat-error>
    </mat-form-field>

    <!-- Subject -->
    <label id="temat" [translation]="TranslationEnum.Translation"> {{'temat'|translate}}</label>
    <!-- <app-tlumacz [id]="'Temat'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    <mat-form-field appearance="outline" class="w-100" color="accent">
      <input  matInput formControlName="subject" type="text">
    </mat-form-field>

    <!-- Content -->
    <label id="wiadomosc" [translation]="TranslationEnum.Translation"> {{'wiadomosc'|translate}}</label>
    <!-- <app-tlumacz [id]="'Wiadomosc'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    <mat-form-field appearance="outline" class="w-100" color="accent">
      <textarea matInput formControlName="content" type="text" cdkTextareaAutosize
        cdkAutosizeMinRows="5"></textarea>
    </mat-form-field>

    <label id="zalaczniki" class="d-block" [translation]="TranslationEnum.Translation">
      {{'zalaczniki' |translate}}</label>
    <!-- <app-tlumacz class="d-block" [id]="'Zalaczniki'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->

    <!-- Specification -->
    <mat-checkbox class="mr-3" formControlName="specification" type="checkbox" color="primary">
      <span id="specyfikacja" [translation]="TranslationEnum.Translation">{{'specyfikacja'|translate}}</span>
      <!-- <app-tlumacz [id]="'specyfikacja'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </mat-checkbox>

    <!-- Valuation -->
    <mat-checkbox formControlName="valuation" type="checkbox" color="primary">
      <span id="wycena" [translation]="TranslationEnum.Translation">{{'wycena'|translate}}</span>
      <!-- <app-tlumacz [id]="'wycena'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </mat-checkbox>
  </div>
  <div mat-dialog-actions>
    <div class="w-100">
      <button mat-flat-button id="anuluj" class="float-right" [translation]="TranslationEnum.Translation" color="primary"
        mat-dialog-close> {{'anuluj'|translate}} </button>
      <button mat-flat-button id="wyślij" class="float-right mr-3" [translation]="TranslationEnum.Translation" color="primary"
        type="submit"> {{'wyślij'|translate}} </button>
    </div>
  </div>
</form>
