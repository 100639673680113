<div *ngIf="Control">
  <label>
    <span id="label_{{Control.label}}" [translation]="TranslationEnum.TranslationForm">
      {{('label_'+ Control.label)|translate}}
    </span>
    {{MinMaxLabel}}
    <app-information [label]="Control.label" [fileInfoId]="Control.fileInfoId"></app-information>
  </label>
  <div class="w-100">
    <mat-checkbox #checkbox [(ngModel)]="EnabledInput" color="primary" class="d-inline mr-2"
      (change)="checkChanged(); input.value = null; " [disabled]="input.disabled"></mat-checkbox>
    <div [formGroup]="Group" class="d-inline">
      <mat-form-field appearance="outline" color="accent" class="w-auto-100">
        <input #input matInput [formControlName]="Control.name" [type]="Control.inputType.replace('check', '')"
          [value-object]="Control" [min]="Min" [max]="Max" [readonly]="!EnabledInput">
        <ng-container *ngFor="let validation of Control.validations;" ngProjectAs="mat-error">
          <mat-error *ngIf="Group.get(Control.name)?.hasError(validation.name)" id="{{validation.message}}"
            [translation]="TranslationEnum.TranslationForm">
            {{validation.message|translate}}
          </mat-error>
        </ng-container>
      </mat-form-field>
    </div>
  </div>
</div>
