import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PermissionEnum } from 'src/app/shared/enums/permission-enum';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { FileControl } from 'src/app/shared/models/form/file-control.model';
import { FormControlsService } from '../../../form-controls.service';
import { Subscription } from 'rxjs';
import { TresciService } from 'src/app/tresci.service';

@Component({
  selector: 'app-tooltip-information',
  templateUrl: './tooltip-information.component.html',
  styleUrls: ['./tooltip-information.component.scss'],
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TooltipInformationComponent implements OnInit, OnDestroy {

  @Input() label = '';
  @Input() ImgSrc?: SafeUrl = undefined;
  @Input() fileInfoId ?= '';
  @Input() permissionValue = false;
  @Output() closeEvent = new EventEmitter<void>();
  private subscriptions: Subscription[] = [];

  TranslationEnum = TranslationEnum;
  PermissionEnum = PermissionEnum;
  File: FileControl = new FileControl();
  public ShowText = false;

  constructor(private formControlsService: FormControlsService, private translateService: TranslateService, public tresci: TresciService) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit(): void {
    if (this.permissionValue === true) {
      this.translateService.reloadLang(this.translateService.currentLang);
    }

    if (this.permissionValue === true) {
      this.translateService.reloadLang(this.translateService.currentLang);
      this.ShowText = true;
    } else {
      this.subscriptions.push(this.translateService.get('info_' + this.label.toLowerCase()).subscribe(x => {
        if (x.trim().toLowerCase() !== ('info_' + this.label.toLowerCase())) {
          this.ShowText = true;
        }
      }));
    }
    this.File.Id = this.fileInfoId ?? 'plik_info_' + this.label + '_' + (window as any)['site'] + '_' + (window as any)['culture'];
    this.label = this.label.toLowerCase();
  }

  changeImg(files: FileList): void {
    const addFile = files.item(0);

    this.File.File = addFile;
    this.File.FileName = addFile.name;
  }

  saveImg(): void {
    this.formControlsService.saveImgForInfo(this.File);
  }

  removeImg(): void {
    this.formControlsService.removeImgForInfo(this.File.Id);
  }

  close(): void {
    this.closeEvent.emit();
  }
}
