import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslationEnum } from '../shared/enums/translation-enum';
import { FormV2ServiceService } from '../form-v2/form-v2-service.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TresciService } from '../tresci.service';
import { UserService } from '../user.service';
import { BehaviorSubject } from 'rxjs';
import { KindOper } from '../shared/enums/kind-oper-enum';
import { Parameter } from '../shared/models/form-model/models/form-controlls.model';

@Component({
  selector: 'app-form-v2-preview',
  templateUrl: './form-v2-preview.component.html',
  styleUrls: ['./form-v2-preview.component.scss']
})
export class FormV2PreviewComponent implements OnInit,OnDestroy {

  public TranslationEnum = TranslationEnum;
  TranslationLoaded: any;
  culture: any;
  wczytywanie: boolean;
  kindOper: any;
  SystemName: string;
  produktPrefix: any;
  IsTrader: any;
  orderId: any;
  zakladka: number = 0;
  Parametry: any;
  DeliveryMethods: any;
  ContractorAddresses: any = [];
  modul = 0;
  modyly = [];
  naglowek = [];
  pozycja = [];

  podglad_poz = false;
  edytowany_mod: any;
  edytowana_poz: any;


  constructor(private formService: FormV2ServiceService,public dialog: MatDialog,private activatedRoute: ActivatedRoute,
    private translateService: TranslateService, public tresci: TresciService, public user: UserService, private router: Router) {

      this.activatedRoute.params.subscribe((params) => {
        localStorage.site = params.system;
        window['site'] = params.system;
        tresci.wczytaj_tresci_tlumaczen2(window['culture'], params.system);
        this.TranslationLoaded = new BehaviorSubject(false);
    
        this.translateService.reloadLang(this.translateService.currentLang).subscribe((x) => this.TranslationLoaded.next(true));
      });
    }

  ngOnInit(): void {
    
    // console.log(this.user);
    this.culture = window['culture'];
    this.wczytywanie = true;
    this.activatedRoute.params.subscribe((params) => {
   
      this.kindOper = KindOper[params.kindOper as keyof typeof KindOper];
      this.SystemName = 'system_' + params.system.toLowerCase();
      localStorage.site = params.system;
      window['site'] = params.system;
      this.produktPrefix = params.system;
      this.orderId = params.id;
      //this.formService.ProductPrefix = params.system;
      //window['site'] = this.formService.ProductPrefix;
      // this.TranslationLoaded = new BehaviorSubject(false);
      if(window['culture'] != null && window['culture'] != ''){
        this.translateService.currentLang = window['culture']; 
      }
     
      this.culture = this.culture;
      this.IsTrader = this.IsTrader;


      this.formService.getDeliveryAddresses().
      subscribe((data) => {
        // console.log(data, 'x');
        this.DeliveryMethods = data.DeliveryMethods;
        this.ContractorAddresses = data.ContractorAddresses;
        
        
      })

      this.formService.getOrderParameters(this.orderId).
      subscribe((data) => {
        this.Parametry = data;
        // console.log(this.Parametry);
        this.modyly = [];
        this.Parametry.Parameters.forEach(p => {
          if(p.Module > this.modyly.length){
            this.modyly.push(1);
          }
        });
        this.GetNg();
        this.wczytywanie = false;
      });
     
    });


    
  }

  getFileInfo(key){
    return 'plik_info_' + key.toLocaleLowerCase() + "_" + localStorage.site + "_" + this.culture;
  }
  WyjdzZEdycji(poz){
    this.podglad_poz = false;
  }
  EdytujPozycje(mod, poz){
    this.edytowany_mod = mod;
    this.edytowana_poz = poz;
    this.podglad_poz = true;
  }
  czyZawiera(key){
    var zawiera = false;
    if(key.includes('suma_')){
      zawiera = true;
    }
    if(key.includes('min_')){
      zawiera = true;
    }
    if(key.includes('max_')){
      zawiera = true;
    }

    return zawiera;
  }
  returnToOrders(): void {
      this.router.navigate(['Orders']);
  }
  GetNg(){
    this.naglowek = [];
    this.pozycja = [];
    this.Parametry.Parameters.forEach(p => {
      if(p.Module == this.modul + 1 && p.Type == 2){
        if(!this.czyZawiera(p.Key) && p.ControlName!= 'Hidden' && p.ControlName != 'Label' && p.DisplayValue != null && p.DisplayValue != ''){
          if(this.naglowek.findIndex(x => x == p.Key) < 0){
            this.naglowek.push(p.Key);
          }
          if(p.Position > this.pozycja.length){
            this.pozycja.push('');
          }
        }
      }
    });
  }
  GetLangValue(p){
    // console.log("jezyk", this.culture);
    switch (this.culture) {
      case 'pl':
        return p.DisplayValuePl;
        break;
      case 'de':
        return p.DisplayValueDe;
        break;
      case 'en':
        return p.DisplayValueEn;
        break;
      case 'fr':
        return p.DisplayValueFr;
        break;
      case 'fr':
        return p.DisplayValueCs;
        break;
      case 'it':
        return p.DisplayValueIt;
        break;
      default:
        return p.DisplayValue;
        break;
  }

    
  }
  GetValue(pozycja, key) {
    var poz;
    this.Parametry.Parameters.forEach(p => {
      if(p.Module == this.modul + 1 && p.Position == pozycja + 1 && p.Key == key){
            poz =this.GetLangValue(p);
      }
    });
    return poz;
  }
  
  ngOnDestroy(): void {
    // console.log("destroy");
    localStorage.site = "stale";
    window['site'] = "stale";
  }

  ZmienModul(numer){
    this.modul = numer;
    this.GetNg();
  }

}
