<div [formGroup]="Group" style="font-size: 12px;">
  <label>
    <span id="label_{{Control.label}}" [translation]="TranslationEnum.TranslationForm">
      {{('label_'+ Control.label)|translate}}
    </span>
  </label>
  <app-information [label]="Control.label" [notAffectOnOtherControl]='Control.notAffectToOtherControls'
  [fileInfoId]="Control.fileInfoId"></app-information>
  <div class="checkmulti">
    <mat-checkbox #checkbox color="primary" [checked]="EnabledSelect" [(ngModel)] = "EnabledSelect" class="d-inline mr-2"
    (change)="checkChanged($event);" [disabled] = "czyEnabled()"  [ngModelOptions]="{standalone: true}" ></mat-checkbox>
    <mat-form-field appearance="outline" class="w-100" color="accent" (click)="openSelectMultiColumnDialog()">
      
      <input autocomplete="off" #input matInput [formControlName]="Control.name" [value-object]="Control" type="text" [readonly]='true'>
      <mat-icon matPrefix [ngClass]="{'mat-accent-light': input.disabled}">create</mat-icon>
      <ng-container *ngFor="let validation of Control.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="Group.get(Control.name)?.hasError(validation.name)" id="{{validation.message}}"
          [translation]="TranslationEnum.TranslationForm">
          {{validation.message|translate}}
        </mat-error>
      </ng-container>
    </mat-form-field>
  </div>
 
</div>
