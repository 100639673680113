<div *ngIf="Control">
  <label>
    <span id="label_{{ Control.label }}" [translation]="TranslationEnum.TranslationForm">
      {{ 'label_' + Control.label | translate }}
    </span>
    <app-information [label]="Control.label" [notAffectOnOtherControl]="Control.notAffectToOtherControls" [fileInfoId]="Control.fileInfoId"></app-information>
  </label>

  <div class="w-100">
    <mat-checkbox [(ngModel)]="EnabledFile" color="primary" class="d-inline mr-2" (change)="checkChanged()" [disabled]="input.disabled"> </mat-checkbox>

    <div [formGroup]="Group" class="d-inline">
      <div style="display: inline-flex">
        <label class="file" [ngClass]="{ 'file-disabled': !EnabledFile || input.disabled, 'file-invalid': Group.get(Control.name)?.hasError('required') }">
          <input
            #input
            [formControlName]="Control.name"
            type="file"
            (change)="changeImg($event)"
            accept="image/png, image/jpeg, image/gif, image/bmp, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain"
          />
          <span class="file-custom">
            <span class="file-custom-before">
              <mat-icon>file_upload</mat-icon>
            </span>
            <span class="file-custom-after">{{ DisplayValue }}</span>
          </span>
        </label>
        <div [hidden]="!EnabledFile || input.disabled || !DisplayValue" (click)="previewFile()" class="mt-auto ml-auto icon-hover" [ngClass]="{ 'file-disabled': !EnabledFile || input.disabled}">
            <span style="cursor: pointer">
              <mat-icon>remove_red_eye</mat-icon>
            </span>
        </div>
      </div>
      <div>

      </div>

      <div class="mat-form-field mat-form-field-appearance-outline d-block">
        <div class="mat-form-field-wrapper">
          <div class="mat-form-field-subscript-wrapper" ng-reflect-ng-switch="error">
            <div class="ng-tns-c117-13 ng-trigger ng-trigger-transitionMessages ng-star-inserted" style="opacity: 1; transform: translateY(0%)">
              <ng-container *ngFor="let validation of Control.validations" ngProjectAs="mat-error">
                <mat-error *ngIf="Group.get(Control.name)?.hasError(validation.name)" id="{{ validation.message }}" [translation]="TranslationEnum.TranslationForm">
                  {{ validation.message | translate }}
                </mat-error>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
