import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';

@Component({
  selector: 'app-select-color',
  templateUrl: './select-color.component.html',
  styleUrls: ['./select-color.component.scss']
})
export class SelectColorComponent implements OnInit {

  TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.Group.get(this.Control.name).setValue(this.Control.value);

    this.Control.options.forEach(element => {
      if (element.Key === this.Control.value) {
        this.Control.value = element;
        this.Group.get(this.Control.name).setValue(element);
      }
    });
  }
}
