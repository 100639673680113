import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { VisualizationDialogData } from '../model/visualization-dialog.model';

@Component({
  selector: 'app-visualization-dialog',
  templateUrl: './visualization-dialog.component.html',
  styleUrls: ['./visualization-dialog.component.scss'],
})
export class VisualizationDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('variantic', { read: ElementRef }) variantic: ElementRef;
  public TranslationEnum = TranslationEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public data: VisualizationDialogData) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      for (const key of Object.keys(this.variantic.nativeElement.player.parametry)) {
        if (this.data.FromGroupForVisualization.controls[key] !== undefined && key === this.data.FromGroupForVisualization.controls[key].value.name) {
          const value = this.data.FromGroupForVisualization.controls[key].value.value;
          if(value.Key === undefined) {
            this.variantic.nativeElement.player.mediator.changeParameter(key, this.data.FromGroupForVisualization.controls[key].value.value);
          } else {
            this.variantic.nativeElement.player.mediator.changeParameter(key, this.data.FromGroupForVisualization.controls[key].value.value.Key);
          }
        }
      }
      // console.log('playerDOM', this.variantic.nativeElement.player.parametry, this.variantic.nativeElement.player);
    }, 2000);
  }

  check(): void {
    for (const key of Object.keys(this.variantic.nativeElement.player.parametry)) {
      if (this.data.FromGroupForVisualization.controls[key] !== undefined && key === this.data.FromGroupForVisualization.controls[key].value.name) {
        // this.variantic.nativeElement.player.parametry[key].value = this.data.FromGroupForVisualization.controls[key].value.value;
        this.variantic.nativeElement.player.mediator.changeParameter(key, this.data.FromGroupForVisualization.controls[key].value.value);
      }
    }
    // console.log('playerDOM', this.variantic.nativeElement.player.parametry, this.variantic.nativeElement.player);
  }
}
