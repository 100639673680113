import { HttpClient, HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';
import { Uwagi } from './uwagi';

export class Check {
  public wartosc;
  public check = false;
    constructor(private id, uwagi, public KeyName, Typ, private tresci, Parameters) {
      if (this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName) !== null) {
        this.wartosc = this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName);
      } else {
        if (Parameters != null ) {
          this.wartosc = Parameters;
        } else {
          this.wartosc = 'NIE';
        }
      }
      this.sprawdz_check();
    }
    public sprawdz_check() {
      if (this.wartosc === 'TAK') {
        this.check = true;
      } else {
        this.check = false;
      }
    }
    public zmien() {
      if (this.wartosc === 'TAK') {
        this.wartosc = 'NIE';
      } else {
        this.wartosc = 'TAK';
      }
      this.sprawdz_check();
      this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, this.wartosc);
    }
    public poza() {
    }

    public getActual() {
      return this.wartosc;
    }
    public getDisplay() {
      return this.wartosc;
    }



}

