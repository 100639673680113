<span class="dialog-possitions">
  <h2 mat-dialog-title>
    <span id="dodaj" [translation]="TranslationEnum.TranslationForm">{{'dodaj' | translate}}</span>
    <span> {{this.data.PositionId}}</span>
    <span id="pozycje_dod" [translation]="TranslationEnum.TranslationForm"> {{'pozycje_dod' | translate}}</span>
    <span *ngIf="data.Set !== undefined">
      -
      <span id='zestaw' [translation]="TranslationEnum.TranslationForm">{{'zestaw' | translate}} </span>
      <span> {{data.Set + 1}}</span>
    </span>
    <button mat-icon-button color="primary" class="float-right mr-3" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <div *ngIf="ShowSpinnerVerify"
    class="mt-5 justify-content-center align-items-center overlay-spinner">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>

    <span id="weryfikacja" [translation]="TranslationEnum.TranslationForm">
      {{'weryfikacja'|translate}}
    </span>
  </div>
  <mat-dialog-content id="scroll">
    <ng-template #moduleForm></ng-template>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="w-100" *ngIf="EndFormPosition || this.data.KindOper === 1">
      <button mat-flat-button color="primary" [hidden]="loadPositionForVerify" class="float-right mr-3" (click)="createColumnNames(true)" [disabled]="DisabledSavePositionButton"
        id="zapisz_pozcyje" [translation]="TranslationEnum.TranslationForm">
        {{'zapisz_pozcyje'|translate}}
      </button>
      <button mat-flat-button color="primary" [hidden]="!this.ShowTechnologyPermission" (click)="showTechnologyValuation()" class="float-right mr-3"
        id="wyniki_wyceny" [translation]="TranslationEnum.TranslationForm">
        {{'wyniki_wyceny'|translate}}
      </button>
    </div>
  </mat-dialog-actions>
</span>
