export class MailWithOrder {
  Id: number;
  CultureId: string;
  To: string;
  CC: string;
  Subject: string;
  Content?: string;
  Valuation: boolean;
  Specification: boolean;
 }
