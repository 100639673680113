
<span class="editable" *ngIf="tresci.user.IsPermission && typ !== 'info' && typ !== 'h2'">
  <span contenteditable="true" class="tresc_editable tresc_{{generate_id}} pole_txt_{{typ}}" id="tresc_{{generate_id}}" #pole_tresc  [innerHTML]="tresci.tresc(id, typ)"></span>
    <span class="zapisz_editable zapisz_editable_zmienne" (click)="zapisz(); $event.stopPropagation();" >
      Zapisz
    </span>
</span>

<span *ngIf="!tresci.user.IsPermission && typ !== 'info' && typ !== 'h2' && (typ !== 'bezpustych' || tresci.czy_pelne(id, typ))" [innerHTML]="tresci.tresc(id, typ)">
</span>

<span *ngIf="typ === 'info' && (tresci.user.IsPermission || tresci.czy_pelne(id, 'bezpustych'))" >
    <div class="info">
      <div #kratka class="tresc tresc_{{tresci.user.IsPermission}}_{{otwarte}}" [style.right]="getOffset()" (click)="otwarte = true;" (mouseover)="drag();getPositionH()" (mouseout) = "usunok = false;">
          <app-tlumacz [id]="id" [typ] = "'bezpustych'" [tresci] = "tresci" ></app-tlumacz>
          <div *ngIf="tresci.user.IsPermission" class="zamknij"  (click)="otwarte = false; $event.stopPropagation()">X</div>
          <div *ngIf="tresci.user.IsPermission || tresci.czy_pelne(id, 'bezpustych')" class="plik">
            <div *ngIf="plik != null && plik.czyImage();"><img *ngIf="plik.tresc != null"  [src] = "plik.tresc" /></div>
            <div class="link" *ngIf="plik != null && plik.type != null && plik.url != null && !plik.czyImage();">
              <a target = "_blank" href="{{plik.getUrl()}}" ><app-tlumacz [id]="'link_' + id" [typ] = "'stale'" [tresci] = "tresci"></app-tlumacz></a>
            </div>
            <input  *ngIf="tresci.user.IsPermission" type="file"  id="avatar" name="avatar"  (change)="fileChangeEvent($event);" >
            <div class="usun_plik" *ngIf="tresci.user.IsPermission && plik != null && plik.tresc != null" (click) ="usunPlik()">
              {{usunTyp()}}
            </div>
          </div>
      </div>
    </div>

  </span>
  <span *ngIf="typ === 'h2' && (tresci.user.IsPermission || tresci.czy_pelne(id, 'bezpustych'))" >
    <h2>| <span>
        <app-tlumacz [id]="id" [typ] = "'bezpustych'" [tresci] = "tresci" ></app-tlumacz>
    </span></h2>
  </span>
