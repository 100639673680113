import { ManagePositions } from './../shared/models/form/manage-positions.model';
import { GrupaModul } from './grupamodul';
import { Krok } from './krok';
import { Konfigurator } from './konfigurator';
import { deserialize, serialize } from 'class-transformer';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpErrorResponse
} from '@angular/common/http';
import { TresciService } from '../tresci.service';
import { $ } from 'protractor';
import { Observable, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
export class Kroki {
  public Krok: Krok[] = [];
  public CurrentStep = '';
  public pozycjaKrok = 0;
  public Last = false;
  public wczytywanie = false;
  public id = 0;
  public poprawny = false;
  public cena = null;
  public idModule = null;
  public isLoaded = false;
  public StepForHidden: number;
  private isEdit: boolean;


  public TmpArray = [];

  public czas_tmp;
  public resultTechInfo: any;
  public failedLoadForm: boolean;
  public $HiddenStep: BehaviorSubject<boolean>;

  private enterTwice = false;

  // tslint:disable-next-line:max-line-length
  constructor(
    public tresci: TresciService,
    public pozycja,
    public modul,
    public FirstStep = null,
    public ParametryPrzed = [],
    public PreviewParameters = []
  ) {
    this.id = this.tresci.getNewId();
    this.$HiddenStep = new BehaviorSubject<boolean>(false);
  }
  public poza() {
    this.Krok.forEach(element => {
      element.Konfigurator.poza();
    });
    if (this.Krok[this.Krok.length - 1] != null) {
      //  this.Krok[this.Krok.length - 1].Konfigurator.czyWiz();
    }
  }
  public Wartosc(Key) {
    let wartosc = null;
    this.Krok.forEach(k => {
      const x = k.Wartosc(Key);
      if (x !== null) {
        wartosc = x;
      }
    });
    return wartosc;
  }

  public getStrukt() {
    const strukt = [];
    this.Krok.forEach(element => {
      strukt.push(element.Tech);
    });
    return strukt;
  }
  public setStrukt(strukt, idModule, idPoz, ParametryW, ParametryWKim) {
    for (let index = 0; index < strukt.length; index++) {
      this.wczytajKrokZStrukt(
        index,
        ParametryW,
        idModule,
        idPoz,
        ParametryWKim,
        strukt[index]
      );
    }
    // this.wczytajKrokZStrukt(strukt.length, ParametryW, idModule, idPoz, ParametryWKim, nextStrukt);
  }
  public WartoscKod(Key) {
    let wartosc = null;
    this.Krok.forEach(k => {
      const x = k.WartoscKod(Key);
      if (x !== null) {
        wartosc = x;
      }
    });
    return wartosc;
  }
  public setFirstStep(FirstStep, Parametry, PreviewParameters = []) {
    this.FirstStep = FirstStep;
    this.ParametryPrzed = Parametry;
    this.PreviewParameters = PreviewParameters;
  }
  public getParams(max = null) {

    for (let index = this.Krok.length - 1; index > 0; index--) {
      if (index >= this.pozycjaKrok) {
        this.Krok.splice(index, 1);
      }
    }
    const parametry = [];



    this.ParametryPrzed.forEach(element => {
      if (element.Module === 1 && element.Type === 1) {
        if (sessionStorage.getItem(element.Key) !== element.Key) {
          sessionStorage.setItem(element.Key, JSON.stringify(element));
        } else {
          sessionStorage.removeItem(element.Key);
          sessionStorage.setItem(element.Key, JSON.stringify(element));
        }
      }
      parametry.push(element);
    });


    if (max === null) {
      max = this.Krok.length;
    }


    for (let index = 0; index < max; index++) {
      this.Krok[index].Konfigurator.kontrolki.forEach(dana => {
        // tslint:disable-next-line: max-line-length
        if (
          dana.ControlType !== 'NewLine' &&
          dana.ControlType !== 'Foto'
          && dana.ControlType !== 'Label'
        ) {
          parametry.push({
            Key: dana.KeyName,
            Value: dana.getValue(),
            DisplayValue: dana.getDisplayValue(),
            DisplayValuePl: dana.getDisplayValue('1'),
            DisplayValueEn: dana.getDisplayValue('2'),
            DisplayValueDe: dana.getDisplayValue('3'),
            DisplayValueFr: dana.getDisplayValue('4'),
            Position: this.pozycja,
            Module: this.modul,
            Type: this.getTyp(this.Krok[index]),
            IsPrint: dana.IsPrint
          });
        }
      });

    }

    // this.TmpArray.forEach(element => {
    //   parametry.push(element);
    // });
    /* this.Krok.forEach(element => {
      element.Konfigurator.kontrolki.forEach(dana => {
        // tslint:disable-next-line: max-line-length
        if (
          dana.ControlType !== 'NewLine' &&
          dana.ControlType !== 'Foto' &&
          dana.ControlType !== 'Label'
        ) {
          parametry.push({
            Key: dana.KeyName,
            Value: dana.getValue(),
            DisplayValue: dana.getDisplayValue(),
            DisplayValuePl: dana.getDisplayValue('1'),
            DisplayValueEn: dana.getDisplayValue('2'),
            DisplayValueDe: dana.getDisplayValue('3'),
            DisplayValueFr: dana.getDisplayValue('4'),
            Position: this.pozycja,
            Module: this.modul,
            Type: this.getTyp(element),
            IsPrint: dana.IsPrint
          });
        }
      });
    }); */
    parametry.forEach(element => {
      if (element.Value) {
        switch (element.Key) {
          case 'Czywprowadzazleceniehandlowiec':
            if (element.Value.toLowerCase() === 'tak') {
              this.tresci.ManagePositions.IsTrader = true;
            } else {
              this.tresci.ManagePositions.IsTrader = false;
            }
            break;
          case 'CzyNiestandardowaModułowość':
            if (element.Value.toLowerCase() === 'tak') {

              this.tresci.ManagePositions.IsCustomModularity = true;
            } else {
              this.tresci.ManagePositions.IsCustomModularity = false;
            }
            break;
          case 'CzyModuł':
              if (element.Value.toLowerCase() === 'tak') {
                this.tresci.ManagePositions.IsModuleProduct = true;
              } else {
                this.tresci.ManagePositions.IsModuleProduct = false;
              }
            break;
        }
      }
    });

    return parametry;
  }
  public getParamsKim() {
    const parametry = [];
    this.Krok.forEach(element => {
      element.Konfigurator.kontrolki.forEach(dana => {
        // tslint:disable-next-line: max-line-length
        if (dana.ControlType === 'Kim') {
          dana.Kim.getList().forEach(elem => {
            elem.Position = this.pozycja;
            elem.Module = this.modul;
            parametry.push(elem);
          });
        }
      });
    });
    return parametry;
  }
  public getTyp(Krok1: Krok) {
    if (Krok1.Type === 'Header') {
      return 0;
    } else if (Krok1.Type === 'ModuleHeader') {
      return 1;
    } else if (Krok1.Type === 'Position') {
      return 2;
    } else if (Krok1.Type === 'ModuleFooter') {
      return 3;
    }

    return 0;
  }
  public getParamsSoft() {
    const parametry = [];
    this.ParametryPrzed.forEach(element => {
      parametry.push(element);
    });
    this.Krok.forEach(element => {
      element.Konfigurator.kontrolki.forEach(dana => {
        if (
          dana.ControlType !== 'NewLine' &&
          dana.ControlType !== 'Foto' &&
          dana.ControlType !== 'Label'
        ) {
          // tslint:disable-next-line: max-line-length
          parametry.push({
            Key: dana.KeyName,
            Value: dana.getValue(),
            DisplayValue: dana.getDisplayValue(),
            DisplayValuePl: dana.getDisplayValue('1'),
            DisplayValueEn: dana.getDisplayValue('2'),
            DisplayValueDe: dana.getDisplayValue('3'),
            DisplayValueFr: dana.getDisplayValue('4'),
            Position: this.pozycja,
            Module: this.modul,
            Type: this.getTyp(element),
            IsPrint: dana.IsPrint
          });
        }
      });
    });
    return parametry;
  }
  public getParamsSoftWybrane() {
    const parametry = [];
    // this.ParametryPrzed.forEach(element => {
    //   parametry.push(element);
    // });
    this.Krok.forEach(element => {
      element.Konfigurator.kontrolki.forEach(dana => {
        if (
          dana.ControlType !== 'NewLine' &&
          dana.ControlType !== 'Foto'
          && dana.ControlType !== 'Label'
        ) {
          // tslint:disable-next-line: max-line-length
          if (!dana.czyNieWybrany()) {
            // tslint:disable-next-line:max-line-length
            parametry.push({
              Key: dana.KeyName,
              Value: dana.getValue(),
              DisplayValue: dana.getDisplayValue(),
              DisplayValuePl: dana.getDisplayValue('1'),
              DisplayValueEn: dana.getDisplayValue('2'),
              DisplayValueDe: dana.getDisplayValue('3'),
              DisplayValueFr: dana.getDisplayValue('4'),
              Position: this.pozycja,
              Module: this.modul,
              Type: this.getTyp(element),
              IsPrint: dana.IsPrint
            });
          }
        }
      });
    });
    return parametry;
  }
  public getSelfParams(hidden = false, max = 10000) {
    for (let index = this.Krok.length - 1; index > 0; index--) {
      if (index >= this.pozycjaKrok) {
        this.Krok.splice(index, 1);
      }
    }
    const parametry = [];
    this.Krok.forEach(element => {
      element.Konfigurator.kontrolki.forEach(dana => {
        // tslint:disable-next-line: max-line-length
        if (dana.ControlType !== 'Hidden' || hidden) {
          if (
            dana.ControlType !== 'NewLine' &&
            dana.ControlType !== 'Foto' &&
            dana.ControlType !== 'Label'
          ) {
            // tslint:disable-next-line:max-line-length
            parametry.push({
              Key: dana.KeyName,
              Value: dana.getValue(),
              DisplayValue: dana.getDisplayValue(),
              DisplayValuePl: dana.getDisplayValue('1'),
              DisplayValueEn: dana.getDisplayValue('2'),
              DisplayValueDe: dana.getDisplayValue('3'),
              DisplayValueFr: dana.getDisplayValue('4'),
              Position: this.pozycja,
              Module: this.modul,
              Type: this.getTyp(element),
              IsPrint: dana.IsPrint
            });
          }
        }
      });
    });
    return parametry;
  }
  public getSelfParamsKim(hidden = false, max = 10000) {
    const parametry = [];
    this.Krok.forEach(element => {
      element.Konfigurator.kontrolki.forEach(dana => {
        // tslint:disable-next-line: max-line-length
        if (dana.ControlType === 'Kim') {
          // tslint:disable-next-line:max-line-length
          dana.Kim.getList().forEach(elem => {
            elem.Position = this.pozycja;
            elem.Module = this.modul;
            parametry.push(elem);
          });
        }
      });
    });
    return parametry;
  }

  public LastStep() {
    if (this.Last) {
      return this.Krok[this.Krok.length - 1].Nazwa;
    } else {
      return null;
    }
  }
  public LastStepMod() {
    if (!this.tresci.zamowienie.IsModule) {
      return this.tresci.zamowienie.naglowek.LastStep();
    } else {
      if (this.Last) {
        return this.Krok[this.Krok.length - 1].Nazwa;
      } else {
        return null;
      }
    }
  }
  public CzyPoprawny() {
    let poprawny = true;
    this.Krok.forEach(k => {
      if (!k.poprawny()) {
        poprawny = false;
      }
    });
    return poprawny;
  }

  public getCena() {
    const Parametry = this.getParams();
    const ProductPrefix = this.tresci.getPrefix();
    let dane = null;
    let ContractorNumber = this.tresci.user.ContractorNumber;
    if (ContractorNumber == null) {
      // ContractorNumber = '00001';
    }
    dane = {
      ProductPrefix: ProductPrefix,
      ContractorNumber: ContractorNumber,
      Parameters: Parametry
    };

    const body = serialize(dane);
    // tslint:disable-next-line:max-line-length
    const request = new HttpRequest(
      'POST',
        '/api/ActiveForm/GetValuation',
      dane,
      { headers: this.tresci.user.getHeaderJson() }
    );
    this.tresci.http.request(request).pipe(/*take(1)*/).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.cena = event.body;
        }
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          // this.wczytywanie = false;
          // if (this.pozycjaKrok > 0) {
          // this.pozycjaKrok--;
          // }
          this.tresci.exception.exception('Błąd wczytywania wyceny', err);
          // this.poprawny = false;
          // this.Last = false;
        }
      }
    );
  }

  public sprawdzPoprawnosc(ParametryWejsciowe = []) {
    if (
      !this.tresci.zamowienie.IsModule &&
      this.modul > 0 &&
      this.pozycja === 0
    ) {
      this.poprawny = true;
    } else {
      this.sprawdzKrok(0, ParametryWejsciowe);
    }
  }

  public wczytajKrokZStrukt(
    numer,
    ParametryWejsciowe,
    idModule = null,
    idPoz = null,
    ParametryWejscioweKim = [],
    event_body
  ) {
    // this.tresci.zamowienie.wczytuje_z_paramwtrow = true;
    this.tresci.zamowienie.wczytujeParam(this.modul, this.pozycja);
    this.tresci.zamowienie.lastMod = this.tresci.zamowienie.parametryWczytane[
      this.tresci.zamowienie.parametryWczytane.length - 1
    ].Module;
    this.tresci.zamowienie.lastPoz = this.tresci.zamowienie.parametryWczytane[
      this.tresci.zamowienie.parametryWczytane.length - 1
    ].Position;
    this.tresci.zamowienie.ModulWczyt = this.modul;
    this.tresci.zamowienie.PozWczyt = this.pozycja;
    this.pozycjaKrok = numer;
    const ProductPrefix = this.tresci.getPrefix();

    this.czas_tmp = new Date().getTime();
    // tslint:disable-next-line:max-line-length

    this.KrokZParametrami_Strukt(
      event_body,
      numer,
      ParametryWejsciowe,
      ParametryWejscioweKim,
      idModule,
      idPoz,
      ProductPrefix
    );
  }

  public KrokZParametrami_Strukt(
    event_body,
    numer,
    ParametryWejsciowe,
    ParametryWejscioweKim,
    idModule,
    idPoz,
    ProductPrefix
  ) {
    this.tresci.zamowienie.ParamWczytany(this.modul, this.pozycja);
    event_body.Controlls = this.poprawKrok(event_body.Controlls);

    if (numer < 1 || event_body.Type === this.Krok[numer - 1].Type) {
      this.Last = false;
      this.tresci.zamowienie.IsModuleFooter = event_body.IsModuleFooter;
      this.tresci.zamowienie.IsFooter = event_body.IsFooter;
      this.tresci.zamowienie.IsModule = event_body.IsModule;
      // tslint:disable-next-line:max-line-length
      // this.Krok[this.pozycjaKrok] = new Krok(event.body.Step, event.body.Controlls, event.body.IsLastStep, this.tresci.http, ProductPrefix, this.tresci, event.body.Type, this.id);

      // tslint:disable-next-line:max-line-length
      this.Krok[this.pozycjaKrok] = new Krok(
        event_body,
        event_body.Step,
        event_body.Controlls,
        event_body.IsLastStep,
        this.tresci.http,
        ProductPrefix,
        this.tresci,
        event_body.Type,
        this.id,
        this,
        this.modul,
        this.pozycja,
        ParametryWejsciowe,
        ParametryWejscioweKim
      );
      this.poprawny = false;
      if (this.Krok[this.pozycjaKrok].czyUzupelnione()) {
        // this.wczytajKrokZparametrami(numer + 1, ParametryWejsciowe, idModule, idPoz, ParametryWejscioweKim);
      } else {
        if (idModule != null) {
          // this.tresci.zamowienie.grupa_modul[idModule].wczytajPozycje(idPoz + 1, true);
        }

        // if (this.tresci.zamowienie.lastMod === this.modul && this.tresci.zamowienie.lastPoz === this.pozycja) {
        // this.tresci.zamowienie.wczytuje_z_paramwtrow = false;

        // }
      }
    } else {

      // if (this.tresci.zamowienie.lastMod === this.modul && this.tresci.zamowienie.lastPoz === this.pozycja) {
      // this.tresci.zamowienie.wczytuje_z_paramwtrow = false;

      // }
      // pierwsza grupa modułów
      this.poprawny = true;
      // this.tresci.zamowienie.ustawPoprawnosc();
      // this.getCena();
      this.Last = true;
      if (this.Krok[numer - 1].Type === 'Header') {
        // this.tresci.zamowienie.wczytajGrupyModulow();
        // this.tresci.zamowienie.sprawdzPoprawnosc();
        this.tresci.zakladka = 1;
        this.tresci.zmien_zapisz();
        //  if (this.tresci.zamowienie.grupa_modul != null && this.tresci.zamowienie.grupa_modul.length >= 1) {
        //    this.tresci.zamowienie.grupa_modul[this.modul - 1].sprawdzPoprawnosc();
        //  }
      } else if (event_body.Type === 'Position') {
        if (
          idModule != null &&
          this.tresci.zamowienie.grupa_modul != null &&
          this.tresci.zamowienie.grupa_modul[idModule] != null
        ) {
          // this.tresci.zamowienie.grupa_modul[idModule].wczytajPozycje(1, true);
        }
      } else if (
        this.Krok[numer - 1].Type === 'Position' &&
        this.tresci.zamowienie.grupa_modul[idModule] != null
      ) {
        if (idModule != null && this.tresci.zamowienie.grupa_modul != null) {
          // this.tresci.zamowienie.grupa_modul[idModule].wczytajPozycje(idPoz + 1, true);
        }
        // this.tresci.zamowienie.grupa_modul[idModule].sprawdzPoprawnosc(ParametryWejsciowe);
      }
      if (
        event_body.Type === 'ModuleFooter' &&
        this.Krok[this.pozycjaKrok - 1].Type === 'Position'
      ) {
        if (
          this.tresci.zamowienie.max_pozycja_w_module != null &&
          this.tresci.zamowienie.max_pozycja_w_module[this.modul] != null &&
          this.tresci.zamowienie.max_pozycja_w_module[this.modul].Max ===
            this.pozycja
        ) {
          // this.tresci.zamowienie.grupa_modul[this.modul - 1].ustawStopke(true);
        }
      } else if (event_body.Type === 'Footer') {
        if (
          this.tresci.zamowienie.max_pozycja_w_module != null &&
          this.tresci.zamowienie.max_pozycja_w_module.length ===
            this.modul - 1 &&
          this.tresci.zamowienie.max_pozycja_w_module[this.modul].Max ===
            this.pozycja
        ) {
          // this.tresci.zamowienie.ustawStopke(this.Krok[this.pozycjaKrok - 1].Nazwa, true);
        }
      }

    }
  }

  public wczytajKrokZparametrami(
    numer,
    ParametryWejsciowe,
    idModule = null,
    idPoz = null,
    ParametryWejscioweKim = []
  ) {
    // this.tresci.zamowienie.wczytuje_z_paramwtrow = true;
    this.tresci.zamowienie.wczytujeParam(this.modul, this.pozycja);
    this.tresci.zamowienie.lastMod = this.tresci.zamowienie.parametryWczytane[
      this.tresci.zamowienie.parametryWczytane.length - 1
    ].Module;
    this.tresci.zamowienie.lastPoz = this.tresci.zamowienie.parametryWczytane[
      this.tresci.zamowienie.parametryWczytane.length - 1
    ].Position;
    this.tresci.zamowienie.ModulWczyt = this.modul;
    this.tresci.zamowienie.PozWczyt = this.pozycja;
    this.pozycjaKrok = numer;
    const Parametry = this.getParams();
    const ProductPrefix = this.tresci.getPrefix();
    let dane = null;

    const ContractorNumber = this.tresci.user.ContractorNumber;

    if (numer === 0 && this.FirstStep == null) {
      dane = {
        ProductPrefix: ProductPrefix,
        ContractorNumber: ContractorNumber,
        Iso639: this.tresci.jezyk,
        IsTrader: this.tresci.user.IsTrader,
        IsCache: false
      };
    } else if (numer === 0) {
      dane = {
        ProductPrefix: ProductPrefix,
        CurrentStep: this.FirstStep,
        Parameters: Parametry,
        PreviewParameters: this.PreviewParameters,
        ContractorNumber: ContractorNumber,
        Iso639: this.tresci.jezyk,
        IsTrader: this.tresci.user.IsTrader,
        IsCache: true
      };
    } else {
      dane = {
        ProductPrefix: ProductPrefix,
        CurrentStep: this.Krok[numer - 1].Nazwa,
        Parameters: Parametry,
        PreviewParameters: this.PreviewParameters,
        ContractorNumber: ContractorNumber,
        Iso639: this.tresci.jezyk,
        IsTrader: this.tresci.user.IsTrader,
        IsCache: true
      };
    }
    const body = serialize(dane);
    this.czas_tmp = new Date().getTime();
    // tslint:disable-next-line:max-line-length
    const request = new HttpRequest(
      'POST',
        '/api/ActiveForm/GetStep',
      dane,
      { headers: this.tresci.user.getHeaderJson() }
    );
    this.tresci.http.request(request).pipe(/*take(1)*/).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:

            this.KrokZParametrami_in(
              dane,
              event,
              numer,
              ParametryWejsciowe,
              ParametryWejscioweKim,
              idModule,
              idPoz,
              ProductPrefix
            );

            this.tresci.LastTechVersion = event.body.TechVersion;
        }
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.wczytywanie = false;
          if (this.pozycjaKrok > 0) {
            this.pozycjaKrok--;
          }

          this.tresci.zamowienie.isLoaded = true;
          this.tresci.exception.exception('Błąd wczytywania formularza', err);
        }
      }
    );
  }

  public KrokZParametrami_in(
    dane,
    event,
    numer,
    ParametryWejsciowe,
    ParametryWejscioweKim,
    idModule,
    idPoz,
    ProductPrefix
  ) {
    if (dane.CurrentStep != null) {
      this.tresci.copyMessage(
        serialize(event.body),
        'Czas pobierania (z parametrami) kroku ' +
          dane.CurrentStep +
          '(' +
          (new Date().getTime() - this.czas_tmp) +
          ')',
        1
      );
    } else {
      this.tresci.copyMessage(
        serialize(event.body),
        'Czas pobierania (z parametrami) kroku 0' +
          '(' +
          (new Date().getTime() - this.czas_tmp) +
          ')',
        1
      );
    }

    event.body.Controlls = this.poprawKrok(event.body.Controlls);

    if (numer < 1 || event.body.Type === this.Krok[numer - 1].Type) {
      this.Last = false;
      this.tresci.zamowienie.IsModuleFooter = event.body.IsModuleFooter;
      this.tresci.zamowienie.IsFooter = event.body.IsFooter;
      this.tresci.zamowienie.IsModule = event.body.IsModule;
      // tslint:disable-next-line:max-line-length
      // this.Krok[this.pozycjaKrok] = new Krok(event.body.Step, event.body.Controlls, event.body.IsLastStep, this.tresci.http, ProductPrefix, this.tresci, event.body.Type, this.id);

      // tslint:disable-next-line:max-line-length
      this.Krok[this.pozycjaKrok] = new Krok(
        event.body,
        event.body.Step,
        event.body.Controlls,
        event.body.IsLastStep,
        this.tresci.http,
        ProductPrefix,
        this.tresci,
        event.body.Type,
        this.id,
        this,
        this.modul,
        this.pozycja,
        ParametryWejsciowe,
        ParametryWejscioweKim
      );
      this.poprawny = false;
      if (this.Krok[this.pozycjaKrok].czyUzupelnione()) {
        this.wczytajKrokZparametrami(
          numer + 1,
          ParametryWejsciowe,
          idModule,
          idPoz,
          ParametryWejscioweKim
        );
      } else {
        if (idModule != null) {
          this.tresci.zamowienie.grupa_modul[idModule].wczytajPozycje(
            idPoz + 1,
            true
          );
        }

        // if (this.tresci.zamowienie.lastMod === this.modul && this.tresci.zamowienie.lastPoz === this.pozycja) {
        // this.tresci.zamowienie.wczytuje_z_paramwtrow = false;
        this.tresci.zamowienie.ParamWczytany(this.modul, this.pozycja);
        // }
      }
    } else {

      // if (this.tresci.zamowienie.lastMod === this.modul && this.tresci.zamowienie.lastPoz === this.pozycja) {
      // this.tresci.zamowienie.wczytuje_z_paramwtrow = false;
      this.tresci.zamowienie.ParamWczytany(this.modul, this.pozycja);
      // }
      // pierwsza grupa modułów
      this.poprawny = true;
      // this.tresci.zamowienie.ustawPoprawnosc();
      // this.getCena();
      this.Last = true;

      if (this.Krok[numer - 1].Type === 'Header') {
        this.tresci.zamowienie.wczytajGrupyModulow();
        // this.tresci.zamowienie.sprawdzPoprawnosc();
        this.tresci.zakladka = 1;
        this.tresci.zmien_zapisz();
        //  if (this.tresci.zamowienie.grupa_modul != null && this.tresci.zamowienie.grupa_modul.length >= 1) {
        //    this.tresci.zamowienie.grupa_modul[this.modul - 1].sprawdzPoprawnosc();
        //  }
      } else if (event.body.Type === 'Position') {
        if (
          idModule != null &&
          this.tresci.zamowienie.grupa_modul != null &&
          this.tresci.zamowienie.grupa_modul[idModule] != null
        ) {
          this.tresci.zamowienie.grupa_modul[idModule].wczytajPozycje(1, true);
        }
      } else if (
        this.Krok[numer - 1].Type === 'Position' &&
        this.tresci.zamowienie.grupa_modul[idModule] != null
      ) {
        if (idModule != null && this.tresci.zamowienie.grupa_modul != null) {
          this.tresci.zamowienie.grupa_modul[idModule].wczytajPozycje(
            idPoz + 1,
            true
          );
        }
        // this.tresci.zamowienie.grupa_modul[idModule].sprawdzPoprawnosc(ParametryWejsciowe);
      }
      if (
        event.body.Type === 'ModuleFooter' &&
        this.Krok[this.pozycjaKrok - 1].Type === 'Position'
      ) {
        if (
          this.tresci.zamowienie.max_pozycja_w_module != null &&
          this.tresci.zamowienie.max_pozycja_w_module[this.modul] != null &&
          this.tresci.zamowienie.max_pozycja_w_module[this.modul].Max ===
            this.pozycja
        ) {
          this.tresci.zamowienie.grupa_modul[this.modul - 1].ustawStopke(true);
        }
      } else if (event.body.Type === 'Footer') {
        //  if (this.tresci.zamowienie.max_pozycja_w_module != null && this.tresci.zamowienie.max_pozycja_w_module.length === this.modul - 1 && this.tresci.zamowienie.max_pozycja_w_module[this.modul].Max === this.pozycja) {
        this.tresci.zamowienie.grupa_modul[this.modul - 1].ustawStopke();
        this.tresci.zamowienie.ustawStopke(
          this.Krok[this.pozycjaKrok - 1].Nazwa,
          true
        );
        //  }
      } else if (event.body.Step === '') {
        //  if (this.tresci.zamowienie.max_pozycja_w_module != null && this.tresci.zamowienie.max_pozycja_w_module.length === this.modul - 1 && this.tresci.zamowienie.max_pozycja_w_module[this.modul].Max === this.pozycja) {
         if (this.tresci.zamowienie.grupa_modul.length >= 1) {
           if (this.modul > 0) {
            this.tresci.zamowienie.grupa_modul[this.modul - 1].ustawStopke();
           } else {
            this.tresci.zamowienie.grupa_modul[0].ustawStopke();
           }
         } else {
         }
        /* this.tresci.zamowienie.ustawStopke(
          this.Krok[this.pozycjaKrok - 1].Nazwa,
          true
        ); */
        //  }
      }

    }
  }

  public sprawdzKrok(numer, ParametryWejsciowe = []) {
    const Parametry = this.getParams(numer);
    const ProductPrefix = this.tresci.getPrefix();
    let dane = null;
    const ContractorNumber = this.tresci.user.ContractorNumber;
    if (numer === 0 && this.FirstStep == null) {
      dane = {
        ProductPrefix: ProductPrefix,
        ContractorNumber: ContractorNumber,
        Iso639: this.tresci.jezyk,
        IsTrader: this.tresci.user.IsTrader,
        IsCache: this.tresci.CacheTech
      };
    } else if (numer === 0) {
      dane = {
        ProductPrefix: ProductPrefix,
        CurrentStep: this.FirstStep,
        Parameters: Parametry,
        PreviewParameters: this.PreviewParameters,
        ContractorNumber: ContractorNumber,
        Iso639: this.tresci.jezyk,
        IsTrader: this.tresci.user.IsTrader,
        IsCache: this.tresci.CacheTech
      };
    } else {
      dane = {
        ProductPrefix: ProductPrefix,
        CurrentStep: this.Krok[numer - 1].Nazwa,
        Parameters: Parametry,
        PreviewParameters: this.PreviewParameters,
        ContractorNumber: ContractorNumber,
        Iso639: this.tresci.jezyk,
        IsTrader: this.tresci.user.IsTrader,
        IsCache: this.tresci.CacheTech
      };
    }
    const body = serialize(dane);
    // tslint:disable-next-line:max-line-length
    const request = new HttpRequest(
      'POST',
        '/api/ActiveForm/GetStep',
      dane,
      { headers: this.tresci.user.getHeaderJson() }
    );
    this.tresci.http.request(request).pipe(/*take(1)*/).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.tresci.LastTechVersion = event.body.TechVersion;
            event.body.Controlls = this.poprawKrok(event.body.Controlls);
            if (
              numer < 1 ||
              (this.Krok[numer - 1] != null &&
                event.body.Type === this.Krok[numer - 1].Type)
            ) {
              // this.Last = false;
              this.tresci.zamowienie.IsModuleFooter = event.body.IsModuleFooter;
              this.tresci.zamowienie.IsFooter = event.body.IsFooter;
              this.tresci.zamowienie.IsModule = event.body.IsModule;
              // tslint:disable-next-line:max-line-length
              // this.Krok[this.pozycjaKrok] = new Krok(event.body, event.body.Step, event.body.Controlls, event.body.IsLastStep, this.tresci.http, ProductPrefix, this.tresci, event.body.Type, this.id);
              if (
                this.Krok[numer] != null &&
                this.Krok[numer].sprawdz(event.body.Controlls)
              ) {
                if (this.pozycjaKrok === numer) {
                  this.poprawny = false;
                  this.Last = false;
                  // this.Krok[this.pozycjaKrok].Konfigurator.poprawny = false;
                  this.tresci.zamowienie.ustawPoprawnosc();
                  // tslint:disable-next-line:max-line-length
                  this.Krok[this.pozycjaKrok] = new Krok(
                    event.body,
                    event.body.Step,
                    event.body.Controlls,
                    event.body.IsLastStep,
                    this.tresci.http,
                    ProductPrefix,
                    this.tresci,
                    event.body.Type,
                    this.id,
                    this,
                    this.modul,
                    this.pozycja,
                    ParametryWejsciowe
                  );
                } else {
                  this.sprawdzKrok(numer + 1);
                }
              } else {
                this.Last = false;
                this.pozycjaKrok = numer;
                // tslint:disable-next-line:max-line-length
                this.Krok[this.pozycjaKrok] = new Krok(
                  event.body,
                  event.body.Step,
                  event.body.Controlls,
                  event.body.IsLastStep,
                  this.tresci.http,
                  ProductPrefix,
                  this.tresci,
                  event.body.Type,
                  this.id,
                  this,
                  this.modul,
                  this.pozycja,
                  ParametryWejsciowe
                );
                this.poprawny = false;
                // this.Krok[this.pozycjaKrok].Konfigurator.poprawny = false;
                this.tresci.zamowienie.ustawPoprawnosc();
              }
            } else {
              // pierwsza grupa modułów
              this.poprawny = true;
              this.tresci.zamowienie.ustawPoprawnosc();
              // this.getCena();
              this.Last = true;
              if (event.body.Type === 'Position') {
                if (
                  this.tresci.zamowienie.grupa_modul != null &&
                  this.tresci.zamowienie.grupa_modul.length >= 1 &&
                  this.tresci.zamowienie.grupa_modul[this.modul - 1] != null
                ) {
                  this.tresci.zamowienie.grupa_modul[
                    this.modul - 1
                  ].sprawdzPoprawnosc();
                }
              }
            }
        }
      },
    );
  }

  public wczytajKrokResultInfo(numer) {
    if (!this.wczytywanie) {
      this.wczytywanie = true;
      const ContractorNumber = this.tresci.user.ContractorNumber;
      const ProductPrefix = this.tresci.getPrefix();
      if (ProductPrefix !== '') {
        this.pozycjaKrok = numer;
        const Parametry = this.getParams();

        if (sessionStorage.getItem('JakiOwalCube') && !Parametry.find(x => x.Key === 'JakiOwalCube')) {
          Parametry.push(JSON.parse(sessionStorage.getItem('JakiOwalCube')));
        }
        if (sessionStorage.getItem('CzyModuł') && !Parametry.find(x => x.Key === 'CzyModuł')) {
          Parametry.push(JSON.parse(sessionStorage.getItem('CzyModuł')));
        }
        if (sessionStorage.getItem('CzyNiestandardowaModułowość') && !Parametry.find(x => x.Key === 'CzyNiestandardowaModułowość')) {
          Parametry.push(JSON.parse(sessionStorage.getItem('CzyNiestandardowaModułowość')));
        }

        let dane = null;
        if (this.pozycjaKrok === 0 && this.FirstStep == null) {
          dane = {
            ProductPrefix: ProductPrefix,
            ContractorNumber: ContractorNumber,
            Iso639: this.tresci.jezyk,
            IsCache: false
          };
        } else if (this.pozycjaKrok === 0) {
          dane = {
            ProductPrefix: ProductPrefix,
            CurrentStep: this.FirstStep,
            Parameters: Parametry,
            PreviewParameters: this.PreviewParameters,
            ContractorNumber: ContractorNumber,
            Iso639: this.tresci.jezyk,
            IsCache: this.tresci.CacheTech
          };
        } else {

          dane = {
            ProductPrefix: ProductPrefix,
            CurrentStep: this.Krok[this.pozycjaKrok - 1].Nazwa,
            Parameters: Parametry,
            PreviewParameters: this.PreviewParameters,
            ContractorNumber: ContractorNumber,
            Iso639: this.tresci.jezyk,
            IsCache: this.tresci.CacheTech
          };
        }
        const body = serialize(dane);
      const request = new HttpRequest(
        'POST',
          '/api/ActiveForm/GetStepResultInfo',
        body,
        { headers: this.tresci.user.getHeaderJson() }
      );
      this.tresci.http.request(request).pipe(/*take(1)*/).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Response:
                this.resultTechInfo = event.body;
                this.isLoaded = true;
              if(this.resultTechInfo.MasterResult.KompilacjaBledy) {
                this.tresci.zamowienie.isLoaded = true;
              }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.wczytywanie = false;
          }
        }
      );
      }
    }
  }

  public dalej(numer, nastepny = true, isEdit = false) {
    if (!nastepny || !this.Krok[numer - 1].Konfigurator.czyNieWybrany()) {
      this.isEdit = isEdit;
      this.wczytajKrok(numer, nastepny);
    } else {
      this.tresci.exception.exception(
        'nie uzupełniono wszystkich wymaganych pól',
        'nie uzupełniono wszystkich wymaganych pól'
      );
    }
  }

  public wczytajKrok(numer, nastepny = true ) {
    this.Last = false;
    this.tresci.wizualizacja = [];
    this.tresci.wizualizacja_id = [];
    this.tresci.id_krok_wizual = -1;
    this.czas_tmp = new Date().getTime();

    if (!this.wczytywanie) {
      this.wczytywanie = true;
      const ContractorNumber = this.tresci.user.ContractorNumber;
      const ProductPrefix = this.tresci.getPrefix();
      if (ProductPrefix !== '') {
        if (nastepny) {
          this.pozycjaKrok = numer;
          // if (this.enterTwice) {
          //   this.tresci.$IsAfterPositions.next(true);
          // } else if (this.modul === 1 && this.pozycja === 0 && numer === 1) {
          //   this.enterTwice = true;
          //   this.tresci.$IsAfterPositions.next(false);
          // }

        } else {
          this.pozycjaKrok = numer - 1;
          if (this.modul <= 1 && this.pozycja === 0 && numer <= 1) {
            this.tresci.$IsAfterPositions.next(false);
          }
        }
        const Parametry = this.getParams();
        if (sessionStorage.getItem('JakiOwalCube') && !Parametry.find(x => x.Key === 'JakiOwalCube')) {
          Parametry.push(JSON.parse(sessionStorage.getItem('JakiOwalCube')));
        }
        if (sessionStorage.getItem('CzyModuł') && !Parametry.find(x => x.Key === 'CzyModuł')) {
          Parametry.push(JSON.parse(sessionStorage.getItem('CzyModuł')));
        }
        if (sessionStorage.getItem('CzyNiestandardowaModułowość') && !Parametry.find(x => x.Key === 'CzyNiestandardowaModułowość')) {
          Parametry.push(JSON.parse(sessionStorage.getItem('CzyNiestandardowaModułowość')));
        }

        let dane = null;
        if (this.pozycjaKrok === 0 && this.FirstStep == null) {
          dane = {
            ProductPrefix: ProductPrefix,
            ContractorNumber: ContractorNumber,
            Iso639: this.tresci.jezyk,
            IsTrader: this.tresci.user.IsTrader,
            IsCache: false
          };
        } else if (this.pozycjaKrok === 0) {
          dane = {
            ProductPrefix: ProductPrefix,
            IsTrader: this.tresci.user.IsTrader,
            CurrentStep: this.FirstStep,
            Parameters: Parametry,
            PreviewParameters: this.PreviewParameters,
            ContractorNumber: ContractorNumber,
            Iso639: this.tresci.jezyk,
            IsCache: this.tresci.CacheTech
          };
        } else {
          dane = {
            ProductPrefix: ProductPrefix,
            CurrentStep: this.Krok[this.pozycjaKrok - 1].Nazwa,
            Parameters: Parametry,
            PreviewParameters: this.PreviewParameters,
            ContractorNumber: ContractorNumber,
            IsTrader: this.tresci.user.IsTrader,
            Iso639: this.tresci.jezyk,
            IsCache: this.tresci.CacheTech
          };
        }
        const body = serialize(dane);

        if (this.isEdit && this.Krok[0].Type === 'Header') {
          if (this.tresci.zamowienie.grupa_modul.length > 0) {
            this.tresci.zamowienie.grupa_modul = [];
          }
        } else if (this.isEdit && this.Krok[0].Type === 'ModuleHeader') {
          if (this.tresci.zamowienie.grupa_modul.length > 0) {
            this.tresci.zamowienie.grupa_modul.forEach( x => x.pozycja = []);
          }
        }

        if (dane.CurrentStep != null) {
          this.tresci.copyMessage(
            body,
            'Kopia parametrów dla ' + dane.CurrentStep,
            1
          );
        } else {
          this.tresci.copyMessage(body, 'Kopia parametrów dla Kroku 0', 1);
        }
        const request = new HttpRequest(
          'POST',
            '/api/ActiveForm/GetStep',
          dane,
          { headers: this.tresci.user.getHeaderJson() }
        );
        this.tresci.http.request(request).pipe(/*take(1)*/).subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Response:
                this.tresci.copyMessage(event.body, 'Wczytano ' + dane.CurrentStep, 1);
                this.tresci.LastTechVersion = event.body.TechVersion;
                if (dane.CurrentStep != null) {
                  this.tresci.copyMessage(
                    serialize(event.body),
                    'Czas pobierania kroku ' +
                      dane.CurrentStep +
                      '(' +
                      (new Date().getTime() - this.czas_tmp) +
                      ')',
                    1
                  );
                } else {
                  this.tresci.copyMessage(
                    serialize(event.body),
                    'Czas pobierania kroku 0' +
                      '(' +
                      (new Date().getTime() - this.czas_tmp) +
                      ')',
                    1
                  );
                }

                if (
                  this.pozycjaKrok < 1 ||
                  event.body.Type === this.Krok[this.pozycjaKrok - 1].Type
                ) {
                  this.tresci.aktualne_id = this.id;
                  this.Last = false;
                  this.tresci.zamowienie.IsModuleFooter =
                    event.body.IsModuleFooter;
                  this.tresci.zamowienie.IsFooter = event.body.IsFooter;
                  this.tresci.zamowienie.IsModule = event.body.IsModule;
                  if (this.Krok.length > this.pozycjaKrok || !nastepny) {
                    // tslint:disable-next-line:max-line-length
                    this.Krok[this.pozycjaKrok] = new Krok(
                      event.body,
                      event.body.Step,
                      event.body.Controlls,
                      event.body.IsLastStep,
                      this.tresci.http,
                      ProductPrefix,
                      this.tresci,
                      event.body.Type,
                      this.id,
                      this,
                      this.modul,
                      this.pozycja,
                    );
                  } else {
                    // tslint:disable-next-line:max-line-length
                    const krok =  new Krok(
                      event.body,
                      event.body.Step,
                      event.body.Controlls,
                      event.body.IsLastStep,
                      this.tresci.http,
                      ProductPrefix,
                      this.tresci,
                      event.body.Type,
                      this.id,
                      this,
                      this.modul,
                      this.pozycja,
                    );
                    this.Krok.push(krok);
                  }
                  const class_div =
                    '.' +
                    this.Krok[this.pozycjaKrok].id +
                    '_' +
                    this.Krok[this.pozycjaKrok].Nazwa;
                  if (this.pozycja > 0) {
                    this.tresci.scrollToPozycja(class_div);
                  } else {
                    this.tresci.scrollTo(class_div);
                  }
                  this.tresci.zmien_zapisz();
                  this.wczytywanie = false;
                  this.poprawny = false;
                  this.tresci.zamowienie.ustawPoprawnosc();
                } else {
                  this.tresci.aktualne_id = 0;
                  this.wczytywanie = false;
                  this.poprawny = true;

                  this.tresci.zamowienie.ustawPoprawnosc();
                  // pierwsza grupa modułów
                  this.Last = true;
                  if (event.body.Type === 'ModuleHeader') {
                    this.tresci.zamowienie.sprawdzPoprawnosc();
                    this.tresci.zakladka = 1;
                    this.tresci.zmien_zapisz();
                  } else if (
                    event.body.Type === 'Position' &&
                    this.Krok[this.pozycjaKrok - 1].Type === 'ModuleHeader'
                  ) {
                    this.tresci.zakladka = 1;
                    // tslint:disable-next-line: max-line-length
                    if (
                      this.tresci.zamowienie.grupa_modul != null &&
                      this.tresci.zamowienie.grupa_modul.length >= 1 &&
                      this.tresci.zamowienie.grupa_modul[this.modul - 1] != null
                    ) {
                      if (nastepny) {
                        this.tresci.zamowienie.grupa_modul[
                          this.modul - 1
                        ].sprawdzPoprawnosc();
                      }
                    }

                  } else if (
                    event.body.Type === 'Position' ||
                    event.body.Type == null
                  ) {
                    if (event.body.Type != null) {
                      if (this.tresci.zakladka === 0) {
                        this.tresci.zamowienie.sprawdzPoprawnosc();
                      }
                      this.tresci.zakladka = 1;
                    }
                    if (
                      this.tresci.zamowienie.grupa_modul != null &&
                      this.tresci.zamowienie.grupa_modul.length >= 1 &&
                      this.tresci.zamowienie.grupa_modul[this.modul - 1] != null
                    ) {
                      if (nastepny) {
                        this.tresci.zamowienie.grupa_modul[
                          this.modul - 1
                        ].sprawdzPoprawnosc();
                      }
                    }
                  } else if (
                    event.body.Type === 'ModuleFooter' &&
                    this.Krok[this.pozycjaKrok - 1].Type === 'Position'
                  ) {
                    this.tresci.zamowienie.grupa_modul[
                      this.modul - 1
                    ].ustawStopke();
                  } else if (event.body.Type === 'Footer') {
                    this.tresci.zamowienie.ustawStopke(
                      this.Krok[this.pozycjaKrok - 1].Nazwa,
                      false
                    );
                  }
                }

                for (const control of event.body.Controlls) {
                  if (event.body.Controlls.length === 1) {
                    if (control.ControlType === 'Hidden') {
                      this.tresci.zakladka = 1;
                      this.StepForHidden = this.pozycjaKrok;
                      this.$HiddenStep.next(true);
                    } else {
                      this.$HiddenStep.next(false);
                    }
                  }
                }


              // this.tresci.wczytaj_tresci();
              // this.tresci.wczytaj_tresci_tlumaczen();
            }
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.wczytywanie = false;
              if (this.pozycjaKrok > 0) {
                this.pozycjaKrok--;
              }
              // this.tresci.copyMessage(serialize(err));
              this.tresci.exception.exception(
                'Błąd wczytywania formularza',
                err
              );
              this.failedLoadForm = true;
              this.tresci.zamowienie.isLoaded = true;
            }
          }
        );
      }
    }
  }
  public wczytajLabele(Parametry_dod = [], konfigurator: Konfigurator) {
    // this.wczytywanie = true;
    const ProductPrefix = this.tresci.getPrefix();
    const Parametry = this.getParamsSoft();
    const ContractorNumber = this.tresci.user.ContractorNumber;
    Parametry_dod.forEach(element => {
      Parametry.push(element);
    });
    let dane = null;
    if (this.pozycjaKrok === 0 && this.FirstStep == null) {
      dane = {
        ProductPrefix: ProductPrefix,
        ContractorNumber: ContractorNumber,
        Iso639: this.tresci.jezyk,
        IsCache: true
      };
    } else if (this.pozycjaKrok === 0) {
      dane = {
        ProductPrefix: ProductPrefix,
        CurrentStep: this.FirstStep,
        Parameters: Parametry,
        PreviewParameters: this.PreviewParameters,
        ContractorNumber: ContractorNumber,
        Iso639: this.tresci.jezyk,
        IsCache: true
      };
    } else {
      dane = {
        ProductPrefix: ProductPrefix,
        CurrentStep: this.Krok[this.pozycjaKrok - 1].Nazwa,
        Parameters: Parametry,
        PreviewParameters: this.PreviewParameters,
        ContractorNumber: ContractorNumber,
        Iso639: this.tresci.jezyk,
        IsCache: true
      };
    }

    const body = serialize(dane);
    // this.tresci.copyMessage(serialize(dane), 'Kopia parametrów dla wc', 1);
    const request = new HttpRequest(
      'POST',
        '/api/ActiveForm/GetStep',
      dane,
      { headers: this.tresci.user.getHeaderJson() }
    );
    this.tresci.http.request(request).pipe(/*take(1)*/).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            event.body.Controlls.forEach(element => {
              if (element.ControlType === 'Label') {
                // this.Krok[this.pozycjaKrok].Konfigurator.kontrolki.forEach(k => {
                konfigurator.kontrolki.forEach(k => {
                  if (k.KeyName === element.KeyName) {
                    k.InputText = element.Parameters[0].Value;
                    this.tresci.zamowienie.parametry_wybrane.add(
                      this.id,
                      element.KeyName,
                      element.Parameters[0].Value
                    );
                  }
                });

                // this.wczytywanie = false;
              }
            });
          // event.body.Controlls = this.poprawKrok(event.body.Controlls);
          // this.Krok[this.pozycjaKrok]
        }
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          // if (this.pozycjaKrok > 0) {
          // this.pozycjaKrok--;
          // }
          this.tresci.exception.exception(
            'Błąd wczytywania danych Labeli',
            err
          );
          // this.wczytywanie = false;
        }
      }
    );
  }

  public poprawKrok(tablica_k) {
    const tab = [];
    tablica_k.forEach(element => {
      // tslint:disable-next-line: max-line-length
      if (
        this.tresci.komponent !== 'OrderDesktop' ||
        (element.KeyName !== 'DotyczyZlec' &&
          element.KeyName !== 'Sposobdostawy' &&
          element.KeyName !== 'IdAdresyDostawy')
      ) {
        tab.push(element);
      }
    });
    return tab;
  }
}
