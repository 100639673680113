<h2 class="headerLine">Nagłówek</h2>
<div class="headerInfo" *ngIf="parametry != null ">

  <ul>
    <ng-container *ngFor="let parametr of parametry; let j = index">
      <ng-container *ngIf="parametr.Module == 0 && parametr.Position == 0">
        <li>
          <span id="label_{{parametr.Key}}" [translation]="TranslationEnum.Translation">
            {{'label_' + parametr.Key|translate}}
          </span>
          <!-- <app-tlumacz [id]="'label_' + parametr.Key" [typ]="'form'" [tresci]="tresci"></app-tlumacz> -->
          <span style="color: red"> {{parametr.DisplayValue}}</span>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>

<ng-container *ngIf="backToArray.length != 0">
  <h2 class="headerLine" style="margin-top: 30px">Nagłówek modułu</h2>
  <div class="headerInfo" *ngIf="parametryKim != null">

    <select class="browser-default custom-select">
      <ng-container *ngFor="let module of backToArray;">
        <option value="module" (click)="showInfo(module)">Moduł: {{module}}</option>
      </ng-container>

    </select>

    <ul>
      <ng-container *ngFor=" let moreInfo of moduleInf; let j = index">
        <li>
          <span id="label_{{moreInfo.Key}}" [translation]="TranslationEnum.Translation">
            {{'label_' + moreInfo.Key|translate}}
          </span>
          <!-- <app-tlumacz [id]="'label_' + moreInfo.Key" [typ]="'form'" [tresci]="tresci"></app-tlumacz> -->
          <span style="color: red"> {{moreInfo.DisplayValue}} ({{moreInfo.Value}})</span>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-container>

<h2 class="headerLine" style="margin-top: 30px">Pozycje modułu</h2>
<div class="headerInfo" *ngIf="parametryKim != null">
  <ul>
    <ng-container *ngFor="let parametr of parametry; let j = index">
      <ng-container *ngIf="parametr.Module == selectedModule && parametr.Position != 0">
        <li>
          <span id="label_{{parametr.Key}}" [translation]="TranslationEnum.Translation">
            {{'label_' + parametr.Key|translate}}
          </span>
          <!-- <app-tlumacz [id]="'label_' + parametr.Key" [typ]="'form'" [tresci]="tresci"></app-tlumacz> -->
          <span style="color: red"> {{parametr.DisplayValue}}</span>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>
