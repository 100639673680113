<ng-container #ref *ngIf="Form">
  <form [@error]="AnimateError" [formGroup]="Form" (ngSubmit)="onSubmit(Form)" class="p-3 mb-2 overflow-hidden bg-whisper">
    <div class="row">
      <ng-container *ngFor="let control of HtmlControls;" dynamic-control [field]="control" [group]="Form">
      </ng-container>
    </div>
    <ng-container *ngIf='IsHiddenStep === false'>
      <button mat-flat-button [hidden]="IsEdit" color="primary" class="float-right" [id]="'btn_'+CurrentStep" [disabled]="Preview || BlockNextStep"
        type="submit"  >
        <span id="dalej" [translation]="TranslationEnum.TranslationForm">
          {{'dalej'|translate}}
        </span>
        <mat-icon class="ml-1">forward</mat-icon>
      </button>
      <button mat-flat-button [hidden]="!IsEdit" type="button" (click)='editCurrentStep(CurrentStep, false)' color="primary" [disabled]="Preview || IsAnotherStepInEdit"
        class="float-right" [name]="CurrentStep" [value]="CurrentStep" >
        <span id="edytuj" [translation]="TranslationEnum.TranslationForm">
          {{'edytuj'|translate}}
        </span>
        <mat-icon class="ml-1">create</mat-icon>
      </button>
      <button *ngIf="ShowTechnologyPermission" mat-flat-button type="button" color="primary" class="float-right mr-3" [disabled]="Preview"
        (click)="showTechnology(Form, CurrentStep)">
        <span id="technologia" [translation]="TranslationEnum.TranslationForm">
          {{'technologia'|translate}}
        </span> V.{{TechVersion}}
      </button>
    </ng-container>
  </form>
</ng-container>
