<h2 mat-dialog-title>
  <span *ngIf="dataDialog.modalForEnum === modalForEnum.OrderMany" id="czy_udało_się_zamówić"
    [translation]="TranslationEnum.Translation">
    {{'czy_udało_się_zamówić'|translate}}</span>
  <!-- <app-tlumacz [hidden]="dataDialog.modalForEnum !== modalForEnum.OrderMany" [id]="'Czy udało się zamówić?'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
  <span *ngIf="dataDialog.modalForEnum === modalForEnum.PayMany" id="czy_udało_się_wygenerować_proformę"
    [translation]="TranslationEnum.Translation">
    {{'czy_udało_się_wygenerować_proformę'|translate}}</span>
  <!-- <app-tlumacz [hidden]="dataDialog.modalForEnum !== modalForEnum.PayMany" [id]="'Czy udało się wygenerować proformę?'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
</h2>
<div mat-dialog-content>
  <table mat-table [dataSource]="dataDialog.data.OrderedStatusList" class="mat-elevation-z8">

    <!-- B2BId Column -->
    <ng-container matColumnDef="B2BId">
      <th mat-header-cell *matHeaderCellDef id="b2bid" [translation]="TranslationEnum.Translation">
        {{'b2bid'|translate}}
        <!-- <app-tlumacz [id]="'B2Bid'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row"> {{row.B2BId}} </td>
    </ng-container>

    <!-- Product Column -->
    <ng-container matColumnDef="Product">
      <th mat-header-cell *matHeaderCellDef id="produkt" [translation]="TranslationEnum.Translation">
        {{'produkt'|translate}}
        <!-- <app-tlumacz [id]="'Produkt'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row" id="produkt_{{row.Product}}" [translation]="TranslationEnum.Translation">
        {{'produkt_' + row.Product|translate}}
        <!-- <app-tlumacz [id]="'produkt_' + row.Product" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </td>
    </ng-container>

    <!-- System Column -->
    <ng-container matColumnDef="System">
      <th mat-header-cell *matHeaderCellDef id="system" [translation]="TranslationEnum.Translation">
        {{'system'|translate}}
        <!-- <app-tlumacz [id]="'System'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row" id="system_{{row.System}}" [translation]="TranslationEnum.Translation">
        {{'system_' + row.System|translate}}
        <!-- <app-tlumacz [id]="'system_' + row.System" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </td>
    </ng-container>

    <!-- TotalCost Column -->
    <ng-container matColumnDef="ValNetBrut">
      <th mat-header-cell *matHeaderCellDef id="cena_razem" [translation]="TranslationEnum.Translation">
        {{'cena_razem'|translate}}
        <!-- <app-tlumacz [id]="'Cena_razem'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row" class="cena">
        <ng-container *ngIf="row.ValueNetto !== null && row.Currency !== null && row.ValueNetto > 0">
          {{row.ValueNetto | PriceFormat}}&nbsp;
          {{row.Currency}}<br />
          {{row.ValueBrutto | PriceFormat}}&nbsp;
          {{row.Currency}}
        </ng-container>
        <ng-container *ngIf="row.ValueNetto !== null && row.Currency !== null && row.ValueNetto == 0">
          ---
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="Ordered">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="text-right">
        <span *ngIf="row.OrderedStatus !== 2" id="zamówiono" [translation]="TranslationEnum.Translation">
          {{'zamówiono'|translate}}</span>
        <!-- <app-tlumacz [hidden]="row.OrderedStatus === 2" [id]="'Zamówiono'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        <span *ngIf="row.OrderedStatus !== 1" id="nie_zamówiono" [translation]="TranslationEnum.Translation">
          {{'nie_zamówiono'|translate}}</span>
        <!-- <app-tlumacz [hidden]="row.OrderedStatus === 1" [id]="'Nie zamówiono'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{'bg-success': row.OrderedStatus === 1, 'bg-danger': row.OrderedStatus === 2}"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <div class="w-100">
    <h5 class="d-inline">
      <span id="numer_proformy" [translation]="TranslationEnum.Translation">{{'numer_proformy'|translate}}</span>
      <!-- <app-tlumacz [id]="'Numer proformy'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>: -->
      {{dataDialog.data.ProfromaFormatNumber}}
    </h5>
    <button mat-flat-button id="ok" class="float-right" [translation]="TranslationEnum.Translation" color="primary" mat-dialog-close>
      {{'ok'|translate}}</button>
    <form class="float-right" ngNoForm
      [action]="tresci.server_url + 'api/ActiveForm/GetProformaPrintout?culture=' + Culture" method="POST"
      target="_blank">
      <input [hidden]="true" type="text" id="ProformaFormat" name="ProformaFormat"
        [value]="dataDialog.data.ProfromaFormatNumber">
      <button mat-flat-button id="proforma" [translation]="TranslationEnum.Translation" color="primary" type="submit">
        {{'proforma'|translate}}
        <!-- <app-tlumacz [id]="'Proforma'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </button>
    </form>
  </div>
</div>
