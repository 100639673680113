import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';



@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient) { }
  getTranslation(lang: string): Observable<any> {
    
    return this.httpClient.get(`api/WebContent/GetWebContentI18n?site=${localStorage.site}&culture=${lang}&webContentDate=2021-01-04T09:49:58`);
  } 

}
