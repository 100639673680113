  export class ClientInfo {
      Id: number;
      ContractorNumber: string;
      Name: string;
      Address: string;
      City: string;
      Country: string;
      NIP: string;
      ZipCode: string;
      ContractorClientProfitMargins: ContractorClientProfitMargin[];

  }

  export class ContractorClientProfitMargin {
    Id: number;
    ContractorClientId: number;
    SystemName: string;
    SystemDisplay: string;
    SystemDiscount: number;
    AccessoryDiscount: number;
    ElectricityDiscount: number;
  }

