import { ManageValueChange } from './../models/form/manage-value-change.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ManageNextButton } from '../models/form/manage-next-button.model';

@Injectable({
  providedIn: 'root',
})
export class DataSharingService {
  public contractorChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public IsStepEditing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public BlockOtherNextButton: BehaviorSubject<ManageNextButton> = new BehaviorSubject<ManageNextButton>(null as any);
  public BlockIfMessageDialogAppear: BehaviorSubject<ManageNextButton> = new BehaviorSubject<ManageNextButton>(null as any);
  public ValueChangeInControl: BehaviorSubject<ManageValueChange> = new BehaviorSubject<ManageValueChange>(null as any);
  public ErrorSettingValue: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public BlockOtherSetsAndSaveForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public SetEnabledForCombineControl: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
