import { Component, Inject, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { TresciService } from 'src/app/tresci.service';



@Component({
  selector: 'app-payment-confirm-dialog',
  templateUrl: './payment-confirm-dialog.component.html',
  styleUrls: ['./payment-confirm-dialog.component.scss'],
})
export class PaymentConfirmDialogComponent {
  public TranslationEnum = TranslationEnum;

  orderTerms: FormGroup;
  valuation = new FormControl(false, Validators.required);
  specification = new FormControl(false, Validators.required);
  proform = new FormControl(false, Validators.required);

  constructor(public dialogRef: MatDialogRef<PaymentConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: number, public tresci: TresciService, private fb: FormBuilder) {
    this.orderTerms = this.fb.group({
      valuation: this.valuation,
      specification: this.specification,
      proform: this.proform
    });
  }

  order({ valid }: { valid: boolean }) {
    if (valid) {
      this.tresci.saveOrdersWithOutVeryficatfionInMultiPaymentWithProfroma(this.data);
      this.dialogRef.close(true);
    }
  }
}
