export enum StatusEnum {
  Opracowywane = 1,
  Zamowiono = 2,
  Realizacja = 3,
  Wstrzymano = 4,
  Zakonczono = 5,
  Anulowano = 6,
  Platnosci = 7,
  Dostawy = 8
}
