import { Component, OnInit, } from '@angular/core';
import { TresciService } from './../tresci.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { serialize } from 'class-transformer';
import { HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { TranslationEnum } from '../shared/enums/translation-enum';
import { take } from 'rxjs/operators';



@Component({
  selector: 'app-zaplata',
  templateUrl: './zaplata.component.html',
  styleUrls: ['./zaplata.component.scss']
})
export class ZaplataComponent implements OnInit {

  public TranslationEnum = TranslationEnum;

  Zadluzenia: any[] = [];
  Today: Date = new Date();



  constructor(public tresci: TresciService, public user: UserService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.tresci.setJezyk(this.route.snapshot.paramMap.get('jezyk'));
    this.tresci.setFunkcja(this.route.snapshot.paramMap.get('system'));
    this.tresci.setId(this.route.snapshot.paramMap.get('id'));
    this.pobierzZadluzenia();
  }



  public pobierzZadluzenia() {

    // if (this.tresci.user.ContractorNumber == null) {
    //   setTimeout(() => { this.pobierzZadluzenia(); }, 300);
    // } else {
      this.pobierzDaneZadluzenia(this.tresci.user.ContractorNumber);
    // }
  }

  private pobierzDaneZadluzenia(contractorNumber) {
    contractorNumber = '00001';
    const body = {
      'ContractorNumber': '00001'
    };
    const dane = serialize(body);
    const request = new HttpRequest('POST', '/api/Debt/GetDebts',
      dane, { headers: this.tresci.user.getHeaderJson() });
    this.tresci.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.Zadluzenia = event.body;
      }
    });
  }
}
