import { ConstantPool } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';



@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})

export class CheckboxComponent implements OnInit  {

  TranslationEnum = TranslationEnum;
  public Control: HtmlControl;
  public Group: FormGroup;


  public stateValue: boolean ;


  constructor() { }

  ngOnInit(): void {
  }
}
