import { ModuleMaintenanceComponent } from './module-maintenance/module-maintenance.component';
import { ResetPasswordComponent } from './module-login/reset-password/reset-password.component';
import { LoginComponent } from './module-login/login/login.component';
import { SystemsComponent } from './module-systems/systems/systems.component';
import { AuthGuard } from './shared/guard/auth-guards';
import { InitialFormComponent } from './new-form/initial-form/initial-form.component';
import { ZaplataComponent } from './zaplata/zaplata.component';
import { PanelComponent } from './panel/panel.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FakturyComponent } from './faktury/faktury.component';
import { NeedchangepasswordComponent } from './needchangepassword/needchangepassword.component';
import { OrderRegisterComponent } from './module-order-register/order-register/order-register.component';
import { IsLoggedGuard } from './shared/guard/is-logged-guard';
import { NotificationsHistoryComponent } from './notifications/notifications-history/notifications-history.component';
import { InvoicesComponent } from './module-invoices/invoices.component';
import { ReklamacjeComponent } from './reklamacje/reklamacje.component';
import { ReklamacjePozComponent } from './reklamacje/reklamacje-poz/reklamacje-poz.component';
import { FormV2Component } from './form-v2/form-v2.component';
import { BrakiComponent } from './braki/braki.component';
import { FormV2PreviewComponent } from './form-v2-preview/form-v2-preview.component';
import { DokumentyComponent } from './dokumenty/dokumenty.component';
import { StrefaKlentaComponent } from './strefa-klenta/strefa-klenta.component';

const routes: Routes = [
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  { path: 'Login', component: LoginComponent, canActivate: [IsLoggedGuard] },
  { path: 'password/new', component: ResetPasswordComponent, canActivate: [IsLoggedGuard] },
  { path: 'Home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'Reklamacje', component: ReklamacjeComponent, canActivate: [AuthGuard] },
  { path: 'Reklamacje/:numer', component: ReklamacjePozComponent, canActivate: [AuthGuard] },
  { path: 'Orders', component: OrderRegisterComponent, canActivate: [AuthGuard] },
  { path: 'Orders/:zakladka', component: OrderRegisterComponent, canActivate: [AuthGuard] },
  { path: 'Invoices', component: InvoicesComponent, canActivate: [AuthGuard] },
  { path: 'Zaplata/:jezyk/:system', component: ZaplataComponent, canActivate: [AuthGuard] },
  { path: 'Systems', component: SystemsComponent, canActivate: [AuthGuard] },
  { path: 'Braki', component: BrakiComponent, canActivate: [AuthGuard] },
  // { path: 'Archives/:jezyk/:system', component: ArchiwumComponent },
  // { path: 'Orders/:jezyk/:system/:id', component: ZamowieniaComponent, canActivate: [AuthGuard] },
  // { path: 'Szczegoly/:jezyk/:system/:id', component: ZamowieniaComponent, canActivate: [AuthGuard] },
  { path: 'Order/:kindOper/:system', component: InitialFormComponent, canActivate: [AuthGuard] },
  { path: 'Order/:kindOper/:system/:id', component: InitialFormComponent, canActivate: [AuthGuard] },
  { path: 'NOrder/:kindOper/:system', component: FormV2Component, canActivate: [AuthGuard] },
  { path: 'NOrder/:kindOper/:system/:id', component: FormV2Component, canActivate: [AuthGuard] },
  { path: 'Preview/:system/:id', component: FormV2PreviewComponent, canActivate: [AuthGuard] },
  { path: 'Dok/:wys/:typ', component: DokumentyComponent, canActivate: [AuthGuard] },
  { path: 'Strefa', component: StrefaKlentaComponent, canActivate: [AuthGuard] },
  // { path: 'NewOrder/:jezyk/:system/:id', component: KonfiguratorComponent },
  // { path: 'Order/:jezyk/:system/:id', component: KonfiguratorComponent },
  { path: 'panel', component: PanelComponent, canActivate: [AuthGuard] },
  { path: 'panel/:jezyk/panelHaslo', component: PanelComponent, canActivate: [AuthGuard] },
  { path: 'Change-password', component: NeedchangepasswordComponent, canActivate: [AuthGuard] },
  { path: 'notification-history', component: NotificationsHistoryComponent, canActivate: [AuthGuard] },
  // { path: 'History/:jezyk/:system', component: HistoriaComponent },
  { path: 'error/503', component: ModuleMaintenanceComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
