import { Module } from './module';
import { TresciService } from "./../tresci.service";
import { Zamowienie } from "./zamowienie";

import { Pozycja } from "./pozycja";
import { Kroki } from "./kroki";
export class GrupaModul {
  public naglowekModul: Kroki;
  public pozycja: Pozycja[] = [];
  public copyPoz: Pozycja;
  public stopkaModul: Kroki;
  public poprawny = false;
  public aktywnaPoz = 0;
  public table_prop = [];
  public table_prop2 = [];
  public poz: Pozycja = null;
  public dalej: Kroki;


  constructor(private tresci: TresciService, private modul, private FirstStep,
    private parametryPrzed, private parametryW = [], private parametryWKim = []) {
    this.naglowekModul = new Kroki(tresci, 0, modul, FirstStep, parametryPrzed);
    this.stopkaModul = new Kroki(tresci, 0, modul, FirstStep, parametryPrzed);
    this.dalej = new Kroki(tresci, 0, modul, FirstStep, parametryPrzed);
    if (!this.tresci.zamowienie.IsModule) {

      this.naglowekModul.wczytywanie = false;
      this.naglowekModul.poprawny = true;
      this.naglowekModul.tresci.zamowienie.ustawPoprawnosc();
      this.naglowekModul.Last = true;
      this.tresci.zakladka = 1;

      // this.naglowekModul.wczytajKrokZparametrami(0, parametryW, this.modul - 1, null, parametryWKim);
      this.wczytajPozycje(1, false);
    } else if (parametryW.length > 0) {
      this.naglowekModul.wczytajKrokZparametrami(
        0,
        parametryW,
        this.modul - 1,
        null,
        parametryWKim
      );
    } else {
      this.naglowekModul.wczytajKrok(0);
    }
  }

  public getStrukt() {
    const nag = this.naglowekModul.getStrukt();
    const stopka = this.stopkaModul.getStrukt();
    const pozycje = [];
    this.pozycja.forEach(element => {
      pozycje.push(element.pozycja.getStrukt());
    });
    const mod = [];
    mod.push(nag);
    mod.push(pozycje);
    mod.push(stopka);
    return mod;
  }
  public setStrukt(mod, ParametryW, ParametryWKim) {
    if (this.tresci.zamowienie.IsModule) {
      let next_strukt = [];
      if (mod[1].lenght > 0) {
        next_strukt = mod[1][0];
      }
      this.naglowekModul.setStrukt(
        mod[0],
        this.modul,
        this.pozycja,
        ParametryW,
        ParametryWKim
      );
    }

    //
  }

  public wczytajPozycje(currentPoz, zParametrami) {
    const parametryDlaPoz = [];
    this.parametryW.forEach(element => {
      if (element.Position === currentPoz) {
        parametryDlaPoz.push(element);
      }
    });
    const parametryDlaPozKim = [];
    this.parametryWKim.forEach(element => {
      if (element.Position === currentPoz) {
        parametryDlaPozKim.push(element);
      }
    });
    if (parametryDlaPoz.length > 0) {
      if (this.pozycja.length < currentPoz) {
        // tslint:disable-next-line:max-line-length
        this.pozycja.push(
          new Pozycja(
            this.tresci,
            this.pozycja.length + 1,
            this.modul,
            this.naglowekModul.LastStepMod(),
            this.naglowekModul.getParams(),
            this.getParamPrevAfter(currentPoz - 1),
            false
          )
        );
        this.pozycja[currentPoz - 1].pozycja.wczytajKrokZparametrami(
          0,
          parametryDlaPoz,
          this.modul - 1,
          currentPoz,
          parametryDlaPozKim
        );
      } else {
        // tslint:disable-next-line:max-line-length
        this.pozycja[currentPoz - 1] = new Pozycja(
          this.tresci,
          this.pozycja.length + 1,
          this.modul,
          this.naglowekModul.LastStepMod(),
          this.naglowekModul.getParams(),
          this.getParamPrevAfter(currentPoz - 1),
          false
        );
        this.pozycja[currentPoz - 1].pozycja.wczytajKrokZparametrami(
          0,
          parametryDlaPoz,
          this.modul - 1,
          currentPoz,
          parametryDlaPozKim
        );
      }
    } else if (!zParametrami) {
      this.sprawdzPoprawnosc();
    }
  }

  public getMaxMinSuma() {
    const param = [];
    const table_prop = [];
    this.pozycja.forEach(pozycja_tmp => {
      const table_prop_tmp = pozycja_tmp.pozycja.getSelfParams();
      table_prop_tmp.forEach(element => {
        let byl_prop = false;
        table_prop.forEach(element2 => {
          if (element.Key === element2.Key) {
            byl_prop = true;
          }
        });
        if (!byl_prop) {
          if (element.DisplayValue !== "X") {
            table_prop.push({
              Key: element.Key,
              Value: element.Value,
              Position: element.Position,
              Module: element.Module,
              Type: element.Type,
              IsPrint: element.IsPrint
            });
          }

          // param.push({Key: element.Key, Value: element.Value, Position: element.Position, Module: element.Module, Type: element.Type});
          if (!isNaN(element.Value)) {
            param.push({
              Key: "min_" + element.Key,
              Value: element.Value,
              DisplayValue: "X",
              Position: 0,
              Module: element.Module,
              Type: 3,
              IsPrint: false
            });
            param.push({
              Key: "max_" + element.Key,
              Value: element.Value,
              DisplayValue: "X",
              Position: 0,
              Module: element.Module,
              Type: 3,
              IsPrint: false
            });
            param.push({
              Key: "suma_" + element.Key,
              Value: element.Value,
              DisplayValue: "X",
              Position: 0,
              Module: element.Module,
              Type: 3,
              IsPrint: false
            });
          }
        } else {
          param.forEach(element2 => {
            if (element2.Key === "min_" + element.Key) {
              if (element.Value * 1 < element2.Value * 1) {
                element2.Value = element.Value;
              }
            }
            if (element2.Key === "max_" + element.Key) {
              if (element.Value * 1 > element2.Value * 1) {
                element2.Value = element.Value;
              }
            }
            if (element2.Key === "suma_" + element.Key) {
              element2.Value = element2.Value * 1 + element.Value * 1;
            }
          });
        }
      });
    });
    return param;
  }

  public getSelfParams() {
    const Parametry = this.naglowekModul.getSelfParams(true);
    this.pozycja.forEach(element => {
      const par1 = element.pozycja.getSelfParams(true);
      let pozycja = 0;
      let modul = 0;
      let typ = "";
      par1.forEach(poz => {
        Parametry.push(poz);
        modul = poz.Module;
        pozycja = poz.Position;
        typ = poz.Type;
      });
      // tu wstawić
      Parametry.push({
        Key: "Numermodułu",
        Value: modul,
        DisplayValue: "X",
        DisplayValuePl: "X",
        DisplayValueEn: "X",
        DisplayValueDe: "X",
        DisplayValueFr: "X",
        Position: pozycja,
        Module: modul,
        Type: typ,
        IsPrint: false
      });
      Parametry.push({
        Key: "Numerpozycji",
        Value: pozycja,
        DisplayValue: "X",
        DisplayValuePl: "X",
        DisplayValueEn: "X",
        DisplayValueDe: "X",
        DisplayValueFr: "X",
        Position: pozycja,
        Module: modul,
        Type: typ,
        IsPrint: false
      });
    });
    const par2 = this.getMaxMinSuma();
    par2.forEach(poz => {
      Parametry.push(poz);
    });
    if (this.tresci.zamowienie.IsModuleFooter) {
      const Parametry_stopka = this.stopkaModul.getSelfParams(true);
      Parametry_stopka.forEach(poz => {
        Parametry.push(poz);
      });
    }

    return Parametry;
  }
  public getSelfParamsKim() {
    const Parametry = this.naglowekModul.getSelfParamsKim();
    this.pozycja.forEach(element => {
      const par1 = element.pozycja.getSelfParamsKim();
      par1.forEach(poz => {
        Parametry.push(poz);
      });
    });
    if (this.tresci.zamowienie.IsModuleFooter) {
      const par1 = this.stopkaModul.getSelfParamsKim();
      par1.forEach(poz => {
        Parametry.push(poz);
      });
    }
    return Parametry;
  }
  public getParams() {
    const Parametry = this.naglowekModul.getParams();
    this.pozycja.forEach(element => {
      const par1 = element.pozycja.getSelfParams();
      par1.forEach(poz => {
        Parametry.push(poz);
      });
    });
    return Parametry;
  }

  public getParamsSoftWybrane() {
    const Parametry = this.naglowekModul.getParamsSoftWybrane();
    this.pozycja.forEach(element => {
      const par1 = element.pozycja.getParamsSoftWybrane();
      par1.forEach(poz => {
        Parametry.push(poz);
      });
    });
    const par2 = this.stopkaModul.getParamsSoftWybrane();
    par2.forEach(poz => {
      Parametry.push(poz);
    });

    return Parametry;
  }
  public getParamsSoftSelfWybrane() {
    const Parametry = [];
    this.pozycja.forEach(element => {
      const par1 = element.pozycja.getParamsSoftWybrane();
      par1.forEach(poz => {
        Parametry.push(poz);
      });
    });
    return Parametry;
  }

  public ustawAktywna(numer) {
    this.aktywnaPoz = numer;
    // if (this.aktywnaPoz > 0) {
    // tslint:disable-next-line:max-line-length
    this.pozycja[this.aktywnaPoz].pozycja.setFirstStep(
      this.naglowekModul.LastStepMod(),
      this.naglowekModul.getParams(),
      this.getParamPrevAfter(this.aktywnaPoz)
    );
    // } else {
    // tslint:disable-next-line:max-line-length
    // this.pozycja[this.aktywnaPoz].pozycja.setFirstStep(this.naglowekModul.LastStep(), this.naglowekModul.getParams());
    // }
    this.pozycja[this.aktywnaPoz].edytowana = true;
    this.tresci.zmien_zapisz();
  }


  public dodajPozycje() {
    // tslint:disable-next-line:max-line-length
    // if (this.pozycja.length > 0) {
    // tslint:disable-next-line:max-line-length

    this.pozycja.push(new Pozycja(this.tresci, this.pozycja.length + 1, this.modul, this.naglowekModul.LastStepMod(),
      this.naglowekModul.getParams(), this.getParamPrevAfter(this.pozycja.length)));

    // } else {
    // tslint:disable-next-line:max-line-length
    // this.pozycja.push(new Pozycja(this.tresci, this.pozycja.length + 1, this.modul, this.naglowekModul.LastStep(), this.naglowekModul.getParams()));
    // }
    this.ustawAktywna(this.pozycja.length - 1);
    this.poprawny = false;
    this.tresci.zamowienie.zapisz_Parametry();
  }

  public kopiujPozycje(numer: number) {

    this.aktywnaPoz = numer;


    const parametryDlaPoz = this.pozycja[numer].pozycja.getSelfParams();
    /*this.parametryW.forEach(element => {

      if (element.Position === this.aktywnaPoz) {
        parametryDlaPoz.push(element);
      }
    }); */
    const parametryDlaPozKim = this.pozycja[numer].pozycja.getSelfParamsKim();
    /*this.parametryWKim.forEach(element => {
      if (element.Position === this.aktywnaPoz) {
        parametryDlaPozKim.push(element);
      }
    });*/

    this.pozycja.push(new Pozycja(this.tresci, this.pozycja.length + 1, this.modul, this.naglowekModul.LastStepMod(),
      this.naglowekModul.getParams(), this.getParamPrevAfter(this.pozycja.length)));

    this.pozycja[this.pozycja.length - 1].pozycja.wczytajKrokZparametrami(0, parametryDlaPoz, this.modul - 1,
      this.pozycja.length, parametryDlaPozKim);
  }

  public usunPozycje(id) {
    this.pozycja.splice(id, 1);
    this.sprawdzPoprawnosc();
    this.tresci.zamowienie.zapisz_Parametry();
    this.tresci.zmien_zapisz();
  }

  public czyPoprawny() {
    let poprawny = true;
    this.pozycja.forEach(element => {
      if (!element.poprawny) {
        poprawny = false;
      }
    });
    if (this.pozycja.length > 0) {
      return poprawny;
    } else {
      return false;
    }
  }

  public getParamPrevAfter(i) {
    let tmp_params = [];
    if (i > 0) {
      tmp_params = this.pozycja[i - 1].pozycja.getSelfParams(true);
    } else {
      tmp_params = [];
    }
    let tmp_params2 = [];
    if (this.pozycja.length > i + 1) {
      tmp_params2 = this.pozycja[i + 1].pozycja.getSelfParams(true);
    } else {
      tmp_params2 = [];
    }
    tmp_params2.forEach(element => {
      element.Key = "next_" + element.Key;
      tmp_params.push(element);
    });
    return tmp_params;
  }

  public sprawdzPoprawnosc(ParametryW = []) {
    // this.naglowekModul.sprawdzPoprawnosc();
    let i = 0;
    this.pozycja.forEach(poz => {
      // if (i > 0) {
      // tslint:disable-next-line:max-line-length
      //  poz.pozycja.setFirstStep(this.naglowekModul.LastStep(), this.naglowekModul.getParams(), this.pozycja[i - 1].pozycja.getSelfParams(true));
      // } else {
      this.naglowekModul.ParametryPrzed = this.tresci.zamowienie.naglowek.getParams();
      poz.pozycja.setFirstStep(
        this.naglowekModul.LastStepMod(),
        this.naglowekModul.getParams(),
        this.getParamPrevAfter(i)
      );
      // }
      poz.pozycja.sprawdzPoprawnosc(ParametryW);
      i++;
    });
    if (this.table_prop.length === 0) {
      this.ustawStopke();
    }
    this.ustawPoprawnosc();
  }

  public ustawPoprawnosc() {
    let poprawny = true;
    if (!this.naglowekModul.poprawny) {
      poprawny = false;
    }
    if (this.tresci.zamowienie.IsModuleFooter && !this.stopkaModul.poprawny) {
      poprawny = false;
    }
    this.pozycja.forEach(poz => {
      if (!poz.pozycja.poprawny) {
        poprawny = false;
      }
    });
    if (this.pozycja.length > 0) {
      this.poprawny = poprawny;
    } else {
      this.poprawny = false;
    }
  }
  public getSeltParamPozycja_max_min() {
    this.table_prop = [];
    this.table_prop2 = [];
    this.pozycja.forEach(pozycja_tmp => {
      const table_prop_tmp = pozycja_tmp.pozycja.getSelfParams();
      table_prop_tmp.forEach(element => {
        let byl_prop = false;
        this.table_prop.forEach(element2 => {
          if (element.Key === element2.Key) {
            byl_prop = true;
          }
        });
        if (!byl_prop) {
          if (element.DisplayValue !== "X") {
            this.table_prop.push({
              Key: element.Key,
              Value: element.Value,
              Position: element.Position,
              Module: element.Module,
              Type: element.Type,
              IsPrint: false
            });
          }

          this.table_prop2.push({
            Key: element.Key,
            Value: element.Value,
            Position: element.Position,
            Module: element.Module,
            Type: element.Type,
            IsPrint: false
          });
          if (!isNaN(element.Value)) {
            this.table_prop2.push({
              Key: "min_" + element.Key,
              Value: element.Value,
              DisplayValue: "X",
              Position: element.Position,
              Module: element.Module,
              Type: 3,
              IsPrint: false
            });
            this.table_prop2.push({
              Key: "max_" + element.Key,
              Value: element.Value,
              DisplayValue: "X",
              Position: element.Position,
              Module: element.Module,
              Type: 3,
              IsPrint: false
            });
            this.table_prop2.push({
              Key: "suma_" + element.Key,
              Value: element.Value,
              DisplayValue: "X",
              Position: element.Position,
              Module: element.Module,
              Type: 3,
              IsPrint: false
            });
          }
        } else {
          this.table_prop2.forEach(element2 => {
            if (element2.Key === "min_" + element.Key) {
              if (element.Value * 1 < element2.Value * 1) {
                element2.Value = element.Value;
              }
            }
            if (element2.Key === "max_" + element.Key) {
              if (element.Value * 1 > element2.Value * 1) {
                element2.Value = element.Value;
              }
            }
            if (element2.Key === "suma_" + element.Key) {
              element2.Value = element2.Value * 1 + element.Value * 1;
            }
          });
        }
      });
    });
  }
  public ustawStopke(zParametrami = false) {
    this.getSeltParamPozycja_max_min();
    const paramNaglowek = this.tresci.zamowienie.naglowek.getParams();

    paramNaglowek.forEach(element => {
      this.table_prop2.push(element);
    });
    if (this.tresci.zamowienie.IsModuleFooter) {
      if (
        this.pozycja.length > 0 &&
        this.pozycja[0] != null &&
        this.pozycja[0].pozycja != null &&
        this.pozycja[0].pozycja.Krok.length > 0
      ) {
        // this.parametryW
        this.stopkaModul.setFirstStep(
          this.pozycja[0].pozycja.Krok[this.pozycja[0].pozycja.Krok.length - 1]
            .Nazwa,
          this.table_prop2
        );
        if (zParametrami) {
          const paramWstopka = [];
          this.parametryW.forEach(element => {
            if (element.Position === 0) {
              paramWstopka.push(element);
            }
          });
          const paramWKimstopka = [];
          this.parametryWKim.forEach(element => {
            if (element.Position === 0) {
              paramWKimstopka.push(element);
            }
          });
          this.stopkaModul.wczytajKrokZparametrami(
            0,
            paramWstopka,
            this.modul - 1,
            null,
            paramWKimstopka
          );
        } else {
          this.stopkaModul.wczytajKrok(0);
        }
        // this.stopkaModul.wczytajKrok(0);
      }
    }
    this.ustawPoprawnosc();
  }
  public sprawdz(FirstStep, Parametry) {
    this.naglowekModul.setFirstStep(FirstStep, Parametry);
    this.naglowekModul.sprawdzPoprawnosc();
    // this.naglowekModul.wczytajKrok(0);
  }
  public poza() {
    this.naglowekModul.poza();
    this.stopkaModul.poza();
    this.pozycja.forEach(poz => {
      poz.pozycja.poza();
    });
  }
  public cenaSuma() {
    let cena = 0;
    this.pozycja.forEach(element => {
      if (element.pozycja.cena != null) {
        element.pozycja.cena.AdditionalValuations.forEach(price => {
          cena += price.FinalPrice;
          this.tresci.Currency = price.Currency;
        });
      }
    });
    return cena;
  }
  public LiczCeny() {
    this.pozycja.forEach(element => {
      element.pozycja.getCena();
    });
  }
}
