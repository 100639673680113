import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { FileControl } from 'src/app/shared/models/form/file-control.model';
import { FormService } from 'src/app/shared/service/form.service';
import { PreviewFileDialogComponent } from '../../preview-file/preview-file-dialog.component';




@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent implements OnInit {
  public TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;
  public DisplayValue: string;

  private file: FileControl = new FileControl();

  constructor(private formService: FormService, private dialog: MatDialog) {}

  ngOnInit(): void {
    const element = this.Control.options[0];
    this.file.Id = element.Key;
    this.file.Key = this.Control.name;
    this.DisplayValue = '';
    if (this.formService.Files.some((x: FileControl) => x.Key === this.file.Key)) {
      this.formService.Files.splice(this.formService.Files.findIndex(x => x.Key === this.file.Key), 1);
    }

    if (this.Control.value !== '') {
      this.DisplayValue = element.DisplayValue;
      this.Control.value = element;
      const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };
      this.file.File = b64toBlob(element.File);
      this.file.FileName = element.FileName;
      this.formService.Files.push(this.file);

      this.Group.get(this.Control.name).setValue(this.Control);
    } else {
      this.Group.controls[this.Control.name].setValue(null);
    }
    this.Control.value = element;
    this.Group.get(this.Control.name).updateValueAndValidity();

  }

  changeImg(e?: any): void {

    //if (this.formService.Files.some((x: FileControl) => x.Id === this.file.Id)) {
    if (this.formService.Files.some((x: FileControl) => x.Key === this.file.Key)) {
      this.formService.Files.splice(this.formService.Files.indexOf(this.file), 1);
    }

    const addFile = e.target.files.item(0);
    this.file.File = addFile;
    this.file.FileName = addFile.name;
    this.formService.Files.push(this.file);

    this.DisplayValue = this.Control.options[0].DisplayValue;
    this.Group.get(this.Control.name).setValue(this.Control);
    this.Group.get(this.Control.name).updateValueAndValidity();
  }

  previewFile() {
    if (this.file.File.type.includes('png') || this.file.File.type.includes('jpeg')) {
      const dialogRef = this.dialog.open(PreviewFileDialogComponent, { data: this.file.File });

      dialogRef.afterClosed().pipe(/*take(1)*/).subscribe((result) => {
      });
    } else {
      window.open(URL.createObjectURL(this.file.File), '_blank');
    }
  }
}
