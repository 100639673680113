import { Module } from './../../pomocnicze/module';
import { Step } from './../../shared/models/form/step.model';
import { DeliveryMethod } from './../../shared/models/form/delivery-method.model';
import { DataSharingService } from './../../shared/service/data-sharing.service';
import { DeliveryEdit } from './../../shared/models/form/delivery-edit.model';
import { DeliveryAddress } from './../../shared/models/form/delivery-address.model';
import { StepOptions } from './../../shared/models/form-model/models/edit-step-options.model';
import { BroadcastService } from './../../shared/async-services/http/broadcast.service';
import { Kim } from './../../shared/models/form-model/kim.model';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { KindOper } from './../../shared/enums/kind-oper-enum';
import { TranslateService } from '@ngx-translate/core';
import { ManageModule, Parameter, FormControls } from './../../shared/models/form-model/models/form-controlls.model';
import { DialogPositionResult } from '../../shared/models/dialog/dialog-position-result.model';
import { ModelComponentEdit, PositionModelComponent } from './../../shared/models/form-model/models/component-edit.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentFactoryResolver, ComponentRef, AfterViewChecked, AfterViewInit, OnDestroy, QueryList, ChangeDetectorRef } from '@angular/core';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, tap, concatMap, distinctUntilChanged, filter, takeWhile } from 'rxjs/operators';
import { FormService } from 'src/app/shared/service/form.service';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { ActiveFormType } from 'src/app/shared/models/form-model/models/active-form.model';
import { FormSet } from 'src/app/shared/models/form-model/models/form-set.model';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { SaveForm } from 'src/app/shared/models/form-model/models/save-form.model';
import { TechnologiaResultInfoComponent } from 'src/app/technologiaResultInfo/technologiaResultInfo.component';
import { DialogPositionComponent } from './dialog-position/dialog-position.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { User } from 'src/app/shared/models/user/user.model';
import { PositionsTab } from 'src/app/shared/models/form-model/models/position.model';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { on, send } from 'process';
import { ElementRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { FileControl } from 'src/app/shared/models/form/file-control.model';
import { SurchargeControl } from 'src/app/shared/models/form/surcharge-control.model';
import { ViewChildren } from '@angular/core';
import { DialogPosition } from 'src/app/shared/models/dialog/dialog-position.model';
import { DeepClone } from 'src/app/shared/deep-clone/deep-clone';
import { TresciService } from 'src/app/tresci.service';
import { Complaint } from 'src/app/shared/models/reklamacje/Complaint.model';

@Component({
  selector: 'app-initial-form',
  templateUrl: './initial-form.component.html',
  styleUrls: ['./initial-form.component.scss'],
})
export class InitialFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('headerForm', { read: ViewContainerRef }) entryHeader: ViewContainerRef;
  @ViewChild('moduleHeaderForm', { read: ViewContainerRef }) entryModuleHeader: ViewContainerRef;
  @ViewChild('moduleFooterForm', { read: ViewContainerRef }) entryModuleFooter: ViewContainerRef;
  @ViewChild('footerForm', { read: ViewContainerRef }) entryFooter: ViewContainerRef;
  // @ViewChild('stepper', { static: true }) private myStepper: MatStepper;
  @ViewChild(TechnologiaResultInfoComponent) technology;
  private subscriptions: Subscription[] = [];
  private isNewTab: boolean;
  private formSets: FormSet[];
  private saveForm: SaveForm;
  private currentStep: string;
  private alert = new Subject<string>();
  private controlListForEdit: { stepName: string; controls: HtmlControl[] }[];
  private componentsEditList: ModelComponentEdit[];
  private dataTable = [];
  private headerFormForSet: FormGroup;
  private footerForm: FormGroup;
  private stepAfterPositionsForNotModules: string;
  private stepForPosition: string;
  private orderId: number;
  private parametersForEdit: Parameter[] = [];
  private parametersKimForEdit: Kim[] = [];
  private broadcastErrorSubscription: Subscription;
  private isStepEditingSubscription: Subscription;
  private kindOper: KindOper;
  private isLoadingAll: boolean;
  private errorSettingValueSubscription: Subscription;
  private blockOtherSetsAndSaveFormSubscription: Subscription;
  private startMultipleVerifySetsSubscription: Subscription;
  private multiStepsForEdit: boolean;
  private startMultipleVerifySets: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public Preview: boolean;
  public TranslationLoaded: BehaviorSubject<boolean>;
  public StopByErrorSettingValue: boolean;
  public CreatedForEditFormAndLoading = false;
  public IsSaving = false;
  public TranslationEnum = TranslationEnum;
  public DisplayedColumns: string[];
  public DynamicColumns: string[] = [];
  public HtmlControls: HtmlControl[];
  public Form: FormGroup;
  public tabs = [{ isError: false, setNumber: 0 }];
  public EndForm = false;
  public IsLoading = true;
  public alertMessage: string;
  public ModuleSelected = new FormControl(0);
  public MainSelected = new FormControl(0);
  public resultInfo;
  public DataSource = new MatTableDataSource(this.dataTable);
  public ManageModule: ManageModule;
  public ShowMoveToFooter: boolean;
  public FinishPosition = false;
  public TechVersion: number;
  public ValuationVersion: number;
  public LoadingName: string;
  public ManageAddingPositionText = 'zakoncz_dodawanie_pozycji';
  public ShowAddNewModule = false;
  public IsLastRight: boolean;
  public StepIsInEdit: boolean;
  public BlockTabsAndSaveForm: boolean;
  public ShowSpinnerVerify = false;
  CreateFooterComponents: any;
  SystemName: any;
  public User: User;
  deliveryMethod: string;
  public IsAddNewPosition: boolean;
  ShowEndPosition: boolean;
  ErrorForSets: boolean;
  VerifySet = false;
  EditHeader: boolean;
  HiddenTableOfPositions: boolean;
  ShowVerifyMessage: boolean;
  enterByButton: boolean;
  IsLoadingByVerify: boolean;
  SetsForVerificationLength = 0;
  IsCopyingSet: boolean;
  multiStepsError = false;
  SetNumber: string;
  PositionNumber = 1;
  howManyModules = 0;
  howManySameSets = 0;
  stepBeforeFooter: string;
  controlsForEditPosition: PositionModelComponent[];
  verifyMultipleSets: boolean;
  StartVerify: boolean;

  constructor(
    public formService: FormService,
    private resolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    private broadcastService: BroadcastService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private authorizationService: AuthorizationService,
    private dataSharingService: DataSharingService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public tresci: TresciService
  ) {
    // super();alive
    this.Form = this.fb.group({});
    this.headerFormForSet = this.fb.group({});
    this.footerForm = this.fb.group({});
    this.formSets = new Array<FormSet>();
    this.saveForm = new SaveForm();
    this.componentsEditList = new Array<ModelComponentEdit>();
    this.controlListForEdit = new Array<{ stepName: ''; controls: [] }>();
    this.ManageModule = new ManageModule();
    this.formService.RemoveControlsFromGroup = [];
    this.formService.TempKimOptions = [];
    this.TranslationLoaded = new BehaviorSubject(false);

    this.subscriptions.push(this.activatedRoute.params.subscribe((params) => {
      this.orderId = params.id;
      this.kindOper = KindOper[params.kindOper as keyof typeof KindOper];
      if(this.kindOper == KindOper.Copy){
        this.formService.CopiedOrderId = this.orderId;
      }
      this.SystemName = 'system_' + params.system.toLowerCase();
      this.formService.ProductPrefix = params.system;
      window['site'] = this.formService.ProductPrefix;
      localStorage.site = this.formService.ProductPrefix;
      // this.TranslationLoaded = new BehaviorSubject(false);
      if(window['culture'] != null && window['culture'] != ''){
        this.translateService.currentLang = window['culture']; 
      }
      
      this.subscriptions.push(this.translateService.reloadLang(this.translateService.currentLang).subscribe((x) => this.TranslationLoaded.next(true)));
    }));
  }

  ngOnInit(): void {
    // this.translateService.reloadLang(this.translateService.currentLang).subscribe(x => this.TranslationLoaded = true);;
    // super.loadScripts();
    this.subscriptions.push(this.alert.subscribe((message) => (this.alertMessage = message)));
    this.subscriptions.push(this.authorizationService.User.subscribe((x) => (this.User = x)));
    if (this.kindOper !== KindOper.Add) {
      this.LoadingName = 'naglowek';
      this.CreatedForEditFormAndLoading = true;
      this.isLoadingAll = true;
    }
    this.Preview = this.kindOper === KindOper.Preview || this.kindOper === KindOper.PreviewClient;

    this.subscriptions.push(this.startMultipleVerifySetsSubscription = this.startMultipleVerifySets.asObservable().subscribe(x => {
      if (x === true) {
        this.verifySet();
      }
    }));

    this.subscriptions.push(this.isStepEditingSubscription = this.dataSharingService.IsStepEditing.asObservable()
      .pipe(distinctUntilChanged())
      .subscribe((x) => {
        this.StepIsInEdit = x;
        this.cdr.detectChanges();
      }));

      this.subscriptions.push(this.blockOtherSetsAndSaveFormSubscription = this.dataSharingService.BlockOtherSetsAndSaveForm.asObservable().subscribe((x) => {
      this.BlockTabsAndSaveForm = x;
    }));

    this.subscriptions.push(this.broadcastErrorSubscription = this.broadcastService.ErrorHttp.asObservable().subscribe((x) => {
      if (x !== null) {
        // console.log('b1', x);
        x = x.replace('System.Exception:','');
        var y= x.split('\\r');
        var z = '';
        if(y.length > 0){
          z = y[0];
        } 
        z = z.replace(' ','');
        z = z.replace('"','');
        this.modalAlertRequest('blad', this.currentStep, z);
        this.CreatedForEditFormAndLoading = false;
        this.VerifySet = false;
        this.IsLoadingByVerify = false;
        this.ShowSpinnerVerify = false;
      }
    }));

    this.subscriptions.push(this.errorSettingValueSubscription = this.dataSharingService.ErrorSettingValue.asObservable().subscribe((x) => {

      if (x !== '') {
        // console.log('b2');
        this.modalAlertRequest('blad', this.currentStep, x);
        this.CreatedForEditFormAndLoading = false;
        this.StopByErrorSettingValue = true;
        this.multiStepsForEdit = false;
        this.IsLoadingByVerify = false;
        this.ShowSpinnerVerify = false;
        // this.multiStepsError = true;
        //  this.VerifySet = false;
      } else {
        this.StopByErrorSettingValue = false;
      }
    }));
  }

  ngAfterViewInit(): void {
    if (this.kindOper === KindOper.Add) {
      this.formService.ActiveFormOrderId = 0;
      this.tresci.IsTrader = window['isTrader'];
      // console.log("trader1", this.tresci.IsTrader);
      this.getStep();
    } else if (this.kindOper === KindOper.Copy) {
      this.formService.OrderId = this.orderId;
      this.formService.ActiveFormOrderId = 0;
      this.tresci.IsTrader = window['isTrader'];
      // console.log("trader2", this.tresci.IsTrader);
      this.getOrderParametersForEdit();
    } else if (this.kindOper === KindOper.EditClient && window['isTrader']) {
      this.formService.IsTrader = !window['isTrader'];
      this.formService.OrderId = this.orderId;
      this.formService.ActiveFormOrderId = this.orderId;
      this.tresci.IsTrader = this.formService.IsTrader;
      // console.log("trader3", this.tresci.IsTrader);
      this.getOrderParametersForEdit();
    } else if (this.kindOper === KindOper.PreviewClient) {
      this.formService.IsTrader = !window['isTrader'];
      this.formService.OrderId = this.orderId;
      this.formService.ActiveFormOrderId = this.orderId;
      this.tresci.IsTrader = this.formService.IsTrader;
      // console.log("trader4", this.tresci.IsTrader);
      this.getOrderParametersForEdit();
    } else {
      this.formService.IsTrader = window['isTrader'];
      this.formService.OrderId = this.orderId;
      this.formService.ActiveFormOrderId = this.orderId;
      this.tresci.IsTrader = this.formService.IsTrader;
      // console.log("trader5", this.tresci.IsTrader);
      this.getOrderParametersForEdit();
    }
  }

  ngOnDestroy(): void {
    // console.log(this);
    this.subscriptions.forEach(e => {

     !e.closed ? e.unsubscribe() : null;
    });
    this.subscriptions= []; ;
    (window as any)['site'] = 'stale';
    this.translateService.reloadLang(this.translateService.currentLang);
    this.dataSharingService.IsStepEditing.next(false);
    this.dataSharingService.BlockOtherNextButton.next({ BlockNextStep: false, CurrentStep: '' });
    this.broadcastErrorSubscription.unsubscribe();
    this.isStepEditingSubscription.unsubscribe();
    this.errorSettingValueSubscription.unsubscribe();
    this.blockOtherSetsAndSaveFormSubscription.unsubscribe();
    this.startMultipleVerifySetsSubscription.unsubscribe();
    this.formService.DeliveryMethod = null;
    this.formService.$DeliveryAddress.next(null);
    this.formService.Surcharges = new Array<SurchargeControl>();
    for (const toRemove of this.controlListForEdit) {
      for (const controlName of toRemove.controls) {
        sessionStorage.removeItem(controlName.name);
      }
    }
    this.formService.Files = new Array<FileControl>();
    Object.keys(this).forEach(key => {
      //Object.keys(this[key]).forEach(key2=> {
       //this[key][key2] = null;
      //});
      this[key] = null;
    });

    
    localStorage.site = "stale";
    window['site'] = "stale";
  }

  returnToOrders(): void {
    if (!this.Preview) {
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: '',
        content: 'czy_na_pewno_chcesz_zamknac',
        answerOk: false,
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          localStorage.setItem('goToPage', '');
          this.router.navigate(['Orders']);
        }
      }));
    } else {
      this.router.navigate(['Orders']);
    }
  }

  public getOrderParametersForEdit() {
    this.subscriptions.push(this.formService
      .getOrderParametersForEdit(this.orderId)
      .pipe(
        concatMap((editForm) => {
          this.parametersForEdit = editForm.Parameters;
          this.parametersKimForEdit = editForm.ParameterExtensions;
          this.multiStepsForEdit = true; //editForm.MultiStepsForEdit;
          this.formService.Surcharges = editForm.Surcharges;
          this.deliveryMethod = editForm.DeliveryMethodId;
          const deliveryEdit = new DeliveryEdit();
          deliveryEdit.DeliveryAdressId = editForm.DeliveryAddressId;
          deliveryEdit.MethodName = editForm.DeliveryMethodId;
          this.formService.$DeliveryAddress.next(deliveryEdit);
          this.formService.$Surcharges.next(this.formService.Surcharges);
          var c = new Complaint();
          // console.log('form',editForm);
          c.ComplaintId = editForm.ComplaintId;
          c.ComplaintNumber = editForm.ComplaintNumber;
          this.formService.Complaint = c;
          if (this.multiStepsForEdit) {
            return this.formService.getFirstMultiSteps(editForm.Parameters);
          } else {
            return this.formService.getFirstStep();
          }
        })
      )
      .subscribe((technologyForm) => {
        // this.CreatedForEditFormAndLoading = true;
        this.ManageModule.IsFooter = technologyForm.IsFooter;
        this.ManageModule.IsModule = technologyForm.IsModule;
        this.ManageModule.IsModuleFooter = technologyForm.IsModuleFooter;
        this.TechVersion = technologyForm.TechVersion;
        this.ValuationVersion = technologyForm.ValuationVersion;

        if (this.multiStepsForEdit) {
          let i = 0;
          for (const step of technologyForm.Steps) {
            i++;
            if (this.multiStepsError === true) {
              break;
            }
            let isLastStepInSpecificType: boolean;
            if (i === technologyForm.Steps.length) {
              isLastStepInSpecificType = true;
              this.currentStep = step.Name;
            }
            this.HtmlControls = this.formService.mapControlsToHtml(step.Controlls, step.Name, isLastStepInSpecificType);
            this.HtmlControls = this.setValuesForMainControls(this.HtmlControls);
            this.currentStep = step.Name;

            this.controlListForEdit.push({ stepName: step.Name, controls: this.HtmlControls });
            this.createComponentForSpecificStepType(technologyForm.Type);
          }
        } else {
          this.HtmlControls = this.formService.mapControlsToHtml(technologyForm.Controlls, technologyForm.Step, true);

          this.currentStep = technologyForm.Step;
          for (const control of this.HtmlControls) {
            const parameter = this.parametersForEdit.find((x) => x.Key.toLowerCase() === control.name.toLowerCase());
            if (parameter !== undefined) {
              if (control.inputType !== 'hidden') {
                if (control.options !== undefined && control.options?.length !== 0) {
                  control.value = control.options.find((x) => x.Key === parameter.Value);
                } else if (control.optionsSelectMultiColumn !== undefined && control.optionsSelectMultiColumn?.length !== 0) {
                  control.value = control.optionsSelectMultiColumn.find((x: { Key: any }) => x.Key === parameter.Value);
                } else if (control.componentName !== 'Pdf') {
                  control.value = parameter.Value;
                }
              } else {
                if (control.options !== undefined && control.options?.length !== 0) {
                  control.value = control.options[0];
                }
              }
            }
          }

          this.ModuleSelected.setValue(0);
          this.createComponentForSpecificStepType(technologyForm.Type);
          this.controlListForEdit.push({ stepName: technologyForm.Step, controls: this.HtmlControls });
        }

        this.IsLoading = false;
      }));
  }

  public getStep(): void {
    this.subscriptions.push(this.formService.getFirstStep().subscribe((technologyForm) => {
      this.ManageModule.IsFooter = technologyForm.IsFooter;
      this.ManageModule.IsModule = technologyForm.IsModule;
      this.ManageModule.IsModuleFooter = technologyForm.IsModuleFooter;
      this.TechVersion = technologyForm.TechVersion;
      this.ValuationVersion = technologyForm.ValuationVersion;

      this.currentStep = technologyForm.Step;
      this.HtmlControls = this.formService.mapControlsToHtml(technologyForm.Controlls, technologyForm.Step);
      this.createComponentForSpecificStepType(technologyForm.Type);
      this.controlListForEdit.push({ stepName: technologyForm.Step, controls: this.HtmlControls });
      this.IsLoading = false;
    }));
  }

  private createComponentForSpecificStepType(moduleType: string): void {
    const factory = this.resolver.resolveComponentFactory(DynamicFormComponent);
    let componentRef: ComponentRef<DynamicFormComponent>;
    let form: FormGroup;
    let position: number;
    let controls: HtmlControl[];

    const formSet = this.formSets[this.ModuleSelected.value];
    switch (moduleType) {
      case ActiveFormType[ActiveFormType.Header]:
        form = this.Form;
        position = 0;
        componentRef = this.entryHeader.createComponent(factory);
        this.setComponentRefInstances(componentRef, this.HtmlControls, form, position, moduleType, 0, 0);
        this.addComponentsForSaveToList(moduleType, componentRef, form, 0, this.currentStep, 0);
        break;

      case ActiveFormType[ActiveFormType.ModuleHeader]:
        window.scrollTo(0, 0);
        this.MainSelected.setValue(1);
        position = 0;
        if (this.formSets.length > 1) {
          this.dataSharingService.BlockOtherSetsAndSaveForm.next(true);
        }
        if (formSet) {
          this.LoadingName = 'zestaw_lad';
          this.SetNumber = JSON.stringify(formSet.GetModuleNr());
          form = formSet.SetForm;
          const setControls = formSet.SetControls;
          controls = formSet.SetControls[setControls.length - 1].HtmlControl;
          componentRef = this.entryModuleHeader.createComponent(factory);
          this.setComponentRefInstances(componentRef, controls, form, position, moduleType, ActiveFormType.ModuleHeader, formSet.GetModuleNr());
          this.addComponentsForSaveToList(moduleType, componentRef, form, ActiveFormType.ModuleHeader, formSet.CurrentStepForSet, this.ModuleSelected.value + 1);
        } else {
          throw console.error('Brak formy: ' + formSet);
        }
        break;

      case ActiveFormType[ActiveFormType.Position]:
        window.scrollTo(0, 0);
        if (this.ManageModule.IsModule) {
          this.dataSharingService.BlockOtherSetsAndSaveForm.next(true);
        }
        if (formSet) {
          const index = formSet.SetControls.findIndex((x) => x.Step === formSet.CurrentStepForSet && x.ModuleType === moduleType);
          // const setControl = { ModuleType: moduleType, HtmlControl: controls, Step: step };
          // formSet.SetControls.splice(index, 1);
          // formSet.SetControls.push({ ModuleType: moduleType, HtmlControl: this.HtmlControls, Step: formSet.CurrentStepForSet });
          this.manageSets(moduleType, this.HtmlControls, formSet.CurrentStepForSet);
        }
        if (!this.VerifySet) {
          if (!this.ManageModule.IsModule) {
            this.MainSelected.setValue(1);
          }
          this.openPositionDialog();
        } else {
          // if (this.DataSource.data.length !== 0) {
          this.verifyPosition(this.DataSource.data[0]);
          // }
        }
        break;

      case ActiveFormType[ActiveFormType.ModuleFooter]:
        position = 0;
        if (formSet) {
          form = formSet.SetForm;
          const setControls = formSet.SetControls;
          controls = formSet.SetControls[setControls.length - 1].HtmlControl;
          componentRef = this.entryModuleFooter.createComponent(factory);

          for (const iterator of Object.keys(form.controls)) {
          }
          this.setComponentRefInstances(componentRef, controls, form, position, moduleType, ActiveFormType.ModuleFooter, formSet.GetModuleNr());
          this.addComponentsForSaveToList(moduleType, componentRef, form, ActiveFormType.ModuleFooter, formSet.CurrentStepForSet, this.ModuleSelected.value + 1);
        } else {
          throw console.error('Brak formy: ' + formSet);
        }
        break;

      case ActiveFormType[ActiveFormType.Footer]:
        this.LoadingName = 'dodatków';
        this.SetNumber = '';
        this.ShowMoveToFooter = true;
        position = 0;
        componentRef = this.entryFooter.createComponent(factory);
        this.setComponentRefInstances(componentRef, this.HtmlControls, this.footerForm, position, moduleType, ActiveFormType.Footer, 0);
        this.addComponentsForSaveToList(moduleType, componentRef, this.footerForm, ActiveFormType.Footer, this.currentStep, 0);
        break;

      default:
        throw console.error('Brak obsługi module type: ' + moduleType);
    }
  }

  private manageSets(moduleType: string, controls: HtmlControl[], step: string) {

    if (!this.formSets[this.ModuleSelected.value]) {
      const formSet = new FormSet();
      formSet.SetId = this.ModuleSelected.value; 
      formSet.ModuleType = moduleType;
      formSet.SetForm = _.cloneDeep(this.headerFormForSet);
      formSet.SetControls.push({ ModuleType: moduleType, HtmlControl: controls, Step: step });
      formSet.CurrentStepForSet = this.currentStep;
      this.formSets.push(formSet);
    } else {
      const formSet = this.formSets.find((x) => x.SetId === this.ModuleSelected.value);
      const formIndex = this.formSets.findIndex((x) => x.SetId === this.ModuleSelected.value); 
      formSet.SetId = this.ModuleSelected.value;
      const index = formSet.SetControls.findIndex((x) => x.Step === step && x.ModuleType === moduleType);

      const setControl = { ModuleType: moduleType, HtmlControl: controls, Step: step };
      formSet.SetControls.push({ ModuleType: moduleType, HtmlControl: controls, Step: step });
      // formSet.SetControls.splice(index, 1);
      // const setControl = { ModuleType: moduleType, HtmlControl: controls, Step: step };
      // formSet.SetControls.splice(index, 1, setControl);

      // formSet.SetControls = formSet.SetControls.filter((x) => {
      //   return x.ModuleType === moduleType;
      // });
      this.formSets.splice(formIndex, 1, formSet);
      // console.log('formSets', this.formSets);
      var x = this.formSets; 
    }
  }

  public editPosition(row: any) {
    if (this.ShowVerifyMessage) {
      // console.log('b3');
      this.modalAlertRequest('ostrzezenie', '', 'zestaw_wymaga_weryfikacji');
      return;
    }

    for (const setPosition of this.formSets[this.ModuleSelected.value].SetPositionsComponents) {
      Object.keys(this.headerFormForSet.controls).forEach((key) => {
        if (setPosition.Form.controls[key] !== undefined) {
          if (this.headerFormForSet.controls[key].value !== null && this.headerFormForSet.controls[key].value.value !== undefined) {
            setPosition.Form.controls[key].value.value = this.headerFormForSet.controls[key].value.value;
          }
        } else {
          if (this.headerFormForSet.controls[key].value !== null) {
            const cloneControl = _.cloneDeep(this.headerFormForSet.controls[key].value);
            if (cloneControl !== undefined) {
              const newControl = this.fb.control(cloneControl);
              setPosition.Form.addControl(cloneControl.name, newControl);
            }
          }
        }
      });
    }

    const positionToEdit = this.formSets[this.ModuleSelected.value].SetPositionsComponents.find((x) => x.PositionId === row['pos']);
    const posLegnth = this.formSets[this.ModuleSelected.value].SetPositionsComponents.length;
    const dialogRef = this.dialog.open(DialogPositionComponent, {
      maxWidth: Math.round(window.innerWidth * 0.6569),
      // maxHeight: Math.round(window.innerHeight * 0.9595),
      width: '100%',
      // height: '100%',
      data: {
        IsEditPosition: true,
        HeaderForm: this.headerFormForSet,
        Form: positionToEdit.Form,
        PreviousPosition: this.formSets[this.ModuleSelected.value].SetPositionsComponents[posLegnth - 1],
        PositionToEdit: positionToEdit,
        PositionId: positionToEdit.PositionId,
        ManageModule: this.ManageModule,
        IsModule: this.ManageModule.IsModule,
        Set: this.ModuleSelected.value,
        KindOper: KindOper.Edit,
        Module: this.ModuleSelected.value + 1,
        MultiStepsForEdit: this.multiStepsForEdit,
      },
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((x: DialogPositionResult) => {
      this.dataSharingService.IsStepEditing.next(false);
      this.dataSharingService.BlockOtherNextButton.next({ BlockNextStep: false, CurrentStep: '' });
      if (x) {
        const index = this.dataTable.findIndex((pos) => pos.pos === positionToEdit.PositionId);
        const dtLength = this.dataTable.length;
        const customModularity =
          this.formSets[this.ModuleSelected.value].SetForm.controls['JakaModułowość'] !== undefined &&
          this.formSets[this.ModuleSelected.value].SetForm.controls['JakaModułowość'].value.value.Key.toLowerCase() === 'tak';

        if (customModularity === false) {
          if (this.dataTable[dtLength - 1]['modul_klucz'] !== 'P1') {
            this.IsLastRight = false;
          } else {
            this.IsLastRight = true;
          }
        }

        this.formSets[this.ModuleSelected.value].CurrentStepForSet = x.LastStep;
        this.formSets[this.ModuleSelected.value].LastStepBeforeFooter = x.LastStep;
        this.formSets[this.ModuleSelected.value].LastStepBeforeModuleFooter = x.LastStep;
        this.dataTable.splice(index, 1, x.PositionsTab.Controls);
        this.DataSource = new MatTableDataSource(this.dataTable);
        const indexOfEditCmp = this.formSets[this.ModuleSelected.value].SetPositionsComponents.findIndex((cmp) => cmp.PositionId === positionToEdit.PositionId);
        this.formSets[this.ModuleSelected.value].SetPositionsComponents.splice(indexOfEditCmp, 1, x.PositionToEdit);
        this.formSets[this.ModuleSelected.value].SetPositionsTab.Controls[positionToEdit.PositionId - 1] = x.PositionsTab.Controls;

      }
    }));
  }

  public openPositionDialog(): void {
    let dialogRef: MatDialogRef<DialogPositionComponent>;

    // console.log('this.ModuleSelected.value', this.ModuleSelected.value);
    const formSet = this.formSets[this.ModuleSelected.value];

    const customModularity =
      formSet.SetForm.controls['JakaModułowość'] !== undefined &&
      ((formSet.SetForm.controls['JakaModułowość'].value.value.Key !== undefined && formSet.SetForm.controls['JakaModułowość'].value.value.Key.toLowerCase() === 'tak') ||
        (formSet.SetForm.controls['JakaModułowość'].value.value !== undefined && formSet.SetForm.controls['JakaModułowość'].value.value === 'tak'));

    if (customModularity === false && this.formService.ProductPrefix.toLowerCase() !== 'ma') {
      if (formSet.SetForm.controls['CzyModuł'] === undefined || formSet.SetForm.controls['CzyModuł'].value.value.Key === 'NIE') {
        this.IsAddNewPosition = false;
        this.ShowEndPosition = true;
      } else if (
        formSet.SetForm.controls['CzyModuł'] !== undefined &&
        (formSet.SetForm.controls['CzyModuł'].value.value.Key === 'TAK' ||
          formSet.SetForm.controls['CzyModuł'].value.value.Key === 'TAKPT0405' ||
          formSet.SetForm.controls['CzyModuł'].value.value.Key === 'MULTIZIIP120')
      ) {
        this.IsAddNewPosition = true;
        this.ShowEndPosition = false;
      } else {
        this.IsAddNewPosition = true;
        this.ShowEndPosition = true;
      }
    } else {
      this.IsAddNewPosition = true;
      this.ShowEndPosition = true;
      this.IsLastRight = false;
    }
    const positionsLenght = formSet.SetPositionsComponents.length;
    const tempFormForPos = _.cloneDeep(this.headerFormForSet);

    const parametersForEditPosition: Parameter[] = [];
    const kimForEditPosition: Kim[] = [];
    if (this.CreatedForEditFormAndLoading) {
      for (const parameter of this.parametersForEdit) {
        if (parameter.Type === ActiveFormType.Position && parameter.Module === formSet.GetModuleNr() && parameter.Position === formSet.SetPositionsComponents.length + 1) {
          parametersForEditPosition.push(parameter);
        }
      }
      for (const kim of this.parametersKimForEdit) {
        if (kim.Module === formSet.GetModuleNr() && kim.Position === formSet.SetPositionsComponents.length + 1) {
          kimForEditPosition.push(kim);
        }
      }
    }

    if (this.ManageModule.IsModule) {
      dialogRef = this.dialog.open(DialogPositionComponent, {
        maxWidth: Math.round(window.innerWidth * 0.6569),
        // maxHeight: Math.round(window.innerHeight * 0.9595),
        width: '100%',
        // height: '100%',
        disableClose: true,
        panelClass: this.CreatedForEditFormAndLoading ? 'invisible' : '',
        backdropClass: this.CreatedForEditFormAndLoading ? 'd-none' : '',
        data: {
          Controls: formSet.SetControls.find((x) => x.ModuleType === 'Position').HtmlControl,
          HeaderForm: this.headerFormForSet,
          PreviousPosition: formSet.SetPositionsComponents[positionsLenght - 1],
          Form: formSet.SetForm,
          PositionId: this.DataSource.data.length + 1,
          Module: formSet.GetModuleNr(),
          CurrentStep: this.stepForPosition,
          IsEditPosition: false,
          ManageModule: this.ManageModule,
          IsOpenByEdit: this.CreatedForEditFormAndLoading,
          ParametersForEdit: parametersForEditPosition,
          KimForEdit: kimForEditPosition,
          Set: formSet.SetId,
          KindOper: KindOper.Add,
          MultiStepsForEdit: this.multiStepsForEdit,
        },
      });
    } else {

      dialogRef = this.dialog.open(DialogPositionComponent, {
        maxWidth: Math.round(window.innerWidth * 0.6569),
        // maxHeight: Math.round(window.innerHeight * 0.9595),
        width: '100%',
        // height: '100%',
        disableClose: true,
        panelClass: this.CreatedForEditFormAndLoading ? 'invisible' : '',
        backdropClass: this.CreatedForEditFormAndLoading ? 'd-none' : '',
        data: {
          Controls: this.controlsForEditPosition, //formSet.SetControls.find((x) => x.ModuleType === 'Position').HtmlControl,
          HeaderForm: this.headerFormForSet,
          Form: tempFormForPos,
          PositionId: this.DataSource.data.length + 1,
          CurrentStep: this.stepForPosition,
          Module: formSet.GetModuleNr(),
          IsEditPosition: false,
          ManageModule: this.ManageModule,
          IsOpenByEdit: this.CreatedForEditFormAndLoading,
          ParametersForEdit: parametersForEditPosition,
          KimForEdit: kimForEditPosition,
          KindOper: KindOper.Add,
          MultiStepsForEdit: this.multiStepsForEdit,
        },
      });
    }

    this.subscriptions.push(dialogRef.beforeClosed().subscribe((x: DialogPositionResult) => {
      if (x) {
        if (this.DynamicColumns.length === 0) {
          this.DynamicColumns = x.PositionsTab.ColumnNames;
          this.DisplayedColumns = Array.from(x.PositionsTab.ColumnNames);
        } else {
          for (const columnName of x.PositionsTab.ColumnNames) {
            const ifExist = this.DynamicColumns.find((c) => c === columnName);
            if (ifExist === undefined) {
              const index = x.PositionsTab.ColumnNames.findIndex((i) => i === columnName) + 1;
              this.DynamicColumns.splice(index, 0, columnName);
              this.DisplayedColumns.splice(index, 0, columnName);
            }
          }
        }
      }
    }));

    this.subscriptions.push(dialogRef.afterClosed().subscribe((x: DialogPositionResult) => {
      if (this.StopByErrorSettingValue) {
        if (!this.VerifySet) {
          this.CreatedForEditFormAndLoading = false;
        }
        this.StopByErrorSettingValue = false;
      }
      this.dataSharingService.IsStepEditing.next(false);
      this.dataSharingService.BlockOtherNextButton.next({ BlockNextStep: false, CurrentStep: '' });
      if (x) {
        this.dataTable.push(x.PositionsTab.Controls);
        this.DataSource.data = Array.from(this.dataTable);
        formSet.SetPositionsComponents.push(x.PositionToEdit);
        if (this.kindOper !== KindOper.Add && this.isLoadingAll) {
          this.CreatedForEditFormAndLoading = true;
        }
        formSet.CurrentStepForSet = x.LastStep;
        formSet.LastStepBeforeFooter = x.LastStep;
        formSet.LastStepBeforeModuleFooter = x.LastStep;
        if (formSet.SetPositionsTab === undefined) {
          formSet.SetPositionsTab = new PositionsTab();
        }
        formSet.SetPositionsTab.ColumnNames = this.DynamicColumns;
        formSet.SetPositionsTab.Controls.push(x.PositionsTab.Controls);
        if (this.CreatedForEditFormAndLoading) {
          const modulePositions = this.parametersForEdit.filter((p) => p.Type === ActiveFormType.Position && p.Module === formSet.GetModuleNr());
          let howManyPositions = 0;
          for (const pos of modulePositions) {
            if (pos.Position > formSet.SetPositionsComponents.length) {
              howManyPositions = pos.Position;
            }
          }

          if (howManyPositions > formSet.SetPositionsComponents.length) {
            this.openPositionDialog();
          } else {
            this.manageFooterAndFinishPosition();
          }
        }
        if (customModularity === false) {
          if (x.PositionsTab.Controls['modul_klucz'] !== 'P1') {
            this.IsLastRight = false;
          } else {
            this.IsLastRight = true;
          }
        }
      } else {
        if (this.DataSource.data.length <= 0) {
          let list: any[];
          if (this.ManageModule.IsModule) {
            if (this.tabs.length === 1) {
              this.addTab();
              this.ModuleSelected.setValue(0);
              this.deleteSet();
            } else {
              // this.ModuleSelected.setValue(0);
              this.deleteSet();
              this.addTab();
              this.isNewTab = false;
            }
          } else {
            this.MainSelected.setValue(0);
            list = this.componentsEditList[0].Components;
            list[list.length - 1].Component.instance.editCurrentStep(list[list.length - 1].Step, true);
            list[list.length - 1].Component.instance.EnableMoveToNextStep = true;
            setTimeout(() => window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight), 100);
          }
        }
      }
    }));
  }

  public deletePosition(row: any) {
    if (row['modul_klucz'] !== undefined && row['modul_klucz'].toUpperCase() === '1P' && this.dataTable.length > 1) {
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: '',
        content: 'usuniecie_modulu_skrajnie_lewego_spowoduje_usuniecie_reszty_pozycji_czy_chcesz_kontynuowac',
        answerOk: false,
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.dataTable = [];
          this.DataSource = new MatTableDataSource(this.dataTable);
          this.formSets[this.ModuleSelected.value].SetPositionsComponents = [];
          this.formSets[this.ModuleSelected.value].SetPositionsTab.ColumnNames = [];
          this.formSets[this.ModuleSelected.value].SetPositionsTab.Controls = [];
          this.DisplayedColumns = [];
          this.DynamicColumns = [];
          this.openPositionDialog();
          this.IsLastRight = false;
        }
      }));
    } else if (row['modul_klucz'] !== undefined && row['modul_klucz'].toUpperCase() === 'PP' && this.dataTable.length > 1) {
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: '',
        content: 'Usunięcie modułu wewnętrznego spowoduje usunięcie również skrajnie prawego. Czy chcesz mimo to kontynuować?',
        answerOk: false,
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          if (row['modul_klucz'] !== undefined) {
            this.IsLastRight = false;
          }
          this.IsAddNewPosition = true;
          this.ShowEndPosition = false;
          const indexToRemove = this.formSets[this.ModuleSelected.value].SetPositionsComponents.findIndex((x) => x.PositionId === row['pos']);
          const controlsIndexRemove = this.formSets[this.ModuleSelected.value].SetPositionsTab.Controls.findIndex((x: { PositionId: any }) => x.PositionId === row['pos']);

          const controlsLength = this.formSets[this.ModuleSelected.value].SetPositionsTab.Controls.length;
          const cmpsLength = this.formSets[this.ModuleSelected.value].SetPositionsComponents.length;
          this.formSets[this.ModuleSelected.value].SetPositionsTab.Controls.splice(controlsIndexRemove, controlsLength);
          this.formSets[this.ModuleSelected.value].SetPositionsComponents.splice(indexToRemove, cmpsLength);
          const indexOnTable = this.dataTable.findIndex((x: { pos: any }) => x.pos === row['pos']);
          const lengthOfTable = this.dataTable.length;
          this.dataTable.splice(indexOnTable, lengthOfTable);
          this.DataSource.data = this.dataTable;
          if (this.dataTable.length === 0) {
            this.openPositionDialog();
          }
        }
      }));
    } else {
      if (row['modul_klucz'] !== undefined) {
        this.IsLastRight = false;
      }
      const indexToRemove = this.formSets[this.ModuleSelected.value].SetPositionsComponents.findIndex((x) => x.PositionId === row['pos']);
      const controlsIndexRemove = this.formSets[this.ModuleSelected.value].SetPositionsTab.Controls.findIndex((x) => x.PositionId === row['pos']);
      this.formSets[this.ModuleSelected.value].SetPositionsTab.Controls.splice(controlsIndexRemove, 1);
      this.formSets[this.ModuleSelected.value].SetPositionsComponents.splice(indexToRemove, 1);
      const indexOnTable = this.dataTable.findIndex((x) => x.PositionId === row['pos']);
      this.dataTable.splice(indexOnTable, 1);
      this.DataSource.data = this.dataTable;
      if (this.dataTable.length === 0) {
        this.openPositionDialog();
      }
    }
  }

  public manageFooterAndFinishPosition() {
    if (this.ShowVerifyMessage) {
      // console.log('b4');
      this.modalAlertRequest('ostrzezenie', '', 'zestaw_wymaga_weryfikacji');
      return;
    }
    if (this.FinishPosition === false) {

      if (this.ManageModule.IsModule) {
        const formSet = this.formSets.find((x) => x.SetId === this.ModuleSelected.value);

        this.formService.RemoveControlsFromGroup.forEach((control) => {
          formSet.SetForm.removeControl(control.name);
        });

        const controlsSumUp = [];
        for (const iterator of formSet.SetPositionsComponents) {
          Object.keys(iterator.Form.controls).forEach((key) => {
            const controlKey = iterator.Form.controls[key];
            if (controlKey.value !== null && controlKey.value.type === ActiveFormType.Position && (controlKey.value.inputType === 'number' || controlKey.value.inputType === 'hidden')) {
              if (isNaN(controlKey.value.value) === false || isNaN(controlKey.value.value.Key) === false) {
                const controlForSum = controlsSumUp.find((x) => x.key === 'suma_' + key);
                const controlForMin = controlsSumUp.find((x) => x.key === 'min_' + key);
                const controlForMax = controlsSumUp.find((x) => x.key === 'max_' + key);

                if (controlForSum) {
                  if (isNaN(controlKey.value.value) === false) {
                    controlForSum.value += +controlKey.value.value;
                  } else if (isNaN(controlKey.value.value.Key) === false) {
                    controlForSum.value += +controlKey.value.value.Key;
                  }
                } else {
                  if (isNaN(controlKey.value.value) === false) {
                    controlsSumUp.push({ key: 'suma_' + key, value: +controlKey.value.value });
                  } else if (isNaN(controlKey.value.value.Key) === false) {
                    controlsSumUp.push({ key: 'suma_' + key, value: +controlKey.value.value.Key });
                  }
                }

                if (controlForMin) {
                  if (isNaN(controlKey.value.value) === false) {
                    controlForMin.value = Math.min(controlForMin.value, +controlKey.value.value);
                  } else if (isNaN(controlKey.value.value.Key) === false) {
                    controlForMin.value = Math.min(controlForMin.value, +controlKey.value.value.Key);
                  }
                } else {
                  if (isNaN(controlKey.value.value) === false) {
                    controlsSumUp.push({ key: 'min_' + key, value: +controlKey.value.value });
                  } else if (isNaN(controlKey.value.value.Key) === false) {
                    controlsSumUp.push({ key: 'min_' + key, value: +controlKey.value.value.Key });
                  }
                }

                if (controlForMax) {
                  if (isNaN(controlKey.value.value) === false) {
                    controlForMax.value = Math.max(controlForMax.value, +controlKey.value.value);
                  } else if (isNaN(controlKey.value.value.Key) === false) {
                    controlForMax.value = Math.max(controlForMax.value, +controlKey.value.value.Key);
                  }
                } else {
                  if (isNaN(controlKey.value.value) === false) {
                    controlsSumUp.push({ key: 'max_' + key, value: +controlKey.value.value });
                  } else if (isNaN(controlKey.value.value.Key) === false) {
                    controlsSumUp.push({ key: 'max_' + key, value: +controlKey.value.value.Key });
                  }
                }
              }
            }
          });
        }

        let controlHowManySame = 0;

        for (const set of this.formSets) {
          controlHowManySame = controlHowManySame + +set.SetForm.controls['IleJednakowych'].value.value * set.SetPositionsComponents.length;

        }
        controlsSumUp.push({ key: 'suma_IleJednakowych', value: controlHowManySame });

        const htmlControlArray: HtmlControl[] = new Array<HtmlControl>();
        if (controlsSumUp.length > 0) {
          for (const control of controlsSumUp) {
            const htmlControl: HtmlControl = new HtmlControl();
            htmlControl.inputType = 'hidden';
            htmlControl.componentName = 'Hidden';
            htmlControl.name = control.key;
            htmlControl.currentStep = formSet.CurrentStepForSet;
            htmlControl.module = formSet.GetModuleNr();
            htmlControl.position = 0;
            htmlControl.type = ActiveFormType.ModuleFooter;
            htmlControl.value = control.value;
            const controlHtml = this.fb.control(htmlControl);
            formSet.SetForm.addControl(htmlControl.name, controlHtml);
            htmlControlArray.push(htmlControl);
          }
          formSet.SetControls.push({ ModuleType: ActiveFormType[ActiveFormType.ModuleFooter], HtmlControl: htmlControlArray, Step: 'OwnHidden' });
          // formSet.SetMainComponents.find((x) => x.ModuleType === ActiveFormType[ActiveFormType.ModuleFooter]).Components;
          this.HtmlControls = htmlControlArray;
          if (this.multiStepsForEdit) {
            const parametersForEditModuleFooter = this.parametersForEdit.filter((x) => x.Type === ActiveFormType.ModuleFooter && x.Module === formSet.GetModuleNr());
            // TODO:WITAM
            this.subscriptions.push(this.formService
              .getMultiStepsFooter(formSet, parametersForEditModuleFooter, ActiveFormType[ActiveFormType.ModuleFooter])
              .pipe(
                tap((technologyForm) => {
                  if (technologyForm.Steps.length > 0) {
                    let i = 0;

                    for (const step of technologyForm.Steps) {
                      i++;
                      if (this.multiStepsError === true) {
                        break;
                      }
                      let isLastStepInSpecificType: boolean;
                      let sendStep: string;
                      if (i === technologyForm.Steps.length) {
                        isLastStepInSpecificType = true;
                        sendStep = step.Name;
                        // this.stepBeforeFooter = sendStep;
                        this.formSets[this.ModuleSelected.value].LastStepBeforeFooter = sendStep;
                      }
                      this.HtmlControls = this.formService.mapControlsToHtml(step.Controlls, step.Name, isLastStepInSpecificType);
                      this.manageSets(technologyForm.Type, this.setValuesForModuleControls(this.HtmlControls), step.Name);
 
                      const currentSet = this.formSets[this.ModuleSelected.value];
                      currentSet.CurrentStepForSet = step.Name;
                      this.controlListForEdit.push({ stepName: step.Name, controls: this.HtmlControls });
                      this.createComponentForSpecificStepType(ActiveFormType[ActiveFormType.ModuleFooter]);
                    }
                  } else {
                    this.moveToNextStepForModuleFooter(formSet);
                  }
                })
              )
              .subscribe());
          } else {
            formSet.CurrentStepForSet = formSet.LastStepBeforeModuleFooter;
            this.moveToNextStepForModuleFooter(formSet);
            // this.createComponentForSpecificStepType(ActiveFormType[ActiveFormType.ModuleFooter]);
          }
        } else {
          this.moveToNextStepForModuleFooter(formSet);
        }
      } else {
        const isComponentCreated = this.saveForm.MainComponents.find((x) => x.ModuleType === ActiveFormType[ActiveFormType.Footer]);
        if (isComponentCreated === undefined) {
          this.stepAfterPositionsForNotModules = this.formSets[this.ModuleSelected.value].CurrentStepForSet;
          this.moveToNextStepOrEdit(this.headerFormForSet, ActiveFormType.Footer.toString(), null);
        } else if (this.VerifySet) {
          // console.log('b5');
          this.modalAlertRequest('', '', 'weryfikacja_przeszla_pomyslnie');
          this.VerifySet = false;
          this.IsLoadingByVerify = false;
          this.ShowSpinnerVerify = false;
          this.tabs[this.ModuleSelected.value].isError = false;
          const indexTab = this.tabs.findIndex((x) => x.isError === true);
          if (indexTab < 0) {
            this.ErrorForSets = false;
          }
        }
        this.ErrorForSets = false;
      }
      this.FinishPosition = true;
      this.ManageAddingPositionText = 'edytuj_pozycje';
    } else {
      // this.dataSharingService.IsStepEditing.next(true);
      this.FinishPosition = false;
      if (this.ManageModule.IsModule) {
        const formSet = this.formSets[this.ModuleSelected.value];

        const customModularity = formSet.SetForm.controls['JakaModułowość'] !== undefined && formSet.SetForm.controls['JakaModułowość'].value.value.Key.toLowerCase() === 'tak';
        if (customModularity === false && this.formService.ProductPrefix.toLowerCase() !== 'ma') {
          if (formSet.SetForm.controls['CzyModuł'] === undefined || formSet.SetForm.controls['CzyModuł'].value.value.Key === 'NIE') {
            this.IsAddNewPosition = false;
            this.ShowEndPosition = true;
          } else if (
            formSet.SetForm.controls['CzyModuł'] !== undefined &&
            (formSet.SetForm.controls['CzyModuł'].value.value.Key === 'TAK' ||
              formSet.SetForm.controls['CzyModuł'].value.value.Key === 'TAKPT0405' ||
              formSet.SetForm.controls['CzyModuł'].value.value.Key === 'MULTIZIIP120')
          ) {
            this.IsAddNewPosition = true;
            this.ShowEndPosition = false;
          } else {
            this.IsAddNewPosition = true;
            this.ShowEndPosition = true;
          }

          for (const control of formSet.SetPositionsTab.Controls) {
            if (control['modul_klucz'] !== 'P1') {
              this.IsLastRight = false;
            } else {
              this.IsLastRight = true;
            }
          }
        } else {
          this.IsAddNewPosition = true;
          this.ShowEndPosition = true;
          this.IsLastRight = false;
        }

        for (const control of this.formService.RemoveControlsFromGroup) {
          if (control.module === formSet.GetModuleNr())
          {
            formSet.SetForm.removeControl(control.name);
          }
        }

        formSet.CurrentStepForSet = formSet.LastStepBeforeFooter;
        const index = formSet.SetControls.findIndex(x => x.ModuleType === ActiveFormType[ActiveFormType.ModuleFooter]);
        const a =formSet.SetControls.splice(index);
        formSet.SetMainComponents.forEach((x) => {
          if (x.ModuleType === ActiveFormType[ActiveFormType.ModuleFooter]) {
            const index = formSet.SetMainComponents.findIndex((id) => id.ModuleType === x.ModuleType);
            formSet.SetMainComponents.splice(index, 1);
            Object.keys(formSet.SetForm.controls).forEach((key) => {

              if (formSet.SetForm.controls[key].value.module === formSet.GetModuleNr() && formSet.SetForm.controls[key].value.type === ActiveFormType.ModuleFooter) {
                formSet.SetForm.removeControl(key);
              }
            });
            this.entryModuleFooter.clear();
          }
        });
      }
      if (this.ManageModule.IsModule) {
        this.dataSharingService.BlockOtherSetsAndSaveForm.next(true);
      }
      this.ManageAddingPositionText = 'zakoncz_dodawanie_pozycji';
      this.ShowAddNewModule = false;
      if (!this.ManageModule.IsFooter) {
        this.EndForm = false;
      }
    }
  }

  private addComponentsForSaveToList(moduleType: string, componentRef: ComponentRef<DynamicFormComponent>, form: FormGroup, dataType: number, step: string, module: number) {
    const isComponent = this.componentsEditList.find((x) => x.ModuleType === moduleType && x.Type === dataType && x.Module === module);

    if (isComponent === undefined) {
      if (dataType !== ActiveFormType.ModuleHeader && dataType !== ActiveFormType.ModuleFooter) {
        this.componentsEditList.push({ ModuleType: moduleType, Components: [{ Component: componentRef, Step: step }], Form: form, Type: dataType, Module: 0 });
      }
    } else {
      if (dataType !== ActiveFormType.ModuleHeader && dataType !== ActiveFormType.ModuleFooter) {
        this.componentsEditList.find((x) => x.ModuleType === moduleType && x.Type === dataType && x.Module === 0).Components.push({ Component: componentRef, Step: step });
      }
    }
    if (dataType !== ActiveFormType.Header && dataType !== ActiveFormType.Footer) {
      if (dataType === ActiveFormType.ModuleHeader || dataType === ActiveFormType.ModuleFooter) {
        if (this.formSets[this.ModuleSelected.value].SetMainComponents.find((m) => m.Module === module && m.Type === dataType) === undefined) {
          this.formSets[this.ModuleSelected.value].SetMainComponents.push({
            ModuleType: moduleType,
            Components: [{ Component: componentRef, Step: step }],
            Form: form,
            Type: dataType,
            Module: module,
          });
        } else {
          this.formSets[this.ModuleSelected.value].SetMainComponents.find((m) => m.Module === module && m.Type === dataType).Components.push({ Component: componentRef, Step: step });
        }
      }
    } else {
      this.saveForm.MainComponents = this.componentsEditList;
    }
  }

  public loadModuleSetPositions(tabIndex: number): void {
    if (!this.isNewTab) {
      if (this.ShowVerifyMessage) {
        // console.log('b6');
        this.modalAlertRequest('ostrzezenie', '', 'zestaw_wymaga_weryfikacji');
      }
      this.ModuleSelected.setValue(tabIndex);
      this.entryModuleHeader.clear();
      this.entryModuleFooter.clear();
      this.dataTable = [];
      this.DataSource = new MatTableDataSource(this.dataTable);
      const factory = this.resolver.resolveComponentFactory(DynamicFormComponent);
      let componentRef: ComponentRef<DynamicFormComponent>;

      const selectedForm = this.formSets[this.ModuleSelected.value];
      if (selectedForm !== undefined) {
        if (selectedForm.SetForm.controls['JakaModułowość'] === undefined || selectedForm.SetForm.controls['JakaModułowość'].value.value.Key.toLowerCase() === 'nie') {
          if (selectedForm.SetForm.controls['CzyModuł'] !== undefined && selectedForm.SetForm.controls['CzyModuł'].value.value.Key === 'NIE') {
            this.IsAddNewPosition = false;
            this.ShowEndPosition = true;
            if (selectedForm.SetPositionsTab) {
              this.FinishPosition = true;
              this.ManageAddingPositionText = 'edytuj_pozycje';
            }
          } else if (selectedForm.SetForm.controls['CzyModuł'] !== undefined && selectedForm.SetForm.controls['CzyModuł'].value.value.Key === 'TAK') {
            for (const control of selectedForm.SetPositionsTab.Controls) {
              if (control['modul_klucz'] !== 'P1') {
                this.IsLastRight = false;
                this.ManageAddingPositionText = 'dodaj_pozycje';
              } else {
                this.IsLastRight = true;
                this.FinishPosition = true;
                this.ManageAddingPositionText = 'edytuj_pozycje';
              }
            }
          } else {
            this.IsAddNewPosition = true;
            this.ShowEndPosition = true;
            // this.ManageAddingPositionText = 'dodaj_pozycje';
          }
        } else {
          this.IsAddNewPosition = true;
          this.ShowEndPosition = true;
          this.ManageAddingPositionText = 'dodaj_pozycje';
        }
        if(this.verifyMultipleSets) {
          this.FinishPosition = false;
        }
        if (selectedForm.SetPositionsTab) {
          this.DynamicColumns = selectedForm.SetPositionsTab.ColumnNames;
          this.DisplayedColumns = Array.from(selectedForm.SetPositionsTab.ColumnNames);
          // this.DisplayedColumns.push('edit', 'delete');
          this.dataTable = Array.from(selectedForm.SetPositionsTab.Controls);
          this.DataSource.data = this.dataTable;
        }
        for (const form of this.formSets) {
          if (form.ShowAddNewModuleForSet) {
            this.ShowAddNewModule = true;
          } else {
            this.ShowAddNewModule = false;
          }
        }
        for (const controlsForSet of selectedForm.SetControls) {
          if (controlsForSet.ModuleType === 'ModuleHeader') {
            componentRef = this.entryModuleHeader.createComponent(factory);

            componentRef.instance.LoadSetWithEdit = true;
            this.setComponentRefInstances(componentRef, controlsForSet.HtmlControl, selectedForm.SetForm, 0, controlsForSet.ModuleType, 1, selectedForm.GetModuleNr());
          } else if (controlsForSet.ModuleType === 'ModuleFooter' && controlsForSet.Step !== 'OwnHidden') {
            componentRef = this.entryModuleFooter.createComponent(factory);
            componentRef.instance.LoadSetWithEdit = true;
            this.setComponentRefInstances(componentRef, controlsForSet.HtmlControl, selectedForm.SetForm, 0, controlsForSet.ModuleType, 3, selectedForm.GetModuleNr());
          }
        }
      }
    }
    this.isNewTab = false; // Nie hejtować komentarz pisałem późno. xD
    // Sterowanie tabami. Niestety funkcja ta się uruchamia gdy doda się nowy tab
    // gdy się ona wykonuje próbuje ona wziąć rzeczy które nie istnieją. Na chwilę obecną tylko na to wpadłem
    // Rozwiązałem go za pomocą isNewTab jeśli dodajemy nowy tab propercje ustawiamy na true
    // do funkcji wejdzie jednak się nie wykona a na sam koniec zmienna isNewtab ustawiana jest na false dzieki temu
    // mozliwe jest wczytanie modulu.
  }

  public addTab(): void {
    this.isNewTab = true;
    this.tabs.push({ isError: false, setNumber: this.tabs.length });
    this.ModuleSelected.setValue(this.tabs.length - 1);
    this.FinishPosition = false;
    this.EndForm = false;
    this.ShowAddNewModule = false;
    this.createNewSetComponent();
  }

  private createNewSetComponent() {
    this.entryModuleHeader.clear();
    this.entryModuleFooter.clear();
    this.dataTable = [];
    this.DataSource = new MatTableDataSource(this.dataTable);
    const formSet = new FormSet();
    formSet.SetId = this.ModuleSelected.value;
    formSet.ModuleType = 'ModuleHeader';
    formSet.SetForm = this.fb.group({});
    formSet.ShowAddNewModuleForSet = false;
    // TODO: Zmienić z pętli na coś innego w celu rozbicia referencji
    this.ManageAddingPositionText = 'zakoncz_dodawanie_pozycji';
    Object.keys(this.headerFormForSet.controls).forEach((key) => {
      formSet.SetForm.addControl(key, this.headerFormForSet.controls[key]);
    });
    formSet.CurrentStepForSet = this.currentStep;
    this.formSets.push(formSet);

    this.moveToNextStepOrEdit(formSet.SetForm, formSet.ModuleType, null);
  }

  private setComponentRefInstances(
    componentRef: ComponentRef<DynamicFormComponent>,
    controls: HtmlControl[],
    form: FormGroup,
    position: number,
    moduleType: string,
    type: number,
    module: number
  ): void {
    componentRef.instance.HtmlControls = controls;
    componentRef.instance.Form = form;
    componentRef.instance.Position = position;
    componentRef.instance.Module = module;
    componentRef.instance.Type = type;
    componentRef.instance.ModuleType = moduleType;
    componentRef.instance.IsLoadByEdit = this.CreatedForEditFormAndLoading;
    componentRef.instance.TechVersion = this.TechVersion;
    componentRef.instance.Preview = this.Preview;
    componentRef.instance.VerifyPositionAndSet = this.VerifySet;
    componentRef.instance.MultiStepsForEdit = this.multiStepsForEdit;
    this.subscriptions.push(componentRef.instance.MoveToNextStep.pipe(
      map((returnValues: any) => {
        if (returnValues.ModuleType === ActiveFormType[ActiveFormType.ModuleFooter]) {
          const deepClone = new DeepClone(this.fb);
          this.formSets[this.ModuleSelected.value].SetForm = deepClone.DeepCloneForm(returnValues.FormGroup); // returnValues.FormGroup;
          this.moveToNextStepOrEdit(this.formSets[this.ModuleSelected.value].SetForm, returnValues.ModuleType, returnValues.StepOptions);
        } else {
          this.moveToNextStepOrEdit(returnValues.FormGroup, returnValues.ModuleType, returnValues.StepOptions);
        }
      })
    ).subscribe());
  }

  editCurrentStep(editStepOptions: StepOptions) {
    if (editStepOptions.Module === 0 && editStepOptions.ModuleType !== ActiveFormType[ActiveFormType.Footer]) {
      const tempListForNames = Array.from(this.controlListForEdit); 
      const index = tempListForNames.findIndex((x) => x.stepName === editStepOptions.StepForEdit);
      tempListForNames.splice(index + 1);
      const tempListWithControlNames = new Array<string>();

      for (const control of tempListForNames) {
        for (const iterator of control.controls) {
          tempListWithControlNames.push(iterator.name);
        }
      }

      const component = this.componentsEditList
        .find((x) => x.ModuleType === editStepOptions.ModuleType)
        .Components.find((cmp) => cmp.Component.instance.CurrentStep === editStepOptions.StepForEdit && cmp.Step === editStepOptions.StepForEdit);
      const componentIndex = this.componentsEditList.find((x) => x.ModuleType === editStepOptions.ModuleType).Components.indexOf(component) + 1;
      const lengthOfComponents = this.componentsEditList.find((x) => x.ModuleType === editStepOptions.ModuleType).Components.length;
      this.componentsEditList.find((x) => x.ModuleType === editStepOptions.ModuleType).Components.splice(componentIndex, lengthOfComponents);

      Object.keys(this.Form.controls).forEach((key) => {
        if (tempListWithControlNames.find((x) => x === key) === undefined) {
          this.Form.removeControl(key);
          if (this.entryHeader.get(componentIndex) !== null) {
            this.entryHeader.remove(componentIndex);
          }
        }
      });

      // if (!this.ManageModule.IsModule || (!this.EndForm && this.formSets.length === 1)) {
      //   this.entryModuleHeader.clear();
      //   this.controlsForEditPosition = Array.from(this.formSets[this.ModuleSelected.value].SetPositionsComponents.filter((x) => x.PositionId > 0));

      //   // console.log('this.controlsForEditPosition', this.controlsForEditPosition, this.formSets[this.ModuleSelected.value].SetPositionsComponents);
      //   this.formSets = [];
      //   this.dataTable = [];
      //   this.DataSource = new MatTableDataSource(this.dataTable);
      //   this.DisplayedColumns = [];
      //   this.DynamicColumns = [];
      //   this.ShowAddNewModule = false;
      //   this.FinishPosition = false;
      //   this.ManageAddingPositionText = 'zakoncz_dodawanie_pozycji';
      // }
      this.currentStep = editStepOptions.StepForEdit;
    } else if (editStepOptions.Module > 0) {
      this.dataSharingService.BlockOtherSetsAndSaveForm.next(true);
      const tempListForNames = new Array<any>();
      const formSet = this.formSets[this.ModuleSelected.value];
      formSet.SetControls.forEach((element) => {
        for (const control of element.HtmlControl) {
          const aa = control.currentStep.split(/([0-9]+)/).filter(Boolean);
          tempListForNames.push({ stepNumber: +aa[1], step: control.currentStep, controlName: control.name, moduleType: control.type });
        }
      });

      const newArray = tempListForNames.filter((x) => x.stepNumber <= editStepOptions.StepForEdit.split(/([0-9]+)/).filter(Boolean)[1] || isNaN(x.stepNumber));
      const tempListWithControlNames = new Array<string>();

      for (const control of newArray) {
        tempListWithControlNames.push(control.controlName);
      }

      Object.keys(this.headerFormForSet.controls).forEach((key) => {
        tempListWithControlNames.push(key);
      });

      const component = formSet.SetMainComponents.find((x) => x.ModuleType === editStepOptions.ModuleType).Components.find(
        (cmp) => cmp.Component.instance.CurrentStep === editStepOptions.StepForEdit && cmp.Step === editStepOptions.StepForEdit
      );

      const componentIndex = formSet.SetMainComponents.find((x) => x.ModuleType === editStepOptions.ModuleType).Components.findIndex((x) => x.Step === component.Step) + 1;
      const indexCmpForDelete: number[] = [];
      for (let index = componentIndex; index <= this.entryModuleFooter.length; index++) {
        const element = this.entryModuleFooter.get(index);

        if (element !== null) {
          indexCmpForDelete.push(index);
        }
      }

      for (const index of indexCmpForDelete.reverse()) {
        if(indexCmpForDelete.length > 0) {
          this.entryModuleFooter.remove(index);
        }
      }

      const lengthOfComponents = formSet.SetMainComponents.find((x) => x.ModuleType === editStepOptions.ModuleType).Components.length;
      formSet.SetMainComponents.find((x) => x.ModuleType === editStepOptions.ModuleType).Components.splice(componentIndex, lengthOfComponents);

      if (editStepOptions.ModuleType === ActiveFormType[ActiveFormType.ModuleHeader]) {
        Object.keys(formSet.SetForm.controls).forEach((key) => {
          if (tempListWithControlNames.find((x) => x === key) === undefined) {
            formSet.SetForm.removeControl(key);
            if (this.entryModuleHeader.get(componentIndex) !== null) {
              this.entryModuleHeader.remove(componentIndex);
            }
          }
        });

        const indexSet = formSet.SetControls.findIndex((x) => x.Step === editStepOptions.StepForEdit) + 1;
        formSet.SetControls.splice(indexSet, formSet.SetControls.length);
        this.entryModuleFooter.clear();
        formSet.SetPositionsComponents = [];
        formSet.SetPositionsTab = new PositionsTab();
        this.dataTable = [];
        this.DataSource = new MatTableDataSource(this.dataTable);
        this.DisplayedColumns = [];
        this.DynamicColumns = [];
        this.ShowAddNewModule = false;
        this.FinishPosition = false;
        this.ManageAddingPositionText = 'zakoncz_dodawanie_pozycji';

        formSet.CurrentStepForSet = editStepOptions.StepForEdit;
      } else if (editStepOptions.ModuleType === ActiveFormType[ActiveFormType.ModuleFooter]) {

      

        Object.keys(formSet.SetForm.controls).forEach((key) => {

          if (tempListWithControlNames.find((x) => x === key) === undefined) {
            if (formSet.SetForm) formSet.SetForm.removeControl(key); 
          }
        });

        const indexSet = formSet.SetControls.findIndex((x) => x.Step === editStepOptions.StepForEdit) + 1;
        formSet.SetControls.splice(indexSet, formSet.SetControls.length);
        formSet.CurrentStepForSet = editStepOptions.StepForEdit;

        this.ShowAddNewModule = false;
      }
    }
  }

  public deleteSet() {
    if (this.tabs.length === 1) {
      this.addTab();
      this.ModuleSelected.setValue(0);
    }

    if (this.ModuleSelected.value === 0) {
      const formSet = this.formSets[this.ModuleSelected.value + 1];
      formSet.SetId = this.ModuleSelected.value;
      for(const maincmp of formSet.SetMainComponents) {
        maincmp.Module = this.ModuleSelected.value + 1;
      }

      for (const posCmp of formSet.SetPositionsComponents) {
        posCmp.Module = this.ModuleSelected.value + 1;
      }

      for (const key of Object.keys(formSet.SetForm.controls)) {
        if (formSet.SetForm.controls[key].value !== null && formSet.SetForm.controls[key].value.module > 0) {
          formSet.SetForm.controls[key].value.module = this.ModuleSelected.value + 1;
        }
      }

      this.formSets.splice(this.ModuleSelected.value, 1, formSet);
      this.formSets.splice(this.ModuleSelected.value + 1, 1);
      this.tabs.splice(this.ModuleSelected.value, 1);
      let newSetId = 0;
      for (const iterator of this.formSets) {
        iterator.SetId = newSetId;
        newSetId = newSetId + 1;
      }

    } else {
      this.formSets.splice(this.ModuleSelected.value, 1);
      this.tabs.splice(this.ModuleSelected.value, 1);
      let newSetId = 0;
      for (const iterator of this.formSets) {
        iterator.SetId = newSetId;
        newSetId = newSetId + 1;
      }
    }
    this.dataSharingService.BlockOtherSetsAndSaveForm.next(false);
    this.loadModuleSetPositions(0);
  }

  public deleteSetMessageDialog() {
    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
    const data: DialogData = {
      title: '',
      content: 'czy_na_pewno_chcesz_usunac_zestaw',
      answerOk: false,
    };
    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteSet();
      }
    }));
  }

  public copySet() {
    setTimeout(() => {
      const cloneForm: FormSet = _.cloneDeep(this.formSets[this.ModuleSelected.value]);
      this.tabs.push({ isError: false, setNumber: this.tabs.length });
      cloneForm.SetId = this.tabs.length - 1;
      this.ModuleSelected.setValue(cloneForm.SetId);
      this.formSets.push(cloneForm);

      for (const iterator of Object.keys(this.formSets[this.ModuleSelected.value].SetForm.controls)) {
        if (this.formSets[this.ModuleSelected.value].SetForm.controls[iterator].value.module !== 0) {
          this.formSets[this.ModuleSelected.value].SetForm.controls[iterator].value.module = cloneForm.GetModuleNr();
        }
      }

      for (const cmp of this.formSets[this.ModuleSelected.value].SetMainComponents) {
        cmp.Module = cloneForm.GetModuleNr();
        for (const iterator of cmp.Components) {
          iterator.Component.instance.Module = cloneForm.GetModuleNr();
        }
      }

      this.stepBeforeFooter = cloneForm.LastStepBeforeFooter;
      this.formSets[this.ModuleSelected.value].ShowAddNewModuleForSet = true;
      this.FinishPosition = true;
      this.ShowAddNewModule = true;
      this.calculateHowManySame(this.formSets[this.ModuleSelected.value].SetForm);

      document.body.style.cursor = 'default';
    }, 500);
  }

  verifySets() {
    this.verifyMultipleSets = true;

    this.verifySet();
  }

  public verifySet() {
    this.IsLoadingByVerify = true;
    this.StartVerify = true;
    this.entryModuleHeader.clear();
    this.entryModuleFooter.clear();
    if (this.kindOper === KindOper.Add) {
      for (const iterator of Object.keys(this.formSets[this.ModuleSelected.value].SetForm.controls)) {
        const control = this.formSets[this.ModuleSelected.value].SetForm.controls[iterator].value;
        let paramForVerify: Parameter;
        if (control.value !== undefined && control.value.Key) {
          paramForVerify = { Key: control.name, Value: control.value.Key, DisplayValue: control.value, Module: control.module, Position: control.position, Type: control.type };
        } else {
          paramForVerify = { Key: control.name, Value: control.value, DisplayValue: control.value, Module: control.module, Position: control.position, Type: control.type };
        }
        this.parametersForEdit.push(paramForVerify);
      }
    }
    this.FinishPosition = false;
    this.DynamicColumns = [];
    this.DisplayedColumns = [];
    this.HiddenTableOfPositions = true;
    this.formSets[this.ModuleSelected.value].SetControls = [];
    for (const control of Object.keys(this.formSets[this.ModuleSelected.value].SetForm.controls)) {
      if (this.formSets[this.ModuleSelected.value].SetForm.controls[control].value !== null && this.formSets[this.ModuleSelected.value].SetForm.controls[control].value !== undefined) {
        if (this.formSets[this.ModuleSelected.value].SetForm.controls[control].value.module > 0) {
          this.formSets[this.ModuleSelected.value].SetForm.controls[control].enable();
          // this.formSets[this.ModuleSelected.value].SetForm.removeControl(control);
        }
      }
    }
    Object.keys(this.headerFormForSet.controls).forEach((key) => {
      if (this.formSets[this.ModuleSelected.value].SetForm.controls[key] !== undefined) {
        if (this.headerFormForSet.controls[key].value !== null) {
          this.formSets[this.ModuleSelected.value].SetForm.controls[key].value.value = this.headerFormForSet.controls[key].value.value;
        }
      } else {
        if (this.headerFormForSet.controls[key].value !== null) {
          const cloneControl = _.cloneDeep(this.headerFormForSet.controls[key].value);
          if (cloneControl !== undefined) {
            const newControl = this.fb.control(cloneControl);
            this.formSets[this.ModuleSelected.value].SetForm.addControl(key, newControl);
          }
        }
      }
    });

    this.ShowVerifyMessage = false;
    this.VerifySet = true;
    this.moveToNextStep(this.headerFormForSet, ActiveFormType[ActiveFormType.Header]);
  }

  private verifyPosition(row: any): void {
    for (const setPosition of this.formSets[this.ModuleSelected.value].SetPositionsComponents) {
      Object.keys(this.headerFormForSet.controls).forEach((key) => {
        if (this.headerFormForSet.controls[key].value !== null) {
          if (setPosition.Form.controls[key] !== undefined && setPosition.Form.controls[key].value !== null) {
            setPosition.Form.controls[key].value.value = this.headerFormForSet.controls[key].value.value;
          } else {
            const cloneControl = _.cloneDeep(this.headerFormForSet.controls[key].value.value);
            if (cloneControl !== undefined) {
              const newControl = this.fb.control(cloneControl);
              setPosition.Form.addControl(cloneControl.name, newControl);
            }
          }
        }
      });
    }
    const positionToVerify = this.formSets[this.ModuleSelected.value].SetPositionsComponents.find((x) => x.PositionId === row['pos']);

    const parametersForEditPosition: Parameter[] = [];
    for (const controlKey of Object.keys(positionToVerify.Form.controls)) {
      // console.log('positionToVerify.Form.controls[controlKey]', positionToVerify.Form.controls[controlKey].value);
      if (positionToVerify.Form.controls[controlKey].value !== null && positionToVerify.Form.controls[controlKey].value.position > 0) {
        let parameter: Parameter;
        if (positionToVerify.Form.controls[controlKey].value.value !== undefined) {
          if (positionToVerify.Form.controls[controlKey].value.value.Key !== undefined) {
            parameter = { Key: controlKey, Value: positionToVerify.Form.controls[controlKey].value.value.Key };
          } else {
            parameter = { Key: controlKey, Value: positionToVerify.Form.controls[controlKey].value.value };
          }
          parametersForEditPosition.push(parameter);
        }
      }
    }

    // const module = this.ModuleSelected.value + 1;
    // Object.keys(positionToVerify.Form.controls).forEach((key) => {
    //   if (positionToVerify.Form.controls[key].value.module === module) {
    //     positionToVerify.Form.removeControl(key);
    //   }
    // });
    const verifyPositionOption: DialogPosition = {
      KindOper: KindOper.Verify,
      HeaderForm: this.headerFormForSet,
      Form: positionToVerify.Form,
      // PreviousPositionsForVerification: this.formSets[this.ModuleSelected.value].SetPositionsComponents.find(
      //   (x) => positionToVerify.PositionId - 1 !== 0 && x.PositionId === positionToVerify.PositionId - 1
      // ),
      PreviousPosition: this.formSets[this.ModuleSelected.value].SetPositionsComponents.find((x) => positionToVerify.PositionId - 1 !== 0 && x.PositionId === positionToVerify.PositionId - 1),
      PositionToEdit: positionToVerify,
      ParametersForEdit: parametersForEditPosition,
      PositionId: positionToVerify.PositionId,
      ManageModule: this.ManageModule,
      Module: positionToVerify.Module,
      CurrentStep: this.stepForPosition,
      MultiStepsForEdit: false,
      IsOpenByEdit: this.IsLoadingByVerify,
      Set: 0,
      KimForEdit: this.parametersKimForEdit,
    };
    const dialogRef = this.dialog.open(DialogPositionComponent, {
      width: '1536px',
      disableClose: true,
      data: verifyPositionOption,
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((x: DialogPositionResult) => {
      this.dataSharingService.IsStepEditing.next(false);
      this.dataSharingService.BlockOtherNextButton.next({ BlockNextStep: false, CurrentStep: '' });
      this.dataSharingService.BlockIfMessageDialogAppear.next({ BlockNextStep: false, CurrentStep: '' });
      if (x) {
        if (this.DynamicColumns.length === 0) {
          this.DynamicColumns = x.PositionsTab.ColumnNames;
          this.DisplayedColumns = Array.from(x.PositionsTab.ColumnNames);
        } else {
          for (const columnName of x.PositionsTab.ColumnNames) {
            const ifExist = this.DynamicColumns.find((c) => c === columnName);
            if (ifExist === undefined) {
              const index = x.PositionsTab.ColumnNames.findIndex((i) => i === columnName) + 1;
              this.DynamicColumns.splice(index, 0, columnName);
              this.DisplayedColumns.splice(index, 0, columnName);
            }
          }
        }
        this.HiddenTableOfPositions = false;
        const index = this.dataTable.findIndex((pos: { pos: number }) => pos.pos === positionToVerify.PositionId);
        if (x.PositionsTab.Controls['modul_klucz'] !== 'P1') {
          this.IsLastRight = false;
        } else {
          this.IsLastRight = true;
        }
        this.formSets[this.ModuleSelected.value].CurrentStepForSet = x.LastStep;
        this.formSets[this.ModuleSelected.value].LastStepBeforeFooter = x.LastStep;
        this.dataTable.splice(index, 1, x.PositionsTab.Controls);
        this.DataSource = new MatTableDataSource(this.dataTable);
        const indexOfEditCmp = this.formSets[this.ModuleSelected.value].SetPositionsComponents.findIndex((cmp) => cmp.PositionId === positionToVerify.PositionId);
        this.formSets[this.ModuleSelected.value].SetPositionsComponents.splice(indexOfEditCmp, 1, x.PositionToEdit);

        if (this.VerifySet) {
          const modulePositions = parametersForEditPosition.filter((p) => p.Type === ActiveFormType.Position && p.Module === this.formSets[this.ModuleSelected.value].GetModuleNr());
          let howManyPositions = 0;
          for (const pos of modulePositions) {
            if (pos.Position > this.formSets[this.ModuleSelected.value].SetPositionsComponents.length) {
              howManyPositions = pos.Position;
            }
          }

          if (positionToVerify.PositionId < this.formSets[this.ModuleSelected.value].SetPositionsComponents.length) {
            // for (let i = 2; i <= this.formSets[this.ModuleSelected.value].SetPositionsComponents.length; i++) {
            //   // console.log('Halooooo')
            this.PositionNumber += 1;
            this.verifyPosition(this.DataSource.data.find((x) => x.pos === this.PositionNumber));

            // }
          } else {
            this.PositionNumber = 1;
            if (this.StartVerify) {
              this.ShowSpinnerVerify = true;
            }
            this.manageFooterAndFinishPosition();
          }
        }
      }
    }));
  }

  private moveToNextStep(form: FormGroup, moduleType: string) {
    let sendStep: string;

    if (ActiveFormType[ActiveFormType.Header] !== moduleType && ActiveFormType[ActiveFormType.Footer] !== moduleType) {
      sendStep = this.formSets[this.ModuleSelected.value].CurrentStepForSet;
    } else {
      if (ActiveFormType[ActiveFormType.Footer] === moduleType) {
        sendStep = this.stepAfterPositionsForNotModules;
      } else {
        sendStep = this.currentStep;
      }
    }


    if (moduleType === ActiveFormType[ActiveFormType.Header]) {
      this.headerFormForSet = _.cloneDeep(form);
    }
    this.subscriptions.push(this.formService
      .moveToNextStep(form, sendStep)
      .pipe(
        tap((technologyForm) => {
          if (technologyForm.Step !== '') {
            if (this.CreatedForEditFormAndLoading || this.VerifySet) {
              this.manageSetsAndControllsInMoveToNextStep(technologyForm, sendStep, form);
              this.createComponentForSpecificStepType(technologyForm.Type);
            } else {
              let indexCmp;
              if (this.formSets[this.ModuleSelected.value] !== undefined && this.ManageModule.IsModule) {
                for (const technologyControl of technologyForm.Controlls) {
                  if (this.formSets[this.ModuleSelected.value].SetControls.length > 0) {
                    indexCmp = this.formSets[this.ModuleSelected.value].SetControls.find(
                      (x) => x.ModuleType === ActiveFormType[ActiveFormType.ModuleHeader] || x.ModuleType === ActiveFormType[ActiveFormType.ModuleFooter]
                    ).HtmlControl.findIndex((x) => x.name === technologyControl.KeyName);
                    if (indexCmp >= 0) {
                      break;
                    }
                  }
                }
              } else if (!this.ManageModule.IsModule && technologyForm.Type === ActiveFormType[ActiveFormType.Position] && this.formSets[this.ModuleSelected.value] !== undefined) {
                indexCmp = this.formSets[this.ModuleSelected.value].SetControls.findIndex((x) => x.ModuleType === ActiveFormType[ActiveFormType.Position]);
              }

              if ((technologyForm.Type === ActiveFormType[ActiveFormType.ModuleHeader] || (technologyForm.Type === ActiveFormType[ActiveFormType.Position])) && this.EditHeader) {
                this.EditHeader = false;
              }

              if (indexCmp < 0 || indexCmp === undefined) {
                this.manageSetsAndControllsInMoveToNextStep(technologyForm, sendStep, form);
                this.createComponentForSpecificStepType(technologyForm.Type);
              }
            }
          } else {
            this.MainSelected.setValue(3);
            this.EndForm = true;
            if (this.ManageModule.IsModule && this.formSets.length > 0 && this.CreatedForEditFormAndLoading) {
              this.loadModuleSetPositions(0);
            }
            this.CreatedForEditFormAndLoading = false;
            this.multiStepsForEdit = false;

            if (this.VerifySet) {

              if (this.verifyMultipleSets) {
                if (this.formSets[this.ModuleSelected.value + 1] !== undefined) {
                  this.VerifySet = false;
                  this.IsLoadingByVerify = false;
                  this.ModuleSelected.setValue(this.ModuleSelected.value + 1);
                  this.startMultipleVerifySets.next(true);
                } else{
                  this.verifyMultipleSets = false;
                  // console.log('b7');
                  this.modalAlertRequest('', '', 'weryfikacja_przeszla_pomyslnie');
                }
              } else {
                // console.log('b8');
                this.modalAlertRequest('', '', 'weryfikacja_przeszla_pomyslnie');
                this.VerifySet = false;
                this.IsLoadingByVerify = false;
                this.ShowSpinnerVerify = false;
              }
            }

            this.isLoadingAll = false;
          }
        })
      )
      .subscribe());
  }

  calculateHowManySame(form: FormGroup) {
      let controlHowManySame = 0;
      for (const set of this.formSets) {
        controlHowManySame = controlHowManySame + +set.SetForm.controls['IleJednakowych'].value.value * set.SetPositionsComponents.length;
      }
      const formSet = this.formSets[this.ModuleSelected.value];
      const htmlControl: HtmlControl = new HtmlControl();
      htmlControl.inputType = 'hidden';
      htmlControl.componentName = 'Hidden';
      htmlControl.name = 'suma_IleJednakowych';
      htmlControl.currentStep = formSet.CurrentStepForSet;
      htmlControl.module = formSet.GetModuleNr();
      htmlControl.position = 0;
      htmlControl.type = ActiveFormType.ModuleFooter;
      htmlControl.value = controlHowManySame;
      const controlHtml = this.fb.control(htmlControl);
      formSet.SetForm.removeControl(htmlControl.name);

      formSet.SetForm.addControl(htmlControl.name, controlHtml);
      this.HtmlControls.push(htmlControl);
      const componentIndex = this.saveForm.MainComponents.findIndex((x) => x.ModuleType === ActiveFormType[ActiveFormType.Footer]);
      this.componentsEditList.find((x) => x.ModuleType === ActiveFormType[ActiveFormType.Footer]).Components.splice(0, 1);
      this.footerForm.controls['AkcesoriaDoZlecenia'].enable();
      this.footerForm.removeControl('AkcesoriaDoZlecenia');
      this.saveForm.MainComponents.splice(componentIndex, 1);
      this.entryFooter.clear();
      this.stepAfterPositionsForNotModules = this.formSets[this.ModuleSelected.value].LastStepBeforeFooter; //this.stepBeforeFooter;
      this.moveToNextStep(form, ActiveFormType[ActiveFormType.Footer]);
  }

  private moveToNextStepOrEdit(form: FormGroup, moduleType: string, stepOptions: StepOptions): void {
   
    if (this.StopByErrorSettingValue) {
      if (!this.VerifySet) {
        this.CreatedForEditFormAndLoading = true;
      }

      if (this.StartVerify) {
        this.ShowSpinnerVerify = true;
      }
      this.StopByErrorSettingValue = false;
      this.dataSharingService.ErrorSettingValue.next('');
    }

    if (stepOptions !== null && stepOptions.IsHowManySameSets) {
      this.calculateHowManySame(form);
    }
    // console.log('formSets MOVE S3', this.formSets);  
    if (stepOptions !== null && stepOptions.IsMarkAsToEdit && (stepOptions.DeleteRestComponents || stepOptions.DeleteHeaderComponents)) {
      this.editCurrentStep(stepOptions);
      // console.log('formSets MOVE S10', this.formSets); 
      this.dataSharingService.BlockIfMessageDialogAppear.next({ BlockNextStep: false, CurrentStep: '' });
      if (moduleType === ActiveFormType[ActiveFormType.Header] ) {
        this.EditHeader = true;
        if (this.formSets.length > 0 && stepOptions.DeleteRestComponents) {
          this.ErrorForSets = true;
          this.ShowVerifyMessage = true;
          for (const tab of this.tabs) {
            tab.isError = true;
          }
          this.SetsForVerificationLength = this.tabs.filter((x) => x.isError === true).length;
        }
      }
      if (moduleType !== ActiveFormType[ActiveFormType.ModuleFooter]) {
        this.moveToNextStep(form, moduleType);
      } else {
        this.moveToNextStepForModuleFooter(this.formSets[this.ModuleSelected.value]);
      }
    } else if (stepOptions === null) {
      // console.log('formSets MOVE S2', this.formSets);   
      if (moduleType !== ActiveFormType[ActiveFormType.ModuleFooter]) {
        // Tu było move to next step z subskrypcją

        this.moveToNextStep(form, moduleType);
      } else {
        this.moveToNextStepForModuleFooter(this.formSets[this.ModuleSelected.value]);
      }
    }
    // console.log('formSets MOVE2', this.formSets);  
  }

  manageSetsAndControllsInMoveToNextStep(technologyForm: FormControls, sendStep: string, form: FormGroup) {
    if (technologyForm.Type !== 'Header' && technologyForm.Type !== 'Footer' && technologyForm.Type !== 'Position') {
      const controls = this.formService.mapControlsToHtml(technologyForm.Controlls, technologyForm.Step);
      if (this.CreatedForEditFormAndLoading || this.VerifySet) {
        this.manageSets(technologyForm.Type, this.setValuesForModuleControls(controls), technologyForm.Step); 
      } else {
        this.manageSets(technologyForm.Type, controls, technologyForm.Step);
      }
      this.formSets[this.ModuleSelected.value].CurrentStepForSet = technologyForm.Step;
    } else if (technologyForm.Type === 'Position') {
      this.stepForPosition = sendStep;
      if (!this.ManageModule.IsModule) {
        const controls = Array.from(this.formService.mapControlsToHtml(technologyForm.Controlls, technologyForm.Step));
        this.manageSets(technologyForm.Type, controls, technologyForm.Step);
        this.formSets[this.ModuleSelected.value].CurrentStepForSet = technologyForm.Step;
      }
    } else {
      if (ActiveFormType[ActiveFormType.Footer] === technologyForm.Type) {
        this.stepAfterPositionsForNotModules = technologyForm.Step;
        if (!this.ManageModule.IsModule) {
          this.currentStep = technologyForm.Step;
        }
      } else {
        this.currentStep = technologyForm.Step;
      }

      const controls = this.formService.mapControlsToHtml(technologyForm.Controlls, technologyForm.Step);
      let indexOfStepOnEditList = -1;
      if (this.controlListForEdit.find((x) => x.stepName === technologyForm.Step) === undefined) {
        this.controlListForEdit.push({ stepName: technologyForm.Step, controls: controls });
      } else {
        indexOfStepOnEditList = this.controlListForEdit.findIndex((x) => x.stepName === technologyForm.Step);
      }

      if (this.CreatedForEditFormAndLoading || this.VerifySet) {
        this.HtmlControls = this.setValuesForMainControls(controls);
      } else {
        this.HtmlControls = this.loadValueForControllsIfStepEdit(controls, technologyForm.Step);
        if (indexOfStepOnEditList !== -1) {
          this.controlListForEdit.splice(indexOfStepOnEditList, 1, { stepName: technologyForm.Step, controls: this.HtmlControls });
        }
        // this.HtmlControls = this.loadValueForControllsIfStepEdit(controls, technologyForm.Step);
      }
    }
  }

  private moveToNextStepForModuleFooter(positionsForSet: FormSet) {
      
    this.subscriptions.push(this.formService
      .moveToNextStepForModuleFooter(positionsForSet, positionsForSet.CurrentStepForSet)
      .pipe(
        tap((technologyForm) => {
          if (technologyForm.Type !== '') {
            if (technologyForm.Type !== ActiveFormType[ActiveFormType.Footer]) {
              const controls = this.formService.mapControlsToHtml(technologyForm.Controlls, technologyForm.Step);
              // console.log('formSets Y', this.formSets);   
              if (this.CreatedForEditFormAndLoading || this.VerifySet) {
                this.manageSets(technologyForm.Type, this.setValuesForModuleControls(controls), technologyForm.Step);
              } else {
                this.manageSets(technologyForm.Type, controls, technologyForm.Step);
              }
              this.formSets[this.ModuleSelected.value].CurrentStepForSet = technologyForm.Step;
               this.formSets[this.ModuleSelected.value].LastStepBeforeFooter = technologyForm.Step;
            } else {
              this.stepAfterPositionsForNotModules = technologyForm.Step;
              this.formSets[this.ModuleSelected.value].CurrentStepForSet = technologyForm.Step;
              this.HtmlControls = this.formService.mapControlsToHtml(technologyForm.Controlls, technologyForm.Step);
              if (this.CreatedForEditFormAndLoading || this.VerifySet) {
                this.HtmlControls = this.setValuesForMainControls(this.HtmlControls);
              }
            }

            this.controlListForEdit.push({ stepName: technologyForm.Step, controls: this.HtmlControls });
            if (ActiveFormType[ActiveFormType.Footer] === technologyForm.Type && this.ManageModule.IsModule) {
              this.dataSharingService.BlockOtherSetsAndSaveForm.next(false);
              if (this.CreatedForEditFormAndLoading || this.VerifySet) {
                for (const parameter of this.parametersForEdit) {
                  if (parameter.Module > this.tabs.length) {
                    this.howManyModules = parameter.Module;
                  }
                }
                if (this.howManyModules > this.tabs.length) {
                  this.formSets[this.ModuleSelected.value].ShowAddNewModuleForSet = true;
                  this.addTab();
                } else {
                  this.ShowAddNewModule = true;
                  this.formSets[this.ModuleSelected.value].ShowAddNewModuleForSet = true;
                  const isComponentCreated = this.saveForm.MainComponents.find((x) => x.ModuleType === ActiveFormType[ActiveFormType.Footer]);
                  if (isComponentCreated === undefined) {
                    this.createComponentForSpecificStepType(technologyForm.Type);
                  }
                }
                if (this.VerifySet) {
                    if (this.verifyMultipleSets) {
                      if (this.formSets[this.ModuleSelected.value + 1] !== undefined) {
                        this.VerifySet = false;
                        this.IsLoadingByVerify = false;
                        this.tabs[this.ModuleSelected.value].isError = false;
                        this.ModuleSelected.setValue(this.ModuleSelected.value + 1);
                        this.startMultipleVerifySets.next(true);
                        this.ShowSpinnerVerify = false;
                      } else {
                         this.verifyMultipleSets = false;
                         // console.log('b9');
                        this.modalAlertRequest('', '', 'weryfikacja_przeszla_pomyslnie');
                        this.VerifySet = false;
                        this.IsLoadingByVerify = false;
                        this.ShowSpinnerVerify = false;
                      }
                    } else {
                      // console.log('b10');
                      this.modalAlertRequest('', '', 'weryfikacja_przeszla_pomyslnie');
                      this.VerifySet = false;
                      this.IsLoadingByVerify = false;
                      this.ShowSpinnerVerify = false;
                    }
                }
                this.tabs[this.ModuleSelected.value].isError = false;
                const indexTab = this.tabs.findIndex((x) => x.isError === true);
                this.SetsForVerificationLength = this.tabs.filter((x) => x.isError === true).length;
                if (indexTab < 0) {
                  this.ErrorForSets = false;
                }
              } else {
                this.ShowAddNewModule = true;
                this.tabs[this.ModuleSelected.value].isError = false;
                const indexTab = this.tabs.findIndex((x) => x.isError === true);
                if (indexTab < 0) {
                  this.ErrorForSets = false;
                }
                this.formSets[this.ModuleSelected.value].ShowAddNewModuleForSet = true;
                const isComponentCreated = this.saveForm.MainComponents.find((x) => x.ModuleType === ActiveFormType[ActiveFormType.Footer]);
                if (isComponentCreated === undefined) {
                  this.createComponentForSpecificStepType(technologyForm.Type);
                } else {
                  const componentIndex = this.saveForm.MainComponents.findIndex((x) => x.ModuleType === ActiveFormType[ActiveFormType.Footer]);
                  this.componentsEditList.find((x) => x.ModuleType === ActiveFormType[ActiveFormType.Footer]).Components.splice(0, 1);
                  this.footerForm.controls['AkcesoriaDoZlecenia'].enable();
                  this.footerForm.removeControl('AkcesoriaDoZlecenia');
                  this.saveForm.MainComponents.splice(componentIndex, 1);
                  this.entryFooter.clear();
                  this.createComponentForSpecificStepType(ActiveFormType[ActiveFormType.Footer]);
                }
              }
            } else if (ActiveFormType[ActiveFormType.ModuleFooter] === technologyForm.Type && this.ManageModule.IsModuleFooter) {
              this.createComponentForSpecificStepType(technologyForm.Type);
            }
          } else {
            if (this.CreatedForEditFormAndLoading || this.VerifySet) {
              for (const parameter of this.parametersForEdit) {
                if (parameter.Module > this.tabs.length) {
                  this.howManyModules = parameter.Module;
                }
              }
              if (this.howManyModules > this.tabs.length) {
                this.addTab();
              } else {
                this.ShowAddNewModule = true;
                this.EndForm = true;
                this.CreatedForEditFormAndLoading = false;
                this.multiStepsForEdit = false;
                this.dataSharingService.BlockOtherSetsAndSaveForm.next(false);
                if (this.VerifySet) {
                 if (this.verifyMultipleSets) {
                   if (this.formSets[this.ModuleSelected.value + 1] !== undefined) {
                     this.VerifySet = false;
                     this.IsLoadingByVerify = false;
                     this.tabs[this.ModuleSelected.value].isError = false;
                     this.ModuleSelected.setValue(this.ModuleSelected.value + 1);
                     this.startMultipleVerifySets.next(true);
                   } else {
                     this.verifyMultipleSets = false;
                     this.VerifySet = false;
                     this.IsLoadingByVerify = false;
                     this.ShowSpinnerVerify = false;
                     const indexTab = this.tabs.findIndex((x) => x.isError === true);
                     if (indexTab < 0) {
                       this.ErrorForSets = false;
                     }
                     // console.log('b11');
                     this.modalAlertRequest('', '', 'weryfikacja_przeszla_pomyslnie');
                   }
                 } else {
                  // console.log('b12');
                    this.modalAlertRequest('', '', 'weryfikacja_przeszla_pomyslnie');
                    this.VerifySet = false;
                    this.IsLoadingByVerify = false;
                    this.ShowSpinnerVerify = false;
                    this.tabs[this.ModuleSelected.value].isError = false;
                    const indexTab = this.tabs.findIndex((x) => x.isError === true);
                    if (indexTab < 0) {
                      this.ErrorForSets = false;
                    }
                 }
                }

                this.isLoadingAll = false;
              }
            } else {
              this.ShowAddNewModule = true;
              this.EndForm = true;
              this.CreatedForEditFormAndLoading = false;
              this.multiStepsForEdit = false;
              this.dataSharingService.BlockOtherSetsAndSaveForm.next(false);
              if (this.VerifySet) {
                // console.log('b13');
                this.modalAlertRequest('', '', 'weryfikacja_przeszla_pomyslnie');
                this.VerifySet = false;
                this.IsLoadingByVerify = false;
                this.ShowSpinnerVerify = false;
                if (this.verifyMultipleSets) {
                  if (this.formSets[this.ModuleSelected.value + 1] !== undefined) {
                    this.startMultipleVerifySets.next(true);
                    this.tabs[this.ModuleSelected.value].isError = false;

                    this.ModuleSelected.setValue(this.ModuleSelected.value + 1);
                  } else {
                    this.verifyMultipleSets = false;
                  }
                }
              }

              this.isLoadingAll = false;
            }
          }
        })
      )
      .subscribe());
  }

  private loadValueForControllsIfStepEdit(htmlControls: HtmlControl[], step: string) {
    const controls = this.controlListForEdit.find((x) => x.stepName === step).controls;
    for (const htmlCtrl of htmlControls) {
      const control = controls.find((x) => htmlCtrl.name.toLowerCase() === x.name.toLowerCase());

      if (control !== undefined) {
        if (htmlCtrl.inputType !== 'hidden') {
          if (htmlCtrl.options !== undefined && htmlCtrl.options.length !== 0 && control.value !== null) {
            if (htmlCtrl.options.find((x) => x.Key === control.value.Key) !== undefined) {
              htmlCtrl.value = htmlCtrl.options.find((x) => x.Key === control.value.Key);
            }
          } else if (control.optionsSelectMultiColumn !== undefined && control.optionsSelectMultiColumn?.length !== 0 && control.value !== null) {
            if (htmlCtrl.optionsSelectMultiColumn.find((x) => x.Key === control.value.Key) !== undefined) {
              htmlCtrl.value = htmlCtrl.optionsSelectMultiColumn.find((x) => x.Key === control.value.Key);
            }
          } else {
            htmlCtrl.value = control.value;
          }
        }
      }
      // else {
      //   if (htmlCtrl.options !== undefined && htmlCtrl.options?.length !== 0 && htmlCtrl.value !== '') {
      //     // console.log('htymlC', JSON.stringify(htmlCtrl), htmlCtrl.value, htmlCtrl.options);

      //     // htmlCtrl.value = htmlCtrl.options.find((x) => x.Key === htmlCtrl.value);

      //   }
      // }

      if (htmlCtrl.inputType === 'kim' && this.parametersKimForEdit.length > 0) {
        if (htmlCtrl.position === 0) {
          htmlCtrl.value = this.parametersKimForEdit;
        }
      }
    }
    return htmlControls;
  }

  private setValuesForMainControls(htmlControlls: HtmlControl[]) {
    for (const control of htmlControlls) {

      const parameter = this.parametersForEdit.find((x) => x.Key.toLowerCase() === control.name.toLowerCase());
      if (parameter !== undefined) {
        if (
          control.inputType !== 'file' &&
          control.inputType !== 'checkfile' &&
          control.componentName !== 'Pdf' &&
          control.inputType !== 'hidden' &&
          control.inputType !== 'komunikat' &&
          control.inputType !== 'label'
        ) {

          if (control.options !== undefined && control.options?.length !== 0) {
            const value = control.options.find((x) => x.Key === parameter.Value);
            control.value = value === undefined ? '' : value;
            if (this.multiStepsForEdit && control.value === '' && control.required) {
              this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
              this.multiStepsError = true; 
            }
          } else if (control.optionsSelectMultiColumn !== undefined && control.optionsSelectMultiColumn?.length !== 0) {
            const value = control.optionsSelectMultiColumn.find((x: { Key: any }) => x.Key === parameter.Value);
            control.value = value === undefined ? '' : value;

            if (this.multiStepsForEdit && control.value === '' && control.required) {
              this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
              this.multiStepsError = true;
            }
          } else {
            control.value = parameter.Value;
            if (this.multiStepsForEdit && control.value === '' && control.required) {
              this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
              this.multiStepsError = true;
            }
          }
        } else if (control.inputType === 'komunikat') {
          if (this.CreatedForEditFormAndLoading || this.multiStepsForEdit) {
            this.dataSharingService.ErrorSettingValue.next('blad_przeczytaj_komunikat');
            this.multiStepsError = true;
          }
        } else {
          if (control.options !== undefined && control.options?.length !== 0) {
            control.options[0].Key = parameter.Value;
            control.value = control.options.find((x) => x.Key === parameter.Value);
          }
        }
      } else if (control.inputType !== 'hidden' && control.inputType !== 'label' && control.inputType !== 'komunikat' && control.inputType !== 'checkselectmulticolumn')  {
        control.value = '';
        if ((this.CreatedForEditFormAndLoading || this.multiStepsForEdit) && control.value === '' && control.required) {
          this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
          this.multiStepsError = true;
        }
      } else if (control.inputType === 'checkselectmulticolumn') {
        control.value = '';
        
      } else if (control.inputType === 'hidden') {
        if (control.options !== undefined && control.options?.length !== 0 && parameter !== undefined) {
          control.options[0].Key = parameter.Value;
          control.value = control.options.find((x) => x.Key === parameter.Value);
        }
      } else if (control.inputType === 'komunikat') {
        if (this.CreatedForEditFormAndLoading || this.multiStepsForEdit) {
          this.dataSharingService.ErrorSettingValue.next('blad_przeczytaj_komunikat');
          this.multiStepsError = true;
        }
      }
      if (control.inputType === 'kim' && this.parametersKimForEdit.length > 0) {
        const paramKim: Kim[] = [];
        for (const kim of this.parametersKimForEdit) {
          if (kim.Position === 0 && kim.Module === 0) {
            paramKim.push(kim);
          }
        }
        control.value = paramKim;
      }
    }
    return htmlControlls;
  }

  moveToSets(index) {
    if (!this.enterByButton) {
      switch (index) {
        case 0:
          this.MainSelected.setValue(0);
          break;

        case 1:
          if (this.ShowVerifyMessage) {
            // console.log('b14');
            this.modalAlertRequest('ostrzezenie', '', 'zestawy_wymagaja_weryfikacji');
          }
          this.MainSelected.setValue(1);
          break;

        case 2:
          this.MainSelected.setValue(2);
          break;

        case 3:
          this.MainSelected.setValue(3);
          break;
      }
    }
    this.enterByButton = false;
  }

  moveToSetsByButton() {
    window.scrollTo(0, 0);
    if (this.ShowVerifyMessage) {
      // console.log('b15');
      this.modalAlertRequest('ostrzezenie', '', 'zestawy_wymagaja_weryfikacji');
    }
    this.MainSelected.setValue(1);
    this.enterByButton = true;
  }

  private setValuesForModuleControls(htmlControlls: HtmlControl[]) {
    for (const control of htmlControlls) {
      const parameter = this.parametersForEdit.find((x) => x.Key === control.name && x.Module === this.ModuleSelected.value + 1);
      if (parameter !== undefined) {
        if (control.inputType !== 'hidden' && control.inputType !== 'komunikat' && control.inputType !== 'label') {
          if (control.options !== undefined && control.options?.length !== 0) {
            const value = control.options.find((x) => x.Key === parameter.Value);
            control.value = value === undefined ? '' : value;
            if (this.multiStepsForEdit && control.value === '') {
              this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
              this.multiStepsError = true;
            }
          } else if (control.optionsSelectMultiColumn !== undefined && control.optionsSelectMultiColumn?.length !== 0) {
            const value = control.optionsSelectMultiColumn.find((x: { Key: any }) => x.Key === parameter.Value);
            control.value = value === undefined ? '' : value;

            if (this.multiStepsForEdit && control.value === '' && control.required) {
              this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
              this.multiStepsError = true;
            }
          } else if (control.componentName !== 'Pdf') {
            control.value = parameter.Value;
            if (this.multiStepsForEdit && control.value === '' && control.required) {
              this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
              this.multiStepsError = true;
            }
          }
        } else if (control.inputType === 'komunikat') {
          if (this.CreatedForEditFormAndLoading || this.multiStepsForEdit) {
            this.dataSharingService.ErrorSettingValue.next('blad_przeczytaj_komunikat');
            this.multiStepsError = true;
          }
        }

        if (control.inputType === 'kim' && this.parametersKimForEdit.length > 0) {
          const paramKim: Kim[] = [];
          if (control.position === 0) {
            control.value = this.parametersKimForEdit;
          }
          control.value = paramKim;
        }
      } else if (control.inputType !== 'hidden' && control.inputType !== 'label' && control.inputType !== 'komunikat' && control.inputType !== 'newline' && control.inputType !== 'checkselect') {
        // console.log('contorl', control)
        control.value = '';
        if (this.CreatedForEditFormAndLoading || this.multiStepsForEdit) {
          this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci'); 
          this.multiStepsError = true;
        }

      } else if (control.inputType === 'komunikat') {
        if (this.CreatedForEditFormAndLoading || this.multiStepsForEdit) {
          this.dataSharingService.ErrorSettingValue.next('blad_przeczytaj_komunikat');
          this.multiStepsError = true;
        }
      }
    }

    return htmlControlls;
  }

  private modalAlertRequest(title: string, sendStep: string, error: any) {
    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
    const data: DialogData = {
      title: title,
      step: sendStep,
      errorType: 'blad',
      content: `${error}`,
      answerOk: true,
    };
    this.broadcastService.ErrorHttp.next(null);
    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data, role: 'alertdialog' });
  }

  public moveToFooter() {
    this.MainSelected.setValue(2);
  }

  public save(): void {
    
    try {
      if (this.IsSaving === false && this.formService.DeliveryMethod !== null && this.formService.DeliveryAddress !== null) {
        this.IsSaving = true;
        this.saveForm.SetsComponents = this.formSets;

        this.subscriptions.push(this.formService.mergeControlsAndSave(this.saveForm, this.TechVersion).subscribe((x) => {
          localStorage.setItem('goToPage', '');
          this.router.navigate([`Orders`]);
          this.formService.ActiveFormOrderId = 0;
          this.IsSaving = false;
        }));
      } else {
        // console.log('adres');
        this.modalAlertRequest('blad', '', 'uzupelnij_adres_dostawy');
      }
    } catch (error) {
      console.error(error);
      // console.log("error", error);
      this.modalAlertRequest('blad', '', error);
    }
  }

  public quickSave(): void {
    try {
      if (this.IsSaving === false) {
        this.IsSaving = true;
        this.saveForm.SetsComponents = this.formSets;
        this.subscriptions.push(this.formService.mergeControlsAndSave(this.saveForm, this.TechVersion).subscribe((x) => {
          this.formService.OrderId = x;
          this.formService.ActiveFormOrderId = x;
          let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
          const data: DialogData = {
            title: 'zapisano',
            step: '',
            errorType: 'info',
            content: 'zamowienie_o_nr',
            additionalContent: x,
            answerOk: true,
          };
          dialogRef = this.dialog.open(UniversalDialogComponent, { data: data, role: 'alertdialog' });
          this.IsSaving = false;
        }));
      }
    } catch (error) {
      console.error(error);
      // console.log("error2", error);
      this.modalAlertRequest('blad', '', error);
    }
  }
}
