import { Component, Inject, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { TresciService } from 'src/app/tresci.service';



@Component({
  selector: 'app-order-note-dialog',
  templateUrl: './order-note-dialog.component.html',
  styleUrls: ['./order-note-dialog.component.scss']
})
export class OrderNoteDialogComponent  {

  public TranslationEnum = TranslationEnum;

  constructor(public dialogRef: MatDialogRef<UniversalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public tresci: TresciService) {
  }



  save() {
    this.tresci.user.zapisz_notatke(this.data.Id, this.data.Description);
  }

}
