export class NotificationUser {
  NotificationId: number;
  Acceptance: boolean;
  Username: string;
  Date: Date;
  Document: Document;
}

class Document {
  Id: string;
  FileName: string;
}
