<div @tooltip class="p-3">
  <span class="float-right" *ngIf="permissionValue">
    <button mat-icon-button color="accent" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </span>
  <p *ngIf="ShowText" id="info_{{label}}" [translation]="TranslationEnum.TranslationForm">
    {{'info_' + label|translate}}
  </p>
  <img class="d-block" style="max-width: 35vw; max-height: 35vh; "  [src]="ImgSrc">
  <mat-progress-spinner *ngIf = "tresci.wczytywanie && (ImgSrc == null || ImgSrc == '')" color="primary" mode="indeterminate" [diameter]="30">
  </mat-progress-spinner>
  <span *ngIf="permissionValue">
    <label class="file mr-2">
      <input type="file" id="file" (change)="changeImg($event.target.files)" accept="image/png, image/jpeg, image/gif, image/bmp">
      <span class="file-custom">
        <span class="file-custom-before">
          <mat-icon>file_upload</mat-icon>
        </span>
        <span class="file-custom-after">{{File.FileName}}</span>
      </span>
    </label>
    <button mat-flat-button color="accent" class="mr-2" (click)="saveImg()">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-flat-button color="accent" id="usun" (click)="removeImg()">
      <mat-icon>delete</mat-icon>
    </button>
  </span>
</div>
