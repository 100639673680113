export class OrderList {
  Id: number;
  B2BId: string;
  Applies: string;
  QuidId: string;
  Produkt: string;
  IsTrader: boolean;
  Prefix: string;
  System: string;
  ProductionNumber: string;
  OrderDate: Date;
  ProcessingSeltDate: Date | null;
  ProcessingSeltName: string;
  OrderStatusId: number;
  OrderStatusName: string;
  Description: string;
  OrderingName: string;
  Email: string;
  Currency: string;
  ValueNetto: number;
  ValueBrutto: number;
  BlockingConditionId: number | null;
  BlockingConditionName: string;
  IsEdit: boolean;
  IsToRemove: boolean;
  IsOrder: boolean;
  IsVerified: boolean;
  ForVerification: boolean;
  Note: string;
  TransferConfirmationId: string | null;
  IsFile: boolean;
  PaymentStatusName: string;
  DeliveryCostNetto: number | null;
  DeliveryCostBrutto: number | null;
  IsActive: boolean;
  IsTechnologyError: boolean;
  IsPackages: boolean;
  ProformaFormat: string;
  Quantity: number;
  FileForVerification: boolean;
  IsOlderThen14Days: boolean;
}
