import { DateAdapter } from '@angular/material/core';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, concatMap, tap, takeWhile } from 'rxjs/operators';
import { TranslationEnum } from '../shared/enums/translation-enum';
import { Invoice } from '../shared/models/invoice/invoice.model';
import { Contractor } from '../shared/models/user/contractor.model';
import { DataSharingService } from '../shared/service/data-sharing.service';
import { InvoicesService } from './invoices.service';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';

enum InvoiceTypeEnum {
  Invoice = 0,
  Debt = 1,
}

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Output() dateChange = new EventEmitter<{ value: string }>();
  @Output() refreshEmit = new EventEmitter<{}>();

  private culture: string;

  public TranslationEnum = TranslationEnum;
  public InvoiceTypeEnum = InvoiceTypeEnum;

  public InvoicesArray: Invoice[] = new Array<Invoice>();
  public InvoicesDataSource = new MatTableDataSource(this.InvoicesArray);

  public DebtsArray = [];
  public DebtsDataSource = new MatTableDataSource(this.DebtsArray);
  private subscriptions: Subscription[] = [];

  public DisplayedColumnsInvoices: string[];
  public DisplayedColumnsDebts: string[];
  public IsLoadingResults: boolean;
  public IsLoadingDebts: boolean;
  public IsRateLimitReached: boolean;
  public IsRateLimitReachedDebts: boolean;
  public ResultsLength: number;
  public ModuleSelected = new FormControl(0);
  public Range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  date = new Date();
  re = /\//gi;
  dateFrom: string = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toLocaleString('en-ZA').slice(0, 10).replace(this.re, '-');
  dateTo: string = this.date.toISOString().slice(0, 10);
  contractorNumber: string;
  constructor(private _adapter: DateAdapter<any>, private invoicesService: InvoicesService, private translateService: TranslateService, private dataSharingService: DataSharingService, translationService: TranslateService) {
    this.culture = window['culture'];
    this._adapter.setLocale(this.culture);

    this.subscriptions.push(translationService.onLangChange.subscribe(() => {
      this.culture = window['culture'];
      this._adapter.setLocale(this.culture);
    }));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit(): void {
    this.getInvoices();
    this.getDebts();
    this.Range.patchValue({
      start: this.dateFrom,
      end: this.dateTo,
    });
  }

  ngAfterViewInit(): void {
    this.dataSharingService.contractorChanged.subscribe((x) => {
      if (x) {
        const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
        this.contractorNumber = contractor.ContractorNumber;
        this.refreshEmit.emit({
          value: true,
        });
      }
    });

    this.subscriptions.push(this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0)));

    this.labelsMatPaginator();
    this.subscriptions.push(merge(this.sort.sortChange, this.paginator.page, this.dateChange, this.refreshEmit)
      .pipe( 
        startWith({}),
        switchMap(() => {
          this.IsLoadingResults = true;
          return this.invoicesService.getInvoices(this.dateFrom, this.dateTo, '', '', this.sort.active, this.sort.direction, this.paginator.pageIndex + 1, this.paginator.pageSize);
        }),
        map((data) => {
          this.IsLoadingResults = false;
          this.IsRateLimitReached = false;
          this.ResultsLength = data.TotalCount;
          return data;
        }),
        catchError(() => {
          this.IsLoadingResults = false;
          this.IsRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.InvoicesDataSource = new MatTableDataSource(data.Invoices))));
  }
  public ToExcel(){
    // console.log("excel");
    var faktury_translate = "Faktury";
    this.translateService.get("faktury").subscribe((data) => 
        faktury_translate = data
      );
    var fileName = formatDate(Date.now(),'yyyy-MM-dd HH mm',"en-US") + '_'+faktury_translate+'.xlsx';
    /* pass here the table id */
   /* var false_trans = "fałsz";
    var true_trans = "prawda";
    this.translateService.get("fałsz_t").subscribe((data) => 
      false_trans = data
    );
    this.translateService.get("prawda_t").subscribe((data) => 
      true_trans = data
    );*/

    const rows = this.InvoicesDataSource.data.map(row => ({
      InvoiceNumber: row.InvoiceNumber,
      InvoiceType: row.InvoiceType,
      //IsCorrection: row.IsCorrection,
      InvoiceDate: formatDate(row.InvoiceDate,'yyyy-MM-dd',"en-US"),
      Currency: row.Currency,
      Netto: row.Netto,
      Brutto: row.Brutto,
      Tax: row.Tax,
      PaymentDate: formatDate(row.PaymentDate,'yyyy-MM-dd',"en-US")
    }));

    var titles = ["numerfaktury","typfaktury",/*"korekta",*/"datawystawienia","waluta","netto","brutto","podatek","datazaplaty" ]
    var titles_translate = []
    titles.forEach(element => {
      
      this.translateService.get(element).subscribe((data) => 
        titles_translate.push(data)
      );
    });
    
  
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows, null);
    XLSX.utils.sheet_add_aoa(ws, [titles_translate], { origin: "A1" });
    const max_width1 = rows.reduce((w, r) => Math.max(w, r.InvoiceNumber.length), titles_translate[0].length);
    const max_width2 = rows.reduce((w, r) => Math.max(w, r.InvoiceType.length), titles_translate[1].length);
    const max_width3 =  titles_translate[2].length > 10 ? titles_translate[2].length : 10;
    const max_width4 =  titles_translate[3].length > 4 ? titles_translate[3].length : 4;
    const max_width5 =  titles_translate[4].length > 6 ? titles_translate[4].length : 6;
    const max_width6 =  titles_translate[5].length > 6 ? titles_translate[5].length : 6;
    const max_width7 =  titles_translate[6].length > 3 ? titles_translate[6].length : 3;
    const max_width8 =  titles_translate[7].length > 10 ? titles_translate[7].length : 10;

    ws["!cols"] = [ { wch: max_width1 }, { wch: max_width2 }, { wch: max_width3 }, { wch: max_width4 }, { wch: max_width5 }, { wch: max_width6 }, { wch: max_width7 }, { wch: max_width8 } ];
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, faktury_translate + "1");
    /* save to file */
    XLSX.writeFile(wb, fileName);
    // console.log("excel2");

  }
    
  public getDebts(): void {
    this.DisplayedColumnsDebts = this.getDisplayColumns(InvoiceTypeEnum.Debt);

    this.IsLoadingDebts = true;
    this.subscriptions.push(this.invoicesService
      .getDebts(this.dateFrom, this.dateTo, '', '', '', '', '', '')
      .pipe(
        tap((debts) => {
          this.IsLoadingDebts = false;
          this.IsRateLimitReachedDebts = false;
          this.DebtsDataSource = new MatTableDataSource(debts);
        }),
        catchError(() => {
          this.IsLoadingDebts = true;
          this.IsRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe());
  }

  public getInvoices(): void {
    this.DisplayedColumnsInvoices = this.getDisplayColumns(InvoiceTypeEnum.Invoice);
  }

  public getDisplayColumns(invoiceType: InvoiceTypeEnum): string[] {
    let displayColumns: string[];
    switch (invoiceType) {
      case InvoiceTypeEnum.Invoice:
        displayColumns = ['InvoiceNumber', 'InvoiceType', 'IsCorrection', 'InvoiceDate', 'Currency', 'Netto', 'Brutto', 'Tax', 'PaymentDate'];
        return displayColumns;
      case InvoiceTypeEnum.Debt:
        displayColumns = ['InvoiceNumber', 'InvoiceDate', 'Currency', 'PriceBrutto', 'PaymentDate', 'DaysAfterPaymentDate'];
        return displayColumns;
    }
  }

  public getInvoiceOrDebts(invoiceType: InvoiceTypeEnum) {
    switch (invoiceType) {
      case InvoiceTypeEnum.Invoice:
        break;

      case InvoiceTypeEnum.Debt:
        break;
    }
  }

  dateRangeChange(event: Event): void {
    if (this.Range.controls['start'].value && this.Range.controls['end'].value) {
      this.dateFrom = this.Range.controls['start'].value.toISOString().slice(0, 10);
      this.dateTo = this.Range.controls['end'].value.toISOString().slice(0, 10);
      this.dateChange.emit({
        value: '',
      });
    }
  }

  labelsMatPaginator(): void {
    this.paginator._intl.itemsPerPageLabel = '';
    this.paginator._intl.firstPageLabel = '';
    this.paginator._intl.previousPageLabel = '';
    this.paginator._intl.nextPageLabel = '';
    this.paginator._intl.lastPageLabel = '';
    this.paginator._intl.getRangeLabel = rangeLabel;
  }
}

const rangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 / ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} / ${length}`;
};

