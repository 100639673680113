<div class="container">

  <h1 *ngIf="tresci.id == null" id="lista" [translation]="TranslationEnum.Translation">
    {{'lista'|translate}}
    <!-- <app-tlumacz [id]="'lista'" [typ]="'zmienne'" [tresci]="tresci"></app-tlumacz> -->
  </h1>

  <table>
    <tr class="naglowek">
      <td id="lp" [translation]="TranslationEnum.Translation">
        {{'lp'|translate}}
        <!-- <app-tlumacz [id]="'lp'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </td>
      <td id="numerfaktury" [translation]="TranslationEnum.Translation">
        {{'numerfaktury'|translate}}
        <!-- <app-tlumacz [id]="'NumerFaktury'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </td>
      <td id="datawystawienia" [translation]="TranslationEnum.Translation">
        {{'datawystawienia'|translate}}
        <!-- <app-tlumacz [id]="'DataWystawienia'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </td>
      <td id="datazaplaty" [translation]="TranslationEnum.Translation">
        {{'datazaplaty'|translate}}
        <!-- <app-tlumacz [id]="'DataZaplaty'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </td>
      <td id="dnipoplatnosci" [translation]="TranslationEnum.Translation">
        {{'dnipoplatnosci'|translate}}
        <!-- <app-tlumacz [id]="'DniPoPlatnosci'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </td>
      <td id="brutto" [translation]="TranslationEnum.Translation">
        {{'brutto'|translate}}
        <!-- <app-tlumacz [id]="'Brutto'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </td>
    </tr>
    <tr *ngFor="let zadluzenie of Zadluzenia; let i = index">
      <td class="lewa_pozycja">
        <div class="ramka_td">{{i + 1}}.</div>
      </td>
      <td>
        <span>
          <div class="ramka_td">
            {{zadluzenie.InvoiceNumber}}
          </div>
        </span>
      </td>
      <td>
        <span>
          <div class="ramka_td">
            {{zadluzenie.InvoiceDate | date:'yyyy-MM-dd'}}
          </div>
        </span>
      </td>
      <td>
        <span>
          <div class="ramka_td">
            {{zadluzenie.PaymentDate | date:'yyyy-MM-dd'}}
          </div>
        </span>
      </td>
      <td>
        <span>
          <div class="ramka_td">
            <div>
              {{zadluzenie.DaysAfterPaymentDate }}
              <span id="dni" [translation]="TranslationEnum.Translation">{{'dni'|translate}}</span>
              <!-- <app-tlumacz [id]="'Dni'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </div>
          </div>
        </span>
      </td>
      <td>
        <div class="ramka_td cena">
          <span [ngStyle]="{'color': zadluzenie.PriceBrutto <= 0 ? 'red': 'green' }">
            {{zadluzenie.PriceBrutto}}&nbsp;
            {{zadluzenie.Currency}}
            <!-- <app-tlumacz [id]="zadluzenie.Currency" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </span>
        </div>
      </td>
    </tr>
  </table>
</div>
