import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeliveryComponent } from './../delivery/delivery.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomTranslateLoader } from 'src/app/shared/language-loader/CustomTranslateLoader';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { ComponentsModule } from 'src/app/shared/components';


@NgModule({
  declarations: [
    DeliveryComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader, useClass: CustomTranslateLoader
      }
    }),
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule
  ],
  exports: [
    DeliveryComponent
  ]

})
export class DeliveryModule { }
