import { TooltipImgComponent } from './../components/tooltip-img/tooltip-img.component';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnInit, OnChanges } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';


@Directive({
  selector: '[tooltipImg]'
})

export class TooltipImgDirective implements OnInit, OnChanges {

  @Input('tooltipImg') image: string;

  private overlayRef: OverlayRef;
  private tooltipRef: ComponentRef<TooltipImgComponent>;

  constructor(private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'end',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
        offsetY: -8,
      }]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  ngOnChanges(): void {
    if (this.tooltipRef !== undefined) {
      this.tooltipRef.instance.image = this.image;
    }
  }

  @HostListener('mouseover')
  show() {
    if (this.image !== '') {
      this.tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipImgComponent));
      this.tooltipRef.instance.image = this.image;
    }
  }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }
}
