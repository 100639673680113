<h2 mat-dialog-title>
  <span id="wyceń_zamówienie" [translation]="TranslationEnum.Translation"> {{'wyceń_zamówienie'|translate}}</span>
  ({{data.B2BId}})
</h2>
<div mat-dialog-content>
  <form [formGroup]="EstimateGroup" #estimateForm="ngForm">
    <div class="row">
      <div class="col-6">
        <!-- Produkt -->
        <label id="produkt" [translation]="TranslationEnum.Translation">
          {{'produkt'|translate}}
        </label>
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <input matInput formControlName="produkt" [ngModel]="EstimateGroup.controls.produkt.value| translate"
            type="text" readonly>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <!-- Contractor number order -->
        <label id="numerzamowieniakontrahenta" [translation]="TranslationEnum.Translation">
          {{'numerzamowieniakontrahenta'|translate}}
        </label>
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <input matInput formControlName="contractorNumberOrder" type="text">
        </mat-form-field>
      </div>

      <div class="col-4">
        <!-- Customer name -->
        <label id="nazwaklienta" [translation]="TranslationEnum.Translation">
          {{'nazwaklienta'|translate}}
        </label>
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <input matInput formControlName="customerName" type="text">
        </mat-form-field>
      </div>

      <div class="col-4">
        <!-- NIP -->
        <label id="nip" [translation]="TranslationEnum.Translation">
          {{'nip'|translate}}
        </label>
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <input matInput formControlName="nip" type="text">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <!-- Address -->
        <label id="adres" [translation]="TranslationEnum.Translation">
          {{'adres'|translate}}
        </label>
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <input matInput formControlName="address" type="text">
        </mat-form-field>
      </div>

      <div class="col-3">
        <!-- Zip code -->
        <label id="kodpocztowy" [translation]="TranslationEnum.Translation">
          {{'kodpocztowy'|translate}}
        </label>
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <input matInput formControlName="zipCode" type="text">
        </mat-form-field>
      </div>

      <div class="col-3">
        <!-- City -->
        <label id="miasto" [translation]="TranslationEnum.Translation">
          {{'miasto'|translate}}
        </label>
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <input matInput formControlName="city" type="text">
        </mat-form-field>
      </div>

      <div class="col-3">
        <!-- Country -->
        <label id="kraj" [translation]="TranslationEnum.Translation">
          {{'kraj'|translate}}
        </label>
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <mat-select formControlName="country">
            <mat-option *ngFor="let item of CountriesList" [value]="item.Id">
              {{item.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">

      <div class="col-4">
        <!-- System discount -->
        <label id="rabatsys" [translation]="TranslationEnum.Translation">
          {{'rabatsys'|translate}}
        </label>
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <input matInput formControlName="systemDiscount" type="number" step="0.01" min="0">
        </mat-form-field>
      </div>

      <div class="col-4">
        <!-- Electrician discount -->
        <label id="rabatel" [translation]="TranslationEnum.Translation">
          {{'rabatel'|translate}}
        </label>
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <input matInput formControlName="electricianDiscount" type="number" step="0.01" min="0">
        </mat-form-field>
      </div>

      <div class="col-4">
        <!-- Accessories discount -->
        <label id="rabatakce" [translation]="TranslationEnum.Translation">
          {{'rabatakce'|translate}}
        </label>
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <input matInput formControlName="accessoriesDiscount" type="number" step="0.01" min="0">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <!-- Calculate VAT for total -->
        <mat-checkbox [formControl]="calculateVATForTotal" [checked]="false" (change)="changeControl()">
          <span id="wyliczcalyvat" [translation]="TranslationEnum.Translation">
            {{'wyliczcalyvat'|translate}}
          </span>
        </mat-checkbox>
      </div>

      <div class="col-4">
        <!-- VAT for total -->
        <mat-form-field appearance="outline" color="accent" class="d-block w-100">
          <input matInput formControlName="vatForTotal" type="number" min="0" [attr.disabled]="calculateVATForTotal.value? null: ''" >
        </mat-form-field>
      </div>
    </div>
  </form>

  <!-- Additional items -->
  <h2 id="dodpoz" [translation]="TranslationEnum.Translation">
    {{'dodpoz'|translate}}
  </h2>

  <form [formGroup]="AdditionalItemsGroup" (ngSubmit)="addAdditionalItem(AdditionalItemsGroup)"
    #additionalItemsForm="ngForm" class="row">

    <div class="col-4">
      <!-- Name of service -->
      <label id="nazwauslugi" [translation]="TranslationEnum.Translation">{{'nazwauslugi'|translate}}</label>
      <mat-form-field appearance="outline" color="accent" class="d-block w-100">
        <input matInput formControlName="name" type="text">
      </mat-form-field>
    </div>

    <div class="col-2">
      <!-- Quantity -->
      <label id="ilosc" [translation]="TranslationEnum.Translation">
        {{'ilosc'|translate}}
      </label>
      <mat-form-field appearance="outline" color="accent" class="d-block w-100">
        <input matInput formControlName="quantity" type="number" min="0">
      </mat-form-field>
    </div>

    <div class="col-2">
      <!-- Price -->
      <label id="cena" [translation]="TranslationEnum.Translation">
        {{'cena'|translate}}
      </label>
      <mat-form-field appearance="outline" color="accent" class="d-block w-100">
        <input matInput formControlName="price" type="number" step="0.01" min="0">
      </mat-form-field>
    </div>

    <div class="col-2">
      <!-- VAT -->
      <label id="vat" [translation]="TranslationEnum.Translation">
        {{'vat'|translate}}
      </label>
      <mat-form-field appearance="outline" color="accent" class="d-block w-100">
        <input matInput formControlName="vat" type="number" min="0">
      </mat-form-field>
    </div>

    <div class="col-2">
      <!-- Discount -->
      <label id="rabat" [translation]="TranslationEnum.Translation">
        {{'rabat'|translate}}
      </label>
      <mat-form-field appearance="outline" color="accent" class="d-block w-100">
        <input matInput formControlName="discount" type="number" step="0.01" min="0">
      </mat-form-field>
    </div>

    <div class="col-12">
      <button mat-flat-button id="dodajusluge" class="float-right" [translation]="TranslationEnum.Translation"
        color="primary" type="submit">
        {{'dodajusluge'|translate}}
      </button>
    </div>
  </form>

  <table mat-table [dataSource]="dataSource" class="mt-3 mb-3 w-100">
    <!-- Name of service Column -->
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef id="nazwauslugi" [translation]="TranslationEnum.Translation">
        {{'nazwauslugi'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Name}} </td>
    </ng-container>

    <!-- Quantity Column -->
    <ng-container matColumnDef="Quantity">
      <th mat-header-cell *matHeaderCellDef id="ilość" [translation]="TranslationEnum.Translation">
        {{'ilość'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Quantity}} </td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="Price">
      <th mat-header-cell *matHeaderCellDef id="cena" [translation]="TranslationEnum.Translation">
        {{'cena'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Price}} </td>
    </ng-container>

    <!-- VAT Column -->
    <ng-container matColumnDef="Vat">
      <th mat-header-cell *matHeaderCellDef id="vat" [translation]="TranslationEnum.Translation">
        {{'vat'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Vat}} </td>
    </ng-container>

    <!-- Discount Column -->
    <ng-container matColumnDef="Discount">
      <th mat-header-cell *matHeaderCellDef id="rabat" [translation]="TranslationEnum.Translation">
        {{'rabat'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Discount}} </td>
    </ng-container>

    <!-- Remove Column -->
    <ng-container matColumnDef="Remove">
      <th mat-header-cell *matHeaderCellDef id="usun" [translation]="TranslationEnum.Translation">
        {{'usun'|translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button color="accent" (click)="removeAdditionalItem(row)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
<div mat-dialog-actions class="float-right">
    <button mat-flat-button color="primary" class="mr-3" type="submit" id="specyfikacja"
      [translation]="TranslationEnum.Translation" (click)="getSpecification(EstimateGroup)">
      {{'specyfikacja'|translate}}
    </button> <button mat-flat-button color="primary" class=" mr-3" type="submit" id="wycena"
      [translation]="TranslationEnum.Translation" (click)="getValuation(EstimateGroup)">
      {{'wycena'|translate}}
    </button>
    <button mat-flat-button color="primary" mat-dialog-close id="zamknij" [translation]="TranslationEnum.Translation">
      {{'zamknij'|translate}}
    </button>
</div>
