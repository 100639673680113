export class Contractor {
    Username: string;
    ContractorNumber: string;
    ContractorName: string;
    ContractorShortName: string;
    NIP: string;
    EnableEWdt:boolean;
    ConfirmationEWdt:boolean;
    Address:string;
    ZipCode:string;
    NIPUE:string;
    CountryName:string;
    Rynek:string;
    Country:string;
}
