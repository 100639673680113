import { RenderComponent } from './../../../shared/components/form-controls/render/render.component';
import { PdfComponent } from './../../../shared/components/form-controls/pdf/pdf.component';
import { CheckboxFileComponent } from './../../../shared/components/form-controls/checkbox-file/checkbox-file.component';
import { KimComponent } from './../../../shared/components/form-controls/kim/kim.component';
import { FileComponent } from './../../../shared/components/form-controls/file/file.component';
import { MessageDialogComponent } from './../../../shared/components/form-controls/message-dialog/message-dialog.component';
import { FabricComponent } from './../../../shared/components/form-controls/fabric/fabric.component';
import { ImgComponent } from './../../../shared/components/form-controls/img/img.component';
import { CheckboxSelectComponent } from './../../../shared/components/form-controls/checkbox-select/checkbox-select.component';
import { RadioComponent } from './../../../shared/components/form-controls/radio/radio.component';
import { RadioImgComponent } from './../../../shared/components/form-controls/radio-img/radio-img.component';
import { CheckboxInputComponent } from './../../../shared/components/form-controls/checkbox-input/checkbox-input.component';
import { OnInit, Input, ComponentFactoryResolver, ViewContainerRef, Directive, ComponentRef, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CheckboxComponent } from 'src/app/shared/components/form-controls/checkbox/checkbox.component';
import { InputComponent } from 'src/app/shared/components/form-controls/input/input.component';
import { LabelComponent } from 'src/app/shared/components/form-controls/label/label.component';
import { NewLineComponent } from 'src/app/shared/components/form-controls/new-line/new-line.component';
import { SelectImgComponent } from 'src/app/shared/components/form-controls/select-img/select-img.component';
import { SelectComponent } from 'src/app/shared/components/form-controls/select/select.component';
import { IControlComponent } from 'src/app/shared/interfaces/control-component.interface';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { SelectMultiColumnComponent } from 'src/app/shared/components/form-controls/select-multi-column/select-multi-column.component';
import { VisualizationComponent } from 'src/app/shared/components/form-controls/visualization/visualization.component';
import { CheckFabricComponent } from 'src/app/shared/components/form-controls/checkfabric/checkfabric.component';
import { CheckSelectMultiColumnComponent } from 'src/app/shared/components/form-controls/select-multi-column-check/check-select-multi-column.component';



const componentMapper: { [key: string]: any } = {

  Check: CheckboxComponent,
  CheckFile: CheckboxFileComponent ,
  CheckNumber: CheckboxInputComponent,
  CheckSelect: CheckboxSelectComponent,
  CheckFabric: CheckFabricComponent,
  // CheckSketch:,
  CheckText: CheckboxInputComponent,
  Fabric: FabricComponent,
  File: FileComponent,
  // Hidden:,
  VarianticVisualization: VisualizationComponent,
  Img: ImgComponent,
  Kim: KimComponent,
  Komunikat: MessageDialogComponent,
  Label: LabelComponent,
  Render: RenderComponent,
  // Module:,
  NewLine: NewLineComponent,
  Number: InputComponent,
  Pdf: PdfComponent,
  Radio: RadioComponent,
  RadioImg: RadioImgComponent,
  Select: SelectComponent,
  // SelectColor: SelectColorComponent,
  SelectImg: SelectImgComponent,
  SelectMultiColumn: SelectMultiColumnComponent,
  CheckSelectMultiColumn: CheckSelectMultiColumnComponent,
  // Sketch:,
  // SnackBar:,
  Text: InputComponent,
};



@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[dynamic-control]'
})


export class DynamicControlDirective implements OnInit {
  @Input() field: HtmlControl;
  @Input() group: FormGroup;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    if (componentMapper[this.field.componentName]) {
      const factory = this.resolver.resolveComponentFactory(
        componentMapper[this.field.componentName]
      );

      const componentRef = this.container.createComponent(factory);

      this.renderer.addClass(componentRef.location.nativeElement, 'col-min-' + this.field.width);
      this.renderer.addClass(componentRef.location.nativeElement, 'col-auto');

      const component = componentRef.instance as IControlComponent;
      component.Control = this.field;
      component.Group = this.group;
    }
  }
}
