import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { environment } from 'src/environments/environment';
import { _MatRadioButtonBase } from '@angular/material/radio';
import { SystemEnum } from 'src/app/shared/enums/system-enum';



@Component({
  selector: 'app-radio-img',
  templateUrl: './radio-img.component.html',
  styleUrls: ['./radio-img.component.scss']
})
export class RadioImgComponent implements OnInit {


  TranslationEnum = TranslationEnum;

  system = '';
  public Control: HtmlControl;
  public Group: FormGroup;
  public server_url = environment.apiUrl;

  public SelectedValue: any;

  @ViewChild('radioImg', { static: true }) radioImg: _MatRadioButtonBase;

  constructor(public httpClient: HttpClient) {
    this.system = SystemEnum[window['site']];
  }

  ngOnInit(): void {
    this.Control.options.forEach((element) => {
      if (element.Key === this.Control.value) {
        this.Control.value = element;
        this.Group.get(this.Control.name).setValue(this.Control);
      }
    });
  }





}
