import { Component, OnDestroy, OnInit } from '@angular/core';
import { TresciService } from '../tresci.service';
import { UserService } from '../user.service';
import { FormV2ServiceService } from './form-v2-service.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KindOper } from '../shared/enums/kind-oper-enum';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormDataV2 } from './form-model/FormDataV2';
import { TranslationEnum } from '../shared/enums/translation-enum';
import { last, tap } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { DeliveryMethod } from '../shared/models/form/delivery-method.model';
import { Address } from 'cluster';
import { DeliveryAddress } from '../shared/models/form/delivery-address.model';
import { Parameter } from '../shared/models/form-model/models/form-controlls.model';
import { deserialize, serialize } from 'class-transformer';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UniversalDialogComponent } from '../shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from '../shared/models/dialog/dialog-data.model';
import { GrupaKrokow } from './form-model/GrupaKrokow';
import { TypEnum } from './form-model/TypEnum';
import { KrokStep } from './form-model/StepKrok';
import { Kim } from '../shared/models/form-model/kim.model';
import { Control } from './form-model/Control';
import { Modul } from './form-model/Modul';
import { FileControl } from '../shared/models/form/file-control.model';
import { TreeTechnologyComponent } from '../module-tree-technology/tree-technology/tree-technology.component';
import { ViewJSONComponent } from './form-control/controls/view-json/view-json.component';
import { BroadcastService } from '../shared/async-services/http/broadcast.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Complaint } from '../shared/models/reklamacje/Complaint.model';
import { ComplaintType } from './form-model/ComplaintType';


@Component({
  selector: 'app-form-v2',
  templateUrl: './form-v2.component.html',
  styleUrls: ['./form-v2.component.scss']
})
export class FormV2Component implements OnInit,OnDestroy {
  private subscriptions: Subscription[] = [];
  //OrderData
  SystemName: string;
  culture: any;
  IsTrader: any;


  WczytaneKroki:KrokStep[] = [];

  public TranslationLoaded: BehaviorSubject<boolean>;
  FormData:FormDataV2; // = new FormDataV2();
  zapisaneParametry:Parameter[] = [];
  zapisaneParameterExtensions:Kim[] = []

  wczytywanie: boolean = false;
  public TranslationEnum = TranslationEnum;
  zapisanePliki: FileControl[];
  broadcastErrorSubscription: Subscription;

  
  constructor(private sanitizer: DomSanitizer, private formService: FormV2ServiceService,public dialog: MatDialog,private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,private broadcastService: BroadcastService, public tresci: TresciService, public user: UserService, private router: Router) {

      this.FormData = new FormDataV2(router);
      this.activatedRoute.params.subscribe((params) => {
        localStorage.site = params.system;
        window['site'] = params.system;
        tresci.wczytaj_tresci_tlumaczen2(window['culture'], params.system);
        this.TranslationLoaded = new BehaviorSubject(false);
    
        this.subscriptions.push(this.translateService.reloadLang(this.translateService.currentLang).subscribe((x) => this.TranslationLoaded.next(true)));
      });

   

    }
    zapiszDoJSON(){
      this.FormData.ZapiszParametryJSON(this.sanitizer);
    }
    zapiszDoXML(){
      this.FormData.ZapiszParametryXML(this.sanitizer);
    }
   
  ngOnDestroy(): void {
    // console.log("destroy");
    localStorage.site = "stale";
    window['site'] = "stale";
  }
  returnToOrders(): void {
    if (!this.FormData.Preview) {
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: '',
        content: 'czy_na_pewno_chcesz_zamknac',
        answerOk: false,
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          localStorage.setItem('goToPage', '');
          this.router.navigate(['Orders']);
        }
      }));
    } else {
      this.router.navigate(['Orders']);
    }
  }
  otworzDev(dane){
    var dialogRef = this.dialog.open(ViewJSONComponent, {
      maxWidth: Math.round(window.innerWidth * 0.6569),
      // maxHeight: Math.round(window.innerHeight * 0.9595),
      width: '100%',
      // height: '100%',
      disableClose: false,
      data: dane,
    });
  }


  changeJSON(e?: any): void {
   
   

    const addFile = e.target.files.item(0);
    // console.log("file", addFile, addFile.text());
    addFile.text().then(value => {
      
      if(addFile.name.includes(".json")){
        // console.log("json", JSON.parse(value));
        // console.log("json1");
        this.zapisaneParametry = this.FormData.WczytajParametry(JSON.parse(value));
        this.zapisaneParameterExtensions = this.FormData.WczytajExtParametry(JSON.parse(value));
        this.zapisanePliki = this.FormData.WczytajPliki(JSON.parse(value));
        this.FormData.Order =this.FormData.WczytajOrder(JSON.parse(value)); 
        //// console.log("json4", JSON.parse(value));a
        this.FormData.CzyWczytane = true;
        //this.wczytajZapisane();
        // console.log("json2");

        this.FormData.Parameters =  this.zapisaneParametry;
        if(this.zapisanePliki != null){
          this.FormData.Files = this.zapisanePliki;
        }
        // console.log("json3");
        // console.log("json del", this.FormData.Order);
        
        this.FormData.ParameterExtensions = this.zapisaneParameterExtensions;
        
        this.FormData.ustawDelivery(this.FormData.Order.DeliveryMethodId, this.FormData.Order.DeliveryAddressId); 
        this.FormData.ustawModuly(); 
        
        // console.log("json4");
          this.FormData.supervisor.auto = 2;
          this.FormData.supervisor.wczytywanieAuto = true;
          this.FormData.next(this.tresci, this.formService, 'Adres', true, this.dialog);
        
        



      } else if(addFile.name.includes(".xml")){
      

        var xml2js = require('xml2js');
        var json;
          xml2js.parseString( value, {explicitArray: false} ,function (err, result) {
            console.dir(result); //Prints JSON object!
            json = result.order;
            
          });
          //// console.log(json, value);
          this.zapisaneParametry = this.FormData.WczytajParametry(json); 
          this.zapisaneParameterExtensions = this.FormData.WczytajExtParametry(json);
          if(this.FormData.ParameterExtensions == null){
            this.FormData.ParameterExtensions = [];
           }
          this.zapisanePliki = this.FormData.WczytajPliki(json);
          this.FormData.Order =this.FormData.WczytajOrder(json);
          this.FormData.CzyWczytane = true;



         // this.wczytajZapisane();
         this.FormData.ParameterExtensions.length
         this.FormData.Parameters =  this.zapisaneParametry;
         if(this.zapisanePliki != null){
          this.FormData.Files = this.zapisanePliki;
         }
         this.FormData.ParameterExtensions = this.zapisaneParameterExtensions;
         // console.log("E", this.FormData.ParameterExtensions);
         if(this.FormData.ParameterExtensions == null){
          this.FormData.ParameterExtensions = [];
         } else if(!Array.isArray(this.FormData.ParameterExtensions)){
          
          var p =this.FormData.ParameterExtensions;
          this.FormData.ParameterExtensions = [];
          this.FormData.ParameterExtensions.push(p);
         }
         // console.log("json del2", this.FormData.Order.DeliveryMethodId, this.FormData.Order.DeliveryAddressId);
         this.FormData.ustawDelivery(this.FormData.Order.DeliveryMethodId, this.FormData.Order.DeliveryAddressId); 
         this.FormData.ustawModuly(); 
        
          this.FormData.supervisor.auto = 2; 
          this.FormData.supervisor.wczytywanieAuto = true;
          this.FormData.next(this.tresci, this.formService, 'Adres', true, this.dialog);
         
         


       
      } else {
        // console.log("nie obsługiwany typ plików");
      }
      
 
    })
    .catch(error => {
      // console.log("Something went wrong" + error);
    });
  }

  public Weryfikuj(){
    //this.FormData.supervisor.wczytywanieAuto = true;
    this.FormData.supervisor.auto = 1;
    this.FormData.next(this.tresci,this.formService, this.FormData.supervisor.aktualnyTyp, false, this.dialog);
  }
  public WeryfikujWszystko(){
    //this.FormData.supervisor.wczytywanieAuto = true;
    this.FormData.supervisor.auto = 1;
    this.FormData.next(this.tresci,this.formService, this.FormData.supervisor.aktualnyTyp, false, this.dialog);
  }

  

  protected showTechnologyValuationNext() {
    var step = null;
    if(this.FormData.supervisor.aktualnyTyp != TypEnum.Adres){
      try {
        step = this.FormData.AktualneKroki().GetLastStep().Step;
      } catch(Exception){

      }
      
    }
    this.dialog.open(TreeTechnologyComponent, {
      width: '1250px',
      maxWidth: '90%',
      disableClose: true,
      data: {
        FormGroupForResultInfo: this.FormData,
        StepForResultInfo: step,
        Parameters: this.FormData.GetParams(),
        PreviousPositionParameters: this.FormData.GetPrevParams(),
        IsValuation: false,
        ProductPrefix: this.FormData.produktPrefix,
        IsTrader: this.IsTrader
      },
    });
  }
  protected showTechnologyValuation() {
    var step = null;
    if(this.FormData.supervisor.aktualnyTyp != TypEnum.Adres){
      try {
        //step = this.FormData.AktualneKroki().GetLastStep().Step;
        if(step == null && this.FormData.AktualneKroki().kroki.length > this.FormData.AktualneKroki().akualnyNumer){
          step = this.FormData.AktualneKroki().kroki[this.FormData.AktualneKroki().akualnyNumer].Step;
        }

      } catch(Exception){

      }
      
    }
    this.dialog.open(TreeTechnologyComponent, {
      width: '1250px',
      maxWidth: '90%',
      disableClose: true,
      data: {
        FormGroupForResultInfo: this.FormData,
        StepForResultInfo: step,
        Parameters: this.FormData.GetParams(),
        PreviousPositionParameters: this.FormData.GetPrevParams(),
        IsValuation: true,
        ProductPrefix: this.FormData.produktPrefix,
        IsTrader: this.IsTrader
      },
    });
  }
  ngOnInit(): void {
    // console.log(this.user);
    this.culture = window['culture'];
    this.wczytywanie = true;
    this.subscriptions.push(this.activatedRoute.params.subscribe((params) => {
      this.FormData.orderId = params.id;
      this.FormData.kindOper = KindOper[params.kindOper as keyof typeof KindOper];
      this.SystemName = 'system_' + params.system.toLowerCase();
      localStorage.site = params.system;
      window['site'] = params.system;
      this.FormData.produktPrefix = params.system;
      //this.formService.ProductPrefix = params.system;
      //window['site'] = this.formService.ProductPrefix;
      // this.TranslationLoaded = new BehaviorSubject(false);
      if(window['culture'] != null && window['culture'] != ''){
        this.translateService.currentLang = window['culture']; 
      }
      this.ZmienTrader();
      this.FormData.Preview = this.FormData.kindOper === KindOper.Preview || this.FormData.kindOper === KindOper.PreviewClient;
      
      this.FormData.culture = this.culture;
      this.FormData.IsTrader = this.IsTrader;
      if(this.FormData.kindOper === KindOper.Copy || this.FormData.kindOper === KindOper.Edit || this.FormData.kindOper === KindOper.EditClient || this.FormData.kindOper === KindOper.Preview || this.FormData.kindOper === KindOper.PreviewClient){
        //this.FormData.supervisor.auto = true;
        //this.FormData.ustawModuly(); 
        this.GetAllStepsMod(null);
      } else {
        this.zapisaneParametry = this.FormData.WczytajParametry(); 
        this.zapisaneParameterExtensions = this.FormData.WczytajExtParametry();
        this.zapisanePliki = this.FormData.WczytajPliki();
        // console.log("FormData2", this.zapisaneParameterExtensions);
  /*
        if(this.zapisaneParametry.length > 0){
  
          // console.log("params",this.StepSheet.WczytajParametry());
          this.subscriptions.push(this.formService.GetAllSteps(this.StepSheet.produktPrefix, this.culture, null, this.IsTrader, this.zapisaneParametry).
          subscribe((data) => {
            this.StepSheet.ustawKroki(data.StepSheet);
            this.StepSheet.parametryWczytane = data.Parameters;
            this.StepSheet.ContractorAddresses = data.ContractorDelivery.ContractorAddresses;
            this.StepSheet.DeliveryMethods = data.ContractorDelivery.DeliveryMethods;
            
            
            this.StepSheet.ustawDelivery(this.StepSheet.Order.DeliveryMethodId, this.StepSheet.Order.DeliveryMethodId); 
            this.StepSheet.ZapiszParametry();
            this.wczytywanie = false;
            if(this.StepSheet.kroki.length > 0){
              this.TechVersion = this.StepSheet.kroki[0].TechVersion;
              this.ValuationVersion = this.StepSheet.kroki[0].ValuationVersion;
              
            }
            this.StepSheet.goToLast(this.tresci);
            // console.log("dd", this.StepSheet.kroki); 
          }));
        } else {
  
          */
          this.subscriptions.push(this.formService.getDeliveryAddresses().
        subscribe((data) => {
          // console.log(data, 'x');
          this.FormData.delivery.DeliveryMethods = data.DeliveryMethods;
          this.FormData.delivery.ContractorAddresses = data.ContractorAddresses;
          this.wczytywanie = false;
          var byl_def = false;
          
          this.FormData.delivery.DeliveryMethods.forEach(deliveryM => {
            if(deliveryM.IsDefault){
              byl_def = true;
              this.FormData.delivery.DeliveryMethod = deliveryM;
              if(deliveryM.IsDeliveryAddressRequired){
                this.FormData.delivery.ContractorAddresses.forEach(adres => {
                  if(adres.Default){
                    this.FormData.delivery.ChosenAdresses = adres;
                  }
                  
                });
              }
            }
          });

          if(!byl_def && this.FormData.delivery.DeliveryMethods.length > 0){
            this.FormData.delivery.DeliveryMethod = this.FormData.delivery.DeliveryMethods[0];
            
          }

/*
          if(this.FormData.delivery.DeliveryMethods.length > 1){
            this.FormData.delivery.DeliveryMethod = this.FormData.delivery.DeliveryMethods[1];
            this.FormData.delivery.ContractorAddresses.forEach(adres => {
              if(adres.Default){
                this.FormData.delivery.ChosenAdresses = adres;
              }
              
            });
            
          } else if(this.FormData.delivery.DeliveryMethods.length > 0){
            this.FormData.delivery.DeliveryMethod = this.FormData.delivery.DeliveryMethods[0];
            
          }*/
          // console.log("aa", this.FormData.delivery.DeliveryMethods); 
          
        }));
        //}
        
        
      }
    
      //this.subscriptions.push(this.translateService.reloadLang(this.translateService.currentLang).subscribe((x) => this.TranslationLoaded.next(true)));
    }));

    this.broadcastErrorSubscription = this.broadcastService.ErrorHttp.asObservable().subscribe((x) => {
      if (x !== null) {
        // console.log('b1', x);
        x = x.replace('System.Exception:','');
        var y= x.split('\\r');
        var z = '';
        if(y.length > 0){
          z = y[0];
        } 
        y.forEach(element => {
          
        });
        z = z.replace(' ','');
        z = z.replace('"','');
        this.modalAlertRequest('blad', this.FormData.supervisor.aktualny_mod + " " + this.FormData.supervisor.aktualna_poz, z);
      }
    })



    
    
  }

  private modalAlertRequest(title: string, sendStep: string, error: any) {
    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
    const data: DialogData = {
      title: title,
      step: sendStep,
      errorType: 'blad',
      content: `${error}`,
      answerOk: true,
    };
    this.broadcastService.ErrorHttp.next(null);
    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data, role: 'alertdialog' });
  }

public GetAllStepsMod(numer:number){
  if(this.FormData.IsFooter){
    this.FormData.supervisor.goToFooter = true;
  }
  
  this.FormData.supervisor.auto = 2;
  // this.FormData.supervisor.auto = true;
  const numer_poz = numer;

      this.subscriptions.push(this.formService.GetAllSteps(this.FormData.produktPrefix, this.culture, this.FormData.orderId, this.IsTrader, null, numer_poz).
      subscribe((data) => {
        // console.log(data);
        this.FormData.LoadingName = 'zestaw_lad';
        this.FormData.Parameters = data.Parameters;
        this.FormData.ParameterExtensions = data.ParameterExtensions;
        this.FormData.Order = data.Order;
        this.FormData.delivery.ContractorAddresses = data.ContractorDelivery.ContractorAddresses;
        this.FormData.delivery.DeliveryMethods = data.ContractorDelivery.DeliveryMethods;
        
         
        this.FormData.ustawDelivery(this.FormData.Order.DeliveryMethodId, this.FormData.Order.DeliveryAddressId); 

        this.FormData.delivery.ChosenComplaint = new Complaint();
        if(this.FormData.Order.ComplaintId != null){
          this.FormData.delivery.ChosenComplaint.Id = this.FormData.Order.ComplaintId;
          this.FormData.delivery.ChosenComplaint.ComplaintNumber = this.FormData.Order.ComplaintNumber;
          this.FormData.delivery.ComplaintType = new ComplaintType();
          this.FormData.delivery.ComplaintType.Id = 1;
        } else if(this.FormData.Order.ChargeableRepairsId != null){
          this.FormData.delivery.ChosenComplaint.Id = this.FormData.Order.ChargeableRepairsId;
          this.FormData.delivery.ChosenComplaint.ComplaintNumber = this.FormData.Order.ChargeableRepairsNumber;
          this.FormData.delivery.ComplaintType = new ComplaintType();
          this.FormData.delivery.ComplaintType.Id = 2;
        }

        this.FormData.ustawModuly(); 
        if(!this.FormData.delivery.complaint_check || this.FormData.delivery.ChosenComplaint != null){ 
            // console.log(this.delivery); 
            if(this.FormData.czyPoprawneDelivery()){
                // console.log("xxx");
                this.FormData.delivery.AdressEdit = false;
                this.FormData.supervisor.nastepnyTyp();
            }
        }
        data.StepSheet.forEach(krok => {
          this.WczytaneKroki.push(krok);
        });
        
        //if(false){
          this.FormData.ustawKroki(this.WczytaneKroki, this.formService, this.dialog);
          //this.FormData.ustawKroki(data.StepSheet);
          this.wczytywanie = false;
         //  // console.log("test", this.FormData.czyPoprawneDelivery());
          //if(!this.FormData.czyPoprawneDelivery()){
          //  this.FormData.ZmienZakladke(0, this.formService,this.tresci, this.dialog);
          //}
       /* } else {
          if(numer_poz == null){
            this.GetAllStepsMod(1);
          } else {
            this.GetAllStepsMod(numer_poz+1);
          }
          
        }
        */
        /*
        


        this.FormData.ZapiszParametry();
        this.wczytywanie = false;
       
        this.FormData.goToLast(this.tresci);
        // console.log("dd", this.FormData.kroki); 
        */
      }, (err) => {
        // console.log("blad zapisu");
        this.wczytywanie = false;
      }, () => {
          // console.log("po zapisie");
          this.wczytywanie = false;
      }));
    
      
      
    
  
    //this.subscriptions.push(this.translateService.reloadLang(this.translateService.currentLang).subscribe((x) => this.TranslationLoaded.next(true)));
  
}

  public usunZapisane(){
    this.zapisaneParametry = [];
    this.FormData.Parameters = [];
    this.zapisanePliki = [];
    this.FormData.ZapiszParametry(this.zapisaneParametry);
  }
  public wczytajZapisane(){
    if(this.zapisaneParametry.length > 0){
      // this.FormData.kroki = [];
      this.FormData.moduly = [];
      this.FormData.stopka = new GrupaKrokow(TypEnum.Footer);
      this.FormData.naglowek = new GrupaKrokow(TypEnum.Header);
      this.FormData.stopka.aktualizujNumer();
      this.FormData.naglowek.aktualizujNumer();
      
      this.FormData.ustawModuly(); 
      // console.log("this.zapisaneParameterExtensions 2", this.zapisaneParameterExtensions);
      
      this.wczytywanie = true;
      // console.log("params",this.FormData.WczytajParametry());
      this.subscriptions.push(this.formService.GetAllSteps(this.FormData.produktPrefix, this.culture, null, this.IsTrader, this.zapisaneParametry, null).
      subscribe((data) => {
        
        this.FormData.Parameters = data.Parameters;
        this.FormData.Files = this.zapisanePliki;
        this.FormData.ParameterExtensions = this.zapisaneParameterExtensions;
        this.FormData.ustawKroki(data.StepSheet, this.formService, this.dialog);
        this.FormData.delivery.ContractorAddresses = data.ContractorDelivery.ContractorAddresses;
        this.FormData.delivery.DeliveryMethods = data.ContractorDelivery.DeliveryMethods;
        
        
        // console.log("Order data", this.FormData.Order);
        this.FormData.ustawDelivery(this.FormData.Order.DeliveryMethodId, this.FormData.Order.DeliveryAddressId); 

        this.FormData.delivery.ChosenComplaint = new Complaint();
        if(this.FormData.Order.ComplaintId != null){
          this.FormData.delivery.ChosenComplaint.Id = this.FormData.Order.ComplaintId;
          this.FormData.delivery.ChosenComplaint.ComplaintNumber = this.FormData.Order.ComplaintNumber;
          this.FormData.delivery.ComplaintType = new ComplaintType();
          this.FormData.delivery.ComplaintType.Id = 1;
        } else if(this.FormData.Order.ChargeableRepairsId != null){
          this.FormData.delivery.ChosenComplaint.Id = this.FormData.Order.ChargeableRepairsId;
          this.FormData.delivery.ChosenComplaint.ComplaintNumber = this.FormData.Order.ChargeableRepairsNumber;
          this.FormData.delivery.ComplaintType = new ComplaintType();
          this.FormData.delivery.ComplaintType.Id = 2;
        }
        
        
        console.log("FormData", this.FormData);
        
        this.FormData.ZapiszParametry();
        this.wczytywanie = false;
        
        this.FormData.goToLast(this.tresci);
        
        // console.log("dd", this.FormData.kroki); 
        this.zapisaneParametry = [];
        
      }));
    }
  }
 
  CzyPoprawnyModul(mod){
    // console.log("mod", mod);
    if(mod > 0 && this.FormData.moduly.length > mod - 1){
      return this.FormData.moduly[mod - 1].CzyPozPoprawny() && this.FormData.moduly[mod - 1].CzyFtPoprawny() && this.FormData.moduly[mod - 1].zakonczono_pozycje;
    } else {
      if(this.FormData.moduly.length == 0){
        this.FormData.dodajModul(this.formService, this.tresci, this.dialog);
        return false;
      } else {
        return false;
      }
      
    }

    //FormData.moduly[FormData.supervisor.getAktualnyMod() - 1].CzyPozPoprawny()
  }


  public CzyjestNextModule(){
    if(this.FormData.moduly.length >  this.FormData.supervisor.getAktualnyMod()){
      return true;
    } else {
      return false;
    }
  }

  public UsunModul(){
    if(!this.FormData.wczytywanie){
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: '',
        content: 'czy_na_pewno_chcesz_usunac_zestaw',
        answerOk: false,
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.FormData.UsunModul();
        }
      }));
    }
  }
 
  
  public CzyJestFooter(){
    var moduly_ok = true;
    this.FormData.moduly.forEach(mod => {
      if(!mod.CzyFtPoprawny()){
        moduly_ok = false;
      }
      if(mod.pozycje.length == 0){
        moduly_ok = false;
      }
    });
    if(this.FormData.moduly.length == 0){
      moduly_ok = false;
    }
    return this.FormData.IsFooter && moduly_ok && this.FormData.IsFooter;
  }
  private ZmienTrader(){
    if (this.FormData.kindOper === KindOper.Add) {
      this.IsTrader = window['isTrader'];
    } else if (this.FormData.kindOper === KindOper.Copy) {
      this.IsTrader = window['isTrader'];
    } else if (this.FormData.kindOper === KindOper.EditClient && window['isTrader']) {
      this.IsTrader = !window['isTrader'];
    } else if (this.FormData.kindOper === KindOper.PreviewClient && window['isTrader']) {
      this.IsTrader = !window['isTrader'];
    } else {
      this.IsTrader = window['isTrader'];
    }
  }

}
