<div class="container">


  <h1 *ngIf="tresci.id == null" id="listafaktur" [translation]="TranslationEnum.Translation">
    {{'listafaktur'|translate}}
    <!-- <app-tlumacz [id]="'listaFaktur'" [typ]="'zmienne'" [tresci]="tresci" ></app-tlumacz> -->
  </h1>
  <h1 *ngIf="tresci.id != null" id="szczegóły_zamówienia" [translation]="TranslationEnum.Translation">
    {{'szczegóły_zamówienia'|translate}}
    <!-- <app-tlumacz [id]="'szczegóły_zamówienia'" [typ]="'zmienne'" [tresci]="tresci" ></app-tlumacz> -->
  </h1>

  <div class="menu_zamowien">
    <table (border)="0">
      <tr>
        <td (click)="CheckGetDebts = true; getDebts();">
          <span id="zadluzenia" [translation]="TranslationEnum.Translation">
            {{'zadluzenia'|translate}}
            <!-- <app-tlumacz [id]="'Zadluzenia'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </span>
          <div class="border_bottom"></div>
        </td>
        <td (click)="CheckGetDebts = false; getDebts();">
          <span id="faktury" [translation]="TranslationEnum.Translation">
            {{'faktury'|translate}}
            <!-- <app-tlumacz [id]="'Faktury'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </span>
          <div class="border_bottom"></div>
        </td>
      </tr>
    </table>
  </div>

  <form *ngIf="!CheckGetDebts">
    <div class="form-group form-inline">
      <span id="data_od" [translation]="TranslationEnum.Translation">{{'data_od'|translate}}</span>
      <!-- <app-tlumacz [id]="'Data od'" [typ]="'zmienne'" [tresci]="tresci" ></app-tlumacz> -->
      <input #dataFrom [(ngModel)]="DateFrom" [disabled]="CheckGetDebts" class="form-control formDate" name="dateFrom"
        type="date">
      <span id="data_do" [translation]="TranslationEnum.Translation"> {{'data_do'|translate}} </span>
      <!-- <app-tlumacz [id]="'data do'" [typ]="'zmienne'" [tresci]="tresci" ></app-tlumacz> -->
      <input [(ngModel)]="DateTo" [disabled]="CheckGetDebts" class="form-control formDate" name="dateTo" type="date">
      <button id="filtruj" class="filtrBtn" [translation]="TranslationEnum.Translation" type="button" (click)="GetDate()">
        {{'filtruj'|translate}}
        <!-- <app-tlumacz [id]="'Filtruj'" [typ]="'zmienne'" [tresci]="tresci" ></app-tlumacz> -->
      </button>
    </div>
  </form>


  <span class="check_input" *ngIf="CheckGetDebts">
    <input type="checkbox" [(ngModel)]="isAfterPayment" (change)="getOnlyAfterPayment(isAfterPayment)" />
    <span id="czyzadluzone" [translation]="TranslationEnum.Translation">{{'czyzadluzone'|translate}}</span>
    <!-- <app-tlumacz [id]="'CzyZadluzone'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
  </span>

  <div *ngIf="!CheckGetDebts && !this.tresci.user.ListOfInvoices">
    <!--*ngIf="this.tresci.user.ListOfInvoices == null"-->
    <svg class="spinner">
      <circle cx="40" cy="40" r="35"></circle>
      <circle class="small" cx="40" cy="40" r="18"></circle>
    </svg>
  </div>
  <div class="faktury">
    <table *ngIf="!CheckGetDebts && this.tresci.user.ListOfInvoices">
      <tr class="naglowek">
        <td id="lp" [translation]="TranslationEnum.Translation">
          {{'lp'|translate}}
          <!-- <app-tlumacz [id]="'lp'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
        </td>
        <td class='sortowanie' (click)="sortuj_po('InvoiceNumber')">
          <span id="numerfaktury" [translation]="TranslationEnum.Translation"> {{'numerfaktury'|translate}}</span>
          <!-- <app-tlumacz [id]="'NumerFaktury'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
          <div class="sortuj sort_id_{{sort_id === 'InvoiceNumber'}} sort_asc_{{asc}}"></div>
        </td>
        <td id="typfaktury" [translation]="TranslationEnum.Translation">
          {{'typfaktury'|translate}}
          <!-- <app-tlumacz [id]="'TypFaktury'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
        </td>
        <td class='sortowanie' (click)="sortuj_po('InvoiceDate')">
          <span id="datawystawienia" [translation]="TranslationEnum.Translation"> {{'datawystawienia' |
            translate}}</span>
          <!-- <app-tlumacz [id]="'DataWystawienia'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
          <div class="sortuj sort_id_{{sort_id === 'InvoiceDate'}} sort_asc_{{asc}}"></div>
        </td>
        <td id="datazaplaty" [translation]="TranslationEnum.Translation">
          {{'datazaplaty'|translate}}
          <!-- <app-tlumacz [id]="'DataZaplaty'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
        </td>
        <td id="poprawiona" [translation]="TranslationEnum.Translation">
          {{'poprawiona'|translate}}
          <!-- <app-tlumacz [id]="'Poprawiona'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
        </td>
        <td id="szczegoly" [translation]="TranslationEnum.Translation">
          {{'szczegoly'|translate}}
          <!-- <app-tlumacz [id]="'Szczegoly'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </td>
        <td id="podatek" [translation]="TranslationEnum.Translation">
          {{'podatek'|translate}}
          <!-- <app-tlumacz [id]="'Podatek'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
        </td>
        <td id="brutto" [translation]="TranslationEnum.Translation">
          {{'brutto'|translate}}
          <!-- <app-tlumacz [id]="'Brutto'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
        </td>
        <td class="sortowanie last" (click)="sortuj_po('Netto')">
          <span id="netto" [translation]="TranslationEnum.Translation">{{'netto'|translate}}</span>
          <!-- <app-tlumacz [id]="'Netto'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
          <div class="sortuj sort_id_{{sort_id === 'Netto'}} sort_asc_{{asc}}"></div>

        </td>
      </tr>

      <tr class="pozycja" *ngFor="let faktura of this.tresci.user.ListOfInvoices; let i=index">
        <td class="lewa_pozycja">
          <div class="ramka_td">{{i + 1}}.</div>
        </td>
        <td>
          <div class="ramka_td">
            {{faktura.InvoiceNumber}}
          </div>
        </td>
        <td>
          <div class="ramka_td">
            {{faktura.InvoiceType}}
          </div>
        </td>
        <td>
          <div class="ramka_td">
            {{faktura.InvoiceDate | date:'yyyy-MM-dd'}}
          </div>
        </td>
        <td>
          <div class="ramka_td">
            {{faktura.PaymentDate | date:'yyyy-MM-dd'}}
          </div>
        </td>
        <td>
          <div class="ramka_td">
            <ng-container *ngIf="faktura.IsCorrection === true">
              <span class="isCorrection ikona"></span>
            </ng-container>
          </div>
        </td>
        <td>
          <div class="ramka_td">
            <span class="szczegoly ikona" (click)="open(content, faktura.InvoiceNumber)"></span>
          </div>
        </td>
        <td>
          <div class="ramka_td">
            {{faktura.Tax}}
          </div>
        </td>
        <td>
          <div class="ramka_td cena">
            <span>
              {{faktura.Brutto}}&nbsp;
              <span>
                {{faktura.Currency}}
                <!-- <app-tlumacz [id]="faktura.Currency" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
              </span>
            </span>
          </div>
        </td>
        <td>
          <div class="ramka_td cena">
            <span>
              {{faktura.Netto}}&nbsp;
              <span>
                {{faktura.Currency}}
                <!-- <app-tlumacz [id]="faktura.Currency" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
              </span>
            </span>
          </div>
        </td>
      </tr>
    </table>

    <div *ngIf="!this.tresci.user.ListOfDebts">
      <!--*ngIf="this.tresci.user.ListOfInvoices == null"-->
      <svg class="spinner">
        <circle cx="40" cy="40" r="35"></circle>
        <circle class="small" cx="40" cy="40" r="18"></circle>
      </svg>
    </div>
    <!-- LISTA ZAŁUŻENIA -->
    <table *ngIf="CheckGetDebts && this.tresci.user.ListOfDebts">
      <tr class="naglowek">
        <td id="lp" [translation]="TranslationEnum.Translation">
          {{'lp'|translate}}
          <!-- <app-tlumacz [id]="'lp'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
        </td>
        <td class='sortowanie' (click)="sortuj_po('InvoiceNumber')">
          <span id="numerfaktury" [translation]="TranslationEnum.Translation">{{'numerfaktury'|translate}}</span>
          <!-- <app-tlumacz [id]="'NumerFaktury'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
          <div class="sortuj sort_id_{{sort_id === 'InvoiceNumber'}} sort_asc_{{asc}}"></div>
        </td>
        <td class='sortowanie' (click)="sortuj_po('InvoiceDate')">
          <span id="datawystawienia" [translation]="TranslationEnum.Translation">{{'datawystawienia' |
            translate}}</span>
          <!-- <app-tlumacz [id]="'DataWystawienia'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
          <div class="sortuj sort_id_{{sort_id === 'InvoiceDate'}} sort_asc_{{asc}}"></div>
        </td>
        <td id="datazaplaty" [translation]="TranslationEnum.Translation">
          {{'datazaplaty'|translate}}
          <!-- <app-tlumacz [id]="'DataZaplaty'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
        </td>
        <td id="szczegoly" [translation]="TranslationEnum.Translation">
          {{'szczegoly'|translate}}
          <!-- <app-tlumacz [id]="'Szczegoly'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </td>
        <td id="dnipoterminie" [translation]="TranslationEnum.Translation">
          {{'dnipoterminie'|translate}}
          <!-- <app-tlumacz [id]="'DniPoTerminie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </td>
        <td id="brutto" [translation]="TranslationEnum.Translation">
          {{'brutto'|translate}}
          <!-- <app-tlumacz [id]="'Brutto'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
        </td>
      </tr>

      <tr class="pozycja debt_{{zadluzenie.AfterPayment}}"
        *ngFor="let zadluzenie of this.tresci.user.ListOfDebts; let i=index">
        <td class="lewa_pozycja">
          <div class="ramka_td">{{i + 1}}.</div>
        </td>
        <td>
          <div class="ramka_td">
            {{zadluzenie.InvoiceNumber}}
          </div>
        </td>
        <td>
          <div class="ramka_td">
            {{zadluzenie.InvoiceDate | date:'yyyy-MM-dd'}}
          </div>
        </td>
        <td>
          <div class="ramka_td">
            {{zadluzenie.PaymentDate | date:'yyyy-MM-dd'}}
          </div>
        </td>
        <td>
          <div class="ramka_td">
            <span class="szczegoly ikona" (click)="open(content, zadluzenie.InvoiceNumber)"></span>
          </div>
        </td>
        <td>
          <div class="ramka_td">
            {{zadluzenie.DaysAfterPaymentDate}}
          </div>
        </td>
        <td>
          <div class="ramka_td cena">
            <span>
              {{zadluzenie.PriceBrutto}}&nbsp;
              <span>
                {{zadluzenie.Currency}}
                <!-- <app-tlumacz [id]="zadluzenie.Currency" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
              </span>
            </span>
          </div>
        </td>
      </tr>
    </table>
  </div>


  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Pozycje faktury {{faktura}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="fakturyPoz">
        <div class="table-scroll">

          <table>
            <thead>
              <tr class="naglowekPoz">

                <td id="lppoz" [translation]="TranslationEnum.Translation">
                  {{'lppoz'|translate}}
                  <!-- <app-tlumacz [id]="'lpPoz'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </td>
                <td id="numerprodukcji" [translation]="TranslationEnum.Translation">
                  {{'numerprodukcji'|translate}}
                  <!-- <app-tlumacz [id]="'NumerProdukcji'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </td>
                <td id="numerprzedmiotu" [translation]="TranslationEnum.Translation">
                  {{'numerprzedmiotu'| translate}}
                  <!-- <app-tlumacz [id]="'NumerPrzedmiotu'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </td>
                <td class='sortowanie' (click)="sortuj_po('Name')">
                  <span id="nazwapoz" [translation]="TranslationEnum.Translation">{{'nazwapoz'|translate}}</span>
                  <!-- <app-tlumacz [id]="'NazwaPoz'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                  <div class="sortuj sort_id_{{sort_id === 'Name'}} sort_asc_{{asc}}"></div>
                </td>
                <td id="ilosc" [translation]="TranslationEnum.Translation">
                  {{'ilosc'|translate}}
                  <!-- <app-tlumacz [id]="'Ilosc'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </td>
                <td id="jedmiary" [translation]="TranslationEnum.Translation">
                  {{'jedmiary'|translate}}
                  <!-- <app-tlumacz [id]="'JedMiary'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </td>
                <td id="poledotyczy" [translation]="TranslationEnum.Translation">
                  {{'poledotyczy'|translate}}
                  <!-- <app-tlumacz [id]="'PoleDotyczy'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </td>
                <td id="podatek" [translation]="TranslationEnum.Translation">
                  {{'podatek'|translate}}
                  <!-- <app-tlumacz [id]="'Podatek'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </td>
                <td class="sortowanie" (click)="sortuj_po('NettoSzt')">
                  <span id="nettoSzt" [translation]="TranslationEnum.Translation">{{'nettoSzt'|translate}}</span>
                  <!-- <app-tlumacz [id]="'NettoSzt'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                  <div class="sortuj sort_id_{{sort_id === 'NettoSzt'}} sort_asc_{{asc}}"></div>
                </td>
                <td class="sortowanie last" (click)="sortuj_po('NettoPoz')">
                  <span id="nettoPoz" [translation]="TranslationEnum.Translation">{{'nettoPoz'|translate}}</span>
                  <!-- <app-tlumacz [id]="'NettoPoz'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                  <div class="sortuj sort_id_{{sort_id === 'NettoPoz'}} sort_asc_{{asc}}"></div>
                </td>
              </tr>
            </thead>

            <tbody>
              <tr class="pozycja" *ngFor="let fakturaPoz of FakturyPoz; let i=index">
                <td class="lewa_pozycja">
                  <div class="ramka_td">{{fakturaPoz.Position}}.</div>
                </td>
                <td>
                  <span>
                    <div class="ramka_td">
                      {{fakturaPoz.ProductionNumber}}
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    <div class="ramka_td">
                      {{fakturaPoz.ItemNo}}
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    <div class="ramka_td">
                      {{fakturaPoz.Name}}
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    <div class="ramka_td">
                      {{fakturaPoz.Quantity}}
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    <div class="ramka_td">
                      {{fakturaPoz.UnitOfmeasure}}
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    <div class="ramka_td">
                      {{fakturaPoz.Correction}}
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    <div class="ramka_td">
                      {{fakturaPoz.Tax}}
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    <div class="ramka_td cena">
                      {{fakturaPoz.PriceNetto}}
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    <div class="ramka_td cena">
                      {{fakturaPoz.Netto}}
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button id="zamknij" class="button_td" [translation]="TranslationEnum.Translation" type="button"
        (click)="modal.close('Save click')">{{'zamknij'|translate}}
        <!-- <app-tlumacz [id]="'Zamknij'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </button>
    </div>
  </ng-template>
