export class StepOptions {
  IsMarkAsToEdit: boolean;
  IsHowManySameSets = false;
  DeleteRestComponents: boolean;
  DeleteHeaderComponents = false;
  StepForEdit: string;
  ModuleType: string;
  Module: number;
  ErrorAppeard: boolean;
  IsLoadByEdit: boolean;
}
