import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslationEnum } from '../shared/enums/translation-enum';
import { TresciService } from '../tresci.service';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-needchangepassword',
  templateUrl: './needchangepassword.component.html',
  styleUrls: ['./needchangepassword.component.scss'],
})
export class NeedchangepasswordComponent implements OnDestroy{
  public TranslationEnum = TranslationEnum;
  public model: any = {};
  private subscriptions: Subscription[] = [];
  public culture = 'pl';

  constructor(public tresci: TresciService, private authorizationService: AuthorizationService, private router: Router, private cookieService: CookieService) {
    tresci.system = 'change-password';
    this.culture = window['culture'];
  }
  public getLink(jaki:string){ 
    switch(jaki){
      case 'ows':{
        switch(window['culture']){
          case 'pl': {
            return 'https://www.selt.com/pliki/pliki/52413OWS_SELT_Sp._z_o.o._2022_2.pdf';
            break;
          }
          case 'en': {
            return 'https://www.selt.com/pliki/pliki/45067GENERAL_SALES_TERMS_AND_CONDITIONS_2023.pdf';
            break;
          }
          case 'de': {
            return 'https://www.selt.com/pliki/pliki/96810Allgemeine_Verkaufsbedingungen_2023.pdf';
            break;
          }
          case 'fr': {
            return 'https://www.selt.com/pliki/pliki/64839OWS_SELT_ZOO_2018_FR.pdff';
            break;
          }
          case 'cz': {
            return 'https://selt.com/pliki/pliki/60987VSEOBECNE_PRODEJNI_PODMINKY_CZ.pdf';
            break;
          }
          case 'it': {
            return 'https://selt.com/pliki/pliki/60633OWS_IT.pdf';
            break;
          }
        }
        break;
      }
      case 'owg':{
        switch(window['culture']){
          case 'pl': {
            return 'https://www.selt.com/pliki/pliki/31257OWGSELTSpzoo2022.pdf';
            break;
          }
          case 'en': {
            return 'https://selt.com/pliki/pliki/20570GENERAL_GUARANTEE_TERMS_AND_CONDITIONS_2023.pdf';
            break;
          }
          case 'de': {
            return 'https://selt.com/pliki/pliki/73463Allgemeine_Garantiebedingungen_2023.pdf';
            break;
          }
          case 'fr': {
            return 'https://selt.com/pliki/pliki/68536Ogolnewarunkigwarancji2019francuskiCGG.pdf';
            break;
          }
          case 'cz': {
            return 'https://selt.com/pliki/pliki/80037VSEOBECNE_ZARUCNI_PODMINKY_CZ.pdf';
            break;
          }
          case 'it': {
            return 'https://selt.com/pliki/pliki/27255OWG_ITA.pdf';
            break;
          }
        }
        break;
      }
      case 'rodo':{
        switch(window['culture']){
          case 'pl': {
            return 'https://www.selt.com/pliki/pliki/76449Informacja_RODO.pdf';
            break;
          }
          case 'en': {
            return 'https://www.selt.com/pliki/pliki/76449Informacja_RODO.pdf';
            break;
          }
          case 'de': {
            return 'https://selt.com/pliki/pliki/13473DSGVO_Selt_Sp_z_oo_2020_DE.pdf';
            break;
          }
          case 'fr': {
            return 'https://selt.com/pliki/pliki/36248Informations_RGPD.pdf';
            break;
          }
          case 'cz': {
            return 'https://selt.com/pliki/pliki/79625Informace_o_GDPR_CZ.pdf';
            break;
          }
          case 'it': {
            return 'https://www.selt.com/pliki/pliki/76449Informacja_RODO.pdf';
            break;
          }
        }
        break;
      }
      case 'prawa':{
        switch(window['culture']){
          case 'pl': {
            return 'https://www.selt.com/pliki/pliki/10731Klauzula_ochrony_praw_autorskich_i_pokrewnych_oraz_praw_wlasnosci_przemyslowej.pdf';
            break;
          }
          case 'en': {
            return 'https://selt.com/pliki/pliki/45081CopyrightandRelatedRights.pdf';
            break;
          }
          case 'de': {
            return 'https://selt.com/pliki/pliki/92303Schutzklausel_von_Urheberrechten_und_verwandter_Schutzrechte_sowie_Schutzrechte_von_gewerblichem_Eigentum.pdf';
            break;
          }
          case 'fr': {
            return 'https://selt.com/pliki/pliki/49319Clause_sur_la_protection_du_droit_dauteur.pdf';
            break;
          }
          case 'cz': {
            return 'https://selt.com/pliki/pliki/76728Dolozka_o_ochrane_autorskych_prav_CZ.pdf';
            break;
          }
          case 'it': {
            return 'https://selt.com/pliki/pliki/45081CopyrightandRelatedRights.pdf';
            break;
          }
        }
        break;
      }
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  onSubmit(userForm) {
    if (userForm.form.valid) {
      this.subscriptions.push(this.authorizationService.changePassword(this.model.password).subscribe((x) => {
        localStorage.clear();
        sessionStorage.clear();
        this.cookieService.delete('_token', '/');
        this.cookieService.delete('_usi', '/');
        this.cookieService.deleteAll('/');
        this.authorizationService.User.next(null);
        this.router.navigate(['Login']);
      }));
    }
  }
}
