<div *ngFor="let krok of Kroki.Krok; let i = index; trackBy: trackByFn">
  <!-- kroki-->
  <!-- <ng-template #hidden>
     {{)}}
  </ng-template> -->
  <!-- <ng-template #notHidden> -->
  <!-- {{testCall(krok)}} -->

  <div class="margin">
    <div class="krok krok_aktywny_{{Kroki.pozycjaKrok == i}} numer_kroku_{{i}} ostatni_krok_{{krok.IsLastStep}}"
      *ngIf="i <= Kroki.pozycjaKrok">
      <span class="{{krok.id}}_{{krok.Nazwa}}">
        <app-tlumacz [id]="'Krok_' + krok.Nazwa" [typ]="'h2'" [tresci]="tresci"></app-tlumacz>
      </span>

      <div class="belka_lewa" (click)="Kroki.dalej(i + 1, Kroki.pozycjaKrok == i)">
        <div class="id_kroku">{{i + 1}}</div>
        <div class="tresc_zarz" *ngIf="Kroki.pozycjaKrok != i">
          <app-tlumacz [id]="'edytuj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
        </div>
        <div class="tresc_zarz" *ngIf="Kroki.pozycjaKrok == i">
          <app-tlumacz [id]="'dalej'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
        </div>
      </div>
      <div class="kontrolki_szerzej2">
        <div class="kontrolki_szerzej">
          <div class="kontrolki" *ngIf="Kroki.pozycjaKrok == i  && !Kroki.wczytywanie">
            <!-- konfigurator poczatek -->
            <div
              class="w-100 kontrolka kontrolka_{{kontr.ControlType}} kontrolka_{{kontr.ControlType}}_{{kontr.KeyName}} Pozycja_{{kontr.ParameterPosition}} Prev{{kontr.prevControlType}}"
              *ngFor="let kontr of krok.Konfigurator.kontrolki; let i = index">

              <div
                *ngIf="kontr.ControlType != 'Hidden' && kontr.ControlType != 'Komunikat' && kontr.ControlType != 'NewLine'">
                <div class="uwagi {{kontr.uwagi.widoczny}}">{{kontr.uwagi.value}}</div>
                <div class="rozmieszczenie_k NieWybrany_{{kontr.czyNieWybrany()}}">
                  <div class="k1" *ngIf="kontr.ControlType !== 'Foto'">

                    <div class="label" *ngIf="kontr.select == null">
                      <app-tlumacz [id]="'label_' + kontr.KeyName" [typ]="'form'" [tresci]="tresci"></app-tlumacz>
                      &nbsp;
                      <app-tlumacz class="info" [id]="'info_' + kontr.KeyName" [typ]="'info'" [tresci]="tresci">
                      </app-tlumacz>
                      <span
                        *ngIf="kontr.ControlType === 'InputNumber' || kontr.ControlType === 'CheckInput' ||  kontr.ControlType === 'CheckNumber'">({{kontr.InputNumber.Min}}-{{kontr.InputNumber.Max}})</span>
                    </div>
                    <div class="label select_z_{{kontr.Values[0].Key}}" *ngIf="kontr.select != null">
                      <app-tlumacz [id]="'label_' + kontr.KeyName" [typ]="'form'" [tresci]="tresci"></app-tlumacz>
                      &nbsp;
                      <app-tlumacz class="info" [id]="'info_' + kontr.KeyName" [typ]="'info'" [tresci]="tresci">
                      </app-tlumacz>
                      <span
                        *ngIf="kontr.ControlType === 'InputNumber' || kontr.ControlType === 'CheckInput' ||  kontr.ControlType === 'CheckNumber'">({{kontr.InputNumber.Min}}-{{kontr.InputNumber.Max}})</span>
                    </div>
                  </div>
                  <div class="k2">
                    <div *ngIf="kontr.ControlType == 'Module'">
                      <div class="modul_wiersz" *ngFor="let kolumna of kontr.module.matrix; let x = index">
                        <div class="modul_komorka komorka_{{komorka}}" *ngFor="let komorka of kolumna; let y = index"
                          (click)="kontr.module.set(y,x)">
                          <span *ngIf="komorka">P{{kontr.module.getName(y,x)}}</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="kontr.ControlType == 'Foto'" [style.height.px]="image_height">
                      <div
                        *ngIf="tresci.wizualizacja != null &&  tresci.id_krok_wizual === tresci.aktualne_id && tresci.wizualizacja != null &&  tresci.id_krok_wizual === tresci.aktualne_id">

                        <img #image [src]="tresci.wizualizacja[0]" class="kontrolka" (load)="onImageLoad($event)">
                      </div>
                    </div>

                      <input *ngIf="kontr.ControlType == 'InputText'" name="{{kontr.KeyName}}" class="InputText"
                        type="text" [(ngModel)]="kontr.InputText" (change)="kontr.InputTextChange()" />
                      <input *ngIf="kontr.ControlType == 'InputNumber' && kontr.InputNumber != null"
                        name="{{kontr.KeyName}}" type="number" [(ngModel)]="kontr.InputNumber.Value" [min]='kontr.InputNumber.Min' [max]='kontr.InputNumber.Max'
                        (keyup)="kontr.InputNumber.zmien_key($event); krok.Konfigurator.aktualizuj_key($event); krok.Konfigurator.changeInput();  czyWybrany(kontr); "
                        (blur)="kontr.InputNumber.zmien($event.target); krok.Konfigurator.aktualizuj();" required/>


                    <span class="Foto_box_combo" *ngIf="kontr.ControlType === 'ComboFoto'">
                      <div class="wybrane_box">
                        <mat-radio-group class="text-center">
                          <mat-radio-button class="pole_foto"
                            *ngFor="let foto_nazwa of kontr.select.pola; let i = index" [value]="foto_nazwa.Value"
                            (change)="kontr.select.zamknij(i);">
                            <img class="d-block w-100"
                              src="{{tresci.server_url + '/api/ActiveForm/GetIcon?code=' + tresci.system + '_' + kontr.KeyName + '_' + foto_nazwa.Key}}">
                            {{foto_nazwa.Value}}
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </span>


                    <div *ngIf="kontr.ControlType === 'File'">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="customFileLang" lang="{{tresci.jezyk}}"
                          accept="image/png, image/jpeg, image/gif, image/bmp, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain"
                          (change)="kontr.fileChange($event.target.files, krok.pozycja, krok.modul)">
                        <label class="custom-file-label"
                          for="customFileLang">{{this.kontr.fileControl.FileName}}</label>
                      </div>
                    </div>


                    <div class="CheckCombo" *ngIf="(kontr.ControlType == 'CheckInput') && kontr.InputNumber != null">
                      <span class="check_input">
                        <input type="checkbox" [(ngModel)]="kontr.InputNumber.check"
                          (change)="kontr.InputNumber.zmien_check()" />
                      </span>
                      <span *ngIf="!kontr.InputNumber.check">
                        <input class="nieaktywny" name="{{kontr.KeyName}}" type="text" value='X' />
                      </span>
                      <span *ngIf="kontr.InputNumber.check">
                        <input name="{{kontr.KeyName}}" type="text" [(ngModel)]="kontr.InputNumber.Value"
                          (keyup)="kontr.InputNumber.zmien_key($event); krok.Konfigurator.aktualizuj_key($event); krok.Konfigurator.changeInput(); czyWybrany(kontr)"
                          (blur)="kontr.InputNumber.zmien($event.target); krok.Konfigurator.aktualizuj();" />
                      </span>
                    </div>
                    <div class="CheckCombo" *ngIf="(kontr.ControlType == 'Check') && kontr.checkKontrolka != null">
                      <span class="check_input">
                        <input type="checkbox" [(ngModel)]="kontr.checkKontrolka.check"
                          (change)="kontr.checkKontrolka.zmien(); kontr.nie_wybrany = false; krok.Konfigurator.aktualizuj();" />
                      </span>
                    </div>

                    <div class="select select_z_{{kontr.Values[0].Key}}"
                      *ngIf="kontr.ControlType === 'SelectImg' && kontr.select != null">
                      <div class="pole_wybrane" (click)="kontr.select.otworz($event)">
                        <div class="nazwa">
                          <div class="pole_nazwa_select">{{kontr.select.getActualDisplay()}}</div>
                        </div>
                      </div>
                      <div class="pole_przewijane pole_przewijane_{{kontr.select.otwarte}}">
                        <div class="pole_lista act_{{value.IsEnable}} wybrane_{{value.wybrane}}"
                          *ngFor="let value of kontr.select.pola; let i = index"
                          (click)="kontr.select.zamknij(i); krok.Konfigurator.aktualizuj();">
                          <div class="obraz"
                            [ngStyle]="{'background-image': 'url(' + tresci.server_url + '/api/ActiveForm/GetIcon?code=' + value.Key + ')'}"
                            *ngIf="kontr.select.fotoBox"></div>
                          <div class="nazwa {{kontr.select.fotoBox}}">{{kontr.select.getDisplay(i)}}</div>
                        </div>
                      </div>
                    </div>
                    <span *ngIf="kontr.ControlType === 'Select'">
                      <ng-select [allowClear]="true" [items]="kontr.select.AktualArray()"
                        [active]="[{id: kontr.select.pole_wybrane.Key, 'text': kontr.select.getActualDisplay()}]"
                        (selected)="setSelected($event); kontr.select.zamknij($event.id); krok.Konfigurator.aktualizuj();"
                        (removed)="kontr.select.nie_wybrany = true;"
                        placeholder="{{tresci.pole_stale_odczyt_bez('Nie wybrano')}}" *ngIf="kontr.select != null">
                      </ng-select>
                    </span>
                    <span class="CheckCombo" *ngIf="kontr.ControlType === 'CheckCombo'">
                      <span class="check_input">
                        <input type="checkbox" [(ngModel)]="kontr.select.check" (change)="kontr.select.zmien_check()" />
                      </span>
                      <ng-select [allowClear]="true" [disabled]="!kontr.select.check"
                        [items]="kontr.select.AktualArray()"
                        [active]="[{id: kontr.select.pole_wybrane.Key, 'text': kontr.select.getActualDisplay()}]"
                        (selected)="setSelected($event); kontr.select.zamknij($event.id); krok.Konfigurator.aktualizuj();"
                        (removed)="kontr.select.nie_wybrany = true;"
                        placeholder="{{tresci.pole_stale_odczyt_bez('Nie wybrano')}}" *ngIf="kontr.select != null">
                      </ng-select>
                    </span>
                    <div class="CheckCombo" *ngIf="(kontr.ControlType == 'CheckNumber') && kontr.InputNumber != null">
                      <span class="check_input">
                        <input type="checkbox" [(ngModel)]="kontr.InputNumber.check"
                          (change)="kontr.InputNumber.zmien_check()" />
                      </span>
                      <span *ngIf="!kontr.InputNumber.check">
                        <input class="nieaktywny" name="{{kontr.KeyName}}" type="text" value='0' />
                      </span>
                      <span *ngIf="kontr.InputNumber.check">
                        <input name="{{kontr.KeyName}}" type="number" [(ngModel)]="kontr.InputNumber.Value"
                          (keyup)="kontr.InputNumber.zmien_key($event); krok.Konfigurator.aktualizuj_key($event); krok.Konfigurator.changeInput(); czyWybrany(kontr)"
                          (blur)="kontr.InputNumber.zmien($event.target); krok.Konfigurator.aktualizuj();" />
                      </span>
                    </div>
                    <div class="CheckCombo" *ngIf="(kontr.ControlType == 'CheckFile') && kontr.fileControl != null">
                      <span class="check_input">
                        <input type="checkbox" [(ngModel)]="kontr.fileControl.Check"
                          (change)="kontr.fileControl.Check; checkIfFileAdded(kontr)" />
                      </span>
                      <span *ngIf="!kontr.fileControl.Check">
                        <input class="nieaktywny" name="{{kontr.KeyName}}" type="text" value='X' />
                      </span>
                        <div *ngIf="kontr.fileControl.Check" class="custom-file">
                          <input type="file" class="custom-file-input" id="customFileLang" lang="{{tresci.jezyk}}"
                            accept="image/png, image/jpeg, image/gif, image/bmp, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain"
                            (change)="kontr.fileChange($event.target.files, krok.pozycja, krok.modul)">
                          <label class="custom-file-label"
                            for="customFileLang">{{this.kontr.fileControl.FileName}}</label>
                        </div>
                    </div>
                    <span
                      *ngIf="kontr.ControlType === 'Sketch' && kontr.sketch != null && kontr.sketch.rysunek != null">
                      <img [src]="kontr.sketch.rysunek" />
                    </span>
                    <div class="select" *ngIf="kontr.selectModal != null">
                      <div class="pole_wybrane" (click)="kontr.selectModal.otworz($event)">
                        <div class="obraz"
                          [ngStyle]="{'background-image': 'url(' + tresci.server_url + '/api/ActiveForm/GetIcon?code=' + kontr.selectModal.pole_wybrane.Key + ')'}"
                          *ngIf="kontr.selectModal.fotoBox"></div>
                        <div class="nazwa">
                          <div class="pole_nazwa_select">{{kontr.selectModal.getActualDisplay()}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="select" *ngIf="kontr.ControlType == 'Label'">
                      <div class="pole_wybrane label_wybrany">{{kontr.getDisplayValue()}}</div>
                    </div>
                    <div class="radio" *ngIf="kontr.radio != null">
                      <div class="pole_radio_box">
                        <div class="pole_radio act_{{value.IsEnable}} wybrane_{{value.wybrane}}"
                          *ngFor="let value of kontr.radio.pola; let i = index"
                          (click)="kontr.radio.zamknij(i); krok.Konfigurator.aktualizuj();">
                          <div class="radio_obraz"></div>
                          <div class="nazwa {{kontr.radio.fotoBox}}">{{value.Value}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="kontrolkaKim" *ngIf="kontr.ControlType == 'Kim' && kontr.Kim != null">
                      <div class="select select_kim" (click)="kontr.Kim.otwarty = true;">
                        <div class="btn btn-default btn-secondary form-control ui-select-toggle">
                          {{kontr.getDisplayValue()}}</div>
                      </div>
                    </div>
                    <ng-container *ngIf="kontr.PictureCode">
                      <img class="d-block w-100"
                        src="{{tresci.server_url + '/api/ActiveForm/GetIcon?code=' + kontr.PictureCode}}">
                    </ng-container>
                    <ng-container *ngIf="kontr.DescriptionCode">
                      <app-tlumacz [id]="kontr.DescriptionCode" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <!-- konfigurator koniec-->
          </div>
        </div>
      </div>

      <div class="kontrolki_szerzej2">
        <div class="kontrolki_szerzej">
          <div class="kontrolki2" *ngIf="Kroki.pozycjaKrok != i || Kroki.wczytywanie">
            <div
              class="kontrolka kontrolka_{{kontr.ControlType}} Pozycja_{{kontr.ParameterPosition}} Prev{{kontr.prevControlType}}"
              *ngFor="let kontr of krok.Konfigurator.kontrolki; let i = index">
              <div
                *ngIf="kontr.ControlType != 'Hidden' && kontr.ControlType != 'Komunikat' && kontr.ControlType != 'Foto' && kontr.ControlType != 'NewLine'">
                <div class="rozmieszczenie_k2">
                  <span>
                    <span class="label">
                      <app-tlumacz [id]="'label_' + kontr.KeyName" [typ]="'form'" [tresci]="tresci"></app-tlumacz>
                      &nbsp;
                      <app-tlumacz class="info" [id]="'info_' + kontr.KeyName" [typ]="'info'" [tresci]="tresci">
                      </app-tlumacz>
                      <span
                        *ngIf="kontr.ControlType === 'InputNumber' || kontr.ControlType == 'CheckInput'">({{kontr.InputNumber.Min}}-{{kontr.InputNumber.Max}})</span>
                    </span>
                  </span>
                  <span class="pole_wartosc"
                    *ngIf="kontr.ControlType !== 'Sketch' && kontr.ControlType !== 'Check'">{{kontr.getDisplayValue()}}</span>
                  <span *ngIf="kontr.ControlType === 'Sketch' && kontr.sketch != null && kontr.sketch.rysunek != null">
                    <img [src]="kontr.sketch.rysunek" />
                  </span>

                  <div class="CheckCombo" *ngIf="(kontr.ControlType == 'Check') && kontr.checkKontrolka != null">
                    <span class="check_input">
                      <input type="checkbox" [(ngModel)]="kontr.checkKontrolka.check" />
                    </span>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="Kroki.pozycjaKrok == i  && !Kroki.wczytywanie">
        <div *ngFor="let kontr of krok.Konfigurator.kontrolki; let i = index">
          <div class="pole_wybor_tkaniny  pole_przewijane_{{kontr.selectModal.otwarte}}"
            *ngIf="kontr.selectModal != null">
            <div class="pole_wyszukiwania" (click)="kontr.szukaj($event);">
              <div class="opis_wyszukaj">
                <app-tlumacz [id]="'wyszukiwarka_txt'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
              </div>
              <div class="pole_wyszukaj"><input name="wyszukaj" [(ngModel)]="kontr.wyszukiwane" /></div>
            </div>
            <div class="pole_lista_ikony_box" *ngFor="let value of kontr.selectModal.pola; let i = index">
              <div class="pole_lista_ikony_box" *ngIf="kontr.porownaj(kontr.wyszukiwane, value.Value, value.Key)">
                <div class="pole_lista_ikony act_{{value.IsEnable}} wybrane_{{value.wybrane}}"
                  (click)="kontr.selectModal.zamknij(i); krok.Konfigurator.aktualizuj();">
                  <div class="obraz"
                    [ngStyle]="{'background-image': 'url(' + tresci.server_url + '/api/ActiveForm/GetIcon?code=' + value.Key + ')'}"
                    *ngIf="kontr.selectModal.fotoBox"></div>
                  <div class="nazwa {{kontr.selectModal.fotoBox}}">{{value.Value}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="kontrolka_rozszerzony Kim_{{kontr.Kim.otwarty}}"
            *ngIf="kontr.ControlType == 'Kim' && kontr.Kim != null" (click)="kontr.Kim.otwarty = false">
            <div class="container" (click)="$event.stopPropagation();">
              <app-tlumacz [id]="'label_' + kontr.KeyName" [typ]="'h2'" [tresci]="tresci"></app-tlumacz>
              <!-- <div class="exit" (click)="kontr.Kim.otwarty = false; tresci.zamowienie.grupa_modul.zapisac = false">X</div> -->
              <select class='select_filter' [(ngModel)]="searchGroupTerm" (change)="applyFilter(kontr.Kim)">
                <ng-container *ngFor="let filter of kontr.Kim.KimFilterList">
                  <option value="{{filter.Id}}">{{tresci.tresc(filter.Name, 'stale', '')}}</option>
                </ng-container>
              </select>

              <select class='select_filter' [(ngModel)]="searchColumnTerm" (change)="applyFilter(kontr.Kim)">
                <option value="Kod katalogowy">{{tresci.tresc('Kod katalogowy', 'stale', '')}}</option>
                <option value="Nazwa">{{tresci.tresc('Nazwa', 'stale', '')}}</option>
              </select>
              <input class='input_filter' type="text" [(ngModel)]="conditionTerm" (keyup)="applyFilter(kontr.Kim)" />
              &nbsp;<app-tlumacz [id]="'Wybrane:'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>&nbsp;<input
                class='check_input' type="checkbox" [(ngModel)]="chosenTerm"
                (change)="conditionTerm = ''; applyFilter(kontr.Kim);">

              <div class="pole_tlumaczen" *ngIf="tresci.user.IsPermission">
                <div>Pole widoczne dla tłumacza</div>
                <div class="box_tlumaczenia">
                  <ng-container *ngFor="let filter of kontr.Kim.KimFilterList">
                    <app-tlumacz [id]="filter.Name" [typ]="'stale'" [tresci]="tresci"></app-tlumacz><br />
                  </ng-container>
                </div>
              </div>

              <div class="table-scroll">
                <table>
                  <tr class="pozycjaKim naglowek">
                    <td class='sortowanie' (click)="kontr.Kim.sortuj_po('Key')">
                      <app-tlumacz [id]="'Kod_katalogowy'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                      <div class="sortuj sort_id_{{kontr.Kim.sort_id === 'Key'}} sort_asc_{{kontr.Kim.asc}}"></div>
                    </td>
                    <td class='sortowanie' (click)="kontr.Kim.sortuj_po('Name')">
                      <app-tlumacz [id]="'Nazwa'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                      <div class="sortuj sort_id_{{kontr.Kim.sort_id === 'Name'}} sort_asc_{{kontr.Kim.asc}}"></div>
                    </td>
                    <td class='sortowanie' (click)="kontr.Kim.sortuj_po('BasicPrice')">
                      <app-tlumacz [id]="'Cena'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                      <div class="sortuj sort_id_{{kontr.Kim.sort_id === 'BasicPrice'}} sort_asc_{{kontr.Kim.asc}}">
                      </div>
                    </td>
                    <td class='sortowanie' (click)="kontr.Kim.sortuj_po('Description')">
                      <app-tlumacz [id]="'Opis'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                      <div class="sortuj sort_id_{{kontr.Kim.sort_id === 'Description'}} sort_asc_{{kontr.Kim.asc}}">
                      </div>
                    </td>
                    <td class='sortowanie' (click)="kontr.Kim.sortuj_po('Power')">
                      <app-tlumacz [id]="'Zasilanie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                      <div class="sortuj sort_id_{{kontr.Kim.sort_id === 'Power'}} sort_asc_{{kontr.Kim.asc}}">
                      </div>
                    </td>
                    <td class='sortowanie' (click)="kontr.Kim.sortuj_po('OutputSignals')">
                      <app-tlumacz [id]="'Sygnaly_wyjsciowe'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                      <div class="sortuj sort_id_{{kontr.Kim.sort_id === 'OutputSignals'}} sort_asc_{{kontr.Kim.asc}}">
                      </div>
                    </td>
                    <td>
                      <app-tlumacz [id]="'Obrazek'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                    </td>
                    <td>
                      <app-tlumacz [id]="'Ilosc'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                    </td>
                  </tr>
                  <tr class="pozycjaKim" *ngFor="let kim of kontr.Kim.DisplayArray(); let k = index">
                    <ng-container>
                      <td>{{kim.Key}}</td>
                      <td>{{kim.Name}}</td>
                      <td>{{kim.BasicPrice}}</td>
                      <td>{{kim.Description}}</td>
                      <td>{{kim.Power}}</td>
                      <td>{{kim.OutputSignals}}</td>
                      <td>
                        <span class="zobacz ikona" (mouseenter)="getImage(kim.Index)" [ngbPopover]="hoverImgTemp"
                          triggers="mouseenter:mouseleave">
                          <ng-template #hoverImgTemp><img width=100px [src]="user.Picture"></ng-template>
                        </span>
                      </td>
                      <td class="ilosc_kim">
                        <span class="ilosc_button" (click)="kontr.Kim.minus($event, k)">-</span>
                        <input onkeyup="this.value=this.value.replace(/[^\d]/,'')" [(ngModel)]="kim.Quantity"
                          (change)="kontr.Kim.czy_poprawny(k)" />
                        <span class="ilosc_button" (click)="kontr.Kim.dodaj($event, k)">+</span></td>
                    </ng-container>
                  </tr>
                </table>
              </div>
              <div class="button zapisz_miejsce" (click)="kontr.Kim.Zamknij()">
                <app-tlumacz [id]="'Akceptuj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- kroki-->
  <div class="ramka_po">
    <div>
      <div
        *ngIf="Kroki.pozycjaKrok == i && tresci.wizualizacja != null &&  tresci.id_krok_wizual === tresci.aktualne_id"
        class="informacje_txt" (click)="tresci.pokaz_wiz = true">
        <div class="info_tresc">
          <app-tlumacz [id]="tresci.system + '_informacje_' + krok.Nazwa" [typ]="'stale'" [tresci]="tresci">
          </app-tlumacz>
        </div>
      </div>
    </div>
    <div>
      <div
        *ngIf="Kroki.pozycjaKrok == i && tresci.wizualizacja != null &&  tresci.id_krok_wizual === tresci.aktualne_id"
        class="informacje" (click)="tresci.pokaz_wiz = true">
      </div>
      <div class="foto" (click)="tresci.pokaz_wiz = false"
        *ngIf="Kroki.pozycjaKrok == i && tresci.wizualizacja != null &&  tresci.id_krok_wizual === tresci.aktualne_id && tresci.pokaz_wiz">
        <div class="container">
          <div *ngIf="tresci.wizualizacja != null &&  tresci.id_krok_wizual === tresci.aktualne_id">

            <img [src]="tresci.wizualizacja[0]">
          </div>
          <div class="zamknij">X</div>
        </div>
      </div>
      <div class="button_edit_dalej">
        <div class="dalej" *ngIf="Kroki.pozycjaKrok == i && i <= Kroki.pozycjaKrok && !Kroki.wczytywanie"
          (click)="Kroki.dalej(i + 1, Kroki.pozycjaKrok == i)" >
          <app-tlumacz [id]="'dalej'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
        </div>
        <div class="technology"
          *ngIf="Kroki.pozycjaKrok == i && i <= Kroki.pozycjaKrok && !Kroki.wczytywanie && tresci.DevMode"
          (click)=" showTechnology(i+1, Kroki.pozycjaKrok == i)">
          <app-tlumacz [id]="'Technologia'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
        </div>
        <div class="edytuj" *ngIf="Kroki.pozycjaKrok != i && i <= Kroki.pozycjaKrok && !Kroki.wczytywanie"
          (click)="editQuestionDialog(i + 1, Kroki.pozycjaKrok == i, krok)">
          <app-tlumacz [id]="'edytuj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
        </div>
        <div class="edytuj" *ngIf="Kroki.wczytywanie">
          <app-tlumacz [id]="'przetwarzanie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isPreviewTech == true">
    <app-technologiaresultinfo #technology [Kroki]="Kroki"></app-technologiaresultinfo>
  </ng-container>
  <div class="both"></div>
</div>
<div class="loading loading_{{Kroki.pozycjaKrok}}" *ngIf="Kroki.wczytywanie"></div>
