<div *ngIf="Control" class="label-control">
  <label>
    <span id="{{Control.label}}" [translation]="TranslationEnum.TranslationForm">{{(Control.label) | translate}}</span>
    <app-information [label]="Control.label"  [notAffectOnOtherControl]='Control.notAffectToOtherControls' [fileInfoId]="Control.fileInfoId"></app-information>
  </label>

 <mat-form-field appearance="outline" class="w-100" color="accent" id="{{'atrr' + Control.label}}">
   <div >
    <textarea *ngIf="!IsNumber" [ngStyle]="{'color': Control.foregrandColour}" id="{{Control.label + '_value'}}"  [translation]="TranslationEnum.TranslationForm" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" type="text"  disabled>{{Control.label + '_value' |translate}}</textarea>
    <textarea *ngIf="IsNumber" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" type="text" [value]="Control.value" disabled></textarea>
    </div>
  </mat-form-field>
</div>
