import { NotAffectToOtherControls } from './../../../shared/enums/not-affect-to-controls-enum';
import { ActiveFormType } from 'src/app/shared/models/form-model/models/active-form.model';
import { Control } from './../../../shared/models/form/control.model';
  import { MatDialog } from '@angular/material/dialog';
import { distinctUntilChanged, first, takeWhile } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DataSharingService } from './../../../shared/service/data-sharing.service';
import { KindOper } from './../../../shared/enums/kind-oper-enum';
import { TranslationEnum } from './../../../shared/enums/translation-enum';
import { FormService } from 'src/app/shared/service/form.service';
import { AuthorizationService } from '../../../module-login/authorization.service';
import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild, ViewContainerRef, ViewRef } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators, FormControl, Form, ValidationErrors } from '@angular/forms';
import { Input } from '@angular/core';
import { HtmlControl, Validator } from 'src/app/shared/models/form-model/models/html-control.model';
import * as _ from 'lodash';
import { StepOptions } from 'src/app/shared/models/form-model/models/edit-step-options.model';
import { TreeTechnologyComponent } from 'src/app/module-tree-technology/tree-technology/tree-technology.component';
import { trigger, state, style, transition, animate, keyframes, animation, animateChild, query } from '@angular/animations';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  animations: [
    trigger('error', [
      // transition('* => *', animate('0.8s linear', keyframes([style({ backgroundColor: 'red' }), style({ backgroundColor: 'blue' }), style({ backgroundColor: '#f44336' })]))),
      // transition('* => *', [query('blinker', animateChild(), { optional: true })]),
    ]),
  ],
})
export class DynamicFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ref') vcRef: ViewRef;
  @Output() MoveToNextStep = new EventEmitter<{ FormGroup: FormGroup; ModuleType: string; StepOptions: StepOptions }>();
  @Output() EditCurrentStep = new EventEmitter<{ stepForEdit: string; moduleType: string; module: number }>();

  @Input() HtmlControls: HtmlControl[];
  @Input() Form: FormGroup;
  @Input() Preview: boolean;

  private subscriptions: Subscription[] = [];
  public TranslationEnum = TranslationEnum;
  public CurrentStep: string;
  public Position: number;
  public Module: number;
  public Type: number;
  public ModuleType: string;
  public TechVersion: number;
  public IsLoadByEdit = false;
  public LoadSetWithEdit: boolean;
  public MarkAsToEdit: boolean;
  public ErrorAppeard = false;
  public EnableMoveToNextStep = false;
  public KindOper: number;
  public VerifyPositionAndSet = false;

  protected IsHiddenStep = false;
  protected ShowTechnologyPermission: boolean;
  protected IsEdit: boolean;
  protected IsAnotherStepInEdit: boolean;
  protected BlockNextStep: boolean;
  protected AnimateError: boolean;

  private tempFormIsEdit: FormGroup;
  private tempControlsForEdit: HtmlControl[];
  private stepOptions: StepOptions;
  private blockOtherNextButtonSubscription: Subscription;
  private blockIfMessageDialogAppear: Subscription;
  private isStepEditingSubscription: Subscription;
  MultiStepsForEdit: any;
  IsEditPosition = false;
  TestForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private formService: FormService,
    public authorizationService: AuthorizationService,
    private cdr: ChangeDetectorRef,
    private dataSharingService: DataSharingService,
    private dialog: MatDialog
  ) {
    this.IsEdit = false;
  }

  ngOnInit(): void {
    if (this.KindOper === KindOper.Edit) {
      if (this.HtmlControls.filter((x) => x.componentName === 'Hidden' || x.componentName === 'NewLine').length >= this.HtmlControls.length) {
        this.IsHiddenStep = true;
      }
      this.IsEdit = true;
    }
    if (this.LoadSetWithEdit === true) {
      for (const key of this.HtmlControls) {
        if (this.Form.controls[key.name].disabled === true) {
          this.IsEdit = true;
        }
        // else {
        //   this.IsEdit = false;
        // }
      }

      if (this.HtmlControls.filter((x) => x.componentName === 'Hidden' || x.componentName === 'NewLine').length >= this.HtmlControls.length) {
        this.IsHiddenStep = true;
      }
      this.createControls();
    } else {

      this.createStep();
    }

    this.subscriptions.push(this.authorizationService.ShowTechnologyPermission.subscribe((x) => {
      this.ShowTechnologyPermission = x;
      this.cdr.detectChanges();
    }));

    this.isStepEditingSubscription = this.dataSharingService.IsStepEditing.asObservable()
      .pipe( distinctUntilChanged())
      .subscribe((x) => {
        this.IsAnotherStepInEdit = x;
        this.cdr.detectChanges();
      });

      this.blockOtherNextButtonSubscription = this.dataSharingService.BlockOtherNextButton.asObservable()
      .pipe( distinctUntilChanged())
      .subscribe((x) => {
        if (x !== null && x !== undefined && x.CurrentStep !== this.CurrentStep) {
          this.BlockNextStep = x.BlockNextStep;
          this.cdr.detectChanges();
        }
      });

      this.subscriptions.push(this.blockIfMessageDialogAppear = this.dataSharingService.BlockIfMessageDialogAppear.asObservable()
      .pipe(distinctUntilChanged())
      .subscribe((x) => {
        if (x !== null && x !== undefined && x.CurrentStep === this.CurrentStep) {
          this.BlockNextStep = x.BlockNextStep;
          this.cdr.detectChanges();
        }
      }));
  }

  ngAfterViewInit(): void {
    if (!this.LoadSetWithEdit) {
      if (document.getElementById('scroll')) {
        document.getElementById('scroll')?.scrollTo(0, document.getElementById('scroll')?.scrollHeight || 0);
      } else {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    this.blockOtherNextButtonSubscription?.unsubscribe();
    this.blockIfMessageDialogAppear?.unsubscribe();
    this.isStepEditingSubscription?.unsubscribe();
    this.tempFormIsEdit = null;
    this.tempControlsForEdit = null;
    this.stepOptions = null;
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  public createStep(): void {
    this.createControls();

    if (
      this.HtmlControls.filter(
        (x) => x.componentName === 'Hidden' || x.componentName === 'NewLine' || (x.componentName === 'Komunikat' && x.parameters.find((x) => x.Key === 'Kind').Value === 'Blocked')
      ).length >= this.HtmlControls.length &&
      this.IsLoadByEdit === false &&
      !this.VerifyPositionAndSet
    ) {
      this.IsEdit = true;
      this.IsHiddenStep = true;
      if (this.HtmlControls.find((x) => x.componentName === 'Komunikat') === undefined && (this.ErrorAppeard === false || this.ErrorAppeard === undefined)) {
        this.MoveToNextStep.emit({ FormGroup: this.Form, ModuleType: this.ModuleType, StepOptions: null });
      }
    }

    if (this.IsLoadByEdit === true || this.VerifyPositionAndSet) {
      const tempListForCondition = this.HtmlControls.filter(
        (x) =>
          x.inputType !== 'newline' &&
          x.inputType !== 'kim' &&
          x.inputType !== 'text' &&
          x.inputType !== 'hidden' &&
          !x.inputType.startsWith('check') &&
          x.inputType !== 'render' &&
          x.inputType !== 'pdf'
      );

      if (tempListForCondition.length === 0 || tempListForCondition.every((x) => x.value !== undefined && x.value !== '')) {
        if (this.HtmlControls.filter((x) => x.componentName === 'Hidden' || x.componentName === 'NewLine').length >= this.HtmlControls.length) {
          this.IsHiddenStep = true;
        }

        this.IsEdit = true;
        Object.keys(this.Form.controls).forEach((key) => {
          if (this.HtmlControls.find((x) => x.name === key && x.inputType !== 'hidden') !== undefined) {
            this.Form.controls[key].disable();
          }
        });

        if (this.MultiStepsForEdit) {
          const isLastStepInSpecificType = this.HtmlControls.find((x) => x.isLastStepInSpecificType === true);
          if (isLastStepInSpecificType !== undefined || ActiveFormType[ActiveFormType.ModuleHeader] === this.ModuleType || ActiveFormType[ActiveFormType.Footer] === this.ModuleType) {
            this.MoveToNextStep.emit({ FormGroup: this.Form, ModuleType: this.ModuleType, StepOptions: null });
          }
        } else {
          this.MoveToNextStep.emit({ FormGroup: this.Form, ModuleType: this.ModuleType, StepOptions: null });
        }
      } else {
        this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci'); 
      }
    }
  }

  createControls() {
    for (const controlConfig of this.HtmlControls) {
      controlConfig.module = this.Module;
      controlConfig.type = this.Type;
      controlConfig.position = this.Position;

      if (controlConfig.validations) {
       this.Form.addControl(controlConfig.name, this.fb.control(controlConfig, this.bindValidations(controlConfig.validations)));
      } else {
        this.Form.addControl(controlConfig.name, this.fb.control(controlConfig));
      }

      if (controlConfig.inputType.startsWith('check')) {
        // console.log(controlConfig);

        if (controlConfig.value === undefined || controlConfig.value === '' || controlConfig.value === null) {
          if (this.formService.RemoveControlsFromGroup.find((x) => x.name === controlConfig.name) === undefined) {
            this.formService.RemoveControlsFromGroup.push(controlConfig);
          } else {
            if (this.formService.RemoveControlsFromGroup.find((x) => x.name === controlConfig.name && x.module === controlConfig.module && x.position === controlConfig.position) === undefined) {
              this.formService.RemoveControlsFromGroup.push(controlConfig);
            }
          }
        } else if (this.formService.RemoveControlsFromGroup.find((x) => x.name === controlConfig.name && x.module === controlConfig.module && x.position === controlConfig.position) !== undefined) {
          this.formService.RemoveControlsFromGroup = this.formService.RemoveControlsFromGroup.filter((x) => x.name !== controlConfig.name);
        }
      }
      this.CurrentStep = controlConfig.currentStep;
    }
  }

  bindValidations(validations: Validator[]): ValidatorFn | null {
    if (validations.length > 0) {
      const validList: ValidatorFn[] = [];
      validations.forEach((valid) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  public onSubmit(form: FormGroup): void {
    if (this.MarkAsToEdit) {
      for (const editControl of this.tempControlsForEdit) {
        const control = this.Form.controls[editControl.name];
        const controlEdit = this.tempFormIsEdit.controls[editControl.name];
        const controlToRemove = this.formService.RemoveControlsFromGroup.find((x) => x.name === editControl.name) !== undefined;
        // Opcja została zakomentowana zgodnie z zadaniem SB2B-649
        // if (!controlToRemove) {
        if (control !== undefined && control.value !== null) {
          if (control.value.options !== undefined && control.value.options.length !== 0) {
            if (controlEdit.value === undefined || controlEdit.value === null || controlEdit.value.value === undefined || control.value.value.Key !== controlEdit.value.value.Key) {
              if (control.value.notAffectToOtherControls === NotAffectToOtherControls.None || control.value.notAffectToOtherControls === NotAffectToOtherControls.NotAffectToPosition) {
                Object.keys(form.controls).forEach((key) => {
                  if (key !== editControl.name) {
                    form.controls[key].clearValidators();
                    form.controls[key].updateValueAndValidity();
                  }
                });
                if (control.value.notAffectToOtherControls === NotAffectToOtherControls.None) {
                  this.stepOptions.DeleteRestComponents = true;
                } else {
                  this.stepOptions.DeleteHeaderComponents = true;
                }
              } else {
                this.stepOptions.DeleteRestComponents = false;
              }
            }
          } else {
            if (controlEdit.value === null || control.value.value !== controlEdit.value.value) {
              if (control.value.notAffectToOtherControls === NotAffectToOtherControls.None || control.value.notAffectToOtherControls === NotAffectToOtherControls.NotAffectToPosition) {
                Object.keys(form.controls).forEach((key) => {
                  if (key !== editControl.name) {
                    form.controls[key].clearValidators();
                    form.controls[key].updateValueAndValidity();
                  }
                });
                if (control.value.notAffectToOtherControls === NotAffectToOtherControls.None) {
                  this.stepOptions.DeleteRestComponents = true;
                } else {
                  this.stepOptions.DeleteHeaderComponents = true;
                }
              } else {
                this.stepOptions.DeleteRestComponents = false;
                if (control.value.name === 'IleJednakowych') {
                  this.stepOptions.IsHowManySameSets = true;
                }
              }
            }
          }
        }
        // } else {
        //   this.EnableMoveToNextStep = true;
        // }
      }
    } else {
      this.stepOptions = null;
    }

    if (this.EnableMoveToNextStep) {
      // this.stepOptions.DeleteRestComponents = true;
      this.EnableMoveToNextStep = false;
    }

    if (this.stepOptions !== null && this.stepOptions.IsMarkAsToEdit === true) {
      let valid = true;
      let isMessage = false;
      Object.keys(form.controls).forEach((key) => {
        if (this.HtmlControls.find((x) => x.name === key)) {
          if (this.Form.controls[key].invalid === true) {
            valid = false;
          }
        }
      });

      if (this.stepOptions.DeleteRestComponents === false) {
        for (const key of Object.keys(form.controls)) {
          if (form.controls[key].value !== null && form.controls[key].value !== undefined && form.controls[key].value !== '' && form.controls[key].value.inputType.startsWith('komunikat')) {
            // this.dataSharingService.BlockIfMessageDialogAppear.next({ BlockNextStep: true, CurrentStep: this.CurrentStep });
            isMessage = true;
          }
        }
      }

      if (valid) {
        Object.keys(form.controls).forEach((key) => {
          if (this.HtmlControls.find((x) => x.name === key && x.inputType !== 'hidden')) {
            this.Form.controls[key].disable();
          }
        });
        this.MoveToNextStep.emit({ FormGroup: form, ModuleType: this.ModuleType, StepOptions: this.stepOptions });
        this.IsEdit = true;
        this.MarkAsToEdit = false;
        this.dataSharingService.IsStepEditing.next(false);
        if (!isMessage) {
          this.dataSharingService.BlockOtherNextButton.next({ BlockNextStep: false, CurrentStep: this.CurrentStep });
        }
      }
    } else {
      if (form.valid) {
        Object.keys(form.controls).forEach((key) => {
          if (this.HtmlControls.find((x) => x.name === key && x.inputType !== 'hidden')) {
            this.Form.controls[key].disable();
          }
        });

        this.MoveToNextStep.emit({ FormGroup: form, ModuleType: this.ModuleType, StepOptions: this.stepOptions });
        this.IsEdit = true;
        this.dataSharingService.IsStepEditing.next(false);
        this.dataSharingService.BlockOtherNextButton.next({ BlockNextStep: false, CurrentStep: this.CurrentStep });

        this.AnimateError = false;
      } else {
        this.AnimateError = true;
        const errorList = document.getElementsByClassName('mat-form-field-invalid');
        for (let i = 0; i < errorList.length; i++) {
          const elem = (<HTMLFormElement>errorList[i]).classList;

          elem.add('activeAnimation');
          setTimeout(() => {
            elem.remove('activeAnimation');
          }, 3500);
        }
        this.validateAllFormFields(form);
      }
    }
  }

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  editCurrentStep(stepForEdit: string, isPositionCanceled: boolean): void {
    this.formService.RemoveControlsFromGroup.forEach((control) => {
      if (this.HtmlControls.find((x) => x.name === control.name) !== undefined) {
        const newControl = this.fb.control(control);
        this.Form.addControl(control.name, newControl);
      }
    });

    Object.keys(this.Form.controls).forEach((key) => {
      if (this.HtmlControls.find((x) => x.name === key)) {
        this.Form.controls[key].enable();
        // this.dataSharingService.SetEnabledForCombineControl.next(true);
      }
    });

    this.stepOptions = new StepOptions();
    this.tempFormIsEdit = _.cloneDeep(this.Form);
    this.tempControlsForEdit = this.HtmlControls;
    this.MarkAsToEdit = true;
    this.stepOptions.IsMarkAsToEdit = true;
    this.stepOptions.StepForEdit = stepForEdit;
    this.stepOptions.ModuleType = this.ModuleType;
    this.stepOptions.Module = this.Module;
    this.stepOptions.DeleteRestComponents = false;
    if (this.ErrorAppeard) {
      this.stepOptions.ErrorAppeard = false;
    }

    this.IsEdit = false;
    if (isPositionCanceled) {
      this.dataSharingService.IsStepEditing.next(false);
      this.dataSharingService.BlockOtherNextButton.next({ BlockNextStep: false, CurrentStep: '' });
    } else {
      this.dataSharingService.IsStepEditing.next(true);
      this.dataSharingService.BlockOtherNextButton.next({ BlockNextStep: true, CurrentStep: this.CurrentStep });
    }
  }

  showTechnology(formGroup: FormGroup, stepForResultInfo: string): void {
    let stepValid = true;
    for (const control of this.HtmlControls) {
      if (this.Form.controls[control.name] !== undefined) {
        if (stepValid) {
          stepValid = this.Form.controls[control.name].valid;
        }
      }
    }

    if (stepValid || this.IsEdit) {
      this.dialog.open(TreeTechnologyComponent, {
        width: '1650px',
        maxWidth: '90%',
        disableClose: true,
        data: {
          FormGroupForResultInfo: formGroup,
          StepForResultInfo: stepForResultInfo,
          IsValuation: false,
        },
      });
    }
  }
}
