import { SurchargeControl } from '../../form/surcharge-control.model';
import { Kim } from './../kim.model';
import { Parameter } from './form-controlls.model';
export class ActiveForm {
  Id: number;
  OrderId: number;
  CurrentStep: string;
  DeliveryMethodId: string;
  DeliveryAddress?: string;
  DeliveryCity?: string;
  DeliveryZipCode?: string;
  DeliveryCountry?: string;
  DeliveryAddressId?: number;
  PreviousPositionParameters?: Parameter[] = new Array<Parameter>();
  Parameters: Parameter[] = new Array<Parameter>();
  ParameterExtensions: Kim[] = new Array<Kim>();
  ContractorNumber: string;
  ProductPrefix: string;
  CategoryProduct: string;
  Culture: string;
  Iso639: string;
  IsCache?: boolean;
  Type?: string;
  Surcharges?: SurchargeControl[];
  TechnologyVersion?: number;
  IsTrader: boolean;
  IsComplaint: boolean;
  ComplaintId: Number;
  ComplaintNumber: string;
  CopiedOrderId:Number;
}

export enum ActiveFormType {
  Header = 0,
  ModuleHeader = 1,
  Position = 2,
  ModuleFooter = 3,
  Footer = 4
}
