import { MatTableDataSource } from '@angular/material/table';
import { Contractor } from '../../shared/models/user/contractor.model';
import { Component, ViewChild, AfterViewInit, Output, EventEmitter, OnDestroy, } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ContractorChooseDialogService } from './contractor-choose-dialog.service';
import { catchError, map, startWith, switchMap, takeWhile } from 'rxjs/operators';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { TresciService } from '../../tresci.service';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { MatSort } from '@angular/material/sort';



@Component({
  selector: 'app-contractor-choose-dialog',
  templateUrl: './contractor-choose-dialog.component.html',
  styleUrls: ['./contractor-choose-dialog.component.scss']

})
export class ContractorChooseDialogComponent implements AfterViewInit, OnDestroy {

  public TranslationEnum = TranslationEnum;

  displayedColumns: string[] = ['ContractorNumber', 'ContractorName'];

  dataSource: MatTableDataSource<Contractor>;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  private subscriptions: Subscription[] = [];


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private contractorChooseDialogService: ContractorChooseDialogService, public tresci: TresciService) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngAfterViewInit() {
    // merge(this.paginator.page, this.filterChanged)
    //   .pipe(
    //     startWith({}),
    //     switchMap(() => {
    //       this.isLoadingResults = true;
    //       return this.contractorChooseDialogService.getContractorsForTrader(this.filterTerm, this.paginator.pageIndex, this.paginator.pageSize);
    //     }),
    //     map(data => {
    //       this.isLoadingResults = false;
    //       this.isRateLimitReached = false;
    //       this.resultsLength = data.TotalCount;
    //       return data.Contractors;
    //     }),
    //     catchError(() => {
    //       this.isLoadingResults = false;
    //       this.isRateLimitReached = true;
    //       return observableOf([]);
    //     })
    //   ).subscribe(data => this.data = data);

    this.subscriptions.push(this.contractorChooseDialogService.getContractorsForTrader().subscribe(data => {
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => {
      this.isLoadingResults = false;
      this.isRateLimitReached = true;
    }));

    this.labelsMatPaginator();
  }


  applyFilter(input: HTMLInputElement) {
    const filterValue = input.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  choose(contractor: Contractor) {
    // console.log("con2", contractor);
    sessionStorage.setItem('_slc', JSON.stringify(contractor));
  }

  labelsMatPaginator(): void {
    this.paginator._intl.itemsPerPageLabel = '';
    this.paginator._intl.firstPageLabel = '';
    this.paginator._intl.previousPageLabel = '';
    this.paginator._intl.nextPageLabel = '';
    this.paginator._intl.lastPageLabel = '';
    this.paginator._intl.getRangeLabel = rangeLabel;
  }
}


const rangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) { return `0 / ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} / ${length}`;
};
