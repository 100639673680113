
<div *ngIf="wczytywanie"
  class="mt-5 d-flex justify-content-center align-items-center bg-white">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>

<div class="container"  *ngIf="!wczytywanie">

    <button class="float-right " style="position: sticky; top: 118px; z-index: 999; padding-top: 10px;" mat-icon-button color="primary" (click)="returnToOrders()">
      <mat-icon>disabled_by_default</mat-icon>
    </button>


    <div class="grupa_tab"  *ngIf="!wczytywanie">
        <div class="tab tab{{zakladka == 0}}" (click) = "zakladka = 0">
            <span id="naglowek" [translation]="TranslationEnum.TranslationForm">
                {{'naglowek' | translate}}
            </span>
        </div>
        <div class="tab tab{{zakladka == 1}}"  (click) = "zakladka = 1">
            <span id="dane_modułów" [translation]="TranslationEnum.TranslationForm">
                {{'dane_modułów' | translate}}
            </span>
        </div>
        <div class="tab tab{{zakladka == 2}}" (click) = "zakladka = 2">
            <span id="dane_stopki" [translation]="TranslationEnum.TranslationForm">
                {{'dane_stopki' | translate}}
            </span>
        </div>
    </div>

    
    <div *ngIf="!wczytywanie && zakladka == 0" class="header_box" >
        
        <h3>
            <span id="dostawa" [translation]="TranslationEnum.TranslationForm">{{ 'dostawa' | translate }}</span>
        </h3>
        <div class="sposob_dostawy" *ngIf = "Parametry.DeliveryMethodId === 'ODBIOR WLASNY'">
            <div class="sposob_dostawy_img">
                <img #ownCollection class="d-block m-auto" height="50px" width="35px" src="../../../assets/images/own_collection_true.svg" />
            </div>
            <div id = "odbior_wlasny" class="sposob_dostawy_tresc" [translation]="TranslationEnum.TranslationForm">
                {{ 'odbior_wlasny' | translate }}
            </div>
        </div>
        <div class="sposob_dostawy" *ngIf = "Parametry.DeliveryMethodId === 'SELT'">
            <div class="sposob_dostawy_img">
                <img #ownCollection class="d-block m-auto" height="50px" width="35px" src="../../../assets/images/delivery_selt_true.svg" />
            </div>
            <div id = "selt" class="sposob_dostawy_tresc" [translation]="TranslationEnum.TranslationForm">
                {{ 'selt' | translate }}
            </div>
        </div>
        <div class="sposob_dostawy" *ngIf = "Parametry.DeliveryMethodId === 'SPEDYTOR'">
            <div class="sposob_dostawy_img">
                <img #ownCollection class="d-block m-auto" height="50px" width="35px" src="../../../assets/images/forwarder_true.svg" />
            </div>
            <div id = "spedytor" class="sposob_dostawy_tresc" [translation]="TranslationEnum.TranslationForm">
                {{ 'spedytor' | translate }}
            </div>
        </div>
        <h3 class="pl-2 mt-3 mb-2">
            <span id="adres_dostawy" [translation]="TranslationEnum.TranslationForm">
              {{ 'adres_dostawy' | translate }}
            </span>
          </h3>
          <div class="pole_tresc" *ngIf = "Parametry.DeliveryAddressId != null">
            <ng-container *ngFor="let adres of ContractorAddresses; let m = index;">
                <ng-container *ngIf = "adres.Id === Parametry.DeliveryAddressId">
                    {{adres.Address}} {{adres.ZipCode}} {{adres.City}} {{adres.Zone}} 
                </ng-container>
            </ng-container>
          </div>
       
        <h3>
            <span id="naglowek" [translation]="TranslationEnum.TranslationForm">{{ 'naglowek' | translate }}</span>
        </h3>
        <ng-container *ngFor="let param of Parametry.Parameters; let m = index;">
            <ng-container *ngIf="param.Type == 0 && param.ControlName != 'Hidden' && param.ControlName != 'Img' && param.ControlName != 'Label' && param.DisplayValue != null && param.DisplayValue != ''  ">
                <div class="kontrolka">
                    <div class="Label">
                        {{param.ControlName}} <span *ngIf = "param.ControlName == 'Label'" id="{{param.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('' + param.Key.toLowerCase())|translate}}</span>
                        <span *ngIf = "param.ControlName != 'Label'"  id="label_{{param.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + param.Key.toLowerCase())|translate}}</span>
                        <app-information [label]="param.Key" [notAffectOnOtherControl]='false' [fileInfoId]="getFileInfo(param.Key)"></app-information>
                    </div>
                
                    <div class="pole_tresc">
                       {{GetLangValue(param)}}
                    </div>
                </div>
                
            </ng-container>
        </ng-container>
    </div>
    <div *ngIf="!wczytywanie && zakladka == 1" class="module_box" >
        <div class="menu_modul" *ngIf = "modyly.length > 0">
            <ng-container *ngFor="let mod of modyly; let m = index;">
              <div class="button_zestaw poprawny_true zestaw_{{modul == m}}" (click) = "ZmienModul(m)">
                <span id='zestaw' [translation]="TranslationEnum.TranslationForm">{{'zestaw' | translate}} </span>
                <span class="ml-1"> {{m + 1}}</span>
              </div>
            </ng-container>
            
          </div>
          
          <div class="mg_modul">
            <ng-container *ngFor="let param of Parametry.Parameters; let m = index;">
                <ng-container *ngIf="param.Type == 1 && param.Module == modul + 1  && param.Position == 0  && param.ControlName != 'Hidden' && param.ControlName != 'Img' && param.ControlName != 'Label' && param.DisplayValue != null && param.DisplayValue != ''">
                    <div class="kontrolka">
                        <div class="Label">
                            <span *ngIf = "param.ControlName == 'Label'" id="{{param.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('' + param.Key.toLowerCase())|translate}}</span>
                            <span *ngIf = "param.ControlName != 'Label'"  id="label_{{param.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + param.Key.toLowerCase())|translate}}</span>
                            <app-information [label]="param.Key" [notAffectOnOtherControl]='false' [fileInfoId]="getFileInfo(param.Key)"></app-information>
                        </div>
                    
                        <div class="pole_tresc">
                            {{GetLangValue(param)}}
                        </div>
                    </div>
                    
                </ng-container>
            </ng-container>
          </div>


          <div class="pozycje_zamowienia" *ngIf = "!podglad_poz">
            <div class="tabela_box">
                <table class="pozycje_tab">
                  <tr class="header">
                    <th class="headcol2" id = "podglad" [translation]="TranslationEnum.TranslationForm">{{'podglad'|translate}}</th>
                    <td id = "lp" [translation]="TranslationEnum.TranslationForm">{{'lp'|translate}}</td>
                    <ng-container *ngFor="let ng of naglowek; let i = index;">
                      <td  id="label_{{ng.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + ng.toLowerCase())|translate}}</td>
                    </ng-container>
                  </tr>

                        <ng-container *ngFor="let poz of pozycja; let p = index;">
                           
                            <tr>
                                <th class="headcol2" >
                                    <button mat-icon-button  color="accent" 
                                    (click)="EdytujPozycje(m, p)">
                                    <mat-icon>remove_red_eye</mat-icon>
                                  </button>
                
                                  </th>
                                <td >{{p + 1}}</td>
                                <ng-container *ngFor="let ng of naglowek; let i = index;">
                                    <td>{{GetValue(p, ng)}}</td>
                                </ng-container>
                            </tr>
                        </ng-container>
                       

                </table>
                
              </div>
          </div>



          <div class="pozycja_box" *ngIf = "podglad_poz">
            <div class="pozycja_edit2">
              <div class="pezycje_in">
                <button mat-icon-button color="primary" class="float-right mr-3" (click)="WyjdzZEdycji(p)">
                  <mat-icon>close</mat-icon>
                </button>
                <ng-container *ngFor="let poz of pozycja; let p = index;"> 
                    
                  <ng-container *ngIf ="p == edytowana_poz">
                    
                    <h2>
                      <span id="podglad_poz" [translation]="TranslationEnum.TranslationForm">{{'podglad_poz' | translate}}</span>
                      <span> {{p+1}}</span>
                    </h2>
                  
                    <div class="pozycja">
                        
                        <ng-container *ngFor="let param of Parametry.Parameters; let m = index;">
                            <ng-container *ngIf="param.Module == modul + 1  && param.Position == p + 1 && param.ControlName != 'Hidden' && param.ControlName != 'Img' && param.ControlName != 'Label' && param.DisplayValue != null && param.DisplayValue != ''">
                                <div class="kontrolka">
                                    <div class="Label">
                                        <span *ngIf = "param.ControlName == 'Label'" id="{{param.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('' + param.Key.toLowerCase())|translate}}</span>
                                        <span *ngIf = "param.ControlName != 'Label'"  id="label_{{param.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + param.Key.toLowerCase())|translate}}</span>
                                        <app-information [label]="param.Key" [notAffectOnOtherControl]='false' [fileInfoId]="getFileInfo(param.Key)"></app-information>
                                    </div>
                                
                                    <div class="pole_tresc">
                                        {{GetLangValue(param)}}
                                    </div>
                                </div>
                                
                            </ng-container>
                        </ng-container>


  
                    
                    </div>
                  </ng-container>
                    
                </ng-container> 
                <button mat-flat-button color="primary" [hidden]="loadPositionForVerify" class="float-right mr-3" (click)="WyjdzZEdycji(p)"
        id="wyjdz_z_poz" [translation]="TranslationEnum.TranslationForm">
        {{'wyjdz_z_poz'|translate}}
      </button>
              </div>
              
            </div>
          </div>





          <div class="mg_stopka">
            <ng-container *ngFor="let param of Parametry.Parameters; let m = index;">
                <ng-container *ngIf="param.Type == 3 && param.Module == modul + 1  && param.Position == 0  && param.ControlName != 'Hidden' && param.ControlName != 'Img' && param.ControlName != 'Label' && param.DisplayValue != null && param.DisplayValue != ''">
                    <div class="kontrolka" *ngIf = "!czyZawiera(param.Key)">
                        <div class="Label">
                            <span *ngIf = "param.ControlName == 'Label'" id="{{param.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('' + param.Key.toLowerCase())|translate}}</span>
                            <span *ngIf = "param.ControlName != 'Label'"  id="label_{{param.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + param.Key.toLowerCase())|translate}}</span>
                            <app-information [label]="param.Key" [notAffectOnOtherControl]='false' [fileInfoId]="getFileInfo(param.Key)"></app-information>
                        </div>
                    
                        <div class="pole_tresc">
                            {{GetLangValue(param)}}
                        </div>
                    </div>
                    
                </ng-container>
            </ng-container>
          </div>

    </div>

    <div *ngIf="!wczytywanie && zakladka == 2" class="add_box" >
        <h3> 
            <span id="dane_stopki" [translation]="TranslationEnum.TranslationForm">{{ 'dane_stopki' | translate }}</span>
        </h3>
        <ng-container *ngFor="let param of Parametry.Parameters; let m = index;">
            <ng-container *ngIf="param.Type == 4 && param.ControlName != 'Hidden' && param.ControlName != 'Img' && param.ControlName != 'Label' && param.DisplayValue != null && param.DisplayValue != ''">
                <div class="kontrolka">
                    <div class="Label">
                        <span *ngIf = "param.ControlName == 'Label'" id="{{param.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('' + param.Key.toLowerCase())|translate}}</span>
                        <span *ngIf = "param.ControlName != 'Label'"  id="label_{{param.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + param.Key.toLowerCase())|translate}}</span>
                        <app-information [label]="param.Key" [notAffectOnOtherControl]='false' [fileInfoId]="getFileInfo(param.Key)"></app-information>
                    </div>
                
                    <div class="pole_tresc">
                        {{GetLangValue(param)}}
                    </div>
                </div>
                
            </ng-container>
        </ng-container>
    </div>


</div>