export class DeliveryAddress {
  Id: number = 0;
  GrId: number;
  ContractorNumber: string;
  Name: string;
  Phone: string;
  Email: string;
  Address: string;
  ZipCode: string;
  City: string;
  Country: string;
  Comment: string;
  Default: boolean;
  ShippingPrice: number;
  HoursOpen: string;
  Hidden: boolean;
  Zone: string;
  IsUE: boolean;
  DeliveryCost: number;
  
  OtherTransportThenSelt:boolean;
}
