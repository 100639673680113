<mat-tab-group animationDuration="0ms" (selectedIndexChange)="getInvoiceOrDebts($event)" class="d-inline ml-auto mr-auto">
  <mat-tab>
    <ng-template mat-tab-label>
      <span id="faktury" [translation]="TranslationEnum.Translation">
        {{'faktury'|translate}}
      </span>
    </ng-template>
    <div class="m-2">
      <div class="mw-100">
      <div class="loading-shade" *ngIf="IsLoadingResults || IsRateLimitReached">
        <mat-spinner *ngIf="IsLoadingResults"></mat-spinner>
        <div *ngIf="IsRateLimitReached" id="wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę"
          class="rate-limit-reached" [translation]="TranslationEnum.Translation">
          {{'wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę'|translate}}
          <!-- <app-tlumacz [id]="'Wystąpił błąd połączenia. Proszę odświeżyć za minutę.'" [typ]="'stale'" [tresci]="tresci">
          </app-tlumacz> -->
        </div>
      </div>

      <mat-form-field appearance="outline" class="mt-3" color="accent">
        <mat-date-range-input mat-date-range-input [formGroup]="Range" [rangePicker]="picker">
          <input readonly matStartDate formControlName="start">
          <input readonly matEndDate formControlName="end" (dateChange)="dateRangeChange($event)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker" class="d-inline-block"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        
      </mat-form-field>
      <div class="toexcel"  (click)="ToExcel()" ><div class="excel"></div><div id ="toexcel" [translation]="TranslationEnum.TranslationForm">{{'toexcel'|translate}}</div></div>

      <table matSort multiTemplateDataRows matSortActive="InvoiceNumber" matSortDisableClear matSortDirection="desc" *ngIf="ModuleSelected.value === 0" mat-table [dataSource]="InvoicesDataSource" class="w-100 mt-10">

   <!-- 'InvoiceNumber', 'ContractorNumber', 'InvoiceType', 'IsCorrection', 'InvoiceDate', 'Currency', 'Netto', 'Brutto', 'Tax', 'PaymentDate' -->
      <ng-container matColumnDef='InvoiceNumber'>
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="numerfaktury" [translation]="TranslationEnum.TranslationForm">
          {{'numerfaktury'|translate}}
        </th>
        <td mat-cell *matCellDef="let row">
          {{row.InvoiceNumber}}
      </ng-container>

      <ng-container  matColumnDef='InvoiceType'>
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="typfaktury" [translation]="TranslationEnum.TranslationForm">
          {{'typfaktury'|translate}}
        </th>
        <td mat-cell *matCellDef="let row" id = "{{ row.InvoiceType.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">
          {{row.InvoiceType.toLowerCase()|translate}}
      </ng-container>

      <ng-container  matColumnDef='IsCorrection'>
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="korekta" [translation]="TranslationEnum.TranslationForm">
          {{'korekta'|translate}}
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.IsCorrection">done</mat-icon>
      </ng-container>

      <ng-container  matColumnDef='InvoiceDate'>
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="datawystawienia" [translation]="TranslationEnum.TranslationForm">
          {{'datawystawienia'|translate}}
        </th>
        <td mat-cell *matCellDef="let row">
          {{row.InvoiceDate | date: 'yyyy-MM-dd'}}
      </ng-container>

      <ng-container  matColumnDef='Currency'>
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="waluta" [translation]="TranslationEnum.TranslationForm">
          {{'waluta'|translate}}
        </th>
        <td mat-cell *matCellDef="let row">
          {{row.Currency}}
      </ng-container>

        <ng-container  matColumnDef='Netto'>
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="netto" [translation]="TranslationEnum.TranslationForm">
          {{'netto'|translate}}
        </th>
        <td mat-cell *matCellDef="let row">
          {{row.Netto | PriceFormat}}
      </ng-container>

      <ng-container  matColumnDef='Brutto'>
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="brutto" [translation]="TranslationEnum.TranslationForm">
          {{'brutto'|translate}}
        </th>
        <td mat-cell *matCellDef="let row">
          {{row.Brutto | PriceFormat}}
      </ng-container>

      <ng-container  matColumnDef='Tax'>
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="podatek" [translation]="TranslationEnum.TranslationForm">
          {{'podatek'|translate}}
        </th>
        <td mat-cell *matCellDef="let row">
          {{row.Tax | PriceFormat}}
      </ng-container>

      <ng-container  matColumnDef='PaymentDate'>
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="datazaplaty" [translation]="TranslationEnum.TranslationForm">
          {{'datazaplaty'|translate}}
        </th>
        <td mat-cell *matCellDef="let row">
          {{row.PaymentDate | date: 'yyyy-MM-dd'}}
      </ng-container>

      <tr mat-header-row *matHeaderRowDef=" DisplayedColumnsInvoices"></tr>
      <tr mat-row *matRowDef="let row; columns: DisplayedColumnsInvoices;"></tr>
      </table>
      </div>
      <mat-paginator [length]="ResultsLength" [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100, 200]"
      showFirstLastButtons></mat-paginator>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span id="zadluzenia" [translation]="TranslationEnum.Translation">
        {{'zadluzenia'|translate}}
      </span>
    </ng-template>
    <div class="m-2">
      <div class="mw-100">
        <div class="loading-shade" *ngIf="IsLoadingDebts || IsRateLimitReachedDebts">
          <mat-spinner *ngIf="IsLoadingDebts"></mat-spinner>
          <div *ngIf="IsRateLimitReachedDebts" id="wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę"
            class="rate-limit-reached" [translation]="TranslationEnum.Translation">
            {{'wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę'|translate}}
          </div>
        </div>

        <table mat-table  [dataSource]="DebtsDataSource" class="w-100 mt-10">
          <ng-container matColumnDef='InvoiceNumber'>
            <th mat-header-cell *matHeaderCellDef id="InvoiceNumber" [translation]="TranslationEnum.TranslationForm">
              {{'numerfaktury'|translate}}
            </th>
            <td mat-cell *matCellDef="let row">
              {{row.InvoiceNumber}}
          </ng-container>

          <ng-container  matColumnDef='InvoiceDate'>
            <th mat-header-cell *matHeaderCellDef id="datawystawienia" [translation]="TranslationEnum.TranslationForm">
              {{'datawystawienia'|translate}}
            </th>
            <td mat-cell *matCellDef="let row">
              {{row.InvoiceDate | date: 'yyyy-MM-dd'}}
          </ng-container>

          <ng-container  matColumnDef='Currency'>
            <th mat-header-cell *matHeaderCellDef id="waluta" [translation]="TranslationEnum.TranslationForm">
              {{'waluta'|translate}}
            </th>
            <td mat-cell *matCellDef="let row">
              {{row.Currency}}
          </ng-container>

            <ng-container  matColumnDef='DaysAfterPaymentDate'>
            <th mat-header-cell *matHeaderCellDef id="dnipoterminie" [translation]="TranslationEnum.TranslationForm">
              {{'dnipoterminie'|translate}}
            </th>
            <td mat-cell *matCellDef="let row">
              {{row.DaysAfterPaymentDate}}
          </ng-container>

          <ng-container  matColumnDef='PriceBrutto'>
            <th mat-header-cell *matHeaderCellDef id="brutto" [translation]="TranslationEnum.TranslationForm">
              {{'brutto'|translate}}
            </th>
            <td mat-cell *matCellDef="let row">
              {{row.PriceBrutto | PriceFormat}}
          </ng-container>

          <ng-container  matColumnDef='PaymentDate'>
            <th mat-header-cell *matHeaderCellDef id="datazaplaty" [translation]="TranslationEnum.TranslationForm">
              {{'datazaplaty'|translate}}
            </th>
            <td mat-cell *matCellDef="let row">
              {{row.PaymentDate | date: 'yyyy-MM-dd'}}
          </ng-container>

          <tr mat-header-row *matHeaderRowDef=" DisplayedColumnsDebts"></tr>
          <tr mat-row *matRowDef="let row; columns: DisplayedColumnsDebts;"></tr>
        </table>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
