export class alert {
  public alert_tresc = '';
  public alert_tresc2 = '';
  public visible = false;
  constructor(private tresci) {
  }
  public wyswietl(tresc, tresc2) {
    this.alert_tresc = tresc;
    this.alert_tresc2 = tresc2;
    this.visible = true;
  }
  public zamknij() {
    this.visible = false;
  }

}
