import { Router } from '@angular/router';
import { BroadcastService } from './broadcast.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { DialogData } from '../../models/dialog/dialog-data.model';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(public broadcastService: BroadcastService, private cookieService: CookieService, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.cookieService.get('_token');
    let modifiedReq: HttpRequest<any> = req.clone();
    if (req.url.includes('version.json') === false) {
      if (req.method === 'POST' && req.url.includes('plikiapi.selt.com')) {
        modifiedReq = req.clone({
          url: `${req.url}`,
          headers: req.headers
            .set('Authorization', 'Bearer ' + token)
        });
      } else if (req.method === 'POST') {
        modifiedReq = req.clone({
          url: environment.apiUrl + `${req.url}`,
          headers: req.headers
            .set('Authorization', 'Bearer ' + token)
        });
      } else if ((req.method === 'GET' && !req.url.includes('api.variantic')) || req.url === 'token') {
        modifiedReq = req.clone({
          url: environment.apiUrl + `${req.url}`,
          responseType: req.responseType,
          headers: req.headers.set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8'),
        });
      } else if (req.method === 'GET' && req.url.includes('api.variantic')) {
        // console.log('req', req);
        modifiedReq = req.clone({
          url: 'https://api.variantic.com/player/init/en/avrPRpwt9cPmBrSxGJNwWgPPZbBxxmU4',
          // responseType: req.responseType,
          // headers: req.headers.set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8'),
        });
      }
    }

    return next.handle(modifiedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';


        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // console.error(errorMessage);

        if (error.status === 400 || error.status === 405) {
          console.error('ctor', JSON.stringify(error.error.Message));
          if (error.error.Message !== undefined) {
            this.broadcastService.ErrorHttp.next(JSON.stringify(error.error.Message));
          } else {
            this.broadcastService.ErrorLogin.next(JSON.stringify(error.error.error));
          }
        }


        if (error.status === 401 && this.broadcastService.DialogRef === undefined) {
          error.error.Message = 'twoj_token_jest_nieaktywny_zaloguj_sie_ponowanie';
          const data: DialogData = {
            title: '',
            content: error.error.Message,
            answerOk: true
          };
          this.broadcastService.open401Dialog(data);
        }

        if (error.status === 503) {
          this.router.navigate(['error/503']);
        }

        return throwError(errorMessage);
      }));
  }

}
