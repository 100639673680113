import { BehaviorSubject } from 'rxjs';
import { NotificationUser } from './../shared/models/notifications/notification-user.model';
import { Notification } from './../shared/models/notifications/notification.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegisterNotificationUser } from '../shared/models/notifications/register-notification-user.model';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { Contractor } from '../shared/models/user/contractor.model';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _userNotifications: BehaviorSubject<Notification[]> = new BehaviorSubject(null as any);
  public readonly userNotifications: Observable<Notification[]> = this._userNotifications.asObservable();

  private _registerNotifications: BehaviorSubject<List<RegisterNotificationUser>> = new BehaviorSubject(List([]));

  public readonly registerNotifications: Observable<List<RegisterNotificationUser>> = this._registerNotifications.asObservable();

  public notificationsShowed = false;
  public isLoading = false;

  constructor(private http: HttpClient) {}

  getNotificationForUser(): Observable<Notification[]> {
    this.isLoading = true;
    // console.log("GetNotificationForUser");
    return this.http.get<Notification[]>('api/Notification/GetNotificationForUser');
  }

  getAllNotifications(contractorNumber: string): void {
    this.isLoading = true;

    this.http.get<List<RegisterNotificationUser>>('api/Notification/GetAllNotifications?contractorNumber=' + contractorNumber).pipe(/*take(1)*/).subscribe(
      (res: List<RegisterNotificationUser>) => {
        this.isLoading = false;
        this._registerNotifications.next(res);
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  saveUserAcceptance(acceptanceModel: NotificationUser): void {
    this.http.post('api/Notification/SaveUserAcceptance', acceptanceModel).pipe(/*take(1)*/).subscribe();
  }

  removeNotificationFromList(id: number): void {
    let notifications = this._userNotifications.getValue();

    notifications = notifications.filter((x) => x.Id !== id);
    this._userNotifications.next(notifications);
  }
}
