import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { deserialize, serialize } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})

class Except {
  public tresc = [];
}

@Injectable()
export class ExceptionsMenagerService {
  public exception_successful_message = '';
  public exception_message = '';
  public exception_con = '';
  public jest_except = false;
  public animation_reset = false;
  public dodane = null;
  public info: Info = new Info();
  public czas_except: Date;
  public typ = 'center';
  public podglad = false;
  public teraz = new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate());
  public opis_bug = '';
  public tresc_bug = '';
  public pokaz_bug = false;
  constructor() {
    if (localStorage.getItem('info') != null && localStorage.getItem('info') !== '') {
      this.info = deserialize(Info, localStorage.getItem('info'));
    }
  }
  public kopiuj () {
     this.copyMessage(serialize(this.exception_con), 'błąd', 0);
  }
   public pokaz_message(opis, tresc) {
    this.opis_bug = opis;
    this.tresc_bug = tresc;
    this.pokaz_bug = true;
   }
  public getMessages(typ) {

    const exc = localStorage.getItem('Exception_' + typ);
    let exc_tab: Except;
    if (exc == null || exc === '') {
      exc_tab = new Except();
    } else {
      exc_tab = deserialize(Except, exc);
    }
    return exc_tab.tresc.reverse();
  }
  public copyMessage(val: string, opis, typ) {
    const czas = new Date();
    const exc = localStorage.getItem('Exception_' + typ);
    let exc_tab: Except;
    if (exc == null || exc === '') {
      exc_tab = new Except();
    } else {
      exc_tab = deserialize(Except, exc);
    }
    if (exc_tab.tresc.length > 50) {
      exc_tab.tresc.splice(0, 1);
    }
   exc_tab.tresc.push({val: val, opis: opis, typ: typ, czas: czas});
   localStorage.setItem('Exception_' + typ, serialize(exc_tab));
    /*
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox); */
  }
  public porownaj(czas) {
    const czas1 = new Date(czas);
    if (czas1.getTime() > this.teraz.getTime()) {
      return true;
    }
    return false;
  }
  public exception_successful(message, exception_con, typ = null, dodane = null) {
    this.exception_con = exception_con;
    this.dodane = dodane;
    if (typ == null) {
      this.typ = 'center';
    } else {
      this.typ = typ;
    }
    this.exception_successful_message = message;
    this.animation_reset = !this.animation_reset;
    this.czas_except = new Date();
    this.exception_message = '';
    this.jest_except = true;
    // this.info.push({'czas': new Date(), 'tresc': message});
    this.info.pozycja.splice(0, 0, new Pozycja(new Date(), message));
    const inf = serialize(this.info);
    localStorage.setItem('info', inf);
    const mes = message;
    if (mes.length > 100) {
      mes.slice(0, 100);
    }
    this.copyMessage(serialize(this.exception_con), mes, 0);
    setTimeout(() => {  this.wylacz(); }, 5000);
  }
  public exception(message: string, exception_con, typ = null, dodane = null) {
    this.exception_con = exception_con;
    this.dodane = dodane;
    if (typ == null) {
      this.typ = 'center';
    } else {
      this.typ = typ;
    }
    this.exception_successful_message = '';
    this.exception_message = message;
    this.animation_reset = !this.animation_reset;
    this.czas_except = new Date();
    this.jest_except = true;
    // this.info.push({'czas': new Date(), 'tresc': message});
    this.info.pozycja.splice(0, 0, new Pozycja(new Date(), message));
    const inf = serialize(this.info);
    localStorage.setItem('info', inf);
    const mes = message;
    if (mes.length > 100) {
      mes.slice(0, 100);
    }
    this.copyMessage(serialize(this.exception_con), mes, 0);
   setTimeout(() => {  this.wylacz(); }, 5000);
  }

  public wylacz() {
    if (new Date().getTime() - this.czas_except.getTime() > 4950) {
      this.jest_except = false;
    }
  }

  public error(err: HttpErrorResponse) {
    if (err.error.ModelState != null && err.error.ModelState[''] != null) {
        return err.error.ModelState[''];
    } else if (err.error != null && err.error.Message != null) {
      return err.error.Message;
    } else  if (err.error != null && err.error.error_description != null) {
      return err.error.error_description;
    } else if (err.error != null) {
      let message = '';
      const tmp = err.error;
      if (tmp !== null && Array.isArray(tmp)) {
        tmp.forEach(element => {
          message += element + ', ';
        });
      }
      return message;
    } else {
      return err.statusText;
    }
  }
}
class Info {
  public pozycja = [];
  constructor() {}
}
class Pozycja {
  constructor(public czas, public tresc) {}
}
