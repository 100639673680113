<h1 matDialogTitle>
  <span id="potwierdz_dostawe" [translation]="TranslationEnum.Translation">
    {{ 'potwierdz_dostawe' | translate }}
  </span>
</h1>
<mat-dialog-content>
<div class="row m-0" >
  <div class="col-sm">
    Invoice no. 2021/4/060401 from the date 30.10.2021
  </div>
  <div class="col-sm">
    Specification of the goods
  </div>
  <div class="col-sm">
    Delivery confirmation ICD
  </div>
</div>
<div class="row">
  <div class="col-sm">
    Seller (Sprzedawca) <br/>
    SELT Sp. z o. o. <br/>
    45-499 Opole, ul. Wschodnia 23a <br/>
    POLSKA <br/>
    Vat UE: PL7543103311 <br/>
  </div>
  <div class="col-sm">
    Buyer (Nabywca) <br/>
    SELT Sp. z o. o. <br/>
    45-499 Opole, ul. Wschodnia 23a <br/>
    POLSKA <br/>
    Vat UE: PL7543103311 <br/>
  </div>
  <div class="col-sm">
    Delivery adress (Adres dostawy) <br/>
    SELT Sp. z o. o. <br/>
    45-499 Opole, ul. Wschodnia 23a <br/>
    POLSKA <br/>
    Vat UE: PL7543103311 <br/>
  </div>

</div>
  <table mat-table [(dataSource)]="data.tableData" multiTemplateDataRows class="mat-elevation-z8 mt-2 mb-3">
    <ng-container matColumnDef="InvoiceNumber">
      <th mat-header-cell *matHeaderCellDef id="numer_faktury" [translation]="TranslationEnum.Translation">
        {{ 'numer_faktury' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.InvoiceNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="WdtNumber">
      <th mat-header-cell *matHeaderCellDef  id="numer_wdt" [translation]="TranslationEnum.Translation">
        {{ 'numer_wdt' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.WdtNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="OrdersNumber">
      <th mat-header-cell *matHeaderCellDef  id="ilosc_zlecen" [translation]="TranslationEnum.Translation">
        {{ 'ilosc_zlecen' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.OrdersNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="DeliveryDate">
      <th mat-header-cell *matHeaderCellDef  id="data_dostawy" [translation]="TranslationEnum.Translation">
        {{ 'data_dostawy' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.DeliveryDate | date: 'yyyy-MM-dd' }}</td>
    </ng-container>

    <ng-container matColumnDef="ValueNettoBrutto">
      <th mat-header-cell *matHeaderCellDef id="wartosc_netto_brutto" [translation]="TranslationEnum.Translation">
        {{ 'wartosc_netto_brutto' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ '5201.45' }}</td>
    </ng-container>

    <ng-container matColumnDef="ShortDeliveryAddress">
      <th mat-header-cell *matHeaderCellDef id="skrocony_adres_dostawy" [translation]="TranslationEnum.Translation">
        {{ 'skrocony_adres_dostawy' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.ShortDeliveryAddress }}</td>
    </ng-container>

    <ng-container matColumnDef="Confirmed">
      <th mat-header-cell *matHeaderCellDef id="potwierdzone" [translation]="TranslationEnum.Translation">
        {{ 'potwierdzone' | translate }}
      </th>
      <td mat-cell *matCellDef="let row"><mat-icon style="color: green">check</mat-icon></td>
    </ng-container>

    <ng-container matColumnDef="PrintoutWDT">
      <th mat-header-cell *matHeaderCellDef id="wydruk_wdt" [translation]="TranslationEnum.Translation">
        {{ 'wydruk_wdt' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.PrintoutWDT }}</td>
    </ng-container>

    <ng-container matColumnDef="Expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="text-right">
        <mat-icon class="animate">
          {{ expandedElement?.includes(row) ?  'keyboard_arrow_down' : 'keyboard_arrow_up' }}
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="border">
        <div class="example-element-detail"
         [@detailExpand]="expandedElement?.includes(element) ? 'expanded' : 'collapsed' ">
          <table mat-table [dataSource]="element.WdtPosList" class="mat-elevation-z8" width="100%">
            <ng-container matColumnDef="OrderWdt">
              <th mat-header-cell *matHeaderCellDef id="numer_zlecenia" [translation]="TranslationEnum.Translation">
                {{ 'numer_zlecenia' | translate }}
                <!-- <app-tlumacz [id]="'Status dostępności'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </th>
              <td mat-cell *matCellDef="let element">{{ element.OrderWdt }}</td>
            </ng-container>

            <ng-container matColumnDef="Sheet">
              <th mat-header-cell *matHeaderCellDef id="ilosc_arkuszy" [translation]="TranslationEnum.Translation">
                {{ 'ilosc_arkuszy' | translate }}
                <!-- <app-tlumacz [id]="'Komentarz'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </th>
              <td mat-cell *matCellDef="let element">{{ element.Sheet }}</td>
            </ng-container>

            <ng-container matColumnDef="PackageDelivered">
              <th mat-header-cell *matHeaderCellDef id="paczek_dostarczono" [translation]="TranslationEnum.Translation">
                {{ 'paczek_dostarczono' | translate }}
                <!-- <app-tlumacz [id]="'Parametr'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </th>
              <td mat-cell *matCellDef="let element">{{ element.PackageDelivered }}</td>
            </ng-container>

            <ng-container matColumnDef="PackageDeliveredDate">
              <th mat-header-cell *matHeaderCellDef id="data_doreczenia" [translation]="TranslationEnum.Translation">
                {{ 'data_doreczenia' | translate }}
                <!-- <app-tlumacz [id]="'Wartość parametru'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </th>
              <td mat-cell *matCellDef="let element">{{ element.PackageDeliveredDate | date:'yyyy-MM-dd H:mm:ss' }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="expandDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: expandDisplayedColumns"></tr>
          </table>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="example-element-row border"
      [class.example-expanded-row]="expandedElement?.includes(element)"
      (click)="elementToExpandClicked(element)"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions>

</mat-dialog-actions>
