import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, } from '@angular/core';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { TranslationEnum } from '../enums/translation-enum';
import { environment } from 'src/environments/environment';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {

  public TranslationEnum = TranslationEnum;

  public dateInFooter: number = new Date().getFullYear();

  public HrefConditionsOfSale: string;
  public HrefTermsOfWarranty: string;
  public HrefAuthorizationContracts: string;
  public HrefRegulations: string;
  public HrefRODO: string;
  public HrefClauseCopyright: string;
  public HrefApplicationForm: string;
  public IsLogedIn = false;
  public Version;
  public HrefAuthorizationContractsNoUe: string;
  private subscriptions: Subscription[] = [];
  public linki = [];

  constructor(public transitionService: TranslateService, public authorizationService: AuthorizationService, private httpClient: HttpClient) {
    this.subscriptions.push(this.authorizationService.User.asObservable().subscribe(x => {
      if (x) {
        if (x.IsFirst) {
          this.IsLogedIn = false;
        } else {
          this.IsLogedIn = true;
        }
      } else {
        this.IsLogedIn = false;
      }
    }));
    this.getFooterLinks();
    this.getLinks();
    this.subscriptions.push(transitionService.onLangChange.subscribe((x) => {this.getFooterLinks(); this.getLinks();}));
    this.Version = environment.appVersion;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];

  }
  getNazwa(dok){
    if(dok.nazwa_s == null || dok.nazwa_s == ''){
      return dok.nazwa;
    }
    return dok.nazwa_s;
  }
  public NazwaPliku(nazwa:string, url){
    nazwa = nazwa.replace('/','');
    nazwa = nazwa.replace('  ',' ');
    var roz = url.split(".");
    return nazwa +"."+ roz[roz.length - 1];
  }
  getLinks(){
    // https://plikiapi.selt.com/input.php?tryb=dokumenty

    this.getLinksApi().
    subscribe((data) => {

      // console.log("dok: ", data);
      this.linki = data;
      
    })

  }
  public getLinksApi(): Observable<any> {
   
    return this.httpClient.post<any>('https://plikiapi.selt.com/input.php?tryb=dokumenty&jezyk='+this.getJezykId(window['culture']), null, {
      headers: this.getHeaderJson(),
    });
  }
  public getLink(dok){
    return 'https://plikiapi.selt.com/download/'+this.NazwaPliku(this.getNazwa(dok), dok.url)+'?nazwa=' + dok.url ;
  }
  public getJezykId(jezyk:string){
    switch (jezyk) {
      case 'pl': {
        return 2;
        break;
      }
      case 'en': {
        return 4;
        break;
      }
      case 'de': {
        return 3;
       break;
      }
      case 'fr': {
        return 5;
        break;
      }
      case 'cs': {
        return 6;
        break;
      }
      case 'it': {
        return 7;
        break;
      }
      case 'esp': {
        return 8;
        break;
      }
      default: {
        return 2;
        break;
      }
    }
        
  }
  public getHeaderJson() {
    const header: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }

  getFooterLinks() {
    switch (window['culture']) {
      case 'pl': {
        this.HrefConditionsOfSale = 'https://selt.com/pliki/pliki/52413OWS_SELT_Sp._z_o.o._2022_2.pdf';
        this.HrefTermsOfWarranty = 'https://selt.com/pliki/pliki/31257OWGSELTSpzoo2022.pdf';
        this.HrefAuthorizationContracts = 'https://www.selt.com/pliki/B2B/pl/UE.pdf';
        this.HrefAuthorizationContractsNoUe = 'https://www.selt.com/pliki/B2B/pl/pozaUE.pdf';
        this.HrefRegulations = 'https://www.selt.com/pliki/B2B/pl/Regulamin_B2B.pdf';
        this.HrefRODO = 'https://selt.com/pliki/pliki/76449Informacja_RODO.pdf';
        this.HrefApplicationForm = '';
        // tslint:disable-next-line: max-line-length
        this.HrefClauseCopyright = 'https://selt.com/pliki/pliki/10731Klauzula_ochrony_praw_autorskich_i_pokrewnych_oraz_praw_wlasnosci_przemyslowej.pdf';
        break;
      }
      case 'en': {
        this.HrefConditionsOfSale = 'https://www.selt.com/pliki/B2B/en/en__ows__19_06_201712409.pdf';
        this.HrefTermsOfWarranty = 'https://www.selt.com/pliki/B2B/en/general_guarantee_terms_and_conditions11661.pdf';
        this.HrefAuthorizationContracts = 'https://www.selt.com/pliki/B2B/en/AUTHORIZATIONTOSIGNAGREEMENTS.pdf';
        this.HrefRegulations = 'https://www.selt.com/pliki/B2B/en/RegulationsfortheonlineplatformB2B.pdf';
        this.HrefAuthorizationContractsNoUe = 'https://www.selt.com/pliki/B2B/en/personsauthorizedspzoo_en.pdf';
        this.HrefRODO = '';
        this.HrefApplicationForm = '';
        this.HrefClauseCopyright = 'https://www.selt.com/pliki/B2B/en/CopyrightandRelatedRights.pdf';
        break;
      }
      case 'de': {
        this.HrefConditionsOfSale = 'https://www.selt.com/pliki/B2B/de/Allgemeine_Verkaufsbedingungen_2020.pdf';
        this.HrefTermsOfWarranty = 'https://www.selt.com/pliki/B2B/de/Allgemeine_Garantiebedingungen_2020.pdf';
        this.HrefAuthorizationContracts = 'https://www.selt.com/pliki/B2B/de/BevollmachtigungzurAusfuhrungvonBestellungenundRechnungsstellungUE.pdf';
        this.HrefAuthorizationContractsNoUe = 'https://www.selt.com/pliki/B2B/de/bevollmachtigungzurausfuhrung_de.pdf';
        this.HrefRegulations = 'https://www.selt.com/pliki/B2B/de/Nutzungsbedingungen_Bestellplattform.pdf';
        this.HrefRODO = 'https://www.selt.com/pliki/B2B/de/DSGVO_Selt_Sp_z_oo_2020_DE.pdf';
        this.HrefApplicationForm = '';
        // tslint:disable-next-line: max-line-length
        this.HrefClauseCopyright = 'https://www.selt.com/pliki/B2B/de/chutzklausel_von_Urheberrechten_und_verwandter_Schutzrechte_sowie_Schutzrechte_von_gewerblichem_Eigentum.pdf';
        break;
      }
      case 'fr': {
        this.HrefConditionsOfSale = 'https://www.selt.com/pliki/B2B/fr/OWS_SELT_ZOO_2018_FR.pdf';
        this.HrefTermsOfWarranty = 'https://www.selt.com/pliki/B2B/fr/golnewarunkigwarancji2019francuskiCGG.pdf';
        this.HrefAuthorizationContracts = 'https://www.selt.com/pliki/B2B/fr/ACCEPTATION_FACTURES_ELECTRONIQUES.pdf';
        this.HrefRegulations = 'https://www.selt.com/pliki/B2B/fr/REGLEMENT_DUNE_PLATEFORME_ELECTRONIQUE.pdf';
        this.HrefRODO = 'https://www.selt.com/pliki/B2B/fr/Informations_RGPD.pdf';
        this.HrefApplicationForm = '';
        this.HrefClauseCopyright = 'https://www.selt.com/pliki/B2B/fr/Clause_sur_la_protection_du_droit_dauteur.pdf';
        break;
      }
      case 'cs': {
        this.HrefConditionsOfSale = 'https://www.selt.com/pliki/B2B/cz/VSEOBECNE_PRODEJNI_PODMINKY_CZ.pdf';
        this.HrefTermsOfWarranty = 'https://www.selt.com/pliki/B2B/cz/VSEOBECNE_ZARUCNI_PODMINKY_CZ.pdf';
        this.HrefAuthorizationContracts = 'https://www.selt.com/pliki/B2B/cz/AUTHORIZATIONTOSIGNAGREEMENTS.pdf';
        this.HrefRegulations = 'https://www.selt.com/pliki/B2B/cz/RegulaminB2B_CZ.pdf';
        this.HrefRODO = 'https://www.selt.com/pliki/B2B/cz/Informace_o_GDPR_CZ.pdf';
        this.HrefApplicationForm = '';
        this.HrefAuthorizationContracts = 'https://www.selt.com/pliki/B2B/cz/ZMOCNENI_K_PODPISU_SMLUV_CZ.pdf';
        this.HrefAuthorizationContractsNoUe = 'https://www.selt.com/pliki/B2B/cz/AUTHORIZATION_TO_SIGN_AGREEMENTS_ENG.pdf';
        // tslint:disable-next-line: max-line-length
        this.HrefClauseCopyright = 'https://www.selt.com/pliki/B2B/cz/Dolozka_o_ochrane_autorskych_prav_CZ.pdf';
        break;
      }
      default: {
        this.HrefConditionsOfSale = 'https://www.selt.com/pliki/B2B/pl/OWS_SELT_Sp._z_o.o._2022_2.pdf';
        this.HrefTermsOfWarranty = 'https://www.selt.com/pliki/B2B/pl/OWGSELTSpzoo2022.pdf';
        this.HrefAuthorizationContracts = 'https://www.selt.com/pliki/B2B/pl/Upowaznienie_do_zawierania_umow.pdf';
        this.HrefAuthorizationContractsNoUe = 'https://www.selt.com/pliki/B2B/pl/osobyupowaznionespzoo_pl.pdf';
        this.HrefRegulations = 'https://www.selt.com/pliki/B2B/pl/Regulamin_B2B.pdf';
        this.HrefRODO = 'https://www.selt.com/pliki/B2B/pl/Informacja_RODO.pdf';
        this.HrefApplicationForm = '';
        // tslint:disable-next-line: max-line-length
        this.HrefClauseCopyright = 'https://www.selt.com/pliki/B2B/pl/Klauzula_ochrony_praw_autorskich_i_pokrewnych_oraz_praw_wlasnosci_przemyslowej.pdf';
        break;
      }
    }

  }
}
