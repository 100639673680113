import { OrderRegisterService } from './../../order-register.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { map, takeWhile } from 'rxjs/operators';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-order-attachment-dialog',
  templateUrl: './order-attachment-dialog.component.html',
  styleUrls: ['./order-attachment-dialog.component.scss']
})
export class OrderAttachmentDialogComponent implements OnInit, OnDestroy {
  public TranslationEnum = TranslationEnum;
  public Culture: string;
  private subscriptions: Subscription[] = [];

  public DataSource = new MatTableDataSource<any>();
  public DisplayedColumns = ['FileName', 'Attachment'];

  constructor(public dialogRef: MatDialogRef<OrderAttachmentDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any, public orderRegisterService: OrderRegisterService, translateService: TranslateService) {
    this.DataSource.data = data;
    this.Culture = translateService.currentLang;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit(): void {
  }

  downloadAttachment(url: string, filename: string): void {
    this.subscriptions.push(this.orderRegisterService.getAttachmentFile(url).pipe(map((blob) => {
      const link = document.createElement('a');
      link.target = '_self';
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      link.remove();
    })).subscribe());

  }

}
