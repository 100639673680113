import { FileControl } from './../shared/models/form/file-control.model';
import { NgModule } from '@angular/core';
import { GrupaModul } from './grupamodul';

import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Kroki } from './kroki';
import { Pozycja } from './pozycja';
import { TresciService } from '../tresci.service';
import { deserialize, serialize } from 'class-transformer';
import { ParametryWybrane } from './parametry_wybrane';
import { ISurcharge } from './Interfaces/ISurcharge';
import { take } from 'rxjs/operators';
// import { ISurcharge } from 'ISurcharge';


class ZamownienieKontener {
  public parametry: any[];
  constructor(any) {
    this.parametry = any;
  }
}

export class Zamowienie {
  public naglowek: Kroki;
  public stopka: Kroki;
  // public pozycja = 0;
  // public pozycjaKrok = 0;
  // public modul = 0;
  public grupa_modul: GrupaModul[] = [];
  public wyb_mod = false;
  public CurrentModule = null;
  public CurrentPosition = null;

  public IsModuleFooter = false;
  public IsFooter = false;
  public IsModule = false;
  public IsLastStep = false;

  public wczytywanie = false;
  public naglowekGotowtTmp = false;
  private http: HttpClient;
  public poprawne = false;
  public parametryWczytane = [];
  public parametryWczytaneKim = [];
  public parametry_wczytwane_licznik = 0;
  public parametry_wybrane: ParametryWybrane;
  public istnieja_zapisane = false;
  public lastMod = -1;
  public lastPoz = -1;
  public wczytuje_z_paramwtrow = false;
  public ModulWczyt = -1;
  public PozWczyt = -1;
  public ParametryTMP = null;
  public ParametryKimTMP = null;
  public wczytywane = [];
  public ile_krokow_wczytano = 0;
  public trwa_zamawianie = false;
  public parametryNaglowka = null;
  public parametryNaglowkaKim = null;
  public max_pozycja_w_module = [];
  public strukt = null;

  public fileControls: FileControl[] = [];

  public isLoaded = false;
  public ListOfSurcharge: ISurcharge[] = [];


  constructor(private tresci: TresciService) {
    this.parametry_wybrane = new ParametryWybrane();
    this.http = this.tresci.http;
    this.naglowek = new Kroki(this.tresci, 0, 0);
    this.tresci.system_wybrany = '';
    //  this.naglowek.wczytajKrok(0);
  }

  public wczytujeParam(modul, pozycja) {
    this.wczytywane.push({ 'modul': modul, 'pozycja': pozycja, 'wczytuje': true });
  }
  public ParamWczytany(modul, pozycja) {
    this.wczytywane.forEach(element => {
      if (element.modul === modul && element.pozycja === pozycja) {
        element.wczytuje = false;
      }
    });
    this.ile_krokow_wczytano++;
  }
  public czyWczytuje() {
    let wczytuje = false;
    this.wczytywane.forEach(element => {
      if (element.wczytuje) {
        wczytuje = true;
      }
    });
    return wczytuje;
  }
  public ileProcent() {
    let proc = 0;
    proc = (this.ModulWczyt / (this.lastMod + 1)) * 100;
    proc = Math.round(this.parametry_wczytwane_licznik / this.parametryWczytane.length * 100);
    if (proc > 100) {
      this.tresci.problem_wczytywanie = true;
      return 100;
    } else {
      return proc;
    }
  }

  public dodajModul() {
    this.grupa_modul.push(new GrupaModul(this.tresci, this.grupa_modul.length + 1, this.naglowek.LastStep(), this.naglowek.getParams()));
    // this.wczytajModulHeader(0);
    this.CurrentModule = this.grupa_modul.length - 1;
    this.tresci.zmien_zapisz();
    // this.tresci.wczytaj_tresci();

    this.zapisz_Parametry();
  }
  public ustawStopke(Step, czyParametry = false) {
    if (this.stopka == null) {
      this.stopka = new Kroki(this.tresci, 0, 0, Step, this.naglowek.getParams());
      if (czyParametry) {
        this.stopka.wczytajKrokZparametrami(0, this.parametryNaglowka, null, null, this.parametryNaglowkaKim);
      } else {
        this.stopka.wczytajKrok(0);
      }
    } else {

    }
  }
  public usunModul(id) {
    this.grupa_modul.splice(id, 1);
    if (this.CurrentModule >= this.grupa_modul.length) {
      this.CurrentModule = this.grupa_modul.length - 1;
    }
    this.zapisz_Parametry();
    this.tresci.zmien_zapisz();
  }
  public zmien_modul(id) {
    this.CurrentModule = id;
    this.tresci.zmien_zapisz();
  }
  public poza() {
    this.naglowek.poza();
    if (this.stopka != null) {
      this.stopka.poza();
    }
    this.grupa_modul.forEach(grupa => {
      grupa.poza();
    });
  }
  public sprawdzPoprawnosc() {
    this.grupa_modul.forEach(mod => {
      mod.sprawdzPoprawnosc();
    });
  }
  public getParams() {
    const parametry = this.naglowek.getParams();
    this.grupa_modul.forEach(modul => {
      const param_mod = modul.getSelfParams();
      param_mod.forEach(param => {
        parametry.push(param);
      });
    });
    if (this.stopka != null) {
      const param_st = this.stopka.getSelfParams();
      param_st.forEach(param => {
        parametry.push(param);
      });
    }
    return parametry;
  }
  public getParamsKim() {
    const parametry = this.naglowek.getParamsKim();
    this.grupa_modul.forEach(modul => {
      const param_mod = modul.getSelfParamsKim();
      param_mod.forEach(param => {
        parametry.push(param);
      });
    });
    if (this.stopka != null) {
      const param_st = this.stopka.getSelfParamsKim();
      param_st.forEach(param => {
        parametry.push(param);
      });
    }
    return parametry;
  }
  public getParamsSoftWybrane() {
    const parametry = this.naglowek.getParamsSoftWybrane();
    this.grupa_modul.forEach(modul => {
      const param_mod = modul.getParamsSoftWybrane();
      param_mod.forEach(param => {
        parametry.push(param);
      });
    });
    if (this.stopka != null) {
      const param_st = this.stopka.getParamsSoftWybrane();
      param_st.forEach(param => {
        parametry.push(param);
      });
    }
    return parametry;
  }
  public czyNaglowekGotowy() {
    if (this.naglowek.Last) {
      if (this.grupa_modul.length === 0) {
        this.dodajModul();
        this.CurrentModule = 0;
      } else if (this.naglowekGotowtTmp === false) {
        this.grupa_modul.forEach(element => {
          element.sprawdz(this.naglowek.LastStep(), this.naglowek.getParams());
        });
      }
      this.naglowekGotowtTmp = true;
    } else {
      this.naglowekGotowtTmp = false;
    }
    return this.naglowek.Last;
  }
  public czyStopka() {
    if (this.grupa_modul.length > this.CurrentModule && this.grupa_modul[this.CurrentModule] !== null
      && this.grupa_modul[this.CurrentModule].pozycja !== null && this.grupa_modul[this.CurrentModule].pozycja.length > 0) {
      const newLocal = this;
      if (this.tresci.user.IsTrader) {
        return true;
        // tslint:disable-next-line: max-line-length
      } else if (this.grupa_modul[this.CurrentModule].pozycja[this.grupa_modul[this.CurrentModule].pozycja.length - 1].WartoscKod('Moduł') === 'P1'
        // tslint:disable-next-line: max-line-length
        || this.grupa_modul[this.CurrentModule].pozycja[this.grupa_modul[this.CurrentModule].pozycja.length - 1].WartoscKod('Moduł') === 'DP1'
        // tslint:disable-next-line: max-line-length
        || newLocal.grupa_modul[this.CurrentModule].pozycja[this.grupa_modul[this.CurrentModule].pozycja.length - 1].WartoscKod('Moduł') === '11') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public czySkonczonyMod() {
    if (!this.IsModule) {
      return true;
    } else if (this.grupa_modul.length > this.CurrentModule &&
      this.grupa_modul[this.CurrentModule] !== null &&
      this.grupa_modul[this.CurrentModule].pozycja != null &&
      this.grupa_modul[this.CurrentModule].pozycja.length > 0 &&
      this.grupa_modul[this.CurrentModule].pozycja[this.grupa_modul[this.CurrentModule].pozycja.length - 1] !== null &&
      (this.tresci.user.IsTrader ||
        // tslint:disable-next-line: max-line-length
        this.grupa_modul[this.CurrentModule].pozycja[this.grupa_modul[this.CurrentModule].pozycja.length - 1].WartoscKod('Moduł') === 'P1' ||
        // tslint:disable-next-line: max-line-length
        this.grupa_modul[this.CurrentModule].pozycja[this.grupa_modul[this.CurrentModule].pozycja.length - 1].WartoscKod('Moduł') === 'DP1' ||
        // tslint:disable-next-line: max-line-length
        this.grupa_modul[this.CurrentModule].pozycja[this.grupa_modul[this.CurrentModule].pozycja.length - 1].WartoscKod('Moduł') === '11')) {
      if (this.IsModuleFooter && this.grupa_modul[this.CurrentModule].stopkaModul.Last) {
        return true;
      } else if (!this.IsModuleFooter) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public czyPoprawny() {
    let poprawny = true;
    this.grupa_modul.forEach(mod => {
      if (!mod.poprawny) {
        poprawny = false;
      }
      if (!mod.stopkaModul.poprawny && this.tresci.zamowienie.IsModuleFooter) {
        poprawny = false;
      }
    });
    if (!this.naglowek.poprawny) {
      poprawny = false;
    }
    if (this.stopka != null && !this.stopka.poprawny) {
      poprawny = false;
    }
    if (this.grupa_modul.length > 0) {
      return poprawny;
    } else {
      return false;
    }

  }
  public ustawPoprawnosc() {
    this.grupa_modul.forEach(element => {
      element.ustawPoprawnosc();
    });
  }
  public korektaParametrow() {
    let modul = 0;
    let modul_tmp = -1;
    this.ParametryTMP.forEach(element => {
      if (element.Module > modul + 1) {
        const mod = element.Module;
        this.ParametryTMP.forEach(element2 => {
          if (element2.Module === mod) {
            element2.Module = modul + 1;
          }
        });
        this.ParametryKimTMP.forEach(element2 => {
          if (element2.Module === mod) {
            element2.Module = modul + 1;
          }
        });
      }
      modul = element.Module;
      let pozycja = 0;
      if (modul_tmp !== modul) {
        this.ParametryTMP.forEach(elementP => {
          if (elementP.Module === modul && modul > 0) {
            if (elementP.Position > pozycja + 1) {
              const poz = elementP.Position;
              this.ParametryTMP.forEach(element2 => {
                if (element2.Module === modul && element2.Position === poz) {
                  element2.Position = pozycja + 1;
                }
              });
              this.ParametryKimTMP.forEach(element2 => {
                if (element2.Module === modul && element2.Position === poz) {
                  element2.Position = pozycja + 1;
                }
              });
            } else {
            }
            pozycja = elementP.Position;
          }
        });
        modul_tmp = modul;
      }

    });
  }
  public zamow(CzyEdytuj, ListOfSurchargeForSave: any) {
    if (!this.trwa_zamawianie) {
      this.trwa_zamawianie = true;
      const ProduktPrefix = this.tresci.getPrefix();
      this.ParametryTMP = this.getParams();
      this.ParametryKimTMP = this.getParamsKim();
      this.korektaParametrow();
      let ContractorNumber = this.tresci.user.ContractorNumber;
      let ContractorName = this.tresci.user.ContractorName;
      if (ContractorName == null) {
        // ContractorName = 'Selt sp. z o.o.';
      }
      if (ContractorNumber == null) {
        // ContractorNumber = '79561';
      }

      const delivery_method = this.tresci.user.dostawa.DeliveryMethods[this.tresci.user.dostawa_id].Name;
      let DeliveryAddress = null;
      let DeliveryCity = null;
      let DeliveryZipCode = null;
      let DeliveryCountry = null;
      let DeliveryAddressId = null;
      const delivery_method_id = this.tresci.user.dostawa.DeliveryMethods[this.tresci.user.dostawa_id].Id;
      if (this.tresci.user.dostawa.DeliveryMethods[this.tresci.user.dostawa_id].IsDeliveryAddress) {
        DeliveryAddress = this.tresci.user.dostawa.ContractorAdresses[this.tresci.user.dostawa_id_adres].Adress;
        DeliveryCity = this.tresci.user.dostawa.ContractorAdresses[this.tresci.user.dostawa_id_adres].City;
        DeliveryZipCode = this.tresci.user.dostawa.ContractorAdresses[this.tresci.user.dostawa_id_adres].ZipCode;
        DeliveryCountry = this.tresci.user.dostawa.ContractorAdresses[this.tresci.user.dostawa_id_adres].Country;
        DeliveryAddressId = this.tresci.user.dostawa.ContractorAdresses[this.tresci.user.dostawa_id_adres].Id;
      }

      let body = null;
      if (CzyEdytuj) {
        body = {
          'ProductPrefix': ProduktPrefix,
          'Parameters': this.ParametryTMP,
          'ContractorNumber': ContractorNumber,
          'ContractorName': ContractorName,
          'CategoryProduct': this.tresci.system,
          'Id': this.tresci.id,
          'DeliveryMethodId': delivery_method,
          'DeliveryMethod': delivery_method_id,
          'DeliveryAddress': DeliveryAddress,
          'DeliveryCity': DeliveryCity,
          'DeliveryZipCode': DeliveryZipCode,
          'DeliveryCountry': DeliveryCountry,
          'DeliveryAddressId': DeliveryAddressId,
          'ParameterExtensions': this.ParametryKimTMP,
          'Language': this.tresci.jezyk,
          'Surcharges': ListOfSurchargeForSave,
          'TechnologyVersion': this.tresci.LastTechVersion,
          'IsTrader': this.tresci.IsTrader
        };
      } else {
        body = {
          'ProductPrefix': ProduktPrefix,
          'Parameters': this.ParametryTMP,
          'ContractorNumber': ContractorNumber,
          'ContractorName': ContractorName,
          'CategoryProduct': this.tresci.system,
          'DeliveryMethodId': delivery_method,
          'DeliveryMethod': delivery_method_id,
          'DeliveryAddress': DeliveryAddress,
          'DeliveryCity': DeliveryCity,
          'DeliveryZipCode': DeliveryZipCode,
          'DeliveryCountry': DeliveryCountry,
          'DeliveryAddressId': DeliveryAddressId,
          'ParameterExtensions': this.ParametryKimTMP,
          'Language': this.tresci.jezyk,
          'Surcharges': ListOfSurchargeForSave,
          'TechnologyVersion': this.tresci.LastTechVersion,
          'IsTrader': this.tresci.IsTrader
        };
      }

      const formData: FormData = new FormData();
      for (const file of this.fileControls) {
        if (file.File !== undefined) {
          formData.append(file.Id, file.File, file.FileName);
        }
      }
      const dane = serialize(body);
      formData.append('ActiveFormOrderVm', dane);

      // tslint:disable-next-line:max-line-length
      this.tresci.copyMessage(dane, 'Parametry zamówienia', 1);

      const request = new HttpRequest('POST',
          '/api/ActiveForm/AddorUpdateActiveFormOrderWithFiles',
        formData,
        { headers: this.tresci.user.getHeaderFile() });
      this.http.request(request).pipe(/*take(1)*/)
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Response:
              if (this.tresci.komponent === 'OrderDesktop') {
                this.tresci.zmienStrone('OrderDesktop', 'Podziekowanie');
              } else {
                this.tresci.zmienStrone('Orders', 'Orders');
              }
              this.trwa_zamawianie = false;
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.tresci.exception.exception(this.tresci.exception.error(err), err);
            this.trwa_zamawianie = false;
          }
        });
    }
  }

  public ile_mod_max(modul, pozycja) {
    if (modul >= this.max_pozycja_w_module.length) {
      this.max_pozycja_w_module.push({ Max: pozycja });
    }
    if (this.max_pozycja_w_module[modul].Max < pozycja) {
      this.max_pozycja_w_module[modul].Max = pozycja;
    }
  }

  public czySkonczone() {
    let skonczone = true;
    this.grupa_modul.forEach(element => {
      if (!(this.tresci.user.IsTrader
        || element.pozycja[element.pozycja.length - 1].WartoscKod('Moduł') === 'P1'
        || element.pozycja[element.pozycja.length - 1].WartoscKod('Moduł') === 'DP1'
        || element.pozycja[element.pozycja.length - 1].WartoscKod('Moduł') === '11')) {
        skonczone = false;
      }
    });
    if (this.IsModule) {
      return skonczone;
    } else {
      return true;
    }
  }

  public wczytajZamowienieZLoc() {
    this.parametry_wczytwane_licznik = 0;
    const param = deserialize(ZamownienieKontener, this.tresci.odczytaj('zamowneinie_' + this.tresci.system));
    const paramKim = deserialize(ZamownienieKontener, this.tresci.odczytaj('zamowneinie_Kim_' + this.tresci.system));
    this.strukt = deserialize(ZamownienieKontener, this.tresci.odczytaj('zamowneinie_strukt_' + this.tresci.system));

    this.tresci.user.ustawDostawa(this.tresci.odczytaj('zamowieninie_dostawa_' + this.tresci.system),
    this.tresci.odczytaj('zamowieninie_adres_' + this.tresci.system));
    if (param != null) {
      this.parametryWczytane = param.parametry;
      this.istnieja_zapisane = true;
    } else {
      this.parametryWczytane = [];
    }
    if (paramKim != null) {
      this.parametryWczytaneKim = paramKim.parametry;
    } else {
      this.parametryWczytaneKim = [];
    }

    this.ParametryTMP = this.parametryWczytane;
    this.ParametryKimTMP = this.parametryWczytaneKim;
    this.korektaParametrow();
    this.parametryWczytane = this.ParametryTMP;
    this.parametryWczytaneKim = this.ParametryKimTMP;
    const parametryNaglowka = [];
    this.parametryWczytane.forEach(element => {
      if (element.Module === 0 && element.Position === 0) {
        parametryNaglowka.push(element);
      }
      this.ile_mod_max(element.Module, element.Position);
    });
    const parametryNaglowkaKim = [];
    this.parametryWczytaneKim.forEach(element => {
      if (element.Module === 0 && element.Position === 0) {
        parametryNaglowkaKim.push(element);
      }
      this.ile_mod_max(element.Module, element.Position);
    });
    this.parametryNaglowka = parametryNaglowka;
    this.parametryNaglowkaKim = parametryNaglowkaKim;
    // if (this.tresci.CacheTech) {
    //   this.wczytajZStrukt();
    // } else {
    this.naglowek.wczytajKrokZparametrami(0, parametryNaglowka, null, null, parametryNaglowkaKim);
    // }
    // this.naglowek.wczytajKrokZparametramiLoc(0, parametryNaglowka, null, null, parametryNaglowkaKim);
    this.istnieja_zapisane = true;
  }

  public wczytajZStrukt() {
    this.IsFooter = this.strukt.parametry[3].IsFooter;
    this.IsModule = this.strukt.parametry[3].IsModule;
    this.IsModuleFooter = this.strukt.parametry[3].IsModuleFooter;
    let last = true;
    if (this.strukt.parametry[1].lenght > 0 && this.strukt.parametry[1][0].lenght > 0 && this.strukt.parametry[1][1].lenght > 0) {
      last = false;
    }
    this.naglowek.setStrukt(this.strukt.parametry[0], 0, 0, this.parametryNaglowka, this.parametryNaglowkaKim);
    for (let index = 0; index < this.strukt.parametry[1].length; index++) {
      const modul = new GrupaModul(this.tresci, this.grupa_modul.length + 1, this.naglowek.LastStep(), this.naglowek.getParams());
      const param = [];
      this.parametryWczytane.forEach(element => {
        if (index + 1 === element.Module) {
          param.push(element);
        }
      });
      const paramKim = [];
      this.parametryWczytaneKim.forEach(element => {
        if (index + 1 === element.Module) {
          paramKim.push(element);
        }
      });
      modul.setStrukt(this.strukt.parametry[1][index], param, paramKim);
      this.grupa_modul.push(modul);

    }
    if (this.IsFooter) {
      this.stopka.setStrukt(this.strukt.parametry[2], 0, 0, this.parametryNaglowka, this.parametryNaglowkaKim);
    }
  }

  public zapisz_Parametry() {
    const Parametry = this.getParamsSoftWybrane();
    const ParametryKim = this.getParamsKim();
    // const Parametry = this.getParams();
    // const ParametryKim = this.getParamsKim();

    const param = new ZamownienieKontener(Parametry);
    const param_kim = new ZamownienieKontener(ParametryKim);
    this.tresci.zapisz('zamowneinie_' + this.tresci.system, serialize(param));
    this.tresci.zapisz('zamowneinie_Kim_' + this.tresci.system, serialize(param_kim));
    this.tresci.zapisz('zamowneinie_tmp_' + this.tresci.system, serialize(param));
    this.tresci.zapisz('zamowneinie_Kim_tmp_' + this.tresci.system, serialize(param_kim));
    this.zapiszStruktureZamowienia();
    this.istnieja_zapisane = true;
  }

  public zapiszStruktureZamowienia() {
    const nag = this.naglowek.getStrukt();
    let stopka = [];
    if (this.stopka != null) {
      stopka = this.stopka.getStrukt();
    }

    const moduly = [];
    this.grupa_modul.forEach(element => {
      moduly.push(element.getStrukt());
    });
    const zam = [];
    zam.push(nag);
    zam.push(moduly);
    zam.push(stopka);
    zam.push({ isModule: this.IsModule, IsFooter: this.IsFooter, IsModuleFooter: this.IsModuleFooter });
    const zapisz_strukt = new ZamownienieKontener(zam);
    this.tresci.zapisz('zamowienie_strukt_' + this.tresci.system, serialize(zapisz_strukt));
  }

  public wyczysc_Parametry() {
    this.tresci.user.dostawa_otwarte = false;
    this.tresci.user.dostawa_aktywne = true;

    this.tresci.zapisz('zamowneinie_' + this.tresci.system, '');
    this.tresci.zapisz('zamowneinie_Kim_' + this.tresci.system, '');
  }
  public WpiszParametry() {

    const ProduktPrefix = this.tresci.getPrefix();
    const Parametry = this.getParams();
    const ParametryKim = this.getParamsKim();
    let ContractorNumber = this.tresci.user.ContractorNumber;
    let ContractorName = this.tresci.user.ContractorName;
    /*if (ContractorName == null) {
      ContractorName = 'Selt sp. z o.o.';
    }
    if (ContractorNumber == null) {
      ContractorNumber = '79561';
    }*/

    let body = null;
    body = {
      'ProductPrefix': ProduktPrefix,
      'Parameters': Parametry,
      'ContractorNumber': ContractorNumber,
      'ContractorName': ContractorName,
      'CategoryProduct': this.tresci.system,
      'Id': this.tresci.id,
      'ParameterExtensions': ParametryKim
    };

    const dane = serialize(body);
    // tslint:disable-next-line:max-line-length

    const request = new HttpRequest('POST',
        '/api/ActiveForm/UpdateActiveFormOrder',
      dane,
      { headers: this.tresci.user.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            // if (this.tresci.komponent === 'OrderDesktop') {
            this.tresci.zmienStrone('OrderDesktop', 'Podziekowanie');
          // } else {
          // this.tresci.zmienStrone('Orders', 'Orders');
          // }
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.tresci.exception.exception(this.tresci.exception.error(err), err);
        }
      });
  }
  public przywroc_parametry() {
    this.tresci.zapisz('zamowneinie_' + this.tresci.system, this.tresci.odczytaj('zamowneinie_tmp_' + this.tresci.system));
    this.tresci.zapisz('zamowneinie_Kim_' + this.tresci.system, this.tresci.odczytaj('zamowneinie_Kim_tmp_' + this.tresci.system));
    window.location.reload();
  }

  public wczytajGrupyModulow() {
    let parametryW = [];
    let parametryWKim = [];
    let currentMod = 0;

    this.parametryWczytane.forEach(element => {
      if (element.Module > 0) {
        if (element.Module === currentMod) {
          parametryW.push(element);
        } else {
          if (currentMod > 0) {
            if (this.grupa_modul.length < currentMod) {
              // tslint:disable-next-line:max-line-length
              this.grupa_modul.push(new GrupaModul(this.tresci, currentMod, this.naglowek.LastStep(), this.naglowek.getParams(), parametryW, parametryWKim));
            } else {
              // tslint:disable-next-line:max-line-length
              this.grupa_modul[currentMod - 1] = new GrupaModul(this.tresci, currentMod, this.naglowek.LastStep(), this.naglowek.getParams(), parametryW, parametryWKim);
            }
          }
          parametryW = [];
          parametryW.push(element);
          currentMod = element.Module;
          parametryWKim = [];
          this.parametryWczytaneKim.forEach(elementKim => {
            if (elementKim.Module === currentMod) {
              parametryWKim.push(elementKim);
            }
          });
        }
      }
    });

    if (currentMod > 0) {
      if (this.grupa_modul.length < currentMod) {
        // tslint:disable-next-line:max-line-length
        this.grupa_modul.push(new GrupaModul(this.tresci, currentMod, this.naglowek.LastStep(), this.naglowek.getParams(), parametryW, parametryWKim));
      } else {
        // tslint:disable-next-line:max-line-length
        this.grupa_modul[currentMod - 1] = new GrupaModul(this.tresci, currentMod, this.naglowek.LastStep(), this.naglowek.getParams(), parametryW, parametryWKim);
      }
    }
    this.CurrentModule = 0;
  }


  public wczytajZamowienie(id) {
    const body = {
      'Id': id
    };
    const dane = serialize(body);
    // tslint:disable-next-line:max-line-length

    const request = new HttpRequest('POST',
        '/api/ActiveForm/GetParameters',
      dane,
      { headers: this.tresci.user.getHeaderJson() });
    this.tresci.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.tresci.user.ustawDostawa(event.body.DeliveryMethodId, event.body.DeliveryAddressId);
            this.parametryWczytane = event.body.Parameters;
            this.ListOfSurcharge = <ISurcharge[]>event.body.Surcharges;
            const parametryNaglowka = [];
            this.tresci.user.dostawa_aktywne = false;
            this.parametryWczytane.forEach(element => {
              if (element.Module === 0 && element.Position === 0) {
                parametryNaglowka.push(element);
              }
              this.ile_mod_max(element.Module, element.Position);
            });

            this.parametryWczytaneKim = event.body.ParameterExtensions;
            const parametryNaglowkaKim = [];
            this.parametryWczytaneKim.forEach(element => {
              if (element.Module === 0 && element.Position === 0) {
                parametryNaglowkaKim.push(element);
              }
              this.ile_mod_max(element.Module, element.Position);
            });
            this.parametryNaglowka = parametryNaglowka;
            this.parametryNaglowkaKim = parametryNaglowkaKim;
            this.naglowek.wczytajKrokZparametrami(0, parametryNaglowka, null, null, parametryNaglowkaKim);
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
        }
      });
  }


  public cenaSuma() {
    let cena = 0;
    this.grupa_modul.forEach(element => {
      cena += element.cenaSuma();
    });
    return cena;
  }
  public liczCeny() {
    this.grupa_modul.forEach(element => {
      element.LiczCeny();
    });
  }
  public czyStopkaPelna() {
    if (this.stopka !== null && this.stopka != null && this.stopka.Krok != null && this.stopka.Krok.length > 0) {
      return true;
    } else {
      return false;
    }
  }

}

