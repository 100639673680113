
<h1 id="braking" [translation]="TranslationEnum.TranslationForm">
    {{ 'braking' | translate }}
</h1>
<div class="container ">
    <div id="opisbraki" [translation]="TranslationEnum.TranslationForm">
        {{ 'opisbraki' | translate }}
    </div>
    <div class="tabela_brakow">
        <table border="1">
            <tr>
                <td class="ng"><span id="lpno" [translation]="TranslationEnum.TranslationForm">{{ 'lpno' | translate }}</span></td>
                <td class="ng"><span id="numerbraku" [translation]="TranslationEnum.TranslationForm">{{ 'numerbraku' | translate }}</span></td>
                <td class="ng"><span id="nazwabraku" [translation]="TranslationEnum.TranslationForm">{{ 'nazwabraku' | translate }}</span></td>
                <td class="ng"><span id="stanbraku" [translation]="TranslationEnum.TranslationForm">{{ 'stanbraku' | translate }}</span></td>
            </tr>
            <ng-container  *ngFor="let brak of braki; let b = index;">
                <tr>
                    <td>{{b + 1}}</td>
                    <td>{{brak.Code}}</td>
                    <td>{{brak.Name}}</td>
                    <td class="stantd"><div class="brak" *ngIf="brak.Quantity <= brak.Availability0"></div><div class="brak2" *ngIf="brak.Quantity > brak.Availability0"></div></td>
                </tr>
            </ng-container>
            
        </table>
        <div class="pospis"><span class="brak2"></span><span id="opisbrak2" [translation]="TranslationEnum.TranslationForm">{{ 'opisbrak2' | translate }}</span></div>
        <div class="pospis"><span class="brak"></span><span id="opisbrak1" [translation]="TranslationEnum.TranslationForm">{{ 'opisbrak1' | translate }}</span></div>
        
    </div>
</div>
