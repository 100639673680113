<h2 mat-dialog-title>
  <span id="uwagi" [translation]="TranslationEnum.Translation">{{'uwagi'|translate}}</span>
  <button mat-icon-button color="primary" class="float-right mr-3" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content class="pt-3 pb-3 pr-4 pl-4">
  <div class="w-100 overflow-auto">
    <table mat-table [dataSource]="DataSource">

      <!-- FileName Column -->
      <ng-container matColumnDef="FileName">
        <th mat-header-cell *matHeaderCellDef id="nazwa_pliku" [translation]="TranslationEnum.Translation">
          {{ "nazwa_pliku"|translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{ row.FileName }}</td>
      </ng-container>

      <!-- Attachment Column -->
      <ng-container matColumnDef="Attachment">
        <th mat-header-cell *matHeaderCellDef id="zalacznik" [translation]="TranslationEnum.Translation">
          {{ "zalacznik"|translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-icon style="cursor: pointer;"
            (click)="downloadAttachment('api/OrderRegister/GetFileToDownload?guid=' + row.FileId + '&culture=' + Culture, row.FileName)">
            file_download</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="DisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: DisplayedColumns;"></tr>
    </table>
  </div>
</mat-dialog-content>
