import { Component, OnInit, Input, ViewChild, AfterContentChecked, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TresciService } from './../tresci.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { Kroki } from '../pomocnicze/kroki';
import { Kim } from '../pomocnicze/kim';
import { TechnologiaResultInfoComponent } from '../technologiaResultInfo/technologiaResultInfo.component';
import { UniversalDialogComponent } from '../shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from '../shared/models/dialog/dialog-data.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-konfiguratorform',
  templateUrl: './konfiguratorform.component.html',
  styleUrls: ['./konfiguratorform.component.scss']
})

export class KonfiguratorformComponent implements OnInit, OnDestroy {

  @Input() Kroki: Kroki;
  // TechnologiaComponent: TechnologiaComponent;
  isPreviewTech = false;
  isShowResults: boolean;
  isShowErrors: boolean;

  private _searchColumn: string;
  private _searchGroup: number;
  private _condition: string;
  private _chosen: boolean;
  public image_height = 0;
  public isHidden: boolean;
  public indexStep: number;
  private subscriptions: Subscription[] = [];
  @ViewChild(TechnologiaResultInfoComponent, {static: true}) technology;

  constructor(
    public tresci: TresciService,
    public user: UserService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
  }
  // this.TechnologiaComponent = new TechnologiaComponent(this.tresci, this.user, this.route);
  ngOnDestroy() {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit() {
    this.searchColumnTerm = 'Kod katalogowy';
    this.searchGroupTerm = 0;
    this.conditionTerm = '';
    this.chosenTerm = false;
    this.subscriptions.push(this.Kroki.$HiddenStep.subscribe(x => {
      if (x) {
        this.Kroki.dalej((this.Kroki.StepForHidden + 1), true);
      }
    }));
  }



  get searchGroupTerm(): number { return this._searchGroup; }
  set searchGroupTerm(value: number) { this._searchGroup = value; }

  get searchColumnTerm(): string { return this._searchColumn; }
  set searchColumnTerm(value: string) { this._searchColumn = value; }

  get conditionTerm(): string { return this._condition; }
  set conditionTerm(value: string) { this._condition = value; }

  get chosenTerm(): boolean { return this._chosen; }
  set chosenTerm(value: boolean) { this._chosen = value; }

  setSelected(selectedItem: any) {
  }

  // public test(event, kontr) {
  // }

  public getImage(index: string) {
    this.user.pobierzObrazekDoAkcesorii(index);
  }

  applyFilter(kim: Kim) {
    kim.filtruj_po(this.searchGroupTerm, this.searchColumnTerm, this.conditionTerm, this.chosenTerm);
  }

  public showTechnology(numer, nastepny = true) {
    this.isPreviewTech = true;
    this.technology.showTechnology(numer, nastepny);
    // this.TechnologiaComponent.showTechnology(numer, nastepny);
  }
  public onImageLoad(e) {
    this.image_height = e.target.height;
  }

  // testCall(krok: any) {
  // }
  czyWybrany(kontr) {
    if (kontr.InputNumber.Value !== '') {
      kontr.nie_wybrany = false;
    } else {
      kontr.nie_wybrany = true;
    }
  }

  trackByFn(index, item) {
    return item;
 }

   editQuestionDialog(numer, nastepny, krok) {
     if (krok.modul === 1 && krok.pozycja < 1) {
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: 'czy_na_pewno_chcesz_kontynuowac',
        content: 'edytujac_utracisz_zestawy_oraz_pozycje',
        answerOk: false
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.Kroki.dalej(numer, nastepny, true);
        }
      }));
    } else {
      this.Kroki.dalej(numer, nastepny, true);
    }
  }

 checkIfFileAdded(control: any) {
  if (control.ControlType === 'CheckFile' && !control.fileControl.Check) {
    const indexToSplice = this.tresci.zamowienie.fileControls.findIndex(x => x.Id === control.fileControl.FileId);
    this.tresci.zamowienie.fileControls.splice(indexToSplice, 1);
    control.fileControl.FileName = '';
  }
 }
}
