import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'PriceFormat' })
export class PriceFormatPipe implements PipeTransform {
  transform(numer: number) {
    numer = Math.round(numer * 100) / 100.0;
    let cena = numer + '';
    if (numer % 1 === 0) {
      cena += '.0';
    }
    if (numer * 10 % 1 === 0) {
      cena += '0';
    }
    for (let i = 2; (i * 3) + 1 <= cena.length; i++) {
      cena = cena.substr(0, cena.length - (i * 3) - (i - 2)) + ' ' + cena.substr(cena.length - (i * 3) - (i - 2));
    }
    return cena;
  }
}
