
<div class="box_out">
    <div class="box">
        <div class="box_dodaj">
            <h2 mat-dialog-title id="dodaj_adres" [translation]="TranslationEnum.TranslationForm">
                {{'dodaj_adres'|translate}}
            </h2>


            <div class="dodaj_box">
                <div class="pole_add blad_{{address_b}}" >
                    <div clss="opis"><span id="address" [translation]="TranslationEnum.TranslationForm">
                        {{'address'|translate}}
                    </span></div>
                    <div clss="pole"><input type ="text" [(ngModel)]= "address"></div>
                </div>
                <div class="pole_add blad_{{zipcode_b}}">
                    <div clss="opis"><span id="zipcode" [translation]="TranslationEnum.TranslationForm">
                        {{'zipcode'|translate}}
                    </span></div>
                    <div clss="pole"><input type ="text" [(ngModel)]= "zipcode"></div>
                </div>
                <div class="pole_add blad_{{city_b}}">
                    <div clss="opis"><span id="city" [translation]="TranslationEnum.TranslationForm">
                        {{'city'|translate}}
                    </span></div>
                    <div clss="pole"><input type ="text" [(ngModel)]= "city"></div>
                </div>
                <div class="pole_add blad_{{country_b}}">
                    <div clss="opis"><span id="country" [translation]="TranslationEnum.TranslationForm">
                        {{'country'|translate}}
                        </span>
                    </div>
                    <div clss="pole">
                        <div class="CheckSelect_selkect">
                            <form [formGroup]="form">
                                <mat-form-field>
                                    <mat-select (selectionChange) = "changeSelect($event)" formControlName="state">
                                        <ng-container  *ngFor="let v of kraje; let kk = index;">
                                            <mat-option [value]="v.Id">{{v.Name}}</mat-option>
                                        </ng-container>
                                      
                                    </mat-select>
                                  </mat-form-field>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="pole_add blad_{{comment_b}}">
                    <div clss="opis"><span id="comment" [translation]="TranslationEnum.TranslationForm">
                        {{'comment'|translate}}
                    </span></div>
                    <div clss="pole"><input type ="text" [(ngModel)]= "comment"></div>
                </div>
            </div>

            <button mat-flat-button color="primary"  (click) = "dodajAdres()" class="float-left" 
                type="submit">
                <span id="dodaj_adres" [translation]="TranslationEnum.TranslationForm">
                    {{'dodaj_adres'|translate}}
                </span>
                </button>
        </div>
        <div class="box_usun">
            <h2 mat-dialog-title id="usun_adres" [translation]="TranslationEnum.TranslationForm">
                {{'usun_adres'|translate}}
            </h2>
            <div class="box_usun_scroll">
                <ng-container *ngFor="let adres of this.data.ContractorAddresses; let m = index;">

                    <div *ngIf = "adres.OtherTransportThenSelt" class="adres_do_usuniecia">{{adres.Address}} {{adres.ZipCode}} {{adres.City}} {{adres.Country}} {{adres.Comment}} <span class="usun_adres_button" (click) = "usun(adres.Id)"><mat-icon >delete</mat-icon></span></div>
                </ng-container>
            </div>
           
        </div>
    </div>
    <div mat-dialog-actions class="close">
        <mat-icon [mat-dialog-close]="true">disabled_by_default</mat-icon>
    </div>
    <div class="wczytywanie2" *ngIf = "wczytywanie">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </div>
</div>


<div mat-dialog-actions>
    <div class="w-100">
      <button mat-flat-button id="akceptuj" class="btn-sm float-right" [translation]="TranslationEnum.Translation"
        color="primary" [mat-dialog-close]="true">
        {{'akceptuj'|translate}}
      </button>
    </div>
  </div>

