import { Component, OnInit, Input, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { TresciService } from '../tresci.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { Kroki } from '../pomocnicze/kroki';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

enum WhichButton {
  Params= 'Params',
  CountingParams= 'CountingParams',
  ProgramParams= 'ProgramParams',
  TabParams= 'TabParams',
  Errors= 'Errors',
  Results= 'Results'
}

@Component({
  selector: 'app-technologiaresultinfo',
  templateUrl: './technologiaResultInfo.component.html',
  styleUrls: ['./technologiaResultInfo.component.scss']
})
export class TechnologiaResultInfoComponent implements OnInit, OnChanges {

  @Input() ResultInfo: any;

  public IsPreviewTech = false;
  public IsLoaded = false;
  // public ResultInfo: any;
  public ActiveButton = '';
  public IsShowResults: boolean;
  private nodes = [];

  paramsOnWeb: any;
  tabs: [];
  indexNode = 0;
  stateButton: WhichButton;



  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();

  constructor(public tresci: TresciService, public user: UserService, private route: ActivatedRoute) {
  }


  ngOnInit() {
  }

  ngOnChanges(): void {
    this.IsLoaded = false;
    this.nodes = [];
    if (this.ResultInfo !== undefined && this.ResultInfo !== null ) {
      this.IsLoaded = true;
      this.fillNodes();
      this.IsPreviewTech = true;
    }
  }

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;


  public showTechnology() {
    this.IsPreviewTech = true;
    this.nodes = [];
   // this.delay(5000).then( any => this.fillNodes());
  }

  async delay(ms: number) {
    await new Promise( resolve => setTimeout(resolve, ms) );
  }



  public fillNodes() {
    // this.Kroki.wczytywanie = false;
   // this.ResultInfo = this.ResultInfoStep
    if (this.ResultInfo) {
      this.IsLoaded = true;
    }
    this.nodes.push({
      id: this.indexNode++,
      name: this.ResultInfo.MasterResult.Name,
      children: [],
      result:
        {
          WynikiExist : this.ResultInfo.MasterResult.WynikiExist,
          WynikiTab : this.ResultInfo.MasterResult.WynikiTab,
          Parametry : this.ResultInfo.MasterResult.Parametry,
          ParametryWyliczeniowe : this.ResultInfo.MasterResult.ParametryWyliczeniowe,
          ParametryZProgramu : this.ResultInfo.MasterResult.ParametryZProgramu,
          ParametryTabelaryczne : this.ResultInfo.MasterResult.ParametryTabelaryczne,
          KompilacjaBledy: this.ResultInfo.MasterResult.KompilacjaBledy
        }
      ,
    });

    this.createTree(this.nodes[0], this.ResultInfo.MasterResult.FunctionResults);

    this.createTree(this.nodes[0], this.ResultInfo.ComponentsResults);
   // this.tree.treeModel.update();
    this.dataSource.data = this.nodes;
  }

  public createTree(node: any, results: any[]) {
    let i = 0;

    results.forEach(result => {
      node.children.push({ id: this.indexNode++, name: result.NameWithPrefix, result: result, children: [] });
      this.createTree(node.children[i], result.FunctionResults);
      i++;
    });
  }
  public showParams(params: any, stateButton: WhichButton) {
    this.paramsOnWeb = params;
    this.stateButton = stateButton;
    this.IsShowResults = false;
    this.ActiveButton = stateButton;
  }

  public showResults(stateButton: WhichButton) {
    this.IsShowResults = true;
    this.ActiveButton = stateButton;
  }

  public selectNode(activeNode: any) {
    this.ResultInfo = activeNode.result;
    let detailsDiv = document.querySelector('div#detailsResult').innerHTML;

    switch (this.stateButton) {
      case WhichButton.Params:
          detailsDiv = '';
          this.IsShowResults = false;
          this.paramsOnWeb = this.ResultInfo.Parametry;
        break;

      case WhichButton.CountingParams:
          detailsDiv = '';
          this.IsShowResults = false;
          this.paramsOnWeb = this.ResultInfo.ParametryWyliczeniowe;
      break;

      case WhichButton.ProgramParams:
          detailsDiv = '';
          this.IsShowResults = false;
          this.paramsOnWeb = this.ResultInfo.ParametryZProgramu;
       break;

       case WhichButton.TabParams:
          detailsDiv = '';
          this.IsShowResults = false;
          this.paramsOnWeb = this.ResultInfo.ParametryTabelaryczne;
        break;

        case WhichButton.Errors:
            detailsDiv = '';
          this.IsShowResults = false;
          this.paramsOnWeb = this.ResultInfo.KompilacjaBledy;
          break;

        default:
          this.paramsOnWeb = this.ResultInfo.Parametry;
          this.ActiveButton = WhichButton.Params;
          break;
    }
  }

  public closeTech(isClose: any) {
    this.IsPreviewTech = isClose;
    this.ResultInfo = [];
    this.paramsOnWeb = [];
    // for (const prop in this.ResultInfo) {
    //   if (this.ResultInfo.hasOwnProperty(prop)) {
    //     this.ResultInfo[prop] = null;
    //   }
    // }
    this.nodes = null;
  }
}
