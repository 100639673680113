import { FileControl } from '../../form/file-control.model';
import { NotAffectToOtherControls } from './../../../enums/not-affect-to-controls-enum';
export class FormControls {
  Controlls: TechnologyControl[] = new Array<TechnologyControl>();
  IsFooter: boolean;
  IsModule: boolean;
  IsModuleFooter: boolean;
  Step: string;
  Steps: Step[];
  TechVersion: number;
  Type: string;
  ValuationVersion: number;
  TechName: string;
}

class Step {
  Name: string;
  Controlls: TechnologyControl[] = new Array<TechnologyControl>();
}

export class Parameter {
  Key: any = 'X';
  Value: any;
  Position?: number;
  Module?: number;
  Type?: any;
  Disabled?: boolean;
  IsPrint?: boolean;
  DisplayValue?: any;
  DisplayValuePl?: any;
  DisplayValueEn?: any;
  DisplayValueDe?: any;
  DisplayValueFr?: any;
  DisplayValueCs?: any;
  DisplayValueIt?: any;
  File?: any;
  FileName?: string;
  IsHidden?: boolean;
  ControlName?: any;
  Kolor?: string;
}

export class TechnologyControl {
  ControlType: string;
  KeyName: string;
  // Description: string;
  TranslateCode: string;
  DefaultValue: any;
  Values?: any[];
  ValuesMultiColumn?: any;
  Parameters?: Parameter[];
  Width: number;
  DisplayInTablePosition: boolean;
  // ParameterPosition: number;
  IsPrint: boolean;
  FileInfoId?: string;
  NotAffectToOtherControls: NotAffectToOtherControls;
  PictureWidth: number;
  Required: boolean;
  ForegrandColour: string;
  BackgroundColour: string;
  IsEnable: boolean;
}

export class ManageModule {
  IsFooter: boolean;
  IsModule: boolean;
  IsModuleFooter: boolean;
}

