<h2 matDialogTitle>
  <span *ngIf="this.data.modalForEnum === modalForEnum.OrderMany" id="czy_na_pewno_zamowic"
    [translation]="TranslationEnum.Translation">
    {{'czy_na_pewno_zamowic'|translate}}</span>
  <!-- <app-tlumacz [hidden]="this.data.modalForEnum !== modalForEnum.OrderMany" [id]="'Czy_na_pewno_zamowic'"[typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
  <span *ngIf="this.data.modalForEnum === modalForEnum.PayMany" id="czy_na_pewno_chcesz_dokonać_płatności_zbiorczej"
    [translation]="TranslationEnum.Translation">
    {{'czy_na_pewno_chcesz_dokonać_płatności_zbiorczej'|translate}}</span>
  <!-- <app-tlumacz [hidden]="this.data.modalForEnum !== modalForEnum.PayMany" [id]="'Czy na pewno chcesz dokonać płatności zbiorczej'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
</h2>
<div mat-dialog-content>
  <table mat-table [(dataSource)]="data.tableData" multiTemplateDataRows class="mat-elevation-z8 mt-2 mb-3">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [hidden]="row.PurchasePermit === 2">
        </mat-checkbox>
      </td>
    </ng-container>


    <!-- B2BId Column -->
    <ng-container matColumnDef="B2BId">
      <th mat-header-cell *matHeaderCellDef id="b2bid" [translation]="TranslationEnum.Translation">
        {{'b2bid'|translate}}
        <!-- <app-tlumacz [id]="'B2Bid'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row"> {{row.B2BId}} </td>
    </ng-container>

    <!-- Product Column -->
    <ng-container matColumnDef="Product">
      <th mat-header-cell *matHeaderCellDef id="produkt" [translation]="TranslationEnum.Translation">
        {{'produkt'|translate}}
        <!-- <app-tlumacz [id]="'Produkt'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row" id="produkt_{{row.Product}}" [translation]="TranslationEnum.Translation">
        {{'produkt_' + row.Product|translate}}
        <!-- <app-tlumacz [id]="'produkt_' + row.Product" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </td>
    </ng-container>

    <!-- System Column -->
    <ng-container matColumnDef="System">
      <th mat-header-cell *matHeaderCellDef id="system" [translation]="TranslationEnum.Translation">
        {{'system'|translate}}
        <!-- <app-tlumacz [id]="'System'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row" id="system_{{row.System}}" [translation]="TranslationEnum.Translation">
        {{'system_' + row.System|translate}}
        <!-- <app-tlumacz [id]="'system_' + row.System" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </td>
    </ng-container>

    <!-- TotalCost Column -->
    <ng-container matColumnDef="ValNetBrut">
      <th mat-header-cell *matHeaderCellDef id="cena_razem" [translation]="TranslationEnum.Translation">
        {{'cena_razem'|translate}}
        <!-- <app-tlumacz [id]="'Cena_razem'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row" class="cena">
        <ng-container *ngIf="row.ValueNetto !== null && row.Currency !== null && row.ValueNetto > 0">
          {{row.ValueNetto | PriceFormat}}&nbsp;
          {{row.Currency}} <br/>
          {{row.ValueBrutto | PriceFormat}}&nbsp;
          {{row.Currency}}
        </ng-container>
        <ng-container *ngIf="row.ValueNetto !== null && row.Currency !== null && row.ValueNetto == 0">
          ---
        </ng-container>
      </td>
    </ng-container>


    <ng-container matColumnDef="Expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="text-right">
        <mat-icon [hidden]="row.PurchasePermit === 1" class="animate">
          {{ expandedElement !== row ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
        </mat-icon>
        <p [hidden]="row.PurchasePermit !== 1" id="dostępny" [translation]="TranslationEnum.Translation">
          {{'dostępny'|translate}}
          <!-- <app-tlumacz [id]="'Dostępny'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="border"
        [class.border-warning]="element.PurchasePermit === PurchasePermitsEnum.Question" [class.border-danger]="element.PurchasePermit === PurchasePermitsEnum.NotAvailable">
        <div *ngIf="element.PurchasePermit !== PurchasePermitsEnum.Available" class="example-element-detail"
          [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <table mat-table [dataSource]="element.OrderWarnings" class="mat-elevation-z8" width="100%">

            <ng-container matColumnDef="WarningName">
              <th mat-header-cell *matHeaderCellDef id="status_dostępności"
                [translation]="TranslationEnum.Translation">
                {{'status_dostępności'|translate}}
                <!-- <app-tlumacz [id]="'Status dostępności'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </th>
              <td mat-cell *matCellDef="let element" [id]="element.WarningName"
                [translation]="TranslationEnum.Translation"> {{element.WarningName |translate }} </td>
            </ng-container>

            <ng-container matColumnDef="WarningDescription">
              <th mat-header-cell *matHeaderCellDef id="komentarz" [translation]="TranslationEnum.Translation">
                {{'komentarz'|translate}}
                <!-- <app-tlumacz [id]="'Komentarz'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </th>
              <td mat-cell *matCellDef="let element" [id]="element.WarningDescription"
                [translation]="TranslationEnum.Translation"> {{element.WarningDescription|translate}} </td>
            </ng-container>

            <ng-container matColumnDef="ParameterName">
              <th mat-header-cell *matHeaderCellDef id="parametr" [translation]="TranslationEnum.Translation">
                {{'parametr'|translate}}
                <!-- <app-tlumacz [id]="'Parametr'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </th>
              <td mat-cell *matCellDef="let element"> {{element.ParameterName}} </td>
            </ng-container>

            <ng-container matColumnDef="ParameterValue">
              <th mat-header-cell *matHeaderCellDef id="wartość_parametru" [translation]="TranslationEnum.Translation">
                {{'wartość_parametru'|translate}}
                <!-- <app-tlumacz [id]="'Wartość parametru'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </th>
              <td mat-cell *matCellDef="let element"> {{element.ParameterValue}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="expandDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: expandDisplayedColumns;"></tr>
          </table>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row border"
      [ngClass]="{'bg-warning border-warning': element.PurchasePermit === 3, 'bg-danger border-danger': element.PurchasePermit === 2}"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element;">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>

</div>
<mat-dialog-actions>
  <form *ngIf="this.data.modalForEnum === modalForEnum.OrderMany" [formGroup]="orderTerms"
    (submit)="orderMany(orderTerms)" #orderTermsForm="ngForm" style="width:100%" [hidden]="!canOrder">
    <div class="float-left">
      <mat-checkbox [formControl]="valuation" id="potwierdzam_wycen_zamówień"
        [translation]="TranslationEnum.Translation" required>
          {{'potwierdzam_wycen_zamówień'|translate}}
        <!-- <app-tlumacz [id]="'Potwierdzam wycenę zamówień.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </mat-checkbox>
      <br />
      <mat-checkbox [formControl]="specification" id="potwierdzam_specyfikację_zamówień"
        [translation]="TranslationEnum.Translation" required>
        {{'potwierdzam_specyfikację_zamówień'|translate}}
        <!-- <app-tlumacz [id]="'Potwierdzam specyfikację zamówień.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </mat-checkbox>
      <br />
      <mat-checkbox *ngIf="this.dataSource.data.length > 1" [formControl]="proform" id="zgadzam_sie_proforma"
        [translation]="TranslationEnum.Translation" required>
        <mat-checkbox-label style="white-space: pre-wrap;">
        {{'zgadzam_sie_proforma'|translate}}
        </mat-checkbox-label>
        <!-- <app-tlumacz [id]="'Potwierdzam specyfikację zamówień.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </mat-checkbox>
      <br/>
      <mat-error *ngIf="(valuation.invalid || specification.invalid) && orderTermsForm.submitted"
        id="musisz_zaakceptować_wszystkie_warunki" [translation]="TranslationEnum.Translation">
        {{'musisz_zaakceptować_wszystkie_warunki'|translate}}
        <!-- <app-tlumacz [id]="'Musisz zaakceptować wszystkie warunki'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </mat-error>
    </div>
    <button mat-flat-button id="anuluj" class="float-right" [translation]="TranslationEnum.Translation" color="primary"
      mat-dialog-close>
      {{'anuluj'|translate}}
      <!-- <app-tlumacz [id]="'Anuluj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>
    <button mat-flat-button id="zamów" class="float-right mr-3" [translation]="TranslationEnum.Translation" color="primary"
      [disabled]="this.selection.selected.length === 0" type="sumbit">
      {{'zamów'|translate}}
      <!-- <app-tlumacz [id]="'zamów'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>
  </form>
  <div class="w-100" *ngIf="this.data.modalForEnum === modalForEnum.PayMany">
    <button mat-flat-button id="anuluj" class="float-right" [translation]="TranslationEnum.Translation" color="primary"
      mat-dialog-close>
      {{'anuluj'|translate}}
      <!-- <app-tlumacz [id]="'Anuluj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>
    <button mat-flat-button id="wygeneruj_proformę" class="float-right mr-3" [translation]="TranslationEnum.Translation"
      color="primary" [disabled]="this.selection.selected.length === 0" (click)="payMany()">
      {{'wygeneruj_proformę'|translate}}
      <!-- <app-tlumacz [id]="'Wygeneruj proformę'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>
  </div>
  <div class="w-100" [hidden]="canOrder">
    <p class="float-left">
      <span *ngIf="this.data.modalForEnum === modalForEnum.OrderMany" id="nie_można_zamówić_żadnej_z_powyższych_pozycji"
        [translation]="TranslationEnum.Translation">
        {{'nie_można_zamówić_żadnej_z_powyższych_pozycji'| translate}}</span>
      <!-- <app-tlumacz [hidden]="this.data.modalForEnum !== modalForEnum.OrderMany"[id]="'Nie można zamówić żadnej z powyższych pozycji.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      <span *ngIf="this.data.modalForEnum === modalForEnum.PayMany"
        id="brak_możliwości_dokonania_wpłaty_dla_powyższych_pozycji" [translation]="TranslationEnum.Translation">
        {{'brak_możliwości_dokonania_wpłaty_dla_powyższych_pozycji'| translate}}</span>
      <!-- <app-tlumacz [hidden]="this.data.modalForEnum !== modalForEnum.PayMany"[id]="'Brak możliwości dokonania wpłaty dla powyższych pozycji.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </p>
    <button mat-flat-button id="ok" class="float-right" [translation]="TranslationEnum.Translation" color="primary"
      mat-dialog-close>
      {{'ok'|translate}}</button>
  </div>
</mat-dialog-actions>
