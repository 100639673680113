import { Component, TemplateRef, ViewChild, } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { FormControlsService } from '../../form-controls.service';



@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss']
})
export class ImgComponent {
  public Control: HtmlControl;
  public Group: FormGroup;

  src: SafeUrl;

  public TranslationEnum = TranslationEnum;

  @ViewChild('imgDialog') imgDialog: TemplateRef<any>;

  constructor(private dialog: MatDialog, private formControlsService: FormControlsService) { }



  openImgDialog() {

    this.formControlsService.getImg(this.Group, this.Control).pipe(/*take(1)*/).subscribe(data => {
      this.createImageFromBlob(data);
      const dialogRef = this.dialog.open(this.imgDialog,  { minWidth: '1080px'});
      // dialogRef.afterClosed().subscribe((result) => {
      //    // console.log(`Dialog result: ${result}`);
      // });
    }, error => {
      this.src = '';
    });
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.src = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

}
