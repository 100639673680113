import { ContactDialogComponent } from './../contact-dialog/contact-dialog.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { concatMap, takeWhile } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { BroadcastService } from 'src/app/shared/async-services/http/broadcast.service';
import { PermissionEnum } from 'src/app/shared/enums/permission-enum';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public closeResult: string;
  public Username: string;
  public Password: string;
  public Lang: string;
  public alertMessage: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
    private cookieService: CookieService,
    public translateService: TranslateService,
    private broadcastService: BroadcastService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.Lang = this.translateService.currentLang;
    this.subscriptions.push(this.broadcastService.ErrorLogin.asObservable().subscribe((x) => {
      if (x !== null) {
        this.snackBar.open('Invalid login or password', 'x', {
          duration: 10000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    }));

    document.body.className = 'bg-login';
  }

  ngOnDestroy(): void {
    this.broadcastService.ErrorHttp.next(null);
    document.body.className = '';
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  public changeLanguage() {
    window['culture'] = this.Lang;
    this.translateService.use(this.Lang);
  }

  public login(): void {
    this.subscriptions.push(this.authorizationService
      .getToken(this.Username, this.Password)
      .pipe( 
        concatMap((token: any) => {
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll();

          this.cookieService.set('lang', this.Lang, { path: '/' });
          this.cookieService.set('_token', token.access_token, { path: '/' });
          return this.authorizationService.login();
        })
      )
      .subscribe((user) => {
        // console.log("login", user);
        if (user) {
          if (user.Permissions !== undefined) {
            this.authorizationService.User.next(user);
            const translation = user.Permissions.find((permission) => permission.Id === PermissionEnum.Translation);
            if (translation) {
              this.authorizationService.changePermissions(PermissionEnum.Translation);
            } else {
              this.cookieService.set('_usi', JSON.stringify(user), { path: '/' });
            }
          } else {
            this.cookieService.set('_usi', JSON.stringify(user), { path: '/' });
          }
        }
        this.authorizationService.User.next(user);
        if (user.IsFirst === undefined || user.IsFirst === false) {
          this.router.navigate(['Home']);
        } else if (user.IsFirst === true) {
          this.router.navigate(['Change-password']);
        }
      }));
  }

  public resetPassword() {
    this.router.navigate(['password/new']);
  }

  routeTo(site: string): void {
    this.router.navigate([`${site}`]);
  }

  openContactDialog(): void {
    this.dialog.open(ContactDialogComponent, { width: '500px' });
  }
}
