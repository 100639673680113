import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DynamicFormComponent } from 'src/app/new-form/initial-form/dynamic-form/dynamic-form.component';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { environment } from 'src/environments/environment';
import { FormDeliveryComponent } from '../form-delivery.component';
import { FormV2ServiceService } from '../../form-v2-service.service';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { TresciService } from 'src/app/tresci.service';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-adress',
  templateUrl: './add-adress.component.html',
  styleUrls: ['./add-adress.component.scss']
})
export class AddAdressComponent implements OnInit {
  public TranslationEnum = TranslationEnum;
  public server_url = environment.apiUrl;
  
  public address: string = '';
  public zipcode: string = '';
  public city: string = '';
  public country: string = '';
  public comment: string = '';

  public address_b: boolean = false;
  public zipcode_b: boolean = false;
  public city_b: boolean = false;
  public country_b: boolean = false;
  public comment_b: boolean = false;

  public wczytywanie = false;


  public kraje = [];

  form:FormGroup;
  
  constructor(public dialogRef: MatDialogRef<DynamicFormComponent>,public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data,private formService: FormV2ServiceService, public translateService: TranslateService) {
    var currentLang = window['culture'];
    var jezyk = 1; 
    for(var j = 0;j< translateService.langs.length; j++){
      if(translateService.langs[j] === currentLang){
        jezyk = j+1;
      }
    }
    this.formService.getCountriesForEstimateOrder(jezyk).
    subscribe((data) => {
      // console.log("kraje", data);
      this.kraje = data;
      
    });
    this.form = new FormGroup({
      state: new FormControl({value: ''}),
    });
  }
  changeSelect(event:any){
    // console.log(event.value);
    this.country = event.value;
  }
  ngOnInit(): void {
   
  }

  pobierzAdresy(){
    this.formService.getDeliveryAddresses().
        subscribe((data) => {
            // console.log("a", data);
            this.data.ContractorAddresses = data.ContractorAddresses;
            this.wczytywanie = false;
        },
        (err)=> {
          this.wczytywanie = false;
        });
  }

  usun(Id) {


    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
    const data: DialogData = {
      title: '',
      content: 'czy_na_pewno_chcesz_usunac_adres',
      answerOk: false,
    };
    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        
        this.wczytywanie = true;
        this.formService.RemoveContractorAddress(Id).
        subscribe((data) => {
          // this.dialogRef.close();
          this.wczytywanie = false;
          this.pobierzAdresy();
          let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
          const data2: DialogData = {
            title: '',
            content: 'usunięto_adres_poprawnie',
            answerOk: true,
          };
          dialogRef = this.dialog.open(UniversalDialogComponent, { data: data2 });
          
        }, 
        (err) => {
          this.wczytywanie = false;
          let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
          const data: DialogData = {
            title: '',
            content: 'blad_przy_usuwaniu_adresu',
            answerOk: true,
          };
          dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
        })
      }
    });

    
  }
  dodajAdres(){
    var blad = false;
    if(this.address === ''){
      this.address_b = true;
      blad = true;
    } else {
      this.address_b = false;
    }
    if(this.zipcode === ''){
      this.zipcode_b = true;
      blad = true;
    } else {
      this.address_b = false;
    }
    if(this.city === ''){
      this.city_b = true;
      blad = true;
    } else {
      this.address_b = false;
    }
    if(this.country === ''){
      this.country_b = true;
      blad = true;
    } else {
      this.address_b = false;
    }
    if(this.comment === ''){
      this.comment_b = true;
      blad = true;
    } else {
      this.address_b = false;
    }


    if(!blad){
      this.wczytywanie = true;
      this.formService.AddContractorAddress(this.address, this.zipcode, this.city, this.country, this.comment).
      subscribe((data) => {
        this.wczytywanie = false;
        let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
        const data2: DialogData = {
          title: '',
          content: 'dodano_adres_poprawnie',
          answerOk: true,
        };
        dialogRef = this.dialog.open(UniversalDialogComponent, { data: data2 });
  
  
        dialogRef.afterClosed().subscribe((result) => {
          this.dialogRef.close();
        });
  
        
      }, 
      (err) => {
        this.wczytywanie = false;
        let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
        const data: DialogData = {
          title: '',
          content: 'blad_przy_dodawaniu_adresu',
          answerOk: true,
        };
        dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      })
    } else {
      this.wczytywanie = false;
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
        const data: DialogData = {
          title: '',
          content: 'nie_wszystkie_pola_uzupelnione',
          answerOk: true,
        };
        dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    }
    

  }
}
