import { BehaviorSubject, Subscription } from 'rxjs';
import { DataSharingService } from 'src/app/shared/service/data-sharing.service';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { FormService } from 'src/app/shared/service/form.service';



@Component({
  selector: 'app-checkbox-select',
  templateUrl: './checkbox-select.component.html',
  styleUrls: ['./checkbox-select.component.scss'],
})
export class CheckboxSelectComponent implements OnInit, AfterViewInit {
  @ViewChild('input') myDiv: ElementRef;

  public EnabledSelect: boolean;
  public TranslationEnum = TranslationEnum;
  public Control: HtmlControl;
  public Group: FormGroup;
  public SetEnabledSubscription: Subscription;

  @ViewChild('select', { static: true }) select: MatSelect;
  ControlTest: any;

  constructor(private formService: FormService, private fb: FormBuilder, private dataSharingService: DataSharingService) {}

  ngOnInit(): void {
    this.EnabledSelect = this.Control.value !== undefined && this.Control.value !== '' && this.Control.value !== null;
    // this.Group.get(this.Control.name).disable(!this.EnabledSelect);
    // console.log('select', this.select.disabled, this.EnabledSelect, this.Group.controls[this.Control.name].disabled, this.Group.controls[this.Control.name]);
    // console.log('this.fomr0', this.formService.RemoveControlsFromGroup);

    // this.SetEnabledSubscription = this.dataSharingService.SetEnabledForCombineControl.asObservable().subscribe((x) => {
    //   if (x) {
    //     this.select.setDisabledState(false);
    //   }
    // });

    this.Control.options.forEach((element) => {
      if (element.Key === this.Control.value) {
        this.Control.value = element;
        this.Group.get(this.Control.name).setValue(this.Control);
        this.Group.get(this.Control.name).setValidators(Validators.required);
        this.Group.get(this.Control.name).updateValueAndValidity();
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.Control.value !== '') {
        this.select.selectionChange.emit(new MatSelectChange(this.select, this.Control.value));
      }
    });
  }

  checkChanged(): void {
    const control = this.formService.RemoveControlsFromGroup.find((x) => x.name === this.Control.name && x.module === this.Control.module && x.position === this.Control.position);
    let index: number;
    if (control !== null) {
      index = this.formService.RemoveControlsFromGroup.indexOf(control);
    }
    if (this.EnabledSelect) {
      const controlSelect = this.fb.control(this.Control);
      this.Group.addControl(this.Control.name, controlSelect);

      this.Group.get(this.Control.name).setValidators(Validators.required);
      this.Group.controls[this.Control.name].setValue('');
      this.Group.get(this.Control.name).updateValueAndValidity();

      if (index !== null && index >= 0) {
        this.formService.RemoveControlsFromGroup.splice(index, 1);
      }
    } else if (!this.EnabledSelect) {
      this.Group.get(this.Control.name).clearValidators();
      this.Control.value = '';
      this.Group.controls[this.Control.name].setValue(this.Control);
      this.Group.get(this.Control.name).setErrors(null);
      this.Group.get(this.Control.name).updateValueAndValidity();
      if (index < 0) {
        this.formService.RemoveControlsFromGroup.push(this.Control);
      }
    }

  }
}

