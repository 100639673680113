import { TresciService } from './../../../../../tresci.service';
import { Component, Inject, OnInit, AfterViewChecked, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControlsService } from '../../../form-controls.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';



@Component({
  selector: 'app-fabric-dialog',
  templateUrl: './fabric-dialog.component.html',
  styleUrls: ['./fabric-dialog.component.scss']
})
export class FabricDialogComponent implements OnInit {

  public dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['Name', 'Picture'];
  public TranslationEnum = TranslationEnum;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<FabricDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any[], public formControlsService: FormControlsService,
    public sanitizer: DomSanitizer, public tresci: TresciService) {
    this.dataSource.data = data;
  }

  ngOnInit(): void {
    setTimeout(() => { this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort; });
  }




  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
