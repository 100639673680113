<div *ngIf="Control" [formGroup]="Group">
  <label>
    <span id="label_{{Control.label}}" [translation]="TranslationEnum.TranslationForm">
      {{('label_'+ Control.label)|translate}}
    </span>
    {{MinMaxLabel}}
    <app-information [label]="Control.label"  [notAffectOnOtherControl]='Control.notAffectToOtherControls' [fileInfoId]="Control.fileInfoId"></app-information>
  </label>
  <mat-form-field appearance="outline" class="d-block w-100" color="accent">
    <ng-container *ngIf="Control.inputType === 'number';">
      <input matInput [formControlName]="Control.name" oninput="this.value=this.value.replace(/[^0-9]/g,'');" [type]="Control.inputType" [min]="Min" [max]="Max" [value-object]="Control">
    </ng-container>
    <ng-container *ngIf="Control.inputType !== 'number';">
      <input matInput [formControlName]="Control.name" [type]="Control.inputType" [min]="Min" [max]="Max" [value-object]="Control">
    </ng-container>
    <ng-container *ngFor="let validation of Control.validations;" ngProjectAs="mat-error">
      <mat-error *ngIf="Group.get(Control.name)?.hasError(validation.name) " id="{{validation.message}}"
        [translation]="TranslationEnum.TranslationForm">
        {{validation.message|translate}}
      </mat-error>
    </ng-container>
  </mat-form-field>
</div>
