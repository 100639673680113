import { catchError, takeWhile } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { AuthorizationService } from '../authorization.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public Username: string;
  public Email: string;
  public Lang: string;
  public UserGroup: FormGroup;
  private subscriptions: Subscription[] = [];

  constructor(private router: Router, public translateService: TranslateService, public authorizationService: AuthorizationService, private fb: FormBuilder, public dialog: MatDialog) {}

  ngOnInit() {
    this.Lang = this.translateService.currentLang;
    document.body.className = 'bg-login';
    this.UserGroup = this.fb.group({
      Username: new FormControl('', [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    document.body.className = '';
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  public changeLanguage() {
    this.translateService.use(this.Lang);
  }

  public resetPassword(UserGroup: FormGroup): void {
    if (UserGroup.valid) {
      const culture = this.translateService.currentLang;
      this.subscriptions.push(this.authorizationService.resetPassword(UserGroup.controls['Username'].value, culture).subscribe((x) => {
        let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
        let data: DialogData;
        if (x !== null) {
          data = {
            title: '',
            content: `${x}`,
            answerOk: true,
          };
        } else {
          data = {
            title: '',
            content: 'na_maila_zostalo_wyslane_tymczasowe_haslo',
            answerOk: true,
          };
        }

        dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
        dialogRef.afterClosed().subscribe((x) => {
          this.routeTo('Login');
        });
      }));
    }
  }

  routeTo(site: string): void {
    this.router.navigate([`${site}`]);
  }
}
