<h2 mat-dialog-title id="wybierz" [translation]="TranslationEnum.TranslationForm">
    {{'wybierz'|translate}}
  </h2>
<input (keyup)="applyFilter($event.target.value)">
<div class="fabrics">
<ng-container *ngFor="let v of values; let m = index;">

    <div class="fabric" (click) = wybierz(v)>
        <div class="kolor" [style.background-color]="v.Kolor"></div><div class="tresc">{{v.DisplayValue}} <br /> ({{v.Key}})<br /> {{v.NazwaProszku}}</div>

    </div>
  </ng-container> 
</div>