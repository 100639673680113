<h2 mat-dialog-title *ngIf="data.title !== ''" id="{{data.title}}" [translation]="TranslationEnum.Translation">{{data.title|translate}}</h2>
<mat-dialog-content>
  <span id="{{data.content}}" [translation]="TranslationEnum.Translation">
    {{data.content|translate}}
  </span>
  <span *ngIf="data.additionalContent" id="{{data.additionalContent}}" [translation]="TranslationEnum.Translation">
    {{data.additionalContent|translate}}
  </span>
  <!-- <app-tlumacz [id]="data.content" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
</mat-dialog-content>
<mat-dialog-actions>
  <div class="w-100" *ngIf="data.control !== undefined">
    <button mat-flat-button id="{{data.control.name}}_ok" class="btn-sm float-right" [translation]="TranslationEnum.Translation" color="primary"
      mat-dialog-close [hidden]="!data.answerOk">
      {{data.control.name+'_ok'|translate}}
    </button>
    <button mat-flat-button id="{{data.control.name}}_nie" class="btn-sm float-right" [translation]="TranslationEnum.Translation" color="primary"
      mat-dialog-close [hidden]="data.answerOk">
      {{data.control.name+'_nie'|translate}}
    </button>
    <button mat-flat-button id="{{data.control.name}}_tak" class="btn-sm float-right mr-3" [translation]="TranslationEnum.Translation"
      color="primary" [mat-dialog-close]="true" [hidden]="data.answerOk">
      {{data.control.name+'_tak'|translate}}
    </button>
  </div>
  <div class="w-100" *ngIf="data.control === undefined">
    <button mat-flat-button id="ok" class="btn-sm float-right" type="button" [translation]="TranslationEnum.Translation" color="primary"
      mat-dialog-close [hidden]="!data.answerOk">
      {{'ok'|translate}}
    </button>
    <button mat-flat-button id="nie" class="btn-sm float-right" [translation]="TranslationEnum.Translation" color="primary"
      mat-dialog-close [hidden]="data.answerOk">
      {{'nie'|translate}}
    </button>
    <button mat-flat-button id="tak" class="btn-sm float-right mr-3" [translation]="TranslationEnum.Translation"
      color="primary" [mat-dialog-close]="true" [hidden]="data.answerOk">
      {{'tak'|translate}}
    </button>
  </div>
</mat-dialog-actions>
