import { TresciService } from './../tresci.service';
import { Uwagi } from './uwagi';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';
import { take } from 'rxjs/operators';

export class Select {
    public pole_wybrane: PoleSelect = new PoleSelect();
    public pole_wybraneEn: PoleSelect = new PoleSelect();
    public pole_wybraneDe: PoleSelect = new PoleSelect();
    public pole_wybraneFr: PoleSelect = new PoleSelect();
    public array_wyswietlany = [];
    public id_wybrane = 0;
    public ustaw_wartosc = '';
    public pola: PoleSelect[];
    public polaEn: PoleSelect[];
    public polaDe: PoleSelect[];
    public polaFr: PoleSelect[];
    public otwarte = false;
    private chron = false;
    private uwagi: Uwagi = new Uwagi('');
    public fotoBox = false;
    public KeyName = '';
    public nie_wybrany = true;

    constructor(private id, public tresci: TresciService, public check = true) {
    }

    public AktualArray() {
      const array = [];
      let array_tresci = null;
      if (this.tresci.jezyk_id === '1') {
        array_tresci = this.pola;
      } else if (this.tresci.jezyk_id === '3') {
        array_tresci = this.polaDe;
      } else {
        array_tresci = this.polaEn;
      }

      let i = 0;
      array_tresci.forEach(element => {
        array.push({'id': i + '', 'text': this.getDisplay(i).trim()});
        i++;
      });
      return array;
    }

    public zmien_check() {
      // this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName + '_check', this.check);
    }
    public wczytaj(uwagi1: Uwagi, KeyName, Values, ValuesEn, ValuesDe, ValuesFr, Parameters, fotki: boolean, check = true) {
      this.uwagi = uwagi1;
      this.KeyName = KeyName;
      this.fotoBox = fotki;
      this.pola = Values as PoleSelect[];
      if (ValuesEn != null) {
        this.polaEn = ValuesEn as PoleSelect[];
      } else {
        this.polaEn = Values as PoleSelect[];
      }
      if (ValuesDe != null) {
        this.polaDe = ValuesDe as PoleSelect[];
      } else {
        this.polaDe = Values as PoleSelect[];
      }
      if (ValuesFr != null) {
        this.polaFr = ValuesFr as PoleSelect[];
      } else {
        this.polaFr = Values as PoleSelect[];
      }
      if (Parameters == null) {
        Parameters = [];
      }
      this.pole_wybrane = Parameters[0] as PoleSelect;
      this.pole_wybraneEn = Parameters[0] as PoleSelect;
      this.pole_wybraneDe = Parameters[0] as PoleSelect;
      this.pole_wybraneFr = Parameters[0] as PoleSelect;
      // if (sessionStorage.getItem('konf_' + this.id + '_'  + KeyName) != null) {
      //   this.ustaw(sessionStorage.getItem('konf_' + this.id + '_'  + KeyName));
      //   this.nie_wybrany = false;
        if (this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName) !== null) {
          this.ustaw(this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName), false, check);
          this.nie_wybrany = false;
        } else {
          if (Parameters != null && Parameters[0] != null) {
            this.ustaw(Parameters[0].Value, false, check);
            this.nie_wybrany = false;
          } else {
            this.ustaw('X', false, check);
          }
        }
    }
    public czyNieWybrany() {
      if (!this.check) {
        return false;
      } else {
        return this.nie_wybrany;
      }
    }
    public wczytaj_z_pliku(uwagi1: Uwagi, http: HttpClient, url: string, KeyName) {
      this.uwagi = uwagi1;
      this.KeyName = KeyName;
      http.get('../../assets/dane/' + url).pipe(/*take(1)*/).subscribe(result => {
          this.pola = result as PoleSelect[];
          if (this.ustaw_wartosc === '') {
            this.pole_wybrane = this.pola[0] as PoleSelect;
            this.id_wybrane = 0;
          } else {
            this.ustaw(this.ustaw_wartosc);
          }
        }, error => console.error(error));
  }
    public aktualizuj(Values) {
      this.pola = Values as PoleSelect[];
      this.sprawdz();
    }
    public getDisplay(id, jezyk = null) {
      if (!this.check) {
        return 'X';
      }
      if (jezyk == null) {
        jezyk = this.tresci.jezyk_id.toString();
      }
      let tresc = '';
      if (jezyk === '1') {
        tresc = this.pola[id].Value;
        // tresc = this.tresci.get_tresc(this.KeyName + '_' + this.pola[id].Key, jezyk, false);
      } else if (jezyk === '3') {
        tresc = this.polaDe[id].Value;
        // tresc = this.tresci.get_tresc(this.KeyName + '_' + this.pola[id].Key, jezyk, false);
      } else if (jezyk === '4') {
        tresc = this.polaFr[id].Value;
        // tresc = this.tresci.get_tresc(this.KeyName + '_' + this.pola[id].Key, jezyk, false);
      } else {
        tresc = this.polaEn[id].Value;
        // tresc = this.tresci.get_tresc(this.KeyName + '_' + this.pola[id].Key, 2, false);
      }
       if (tresc == null || tresc === '') {
        if (jezyk === '1') {
          // tresc = this.pola[id].Value;
          tresc = this.tresci.get_tresc(this.KeyName + '_' + this.pola[id].Key, jezyk, false);
        } else if (jezyk === '3') {
          // tresc = this.polaDe[id].Value;
          tresc = this.tresci.get_tresc(this.KeyName + '_' + this.pola[id].Key, jezyk, false);
        } else if (jezyk === '4') {
          // tresc = this.polaDe[id].Value;
          tresc = this.tresci.get_tresc(this.KeyName + '_' + this.pola[id].Key, jezyk, false);
        } else {
          // tresc = this.polaEn[id].Value;
          tresc = this.tresci.get_tresc(this.KeyName + '_' + this.pola[id].Key, 2, false);
        }
       }

      if (tresc != null) {
        return tresc;
      } else if (this.pola[id].Value != null) {
        return this.pola[id].Value;
      } else {
        return '';
      }
    }
    public getActual() {
      if (!this.check) {
        return 'X';
      }
      if (this.KeyName === 'System') {
        this.tresci.system_wybrany = this.pole_wybrane.Key;
      }

      if (this.pola != null && this.pola[0] != null) {
        if (this.pola[0].Key  === 'hidden') {
          return 'brak';
        }
      }
      // tslint:disable-next-line:max-line-length
      /*if (this.KeyName === 'Wysieg' &&  (sessionStorage.getItem('konf_' + this.id + '_'  + this.KeyName) == null ||  sessionStorage.getItem('konf_' + this.id + '_'  + this.KeyName) === '')) {
        return '160';
      } else {
        return sessionStorage.getItem('konf_' + this.id + '_'  + this.KeyName);
      }*/
      return this.pole_wybrane.Key;
    }
    public getActualDisplay(jezyk = null) {
      if (!this.check) {
        return 'X';
      }
      if (jezyk == null) {
        jezyk = this.tresci.jezyk_id.toString();
      }
      /*if (this.pola != null && this.pola[0] != null) {
        if (this.pola[0].Key  === 'hidden') {
          return 'brak';
        }
      }
      return sessionStorage.getItem('konf_Display_' + this.id + '_'  + this.KeyName);*/
      let tresc = '';
      if (jezyk === '1') {
        if (this.pole_wybrane != null) {
          tresc = this.pole_wybrane.Value;
        }
        // tresc = this.tresci.get_tresc(this.KeyName + '_' + this.getActual(), jezyk, false);
      } else if (jezyk === '3') {
        if (this.pole_wybraneDe != null) {
          tresc = this.pole_wybraneDe.Value;
        }
        // tresc = this.tresci.get_tresc(this.KeyName + '_' + this.getActual(), jezyk, false);
      } else if (jezyk === '4') {
        if (this.pole_wybraneFr != null) {
          tresc = this.pole_wybraneFr.Value;
        }
        // tresc = this.tresci.get_tresc(this.KeyName + '_' + this.getActual(), jezyk, false);
      } else {
        if (this.pole_wybraneEn != null) {
          tresc = this.pole_wybraneEn.Value;
        }
        // tresc = this.tresci.get_tresc(this.KeyName + '_' + this.getActual(), 2, false);
      }
      if (tresc == null || tresc === '') {
        if (jezyk === '1') {
          // tresc = this.pole_wybrane.Value;
          tresc = this.tresci.get_tresc(this.KeyName + '_' + this.getActual(), jezyk, false);
        } else if (jezyk === '3') {
          // tresc = this.pole_wybraneDe.Value;
          tresc = this.tresci.get_tresc(this.KeyName + '_' + this.getActual(), jezyk, false);
        } else if (jezyk === '4') {
          // tresc = this.pole_wybraneDe.Value;
          tresc = this.tresci.get_tresc(this.KeyName + '_' + this.getActual(), jezyk, false);
        } else {
          // tresc = this.pole_wybraneEn.Value;
          tresc = this.tresci.get_tresc(this.KeyName + '_' + this.getActual(), 2, false);
        }
      }
      if (tresc != null) {
      } else {
        tresc =  this.pole_wybrane.Value;
      }

      if (!this.nie_wybrany) {
        return tresc;
      } else {
        return null;
      }

    }
    public otworz(event) {
        this.otwarte = !this.otwarte;
        this.chron = true;
        /*event.stopPropagation();*/
    }
    public zamknij(id) {
      id = parseInt(id);
      this.nie_wybrany = false;
      this.otwarte = false;

      if (this.pola[id].IsEnable) {
        this.pole_wybrane = this.pola[id];
        this.pole_wybraneEn = this.polaEn[id];
        this.pole_wybraneDe = this.polaDe[id];
        this.pole_wybraneFr = this.polaFr[id];
        this.id_wybrane = id;
        if (this.pola[id].Key !== 'hidden') {
          this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, this.pola[id].Key);
          this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName + '_display', this.pola[id].Value);
          // sessionStorage.setItem('konf_' + this.id + '_'  + this.KeyName, this.pola[id].Key);
          // sessionStorage.setItem('konf_Display_' + this.id + '_'  + this.KeyName, this.pola[id].Value);
        }
      } else {
        this.uwagi.zmien('Wartość niemożliwa dla wybranych parametrów');
      }
      this.sprawdz();
      this.tresci.zamowienie.zapisz_Parametry();
    }
    public sprawdz() {
      let tmp_byl = false;
      if (this.pola != null) {
        // tslint:disable-next-line:max-line-length
        while (this.pola[this.id_wybrane] != null && this.pola[this.id_wybrane].IsEnable != null && !this.pola[this.id_wybrane].IsEnable && this.id_wybrane > 0 ) {
          this.id_wybrane-- ;
          this.pole_wybrane = this.pola[this.id_wybrane];
          this.pole_wybraneEn = this.polaEn[this.id_wybrane];
          this.pole_wybraneDe = this.polaDe[this.id_wybrane];
          this.pole_wybraneFr = this.polaFr[this.id_wybrane];
          if (this.pola[this.id_wybrane].Key !== 'hidden') {
             sessionStorage.setItem('konf_' + this.id + '_'  + this.KeyName, this.pola[this.id_wybrane].Key);
             sessionStorage.setItem('konf_Display_' + this.id + '_'  + this.KeyName, this.pola[this.id_wybrane].Value);
            // this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, this.pola[this.id_wybrane].Key);
            // this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName + '_display', this.pola[this.id_wybrane].Value);
          }
          tmp_byl = true;
          this.uwagi.zmien('Wartość zmieniona! Dostosowano do możliwości konstrukcyjnych.');
        }
        let byl_wybrany = false;
        for (let id = 0; id < this.pola.length; id++) {
          if (this.id_wybrane === id) {
            this.pola[id].wybrane = true;
            if (this.pola[id] != null && this.pole_wybrane != null && this.pola[id].Key === this.pole_wybrane.Key) {
              byl_wybrany = true;
            }
            if (this.pola[id].Key !== 'hidden') {
               sessionStorage.setItem('konf_' + this.id + '_'  + this.KeyName, this.pola[id].Key);
               sessionStorage.setItem('konf_Display_' + this.id + '_'  + this.KeyName, this.pola[id].Value);
              // this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, this.pola[id].Key);
              // this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName + '_display', this.pola[id].Value);
            }
          } else {
            this.pola[id].wybrane = false;
          }
        }
        if (!byl_wybrany) {
          this.pola[0].wybrane = true;
          this.id_wybrane = 0;
          this.pole_wybrane = this.pola[this.id_wybrane];
          this.pole_wybraneEn = this.polaEn[this.id_wybrane];
          this.pole_wybraneDe = this.polaDe[this.id_wybrane];
          this.pole_wybraneFr = this.polaFr[this.id_wybrane];
            byl_wybrany = true;
            if (this.pola[0].Key !== 'hidden') {
               sessionStorage.setItem('konf_' + this.id + '_'  + this.KeyName, this.pola[0].Key);
               sessionStorage.setItem('konf_Display_' + this.id + '_'  + this.KeyName, this.pola[0].Value);
              // this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, this.pola[0].Key);
              // this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName + '_display', this.pola[0].Value);
            }
        }
        return tmp_byl;
      }
    }
    public ustaw(wartosc, enable = false, check = true) {
      if (wartosc === 'X' && !check) {
        this.check = false;
      } else {
        this.check = true;
      }

      this.zmien_check();
      this.ustaw_wartosc = wartosc;
      let byla_ustawiona = false;
      if (this.pola != null && wartosc != null) {
        for (let id = 0; id < this.pola.length; id++) {
          if (this.pola[id].Key != null && this.pola[id].Key.toString() === wartosc.toString()) {
            this.pola[id].wybrane = true;
            this.pole_wybrane = this.pola[id];
            this.pole_wybraneEn = this.polaEn[id];
            this.pole_wybraneDe = this.polaDe[id];
            this.pole_wybraneFr = this.polaFr[id];
            this.id_wybrane = id;
            if (enable) {
              this.nie_wybrany = false;
            }
            if (this.pola[id].Key !== 'hidden') {
              sessionStorage.setItem('konf_' + this.id + '_'  + this.KeyName, this.pola[id].Key);
              sessionStorage.setItem('konf_Display_' + this.id + '_'  + this.KeyName, this.pola[id].Value);
            }
            byla_ustawiona = true;
          } else {
            this.pola[id].wybrane = false;
          }
        }
        if (!byla_ustawiona) {
          if (!check) {
            this.check = false;
          }
            this.pola[0].wybrane = true;
            this.pole_wybrane = this.pola[0];
            this.pole_wybraneEn = this.polaEn[0];
            this.pole_wybraneDe = this.polaDe[0];
            this.pole_wybraneFr = this.polaFr[0];
            this.id_wybrane = 0;
            if (this.pola[0].Key !== 'hidden') {
              sessionStorage.setItem('konf_' + this.id + '_'  + this.KeyName, this.pola[0].Key);
              sessionStorage.setItem('konf_Display_' + this.id + '_'  + this.KeyName, this.pola[0].Value);
            }

        }
      }
      this.sprawdz();
    }
    public poza() {
      if (!this.chron) {
        this.otwarte = false;
      } else {
        this.chron = false;
      }
    }
}

class PoleSelect {
    Key: string;
    Value: string;
    IsEnable: boolean;
    wybrane: boolean;
    Price: number;
    IsVisible: boolean = true;
    constructor() {
    }
}

