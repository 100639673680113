import { Component, OnInit } from '@angular/core';
import { TresciService } from '../../app/tresci.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../app/user.service';
import { daneStron } from '../../app/pomocnicze/strony';

@Component({
  selector: 'app-podziekowanie',
  templateUrl: './podziekowanie.component.html',
  styleUrls: ['./podziekowanie.component.scss']
})
export class PodziekowanieComponent implements OnInit {
  public strony;
  constructor(public tresci: TresciService, public user: UserService, private route: ActivatedRoute) {
    this.strony = daneStron;
  }

  ngOnInit() {
    this.tresci.setJezyk(this.route.snapshot.paramMap.get('jezyk'));
    this.tresci.setFunkcja(this.route.snapshot.paramMap.get('system'));
    this.tresci.setId(this.route.snapshot.paramMap.get('id'));
  }

}
