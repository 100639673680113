import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicFormComponent } from 'src/app/new-form/initial-form/dynamic-form/dynamic-form.component';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';

@Component({
  selector: 'app-view-json',
  templateUrl: './view-json.component.html',
  styleUrls: ['./view-json.component.scss']
})
export class ViewJSONComponent implements OnInit {
  public TranslationEnum = TranslationEnum;
  
  constructor(public dialogRef: MatDialogRef<DynamicFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      
  }
  ngOnInit(): void {
  }

}
