<footer [ngStyle]="{'background-color': IsLogedIn? '#002d45': 'transparent'}" >
  <div class="container">
    <div class="grid_footer">
      <div class="col1">
        <h4 id="warunki" [translation]="TranslationEnum.Translation">
          {{'warunki'|translate}}
        </h4>
        <ng-container *ngFor="let dok of linki; let m = index;">
          <a  *ngIf = "dok.nazwa_atr == 'b2b_warunki'" [href]="getLink(dok)"
            rel="nofollow" target="_blank" rel="noopener">
            {{getNazwa(dok)}}
          </a><br *ngIf = "dok.nazwa_atr == 'b2b_warunki'" />
        </ng-container>
      </div>
      <mat-divider class="vertical mt-2 mb-2" [vertical]="true"></mat-divider>
      <div class="col2">
        <h4 id="informacje" [translation]="TranslationEnum.Translation">
          {{'informacje'|translate}}
        </h4>
        <ng-container *ngFor="let dok of linki; let m = index;">
          <a  *ngIf = "dok.nazwa_atr == 'b2b_informacje'" [href]="getLink(dok)"
            rel="nofollow" target="_blank" rel="noopener">
            {{getNazwa(dok)}}
          </a><br  *ngIf = "dok.nazwa_atr == 'b2b_informacje'" />
        </ng-container>
   
      </div>
    </div>
  </div>
  <div class="d-flex w-100">
    <p class="d-inline ml-auto mr-auto">© {{dateInFooter}} b2b.selt.com powered by
      <a href="https://www.tarkus.pl" target="_blank">Tarkus</a>
    </p>
  </div>
  <p class="pr-3 pl-3 app-version">v. {{Version}}</p>
</footer>
