<!-- <h2 matDialogTitle>
  <span id="potwierdz_dostawe" [translation]="TranslationEnum.Translation">
    {{ 'potwierdz_dostawe' | translate }}
  </span>
</h2> -->
<h1 matDialogTitle>
  <span id="potwierdz_dostawe" [translation]="TranslationEnum.Translation">
    {{ 'potwierdz_dostawe' | translate }}
  </span>
</h1>
<div mat-dialog-content>
  <ng-container *ngFor="let item of this.data.tableData">
    <div class="mb-5 p-2" style="border: solid 1px black; overflow: auto;">

      <div class="row m-0 conf-ng" style="border: solid 0.5px black; background-color: rgb(165, 165, 165)">
        <div class="col-sm text-center">
          <span id="nonfirmationno" [translation]="TranslationEnum.Translation"> {{'nonfirmationno' | translate}} </span>
          {{item.WdtNumber}} 
          (Potwierdzenie WDT nr {{item.WdtNumber}})
        </div>
      </div>

      <div class="row m-0" style="border: solid 0.5px black; background-color: rgb(165, 165, 165)">
      <div class="col-sm text-center">
        <span id="invoiceno" [translation]="TranslationEnum.Translation"> {{'invoiceno' | translate}} </span>  {{item.InvoiceNumber}} <span id="fromthedate" [translation]="TranslationEnum.Translation"> {{'fromthedate' | translate}} </span> {{item.InvoiceDate | date:'yyyy-MM-dd H:mm:ss'}} <br />
        (Faktura nr {{item.InvoiceNumber}} z dnia {{item.InvoiceDate | date:'yyyy-MM-dd H:mm:ss'}})
      </div>
      <div class="col-sm text-center">
        <span id="specyfikacjatowarów" [translation]="TranslationEnum.Translation"> {{'specyfikacjatowarów' | translate}} </span> <br />
        (Specyfikacja towarów)
      </div>
      <!-- <div class="col-sm text-center">
        Delivery confirmation ICD <br />
        (Dowód dostawy WDT)
      </div> -->
      </div>
      <div class="row mt-3 mb-3 ml-0 mr-0">
      <div class="col-sm">
        <span class="headline-info"><span id="seller" [translation]="TranslationEnum.Translation"> {{'seller' | translate}} </span> (Sprzedawca)</span>
        SELT Sp. z o. o. <br />
        45-499 Opole, ul. Wschodnia 23a <br />
        POLSKA <br />
        VAT UE: PL7543103311 <br />
      </div>
      <div class="col-sm">
        <span class="headline-info"><span id="buyer" [translation]="TranslationEnum.Translation"> {{'buyer' | translate}} </span> (Nabywca) ({{item.ContractorNumber}})</span>
        {{item.ContractorName}}<br />
        {{item.ContractorZipCode}}  {{item.ContractorCity}}
        {{item.ContractorCountry}} <br />
        {{item.ContractorAddress}} <br />
        VAT UE: {{item.ContractorNip}} <br />
      </div>
      <div class="col-sm">
        <span class="headline-info"><span id="deliveryadress" [translation]="TranslationEnum.Translation"> {{'deliveryadress' | translate}} </span> (Adres dostawy)</span>
        <span [innerText]="item.ShortDeliveryAddress"></span>
        <div class="row m-0 p-0"> <!-- *ngIf="item.UpsNumber || item.CarPlate" -->
          <div class="col p-0">
            <span *ngIf="item.UpsNumber" class="mr-3">{{item.UpsNumber}}</span>                                                                         <!-- {{item.UpsNumber}}  UPS: 1ZXV50106892986196  -->
            <span *ngIf="item.CarPlate" id="nr_rej" [translation]="TranslationEnum.Translation"> {{'nr_rej' | translate}} </span>{{item.CarPlate}}      <!-- {{item.CarPlate}}   OP 2562P/OP 6382M        -->
          </div>
        </div>
        <div class="row m-0 p-0"> <!-- *ngIf="item.TransportType || item.Driver" -->
          <div class="col-md-6 p-0">
            <span *ngIf="item.TransportType" class="mr-2"><span id="srodektransportu" [translation]="TranslationEnum.Translation"> {{'srodektransportu' | translate}} </span>
            <span id="typtransportu{{item.TransportType.toLowerCase()}}" [translation]="TranslationEnum.Translation"> {{'typtransportu' + item.TransportType.toLowerCase() | translate}} </span> </span>
             {{item.Driver}}
          </div>
        </div>
      </div>
      </div>
      <table mat-table [(dataSource)]="item.WdtPosList" multiTemplateDataRows class="mat-elevation-z8 mt-2 mb-3">
      <ng-container matColumnDef="Lp">
        <th mat-header-cell *matHeaderCellDef id="Lp2">
          <span id="lp" [translation]="TranslationEnum.Translation">{{ 'lp' | translate }}</span><br />(Lp.)
          <!-- <app-tlumacz [id]="'Status dostępności'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{ element.No }}</td>
      </ng-container>

      <ng-container matColumnDef="IndexProduct">
        <th mat-header-cell *matHeaderCellDef id="indeks_produkt2">
          <span id="indeks_produkt" [translation]="TranslationEnum.Translation"> {{ 'indeks_produkt' | translate }} </span><br />(Indeks produkt)
          <!-- <app-tlumacz [id]="'Status dostępności'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{element.ProductIndex}}</td>
      </ng-container>

      <ng-container matColumnDef="ProductName">
        <th mat-header-cell *matHeaderCellDef id="produkt_nazwa2" >
        <span id="produkt_nazwa" [translation]="TranslationEnum.Translation">{{ 'produkt_nazwa' | translate }}</span><br />(Nazwa produktu)
          <!-- <app-tlumacz [id]="'Status dostępności'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{element.ProductName}}</td>
      </ng-container>

      <ng-container matColumnDef="IdOfOrderingPerson">
        <th mat-header-cell *matHeaderCellDef id="id_zamawiajacego2">
          <span id="id_zamawiajacego" [translation]="TranslationEnum.Translation">{{ 'id_zamawiajacego' | translate }}</span><br />(Id zamawiającego)
          <!-- <app-tlumacz [id]="'Status dostępności'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{element.IdOfOrderingPerson}}</td>
      </ng-container>

      <ng-container matColumnDef="Proforma">
        <th mat-header-cell *matHeaderCellDef id="proforma2">
          <span id="proforma" [translation]="TranslationEnum.Translation">{{ 'proforma' | translate }}</span><br />(Proforma)
          <!-- <app-tlumacz [id]="'Status dostępności'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{ element.Proforma }}</td>
      </ng-container>

      <ng-container matColumnDef="OrderWdt">
        <th mat-header-cell *matHeaderCellDef id="numer_zlecenia2">
          <span id="numer_zlecenia" [translation]="TranslationEnum.Translation">{{ 'numer_zlecenia' | translate }}</span><br />(Numer zlecenia)
          <!-- <app-tlumacz [id]="'Status dostępności'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{ element.OrderWdt }}</td>
      </ng-container>

      <ng-container matColumnDef="OrderId">
        <th mat-header-cell *matHeaderCellDef id="OrderId2">
          <span id="orderid" [translation]="TranslationEnum.Translation">{{ 'orderid' | translate }}</span><br />(Id zam. według nabywcy)
          <!-- <app-tlumacz [id]="'Komentarz'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{ element.OrderId }}</td>
      </ng-container>

      <ng-container matColumnDef="Unit">
        <th mat-header-cell *matHeaderCellDef id="jm2">
          <span id="jm" [translation]="TranslationEnum.Translation">{{ 'jm' | translate }}</span><br />(JM)
          <!-- <app-tlumacz [id]="'Komentarz'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{ element.UnitOfMeasure }}</td>
      </ng-container>

      <ng-container matColumnDef="Quantity">
        <th mat-header-cell *matHeaderCellDef id="ilosc2">
          <span id="ilosc" [translation]="TranslationEnum.Translation">{{ 'ilosc' | translate }}</span><br />(Ilość)
          <!-- <app-tlumacz [id]="'Wartość parametru'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{ element.Quantity  }}</td>
      </ng-container>

      <ng-container matColumnDef="PackageDelivered">
        <th mat-header-cell *matHeaderCellDef id="paczek_dostarczono2">
          <span id="paczek_dostarczono" [translation]="TranslationEnum.Translation">{{ 'paczek_dostarczono' | translate }}</span><br />(Dostarczone paczki)
          <!-- <app-tlumacz [id]="'Komentarz'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{ element.PackageDelivered }}</td>
      </ng-container>

      <ng-container matColumnDef="ValueNet">
        <th mat-header-cell *matHeaderCellDef id="wartosc_net2">
          <span id="wartosc_net" [translation]="TranslationEnum.Translation">{{ 'wartosc_net' | translate }}</span><br />(Wartość netto)
          <!-- <app-tlumacz [id]="'Parametr'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{ element.ValueNet }}</td>
      </ng-container>

      <ng-container matColumnDef="Net">
        <th mat-header-cell *matHeaderCellDef id="net2">
          <span id="net" [translation]="TranslationEnum.Translation">{{ 'net' | translate }}</span><br />(Cena Netto)<br />EUR
          <!-- <app-tlumacz [id]="'Wartość parametru'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{ element.Net }}</td>
      </ng-container>

      <ng-container matColumnDef="Vat">
        <th mat-header-cell *matHeaderCellDef id="vat2">
          <span id="vat" [translation]="TranslationEnum.Translation">{{ 'vat' | translate }}</span><br />(Stawka VAT)
          <!-- <app-tlumacz [id]="'Wartość parametru'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{ element.Vat}}</td>
      </ng-container>


      <ng-container matColumnDef="ValueGross">
        <th mat-header-cell *matHeaderCellDef id="brutto2" >
        <span id="brutto" [translation]="TranslationEnum.Translation">{{ 'brutto' | translate }}</span><br />(Wartość Brutto) <br />EUR
          <!-- <app-tlumacz [id]="'Wartość parametru'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let element">{{ element.ValueGross}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
      </table>
      <div class="text-left" style="overflow-y: auto; font-size: small;">
        <b><span id="trescewdt1" [translation]="TranslationEnum.Translation"> {{'trescewdt1' | translate}} </span></b> <br/>
        <span class="font-italic">Potwierdzam przyjęcie jakościowo i ilościowo ww. towarów (produktów) bez zastrzeżeń przez nabywcę w miejscu (pod wskazanym powyżej adresem dostawy), które znajduje się na terytorium państwa członkowskiego UE innego niż Rzeczpospolita Polska.</span><br/>
        <b><span id="trescewdt2" [translation]="TranslationEnum.Translation"> {{'trescewdt2' | translate}} </span></b> <br/>
        <span class="font-italic">Niniejsze potwierdzenie zostało złożone przez Kupującego w celu potwierdzenia dokonania przez Sprzedawcę dostawy towarów (wskazanych powyżej) na rzecz Kupującego, która to dostawa stanowi dla Sprzedawcy Wewnątrzwspólnotową dostawę towarów (0% VAT w Polsce). </span><br/>
        <b><span id="trescewdt3" [translation]="TranslationEnum.Translation"> {{'trescewdt3' | translate}} </span></b><br/>
        <span class="font-italic">W cenie towarów uwzględnia się koszt transportu tych towarów zgodnie z umową. Koszt transportu może zostać wskazany w odrębnej pozycji na fakturze.</span>
      </div>
      <button mat-flat-button *ngIf="!item.IsDeliveryConfirmed && ConfirmationEWdt" style="line-height: normal;" (click)="confirmWdt(item)" class="float-right text-center" color="primary" >
        <span id="confirmintracommunity" [translation]="TranslationEnum.Translation"> {{'confirmintracommunity' | translate}} </span> <br/>
        (POTWIERDZAM WENĄTRZWSPÓLNOTOWĄ DOSTAWĘ TOWARÓW)
      <!-- <app-tlumacz [id]="'Wygeneruj proformę'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </button>
    </div>
  </ng-container>
</div>
<mat-dialog-actions>


  <div class="w-100">
    <button mat-flat-button id="anuluj"  class="float-right" [translation]="TranslationEnum.Translation" color="primary" mat-dialog-close>
      {{ 'anuluj' | translate }}
    </button>
    <button mat-flat-button *ngIf = "ConfirmationEWdt" class="float-right mr-3 text-center" style="line-height: normal;" color="primary" (click)="confirmAllWdt()">
      <span id="confirmintracommunity2" [translation]="TranslationEnum.Translation"> {{'confirmintracommunity2' | translate}} </span> <br/>
       POTWIERDZAM WSKAZANE POWYŻEJ WEWNĄTRZWSPÓLNOTOWE DOSTAWY TOWARÓW
    </button>
  </div>
</mat-dialog-actions>
