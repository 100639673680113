import { Component, OnInit } from '@angular/core';
import { TranslationEnum } from '../shared/enums/translation-enum';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-braki',
  templateUrl: './braki.component.html',
  styleUrls: ['./braki.component.scss']
})
export class BrakiComponent implements OnInit {
  public TranslationEnum = TranslationEnum;
  culture: string;
  public braki;
  constructor(private translateService: TranslateService, private httpClient: HttpClient) {
    this.culture = window['culture'];
    translateService.onLangChange.subscribe(() => {
      this.culture = window['culture'];
      this.GetBraki(this.culture).
      subscribe((data) => {
        //this.statusy = data
        // console.log("braki", data);
        this.braki = data;
      });
    });
  }

  ngOnInit(): void {
    this.GetBraki(this.culture).
    subscribe((data) => {
      //this.statusy = data
      // console.log("braki", data);
      this.braki = data;
    });

    
  }



  public GetBraki(language: string): Observable<any> {
    //var body:PostBody = new PostBody();
    //body.Id = id_reklamacji;
    //body.PrintLabelCount = ilosc_etykiet;

    return this.httpClient.get<Blob>('api/Additives/GetMaterialShortages?language=' + language);

  }
}
