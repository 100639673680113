import { PreviewFileDialogComponent } from './../../preview-file/preview-file-dialog.component';
import { Parameter } from './../../../models/form-model/models/form-controlls.model';
import { FormService } from 'src/app/shared/service/form.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { FileControl } from 'src/app/shared/models/form/file-control.model';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';



@Component({
  selector: 'app-checkbox-file',
  templateUrl: './checkbox-file.component.html',
  styleUrls: ['./checkbox-file.component.scss'],
})
export class CheckboxFileComponent implements OnInit {
  public TranslationEnum = TranslationEnum;

  public EnabledFile: boolean;
  public Control: HtmlControl;
  public Group: FormGroup;
  public DisplayValue: string;

  private file: FileControl = new FileControl();
  private controlOptions: Parameter;

  constructor(private formService: FormService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.EnabledFile = this.Control.value !== '';

    this.controlOptions = this.Control.options[0];
    this.file.Id = this.controlOptions.Key;
    this.file.Key = this.Control.name;
    this.DisplayValue = '';

    if (this.EnabledFile) {
      if (this.Control.value !== '') {
        this.DisplayValue = this.controlOptions.DisplayValue;
        this.Control.value = this.controlOptions;
        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };
        this.file.File = b64toBlob(this.controlOptions.File);
        this.file.FileName = this.controlOptions.FileName;
        // console.log("ff",  this.file);
        this.formService.Files.push(this.file);
        this.Group.get(this.Control.name).setValue(this.Control);
      } else {
        const index = this.formService.Files.findIndex((x) => x.Key === this.file.Key);
        if (index > 0) {
          this.formService.Files.slice(index, 1);
        }
        this.Group.controls[this.Control.name].setValue(null);
        this.Group.get(this.Control.name).clearValidators();
      }
    } else {
      this.Group.controls[this.Control.name].setValue(null);
      this.Group.get(this.Control.name).clearValidators();
      this.Group.get(this.Control.name).setErrors(null);
    }

    this.Control.value = this.controlOptions;
    this.Group.get(this.Control.name).updateValueAndValidity();
  }

  changeImg(e?: any): void {
    // if (this.formService.Files.find((x: FileControl) => x.Id === this.file.Id)) {
    if (this.formService.Files.find((x: FileControl) => x.Key === this.file.Key)) {
      this.formService.Files.splice(this.formService.Files.indexOf(this.file), 1);
    }

    const addFile = e.target.files.item(0);
    this.file.File = addFile;
    this.file.FileName = addFile.name;
    this.formService.Files.push(this.file);
    this.DisplayValue = this.controlOptions.DisplayValue;
    this.Group.get(this.Control.name).setValue(this.Control);
    this.Group.get(this.Control.name).updateValueAndValidity();
  }

  checkChanged(): void {
    const control = this.formService.RemoveControlsFromGroup.find((x) => x.name === this.Control.name && x.module === this.Control.module && x.position === this.Control.position);
    let index: number;
    if (control !== null) {
      index = this.formService.RemoveControlsFromGroup.indexOf(control);
    }

    if (this.EnabledFile) {
      this.Group.get(this.Control.name).setValidators(Validators.required);
      this.Group.get(this.Control.name).updateValueAndValidity();

      if (index !== null && index >= 0) {
        this.formService.RemoveControlsFromGroup.splice(index, 1);
      }
    } else if (!this.EnabledFile) {
      this.DisplayValue = '';
      this.Control.value = null;
      this.Group.controls[this.Control.name].setValue(null);
      this.Group.get(this.Control.name).clearValidators();
      this.Group.get(this.Control.name).setErrors(null);
      this.Group.get(this.Control.name).updateValueAndValidity();

      // if (this.formService.Files.find((x: FileControl) => x.Id === this.file.Id)) {
      if (this.formService.Files.find((x: FileControl) => x.Key === this.file.Key)) {
        this.formService.Files.splice(this.formService.Files.indexOf(this.file), 1);
      }

      if (index < 0) {
        this.formService.RemoveControlsFromGroup.push(this.Control);
      }
    }
  }

  previewFile() {

    if (this.file.File.type.includes('png') || this.file.File.type.includes('jpeg')) {
      const dialogRef = this.dialog.open(PreviewFileDialogComponent, { data: this.file.File });

      dialogRef.afterClosed().pipe(/*take(1)*/).subscribe((result) => {
      });
    } else {
      window.open(URL.createObjectURL(this.file.File), '_blank');
    }
  }


}

