<h1 id="lista" [translation]="TranslationEnum.Translation">
  {{'lista'|translate}}
  <!-- <app-tlumacz [id]="'lista'" [typ]="'zmienne'" [tresci]="tresci"></app-tlumacz> -->
</h1>
<mat-checkbox color="primary" class=" m-2" #isMineOrders [(ngModel)]="IsMineOrders" (change)="refresh();">
  <label id="czymojezam" [translation]="TranslationEnum.Translation">
    {{'czymojezam'|translate}}
  </label>
  <!-- <app-tlumacz [id]="'CzyMojeZam'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
</mat-checkbox>
<mat-checkbox *ngIf = "IsTrader || CreateComplaintOrder" color="primary" class=" m-2"  [(ngModel)]="ShowComplaints" (change)="refresh();">
  <label id="czyreklamacja" [translation]="TranslationEnum.Translation">
    {{'czyreklamacja'|translate}}
  </label>
</mat-checkbox>



<mat-tab-group  [selectedIndex]="selected_id" animationDuration="0ms" class="d-inline">
  <mat-tab *ngIf="!BlockingB2B"  label="First">
    <ng-template mat-tab-label>
      <span id="opracowywane" [translation]="TranslationEnum.Translation">
        {{'opracowywane'|translate}}
      </span>
      {{StatuesQuantity.Opracowywane !== 0 ? '(' + StatuesQuantity.Opracowywane + ')': ''}}
    </ng-template>
    <ng-template matTabContent>
      <app-order-list [status]="+StatusEnum.Opracowywane" [refresh]="refreshRegister"
        [isMineOrders]="isMineOrders.checked" [ShowComplaints] = "ShowComplaints" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-order-list>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="!BlockingB2B"  label="zamowiono">
    <ng-template mat-tab-label>
      <span id="zamowiono" [translation]="TranslationEnum.Translation">
        {{'zamowiono'|translate}}
      </span>
      {{StatuesQuantity.Zamowiono !== 0 ? '(' + StatuesQuantity.Zamowiono + ')': ''}}
    </ng-template>
    <ng-template matTabContent>
      <app-order-list [status]="+StatusEnum.Zamowiono" [refresh]="refreshRegister" [isMineOrders]="isMineOrders.checked" [ShowComplaints] = "ShowComplaints"
        [dateFrom]="dateFrom" [dateTo]="dateTo"></app-order-list>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="!BlockingB2B" label="realizacja">
    <ng-template mat-tab-label>
      <span id="realizacja" [translation]="TranslationEnum.Translation">
        {{'realizacja'|translate}}
      </span>
      {{StatuesQuantity.Realizacja !== 0 ? '(' + StatuesQuantity.Realizacja + ')': ''}}
    </ng-template>
    <ng-template matTabContent>
      <app-order-list [status]="+StatusEnum.Realizacja" [refresh]="refreshRegister"
        [isMineOrders]="isMineOrders.checked" [ShowComplaints] = "ShowComplaints" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-order-list>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="!BlockingB2B" label="wstrzymano">
    <ng-template mat-tab-label>
      <span id="wstrzymano" [translation]="TranslationEnum.Translation">
        {{'wstrzymano'|translate}}
      </span>
      {{StatuesQuantity.Wstrzymano !== 0 ? '(' + StatuesQuantity.Wstrzymano + ')': ''}}
    </ng-template>
    <ng-template matTabContent>
      <app-order-list [status]="+StatusEnum.Wstrzymano" [refresh]="refreshRegister"
        [isMineOrders]="isMineOrders.checked" [ShowComplaints] = "ShowComplaints" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-order-list>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="!BlockingB2B" label="zakonczono">
    <ng-template mat-tab-label>
      <span id="zakonczono" [translation]="TranslationEnum.Translation">
        {{'zakonczono'|translate}}
      </span>
      {{StatuesQuantity.Zakonczono !== 0 ? '(' + StatuesQuantity.Zakonczono + ')': ''}}
    </ng-template>
    <ng-template matTabContent>
      <app-order-list [status]="+StatusEnum.Zakonczono" [refresh]="refreshRegister"
        [isMineOrders]="isMineOrders.checked" [ShowComplaints] = "ShowComplaints" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-order-list>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="!BlockingB2B" label="anulowano">
    <ng-template mat-tab-label>
      <span id="anulowano" [translation]="TranslationEnum.Translation">
        {{'anulowano'|translate}}
      </span>
      {{StatuesQuantity.Anulowano !== 0 ? '(' + StatuesQuantity.Anulowano + ')': ''}}
    </ng-template>
    <ng-template matTabContent>
      <app-order-list [status]="+StatusEnum.Anulowano" [refresh]="refreshRegister" [isMineOrders]="isMineOrders.checked" [ShowComplaints] = "ShowComplaints"
        [dateFrom]="dateFrom" [dateTo]="dateTo"></app-order-list>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="!BlockingB2B"  label="platnosci">
    <ng-template mat-tab-label>
      <span id="platnosci" [translation]="TranslationEnum.Translation">
        {{'platnosci'|translate}}
      </span>
      {{StatuesQuantity.Platnosci !== 0 ? '(' + StatuesQuantity.Platnosci + ')': ''}}
    </ng-template>
    <ng-template matTabContent>
      <app-proforms-list [isMineOrders]="isMineOrders.checked" [dateFrom]="dateFrom" [dateTo]="dateTo" [refresh]="refreshRegister">
      </app-proforms-list>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="EnableEWdt" label="ewdt">
    <ng-template mat-tab-label>
      <span id="potwierdzenie_e-wdt" [translation]="TranslationEnum.Translation">
        {{'potwierdzenie_e-wdt'|translate}}
      </span>
    </ng-template>
    <ng-template matTabContent>
      <app-delivery-list [refresh]="refreshRegister" [isMineOrders]="isMineOrders.checked" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-delivery-list>
    </ng-template>
  </mat-tab>
</mat-tab-group>

