<h2 mat-dialog-title id="wymagana_akc" [translation]="TranslationEnum.TranslationForm">
  {{ 'wymagana_akc'|translate }}
</h2>
<div mat-dialog-content>
  <div class="overflow-auto possitions">
    <table mat-table [dataSource]="DataSource" class="w-100">
      <ng-container matColumnDef="tytul_pow">
        <th mat-header-cell *matHeaderCellDef id="tytul_pow" [translation]="TranslationEnum.TranslationForm">
          {{ 'tytul_pow'|translate }}
        </th>
        <td mat-cell *matCellDef="let notification">
          {{ notification.Title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="tresc_pow">
        <th mat-header-cell *matHeaderCellDef id="tresc_pow" [translation]="TranslationEnum.TranslationForm">
          {{ 'tresc_pow'|translate }}
        </th>
        <td mat-cell *matCellDef="let notification">
          <span [innerHtml]="notification.Content|styleP: 'margin': '0'"></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="zalacznik">
        <th mat-header-cell *matHeaderCellDef id="zalacznik" [translation]="TranslationEnum.TranslationForm">
          {{ 'zalacznik'|translate }}
        </th>
        <td mat-cell *matCellDef="let notification">
          <ng-container *ngIf="notification.Document !== null && notification.Document !== undefined">
            <a mat-icon-button
              [href]="serverUrl + '/api/Notification/GetNotificaionFile?fileId=' + notification.Document.Id"
              method="POST" target="_blank" class="text-center" [color]="ColorNotification">
              <mat-icon>picture_as_pdf</mat-icon>
            </a>
          </ng-container>

        </td>
      </ng-container>
      <ng-container matColumnDef="akceptacja">
        <th mat-header-cell *matHeaderCellDef id="akceptacja"></th>
        <td mat-cell *matCellDef="let notification">
          <button mat-flat-button color="primary" class="d-block" (click)="acceptOrDeclineNotification(true, notification.Id)" id="akceptuj" [translation]="TranslationEnum.Translation">
            {{'akceptuj'|translate}}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="DisplayedColumns"></tr>
      <tr mat-row *matRowDef="let notification; columns: DisplayedColumns"></tr>
    </table>
  </div>
</div>
