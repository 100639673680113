import { environment } from 'src/environments/environment';
import { NotificationUser } from './../../shared/models/notifications/notification-user.model';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from '../notifications.service';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/shared/models/user/user.model';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss'],
})
export class NotificationsModalComponent implements OnInit {
  public TranslationEnum = TranslationEnum;
  public DisplayedColumns: string[];
  public DataSource = new MatTableDataSource<any>();
  public serverUrl: string;
  public ColorNotification = 'accent';
  private notifications: any;

  private user: User;

  constructor(public dialogRef: MatDialogRef<NotificationsModalComponent>, public notificationsService: NotificationsService,
    @Inject(MAT_DIALOG_DATA) data: any, private cdr: ChangeDetectorRef) {
    this.DisplayedColumns = ['tytul_pow', 'tresc_pow', 'zalacznik', 'akceptacja'];
    this.notifications = data.NotifcationsWithDocument;
    this.DataSource.data = data.NotifcationsWithDocument;
    this.user = data.User;
    this.serverUrl = environment.apiUrl;
  }

  public acceptOrDeclineNotification(acceptance: boolean, notificationId: number) {
    const notificationModel = new NotificationUser();

    notificationModel.Date = new Date();
    notificationModel.NotificationId = notificationId;
    notificationModel.Acceptance = acceptance;
    notificationModel.Username = this.user.Username;
    const indexToRemove = this.notifications.findIndex((x) => x.Id === notificationId);
    this.notifications.splice(indexToRemove, 1);
    this.DataSource = new MatTableDataSource<any>(this.notifications);
    this.cdr.detectChanges();
    this.notificationsService.saveUserAcceptance(notificationModel);
    if (this.DataSource.data.length === 0) {
      this.dialogRef.close();
    }
  }

  ngOnInit() { }
}
