import { FormGroup } from '@angular/forms';
import { PositionsTab } from '../form-model/models/position.model';
import { PositionModelComponent } from './../form-model/models/component-edit.model';

export class DialogPositionResult {
  PositionsTab: PositionsTab = new PositionsTab;
  PositionToEdit: PositionModelComponent;
  LastStep: string;
  ModuleType: string;
  ErrorHasApperd: boolean;
}
