import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslationEnum } from '../shared/enums/translation-enum';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-dokumenty',
  templateUrl: './dokumenty.component.html',
  styleUrls: ['./dokumenty.component.scss']
})
export class DokumentyComponent implements OnInit {
  typ: string;
  wys: string;
  public TranslationEnum = TranslationEnum;
  linki: any;
  systemy: any;
  grupy: any = [];

  constructor(private activatedRoute: ActivatedRoute, private translateService: TranslateService, private httpClient: HttpClient, private router: Router) {

    this.activatedRoute.params.subscribe((params) => {
      this.typ = params.typ;
      this.wys = params.wys;
    });
  }

  ngOnInit(): void {
    this.getLinksApi().
    subscribe((data) => {

      // console.log("dok: ", data);
      var n_tmp = '';
      var id_wzor = 0;
      var linki_tmp = data;
      this.linki = [];
      linki_tmp.forEach(l => {
        if(l.nazwa_s  != n_tmp && l.id_wzor != id_wzor){
          n_tmp = l.nazwa_s;
          id_wzor = l.id_wzor;
          this.linki.push(l);
        }
      });
      
    });
    this.getSystemy().
    subscribe((data) => {

      // console.log("sys: ", data);
      this.systemy = data;

      var sys = '';
      this.grupy = [];
      this.systemy.forEach(s => {
        if(sys != s.nazwa_grupa_system_s){
          sys = s.nazwa_grupa_system_s;
          this.grupy.push(s);
        }
      });
    });
    
  }
  returnToStrefa(){
    this.router.navigate(['Strefa']);
  }
  getNazwa(dok){
    if(dok.nazwa_s == null || dok.nazwa_s == ''){
      return dok.nazwa;
    }
    return dok.nazwa_s;
  }
  public NazwaPliku(nazwa:string, url){
    nazwa = nazwa.replace('/','');
    nazwa = nazwa.replace('  ',' ');
    var roz = url.split(".");
    return nazwa +"."+ roz[roz.length - 1];
  }

  public getLinksApi(): Observable<any> {
   
    return this.httpClient.post<any>('https://plikiapi.selt.com/input.php?tryb=dokumenty&typ='+this.typ+'&wys='+this.wys+'&jezyk='+this.getJezykId(window['culture']), null, {
      headers: this.getHeaderJson(),
    });
  }
  public getSystemy(): Observable<any> {
   
    return this.httpClient.post<any>('https://plikiapi.selt.com/input.php?tryb=systemy&jezyk='+this.getJezykId(window['culture']), null, {
      headers: this.getHeaderJson(),
    });
  }
  public getLink(dok){
    return 'https://plikiapi.selt.com/download/'+this.NazwaPliku(this.getNazwa(dok), dok.url)+'?nazwa=' + dok.url ;
  }
  public getJezykId(jezyk:string){
    switch (jezyk) {
      case 'pl': {
        return 2;
        break;
      }
      case 'en': {
        return 4;
        break;
      }
      case 'de': {
        return 3;
       break;
      }
      case 'fr': {
        return 5;
        break;
      }
      case 'cs': {
        return 6;
        break;
      }
      case 'it': {
        return 7;
        break;
      }
      case 'esp': {
        return 8;
        break;
      }
      default: {
        return 2;
        break;
      }
    }
        
  }
  public getHeaderJson() {
    const header: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }
}
