import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {
  public TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
  }

  openPdf(): void {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = this.Control.value;
    link.click();
    link.remove();
  }
}
