import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from '../shared/models/invoice/invoice.model';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  constructor(private httpClient: HttpClient) {}

  public getInvoices(dateFrom: string, dateTo: string, filterTerm, filterColumn, sortColumn, sortDirection, pageIndex, pageSize): Observable<any> {
    return this.httpClient.post<any>(
      `api/Invoice/GetInvoices/?ContractorNumber=${
        JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber
      }&dateFrom=${dateFrom}&dateTo=${dateTo}&searchColumn=${filterColumn}&searchTerm=${filterTerm}&columnKeySort=${sortColumn}&kindSort=${sortDirection}&pageNumber=${pageIndex}&itemPerPage=${pageSize}`,
      {}
    );
    // return this.httpClient.post<any>('api/ActiveForm/GetStepResultInfo', {});
  }

  public getDebts(dateFrom: string, dateTo: string, filterTerm: string, filterColumn: string, sortColumn: string, sortDirection: string, pageIndex: string, pageSize: string) {
        return this.httpClient.post<any>(
          `/api/Debt/GetDebts/?ContractorNumber=${
            JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber
          }&dateFrom=${dateFrom}&dateTo=${dateTo}&searchColumn=${filterColumn}&searchTerm=${filterTerm}&columnKeySort=${sortColumn}&kindSort=${sortDirection}&pageNumber=${pageIndex}&itemPerPage=${pageSize}`,
          {}
        );
  }
}
