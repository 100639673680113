import { FormService } from 'src/app/shared/service/form.service';
import { TreeTechnologyService } from './../tree-technology.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tree-technology',
  templateUrl: './tree-technology.component.html',
  styleUrls: ['./tree-technology.component.scss'],
})
export class TreeTechnologyComponent implements OnInit, OnDestroy {
  private nodes: any[];
  private indexNode: number = 0;

  public TreeControl = new NestedTreeControl<any>((node) => node.children);
  public DataSource = new MatTreeNestedDataSource<any>();
  public ResultInfo: any;
  public IsLoading: boolean;
  public IsExpandAll: boolean;
  public IsValuation: boolean;
  public TechName: string;
  private subscriptions: Subscription[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, private formService: FormService) {

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit(): void {
        this.IsLoading = true;
        this.nodes = [];
        this.subscriptions.push(this.formService.getResultInfoForStep(this.dialogData.FormGroupForResultInfo, this.dialogData.StepForResultInfo, false, this.dialogData.Parameters, this.dialogData.ProductPrefix, this.dialogData.IsTrader).subscribe((x) => {
          if (x !== null && x !== undefined) {
            this.ResultInfo = x;
            this.fillNodes();
            this.IsLoading = false;
            this.TechName = JSON.stringify(x.TechName);
          }
        }));
        // this.TechName = JSON.stringify(tech);
  }

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  private fillNodes(): void {
    this.nodes.push({
      id: this.indexNode++,
      name: this.ResultInfo.MasterResult.Name,
      elapsedTime: this.ResultInfo.MasterResult.ElapsedTime,
      scriptKind: this.ResultInfo.MasterResult.ScriptKindName,
      techName: this.ResultInfo.TechName,
      children: [],
      result: {
        WynikiExist: this.ResultInfo.MasterResult.WynikiExist,
        WynikiTab: this.ResultInfo.MasterResult.WynikiTab,
        Parametry: this.ResultInfo.MasterResult.Parametry,
        ParametryWyliczeniowe: this.ResultInfo.MasterResult.ParametryWyliczeniowe,
        ParametryZProgramu: this.ResultInfo.MasterResult.ParametryZProgramu,
        ParametryTabelaryczne: this.ResultInfo.MasterResult.ParametryTabelaryczne,
        KompilacjaBledy: this.ResultInfo.MasterResult.KompilacjaBledy,
      },
    });

    this.createTree(this.nodes[0], this.ResultInfo.MasterResult.FunctionResults);

    this.createTree(this.nodes[0], this.ResultInfo.ComponentsResults);
    this.TreeControl.expand(this.nodes[0]);

    // this.tree.treeModel.update();
    this.DataSource.data = this.nodes;
    this.TreeControl.dataNodes = this.nodes;
  }

  public createTree(node: any, results: any[]): void {
    let i = 0;

    results.forEach((resultForChild) => {
      node.children.push({ id: this.indexNode++, name: resultForChild.NameWithPrefix, elapsedTime: resultForChild.ElapsedTime, result: resultForChild, children: [] });
      this.createTree(node.children[i], resultForChild.FunctionResults);
      i++;
    });
  }

  public expandAll() {
    if (!this.IsExpandAll) {
      this.TreeControl.expandAll();
      this.IsExpandAll = true;
    } else {
      this.TreeControl.collapseAll();
      this.IsExpandAll = false;
    }
  }

  public selectNode(activeNode: any): void {
    this.ResultInfo = activeNode.result;
    // this.TechName = activeNode.techName;
    //   let detailsDiv = document.querySelector('div#detailsResult').innerHTML;
  }
}
