import { SafeUrl } from '@angular/platform-browser';

export class KimSave {

  public Index: string;
  public Quantity: number;
  public Position: number;
  public Module: number;

  public constructor(){
               
  }
}
