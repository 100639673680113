<div class="container overflow-hidden">
  <div class="row my-3">
    <div class="col-lg-4 col-md-6 MA MAD">
      <div class="row">
        <div class="col-6 MA" (click)="routeToNewOrder('MA')">
          <div class="system">
            <img width="100%" src="../../assets/images/SVG/lista/Markizy.svg">
            <p id="markiza" [translation]="TranslationEnum.Translation">
              {{'markiza'|translate}}
            </p>
          </div>
        </div>
        <div class="col-6 MAD" (click)="routeToNewOrder('MAD')">
          <div class="system">
            <img class="details" width="100%" src="../../assets/images/SVG/lista/Detale.svg">
            <p id="markiza_detale" [translation]="TranslationEnum.Translation">
              {{'markiza_detale'|translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <mat-divider class="mt-5 mb-5 MA MAD" [vertical]="true"></mat-divider>
    <div class="col-lg-4 col-md-6 PG MP" >
      <div class="row">
        <div class="col-6 PG" (click)="routeToNewOrder('PG')">
          <div class="system">
            <img width="100%" src="../../assets/images/SVG/lista/Pergola.svg">
            <p id="pergola" [translation]="TranslationEnum.Translation">
              {{'pergola'|translate}}
            </p>
          </div>
        </div>
        
      </div>
    </div>
    <mat-divider class="mt-5 mb-5 MA MAD" [vertical]="true"></mat-divider>
    <div class="col-lg-4 col-md-6 PG MP" >
      <div class="row">
        <div class="col-6 MP" (click)="routeToNewOrder('MP')">
          <div class="system">
            <img width="100%" src="../../assets/images/SVG/lista/PergolaV.svg">
            <p id="pergola_v" [translation]="TranslationEnum.Translation">
              {{'pergola_v'|translate}}
            </p>
          </div>
        </div>
        <div class="col-6 MP" (click)="routeToNewOrder('MPD')">
          <div class="system">
            <img width="100%" src="../../assets/images/SVG/lista/Detale.svg">
            <p id="pergola_v_detal" [translation]="TranslationEnum.Translation">
              {{'pergola_v_detal'|translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <mat-divider class="mt-5 mb-5 PG MP" [vertical]="true"></mat-divider>
    <div class="col-lg-4 col-md-6 FS FSD">
      <div class="row">
        <div class="col-6 FS" (click)="routeToNewOrder('FS')">
          <div class="system">
            <img width="100%" src="../../assets/images/SVG/lista/Zaluzje.svg">
            <p id="żaluzje_fasadowe" [translation]="TranslationEnum.Translation">
              {{'żaluzje_fasadowe'|translate}}
            </p>
          </div>
        </div>
        <div class="col-6 FSD" (click)="routeToNewOrder('FSD')">
          <div class="system">
            <img class="details" width="100%" src="../../assets/images/SVG/lista/Detale.svg">
            <p id="żaluzje_fasadowe_detale" [translation]="TranslationEnum.Translation">
              {{'żaluzje_fasadowe_detale'|translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <mat-divider class="mt-5 mb-5 FS FSD" [vertical]="true"></mat-divider>
    <div class="col-lg-4 col-md-6 FR FRD">
      <div class="row">
        <div class="col-6 FR" (click)="routeToNewOrder('FR')">
          <div class="system">
            <img width="100%" src="../../assets/images/SVG/lista/Refleksole.svg">
            <p id="refleksol" [translation]="TranslationEnum.Translation">
              {{'refleksol'|translate}}
            </p>
          </div>
        </div>
        <div class="col-6 FRD" (click)="routeToNewOrder('FRD')">
          <div class="system">
            <img class="details" width="100%" src="../../assets/images/SVG/lista/Detale.svg">
            <p id="refleksol_detale" [translation]="TranslationEnum.Translation">
              {{'refleksol_detale'|translate}}
            </p>
          </div>
        </div>
      </div>

    </div>

    <mat-divider class="mt-5 mb-5 FR" [vertical]="true"></mat-divider>
    <div class="col-lg-4 col-md-6 WGS">
      <div class="row">
        <div class="col-6 WGS" (click)="routeToNewOrder('WGS')">
          <div class="system">
            <img width="100%" src="../../assets/images/SVG/lista/WindGlass.svg">
            <p id="glass_system" [translation]="TranslationEnum.Translation">
              {{'glass_system'|translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <mat-divider class="mt-5 mb-5 WGS" [vertical]="true"></mat-divider>
    <div class="col-lg-4 col-md-6 MV">
      <div class="row">
        <div class="col-6 MV" (click)="routeToNewOrder('MV')">
          <div class="system">
            <img width="100%" src="../../assets/images/SVG/lista/Veranda.svg">
            <p id="veranda" [translation]="TranslationEnum.Translation">
              {{'veranda'|translate}}
            </p>
          </div>
        </div>
         <div class="col-6 MVD" (click)="routeToNewOrder('MVD')">
          <div class="system">
            <img class="details" width="100%" src="../../assets/images/SVG/lista/Detale.svg">
            <p id="veranda_detale" [translation]="TranslationEnum.Translation">
              {{'veranda_detale'|translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
