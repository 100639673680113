<div mat-dialog-content>

  <!-- Filtr term -->
  <mat-form-field appearance="outline" color="accent">
    <input matInput (keyup)="applyFilter(input)" #input>
    <button mat-button matSuffix mat-icon-button class="d-inline-block" (click)="applyFilter(input)">
      <mat-icon>search</mat-icon>
    </button>
    <button mat-button *ngIf="input.value" matSuffix mat-icon-button class="d-inline-block" (click)="input.value=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Traders list -->
  <div class="mw-100">

    <!-- Progress spinner -->
    <div class="loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      <div *ngIf="isRateLimitReached" id="wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę"
        class="rate-limit-reached" [translation]="TranslationEnum.Translation">
        {{'wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę'|translate}}
        <!-- <app-tlumacz [id]="'Wystąpił błąd połączenia. Proszę odświeżyć za minutę.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </div>
    </div>

    <div>
      <!-- Table -->
      <table mat-table [dataSource]="dataSource" matSort matSortActive="ContractorNumber" matSortDirection="desc"
        matSortDisableClea class="mat-elevation-z8 w-100">

        <!-- Contractor number column -->
        <ng-container matColumnDef="ContractorNumber">
          <th mat-header-cell *matHeaderCellDef id="numerkontrahenta" [translation]="TranslationEnum.Translation">
            {{'numerkontrahenta'|translate}}
          </th>
          <td mat-cell *matCellDef="let row" (click)="choose(row)" [mat-dialog-close]="row"> {{row.ContractorNumber}}
          </td>
        </ng-container>

        <!-- Contractor name column -->
        <ng-container matColumnDef="ContractorName">
          <th mat-header-cell *matHeaderCellDef id="nazwakontrahenta" [translation]="TranslationEnum.Translation">
            {{'nazwakontrahenta'|translate}}
          </th>
          <td mat-cell *matCellDef="let row" (click)="choose(row)" [mat-dialog-close]="row"> {{row.ContractorName}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row bg-white" *matNoDataRow>
          <td colspan="4" id="brak_danych_do_wyświetlenia" class="mat-cell" [translation]="TranslationEnum.Translation">
            {{'brak_danych_do_wyświetlenia'|translate}}
            <!-- <app-tlumacz [id]="'Brak danych do wyświetlenia'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </td>
        </tr>

      </table>
    </div>

    <!-- Paginator -->
    <mat-paginator [length]="resultsLength" [pageSize]="8" [pageSizeOptions]="[8, 16, 24, 32, 40]" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
<div mat-dialog-actions>
  <div class="w-100">
    <button mat-flat-button color="primary" class="btn-sm float-right" [mat-dialog-close]="null"> Anuluj </button>
  </div>
</div>
