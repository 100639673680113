import { Kim } from 'src/app/shared/models/form-model/kim.model';

export class FilterKim {
  Id: number;
  Klucz: string;
  Name: string;
  NumberFilter: number;
}


export class KimDialogData {
  Data: Kim[];
  IsPossition: boolean;
  Max: number;
}
