import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Control } from 'src/app/form-v2/form-model/Control';
import { Values } from 'src/app/form-v2/form-model/Values';
import { DynamicFormComponent } from 'src/app/new-form/initial-form/dynamic-form/dynamic-form.component';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Parameter } from 'src/app/shared/models/form-model/models/form-controlls.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-multi-column-control',
  templateUrl: './select-multi-column-control.component.html',
  styleUrls: ['./select-multi-column-control.component.scss']
})
export class SelectMultiColumnControlComponent implements OnInit {
  public TranslationEnum = TranslationEnum;
  public server_url = environment.apiUrl;
  public values: Values[] = []

  public site = 0;

  constructor(public dialogRef: MatDialogRef<DynamicFormComponent>, @Inject(MAT_DIALOG_DATA) public data: Control) {
    this.values = data.ValuesMultiColumn;
    // console.log("MS",data);
  }

  ngOnInit(): void {
  }
  applyFilter(tresc){
    // console.log(tresc, this.data);
    var val:Values[] = [];
    this.data.ValuesMultiColumn.forEach(v => {
      if(v.Key.toLowerCase().includes(tresc.toLowerCase()) || v.DisplayValue.toLowerCase().includes(tresc.toLowerCase())){
        val.push(v);
      }
    });
    this.values = val;
  }
  wybierz(v:Values){
    // console.log(v);
    var p = new Parameter();
    p.Disabled = v.Disabled;
    p.DisplayValue = v.DisplayValue;
    p.DisplayValueCs = v.DisplayValueCs;
    p.DisplayValueDe = v.DisplayValueDe;
    p.DisplayValueEn = v.DisplayValueEn;
    p.DisplayValueFr = v.DisplayValueFr;
    p.DisplayValueIt = v.DisplayValueIt;
    p.DisplayValuePl = v.DisplayValuePl;
    p.Kolor = v.Kolor;
    p.Key = v.Key;
    //p.Module = v.Module.valueOf();
    //p.Position = v.Position.valueOf();
    //p.Type = v.Type;
    p.Value = v.Key;
    p.IsPrint = this.data.IsPrint;
    
    this.data.wybrany = p;
    this.dialogRef.close();
  }
}
