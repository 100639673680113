<div class="p-3">
  <span class="mr-3 d-inline-block">
    <label class="d-block">
      <span id="dopłata" [translation]="TranslationEnum.TranslationForm">
        {{'dopłata'|translate}}
      </span>
    </label>
    <mat-form-field>
      <mat-select [(value)]="Surcharge">
        <mat-option *ngFor="let surcharge of Surcharges" [value]="surcharge">
          {{surcharge.Name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </span>
  <span class="d-inline-block">
    <label class="d-block">
      <span id="kwota" [translation]="TranslationEnum.TranslationForm">
        {{'kwota'|translate}}
      </span>
    </label>
    <mat-form-field>
      <input [(ngModel)]="Price" type="number" matInput step="0.01" min="0" value="0">
    </mat-form-field>
  </span>

  <button mat-flat-button color="primary" type="button" class="d-block" id="dodaj_doplate"
    [translation]="TranslationEnum.TranslationForm" (click)="addSurcharge()" [disabled]="Preview">
    {{'dodaj_doplate'|translate}}
  </button>

  <table *ngIf="this.dataSource.data.length > 0" mat-table [dataSource]="dataSource" class="mat-elevation-z8 mt-3 mb-3">

    <!-- Surcharge Column -->
    <ng-container matColumnDef="Surcharge">
      <th mat-header-cell *matHeaderCellDef id="dopłata" [translation]="TranslationEnum.TranslationForm">
        {{'dopłata'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Name}} </td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="Price">
      <th mat-header-cell *matHeaderCellDef id="kwota" [translation]="TranslationEnum.TranslationForm">
        {{'kwota'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Price}} </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef id="type" [translation]="TranslationEnum.TranslationForm" class="w-100p">
        {{'type'|translate}}
        <!-- <app-tlumacz [id]="'PackageNumber'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row" id="{{SurchargeTypeEnum[row.Type]}}" [translation]="TranslationEnum.TranslationForm">
        {{SurchargeTypeEnum[row.Type]|translate}}
      </td>
    </ng-container>

    <ng-container matColumnDef="Remove">
      <th mat-header-cell *matHeaderCellDef id="usun" [translation]="TranslationEnum.TranslationForm" class="w-75p" >
        {{'usun'|translate}}
        <!-- <app-tlumacz [id]="'Type'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button color="accent" (click)="removeSurcharge(row)" [disabled]="Preview">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
