import { UserPermission } from './../shared/models/user/user-permission.model';
import { PermissionEnum } from 'src/app/shared/enums/permission-enum';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { User } from '../shared/models/user/user.model';
import { TresciService } from '../tresci.service';
import { take, takeWhile } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class AuthorizationService implements OnDestroy {
  zalogowany: boolean;
  haslo: string;
  exception: any;

  public IsLoged: boolean;
  private subscriptions: Subscription[] = [];

  public User: BehaviorSubject<User>;
  public ShowTechnologyPermission: BehaviorSubject<boolean>;

  private user: User;
  private userPermissions: UserPermission[];

  constructor(private httpClient: HttpClient, private cookieService: CookieService, tresci: TresciService) {
    this.User = new BehaviorSubject<User>(null);
    this.ShowTechnologyPermission = new BehaviorSubject<boolean>(false);

    if (this.cookieService.check('_usi')) {
      const user: User = JSON.parse(this.cookieService.get('_usi'));
      this.User.next(user);
    }

    this.User.pipe(/*take(1)*/).subscribe((user) => {
      if (user) {
        this.user = user;
        this.userPermissions = user.Permissions;
      }
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  public getToken(login: string, password: string): Observable<any> {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('grant_type', 'password');
    urlSearchParams.append('username', login);
    urlSearchParams.append('password', password);
    const body = urlSearchParams.toString();
    return this.httpClient.post<any>('/token', body);
  }

  public login(): Observable<User> {
    return this.httpClient.get<User>('/api/Account/Login');
  }

  public hasPermission(permissionEnum: PermissionEnum): UserPermission {
    if (this.userPermissions) {
      return this.userPermissions.find((permission) => permission.Id === permissionEnum);
    }
    return null;
  }

  public changePermissions(permissionEnum: PermissionEnum) {
    if (this.userPermissions) {
      const userPermission = this.userPermissions.find((permission) => permission.Id === permissionEnum);
      this.user.Permissions.find((permission) => permission.Id === permissionEnum).Value = !userPermission.Value;
      this.cookieService.set('_usi', JSON.stringify(this.user), { path: '/' });
    }
  }

  public resetPassword(username: string, culture: string): Observable<string> {
    const data = {
      username: username,
      culture: culture,
    };
    return this.httpClient.post<string>('/api/Account/ForgotPassword', data);
  }

  public changePassword(password): Observable<void> {
    const body = {
      Password: password,
    };
    return this.httpClient.post<any>('/api/Account/ChangePassword', body);
  }
}


