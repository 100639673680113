import { DirectivesModule } from './../../../shared/directives/directives.module';
import { KimComponent } from './../../../shared/components/form-controls/kim/kim.component';
import { FabricComponent } from './../../../shared/components/form-controls/fabric/fabric.component';
import { FileComponent } from './../../../shared/components/form-controls/file/file.component';
import { MessageDialogComponent } from './../../../shared/components/form-controls/message-dialog/message-dialog.component';
import { SelectImgComponent } from './../../../shared/components/form-controls/select-img/select-img.component';
import { DynamicControlDirective } from './../dynamic-control/dynamic-control.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormRoutingModule } from './dynamic-form-routing.module';
import { DynamicFormComponent } from './dynamic-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from 'src/app/shared/components/form-controls/checkbox/checkbox.component';
import { InputComponent } from 'src/app/shared/components/form-controls/input/input.component';
import { LabelComponent } from 'src/app/shared/components/form-controls/label/label.component';
import { SelectComponent } from 'src/app/shared/components/form-controls/select/select.component';
import { MaterialModule } from 'src/app/material/material.module';
import { NewLineComponent } from 'src/app/shared/components/form-controls/new-line/new-line.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomTranslateLoader } from 'src/app/shared/language-loader/CustomTranslateLoader';
import { CheckFabricComponent } from 'src/app/shared/components/form-controls/checkfabric/checkfabric.component';


@NgModule({
  declarations: [DynamicFormComponent,
    DynamicControlDirective,
    ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader, useClass: CustomTranslateLoader
      }
    }),
    DynamicFormRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    DirectivesModule,
  ],
  entryComponents: [
    InputComponent,
    SelectComponent,
    CheckboxComponent,
    LabelComponent,
    SelectImgComponent,
    NewLineComponent,
    MessageDialogComponent,
    FileComponent,
    FabricComponent,
    CheckFabricComponent,
    KimComponent
  ]
})
export class DynamicFormModule { }
