import { InformationDirective } from './information.directive';
import { PermissionDirective } from './permission.directive';
import { TooltipImgDirective } from './tooltip-img.directive';
import { CommonModule } from '@angular/common';
import { TranslationDirective } from './translation.directive';
import { NgModule } from '@angular/core';



@NgModule({
  declarations: [InformationDirective, PermissionDirective, TooltipImgDirective, TranslationDirective ],
  imports: [CommonModule],
  exports: [InformationDirective, PermissionDirective, TooltipImgDirective, TranslationDirective]
})
export class DirectivesModule { }
