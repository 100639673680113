import { FormGroup } from '@angular/forms';
import { Component, AfterViewChecked, OnInit, AfterViewInit } from '@angular/core';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { ErrorState } from 'src/app/shared/validations/error-class';



@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  public TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;
  public Min: number;
  public Max: number;
  public MinMaxLabel = '';
  // matcher = new ErrorState();


  constructor() {
    // if (this.Control.value === '') {
    //   this.Group.get(this.Control.name)?.setValue('');
    // }
  }

  ngOnInit() {
    if (this.Control.parameters) {
      this.Min = this.Control.parameters.find(x => x.Key === 'Min').Value;
      this.Max = this.Control.parameters.find(x => x.Key === 'Max').Value;
      this.MinMaxLabel = '(' + this.Min + ' - ' + this.Max + ')';
    }
  }

}
