import { Component, AfterViewChecked, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { DataSharingService } from 'src/app/shared/service/data-sharing.service';
import { UniversalDialogComponent } from '../../universal-dialog/universal-dialog.component';
import * as _ from 'lodash';

enum KindMessageDialog {
  Blocked = 'Blocked',
  YesNo = 'YesNo',
  Info = 'Info'
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit, OnDestroy {

  public TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;
  private subscriptions: Subscription[] = [];

  constructor(public dialog: MatDialog, private dataSharingService: DataSharingService, private formBuilder: FormBuilder) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit() {
    this.openDialog();
  }

  openDialog() {
    const kind = this.Control.parameters.find(x => x.Key === 'Kind').Value;
    let anserwOk = true;
    if (kind === KindMessageDialog.Blocked || kind === KindMessageDialog.YesNo) {
      this.dataSharingService.BlockIfMessageDialogAppear.next({ BlockNextStep: true, CurrentStep: this.Control.currentStep })
    }

    if (kind === KindMessageDialog.YesNo) {
      anserwOk = false;
    }

    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;

    const data: DialogData = {
      title: '',
      content: this.Control.value,
      control: this.Control,
      answerOk: anserwOk
    };

    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data, disableClose: true });
    if (kind === KindMessageDialog.YesNo) {
      this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dataSharingService.BlockIfMessageDialogAppear.next({ BlockNextStep: false, CurrentStep: this.Control.currentStep })
        }
      }))
    } else {
      this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {
        const ctrl = this.formBuilder.control(_.cloneDeep(this.Control))
        this.Group.addControl(this.Control.name + '2', ctrl);
       }));
    }

  }
}
