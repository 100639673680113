
import { FormControl, Validators } from '@angular/forms';
import { TechnologyControl } from '../models/form-model/models/form-controlls.model';
import { HtmlControl } from '../models/form-model/models/html-control.model';


export class FormControlMapper {
  public mapToHtml(technologyControl: TechnologyControl, step: string, isLastStepInSpecifiType: boolean): HtmlControl {
    let control = new HtmlControl();
    control = {
      inputType: technologyControl.ControlType.toLocaleLowerCase(),
      name: technologyControl.KeyName,
      componentName: technologyControl.ControlType,
      parameters: technologyControl.Parameters,
      value: technologyControl.DefaultValue,
      width: technologyControl.Width,
      label: technologyControl.TranslateCode.toLowerCase(),
      currentStep: step,
      isPrint: technologyControl.IsPrint,
      displayInTablePosition: technologyControl.DisplayInTablePosition,
      fileInfoId: technologyControl.FileInfoId,
      notAffectToOtherControls: technologyControl.NotAffectToOtherControls,
      isLastStepInSpecificType: isLastStepInSpecifiType,
      pictureWidth: technologyControl.PictureWidth,
      required: technologyControl.Required,
      translateCode: technologyControl.TranslateCode,
      foregrandColour: technologyControl.ForegrandColour,
      backgroundColour: technologyControl.BackgroundColour,
    };
    if (technologyControl.ControlType.toLowerCase() === 'selectmulticolumn' || technologyControl.ControlType.toLowerCase() === 'checkselectmulticolumn') {
      control.optionsSelectMultiColumn = technologyControl.ValuesMultiColumn;
    } else if (technologyControl.ControlType.toLowerCase() === 'kim') {
      control.optionsKim = technologyControl.Values;
    } else {
      control.options = technologyControl.Values;
    }
    if (control.inputType === 'hidden' && control.options !== undefined && control.options.length !== 0) {
      control.options.forEach((element) => {
        if (element.Key === control.value) {
          control.value = element;
        }
      });
    }

    if (technologyControl.ControlType === 'Number' || technologyControl.ControlType === 'CheckNumber') {
      const min = control.parameters.find((x) => x.Key === 'Min').Value;
      const max = control.parameters.find((x) => x.Key === 'Max').Value;
      control.validations = [
        { name: 'required', validator: Validators.required, message: 'podaj_wartosc' },
        { name: 'min', validator: Validators.min(min), message: 'wartosc_mniejsza_min' },
        { name: 'max', validator: Validators.max(max), message: 'wartosc_wieksza_maks' },
      ];
    } else if (technologyControl.Required === true && technologyControl.ControlType !== 'Number' && technologyControl.ControlType !== 'CheckNumber' && technologyControl.ControlType !== 'Label') {
      // || technologyControl.ControlType === 'Fabric'
      control.validations = [{ name: 'required', validator: Validators.required, message: 'podaj_wartosc' }];
    }
    return control;
  }
}
