<h2 mat-dialog-title id="czy_na_pewno_zamowic" [translation]="TranslationEnum.Translation">
  {{'czy_na_pewno_zamowic'|translate}}
  <!-- <app-tlumacz [id]="'Czy_na_pewno_zamowic'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
</h2>
<div mat-dialog-content>
  <form [formGroup]="orderTerms" (submit)="order(orderTerms)" #orderTermsForm="ngForm">
    <mat-checkbox [formControl]="valuation" required>
      <span id="potwierdzam_wycenę_zamówień" [translation]="TranslationEnum.Translation">
        {{'potwierdzam_wycenę_zamówień'|translate}}</span>
      <!-- <app-tlumacz [id]="'Potwierdzam wycenę zamówień.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </mat-checkbox>
    <br />
    <mat-checkbox [formControl]="specification" required>
      <span id="potwierdzam_specyfikację_zamówień" [translation]="TranslationEnum.Translation">
        {{'potwierdzam_specyfikację_zamówień'|translate}}</span>
      <!-- <app-tlumacz [id]="'Potwierdzam specyfikację zamówień.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </mat-checkbox>
    <br />
    <mat-checkbox [formControl]="proform">
        <span style="white-space: pre-wrap;" id="zgadzam_sie_proforma" [translation]="TranslationEnum.Translation" required>
        {{'zgadzam_sie_proforma'|translate}}
        </span>
        <!-- <app-tlumacz [id]="'Potwierdzam specyfikację zamówień.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </mat-checkbox>
    <br/>
    <mat-error *ngIf="(valuation.invalid || specification.invalid) && orderTermsForm.submitted"
      id="musis_zaakceptować_wszystkie_warunki" [translation]="TranslationEnum.Translation">
      {{'musis_zaakceptować_wszystkie_warunki'|translate}}
      <!-- <app-tlumacz [id]="'Musisz zaakceptować wszystkie warunki'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </mat-error>
    <div class="w-100">
      <button mat-flat-button id="anuluj" class="float-right" [translation]="TranslationEnum.Translation" color="primary"
        mat-dialog-close (click)="this.dialogRef.close();">
        {{'anuluj'|translate}}
        <!-- <app-tlumacz [id]="'Anuluj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </button>
      <button mat-flat-button id="zamów" class="float-right mr-3" [translation]="TranslationEnum.Translation" color="primary"
        type="sumbit">
        {{'zamów'|translate}}
        <!-- <app-tlumacz [id]="'zamów'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </button>
    </div>
  </form>
</div>
