<div *ngIf="Control" [formGroup]="Group">
  <label>
    <span id="label_{{Control.label}}" [translation]="TranslationEnum.TranslationForm">
      {{('label_'+ Control.label)|translate}}
    </span>
    <app-information [label]="Control.label" [notAffectOnOtherControl]='Control.notAffectToOtherControls'  [fileInfoId]="Control.fileInfoId"></app-information>
  </label>
  <!-- <app-tlumacz [id]="'label_'+Control.label" [typ]="'form'" [tresci]="tresci"></app-tlumacz> -->
  <mat-form-field appearance="outline" class="w-100" color="accent" (click)="openKimDialog()" style="font-size: 14px">
    <textarea #textarea matInput [formControlName]="Control.name" [value-object]="Control" readonly
    cdkTextareaAutosize #autosize="cdkTextareaAutosize"></textarea>
    <button mat-button type="button" color="accent" matPrefix mat-icon-button class="d-inline-block" [disabled]="textarea.disabled">
      <mat-icon>add</mat-icon>
    </button>
  </mat-form-field>
</div>
