<div class="m-2">

  <div class="d-inline-block mt-3 mr-3">
    <span id="numer_wdt" [translation]="TranslationEnum.Translation">{{ 'numer_wdt' | translate }} : </span> 
    <mat-form-field appearance="outline" class="w-190p" color="accent">
      
      <input matInput (keyup.enter)="applyFilter(input)" #input>
      <button *ngIf="input.value" mat-button matSuffix mat-icon-button class="d-inline-block"
        (click)="applyFilter(input)">
        <mat-icon>search</mat-icon>
      </button>
      <button *ngIf="input.value" mat-button matSuffix mat-icon-button class="d-inline-block"
        (click)="input.value='';applyFilter(input)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline" *ngIf = "radioCheckedOption != 0" class="mt-3" color="accent">
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input readonly matStartDate formControlName="start">
      <input readonly matEndDate formControlName="end" (dateChange)="dateRangeChange($event)">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker" class="d-inline-block"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <mat-radio-group class="ml-3" aria-label="Select an option">
    <mat-radio-button *ngFor="let option of optionsForRadios" class="ml-3" color="primary" [checked]="option.CheckedOnStart" [value]="option.Value" (change)="radioChange(option)">
      <span id="{{option.Display}}" [translation]="TranslationEnum.Translation">{{ option.Display | translate }}</span>
    </mat-radio-button>
  </mat-radio-group>


   <div class="float-right mt-3 d-inline-block">
    <button mat-flat-button id="potwierdz_wiele" class="mr-3" (click)="checkOrderAvailability()" [translation]="TranslationEnum.Translation" color="primary">
      {{'potwierdz_wiele'|translate}}
      <!-- <app-tlumaczt [id]="'Usun_wiele'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>
  </div>


  <div class="loading-shade" *ngIf="IsLoadingResults || IsRateLimitReached">
    <mat-spinner *ngIf="IsLoadingResults"></mat-spinner>
    <div *ngIf="IsRateLimitReached" id="wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę"
      class="rate-limit-reached" [translation]="TranslationEnum.Translation">
      {{'wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę'|translate}}
      <!-- <app-tlumaczt [id]="'Wystąpił błąd połączenia. Proszę odświeżyć za minutę.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </div>
  </div>

  <div class="mw-100">
  <table [(dataSource)]="dataSource" mat-table matSort matSortActive="InvoiceNumber" matSortDisableClear matSortDirection="desc">
  <ng-container matColumnDef="Select" class=" w-75p">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="this.radioCheckedOption !== 1" color="primary" (change)="$event ? masterToggle() : null"
        [checked]="Selection.hasValue() && isAllSelected()"
        [indeterminate]="Selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox color="primary"
        *ngIf="!row.IsDeliveryConfirmed && row.IsPossibleConfirm"
        (click)="$event.stopPropagation()" (change)="$event ? Selection.toggle(row) : null"
        [checked]="Selection.isSelected(row)">
      </mat-checkbox>
      <!-- 12 - weryfikacja zamówienia -->
    </td>
  </ng-container>

  <ng-container matColumnDef="InvoiceNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header id="numer_faktury" [translation]="TranslationEnum.Translation">
      {{ 'numer_faktury'| translate}}
    </th>
     <td mat-cell *matCellDef="let row"> {{row.InvoiceNumber}} </td>
  </ng-container>
  <ng-container matColumnDef="WdtNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header id="numer_wdt" [translation]="TranslationEnum.Translation">
      {{ 'numer_wdt'| translate}}
    </th>
     <td mat-cell *matCellDef="let row"> {{row.WdtNumber}} </td>
  </ng-container>
  <ng-container matColumnDef="OrdersNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header id="ilosc_zlecen" [translation]="TranslationEnum.Translation">
      {{ 'ilosc_zlecen'| translate}}
    </th>
     <td mat-cell *matCellDef="let row"> {{row.OrdersNumber}} </td>
  </ng-container>
  <ng-container matColumnDef="DeliveryDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header id="data_dostawy" [translation]="TranslationEnum.Translation">
      {{ 'data_dostawy'| translate}}
    </th>
     <td mat-cell *matCellDef="let row"> {{row.DeliveryDate | date:'yyyy-MM-dd'}} </td>
  </ng-container>
  <!-- <ng-container matColumnDef="ValueNettoBrutto">
    <th mat-header-cell *matHeaderCellDef id="wartosc_netto_brutto" [translation]="TranslationEnum.Translation">
      {{ 'wartosc_netto_brutto'| translate}}
    </th>
     <td mat-cell *matCellDef="let row"> {{'5201.45'}} </td>
  </ng-container> -->

  <ng-container matColumnDef="UpsNo">
    <th mat-header-cell *matHeaderCellDef id="ups" [translation]="TranslationEnum.Translation">
        {{ 'ups' | translate }}
        <!-- <app-tlumacz [id]="'Komentarz'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </th>
    <td mat-cell *matCellDef="let row">{{row.UpsNumber}}</td>
  </ng-container>
  <ng-container matColumnDef="ShortDeliveryAddress">
    <th mat-header-cell *matHeaderCellDef mat-sort-header id="skrocony_adres_dostawy" [translation]="TranslationEnum.Translation">
      {{ 'skrocony_adres_dostawy'| translate}}
    </th>
     <td mat-cell *matCellDef="let row"> {{row.ShortDeliveryAddress}} </td>
  </ng-container>
  <ng-container matColumnDef="Confirmed">
    <th mat-header-cell *matHeaderCellDef id="potwierdzone" [translation]="TranslationEnum.Translation">
      {{ 'potwierdzone'| translate}}
    </th>
     <td mat-cell *matCellDef="let row" ><mat-icon *ngIf="row.IsDeliveryConfirmed" style="color: green">check</mat-icon></td>
  </ng-container>
  <ng-container matColumnDef="WhoConfirmed">
    <th mat-header-cell *matHeaderCellDef id="kto_potwierdzil" [translation]="TranslationEnum.Translation">
      {{ 'kto_potwierdzil'| translate}}
    </th>
     <td mat-cell *matCellDef="let row" >{{row.WhoConfirmedDelivery}}</td>
  </ng-container>
    <ng-container matColumnDef="ConfirmationDate">
    <th mat-header-cell *matHeaderCellDef id="kiedy_potwierdzil" [translation]="TranslationEnum.Translation">
      {{ 'kiedy_potwierdzil'| translate}}
    </th>
     <td mat-cell *matCellDef="let row" >{{row.DateDeliveryConfirmation | date: 'yyyy-MM-dd H:mm:ss'}}</td>
  </ng-container>
  <ng-container matColumnDef="PrintoutWDT">
    <th mat-header-cell *matHeaderCellDef mat-sort-header id="wydruk_wdt" [translation]="TranslationEnum.Translation">
      {{ 'wydruk_wdt'| translate}}
    </th>
     <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.IsDeliveryConfirmed" >
          <a mat-flat-button id="potwierdzenie_wdt" class="btn-sm d-block mb-2"
              [translation]="TranslationEnum.Translation" color="primary" [hidden]="row.IsTechnologyError"
              [href]="tresci.server_url + '/api/Wdt/GetWdtConfirmationPrint?guidId=' + row.GuidId + '&Culture=' + getCulture()"
              target="_blank">
              {{'potwierdzenie_wdt'|translate}}
              <!--<app-tlumaczt [id]="'Specyfikacja'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </a>
        </ng-container>
      </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="columnsToDisplay;"></tr>
  <tr mat-row class="element-row"
    *matRowDef="let row; columns: columnsToDisplay;">
  </tr>

  <tr class="mat-row bg-white" *matNoDataRow>
    <td colspan="4" id="brak_danych_do_wyświetlenia" class="mat-cell" [translation]="TranslationEnum.Translation">
      {{'brak_danych_do_wyświetlenia'|translate}}
      <!-- <app-tlumacz [id]="'Brak danych do wyświetlenia'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </td>
  </tr>
    </table>
  </div>
</div>
