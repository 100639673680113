<h1 id="historia_powiadomien" [translation]="TranslationEnum.Translation">
  {{ 'historia_powiadomien' | translate }}
  <!-- <app-tlumacz [id]="'lista'" [typ]="'zmienne'" [tresci]="tresci"></app-tlumacz> -->
</h1>
<!-- Progress spinner -->
<!-- <div class="loading-shade" *ngIf="notificationsService.isLoading">
    <mat-spinner *ngIf="notificationsService.isLoading"></mat-spinner>
  </div> -->


<div class="container">
  <h3 class="text-center" *ngIf="(notificationsService.registerNotifications | async)?.size === 0">
    <span id="historia_brak_powiadomien" [translation]="TranslationEnum.Translation">
      {{ 'historia_brak_powiadomien' | translate }}
    </span>
  </h3>
  <ng-container *ngFor="let notification of notificationsService.registerNotifications | async">
    <div class="row pr-3 mw-100 mt-3 mat-elevation-z8">
      <div class="pl-3 pr-3 pt-3 w-100 d-flex flex-row d-flex justify-content-between">
        <h3>
          <mat-icon *ngIf="notification.IsRequired" class="align-center material-icons-outlined" color="primary">
            notification_important
          </mat-icon>
          <mat-icon *ngIf="!notification.IsRequired" class="align-center material-icons-outlined" color="accent">
            notifications
          </mat-icon>
          {{ notification.Title }}
        </h3>
        <br>
        <p class="mb-0 text-right">
          <span id="data_powiadomienia" [translation]="TranslationEnum.Translation">
            {{'data_powiadomienia'|translate}}
          </span>
          {{ notification.DateNotification | date: 'd-MM-yyyy' }}
          <br />
          <span style="color: green">
            <span *ngIf="notification.DateAcceptance !== undefined" id="zaakceptowano"
              [translation]="TranslationEnum.Translation">
              {{'zaakceptowano'|translate}}
            </span>
            {{ notification.DateAcceptance | date: 'd-MM-yyyy' }}
          </span>
        </p>
      </div>
      <div class="p-3 w-100">
        <span [innerHtml]="notification.Content|styleP: 'margin': '0'" class="text-wrap"></span>
      </div>
      <div *ngIf="notification.Document !== undefined" class="p-3">
        <mat-icon>attach_file</mat-icon>
        <a [href]="serverUrl + '/api/Notification/GetNotificaionFile?fileId=' + notification.Document.Id" method="POST"
          target="_blank" class="text-center mt-auto mb-auto" [color]="ColorNotification">
          {{notification.Document.FileName }}
        </a>
      </div>
    </div>
  </ng-container>
</div>
