import { NotAffectToOtherControls } from './../../../enums/not-affect-to-controls-enum';
import { Kim } from '../kim.model';
import { Parameter } from './form-controlls.model';

export class HtmlControl {
  name: string;
  label: string;
  inputType: string;
  value?: any; // any ponieważ może być również obiektem
  componentName: string;
  options?: Parameter[];
  optionsKim?: Kim[];
  optionsSelectMultiColumn?: any;
  parameters?: Parameter[];
  validations?: Validator[];
  technologyVersion?: number;
  type?: number;
  module?: number;
  position?: number;
  width: number;
  isPrint: boolean;
  currentStep: string;
  displayInTablePosition: boolean;
  fileInfoId?: string;
  notAffectToOtherControls: NotAffectToOtherControls;
  isLastStepInSpecificType = false;
  pictureWidth: number;
  required: boolean;
  translateCode: string;
  foregrandColour: string;
  backgroundColour: string;
}

export class Validator {
  name: string;
  validator: any;
  message: string;
}
