import { FormService } from 'src/app/shared/service/form.service';
import { AbstractControl, Validators } from '@angular/forms';
import { Component, AfterViewChecked, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';



@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
})
export class CheckboxInputComponent implements OnInit {

  public TranslationEnum = TranslationEnum;
  public Control: HtmlControl;
  public Group: FormGroup;
  public EnabledInput: boolean;

  public Min: number;
  public Max: number;
  public MinMaxLabel = '';

  constructor(private formService: FormService) {}

  ngOnInit(): void {
    if (this.Control.parameters) {
      this.Min = this.Control.parameters.find((x) => x.Key === 'Min').Value;
      this.Max = this.Control.parameters.find((x) => x.Key === 'Max').Value;
      this.MinMaxLabel = '(' + this.Min + ' - ' + this.Max + ')';
    }
    this.EnabledInput = typeof this.Control.value !== undefined && this.Control.value !== '' && this.Control.value !== null;

    if (!this.EnabledInput) {
      this.Group.get(this.Control.name).clearValidators();
      this.Group.controls[this.Control.name].setValue(this.Control);
      this.Group.get(this.Control.name).setErrors(null);
      this.Group.get(this.Control.name).updateValueAndValidity();
    }
  }

  checkChanged(): void {
    const control = this.formService.RemoveControlsFromGroup.find((x) => x.name === this.Control.name && x.module === this.Control.module && x.position === this.Control.position);
    let index: number;
    if (control !== null) {
      index = this.formService.RemoveControlsFromGroup.indexOf(control);
    }
    if (this.EnabledInput) {
      this.Group.get(this.Control.name).setValidators([Validators.required, Validators.min(this.Min), Validators.max(this.Max)]);
      this.Group.controls[this.Control.name].setValue('');
      this.Group.get(this.Control.name).updateValueAndValidity();
      if (index !== null && index >= 0) {
        this.formService.RemoveControlsFromGroup.splice(index, 1);
      }
    } else {
      this.Group.get(this.Control.name).clearValidators();
      this.Control.value = '';
      this.Group.controls[this.Control.name].setValue(this.Control);
      this.Group.get(this.Control.name).setErrors(null);
      this.Group.get(this.Control.name).updateValueAndValidity();

      if (index < 0) {
        this.formService.RemoveControlsFromGroup.push(this.Control);
      }
    }
    this.Control.value = null;
  }
}
