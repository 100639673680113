import { User } from './shared/models/user/user.model';
import { Zamowienie } from './pomocnicze/zamowienie';
import { ListaZamowien } from './pomocnicze/listazamowien';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class Config {

  public static ApiUrl = 'https://devreklamacje-dev.selt.com/';
  public static Token = '';
  public static System = null;

  private static _value: Config;

  public User: User;

  public Orders: ListaZamowien;

  public static Value(): Config {
    if (!this._value) {
      this._value = new Config();
    }
    return this._value;
  }

  public get isLogIn(): boolean {

    return this.User !== null;
  }
}
