
<h1 id = "reklamacje_ng" [translation]="TranslationEnum.Translation">{{'reklamacje_ng'|translate}}</h1>




<div class="mw-100">

  <mat-tab-group #statusGroup (selectedTabChange)="zmienKarte($event)" animationDuration="0ms" class="d-inline">
    <mat-tab  *ngFor="let stat of statusy; let i = index;">
        <ng-template mat-tab-label>
          <span id="{{NazwaTlumacz(stat.Name)}}" [translation]="TranslationEnum.Translation">
            {{NazwaTlumacz(stat.Name)|translate}}
          </span>
        </ng-template>
     
    </mat-tab>
  </mat-tab-group>
  <div class="float-right mt-3 d-inline-block">
    <button mat-flat-button id="dodaj_reklamacje2" class="mr-3" color="primary"
      (click)="Dodaj();">
      <span id="dodaj_reklamacje" [translation]="TranslationEnum.Translation">{{'dodaj_reklamacje'|translate}}</span>
    </button>
  </div>


    <div class="loading-shade" *ngIf="IsLoadingResults || IsRateLimitReached">
        <mat-spinner *ngIf="IsLoadingResults"></mat-spinner>
        <div *ngIf="IsRateLimitReached" id="wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę"
          class="rate-limit-reached" [translation]="TranslationEnum.Translation">
          {{'wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę'|translate}}
          <!-- <app-tlumaczt [id]="'Wystąpił błąd połączenia. Proszę odświeżyć za minutę.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </div>
      </div>
      <div class="pole_Szukaj">
        <span id = "filter"  [translation]="TranslationEnum.Translation">{{'filter'|translate}}</span>&nbsp;
        <mat-form-field appearance="standard">
          <input matInput (keyup)="applyFilter($event)" placeholder="Wyszukaj" #input>
        </mat-form-field>
      </div>
      
    
      <table mat-table [dataSource]="dataSource" matSort #empTbSort="matSort" matSortActive="ComplaintDate" matSortDisableClear matSortDirection="desc">
      
        <ng-container matColumnDef="ComplaintDate" class=" w-75p">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header id = "data_reklamacji"  [translation]="TranslationEnum.Translation">
            {{'data_reklamacji'|translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.ComplaintDate | date:'yyyy-MM-dd'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="ComplaintNumber" class=" w-75p">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header id = "id_reklamacji"  [translation]="TranslationEnum.Translation">
            {{'id_reklamacji'|translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.ComplaintNumber}}
          </td>
        </ng-container>
        <ng-container matColumnDef="B2BNumber" class=" w-75p">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header id = "b2bid"  [translation]="TranslationEnum.Translation">
            {{'b2bid'|translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.B2BNumber}}
          </td>
        </ng-container>
        <ng-container matColumnDef="IsAttachment" class=" w-75p">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header id = "isattachment" [translation]="TranslationEnum.Translation">
            {{'isattachment'|translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.IsAttachment">attachment</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="InvoiceNumber" class=" w-75p">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header id = "invoicenumber"  [translation]="TranslationEnum.Translation">
            {{'invoicenumber'|translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.InvoiceNumber}}
          </td>
        </ng-container>
        <ng-container matColumnDef="ShortDescription" class=" w-75p">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header id = "shortdescription"  [translation]="TranslationEnum.Translation">
            {{'shortdescription'|translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.ShortDescription}}
          </td>
        </ng-container>
        <ng-container matColumnDef="ConcernsOrder" class=" w-75p">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header id = "concernsorder"  [translation]="TranslationEnum.Translation">
            {{'concernsorder'|translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.ConcernsOrder}}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="Status" class=" w-75p">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header id = "status"  [translation]="TranslationEnum.Translation">
            {{'status'|translate}}
          </th>
          <td mat-cell id="{{row.Status}}" *matCellDef="let row" [translation]="TranslationEnum.Translation">
            {{NazwaTlumacz(row.Status)|translate}}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="ProductionNumber" class=" w-75p">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header id = "productionnumber" [translation]="TranslationEnum.Translation">
            {{'productionnumber'|translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.ProductionNumber}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Pokaz"  class=" w-75p">
          <th mat-header-cell *matHeaderCellDef id = "pokaz" [translation]="TranslationEnum.Translation">
            {{'pokaz'|translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button color="accent" (click)="Show(row)">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="CancelEnable" class=" w-75p">
          <th mat-header-cell *matHeaderCellDef id = "anuluj" [translation]="TranslationEnum.Translation">
            {{'anuluj'|translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            <button *ngIf="row.CancelEnable && !User.IsTrader"  mat-icon-button color="accent" (click)="Anuluj(row)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="columndefs"></tr>
        <tr mat-row *matRowDef="let row; columns: columndefs;"></tr>
      </table>

      <mat-paginator [length]="ResultsLength" [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100, 200]"
      showFirstLastButtons [pageIndex]="currentPage" (page)="handlePage($event)"></mat-paginator>


<!--<div id="test123" [translation]="TranslationEnum.Translation">
    {{'test123'|translate}}
    </div>-->