import { TresciService } from 'src/app/tresci.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, AfterViewChecked, AfterViewInit, ViewChild } from '@angular/core';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { MatSelect, MatSelectChange } from '@angular/material/select';



@Component({
  selector: 'app-select-img',
  templateUrl: './select-img.component.html',
  styleUrls: ['./select-img.component.scss']
})
export class SelectImgComponent implements OnInit, AfterViewInit {

  public TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;

  @ViewChild('select', { static: true }) select: MatSelect;


  constructor(public tresci: TresciService) { }

  ngOnInit(): void {
    this.Control.options.forEach(element => {
      if (element.Key === this.Control.value) {
        this.Control.value = element;
        this.Group.get(this.Control.name).setValue(this.Control);
      }
    });
  }

  ngAfterViewInit() {
    if (this.Control.value === '') {
      this.Group.get(this.Control.name).setValue(this.Control.value);
    }

    setTimeout(() => {
      if (this.Control.value !== '') {
        this.select.selectionChange.emit(new MatSelectChange(this.select, this.Control.value));
      }
    });
  }


}
