<h2 mat-dialog-title id="{{'label_'+ ControlLabel}}" [translation]="TranslationEnum.TranslationForm">
  {{'label_'+ ControlLabel|translate}}
</h2>
<mat-dialog-content class="pt-3 pb-3 pr-4 pl-4">
  <span class="float-right">
    <mat-form-field class="w-190px">
      <input autocomplete="off" matInput #input (keyup.enter)="applyFilter(input)"
        placeholder="{{'wyszukaj'|translate}}" />
      <mat-icon matPrefix>search</mat-icon>
      <mat-icon matSuffix (click)="input.value = ''; applyFilter(input)">close</mat-icon>
    </mat-form-field>
  </span>

  <div class="w-100 overflow-auto multicolumn mh-50vh">
    <table mat-table [dataSource]="DataSource">
      <ng-container [matColumnDef]="column" *ngFor="let column of DisplayedColumns">
        <th mat-header-cell *matHeaderCellDef id="{{ControlLabel + '_' + column.toLowerCase()}}"
          [translation]="TranslationEnum.TranslationForm">
          {{ControlLabel + '_' + column.toLowerCase()|translate}}
        </th>
        <td mat-cell *matCellDef="let row" [ngClass]="{'text-center': !!row[column] === row[column]}">
          <ng-container
            [ngTemplateOutlet]=" !!row[column] === row[column] ? first : (row[column] !== undefined && row[column].includes('rgb')) ? second : third">
          </ng-container>

          <ng-template #first class="text-center">
            <mat-icon *ngIf="row[column]">check</mat-icon>
          </ng-template>

          <ng-template #second>
            <div class="shape arrow-point mw-100 mh-100" [style.backgroundColor]="row[column]"></div>
          </ng-template>

          <ng-template #third>
            {{row[column]}}
          </ng-template>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="DisplayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: DisplayedColumns;" (click)="choose(row)"></tr>
    </table>
  </div>
</mat-dialog-content>
