
<div class="ramka_scroll">
    <ngx-json-viewer [json]="data"></ngx-json-viewer>
</div>
<div mat-dialog-actions>
  <div class="w-100">
    <button mat-flat-button id="ok" class="btn-sm float-right" [translation]="TranslationEnum.Translation"
      color="primary" [mat-dialog-close]="true">
      {{'ok'|translate}}
    </button>
  </div>
</div>
