import { Directive, ElementRef, OnInit, Input, Renderer2, OnChanges, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { PermissionEnum } from '../enums/permission-enum';

@Directive({
  selector: '[permission]'
})
export class PermissionDirective implements OnInit {
  @Input('permission') permissionEnum: PermissionEnum;

  constructor(private renderer: Renderer2, private el: ElementRef, private authorizationService: AuthorizationService, private router: Router) { }


  ngOnInit() {
    const permission = this.authorizationService.hasPermission(this.permissionEnum);
    if (permission !== null) {
      if (permission.Value === true) {
        this.renderer.addClass(this.el.nativeElement, 'mat-primary');
      } else {
        this.renderer.addClass(this.el.nativeElement, 'mat-accent');
      }
    } else {
      this.renderer.addClass(this.el.nativeElement, 'd-none');
    }
  }


  @HostListener('click', ['$event']) public onKeyup(event: Event): void {
    this.authorizationService.changePermissions(this.permissionEnum);
    const permission = this.authorizationService.hasPermission(this.permissionEnum);
    if (permission !== null) {
      this.renderer.removeClass(this.el.nativeElement, 'd-none');
      if (permission.Value === true) {
        this.renderer.removeClass(this.el.nativeElement, 'mat-accent');
        this.renderer.addClass(this.el.nativeElement, 'mat-primary');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'mat-primary');
        this.renderer.addClass(this.el.nativeElement, 'mat-accent');
      }
    } else {
      this.renderer.addClass(this.el.nativeElement, 'd-none');
    }
   window.location.reload();
  }
}

