import { Component, OnDestroy, OnInit, } from '@angular/core';
import { TresciService } from './../tresci.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';
import { serialize } from 'class-transformer';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslationEnum } from '../shared/enums/translation-enum';
import { Subscription } from 'rxjs';




@Component({
  selector: 'app-faktury',
  templateUrl: './faktury.component.html',
  styleUrls: ['./faktury.component.scss']
})
export class FakturyComponent implements OnInit, OnDestroy {

  public TranslationEnum = TranslationEnum;

  Faktury: any[] = [];
  FakturyPoz: any[] = [];
  today: any;
  lastDay: any;
  Day: Date = new Date();
  DateTo: any;
  DateFrom: any;
  sort_id: any;
  asc: boolean;
  faktura;
  closeResult: string;
  private _debtsList;
  public CheckGetDebts = true;
  public isAfterPayment = false;
  private subscriptions: Subscription[] = [];

  constructor(public tresci: TresciService, public user: UserService, private route: ActivatedRoute, private modalService: NgbModal) {
    this.DateFrom = this.getDate(new Date(this.Day.getTime() - (1000 * 60 * 60 * 24 * 30)));
    this.DateTo = this.getDate(this.Day);
  }
  ngOnDestroy(): void {

    this.subscriptions.forEach(e => {
        // console.log(e);
       !e.closed ? e.unsubscribe() : null;
      });

    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit() {
    this.tresci.setJezyk(this.route.snapshot.paramMap.get('jezyk'));
    this.tresci.setFunkcja(this.route.snapshot.paramMap.get('system'));
    this.tresci.setId(this.route.snapshot.paramMap.get('id'));
    this.pobierzZadluzenia();
  }



  public getDate(data: Date) {
    const m = data.getMonth() + 1;
    let miesiac = '';
    if (m < 10) {
      miesiac = '0' + m;
    } else {
      miesiac = '' + m;
    }
    const d = data.getDate();
    let dzien = '';
    if (d < 10) {
      dzien = '0' + d;
    } else {
      dzien = '' + d;
    }
    return data.getFullYear() + '-' + miesiac + '-' + dzien;
  }

  public pobierzFaktury() {

    if (JSON.parse(sessionStorage.getItem('_slc')) === null) {
      setTimeout(() => { this.pobierzFaktury(); }, 300);
    } else {
      this.tresci.user.pobierzDaneFaktur(this.DateTo, this.DateFrom);
    }
  }

  public pobierzZadluzenia() {

    if (JSON.parse(sessionStorage.getItem('_slc')) === null) {
      setTimeout(() => { this.pobierzZadluzenia(); }, 300);
    } else {
      this.tresci.user.getDebts();
    }
  }

  public getDebts() {

    if (this.CheckGetDebts) {
      this.tresci.user.ListOfDebts = null;
      this.tresci.user.getDebts();

    } else {
      this.isAfterPayment = false;
      this.tresci.user.ListOfInvoices = null;
      this.pobierzFaktury();
    }
  }

  public GetDate() {
    if (this.DateFrom) {
      if (!this.DateTo) {
        this.DateTo = this.getDate(this.Day);
      }
      this.pobierzFaktury();
    }
  }

  public getOnlyAfterPayment() {
    if (this.tresci.user.ListOfDebts !== null || this.tresci.user.ListOfDebts.length > 0) {
      if (this.isAfterPayment) {
        const tempArray: any[] = [];
        for (let index = 0; index < this.tresci.user.ListOfDebts.length; index++) {
          const element = this.tresci.user.ListOfDebts[index];
          if (element.AfterPayment === true) {
            tempArray.push(element);
          }
        }
        this.tresci.user.ListOfDebts = null;
        this.tresci.user.ListOfDebts = tempArray;
      } else {
        this.tresci.user.ListOfDebts = null;
        this.getDebts();
      }
    }
  }

  public sortuj_po(id) {
    this.asc = !this.asc;
    this.sort_id = id;
    if (id === 'InvoiceNumber') {
      this.Faktury.sort((a, b) => {
        return this.porownaj(b.InvoiceNumber, a.InvoiceNumber, this.asc);
      });
    } else if (id === 'InvoiceDate') {
      this.Faktury.sort((a, b) => {
        return this.porownaj(b.InvoiceDate, a.InvoiceDate, this.asc);
      });
    } else if (id === 'Netto') {
      this.Faktury.sort((a, b) => {
        return this.porownaj(b.Netto, a.Netto, this.asc);
      });
    } else if (id === 'NettoSzt') {
      this.FakturyPoz.sort((a, b) => {
        return this.porownaj(b.PriceNetto, a.PriceNetto, this.asc);
      });
    } else if (id === 'Name') {
      this.FakturyPoz.sort((a, b) => {
        return this.porownaj(b.Name, a.Name, this.asc);
      });
    } else if (id === 'NettoPoz') {
      this.FakturyPoz.sort((a, b) => {
        return this.porownaj(b.Netto, a.Netto, this.asc);
      });
    }
  }

  public porownaj(a, b, asc = true) {
    if (a < b) { if (asc) { return -1; } else { return 1; } }
    if (a > b) { if (asc) { return 1; } else { return -1; } }
    return 0;
  }

  // MODAL BOX TESTOWY //
  open(content, invoiceNumber) {
    this.faktura = invoiceNumber;
    this.getInvoicePositions(invoiceNumber);
    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private getInvoicePositions(invoiceNumber: any) {
    const body = {
      'InvoiceNumber': invoiceNumber
    };
    const dane = serialize(body);
    const request = new HttpRequest('POST', '/api/Invoice/GetInvoicePositions',
      dane, { headers: this.tresci.user.getHeaderJson() });
      this.subscriptions.push(this.tresci.http.request(request).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.FakturyPoz = event.body;
      }
    }));
  }

}


