import { deserialize, serialize } from "class-transformer";
import { DeliveryAddress } from "../../shared/models/form/delivery-address.model";
import { DeliveryMethod } from "../../shared/models/form/delivery-method.model";
import { Control, Param } from "./Control";
import { KrokStep } from "./StepKrok";
import { Parameter } from "src/app/shared/models/form-model/models/form-controlls.model";
import { Order } from "./Order";
import { Modul } from "./Modul";
import { GrupaKrokow } from "./GrupaKrokow";
import { TypEnum } from "./TypEnum";
import { FormV2ServiceService } from "../form-v2-service.service";
import { Delivery } from "./Delivery";
import { Supervisor } from "./Supervisor";
import * as _ from 'lodash';
import { FormDataSend } from "./FormDataSend";
import { KindOper } from '../../shared/enums/kind-oper-enum';
import { Parametr } from "src/app/pomocnicze/parametry_wybrane";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UniversalDialogComponent } from "src/app/shared/components/universal-dialog/universal-dialog.component";
import { DialogData } from "src/app/shared/models/dialog/dialog-data.model";
import { Kim } from "src/app/shared/models/form-model/kim.model";
import { FormService } from "src/app/shared/service/form.service";
import { FileControl } from "src/app/shared/models/form/file-control.model";
import { FormDataV2Save } from "./FormDataV2Save";
import { KimSave } from "./KimSave";

export class FormDataV2 {

    //kroki:KrokStep[] = [];

    zapisuje:boolean = false;

    Order:Order;
    OrderSave:Order;
    supervisor:Supervisor = new Supervisor();

    Parameters:Parameter[] = [];
    Files:FileControl[] = [];
    public ParameterExtensions:Kim[] = [];
    
    delivery:Delivery = new Delivery();

    produktPrefix: string;
    orderId: any;
    kindOper: any;


    public naglowek:GrupaKrokow;
    public moduly:Modul[] = [];
    public stopka:GrupaKrokow;
    public CzyWczytane = false;
    culture: string;
    IsTrader: boolean;
    wczytywanie: boolean;


    TechVersion: Number;
    ValuationVersion: Number;
    IsFooter: boolean;
    IsModule: boolean;
    IsModuleFooter: boolean;

    IloscWczytana: Number = 0;
    ModuleCount: Number = 1;

    LoadingName:string = 'naglowek'; //'zestaw_lad';
    ostatniKrok: KrokStep;
  Preview: boolean;
    fileUrl: any;


    
  public krokiTMP:KrokStep[] = [];
  public numer_krokuTMP = 0;
  public pozycjaTMP = 0;
  public modulTMP = 0;

    
    constructor(private router: Router){
        this.naglowek = new GrupaKrokow(TypEnum.Header);
        this.stopka = new GrupaKrokow(TypEnum.Footer);
    }
    /*
    public MaxMod(){
        var mod = 0;
        this.Parameters.forEach(param => {
            if(param.Module > mod){
                mod = param.Module;
            }
        });
        return mod;
    }

    public MaxPoz(){
        var poz = 0;
        this.Parameters.forEach(param => {
            if(param.Position > poz){
                poz = param.Position;
            }
        });
        return poz;
    }
    public CzyBylaStopkaMod(mod){
        var stop = false;
        this.Parameters.forEach(param => {
            if(param.Type == TypEnum.ModuleFooter && param.Module == mod){
                stop = true;
            }
        });
        return stop;
    }
    public CzyBylFooter(){
        var stop = false;
        this.Parameters.forEach(param => {
            if(param.Type == TypEnum.Footer){
                stop = true;
            }
        });
        return stop;
    }
    */
/*
    GetNg(numer_modul:number){
        var naglowki:String[] = [];
        


        this.moduly[numer_modul].pozycje.forEach(poz => {
            poz.kroki.forEach(k => {
                    k.Controlls.forEach(con => {
                        if(con.wybrany.Key != 'X'){
                            if(con.ControlType != "Hidden"){
                                // con.wybrany;
                                var jest = naglowki.find(x => x == con.wybrany.Key);
                                                            
                                if(jest == null){
                                        naglowki.push(con.wybrany.Key);
                                }
                            }
                        }
                    });
                
            });
        });

        return naglowki;
    } */

    public nextModule(){
        this.supervisor.aktualny_mod++;
    }
    ustawModuly() {
        var pos = 0;
        var mod = 0;
        this.Parameters.forEach(param => {
            if(this.moduly.length < param.Module){
                this.moduly.push(new Modul());
            }
            if(param.Module > 0  && this.moduly[param.Module - 1].pozycje.length < param.Position){
                this.moduly[param.Module - 1].pozycje.push(new GrupaKrokow(TypEnum.Position));
            }
            
        });
    }
    public DodajPozycje(tresci, formService:FormV2ServiceService, czyscroll = true, modul:number, dialog: MatDialog){
        this.supervisor.aktualna_poz = this.moduly[modul].pozycje.length + 1;
        if(this.moduly[modul].moduleNg.GetLastStep() == undefined){
            this.wczytajKrok(formService, true, tresci, this.naglowek.GetLastStep().Step, dialog); 
        } else {
            this.wczytajKrok(formService, true, tresci, this.moduly[modul].moduleNg.GetLastStep().Step, dialog); 
        }
        
    }
    public UsunPozycje(tresci, formService:FormV2ServiceService, czyscroll = true, modul:number, p:number, dialog: MatDialog){
        if(p < this.moduly[modul].pozycje.length){
            this.usunDalszePoz(p);
        }
        this.moduly[modul].pozycje.splice(p, 1);
        
        if(this.moduly[modul].pozycje.length == 0){
            this.DodajPozycje(tresci, formService, czyscroll, modul, dialog);
        }
        this.Parameters.forEach(param => {
            if(param.Module == modul + 1 && param.Position >= p){
                param.Position--;
            }
        });
        this.poprawNumeryPoz();
        this.stopka.WyczyscWszystko();
        // console.log("usuwam stopke 1");
    }
    public EdytujPozycje(tresci, formService:FormV2ServiceService, czyscroll = true, modul:number, p:number, dialog: MatDialog){
        // console.log("edytyje pozycje", p, modul);
        this.supervisor.EdytujPozycje(p);
        if(this.AktualneKroki().kroki.length == 0){
            if(this.IsModule){
                this.wczytajKrok(formService, true, tresci, this.moduly[modul].moduleNg.GetLastStep().Step, dialog); 
            } else {
                this.wczytajKrok(formService, true, tresci, this.naglowek.GetLastStep().Step, dialog); 
            }
            
        } 
    }
    public WczytajModuleFooter(modul:number, formService:FormV2ServiceService, tresci, dialog: MatDialog){
        console.log("mod foot",this.moduly[modul].pozycje,  this.moduly[modul].pozycje[this.moduly[modul].pozycje.length - 1].GetLastStep().Step, this.supervisor.aktualna_poz);
        // this.supervisor.aktualna_poz = this.moduly[modul].pozycje.length - 1;
        this.wczytajKrok(formService, true, tresci, this.moduly[modul].pozycje[this.moduly[modul].pozycje.length - 1].GetLastStep().Step, dialog); 
    }
    public WczytajFooter(modul:number, formService:FormV2ServiceService, tresci, dialog: MatDialog){
        if(this.IsModuleFooter){
            this.wczytajKrok(formService, true, tresci, this.moduly[modul].moduleFooter.GetLastStep().Step, dialog); 
        } else {
            this.wczytajKrok(formService, true, tresci, this.moduly[modul].pozycje[this.moduly[modul].pozycje.length - 1].GetLastStep().Step, dialog); 
        }
        
    }

    public moveToModuleFooter(modul:number, formService:FormV2ServiceService, tresci, dialog: MatDialog){
        this.moduly[modul].zakonczono_pozycje = true;
        // console.log(this.moduly[modul].moduleFooter, this.moduly[modul].moduleFooter.CzyZakonczony(), this.IsModuleFooter);
        if(this.moduly[modul].moduleFooter.kroki.length == 0 && this.IsModuleFooter){
            
            this.supervisor.aktualnyTyp = TypEnum.ModuleFooter;
            this.supervisor.aktualna_poz = 0;
            this.WczytajModuleFooter(modul, formService, tresci, dialog);
        } else {

            if(!this.IsModuleFooter){
                this.moduly[modul].moduleFooter.poprawnie_zakonczone = true;
                this.moduly[modul].moduleFooter.edytowane = false;
            } else {
                this.supervisor.aktualnyTyp = TypEnum.ModuleFooter
            }

            if(this.supervisor.auto.valueOf() > 0){
                this.nextAutoEnd(tresci, formService, true, dialog, null);
            } else {
                
                if(this.moduly[modul].moduleFooter.CzyZakonczony()){
                    if(this.IsFooter){
                        this.supervisor.aktualnyTyp = TypEnum.Footer;
                    /* 
                        this.supervisor.aktualna_poz = 0; 
                        this.supervisor.aktualny_mod = 0;
                        if(this.stopka == null || this.stopka.kroki.length == 0){
                            this.WczytajFooter(modul, formService, tresci, dialog);
                        } else {
                            this.supervisor.zakladka = 2;
                        }*/
                        
                    } else {
                            
                        this.stopka.poprawnie_zakonczone = true;
                        this.stopka.edytowane = false;
                    }
                } else {
                    
                }
                //this.moduly[modul].moduleFooter.poprawnie_zakonczone = true;
                //this.moduly[modul].moduleFooter.edytowane = false;
            }
            
            
            
        }
    }

    public WrocDoEdycjiPoz(modul:number){
        this.moduly[modul].zakonczono_pozycje = false;
        this.supervisor.aktualnyTyp = TypEnum.Position;
        console.log("bez auto wroc do pozycji");
        this.supervisor.auto = 0;
        
    }
    public CzyModulyZakonczone(){
        var zakonczone = true;
        this.moduly.forEach(mod => {
            if(!mod.zakonczono_pozycje){
                zakonczone = false;
            }
            if(!mod.moduleFooter.CzyZakonczony()){
                zakonczone = false;
            }
        });
        if(this.moduly.length == 0){
            zakonczone = false;
        }
        
        return zakonczone;
    }

    public quickSave(formService:FormV2ServiceService){
        this.wczytywanie = true;
        this.zapisuje = true;
        //var param = this.GetAllParams();
        // console.log(param);
        var formData:FormDataSend = new FormDataSend();
        formData.Parameters = this.GetAllParams();
        formData.ParameterExtensions = this.ParameterExtensions;
        formData.ContractorNumber = JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber;
        
        formData.Culture = this.culture;
        if(this.delivery == null || this.delivery.ChosenAdresses == null){
            formData.DeliveryAddressId = null;
        } else {
            formData.DeliveryAddressId = this.delivery.ChosenAdresses.Id;
        }
        
        formData.DeliveryMethodId = this.delivery.DeliveryMethod.Name;
        if(this.kindOper === KindOper.Copy){
            formData.CopiedOrderId = this.orderId;
            formData.Id = 0;
        } else {
            formData.CopiedOrderId = null;
            formData.Id = this.orderId;
        }
        formData.IsCache = true;
        
        if(this.delivery.ComplaintType != null){
            if(this.delivery.ComplaintType.Id == 1){
                formData.IsComplaint = true;
                formData.ComplaintId = this.delivery.ChosenComplaint.Id;
                formData.ComplaintNumber = this.delivery.ChosenComplaint.ComplaintNumber;
                formData.ChargeableRepairsId = null;
                formData.ChargeableRepairsNumber = null;
                formData.ComplaintType  = this.delivery.ComplaintType.Id;
            } else {
                formData.IsComplaint = true;
                formData.ComplaintId = null;
                formData.ComplaintNumber = null;
                formData.ChargeableRepairsId = this.delivery.ChosenComplaint.Id;
                formData.ChargeableRepairsNumber = this.delivery.ChosenComplaint.ComplaintNumber;
                formData.ComplaintType  = this.delivery.ComplaintType.Id;
            }
        } else {
            formData.IsComplaint = null;
        }
        



        formData.IsTrader = this.IsTrader;
        formData.ProductPrefix = this.produktPrefix;
        formData.TechnologyVersion = this.TechVersion;

        formService.AddorUpdateActiveFormOrderWithFiles(formData, this.Files).
        subscribe((data) => {
            this.orderId = data;
            if(this.kindOper != KindOper.EditClient){
                this.kindOper = KindOper.Edit;
            }
            
            this.wczytywanie = false;
            this.zapisuje = false;
          //localStorage.setItem('goToPage', '');
          //this.router.navigate(['Orders']);
          
        }, (err) => {
            // console.log("blad zapisu");
            this.wczytywanie = false;
            this.zapisuje = false;
        }, () => {
            // console.log("po zapisie");
            this.wczytywanie = false;
            this.zapisuje = false;
        }
        
        );
    }
    public save(formService:FormV2ServiceService){
        this.wczytywanie = true;
        this.zapisuje = true;
        //var param = this.GetAllParams();
        // console.log(param);
        var formData:FormDataSend = new FormDataSend();
        formData.Parameters = this.GetAllParams();
        formData.ParameterExtensions = this.ParameterExtensions;
        formData.ContractorNumber = JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber;
        
        formData.Culture = this.culture;
        if(this.delivery == null || this.delivery.ChosenAdresses == null){
            formData.DeliveryAddressId = null;
        } else {
            formData.DeliveryAddressId = this.delivery.ChosenAdresses.Id;
        }
        
        formData.DeliveryMethodId = this.delivery.DeliveryMethod.Name;
        if(this.kindOper === KindOper.Copy){
            formData.CopiedOrderId = this.orderId;
            formData.Id = 0;
        } else {
            formData.CopiedOrderId = null;
            formData.Id = this.orderId;
        }
        formData.IsCache = true;
        
        if(this.delivery.ComplaintType != null){
            if(this.delivery.ComplaintType.Id == 1){
                formData.IsComplaint = true;
                formData.ComplaintId = this.delivery.ChosenComplaint.Id;
                formData.ComplaintNumber = this.delivery.ChosenComplaint.ComplaintNumber;
                formData.ChargeableRepairsId = null;
                formData.ChargeableRepairsNumber = null;
                formData.ComplaintType  = this.delivery.ComplaintType.Id;
            } else {
                formData.IsComplaint = true;
                formData.ComplaintId = null;
                formData.ComplaintNumber = null;
                formData.ChargeableRepairsId = this.delivery.ChosenComplaint.Id;
                formData.ChargeableRepairsNumber = this.delivery.ChosenComplaint.ComplaintNumber;
                formData.ComplaintType  = this.delivery.ComplaintType.Id;
            }
        } else {
            formData.IsComplaint = null;
        }

        formData.IsTrader = this.IsTrader;
        formData.ProductPrefix = this.produktPrefix;
        formData.TechnologyVersion = this.TechVersion;
        // console.log("form", formData);
        //this.wczytywanie = false;
        //this.zapisuje = false;
        // console.log("zam", formData);
        formService.AddorUpdateActiveFormOrderWithFiles(formData, this.Files).
        subscribe((data) => {
            
          this.wczytywanie = false;
          this.zapisuje = false;
          localStorage.setItem('goToPage', '');
          this.router.navigate(['Orders']);
          
        }, (err) => {
            // console.log("blad zapisu");
            this.wczytywanie = false;
            this.zapisuje = false;
        }, () => {
            // console.log("po zapisie");
            this.wczytywanie = false;
            this.zapisuje = false;
        }
        
        );
    }

    public ustawDelivery(DeliveryMethodId, ContractorAddressId){
        // console.log("id del", DeliveryMethodId);

       

        this.delivery.DeliveryMethods.forEach(del => { 
            
        // console.log("id del", DeliveryMethodId, del.Name);
            if(del.Name == DeliveryMethodId || del.Id == DeliveryMethodId) {
                this.delivery.DeliveryMethod = del;
            }
        });
        // console.log(this.delivery.ContractorAddresses, ContractorAddressId );
        this.delivery.ContractorAddresses.forEach(adr => {
            if(adr.Id == ContractorAddressId) {
                // console.log(adr );
                this.delivery.ChosenAdresses = adr;
            }
        });
        if(this.delivery.ChosenAdresses != null && this.delivery.ChosenAdresses.OtherTransportThenSelt && this.delivery.DeliveryMethod.Id == 3){
            this.delivery.ChosenAdresses = new DeliveryAddress(); 
          }
    }
    
   
    public dodajModul(formService,tresci, dialog: MatDialog){
        console.log("bez auto dodaj modul");
        this.supervisor.auto = 0;
        this.moduly.push(new Modul());
        this.supervisor.aktualny_mod = this.moduly.length;
        this.supervisor.aktualna_poz = 0;
        this.supervisor.aktualnyTyp = TypEnum.ModuleHeader;
        this.wczytajKrok(formService, true, tresci, this.naglowek.GetLastStep().Step, dialog); 
        // console.log('moduł', this.supervisor.aktualny_mod);
    }
    public UsunModul(){
        this.moduly.splice(this.supervisor.aktualny_mod - 1,1);
        if(this.supervisor.aktualny_mod > 0){
            this.supervisor.aktualny_mod--;
        }
        this.poprawNumeryMod();
        this.stopka.WyczyscWszystko();
        // console.log("usuwam stopke 2");
    }
    
    
    public poprawNumeryMod() {
        for(var i = 0;i < this.moduly.length; i ++){
            this.moduly[i].moduleNg.kroki.forEach(mod => {
                mod.Module = i + 1;
                mod.Controlls.forEach(con => {
                    con.wybrany.Module = i + 1;
                });
                
            });
            this.moduly[i].pozycje.forEach(poz => {
                poz.kroki.forEach(mod => {
                    mod.Module = i + 1;
                    mod.Controlls.forEach(con => {
                        con.wybrany.Module = i + 1;
                    });
                    
                });
            });
            this.moduly[i].moduleFooter.kroki.forEach(mod => {
                mod.Module = i + 1;
                mod.Controlls.forEach(con => {
                    con.wybrany.Module = i + 1;
                });
                
            });
        }
        // console.log(this.moduly);
    }
    public poprawNumeryPoz() {
        for(var i = 0;i < this.moduly.length; i ++){
            for(var m = 0;m < this.moduly[i].pozycje.length; m ++){
                this.moduly[i].pozycje[m].kroki.forEach(mod => {
                    mod.Position = m + 1;
                    mod.Controlls.forEach(con => {
                        con.wybrany.Position = m + 1;
                    });
                    
                });
            }
        }
    }

    public KopiujModul(){
        if(!this.wczytywanie){
    //this.moduly.push(JSON.parse(JSON.stringify(this.moduly[this.supervisor.aktualny_mod - 1])));
            //this.moduly.push(Object.assign({}, this.moduly[this.supervisor.aktualny_mod - 1]));
                console.log("bez auto kopiuj mod");
            this.supervisor.auto = 0;
            this.moduly.push(_.cloneDeep(this.moduly[this.supervisor.aktualny_mod - 1]));
            
            
            this.supervisor.aktualny_mod = this.moduly.length; 
            this.poprawNumeryMod();
            // this.aktualny_mod--;
            // this.moduly.push(this.moduly[this.aktualny_mod]);
            this.stopka.WyczyscWszystko();
            // console.log("usuwam stopke 3");
        }
        
    }
    public ZmienModul(numer){
        if(!this.wczytywanie){
            this.supervisor.aktualny_mod = numer + 1;
        }
        
    }
    public getModNumber(parametry:Parameter[]){
        if(parametry.length > 0){
            return parametry[0].Module;
        }
        return -1;
    }

    public getPozNumber(parametry:Parameter[]){
        if(parametry.length > 0){
            return parametry[0].Position;
        }
        return -1;
    }

    public ustawKimStep(step:KrokStep){

        step.Controlls.forEach(con => {
            if(con.ControlType == 'Kim'){
                con.Values.forEach(val => {
                    val.Module = this.supervisor.getAktualnyMod();
                    val.Position = this.supervisor.getAktualnaPoz();
                });
            } 
        });

    }
    public CzyPoprawnyAdres(){
        /*var adres_pop = this.czyPoprawneDelivery();
        if(this.supervisor.auto == 0 && !this.supervisor.wczytywanieAuto){
            setTimeout(()=> {
                if(!adres_pop && this.supervisor.zakladka != 0){
                    this.supervisor.zakladka = 0;
                    // console.log("wymuszono header", adres_pop);
                }
            }, 100);
        }
        */
        
        return this.czyPoprawneDelivery();
    }
    public ustawKrok(krok:KrokStep){
        // console.log("krok", krok); 
        var step:KrokStep = new KrokStep(krok, this.Parameters, this.Files);
        this.ustawKimStep(step);
        this.IsFooter = step.IsFooter;
        this.IsModule = step.IsModule;
        this.IsModuleFooter = step.IsModuleFooter;
        this.TechVersion = step.TechVersion;
        this.ValuationVersion = step.ValuationVersion;
        //// console.log("isfotherKrok", this.IsFooter);
        if(step.Type == TypEnum.Header){
            this.naglowek.dodajKrok(step);
            this.naglowek.czyPoprawny();
            this.supervisor.SetAktualnyTyp('Header');
        } else if (step.Type == TypEnum.ModuleHeader){
            var mod = step.Module.valueOf();
            while(mod > this.moduly.length){
                this.moduly.push(new Modul());
            }
            
            this.moduly[mod - 1].moduleNg.dodajKrok(step);
            this.moduly[mod - 1].moduleNg.czyPoprawny();
            this.supervisor.aktualny_mod = mod;
            this.supervisor.SetAktualnyTyp('ModuleHeader');
            // this.aktualnyTyp = 'ModuleHeader';
        } else if (step.Type == TypEnum.Position){
            
            var mod = step.Module.valueOf(); //this.getModNumber(k.Parameters);
            while(mod > this.moduly.length){
                this.moduly.push(new Modul());
            }
            var poz = step.Position.valueOf(); //this.getPozNumber(k.Parameters);
            
            while(poz > this.moduly[mod - 1].pozycje.length){
                this.moduly[mod - 1].pozycje.push(new GrupaKrokow(TypEnum.Position));
            }
            // console.log("popoz", mod, poz,this.moduly.length, this.moduly[mod - 1].pozycje.length);
            if(!this.IsModule){
                this.moduly[mod - 1].moduleNg.poprawnie_zakonczone = true;
                this.moduly[mod - 1].moduleNg.edytowane = false;

            }
            // console.log(step,mod, poz);
            this.moduly[mod - 1].pozycje[poz - 1].dodajKrok(step);
            this.moduly[mod - 1].pozycje[poz - 1].czyPoprawny();
            this.supervisor.aktualny_mod = mod;
            this.supervisor.aktualna_poz = poz;
            this.supervisor.SetAktualnyTyp('Position');
            this.supervisor.edycja_pozycji = true;
            // console.log(this.moduly);
            // this.aktualnyTyp = 'Position';
        } else if (step.Type == TypEnum.ModuleFooter){ 
            var mod = step.Module.valueOf(); //this.getModNumber(k.Parameters);
            while(mod > this.moduly.length){
                this.moduly.push(new Modul());
            }
            this.moduly[mod - 1].moduleFooter.dodajKrok(step);
            this.moduly[mod - 1].moduleFooter.czyPoprawny();
            this.supervisor.aktualny_mod = mod;
            // this.aktualnyTyp = 'ModuleFooter';
            this.supervisor.SetAktualnyTyp('ModuleFooter');
            
        } else if (step.Type == TypEnum.Footer){
            
            this.stopka.dodajKrok(step);
            this.stopka.czyPoprawny();
            this.stopka.edytowane = true;
           
            this.supervisor.aktualnyTyp = TypEnum.Footer;
            this.supervisor.SetAktualnyTyp('Footer');
            
            
            // this.ZmienZakladke(1);
            //this.supervisor.aktualnyTyp = TypEnum.Footer;
            //this.supervisor.SetAktualnyTyp('Footer');
        }
        
    }
    public ustawKroki(kroki:KrokStep[], formService, dialog: MatDialog){
        // console.log("pp", kroki);
        var typ = TypEnum.Header;
        var mod = 0;
        var poz = 0;
        // console.log("isfother", this.IsFooter);
        if(kroki.length > 0){
            this.IsFooter = kroki[0].IsFooter;
        }
        if(this.IsFooter){
            this.supervisor.goToFooter = true;
        }
        
        //var j = 0 ;
        for (var i = 0; i < kroki.length; i++) {
            var con:Control;
            kroki[i].Controlls.forEach(c => {
                if(c.ControlType == 'Komunikat'){
                    con = c;
                }
            });
            // console.log("pp con", kroki[i].Step, this.AktualneKroki().czyPoprawny());
            if(con != null){
                // console.log("pp błąd ", kroki[i].Step,  this.AktualneKroki(), kroki[i],this.AktualneKroki(), this.AktualneKroki().czyPoprawny());
                    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
                    const dataDial: DialogData = {
                    title: '',
                    content:  con.DefaultValue,
                    answerOk: true,
                    };
                    dialogRef = dialog.open(UniversalDialogComponent, { data: dataDial, role: 'alertdialog' });
                    // console.log("komunikat", this.AktualneKroki().edytowane, this.AktualneKroki().poprawnie_zakonczone);
                    this.AktualneKroki().poprawnie_zakonczone = false;
                    this.AktualneKroki().edytowane = true; 
                    break;
                
            } else if(kroki[i].Type == typ && kroki[i].Module == mod && kroki[i].Position == poz){
                //if(typ == TypEnum.Header || this.AktualneKroki().czyPoprawny()){
                // console.log("pp", kroki[i].Step, kroki[i], this.AktualneKroki().czyPoprawny());
                if(this.AktualneKroki().czyPoprawny()){
                    // console.log(kroki[i]);
                    this.ustawKrok(kroki[i]); 
                } else {
                    
                    console.log("blad poz2?"); 
                } 
            } else {
                // console.log(this.AktualneKroki());
               
               //  console.log("pp2", kroki[i].Step,  this.AktualneKroki(), kroki[i],this.AktualneKroki(), this.AktualneKroki().czyPoprawny());
                if(this.AktualneKroki().czyPoprawny()){
                   //if(j < 1){
                        //j++;
                        if(this.AktualneKroki().typ == TypEnum.Position && kroki[i].Type != TypEnum.Position){
                            this.moduly[this.supervisor.aktualny_mod - 1].zakonczono_pozycje = true;
                            
                        }
                        this.AktualneKroki().edytowane = false; 
                        this.AktualneKroki().poprawnie_zakonczone = true;

                        

                        this.supervisor.aktualna_poz = kroki[i].Position.valueOf();
                        this.supervisor.aktualny_mod = kroki[i].Position.valueOf();
                        this.supervisor.aktualnyTyp = kroki[i].Type;
                        typ = kroki[i].Type;
                        this.ustawKrok(kroki[i]); 
                    //}
                } else {
                   
                    // console.log("blad poz3?"); 
                    this.AktualneKroki().edytowane = true;
                    this.AktualneKroki().poprawnie_zakonczone = false;
    
                    break;
                }
            }
        };

        if(this.moduly.length == 0 && this.naglowek.CzyZakonczony()){
            this.dodajModul(formService, null, dialog);
        }
        if(!this.IsModule && this.naglowek.CzyZakonczony()){
            this.moduly.forEach(mod => {
                mod.moduleNg.edytowane = false; 
                mod.moduleNg.poprawnie_zakonczone = true;

            });
        }
        if(!this.IsModuleFooter && this.naglowek.CzyZakonczony()){
            this.moduly.forEach(mod => {
                if(mod.moduleNg.CzyZakonczony()){
                    var poz_zakonczone = true;
                    mod.pozycje.forEach(poz => {
                        if(!poz.CzyZakonczony()){
                            poz_zakonczone = false;
                        }
                    });
                    if(poz_zakonczone && mod.pozycje.length > 0){
                        mod.moduleFooter.edytowane = false; 
                        mod.moduleFooter.poprawnie_zakonczone = true;
                        // console.log("bez stopki", mod);
                        var poz_ok = true;
                        mod.pozycje.forEach(p => {
                            if(p.poprawnie_zakonczone == false || p.edytowane == true){
                                poz_ok = false;
                            }
                        });
                        if(poz_ok && !this.IsFooter){
                            this.supervisor.edycja_pozycji = false;
                            mod.zakonczono_pozycje = true;
                        }
                    }
                    
                }
                
            });
        }
        if(!this.IsFooter && this.naglowek.CzyZakonczony()){
            var mod_poprawne= true;
            this.moduly.forEach(mod => {
                if(!mod.moduleFooter.CzyZakonczony()){
                    mod_poprawne = false;
                }
            });
            if(mod_poprawne){
                this.stopka.edytowane = false; 
                this.stopka.poprawnie_zakonczone = true;
            }
        }
        if(this.AktualneKroki().typ == TypEnum.Footer && this.AktualneKroki().CzyZakonczony()){
            this.AktualneKroki().edytowane = false; 
            this.AktualneKroki().poprawnie_zakonczone = true;
        }
        if(this.AktualneKroki().typ != TypEnum.Position){
            this.supervisor.edycja_pozycji = false;
        }
        this.supervisor.goToFooter = false;
        // console.log("gotofooter", this.supervisor);
    }

   /* nextDelivery(){
        if(this.czyPoprawneDelivery()){
            //this.delivery.czyAdres = false;
            this.supervisor.aktualnyTyp = TypEnum.Header;
            
            return true;
        } else {
            return false;
        }
    }*/
    public GetPrevParams(){
        var p:Parameter[] = [];
        this.moduly.forEach(m => {
           
            m.pozycje.forEach(poz => {
                poz.kroki.forEach(k => {
                    if(k.Module == this.supervisor.getAktualnyMod() && k.Position == this.supervisor.getAktualnaPoz() - 1){
                        k.Controlls.forEach(con => {
                            if(con.wybrany.Key != 'X'){
                                p.push(con.wybrany);
                            }
                        });
                    }
                });
            });
        });
        return p;
    }
    public GetAllParamsSave(){
        

        var p:Parameter[] = [];
      
        this.naglowek.kroki.forEach(k => {
                k.Controlls.forEach(con => {
                    if(con.wybrany.Key != 'X'){
                        con.wybrany.Position = 0;
                        con.wybrany.Module = 0;
                        con.wybrany.Type = 0;
                        
                        //p.push(con.wybrany);
                        this.DodajDoParamSave(p, con, 0 , 0);
                    }
                });
        });

        for(var i = 0;  i < this.moduly.length; i++){
            this.moduly[i].moduleNg.kroki.forEach(k => {
                    k.Controlls.forEach(con => {
                        if(con.wybrany.Key != 'X'){
                            //p.push(con.wybrany);
                            this.DodajDoParamSave(p, con, i+1, 0);
                        }
                    });
            });

            for(var ip = 0; ip < this.moduly[i].pozycje.length; ip++){
                this.moduly[i].pozycje[ip].kroki.forEach(k => {
                    k.Controlls.forEach(con => {
                        if(con.wybrany.Key != 'X'){
                            //p.push(con.wybrany);
                            this.DodajDoParamSave(p, con, i+1, ip+1);
                        }
                    });
                });
            }
            
            

            this.moduly[i].moduleFooter.kroki.forEach(k => {
                    k.Controlls.forEach(con => {
                        if(con.wybrany.Key != 'X'){
                            //p.push(con.wybrany);
                            this.DodajDoParamSave(p, con, i+1, 0);
                        }
                    });
            });
        }
        
        
        
        this.stopka.kroki.forEach(k => {
                k.Controlls.forEach(con => {
                    // console.log("w stopce", con.wybrany);
                    if(con.wybrany.Key != 'X'){
                        //p.push(con.wybrany);
                        this.DodajDoParamSave(p, con, 0, 0);
                    }
                });
        });
        // console.log("param", p);
        return p;
    }

    public Dodaj_wersje(p:Parameter[]){
        var par:Parameter = new Parameter();
        par.IsPrint = false;
        par.Module = 0;
        par.Position = 0;
        par.Value = this.TechVersion;
        par.DisplayValue = this.TechVersion;
        par.DisplayValueCs = this.TechVersion;
        par.DisplayValueDe = this.TechVersion;
        par.DisplayValueEn = this.TechVersion;
        par.DisplayValueFr = this.TechVersion;
        par.DisplayValuePl = this.TechVersion;
        par.DisplayValueIt = this.TechVersion;
        par.Type = 0;
        par.IsHidden = true;
        par.ControlName = 'Hidden';
        par.Key = "TechVersion";
        par.Disabled =false;
        p.push(par);
    }
    public GetAllParams(){
        


        var p:Parameter[] = [];
        var wyliczane:Parameter[] = [];
        var wyliczane_mod:Parameter[] = [];
      

        this.Dodaj_wersje(p);

        this.naglowek.kroki.forEach(k => {
                k.Controlls.forEach(con => {
                    if(con.wybrany.Key != 'X'){
                        con.wybrany.Position = 0;
                        con.wybrany.Module = 0;
                        con.wybrany.Type = 0;
                        
                        //p.push(con.wybrany);
                        con.wybrany.Type = 0;
                        this.DodajDoParam(p, con, 0 , 0);
                    } else if(con.ControlType == 'CheckSelect' || con.ControlType == 'CheckNumber' || con.ControlType == 'CheckSelectMultiColumn'){
                        con.wybrany.Type = 0;
                        this.DodajDoParam(p, con, 0 , 0);
                    }
                });
        });

        wyliczane_mod = [];
        for(var i = 0;  i < this.moduly.length; i++){
            this.moduly[i].moduleNg.kroki.forEach(k => {
                    k.Controlls.forEach(con => {
                        if(con.wybrany.Key != 'X'){
                            //p.push(con.wybrany);
                            this.DodajDoParam(p, con, i+1, 0);
                            this.WyliczDodatkoweMod(wyliczane_mod, con.wybrany);
                            con.wybrany.Type = 1;
                        } else if(con.ControlType == 'CheckSelect' || con.ControlType == 'CheckNumber' || con.ControlType == 'CheckSelectMultiColumn'){
                            con.wybrany.Type = 1;
                            this.DodajDoParam(p, con, i+1, 0);
                        }
                    });
            });
            wyliczane = [];
            for(var ip = 0; ip < this.moduly[i].pozycje.length; ip++){
                this.moduly[i].pozycje[ip].kroki.forEach(k => {
                    k.Controlls.forEach(con => {
                        // console.log('p2', con.ControlType, con.KeyName);
                        if(con.wybrany.Key != 'X'){
                            //p.push(con.wybrany);
                            con.wybrany.Type = 2;
                            this.DodajDoParam(p, con, i+1, ip+1);
                            this.WyliczDodatkowe(wyliczane, con.wybrany);
                        } else if(con.ControlType == 'CheckSelect' || con.ControlType == 'CheckNumber' || con.ControlType == 'CheckSelectMultiColumn'){
                            con.wybrany.Type = 2;
                            this.DodajDoParam(p, con, i+1, ip+1);
                        }
                    });
                });
                var par:Parameter = new Parameter();
                par.IsPrint = false;
                par.Module = i+1;
                par.Position = ip+1;
                par.Value = ip+1;
                par.DisplayValue = ip+1;
                par.DisplayValueCs = ip+1;
                par.DisplayValueDe = ip+1;
                par.DisplayValueEn = ip+1;
                par.DisplayValueFr = ip+1;
                par.DisplayValuePl = ip+1;
                par.DisplayValueIt = ip+1;
                par.Type = 2;
                par.IsHidden = true;
                par.ControlName = 'Hidden';
                par.Key = "Numerpozycji";
                par.Disabled =false;
                p.push(par);
                var par2:Parameter = new Parameter();
                par2.IsPrint = false;
                par2.Module = i+1;
                par2.Position = ip+1;
                par2.Value = i+1;
                par2.DisplayValue = i+1;
                par2.DisplayValueCs = i+1;
                par2.DisplayValueDe = i+1;
                par2.DisplayValueEn = i+1;
                par2.DisplayValueFr = i+1;
                par2.DisplayValuePl = i+1;
                par2.DisplayValueIt = i+1;
                par2.Type = 2;
                par.IsHidden = true;
                par2.Key = "Numermoduł";
                par2.ControlName = 'Hidden';
                par2.Disabled =false;
                p.push(par2);

            }
            
            
            wyliczane.forEach(w => {
                p.push(w);
            });
            

            this.moduly[i].moduleFooter.kroki.forEach(k => {
                    k.Controlls.forEach(con => {
                        if(con.wybrany.Key != 'X'){
                            //p.push(con.wybrany);
                            con.wybrany.Type = 3;
                            this.DodajDoParam(p, con, i+1, 0);
                            this.WyliczDodatkoweMod(wyliczane_mod, con.wybrany);
                        } else if(con.ControlType == 'CheckSelect' || con.ControlType == 'CheckNumber' || con.ControlType == 'CheckSelectMultiColumn'){
                            con.wybrany.Type = 3;
                            this.DodajDoParam(p, con, i+1, 0);
                            this.WyliczDodatkoweMod(wyliczane_mod, con.wybrany);
                        }
                    });
            });
        }
        
        wyliczane_mod.forEach(w => {
            p.push(w);
        });
        
        
        
        this.stopka.kroki.forEach(k => {
                k.Controlls.forEach(con => {
                    // console.log("w stopce", con.wybrany);
                    if(con.wybrany.Key != 'X'){
                        //p.push(con.wybrany);
                        con.wybrany.Type = 4;
                        this.DodajDoParam(p, con, 0, 0);
                    }else if(con.ControlType == 'CheckSelect' || con.ControlType == 'CheckNumber' || con.ControlType == 'CheckSelectMultiColumn'){
                        con.wybrany.Type = 4;
                        this.DodajDoParam(p, con, 0, 0);
                    }
                });
        });
        // console.log("param", p);
        return p;
    }
    public GetParams(){



        var p:Parameter[] = [];
        var wyliczane:Parameter[] = [];
        var wyliczane_mod:Parameter[] = [];
        // console.log();
        //public moduly:Modul[] = [];
        this.naglowek.kroki.forEach(k => {
                k.Controlls.forEach(con => {
                    if(con.wybrany.Key != 'X'){
                        //p.push(con.wybrany);
                        this.DodajDoParam(p, con, 0, 0);
                    }
                });
        });

        // console.log("mod", this.moduly, this.supervisor.getAktualnyMod(), this.supervisor.getAktualnaPoz());
        wyliczane_mod = [];
        this.moduly.forEach(m => {
            m.moduleNg.kroki.forEach(k => {
                if(k.Module == this.supervisor.getAktualnyMod() && k.Position == 0){
                    k.Controlls.forEach(con => {
                        if(con.wybrany.Key != 'X'){
                            //p.push(con.wybrany);
                            this.DodajDoParam(p, con, k.Module, 0);
                        }
                    });
                }
                k.Controlls.forEach(con => {
                    if(con.wybrany.Key != 'X'){
                        this.WyliczDodatkoweMod(wyliczane_mod, con.wybrany);
                    }
                });
            });
            m.pozycje.forEach(poz => {
                poz.kroki.forEach(k => {
                    if(k.Module == this.supervisor.getAktualnyMod() && k.Position == this.supervisor.getAktualnaPoz()){
                        k.Controlls.forEach(con => {
                            if(con.wybrany.Key != 'X'){
                                //p.push(con.wybrany);
                                this.DodajDoParam(p, con, k.Module, k.Position);
                            }
                        });
                    }
                });
            });

            wyliczane = [];
            m.pozycje.forEach(poz => {
                poz.kroki.forEach(k => {
                    if(k.Module == this.supervisor.getAktualnyMod()){
                        k.Controlls.forEach(con => {
                            if(con.wybrany.Key != 'X'){
                                this.WyliczDodatkowe(wyliczane, con.wybrany);
                            }
                        });
                    }
                });
            });




            if(this.supervisor.aktualnyTyp == TypEnum.ModuleFooter || this.supervisor.aktualnyTyp == TypEnum.Position){
                wyliczane.forEach(w => {
                    p.push(w);
                });
            }
            m.moduleFooter.kroki.forEach(k => {
                if(k.Module == this.supervisor.getAktualnyMod() && k.Position == 0){
                    k.Controlls.forEach(con => {
                        if(con.wybrany.Key != 'X'){
                            //p.push(con.wybrany);
                            this.DodajDoParam(p, con, k.Module, 0);
                        }
                    });
                }
                k.Controlls.forEach(con => {
                    if(con.wybrany.Key != 'X'){
                        this.WyliczDodatkoweMod(wyliczane_mod, con.wybrany);
                    }
                });
            });


        });
        //if(this.supervisor.aktualnyTyp == TypEnum.Footer || this.supervisor.aktualnyTyp == TypEnum.ModuleFooter || this.supervisor.aktualnyTyp == TypEnum.Position){
            wyliczane_mod.forEach(w => {
                p.push(w);
            });
        //}
        
        this.stopka.kroki.forEach(k => {
            if((k.Module == 0 && k.Position == 0)){
                k.Controlls.forEach(con => {
                    if(con.wybrany.Key != 'X'){
                        //p.push(con.wybrany);
                        con.wybrany.Type = 4;
                        this.DodajDoParam(p, con, 0, 0);
                    }
                });
            }
        });
        return p;
    }
    DodajDoParamSave(p:Parameter[],wparam:Control, mod:Number, poz:Number){
           
       if(wparam.ControlType != "Pokaz" && wparam.ControlType != "Hidden" 
       && wparam.ControlType != "CheckFile"  && wparam.ControlType != "File" 
       && wparam.ControlType != "NewLine"
       && wparam.ControlType != "Label"
       && wparam.ControlType != "VarianticVisualization"
       && wparam.ControlType != "Pdf"
       && wparam.ControlType != "Kim" ){
            wparam.wybrany.IsPrint = wparam.IsPrint;
            wparam.wybrany.Module = mod.valueOf();
            wparam.wybrany.Position = poz.valueOf();
            wparam.wybrany.IsHidden = wparam.Hidden;
            wparam.wybrany.ControlName  =   wparam.ControlType;
            /*if(wparam.wybrany.Type == null){
                if(mod == 0 && poz == 0){
                    wparam.wybrany.Type =0;
                }
            }*/
            if(wparam.wybrany.Value != null){
                var index = p.findIndex(x => x.Key == wparam.KeyName && x.Module == wparam.wybrany.Module && x.Position == wparam.wybrany.Position);
                if(index < 0){
                    var par = new Parameter();
                    par.Key = wparam.wybrany.Key;
                    par.Position = wparam.wybrany.Position;
                    par.Module = wparam.wybrany.Module;
                    par.Type = wparam.wybrany.Type;
                    par.Value = wparam.wybrany.Value;

                    p.push(par);

                } else {
                    var par = new Parameter();
                    par.Key = wparam.wybrany.Key;
                    par.Position = wparam.wybrany.Position;
                    par.Module = wparam.wybrany.Module;
                    par.Type = wparam.wybrany.Type;
                    par.Value = wparam.wybrany.Value;
                    p[index] = par;
                }
                
            }
            
        }

    }
    DodajDoParam(p:Parameter[],wparam:Control, mod:Number, poz:Number){
        if(wparam.wybrany.Value == null || wparam.wybrany.Module == null || wparam.wybrany.Position == null || wparam.wybrany.Type == null){
            // console.log(wparam);
        }
        if(wparam.ControlType === "File" || wparam.ControlType === "CheckFile"){
            wparam.wybrany.IsPrint = wparam.IsPrint;
            wparam.wybrany.Module = mod.valueOf();
            wparam.wybrany.Position = poz.valueOf();
            /*if(wparam.wybrany.Type == null){
                if(mod == 0 && poz == 0){
                    wparam.wybrany.Type =0;
                }
            }*/
            var par:Parameter = new Parameter();
            par.IsPrint = wparam.IsPrint;
            par.Module = mod.valueOf();
            par.Position = poz.valueOf();
            par.Value = wparam.wybrany.Value;
            par.DisplayValue = wparam.wybrany.DisplayValue;
            par.DisplayValueCs = wparam.wybrany.DisplayValueCs;
            par.DisplayValueDe = wparam.wybrany.DisplayValueDe;
            par.DisplayValueEn = wparam.wybrany.DisplayValueEn;
            par.DisplayValueFr = wparam.wybrany.DisplayValueFr;
            par.DisplayValuePl = wparam.wybrany.DisplayValueIt;
            par.DisplayValueIt = wparam.wybrany.DisplayValueIt;
            par.Type = wparam.wybrany.Type;
            par.Key = wparam.wybrany.Key;
            par.Disabled = wparam.wybrany.Disabled;
            par.IsHidden = wparam.Hidden;
            par.ControlName = wparam.ControlType;
            // console.log("par", par);
            if(par.Value != null){
                var tp_guid = par.Value;
                var new_guid = this.generateGuid();
                
                this.Files.forEach(file => {
                    if(file.Key == par.Key){
                        file.Id = new_guid;
                    }
                });
                par.Value = new_guid;

                console.log("plik", par, this.Files, tp_guid);
                p.push(par);
            }
        } else if(wparam.ControlType != "Pokaz"){
            wparam.wybrany.IsPrint = wparam.IsPrint;
            wparam.wybrany.Module = mod.valueOf();
            wparam.wybrany.Position = poz.valueOf();
            wparam.wybrany.IsHidden = wparam.Hidden;
            wparam.wybrany.ControlName  =   wparam.ControlType;
            /*if(wparam.wybrany.Type == null){
                if(mod == 0 && poz == 0){
                    wparam.wybrany.Type =0;
                }
            }*/
          
            if(wparam.wybrany.Value != null){
                if(wparam.ControlType == "CheckNumber" || wparam.ControlType == "CheckSelect" || wparam.ControlType == 'CheckSelectMultiColumn'){
                    if(wparam.wybrany.Value != ''){
                        var index = p.findIndex(x => x.Key == wparam.KeyName && x.Module == wparam.wybrany.Module && x.Position == wparam.wybrany.Position);
                        if(index < 0){
                            p.push(wparam.wybrany);
                        } else {
                            p[index] = wparam.wybrany;
                        }
                    }
                } else if(wparam.ControlType == "Number" || wparam.ControlType == "Text" ){
                    wparam.wybrany.DisplayValue = wparam.wybrany.Value;
                    wparam.wybrany.DisplayValueCs= wparam.wybrany.Value;
                    wparam.wybrany.DisplayValueDe = wparam.wybrany.Value;
                    wparam.wybrany.DisplayValueEn = wparam.wybrany.Value;
                    wparam.wybrany.DisplayValueFr = wparam.wybrany.Value;
                    wparam.wybrany.DisplayValueIt = wparam.wybrany.Value;
                    wparam.wybrany.DisplayValuePl = wparam.wybrany.Value;

                    var index = p.findIndex(x => x.Key == wparam.KeyName && x.Module == wparam.wybrany.Module && x.Position == wparam.wybrany.Position);
                    if(index < 0){
                        p.push(wparam.wybrany);
                    } else {
                        p[index] = wparam.wybrany;
                    }
                } else {
                    // console.log("par", wparam);
                    var index = p.findIndex(x => x.Key == wparam.KeyName && x.Module == wparam.wybrany.Module && x.Position == wparam.wybrany.Position);
                    if(index < 0){
                        p.push(wparam.wybrany);
                    } else {
                        p[index] = wparam.wybrany;
                    }
                }
                
                
            }
            /* else if(wparam.ControlType == "CheckNumber" || wparam.ControlType == "CheckSelect" || wparam.ControlType == 'CheckSelectMultiColumn'){
                // console.log("par null", wparam);
                wparam.wybrany.Value = '';
                wparam.wybrany.Key  =   wparam.KeyName;
                var index = p.findIndex(x => x.Key == wparam.KeyName && x.Module == wparam.wybrany.Module && x.Position == wparam.wybrany.Position);
                if(index < 0){
                    p.push(wparam.wybrany);
                } else {
                    p[index] = wparam.wybrany;
                }
            } */
            
        }

    }
    generateGuid() : string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
    WyliczDodatkoweMod(wyliczane: Parameter[], wybrany: Parameter) {
        if (!isNaN(wybrany.Value)) {
            var suma = wyliczane.find(x=> x.Key == "suma_" + wybrany.Key);
            var min = wyliczane.find(x=> x.Key == "min_" + wybrany.Key);
            var max = wyliczane.find(x=> x.Key == "max_" + wybrany.Key);
            if(suma != null){
                suma.DisplayValue = suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValueCs = suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValueDe = suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValueEn = suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValueFr = suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValueIt = suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValuePl = suma.Value*1+ wybrany.Value*1;  
                suma.Value = suma.Value*1+ wybrany.Value*1;  
            } else {
                var p:Parameter = new Parameter();
                p.Key = "suma_"+wybrany.Key;
                p.Value = wybrany.Value*1;
                p.DisplayValue = wybrany.Value*1;
                p.DisplayValueCs = wybrany.Value*1;
                p.DisplayValueDe = wybrany.Value*1;
                p.DisplayValueEn = wybrany.Value*1;
                p.DisplayValueFr = wybrany.Value*1;
                p.DisplayValueIt = wybrany.Value*1;
                p.DisplayValuePl = wybrany.Value*1;
                p.Module = 0;
                p.IsHidden = true;
                p.ControlName = 'Hidden';
                p.Position = 0;
                p.Type = 0;
                wyliczane.push(p); 
            }
            if(min != null){
                if(wybrany.Value*1 < min.Value*1){ 
                    min.Value = wybrany.Value*1; 
                    min.DisplayValue = wybrany.Value*1;
                    min.DisplayValueCs = wybrany.Value*1;
                    min.DisplayValueDe = wybrany.Value*1;
                    min.DisplayValueEn = wybrany.Value*1;
                    min.DisplayValueFr = wybrany.Value*1;
                    min.DisplayValueIt = wybrany.Value*1;
                    min.DisplayValuePl = wybrany.Value*1;
                }
            } else {
                var p:Parameter = new Parameter();
                p.Key = "min_"+wybrany.Key;
                p.Value = wybrany.Value*1;
                p.DisplayValue = wybrany.Value*1;
                p.DisplayValueCs = wybrany.Value*1;
                p.DisplayValueDe = wybrany.Value*1;
                p.DisplayValueEn = wybrany.Value*1;
                p.DisplayValueFr = wybrany.Value*1;
                p.DisplayValueIt = wybrany.Value*1;
                p.DisplayValuePl = wybrany.Value*1;
                p.IsHidden = true;
                p.ControlName = 'Hidden';
                p.Module = 0;
                p.Position = 0;
                p.Type = 0;
                wyliczane.push(p);
            }
            if(max != null){
                if(wybrany.Value*1 > max.Value*1){
                    max.Value = wybrany.Value*1;
                    max.DisplayValue = wybrany.Value*1;
                    max.DisplayValueCs = wybrany.Value*1;
                    max.DisplayValueDe = wybrany.Value*1;
                    max.DisplayValueEn = wybrany.Value*1;
                    max.DisplayValueFr = wybrany.Value*1;
                    max.DisplayValueIt = wybrany.Value*1;
                    max.DisplayValuePl = wybrany.Value*1;
                }
            } else {
                var p:Parameter = new Parameter();
                p.Key = "max_"+wybrany.Key;
                p.Value = wybrany.Value*1; 
                p.DisplayValue = wybrany.Value*1;
                p.DisplayValueCs = wybrany.Value*1;
                p.DisplayValueDe = wybrany.Value*1;
                p.DisplayValueEn = wybrany.Value*1;
                p.DisplayValueFr = wybrany.Value*1;
                p.DisplayValueIt = wybrany.Value*1;
                p.DisplayValuePl = wybrany.Value*1;
                p.IsHidden = true;
                p.ControlName = 'Hidden';
                p.Module = 0;
                p.Position = 0;
                p.Type = 0;
                wyliczane.push(p);
            }
            
        }
    }
    WyliczDodatkowe(wyliczane: Parameter[], wybrany: Parameter) {
        if (!isNaN(wybrany.Value)) {
            var suma = wyliczane.find(x=> x.Key == "suma_" + wybrany.Key);
            var min = wyliczane.find(x=> x.Key == "min_" + wybrany.Key);
            var max = wyliczane.find(x=> x.Key == "max_" + wybrany.Key);
            if(suma != null){
                suma.DisplayValue =suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValueCs =suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValueDe = suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValueEn = suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValueFr = suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValueIt = suma.Value*1+ wybrany.Value*1;  
                suma.DisplayValuePl = suma.Value*1+ wybrany.Value*1;  
                suma.Value = suma.Value*1+ wybrany.Value*1;  
            } else {
                var p:Parameter = new Parameter();
                p.Key = "suma_"+wybrany.Key;
                p.Value = wybrany.Value*1;
                p.DisplayValue = wybrany.Value*1;
                p.DisplayValueCs = wybrany.Value*1;
                p.DisplayValueDe = wybrany.Value*1;
                p.DisplayValueEn = wybrany.Value*1;
                p.DisplayValueFr = wybrany.Value*1;
                p.DisplayValueIt = wybrany.Value*1;
                p.DisplayValuePl = wybrany.Value*1;
                p.Module = wybrany.Module;
                p.IsHidden = true;
                p.ControlName = 'Hidden';
                p.Position = 0;
                p.Type = 3;
                wyliczane.push(p); 
            }
            if(min != null){
                if(wybrany.Value*1 < min.Value*1){ 
                    min.Value = wybrany.Value*1; 
                    min.DisplayValue = wybrany.Value*1;
                    min.DisplayValueCs = wybrany.Value*1;
                    min.DisplayValueDe = wybrany.Value*1;
                    min.DisplayValueEn = wybrany.Value*1;
                    min.DisplayValueFr = wybrany.Value*1;
                    min.DisplayValueIt = wybrany.Value*1;
                    min.DisplayValuePl = wybrany.Value*1;
                }
            } else {
                var p:Parameter = new Parameter();
                p.Key = "min_"+wybrany.Key;
                p.Value = wybrany.Value*1;
                p.DisplayValue = wybrany.Value*1;
                p.DisplayValueCs = wybrany.Value*1;
                p.DisplayValueDe = wybrany.Value*1;
                p.DisplayValueEn = wybrany.Value*1;
                p.DisplayValueFr = wybrany.Value*1;
                p.DisplayValueIt = wybrany.Value*1;
                p.DisplayValuePl = wybrany.Value*1;
                p.IsHidden = true;
                p.ControlName = 'Hidden';
                p.Module = wybrany.Module;
                p.Position = 0;
                p.Type = 3;
                wyliczane.push(p);
            }
            if(max != null){
                if(wybrany.Value*1 > max.Value*1){
                    max.Value = wybrany.Value*1;
                    max.DisplayValue = wybrany.Value*1;
                    max.DisplayValueCs = wybrany.Value*1;
                    max.DisplayValueDe = wybrany.Value*1;
                    max.DisplayValueEn = wybrany.Value*1;
                    max.DisplayValueFr = wybrany.Value*1;
                    max.DisplayValueIt = wybrany.Value*1;
                    max.DisplayValuePl = wybrany.Value*1;
                }
            } else {
                var p:Parameter = new Parameter();
                p.Key = "max_"+wybrany.Key;
                p.Value = wybrany.Value*1; 
                p.DisplayValue = wybrany.Value*1;
                p.DisplayValueCs = wybrany.Value*1;
                p.DisplayValueDe = wybrany.Value*1;
                p.DisplayValueEn = wybrany.Value*1;
                p.DisplayValueFr = wybrany.Value*1;
                p.DisplayValueIt = wybrany.Value*1;
                p.DisplayValuePl = wybrany.Value*1;
                p.IsHidden = true;
                p.ControlName = 'Hidden';
                p.Module = wybrany.Module;
                p.Position = 0;
                p.Type = 3;
                wyliczane.push(p);
            }
            
        }
    }

    wczytajKrok2(data, tresci, dialog, formService){
        this.ostatniKrok = data;
        this.wczytywanie = false;
        // console.log("wczyt1 - false");
        // console.log("step", data, this); 

        
        var k:KrokStep = data;
        k.Module = this.supervisor.getAktualnyMod();
        k.Position = this.supervisor.getAktualnaPoz();
          var c:Control;
          k.Controlls.forEach(con => {
              if(con.ControlType == "Komunikat"){
                  c = con;
              }
          });

          if(c!= null){
           
              let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
              const dataDial: DialogData = {
                title: '',
                content: c.DefaultValue,
                answerOk: true,
              };
              dialogRef = dialog.open(UniversalDialogComponent, { data: dataDial, role: 'alertdialog' });

          } else {

              //czy jest taki sam typ
              if(this.supervisor.aktualnyTyp == k.Type){
                  this.ustawKrok(k);
                  if(!this.AktualneKroki().kroki[this.AktualneKroki().akualnyNumer].czyWidoczny()){
                      this.wczytajKrok(formService, true, tresci, null, dialog);
                  } else {
                      if(this.supervisor.auto.valueOf() > 0){
                          this.nextAuto(tresci, formService, true, dialog);
                      }
                  }
              } else {
                  // console.log("koncze kroki 1", this.AktualneKroki(), this.supervisor.auto);
                  this.supervisor.ZakonczGrupeKrokow(this.AktualneKroki());
                  if(this.supervisor.auto.valueOf() > 0){
                      this.nextAutoEnd(tresci, formService, true, dialog, k);
                  } else {
                      // console.log("ak", this.AktualneKroki());
                      k.Module = this.supervisor.getAktualnyMod();
                      k.Position = this.supervisor.getAktualnaPoz();
                      if(this.supervisor.aktualnyTyp == TypEnum.Header){
                          if(this.moduly.length == 0){
                              if(k.Type == TypEnum.ModuleHeader){
                                  k.Module = 1;
                                  k.Position = 0;
                                  this.ustawKrok(k);
                              } else if(k.Type == TypEnum.Position){
                                  k.Module = 1;
                                  k.Position = 1;
                                  this.ustawKrok(k);
                              }
                          } else {
                              for(var i = 0;i < this.moduly.length;i++){
                                  if(k.Type == TypEnum.ModuleHeader){
                                      if(this.moduly[i].moduleNg.kroki.length == 0){
                                          k.Module = i+1;
                                          k.Position = 0;
                                          this.ustawKrok(k);
                                      }
                                  } else if(k.Type == TypEnum.Position){
                                      if(!this.IsModule){
                                          k.Module = i+1;
                                          k.Position = 1;
                                          this.ustawKrok(k);
                                      }
                                      //k.Module = i+1;
                                      //k.Position = 1;
                                      //this.ustawKrok(k);

                                  }
                              }
                          
                          }
                      

                          
                      } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleHeader && this.moduly[this.supervisor.aktualny_mod - 1].pozycje.length == 0){

                          if(k.Type == TypEnum.Position){
                              k.Position = 1;
                              this.ustawKrok(k);
                          }
                      } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleFooter){

                          if(k.Type == TypEnum.Footer){
                              /*
                              k.Position = 0;
                              k.Module = 0;
                              if(this.stopka == null || this.stopka.kroki.length == 0){
                                  this.ustawKrok(k);
                              } else {
                                  this.supervisor.zakladka = 2;
                              }*/
                          } else {
                              
                          }
                      } else if(this.supervisor.aktualnyTyp == TypEnum.Position){
                          // console.log("xxx2");
                          if(k.Type == TypEnum.Footer){
                              
                              this.moduly[this.supervisor.aktualny_mod - 1].moduleFooter.poprawnie_zakonczone = true
                              this.moduly[this.supervisor.aktualny_mod - 1].moduleFooter.edytowane = false;
                              this.supervisor.edycja_pozycji = false; 
/*
                              k.Position = 0;
                              k.Module = 0;
                              if(this.stopka == null || this.stopka.kroki.length == 0){
                                  this.ustawKrok(k);
                              } else {
                                  this.supervisor.zakladka = 2;
                              } */
                          } else {
                              
                          }
                          
                      }
                  }
                  
                  
              /*  if(this.supervisor.ModulePositionForGetStep(k, this.AktualneKroki())){
                      if(k.Type == TypEnum.ModuleHeader){
                          if(this.moduly.length == 0){
                              this.ustawKrok(k);
                          } else {
                              
                              this.GenerujKrok(k); 
                          }
                      } else if(k.Type == TypEnum.Position){
                          
                          if(this.moduly.length == 0 || this.moduly[this.supervisor.aktualny_mod - 1].pozycje.length == 0){
                              this.ustawKrok(k);
                          } else {
                              
                              this.GenerujKrok(k);
                          }
                      } else if(k.Type == TypEnum.ModuleFooter){
                          if(this.moduly.length == 0 || this.moduly[this.supervisor.aktualny_mod - 1].moduleFooter.kroki.length == 0){
                              this.ustawKrok(k);
                          } else {
                              
                              this.GenerujKrok(k);
                          }
                      }
                      //  this.ustawKrok(k);
                  } */
              }
              if(tresci != null){
                  // var x = this.moduly;
                  
                  if(k.Position.valueOf() > 0){
                      if(this.supervisor.auto.valueOf() != 2){
                          try {
                              tresci.scrollToPozycja('#krok_' + this.AktualneKroki().kroki[this.AktualneKroki().akualnyNumer].Step);
                          } catch (Exception){
                              // console.log("błąd scrollowania 1");
                          }
                          
                      }
                      
                      //tresci.scrollToPozycja('#krok_' + this.AktualneKroki().kroki[this.AktualneKroki().akualnyNumer].Step);
                  } else {
                      if(this.supervisor.auto.valueOf() != 2){
                          try {
                              tresci.scrollTo('#krok_' + this.AktualneKroki().kroki[this.AktualneKroki().akualnyNumer].Step);
                          } catch (Exception){
                              // console.log("błąd scrollowania 2");
                          }
                          
                      }
                      
                  }
                  
                  
              } else {
                  // console.log("tresci null");
              }
          }
    }

    wczytajKrok(formService:FormV2ServiceService, isCache:boolean, tresci, step, dialog: MatDialog){
        
        this.wczytywanie = true;
        // console.log("wczyt - true");
        if(step == null && this.AktualneKroki().kroki.length > this.AktualneKroki().akualnyNumer){
            step = this.AktualneKroki().kroki[this.AktualneKroki().akualnyNumer].Step;
        }
        // console.log("FormData", this); 
        /*
        if(((this.supervisor.auto.valueOf() == 2 && !this.CzyWczytane) || (this.supervisor.auto.valueOf() == 1 && this.supervisor.byl_skip)) && this.AktualneKroki().akualnyNumer == 0){
            // console.log("moge multi", this.AktualneKroki().akualnyNumer, this.supervisor.aktualnyTyp, this.supervisor.aktualny_mod, this.supervisor.aktualna_poz);
            formService.getMultiStep(this.produktPrefix, this.culture, isCache, this.IsTrader,this.GetParams(),step, this.GetPrevParams(), this.supervisor.aktualnyTyp).
            subscribe((data) => {
                
                this.krokiTMP = data;
                this.numer_krokuTMP = 0;
                this.pozycjaTMP = this.supervisor.aktualna_poz;
                this.modulTMP = this.supervisor.aktualny_mod;
                //this. wczytajKrok2(data, tresci, dialog, formService);
            }, (err) => {
                // console.log("blad wczytywania");
                this.wczytywanie = false;
                this.supervisor.wczytywanieAuto = false;
                // console.log("wczyt2 - false");
            }, () => {
                // console.log("wczyt3 - false");
                // this.wczytywanie = false;
            });
        }
        */
        this.supervisor.byl_skip = false;

        this.ZapiszParametry();

        formService.getStep(this.produktPrefix, this.culture, isCache, this.IsTrader,this.GetParams(),step, this.GetPrevParams()).
        subscribe((data) => {
            
           this. wczytajKrok2(data, tresci, dialog, formService);
        }, (err) => {
            // console.log("blad wczytywania");
             this.wczytywanie = false;
             this.supervisor.wczytywanieAuto = false;
            // console.log("wczyt2 - false");
        }, () => {
            // console.log("wczyt3 - false");
            // this.wczytywanie = false;
        }
        
        );
    }
    GenerujKrok(krok:KrokStep){

    }
    public czyZakonczJakMozeszDodac(){
        var mozna = false;
        var bylo_mod = false;
        if(!this.IsModule){
            mozna = true;
        } 

        
        var czyNiest = false;
        if(this.naglowek.kroki.length > 0){
            this.naglowek.kroki.forEach(krok => {
                krok.Controlls.forEach(c => {
                  if(c.KeyName == 'JakaModułowość'){
                    if(c.wybrany.Value === 'TAK'){
                        czyNiest = true;
                    }
                  }
                });
              });
        }
        if(czyNiest){
            return true;
        }

        if(this.moduly[this.supervisor.getAktualnyMod() - 1].moduleNg.kroki.length > 0){
            this.moduly[this.supervisor.getAktualnyMod() - 1].moduleNg.kroki.forEach(krok => {
              krok.Controlls.forEach(c => {
                if(c.KeyName == 'CzyModuł'){
                  if(c.wybrany.Value === 'TAK11'){
                    mozna = true;
                  }
                  bylo_mod = true;
                }
              });
            });
          }
          if(!bylo_mod){
            mozna = true; 
          }
        return mozna;
    }
    public czyWielomodOK(){

        var czy_wielomod = false;
        var bylo_mod = false;
        var czyNiest = false;

        if(this.naglowek.kroki.length > 0){
            this.naglowek.kroki.forEach(krok => {
                krok.Controlls.forEach(c => {
                  if(c.KeyName == 'JakaModułowość'){
                    if(c.wybrany.Value === 'TAK'){
                        czyNiest = true;
                    }
                  }
                });
              });
        }
        if(czyNiest){
            return true;
        }


        if(this.moduly[this.supervisor.getAktualnyMod() - 1].moduleNg.kroki.length > 0){
            this.moduly[this.supervisor.getAktualnyMod() - 1].moduleNg.kroki.forEach(krok => {
              krok.Controlls.forEach(c => {
                if(c.KeyName == 'CzyModuł'){
                  if(c.wybrany.Value === 'TAK' || c.wybrany.Value === 'TAK11' || c.wybrany.Value === 'TAKPT0405' || c.wybrany.Value === 'MULTIZIIP120' ){
                    czy_wielomod = true;
                  }
                  bylo_mod = true;
                }
              });
            });
          }


          if(czy_wielomod || !bylo_mod){
            return true;
          } else {
            if(this.moduly[this.supervisor.getAktualnyMod() - 1].pozycje.length > 1 && this.IsModule){
                return false;
            } else {
                return true;
            }   
          } 
    }
    public czyMoznaDodacPozycje(){
        var wyb:string = '';


        var czyNiest = false;
        if(this.naglowek.kroki.length > 0){
            this.naglowek.kroki.forEach(krok => {
                krok.Controlls.forEach(c => {
                  if(c.KeyName == 'JakaModułowość'){
                    if(c.wybrany.Value === 'TAK'){
                        czyNiest = true;
                    }
                  }
                });
              });
        }
        if(czyNiest){
            return true;
        }


        if(this.moduly[this.supervisor.getAktualnyMod() - 1].pozycje.length > 0){
          this.moduly[this.supervisor.getAktualnyMod() - 1].pozycje[this.moduly[this.supervisor.getAktualnyMod() - 1].pozycje.length - 1].kroki.forEach(krok => {
            krok.Controlls.forEach(c => {
              if(c.KeyName == 'Moduł'){
                wyb = c.wybrany.Value;
              }
            });
          });
        }
        var czy_mozna = true;
        if(wyb === ''){
            czy_mozna = true;
        } else if(wyb === 'P1' || wyb === 'DP1' || wyb === 'D11'){
          czy_mozna = false;
        } else if(wyb === '11'){
          if(this.moduly[this.supervisor.getAktualnyMod() - 1].moduleNg.kroki.length > 0){
            this.moduly[this.supervisor.getAktualnyMod() - 1].moduleNg.kroki.forEach(krok => {
              krok.Controlls.forEach(c => {
                if(c.KeyName == 'CzyModuł'){
                  if(!(c.wybrany.Value === 'TAK11')){
                    czy_mozna = false;
                  }
                }
              });
            });
          }
        }
        
       return czy_mozna;
        
      }

    NextExist(){
        return this.AktualneKroki().czyJestNext();
    }
    nextAutoEnd(tresci, formService:FormV2ServiceService, czyscroll = true, dialog: MatDialog, k:KrokStep){
        console.log("end", this.supervisor.aktualnyTyp);
        if(k != null && k.Step == ''){
            this.supervisor.wczytywanieAuto = false;
        }
        if(this.supervisor.aktualnyTyp == TypEnum.Header){
            //this.supervisor.aktualny_mod = 1;
            //this.supervisor.aktualna_poz = 0;
            if(k != null){
                k.Module = this.supervisor.getAktualnyMod();
                k.Position = this.supervisor.getAktualnaPoz();
                
                    if(k.Type == TypEnum.ModuleHeader){
                        k.Module = 1;
                        k.Position = 0;
                        if(this.moduly[k.Module.valueOf() - 1].moduleNg.kroki.length == 0){
                            this.ustawKrok(k);
                            this.nextAuto(tresci, formService, czyscroll, dialog);
                        } else {
                            this.supervisor.SetAktualnyTyp(TypEnum.ModuleHeader);
                            this.supervisor.aktualny_mod = 1;
                            this.supervisor.wczytywanieAuto = false;
                            
                        }
                    } else if(k.Type == TypEnum.Position){
                        k.Module = 1;
                        k.Position = 1;
                        if(this.moduly[k.Module.valueOf() - 1].pozycje[k.Position.valueOf() - 1].kroki.length == 0){
                            this.ustawKrok(k);
                            this.nextAuto(tresci, formService, czyscroll, dialog);
                        } else {
                            this.supervisor.SetAktualnyTyp(TypEnum.Position);
                            this.supervisor.aktualny_mod = 1;
                            this.supervisor.wczytywanieAuto = false;
                        }
                        
                    }
            } else {
               
                this.supervisor.aktualny_mod = 1;
                this.supervisor.aktualna_poz = 0;
                this.supervisor.aktualnyTyp = TypEnum.ModuleHeader;
                this.nextAuto(tresci, formService, czyscroll, dialog);
            }
            
            

            
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleHeader){ 
            if(k != null && k.Type == TypEnum.Position){
                k.Module = this.supervisor.getAktualnyMod();
                k.Position = 1;
                //// console.log("kroki 1", this.moduly[k.Module.valueOf() - 1].pozycje[k.Position.valueOf() - 1]);
                if(this.moduly[k.Module.valueOf() - 1].pozycje[k.Position.valueOf() - 1].kroki.length == 0){
                    
                    this.ustawKrok(k);
                    this.nextAuto(tresci, formService, czyscroll, dialog);
                } else {

                }
                
            } else {
                this.EdytujPozycje(tresci, formService, true, this.supervisor.getAktualnyMod(), 1,dialog);
                this.nextAuto(tresci, formService, czyscroll, dialog);
            }
        } else if(this.supervisor.aktualnyTyp == TypEnum.Position){ 
            console.log("xxx");
            if(this.moduly[this.supervisor.aktualny_mod - 1].pozycje.length > this.supervisor.getAktualnaPoz()){
                 
                console.log("następna pozycja", this.supervisor.getAktualnyMod() - 1, this.supervisor.getAktualnaPoz());
                this.EdytujPozycje(tresci, formService, true, this.supervisor.getAktualnyMod() - 1, this.supervisor.getAktualnaPoz(),dialog); 
                // this.nextAuto(tresci, formService, czyscroll, dialog);
            } else {
                console.log("zakoncz_poz", this.supervisor.getAktualnyMod() - 1, this.supervisor.getAktualnaPoz());
                this.supervisor.ZakonczPozycje(this.supervisor.getAktualnaPoz());
                setTimeout(()=> {
                    // console.log("tworze stopkę modułu", this.czyWielomodOK(), this.czyMoznaDodacPozycje());
                
                    if(this.moduly[this.supervisor.getAktualnyMod() - 1].CzyPozPoprawny() && (!this.czyMoznaDodacPozycje() || this.czyZakonczJakMozeszDodac()) && this.czyWielomodOK()){
                        this.moduly[this.supervisor.getAktualnyMod() - 1].zakonczono_pozycje = true; 
                         //this.moveToModuleFooter(this.supervisor.getAktualnyMod() - 1, formService, tresci,dialog);

                         if(this.moduly[this.supervisor.getAktualnyMod() - 1].moduleFooter.kroki.length == 0 && this.IsModuleFooter){
                            
                                this.supervisor.aktualnyTyp = TypEnum.ModuleFooter;
                                this.supervisor.aktualna_poz = 0;
                                this.WczytajModuleFooter(this.supervisor.getAktualnyMod() - 1, formService, tresci, dialog);
                            
                            
                        } else {
                
                            if(!this.IsModuleFooter){
                                this.moduly[this.supervisor.getAktualnyMod() - 1].moduleFooter.poprawnie_zakonczone = true;
                                this.moduly[this.supervisor.getAktualnyMod() - 1].moduleFooter.edytowane = false; 
                            }
                            if(this.moduly[this.supervisor.getAktualnyMod() - 1].CzyFtPoprawny()){
                                if(this.moduly.length > this.supervisor.getAktualnyMod()){
                                    // console.log("wczytaj następny moduł");
                                    this.supervisor.aktualny_mod++;
                                    this.supervisor.aktualna_poz= 0;
                                    this.supervisor.aktualnyTyp = TypEnum.ModuleHeader;
                                    //this.next(tresci, formService, TypEnum.ModuleHeader, true, dialog);
                                    if(this.moduly[this.supervisor.aktualny_mod - 1].moduleNg.kroki.length == 0){
                                        this.wczytajKrok(formService, true, tresci, this.naglowek.GetLastStep().Step, dialog); 
                                    } else {
                                        this.next(tresci,formService,this.supervisor.aktualnyTyp,czyscroll,dialog);
                                    }
                                   
                                } else {
                                    // console.log("wczytaj stopke");
                                    if(!this.CzyPoprawnyAdres()){
                                        this.supervisor.zakladka = 0; 
                                    }
                                    
                                    this.ZmienZakladke(2, formService,tresci, dialog);
                                    
                                    
                                    
                                }
                            } else {
                                // console.log("niepoprawna stopka modułu");
                            }
                        }

                    } else {
                        console.log("nie mozna dodac stopki modułu");
                        this.supervisor.auto = 0;
                        this.supervisor.wczytywanieAuto = false;
                        this.moduly[this.supervisor.getAktualnyMod() - 1].zakonczono_pozycje = false; 
                    }
                }, 100);
                
               
            }
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleFooter){ 
            setTimeout(()=> {
                // console.log("stopka mod", this.moduly[this.supervisor.getAktualnyMod() - 1].CzyFtPoprawny());
                if(this.moduly[this.supervisor.getAktualnyMod() - 1].CzyFtPoprawny()){
                    if(this.moduly.length > this.supervisor.getAktualnyMod()){
                        // console.log("wczytaj następny moduł");
                        this.supervisor.aktualny_mod++;
                        this.supervisor.aktualna_poz= 0;
                        this.supervisor.aktualnyTyp = TypEnum.ModuleHeader;
                        //this.next(tresci, formService, TypEnum.ModuleHeader, true, dialog);
                        if(this.moduly[this.supervisor.aktualny_mod - 1].moduleNg.kroki.length == 0){
                            this.wczytajKrok(formService, true, tresci, this.naglowek.GetLastStep().Step, dialog); 
                        } else {
                            // this.next(tresci,formService,this.supervisor.aktualnyTyp,czyscroll,dialog);
                        }
                        
                    } else {
                        // console.log("wczytaj stopke"); 
                        if(!this.CzyPoprawnyAdres()){
                            this.supervisor.zakladka = 0; 
                        }
                        
                        this.ZmienZakladke(2, formService,tresci, dialog);
                    }
                } else {
                    // console.log("niepoprawna stopka modułu");
                }
            }, 100);

                
        }


       /* if(this.supervisor.aktualnyTyp == TypEnum.ModuleHeader || this.supervisor.aktualnyTyp == TypEnum.Header ){
            this.supervisor.nastepnyTyp();
            this.supervisor.aktualny_mod = 1;
            this.supervisor.aktualna_poz = 0;
            this.AktualneKroki().akualnyNumer = 0;
            this.supervisor.EdytujPozycje(this.supervisor.aktualna_poz);
            if(this.AktualneKroki().kroki.length == 0){
                this.wczytajKrok(formService, true, tresci, this.moduly[this.supervisor.aktualny_mod - 1].moduleNg.GetLastStep().Step, dialog); 
            } 
            
        } else if(this.supervisor.aktualnyTyp == TypEnum.Position){
            this.next(tresci, formService, TypEnum.Position, true, dialog);
        } */
        // this.next(tresci, formService, this.AktualneKroki().GetLastStep().Type, true, dialog);
        //
    }
    nextAuto(tresci, formService:FormV2ServiceService, czyscroll = true, dialog: MatDialog){
        //// console.log("mid", this.supervisor.aktualna_poz, this.supervisor.aktualny_mod);
        this.next(tresci, formService, this.AktualneKroki().GetLastStep().Type, true, dialog);
        try {
        } catch (Exception){
            // console.log("błąd przy wczytywaniu automatycznym");
        }
       
    }
    next(tresci, formService:FormV2ServiceService, typ, czyscroll = true, dialog: MatDialog){
        if(this.supervisor.bez_zmian && this.AktualneKroki().czyPoprawny()){
            this.supervisor.bez_zmian = false;
            this.AktualneKroki().edytowane = false;
            this.supervisor.aktualnyTyp = this.supervisor.prev_type;
            this.supervisor.aktualny_mod = this.supervisor.prev_mod;
            this.supervisor.aktualna_poz = this.supervisor.prev_poz;
            this.AktualneKroki().edytowane = this.supervisor.prev_edit;
            this.AktualneKroki().akualnyNumer = this.supervisor.prev_numer;

        } else {
            if(this.supervisor.auto.valueOf() == 2){
                this.supervisor.wczytywanieAuto = true;
            }
            
            // console.log("dialog3", dialog);
            this.supervisor.aktualnyTyp = typ;
            if(this.supervisor.aktualnyTyp == TypEnum.Adres){
                // console.log("del", this.delivery);
                if(!this.delivery.complaint_check || this.delivery.ChosenComplaint != null){
                     // console.log("d2", this.delivery);
                    if(this.czyPoprawneDelivery()){
                        // console.log("xxx");
                        this.delivery.AdressEdit = false;
                        this.supervisor.nastepnyTyp();
                    } else {
                        this.supervisor.wczytywanieAuto = false;
                    }
                } else {
                    this.supervisor.wczytywanieAuto = false; 
                }
            }
            
    
            if(this.NextExist()){
                console.log("pop2", this.AktualneKroki().czyPoprawny());
                // this.supervisor.auto = false;
                if(this.AktualneKroki().czyPoprawny()){
                    this.AktualneKroki().nextWithoutInc();
    
    
                    if(!this.AktualneKroki().kroki[this.AktualneKroki().akualnyNumer].czyWidoczny()){
                        this.next(tresci, formService, typ, czyscroll, dialog);
                    } else {
                        if(this.AktualneKroki().kroki[this.AktualneKroki().akualnyNumer].Position.valueOf() > 0) {
                            if(this.supervisor.auto.valueOf() != 2){
                                tresci.scrollToPozycja('#krok_' + this.AktualneKroki().kroki[this.AktualneKroki().akualnyNumer].Step);
                            }
                           
                        } else {
                            if(this.supervisor.auto.valueOf() != 2){
                                tresci.scrollTo('#krok_' + this.AktualneKroki().kroki[this.AktualneKroki().akualnyNumer].Step);
                            }
                            
                        }
                        if(this.supervisor.auto.valueOf() > 0){
                            this.nextAuto(tresci, formService,true,dialog);
                        }
                    }
                } else {
                    this.supervisor.wczytywanieAuto = false;
                }
                
            } else if (this.AktualneKroki().poprawnie_zakonczone){
                if(this.AktualneKroki().czyPoprawny()){
                    console.log("zakonczone ", this.supervisor.aktualnyTyp);
                    this.AktualneKroki().edytowane = false; 
                    if(this.supervisor.auto.valueOf() > 0){
                        this.nextAutoEnd(tresci, formService,true,dialog, null);
                    } else {
                        this.supervisor.nastepnyTyp();
                    }
                }
               
                
            } else {
                if(this.supervisor.auto.valueOf() == 1){
                    this.CzyAuto(formService, tresci, dialog);
                }
                if(this.AktualneKroki().czyPoprawny()){
                    if(this.naglowek.kroki.length == 0) {
                        this.wczytajKrok(formService, false, tresci, null, dialog); 
                    } else {
                        this.wczytajKrok(formService, true, tresci, null, dialog);  
                    }
                    
                } else {
                        this.supervisor.wczytywanieAuto = false;
                        this.CzyAuto(formService, tresci, dialog);
                    
                     // console.log("nie uzupełnone dane");
                }
                
            }
        }

        


        return false;
        
    }
    public CzyAutoSkip(){
        if(this.supervisor.aktualnyTyp == TypEnum.Header) {
            if(this.naglowek.max_p > this.naglowek.akualnyNumer || this.moduly.length > 0){
               // this.supervisor.auto = true;
                return true;
            } else {
                this.supervisor.skip = false;
                return false;
            }
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleHeader) {
            if(this.AktualneKroki().max_p > this.AktualneKroki().akualnyNumer ||   this.moduly[this.supervisor.aktualny_mod - 1].pozycje.length > 0){
                //this.supervisor.auto = true; 
                return true;
            } else {
                this.supervisor.skip = false;
                return false;
            }
        } else if(this.supervisor.aktualnyTyp == TypEnum.Position) {
            //var t = this.AktualneKroki();  
            if(this.supervisor.getAktualnaPoz() == 0 || this.supervisor.getAktualnaPoz() > this.moduly[this.supervisor.aktualny_mod - 1].pozycje.length){
                return true;
            } else {
                if(this.AktualneKroki().max_p >  this.AktualneKroki().akualnyNumer || this.moduly.length > this.supervisor.aktualny_mod){
                    //this.supervisor.auto = true;
                    return true;
                } else {
                    this.supervisor.skip = false;
                    return false;
                }
            }
                
            
            
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleFooter) {
            if(this.AktualneKroki().max_p  >  this.AktualneKroki().akualnyNumer || this.moduly.length > this.supervisor.aktualny_mod){
                //this.supervisor.auto = true;
                return true;
            } else {
                this.supervisor.skip = false;
                return false;
            }
        } else if(this.supervisor.aktualnyTyp == TypEnum.Footer) {
            if(this.AktualneKroki().max_p  >  this.AktualneKroki().akualnyNumer){
                //this.supervisor.auto = true;
                return true;
            } else {
                this.supervisor.skip = false;
                return false;
            }
        }
        return false;
    }
    public CzyAuto(formService, tresci, dialog){
        if(this.supervisor.aktualnyTyp == TypEnum.Header) {
            if(this.naglowek.max_p > this.naglowek.akualnyNumer || this.moduly.length > 0){
               // this.supervisor.auto = true;
            } else {
                console.log("bez auto 1");
                this.supervisor.auto = 0;
                this.supervisor.wczytywanieAuto = false;
            }
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleHeader) {
            if(this.AktualneKroki().max_p > this.AktualneKroki().akualnyNumer ||   this.moduly[this.supervisor.aktualny_mod - 1].pozycje.length > 0){
                //this.supervisor.auto = true; 
            } else {
                console.log("bez auto 2");
                this.supervisor.auto = 0;
                this.supervisor.wczytywanieAuto = false;
            }
        } else if(this.supervisor.aktualnyTyp == TypEnum.Position) {
            var t = this.AktualneKroki();  
            
            console.log(this.AktualneKroki().max_p, this.AktualneKroki().akualnyNumer, this.moduly.length, this.supervisor.aktualny_mod, this.moduly[this.supervisor.aktualny_mod - 1].pozycje.length , this.supervisor.aktualna_poz);
            if(this.AktualneKroki().max_p > this.AktualneKroki().akualnyNumer || this.moduly.length > this.supervisor.aktualny_mod || this.moduly[this.supervisor.aktualny_mod - 1].pozycje.length > this.supervisor.aktualna_poz){
                //this.supervisor.auto = true;
            } else {
                if(this.IsModuleFooter){

                } else if(this.IsFooter) {
                    // this.ZmienZakladke(2, formService, tresci, dialog); 
                } else {
                    console.log("bez auto 3");
                    this.supervisor.auto = 0;
                    this.supervisor.wczytywanieAuto = false;
                }
                
            }
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleFooter) {
            if(this.AktualneKroki().max_p  > this.AktualneKroki().akualnyNumer || this.moduly.length > this.supervisor.aktualny_mod){
                //this.supervisor.auto = true;
            } else {
                if(this.IsFooter) {
                    // this.ZmienZakladke(2, formService, tresci, dialog);
                } else {
                    console.log("bez auto 4");
                    this.supervisor.auto = 0;
                    this.supervisor.wczytywanieAuto = false;
                }
                
            }
        } else if(this.supervisor.aktualnyTyp == TypEnum.Footer) {
            if(this.AktualneKroki().max_p  > this.AktualneKroki().akualnyNumer){
                //this.supervisor.auto = true;
            } else {
                console.log("bez auto 5");
                this.supervisor.auto = 0;
                this.supervisor.wczytywanieAuto = false;
            }
        }
    }
    public CzyWczesniejszeUzupelnione(typ:string){ //nie używane
        if(typ == this.supervisor.aktualnyTyp){
            return true;
        } else if(this.supervisor.aktualnyTyp == TypEnum.Header){
            return false;
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleHeader){
            if(typ == TypEnum.Header){
                return true;
            }
            return false;
        } else if(this.supervisor.aktualnyTyp == TypEnum.Position){
            if(typ == TypEnum.Header || typ == TypEnum.ModuleHeader){
                return true;
            }
            return false;
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleFooter){
            if(typ == TypEnum.Header || typ == TypEnum.Position || typ == TypEnum.ModuleHeader){
                return true;
            }
            return false;
        } else if(this.supervisor.aktualnyTyp == TypEnum.Footer){
            if(typ == TypEnum.Header || typ == TypEnum.Position || typ == TypEnum.ModuleHeader || typ == TypEnum.ModuleFooter){
                return true;
            }
            return false;
        }
    }
    ustawStr(){
        /*
        if(this.kroki[this.aktualnyStep].Type == "Header"){
            this.zakladka = 0;
        } else if(this.kroki[this.aktualnyStep].Type == "Position"){
            this.zakladka = 1;
        } else if(this.kroki[this.aktualnyStep].Type == "ModuleHeader"){
            this.zakladka = 1;
        } else if(this.kroki[this.aktualnyStep].Type == "ModuleFooter"){
            this.zakladka = 1;
        } else if(this.kroki[this.aktualnyStep].Type == "Footer"){
            this.zakladka = 2;
        }*/
    }
    goToLast(tresci){
       /* while(this.kroki.length > this.aktualnyStep + 1){
            if(!this.next(tresci, false)){
                // console.log("next");
                //// console.log(this.kroki[this.aktualnyStep].Step, this.kroki);
                tresci.scrollTo("#krok_" + this.kroki[this.aktualnyStep].Step);
                break;
            } 
        } */
        
    }
    active(GrupaKrokow:GrupaKrokow, numer, module, position, typ){
        if(numer == -1){
            //this.delivery.czyAdres = true;
            // console.log('aaaa');
            this.delivery.AdressEdit = true;
            this.supervisor.SetAktualnyTyp(TypEnum.Adres);
            console.log("bez auto adres");
             this.supervisor.auto = 0;
            //this.supervisor.wczytywanieAuto = false;
        } else {
            // this.supervisor.auto = true;
            this.supervisor.prev_edit = this.AktualneKroki().edytowane;
            this.supervisor.prev_type = this.supervisor.aktualnyTyp;
            this.supervisor.prev_mod = this.supervisor.aktualny_mod;
            this.supervisor.prev_poz = this.supervisor.aktualna_poz;
            this.supervisor.prev_numer = this.AktualneKroki().akualnyNumer; 
            if(typ != TypEnum.Footer){
                this.supervisor.bez_zmian = true;
            }
            
            


            this.supervisor.SetAktualnyTyp(typ); 
            this.supervisor.aktualny_mod = module;
            this.supervisor.aktualna_poz = position;
            this.AktualneKroki().akualnyNumer = numer; 
            this.AktualneKroki().edytowane = true;
            console.log("bez auto active");
            this.supervisor.auto = 0;


           //this.supervisor.wczytywanieAuto = false;
        }
       
        /*this.aktualnyStep = numer;*/
    }
    czyPoprawneDelivery(){
            // console.log("delivery", this.delivery);
            if(this.delivery.DeliveryMethod.Id != 0){
                if(!this.delivery.DeliveryMethod.IsDeliveryAddressRequired || (this.delivery.ChosenAdresses != null && this.delivery.ChosenAdresses.Id != 0)){
                    return true;
                } 
            } 
        return false;
    }
 
    
    public ZmienZakladke(numer, formService, tresci, dialog){
        // console.log("zak", this);
        if(numer == 1){
            if(this.supervisor.getAktualnyMod() == 0 || this.supervisor.getAktualnyMod() > this.moduly.length){
                
                    this.supervisor.aktualny_mod = this.moduly.length;
               
            }
        }
        if(!this.supervisor.edycja_pozycji){
            if(numer == 2){
                this.supervisor.aktualny_mod = 0;
                this.supervisor.aktualna_poz = 0;
                this.supervisor.SetAktualnyTyp(TypEnum.Footer);

                if(this.stopka == null || this.stopka.kroki.length == 0){
                    this.WczytajFooter(0, formService, tresci, dialog);
                } 
                // console.log("isfother123", this.IsFooter);
                    if(this.IsFooter){
                        this.supervisor.zakladka = 2;
                    }
                    
                

            } else if (numer == 0){
                this.supervisor.aktualny_mod = 0;
                this.supervisor.aktualna_poz = 0;
                this.supervisor.SetAktualnyTyp(TypEnum.Header);
                this.supervisor.zakladka = numer;
            } else {
                this.supervisor.zakladka = numer;
            }
                
          
            
        } else {
          
                this.supervisor.zakladka = 1;
            
        }
        
    }
    public AktualneKroki():GrupaKrokow {
        if(this.supervisor.aktualnyTyp == TypEnum.Header ){
            return this.naglowek;
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleHeader){
            return this.moduly[this.supervisor.getAktualnyMod() - 1].moduleNg;
        } else if(this.supervisor.aktualnyTyp == TypEnum.Position){
            return this.moduly[this.supervisor.getAktualnyMod() - 1].pozycje[this.supervisor.getAktualnaPoz() - 1];
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleFooter){
            return this.moduly[this.supervisor.getAktualnyMod() - 1].moduleFooter;
        } else if(this.supervisor.aktualnyTyp == TypEnum.Footer){
            return this.stopka;
        }  else  {
            return new GrupaKrokow('');
        }

    }

    public CzyJestStep(numer, krok:KrokStep){
        var widoczny = false;
       // console.log(this.AktualneKroki(), numer);
        if(this.AktualneKroki().kroki.length > numer){
          /*  krok.Controlls.forEach(c => {
                if(c.ControlType != 'Hidden' && c.ControlType != 'NewLine') {
                  widoczny = true;
                }
              });*/
        } else {
            return  false;
        }
        
        return widoczny;
      }

      public zapiszParam2(p:Parameter){
        
        var byl:boolean = false;
        this.Parameters.forEach(param => {
            if(param.Key == p.Key && param.Module == p.Module && param.Position == p.Position){
                param = p;
                byl = true;
            }
        });
        if(!byl){
            this.Parameters.push(p);
        }
      }
      public zapiszParam(control:Control){
        // console.log("params", this.Parameters, control);
        control.wybrany.Key = control.KeyName;
        var byl:boolean = false;
        this.Parameters.forEach(param => {
            if(param.Key == control.KeyName && param.Module == this.supervisor.aktualny_mod && param.Position == this.supervisor.aktualna_poz){
                param.DisplayValue = control.wybrany.DisplayValue;
                param.DisplayValueCs = control.wybrany.DisplayValueCs; 
                param.DisplayValueDe = control.wybrany.DisplayValueDe;
                param.DisplayValueEn = control.wybrany.DisplayValueEn;
                param.DisplayValueFr = control.wybrany.DisplayValueFr;
                param.DisplayValuePl = control.wybrany.DisplayValuePl;
                param.Value = control.wybrany.Value;
                byl = true;
            }
        });
        if(!byl){
            var p = new Parameter();
            p.Key = control.wybrany.Key;
            p.Value = control.wybrany.Value;
            p.Module = this.supervisor.aktualny_mod;
            p.Position = this.supervisor.aktualna_poz;
            this.Parameters.push(p);
        }
            
        // console.log('param', this.Parameters);
      }
      
      public usunDalszePoz(numerPozycji:number){
         // console.log("usun dalsze poz");
         this.supervisor.bez_zmian = false;
         if(this.supervisor.auto.valueOf() == 0){
            this.supervisor.skip = true;
            this.CzyAutoSkip();
        }
        for(var i = this.moduly[this.supervisor.getAktualnyMod() - 1].pozycje.length - 1; i>=numerPozycji; i--){
            this.moduly[this.supervisor.getAktualnyMod() - 1].pozycje[i].WyczyscWszystko();
        }
        this.moduly[this.supervisor.getAktualnyMod() - 1].moduleFooter.WyczyscWszystko();
        if(this.supervisor.aktualnyTyp != TypEnum.Footer){
            this.stopka.WyczyscWszystko();
            // console.log("usuwam stopke 4");
        }
        
      }

      public usunDalsze(control:Control){
         // console.log("usun dalsze");
         this.supervisor.bez_zmian = false;
        if(this.supervisor.auto.valueOf() == 0){
            this.supervisor.skip = true;
            this.CzyAutoSkip();
        }
        this.AktualneKroki().WyczyscDalsze();
        if(this.supervisor.aktualnyTyp == TypEnum.Header){
            this.moduly.forEach(mod => {
                mod.moduleNg.WyczyscWszystko();
                mod.moduleFooter.WyczyscWszystko();
                mod.pozycje.forEach(poz => {
                    poz.WyczyscWszystko();
                });
            });
           
            this.stopka.WyczyscWszystko();
            // console.log("usuwam stopke 5");
            
            //this.stopka.WyczyscWszystko();
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleHeader){
            this.moduly[this.supervisor.getAktualnyMod() - 1].pozycje.forEach(poz => {
                poz.WyczyscWszystko();
            });
            this.moduly[this.supervisor.getAktualnyMod() - 1].moduleFooter.WyczyscWszystko();
            this.stopka.WyczyscWszystko();
            // console.log("usuwam stopke 6");
        }  else if(this.supervisor.aktualnyTyp == TypEnum.Position){
            for(var i = this.moduly[this.supervisor.getAktualnyMod() - 1].pozycje.length - 1; i>=this.supervisor.getAktualnaPoz(); i--){
                this.moduly[this.supervisor.getAktualnyMod() - 1].pozycje[i].WyczyscWszystko();
            }
            this.moduly[this.supervisor.getAktualnyMod() - 1].moduleFooter.WyczyscWszystko();
            this.stopka.WyczyscWszystko();
            // console.log("usuwam stopke 7");
        } else if(this.supervisor.aktualnyTyp == TypEnum.ModuleFooter){
            this.stopka.WyczyscWszystko();
            // console.log("usuwam stopke 8");
        }
      }
      ZmianaParametru(control:Control) {
        // console.log("zmiana", control);
       /* control.Values.forEach(v => {
            if(v.Key == control.wybrany.Value){
                control.wybrany.DisplayValue = v.DisplayValue;
                control.wybrany.DisplayValueCs = v.DisplayValueCs;
                control.wybrany.DisplayValueDe = v.DisplayValueDe;
                control.wybrany.DisplayValueEn = v.DisplayValueEn;
                control.wybrany.DisplayValueFr = v.DisplayValueFr;
                control.wybrany.DisplayValuePl = v.DisplayValuePl;
                control.wybrany.Key = v.Key;
            }
        }); */
            var param:Parameter[] = [];
            param = this.GetAllParams();

            this.zapiszParam(control);
            param.forEach(p => {
                this.zapiszParam2(p);
            });
            //this.Parameters = param;

        
        if(control.NotAffectToOtherControls == 0){
             // console.log("USUŃ DALSZE 1", control);
            this.usunDalsze(control);
        } else if(control.NotAffectToOtherControls == 2){
             // console.log("USUŃ DALSZE 2", control);
             
            this.supervisor.bez_zmian = false;
            if(this.supervisor.auto.valueOf() == 0){
                this.supervisor.skip = true;
                this.CzyAutoSkip();
            }
            
            this.AktualneKroki().WyczyscDalsze();
        } else {
            if(this.supervisor.aktualnyTyp != TypEnum.Footer){
                this.stopka.WyczyscWszystko(); 
                // console.log("usuwam stopke 9");
            }
        }
        // console.log("con", control);
        this.AktualneKroki().czyPoprawny();
        this.ZapiszParametry(param); 
      }
     
      ZapiszParametry(param:Parameter[] = null) {
    
        // console.log("zapisuje parametry", this);
        var formTMP = new FormDataV2(null);
        if(param == null){
            formTMP.Parameters = this.GetAllParams();
        } else {
            formTMP.Parameters = param;
        }
        
        formTMP.IsFooter = this.IsFooter;
        formTMP.IsModule = this.IsModule;
        formTMP.IsModuleFooter = this.IsModuleFooter;
        formTMP.IsTrader = this.IsTrader;
        formTMP.Order = this.Order;
        formTMP.delivery = this.delivery;

        formTMP.Files = this.Files;
        formTMP.ParameterExtensions = this.ParameterExtensions;


        localStorage.setItem('parametry_'+this.produktPrefix, serialize(formTMP)); 
        // console.log("zapisuje parametry", this); 
      }
      
      ZapiszParametryJSON(sanitizer) {
    
        // console.log("zapisuje parametry", this);
        var formTMP = new FormDataV2Save();

        formTMP.Parameters = this.GetAllParamsSave();
        formTMP.ParameterExtensions = [];
        if(this.ParameterExtensions == null){
            this.ParameterExtensions = [];
        }
        this.ParameterExtensions.forEach(par => {
            var p = new KimSave();
            p.Index = par.Index;
            p.Module = par.Module;
            p.Position = par.Position;
            p.Quantity = par.Quantity;
            formTMP.ParameterExtensions.push(p);
        }); 
        //formTMP.IsFooter = this.IsFooter;
        //formTMP.IsModule = this.IsModule;
        //formTMP.IsModuleFooter = this.IsModuleFooter;
       // formTMP.IsTrader = this.IsTrader;
       // formTMP.Order = this.Order;
       //// console.log("order", this.Order, this);
        formTMP.OrderSave = new Order();
        formTMP.OrderSave.DeliveryMethodId = this.delivery.DeliveryMethod.Id;
        
        if(this.delivery.ChosenAdresses != null){
            formTMP.OrderSave.DeliveryAddressId = this.delivery.ChosenAdresses.Id;
        }
        
        const data = serialize(formTMP);
        const blob = new Blob([data], { type: 'application/octet-stream' });
    
        //this.fileUrl = sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

        var downloadURL = window.URL.createObjectURL(blob);
      
        // console.log(plik);
        // window.open(downloadURL,'_blank');
  
        var fileLink = document.createElement('a');
        fileLink.href = downloadURL;
        fileLink.target = "_blank"; 
        fileLink.download = "plik"+this.produktPrefix+".json";
        fileLink.click();
  

      }
      ZapiszParametryXML(sanitizer) {
    
        // console.log("zapisuje parametry", this);
        var formTMP = new FormDataV2Save();

        formTMP.Parameters = this.GetAllParamsSave();
        formTMP.ParameterExtensions = [];
        if(this.ParameterExtensions == null){
            this.ParameterExtensions = [];
        }
        this.ParameterExtensions.forEach(par => {
            var p = new KimSave();
            p.Index = par.Index;
            p.Module = par.Module;
            p.Position = par.Position;
            p.Quantity = par.Quantity;
            formTMP.ParameterExtensions.push(p);
        }); 
        
        //formTMP.IsFooter = this.IsFooter;
        //formTMP.IsModule = this.IsModule;
        //formTMP.IsModuleFooter = this.IsModuleFooter;
       // formTMP.IsTrader = this.IsTrader;
       // formTMP.Order = this.Order;
       // console.log("order2", this.Order, this);
        // formTMP.OrderSave = this.Order;
        formTMP.OrderSave = new Order();
        formTMP.OrderSave.DeliveryMethodId = this.delivery.DeliveryMethod.Id;
        if(this.delivery.ChosenAdresses != null){
            formTMP.OrderSave.DeliveryAddressId = this.delivery.ChosenAdresses.Id;
        }
        

        var js2xmlparser = require("js2xmlparser");
        const data = js2xmlparser.parse("order", formTMP);
        //const data = serialize(formTMP);
        // console.log("data", data);
        const blob = new Blob([data], { type: 'application/octet-stream' });
    
        //this.fileUrl = sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

        var downloadURL = window.URL.createObjectURL(blob);
      
        // console.log(plik);
        // window.open(downloadURL,'_blank');
  
        var fileLink = document.createElement('a');
        fileLink.href = downloadURL;
        fileLink.target = "_blank"; 
        fileLink.download = "plik"+this.produktPrefix+".xml";
        fileLink.click();
  

      }
      WczytajParametry(json = null):Parameter[]{
        var parametry:Parameter[] = [];
        try {
            if(json == null){
                var kr:FormDataV2 =  deserialize(FormDataV2,localStorage.getItem('parametry_'+this.produktPrefix));
            } else {
                var kr:FormDataV2 =  json;
            }
          
          
         // console.log("wczytane parametry1", kr);
         parametry = kr.Parameters;
         // this.Files = kr.Files;
          /*kr.kroki.forEach(k => {
            k.Controlls.forEach(con => {
                parametry.push(con.wybrany);
              });
           
          }); */
          kr.Order = new Order();
          
          try {
            kr.Order.DeliveryMethodId = kr.delivery.DeliveryMethod.Id;
          } catch (errr){

          }
          try {
            kr.Order.DeliveryAddressId = kr.delivery.ChosenAdresses.Id;
          } catch (errr){

          }
          
          this.Order = kr.Order;
          //this.ZapiszParametry();
         // console.log("wczytane parametry", parametry);
          return parametry;
        } catch(Exception){
            // this.ZapiszParametry();
          return parametry;
        }
        
      }
      WczytajExtParametry(json = null):Kim[]{
        var parametry:Kim[] = [];
        try {
            if(json == null){
                var kr:FormDataV2 =  deserialize(FormDataV2,localStorage.getItem('parametry_'+this.produktPrefix));
            } else {
                var kr:FormDataV2 =  json;
            }
          console.log("kr", kr);
         parametry = kr.ParameterExtensions;
         
         // this.ZapiszParametry();
          return parametry;
        } catch(Exception){
            // this.ZapiszParametry();
          return parametry;
        }
        
      }
      WczytajOrder(json = null){
        // console.log("xxx");
        var Order;
        try {
            if(json == null){
                var kr:FormDataV2 =  deserialize(FormDataV2,localStorage.getItem('parametry_'+this.produktPrefix));
            } else {
                var kr:FormDataV2 =  json;
            }
            Order = kr.OrderSave;
         
          return Order;
        } catch(Exception){
            // console.log("error", Exception);
          return Order;
        }
      }
      WczytajPliki(json = null):FileControl[]{
        var pliki:FileControl[] = [];
        try {
            if(json == null){
                var kr:FormDataV2 =  deserialize(FormDataV2,localStorage.getItem('parametry_'+this.produktPrefix));
            } else {
                var kr:FormDataV2 =  json;
            }
          pliki = kr.Files;
         
          return pliki;
        } catch(Exception){
          return pliki;
        }
        
      }

  }
 


 

  


  