import { Component, OnInit } from '@angular/core';
import { TresciService } from '../../app/tresci.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../app/user.service';

@Component({
  selector: 'app-historia',
  templateUrl: './historia.component.html',
  styleUrls: ['./historia.component.scss']
})
export class HistoriaComponent implements OnInit {

  constructor(public tresci: TresciService, public user: UserService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.tresci.setJezyk(this.route.snapshot.paramMap.get('jezyk'));
    this.tresci.setFunkcja(this.route.snapshot.paramMap.get('system'));
    this.tresci.setId(this.route.snapshot.paramMap.get('id'));
  }

}
