import { Component, OnInit } from '@angular/core';
import { TranslationEnum } from '../shared/enums/translation-enum';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-strefa-klenta',
  templateUrl: './strefa-klenta.component.html',
  styleUrls: ['./strefa-klenta.component.scss']
})
export class StrefaKlentaComponent implements OnInit {

  public TranslationEnum = TranslationEnum;
  constructor(private translateService: TranslateService, private router: Router) { }

  ngOnInit(): void {
  }

  routeTo(site: string) {
    this.router.navigate([`${site}`]);
  }
}
