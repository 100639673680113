import { ModelComponentEdit, PositionModelComponent } from './component-edit.model';
import { FormGroup } from '@angular/forms';
import { HtmlControl } from './html-control.model';
import { PositionsTab } from './position.model';

export class FormSet {
  public SetId: number;
  public ModuleType: string;
  public SetControls: {ModuleType: string, HtmlControl: HtmlControl[], Step: string}[] = new Array<{ModuleType: '', HtmlControl: [], Step: string}>();
  public SetPositionsComponents: PositionModelComponent[] = new Array<PositionModelComponent>();
  public SetMainComponents: ModelComponentEdit[] = new Array<ModelComponentEdit>();
  public SetPositionsTab: PositionsTab;//[] = new Array<PositionsTab>();
  public SetForm: FormGroup;
  public CurrentStepForSet: string;
  public PreviousStepForSet: string;
  public ShowAddNewModuleForSet: boolean;
  public LastStepBeforeFooter: string;
  public LastStepBeforeModuleFooter: string;
  public VerifyModule: boolean;

  public GetModuleNr():number{
    return this.SetId + 1;
  }
}
