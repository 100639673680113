import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UniversalDialogComponent } from '../../components/universal-dialog/universal-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  public ErrorHttp: BehaviorSubject<string>;
  public ErrorLogin: BehaviorSubject<string>;

  public DialogRef: MatDialogRef<UniversalDialogComponent, any>;

  constructor(private cookieService: CookieService, public dialog: MatDialog, private authorizationService: AuthorizationService,
    private router: Router) {
    this.ErrorHttp = new BehaviorSubject<string>(null);
    this.ErrorLogin = new BehaviorSubject<string>(null);
  }


  open401Dialog(data: DialogData) {
    this.DialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    this.DialogRef.afterClosed().pipe(/*take(1)*/).subscribe(result => {
      localStorage.clear();
      sessionStorage.clear();
      this.cookieService.delete('_token');
      this.cookieService.delete('_usi');
      this.cookieService.deleteAll();
      this.authorizationService.User.next(null);
      this.router.navigate([`Login`]);
      this.DialogRef = undefined;
    });
  }



}
