import { KrokStep } from "./StepKrok";

export class GrupaKrokow {
    
    kroki:KrokStep[] = [];
    akualnyNumer: number = 0;
    edytowane:boolean = true;
    poprawnie_zakonczone:boolean = false;

    max_p = 0;

    constructor(public typ: string){
        
    }
    GetLastStep():KrokStep {
        return this.kroki[this.kroki.length - 1];
    }

    public GetValue(nazwa:string){
        var wartosc = 'X';
        this.kroki.forEach(k => {
            k.Controlls.forEach(c => {
                if(c.KeyName == nazwa){
                    // console.log("control", c.KeyName, c);
                    if(c.ControlType == "Number" || c.ControlType == "Hidden" || c.ControlType == "Label"){
                        wartosc = c.wybrany.Value;
                    } else {
                        if(c.wybrany.DisplayValue == null || c.wybrany.DisplayValue == ''){
                            wartosc = c.wybrany.Value;
                        } else {
                            wartosc = c.wybrany.DisplayValue;
                        }
                    }
                    
                    
                }
            });
        });

        return wartosc;
    }
    public WyczyscDalsze() {
        // console.log(this.kroki, this.akualnyNumer, this.kroki.length);
        if(this.akualnyNumer + 1 < this.kroki.length){
            this.kroki = this.kroki.slice(0, this.akualnyNumer + 1);
        }
        this.poprawnie_zakonczone = false;
        // console.log(this.kroki, this.akualnyNumer, this.kroki.length);
    }
    public WyczyscWszystko() {
        // console.log(this.kroki, this.akualnyNumer, this.kroki.length);
        this.kroki = [];
        
        this.poprawnie_zakonczone = false;
        this.akualnyNumer = 0;
        this.edytowane = true;
        // console.log(this.kroki, this.akualnyNumer, this.kroki.length);
    }
    public nextWithoutInc(){
        this.akualnyNumer++;
        
    }
    public dodajKrok(krok:KrokStep){
        this.kroki.push(krok);

        this.akualnyNumer = this.kroki.length - 1;
        this.SetMax(this.akualnyNumer);
        //if(this.akualnyNumer >= this.kroki.length){
        //    this.akualnyNumer = this.kroki.length;
        //}
    }
    public SetMax(numer){
        if(this.max_p < numer){
            this.max_p = numer;
        }
    }
    public czyJestNext(){
        if(this.akualnyNumer + 1 < this.kroki.length){
            return true;
        }
        return false;
    }
    public aktualizujNumer(){
        if(this.akualnyNumer > this.kroki.length){
            this.akualnyNumer = this.kroki.length;
        }
    }
    public CzyZakonczony(){
        if(this.poprawnie_zakonczone && !this.edytowane){
            return true;
        } else {
            return false;
        }
    }
    public CzyAktywny(numer:number){
        if(numer == this.akualnyNumer && this.edytowane){
            return true;
        } else {
            return false;
        }
    }
    public CzyWidocznyStep(numer:number){
        if(numer <= this.akualnyNumer){
            return true;
        } else {
            return false;
        }
    }
    
    public next(){ 
        if(this.czyPoprawne(this.akualnyNumer)){
            if(this.kroki.length > this.akualnyNumer){
                this.akualnyNumer++;
                while(this.kroki.length > this.akualnyNumer && !this.kroki[this.akualnyNumer].czyWidoczny()){
                    this.akualnyNumer++;
                }
            }
            if(this.kroki.length == this.akualnyNumer){
                // this.stepSheet.nastepnyTyp();  
                return true;
            }
        }
        return false;
    }
    public czyZawieraKomunikat(){
        this.kroki[this.akualnyNumer].Controlls.forEach(k => {
            if(k.ControlType == "Komunikat"){
                return k.KeyName;
            }
        });
        return "";
    }

    public czyPoprawny(){
        if(this.kroki.length == 0){
            return true;
        }
        if(this.czyPoprawne(this.akualnyNumer)){
            return true;
        }
        return false;
    }
    public czyPoprawne(numer){
        
        if(numer < this.kroki.length){
            
            return this.kroki[numer].czyPoprawne();
        } else {
            return false;
        }
        
        return false;
    }
    /*public active(numer:number) {
        this.akualnyNumer = numer;
        this.edytowane = true;
        // this.stepSheet.aktualnyTyp = this.typ;
    }*/
}