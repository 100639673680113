<div *ngIf="Control" [formGroup]="Group"  class="mt-2">
  <input #input [formControlName]="Control.name" [value-object]="Control" type="text" class="d-none">
  <button [disabled]="input.disabled" mat-flat-button  type="button" color="accent" id="label_{{Control.label}}" class="btn-sm float-right"
    [translation]="TranslationEnum.TranslationForm" (click)="openImgDialog()">
    {{('label_'+ Control.label)|translate}}
  </button>
</div>

<ng-template #imgDialog>
  <div mat-dialog-content>
    <img [src]="src">
  </div>
</ng-template>
