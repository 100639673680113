import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { concatMap, takeWhile } from 'rxjs/operators';
import { DeliveryMethod } from './../../shared/models/form/delivery-method.model';
import { DeliveryAddress } from './../../shared/models/form/delivery-address.model';
import { TranslationEnum } from './../../shared/enums/translation-enum';
import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { FormService } from 'src/app/shared/service/form.service';
import { FormControl, Validators } from '@angular/forms';
import { ErrorState } from 'src/app/shared/validations/error-class';
import { TranslateService } from '@ngx-translate/core';
import { DataSharingService } from 'src/app/shared/service/data-sharing.service';
import { Subscription } from 'rxjs';
import { Order } from 'src/app/shared/models/orders/order.model';
import { Complaint } from 'src/app/shared/models/reklamacje/Complaint.model';
import { PermissionEnum } from 'src/app/shared/enums/permission-enum';
import { MatSort } from '@angular/material/sort';
import { AddAdressComponent } from 'src/app/form-v2/form-delivery/add-adress/add-adress.component';
import { MatDialog } from '@angular/material/dialog';
import { Contractor } from 'src/app/shared/models/user/contractor.model';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit, OnDestroy {

  @Input() Preview: boolean;
  reklamacja_check:boolean = false;
  TranslationEnum = TranslationEnum;

  matcher = new ErrorState();
  IsEdit = false;

  DeliveryMethod: DeliveryMethod = new DeliveryMethod();
  DeliveryMethods: DeliveryMethod[] = [];
  
  
  deliveryAddressControl = new FormControl({ value: '', disabled: this.IsEdit }, [
    Validators.required
  ]);
  complaintsControl = new FormControl({ value: '', disabled: this.IsEdit }, [
    Validators.required
  ]);
  DeliveryAddresses: DeliveryAddress[] = [];
  complaints: Complaint[] = [];

  public FileInfoId: string;
  private subscriptions: Subscription[] = [];
  CreateComplaintOrder: boolean;
  contractor: Contractor;


  constructor(private formService: FormService, private _changeDetectorRef: ChangeDetectorRef, public authService: AuthorizationService, public translateService: TranslateService,
    private dataSharingService: DataSharingService, private authorizationService: AuthorizationService,public dialog: MatDialog) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= []; 
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  public ZmienDelivery(numer){
    // console.log("dm",this.FormData.delivery.DeliveryMethods, numer);
   
    if(numer == 1 && this.deliveryAddressControl.value.OtherTransportThenSelt ){
      this.deliveryAddressControl.setValue('');
    }
  }
  otworzAdres(){

    const data = {
      ContractorAddresses: this.DeliveryAddresses
    };

    var dialogRef = this.dialog.open(AddAdressComponent, {
      maxWidth: Math.round(window.innerWidth * 0.6569),
      // maxHeight: Math.round(window.innerHeight * 0.9595),
      width: '100%',
      // height: '100%',
      disableClose: true,
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.formService.getDeliveryAddresses().
      subscribe((data) => {

        this.DeliveryAddresses = data.ContractorAddresses;
        
        
      })
    });
  }
  ngOnInit(): void {
    this.contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
    // console.log("cont", this.contractor); 
    this.FileInfoId = (window as any)['site'] + '_' + (window as any)['culture'];
    this.subscriptions.push(this.formService.getDeliveryAddresses().pipe(concatMap(x => {
      this.DeliveryMethods = x.DeliveryMethods;
      this.DeliveryAddresses = x.ContractorAddresses;
      if (this.formService.DeliveryMethod === null) {
        if (this.DeliveryAddresses.length > 0) {
          this.DeliveryMethod = this.DeliveryMethods.find(y => y.Id === 3);
        } else {
          this.DeliveryMethod = this.DeliveryMethods.find(y => y.Id === 2);
        }
        this.deliveryAddressControl.setValue(this.DeliveryAddresses.find(y => y.Default));
      }
      return this.formService.$DeliveryAddress.asObservable();
    })).subscribe(
      (address) => {
        if (address !== null && this.DeliveryMethods.length > 0) {
         this.DeliveryMethod = this.DeliveryMethods.find( dm => dm.Name === address.MethodName);
         this.deliveryAddressControl.setValue(this.DeliveryAddresses.find(y => y.Id === address.DeliveryAdressId));
          this._changeDetectorRef.detectChanges();
          if(this.reklamacja_check){
            this.getComplaints();
          }
          
          this.submit();
        }
      }
    ));
    var CreateCon = this.authorizationService.hasPermission(PermissionEnum.CreateComplaintOrder);
    // console.log('CreateComplaintOrder', CreateCon);
    if(CreateCon != null){
      this.CreateComplaintOrder = true;
    } else {
      this.CreateComplaintOrder = false;
    }
    // console.log('CreateComplaintOrder', this.CreateComplaintOrder);
   
  }
  zmien_check(event){
    // console.log(event, this.reklamacja_check);
    if(this.reklamacja_check && this.complaints.length == 0) {
      this.getComplaints();
    }
    
  }
  getComplaints(){
   
    this.subscriptions.push(this.formService.GetComplaintActiveFrom().subscribe(
      (complaints) => {
        // console.log(complaints);
        this.complaints = complaints;
        if(this.complaints.length == 0){
          /*var x = new Complaint();
          x.ComplaintNumber = "1/XX/XXXXX";
          x.Id  = 123;
          x.B2BNumber = "B2B/test/123456789";
          x.ProductionNumber = "MA1024TEST";  
          this.complaints.push(x);*/
        }
        this.setComplaint();
      }
    ));
    
    
  }
  setComplaint(){
    //this.complaints.forEach(c => {
      if(this.formService.Complaint != null && this.formService.Complaint.ComplaintId != null){ // && c.ComplaintId == this.formService.Complaint.ComplaintId){
        this.reklamacja_check = true;
        this.complaintsControl.setValue(this.complaints.find(y => y.Id === this.formService.Complaint.ComplaintId));
      }
    //});
  }

  submit() {
    const deliveryMethodError = (this.translateService.currentLang === 'pl' && !this.authService.User.value.IsTrader && this.DeliveryMethod.Id === 4);
    if (deliveryMethodError) {
      this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
    }
    if ((!this.DeliveryMethod.IsDeliveryAddressRequired || !this.deliveryAddressControl.hasError('required')) && !deliveryMethodError && (!this.CreateComplaintOrder || (!this.reklamacja_check || !this.complaintsControl.hasError('required')))) {
      this.IsEdit = true;
      this.deliveryAddressControl.disable();
      this.complaintsControl.disable();
      this.formService.IsDelivery = true;
      this.formService.DeliveryMethod = this.DeliveryMethod;
      this.formService.DeliveryAddress = this.deliveryAddressControl.value;
      if(this.reklamacja_check){
        this.formService.Complaint = this.complaintsControl.value;
        // console.log('1', this.formService.Complaint);
      } else {
        this.formService.Complaint = null;
      }
      
    } else {
      // console.log('coś nie tak', this.DeliveryMethod.IsDeliveryAddressRequired, this.deliveryAddressControl.hasError('required'), deliveryMethodError, this.reklamacja_check, this.complaintsControl.hasError('required'));
    }
        

  }

  edit() {
    this.IsEdit = false;
    this.deliveryAddressControl.enable();
    this.complaintsControl.enable();
    this.formService.DeliveryMethod = null;
    this.formService.DeliveryAddress = null;
    this.formService.Complaint = null;
  }

}



