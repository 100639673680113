import { DataSharingService } from 'src/app/shared/service/data-sharing.service';
import { Control } from './../models/form/control.model';

import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Kim } from '../models/form-model/kim.model';
import { HtmlControl } from '../models/form-model/models/html-control.model';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[value-object]'
})


export class ValueObjectDirective implements AfterViewInit {
  @Input('value-object') control: HtmlControl;

  private min: number;
  private max: number;

  constructor(private model: NgControl, private dataSharingService: DataSharingService) {
  }

  ngAfterViewInit(): void {

    if (
      this.control.inputType !== 'select' &&
      this.control.inputType !== 'file' &&
      this.control.inputType !== 'checkfile' &&
      this.control.inputType !== 'fabric' &&
      this.control.inputType !== 'check' &&
      this.control.inputType !== 'checkfabric'
    ) {
      this.model.control.setValue(this.control);
      this.model.valueAccessor.writeValue(this.control.value);
    }
    if (this.control.inputType === 'text' && this.control.required === false) {
       this.model.control.setValue(this.control);
       this.model.valueAccessor.writeValue(this.control.value);
    }

      if (
        (this.control.inputType === 'select' || (this.control.inputType === 'text' && this.control.required === true) || this.control.inputType === 'radioimg') &&
        (this.control.value === '' || this.control.value === undefined)
      ) {
        this.model.control.setValue('');
        this.model.valueAccessor.writeValue('');
      }
      if (
        (this.control.inputType === 'checkSelect') &&
        (this.control.value === '' || this.control.value === undefined)
      ) {
        this.model.control.setValue('');
        this.model.valueAccessor.writeValue('');
      }
      if (this.control.inputType === 'fabric' && this.control.value !== undefined) {
        this.model.control.setValue(this.control);
        this.model.valueAccessor.writeValue(this.control.value.Value);
      }
      if (this.control.inputType === 'checkfabric' && this.control.value !== undefined) {
        this.model.control.setValue(this.control);
        this.model.valueAccessor.writeValue(this.control.value.Value);
      }

    if ( this.control.inputType === 'selectmulticolumn' || this.control.inputType === 'checkselectmulticolumn') {
      this.model.control.setValue(this.control);
      this.model.valueAccessor.writeValue(this.control.value.DisplayValue);
    }


    if (this.control.inputType === 'kim' && this.control.value !== undefined && this.control.value !== 'X' && this.control.value !== '') {
      this.model.control.setValue(this.control);
      let writeValue = '';
      let option: Kim;
      const tempArrayKim: Kim[] = new Array<Kim>();
      this.control.value.forEach((x: Kim) => {
        option = this.control.optionsKim.find((y) => y.Index === x.Index);
        if (option !== undefined) {
          tempArrayKim.push(option);
          option.Quantity = x.Quantity;
          writeValue = writeValue + option.Name + ' x ' + x.Quantity + ',\n';
        }
      });
      this.control.value = tempArrayKim;
      this.model.valueAccessor.writeValue(writeValue);
    }

    if (this.control.inputType === 'number' ) {
      this.min = this.control.parameters.find(x => x.Key === 'Min').Value;
      this.max = this.control.parameters.find(x => x.Key === 'Max').Value;
      if (this.control.value === '') {
        this.model.control.setValue(null);
        this.model.valueAccessor.writeValue(null);
      } else if (+this.control.value < this.min || +this.control.value > this.max) {
        this.model.control.setValue(+this.control.value);
        this.model.valueAccessor.writeValue(+this.control.value);
      }
    } else if (this.control.inputType === 'checknumber') {
      this.min = this.control.parameters.find((x) => x.Key === 'Min').Value;
      this.max = this.control.parameters.find((x) => x.Key === 'Max').Value;
      if (this.control.value === '') {
        this.model.control.setValue(this.control);
        this.model.valueAccessor.writeValue(null);
      } else if (+this.control.value < this.min || +this.control.value > this.max) {
        this.model.control.setValue(+this.control.value);
        this.model.valueAccessor.writeValue(+this.control.value);
      }
    }

    if (this.control.inputType === 'check') {
      let value;

      this.control.options.forEach((element) => {
        if (element.Key === this.control.value) {
          this.control.value = element;
        }
      });

      if (this.control.value.Key === 'TAK') {
        value = true;
      } else {
        value = false;
      }

      this.model.control.setValue(this.control);
      this.model.valueAccessor.writeValue(value);
    }

    this.model.control.markAsTouched();
  }


  @HostListener('change', ['$event']) onKey(event: any): void {
    if (this.control.inputType === 'kim') {
      this.model.control.setValue(this.control)
      let writeValue = '';
      for (const x of this.control.value) {
        writeValue = writeValue + x.Name + ' x ' + x.Quantity + ',\n';
      }
      this.model.valueAccessor.writeValue(writeValue);
    } else if (this.control.inputType === 'fabric' || this.control.inputType === 'selectmulticolumn' || this.control.inputType === 'checkselectmulticolumn') {
      const value = event.target.value;
      this.model.control.setValue(this.control);
      this.model.valueAccessor.writeValue(value); 
    } else if (this.control.inputType === 'checkfabric') {
      var x = event; 
      const value = event.target.value;
      this.model.control.setValue(this.control);
      this.model.valueAccessor.writeValue(value);
    } else if (this.control.inputType === 'check') {

      const value = event.checked;
      if (value) {
        this.control.value = this.control.options.find(x => x.Key === 'TAK');
      } else {
        this.control.value = this.control.options.find(x => x.Key === 'NIE');
      }
      this.model.control.setValue(this.control);
      this.model.valueAccessor.writeValue(value);

    } else if (this.control.inputType === 'number' || this.control.inputType === 'checknumber') {
      if (event.target.value !== '' && +event.target.value >= this.min && +event.target.value <= this.max) {
        const value = event.target.value;
        this.control.value = '' + +value;
        this.model.control.setValue(this.control);
        this.model.valueAccessor.writeValue(this.control.value);
      }
    } else if (this.control.inputType === 'radioimg') {
      this.control.value = event.value;
      this.model.control.setValue(this.control);
      this.model.valueAccessor.writeValue(this.control.value);
    } else {
      this.control.value = event.target.value;
      this.model.control.setValue(this.control);
      this.model.valueAccessor.writeValue(this.control.value);
    }
    this.dataSharingService.ValueChangeInControl.next({ ValueChange: true, CurrentStep: this.control.currentStep});
  }


  @HostListener('selectionChange', ['$event']) onChange(event: any): void {
    this.control.value = event.value;
    this.model.control.setValue(this.control);
    this.model.valueAccessor.writeValue(this.control.value);
    // console.log('event', event, this.control);

    this.dataSharingService.ValueChangeInControl.next({ ValueChange: true, CurrentStep: this.control.currentStep });
  }


}
