import { TresciService } from './../tresci.service';
import { deserialize, serialize } from 'class-transformer';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { BehaviorSubject } from 'rxjs';
import { HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';
export class ListaZamowien {

  public lista = [];
  public listaAll = this.lista;
  public szczegoly = null;
  public kategorie = [];
  public statusList = [];
  public wczytane_id = 0;
  public czyMojeZam = false;
  public asc = true;
  public aktualny_status = 'Opracowywane';
  public kto_opracowal = null;
  public data_zamowienia = null;
  public kto_zamowil = null;
  public sort_id = '';
  public zamawiam = '';

  public listObservable$ = new BehaviorSubject<any[]>([]);

  Day: Date = new Date();
  DateTo: any;
  DateFrom: any;

  constructor(public tresci: TresciService, public archiwum: boolean) {
    this.DateFrom = this.getDate(new Date(this.Day.getTime() - (1000 * 60 * 60 * 24 * 30)));
    this.DateTo = this.getDate(this.Day);

    if (tresci.id == null) {
      /*if(archiwum) {
        this.getArchiwum();
      } else {
        this.getZamowienia();
      }*/
      this.GetOrderStatuses();
    } else {
      // this.getOrder(tresci.id);
    }
  }

  public filterByColumn ( searchColumn: string, condition: string) {
    this.lista = this.listaAll;
    if (searchColumn === 'Numer B2B') {
      this.lista = this.tresci.zamowienia.listaAll.filter(a => a.B2BId.toLowerCase().includes(condition.trim().toLowerCase()));
    } else if (searchColumn === 'Produkt') {
      this.lista = this.tresci.zamowienia.listaAll.filter(a => a.CategoryProduct.toLowerCase().includes(condition.trim().toLowerCase()));
    } else if (searchColumn === 'System') {
      this.lista = this.tresci.zamowienia.listaAll.filter(a => a.System.toLowerCase().includes(condition.trim().toLowerCase()));
    }
  }

  public zapisz_potwierdzenie(Id: number, Dokument: string, Rozszerzenie: string, FileId = null, zamowienie = null) {
    const body = {
      'Id': Id,
      'Document': Dokument,
      'FileExtension': Rozszerzenie,
      'FileId': FileId
    };
    /*const body = {
      'Id' : Id,
      'File' : Dokument,
      'FileExtension' : Rozszerzenie
    };*/
    const dane = serialize(body);

    const request = new HttpRequest('POST',
        '/api/ActiveForm/UploadTransferConfirmation',
      dane,
      { headers: this.tresci.user.getHeaderJson() });
    this.tresci.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            zamowienie.zapisano = true;
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
        }
      });
  }

  public zmienStatus(id) {
    this.wczytane_id = id;
    this.aktualny_status = this.statusList[id].Name;
    this.getZamowienia();
  }

  public zaznaczMojeZamowienia(czyMoje: boolean) {
    this.czyMojeZam = czyMoje;
    this.getZamowienia();
  }


  public GetOrderStatuses() {
    // const body = {
    //   'ContractorNumber': this.tresci.user.ContractorNumber
    // };
    // const dane = serialize(body);
    // const request = new HttpRequest('GET',
    //   this.tresci.server_url + '/api/OrderRegister/GetOrderStatuses?ContractorNumber=' + this.tresci.user.ContractorNumber
    //   + '&dateFrom=' + this.DateFrom + '&dateTo=' + this.DateTo,
    //   {},
    //   { headers: this.tresci.user.getHeaderJson() });
    // this.tresci.http.request(request)
    //   .subscribe((event: HttpEvent<any>) => {
    //     switch (event.type) {
    //       case HttpEventType.Response:
    //         this.statusList = event.body;
    //         this.statusList.push({Name: 'Płatności'})
    //         const aktualny_status_id = this.tresci.odczytaj('aktualny_status_id');
    //         if (aktualny_status_id != null && aktualny_status_id !== '') {
    //           this.wczytane_id = parseInt(aktualny_status_id);
    //         }
    //         if (  this.wczytane_id !== 6){
    //           this.getZamowienia();
    //         }
    //     }
    //   }, (err: any) => {
    //     if (err instanceof HttpErrorResponse) {
    //     }
    //   });
  }



  public getArchiwum() {
    let ContractorNumber = this.tresci.user.ContractorNumber;
    // if (ContractorNumber == null) {
    //   ContractorNumber = '00001';
    // }
    const body = {
      'ContractorNumber': ContractorNumber
    };
    const dane = serialize(body);
    // tslint:disable-next-line:max-line-length

    const request = new HttpRequest('POST',
        '/api/ActiveForm/GetOrderArchive',
      dane,
      { headers: this.tresci.user.getHeaderJson() });
    this.tresci.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.lista = event.body;
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
        }
      });
  }

  public getZamowienia() {
    // this.lista = null;
    // const ContractorNumber = this.tresci.user.ContractorNumber;
    // // if (ContractorNumber == null) {
    // //   ContractorNumber = '00001';
    // // }
    // const body = {
    //   'ContractorNumber': ContractorNumber
    // };
    // const dane = serialize(body);
    // // tslint:disable-next-line:max-line-length
    // // /api/StorageFile/Get?id=2292
    // const request = new HttpRequest('POST',
    //   this.tresci.server_url + '/api/ActiveForm/GetOrders?status=' + this.statusList[this.wczytane_id].Name
    //   + '&dateFrom=' + this.DateFrom + '&dateTo=' + this.DateTo + '&isMineOrders=' + this.czyMojeZam,
    //   dane,
    //   { headers: this.tresci.user.getHeaderJson() });
    // this.tresci.http.request(request)
    //   .subscribe((event: HttpEvent<any>) => {
    //     switch (event.type) {
    //       case HttpEventType.Response:
    //         this.lista = event.body;
    //         this.listObservable$.next(this.lista);
    //         this.listaAll = this.lista;

    //         if (this.lista != null && this.lista.length > 0) {
    //           this.aktualny_status = this.lista[0].OrderStatusName;
    //         }
    //         if (this.aktualny_status === 'Zaakceptowane' || this.aktualny_status === 'Wstrzymane' || this.aktualny_status === 'Wstrzymano' || this.aktualny_status === 'Do weryfikacji') {
    //           this.aktualny_status = 'Opracowywane';
    //         }
    //         this.tresci.zapisz('aktualny_status_id', this.wczytane_id);
    //         // this.lista.sort(function(a, b) {return b.Id - a.Id; });
    //         this.lista.sort((a, b) => {
    //           return this.porownaj(b.OrderDate, a.OrderDate, true);
    //         });
    //     }
    //   }, (err: any) => {
    //     if (err instanceof HttpErrorResponse) {
    //     }
    //   });
  }
  public sortuj_po(id) {
    this.asc = !this.asc;
    this.sort_id = id;
    if (id === 'IsPaid') {
      this.lista.sort((a, b) => {
        return this.porownaj(b.IsPaid, a.IsPaid, this.asc);
      });
    } else if (id === 'ValueNetto') {
      this.lista.sort((a, b) => {
        return this.porownaj(b.ValueNetto, a.ValueNetto, this.asc);
      });
    } else if (id === 'DeliveryCostNetto') {
      this.lista.sort((a, b) => {
        return this.porownaj(b.DeliveryCostNetto, a.DeliveryCostNetto, this.asc);
      });
    } else if (id === 'ProductionNumber') {
      this.lista.sort((a, b) => {
        return this.porownaj(b.ProductionNumber, a.ProductionNumber, this.asc);
      });
    } else if (id === 'OrderDate') {
      this.lista.sort((a, b) => {
        return this.porownaj(b.OrderDate, a.OrderDate, this.asc);
      });
    } else if (id === 'ProcessingSeltDate') {
      this.lista.sort((a, b) => {
        return this.porownaj(b.ProcessingSeltDate, a.ProcessingSeltDate, this.asc);
      });
    }
  }

  public getDate(data: Date) {
    const m = data.getMonth() + 1;
    let miesiac = '';
    if (m < 10) {
      miesiac = '0' + m;
    } else {
      miesiac = '' + m;
    }
    const d = data.getDate();
    let dzien = '';
    if (d < 10) {
      dzien = '0' + d;
    } else {
      dzien = '' + d;
    }
    return data.getFullYear() + '-' + miesiac + '-' + dzien;
  }


  public porownaj(a, b, asc = true) {
    if (a < b) { if (asc) { return -1; } else { return 1; } }
    if (a > b) { if (asc) { return 1; } else { return -1; } }
    return 0;
  }
  public pobierzWydrukSpecyfikacji(Id, ContractorNumber, ProductionNumber) {
    const body = {
      'Id': Id,
      'ContractorNumber': ContractorNumber,
      'ProductionNumber': ProductionNumber
    };
    const dane = serialize(body);
    const request = new HttpRequest('POST',   '/api/ActiveForm/GetConfirmationPrintout',
      dane,
      { responseType: 'blob', headers: this.tresci.user.getHeaderJson() });
    this.tresci.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            const url = window.URL.createObjectURL(new Blob([event.body], { type: 'application/pdf' }));
            //window.open(url, '_blank');
            const dupa = this.tresci.sanitizer.bypassSecurityTrustUrl(url);
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
        }
      });
  }

  public addOrderToarchive(Id) {
    const body = {
      'Id': Id
    };
    const dane = serialize(body);

    const request = new HttpRequest('POST',
        '/api/ActiveForm/AddOrderToArchive',
      dane,
      { headers: this.tresci.user.getHeaderJson() });
    this.tresci.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.tresci.zamowienia = new ListaZamowien(this.tresci, true);
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
        }
      });
  }




  public saveOrderWeryfikacja(Id) {
    const body = {
      'Id': Id
    };
    const dane = serialize(body);
    // tslint:disable-next-line:max-line-length
    this.zamawiam = Id;
    // tslint:disable-next-line:max-line-length
    this.tresci.copyMessage(dane, 'Save Order: ' + this.tresci.server_url + '/api/ActiveForm/OrderVerificationBySelt?cultureId' + this.tresci.jezyk_id, 1);
    const request = new HttpRequest('POST',
        '/api/ActiveForm/OrderVerificationBySelt?CultureId=' + this.tresci.jezyk_id,
      dane,
      { headers: this.tresci.user.getHeaderJson() });
    this.tresci.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.zamawiam = '';
            // if (event.body == null) {
            //   this.tresci.exception.exception('Niepoprawny format zamówienia', 'Niepoprawny format zamówienia');
            // } else {
            //   this.tresci.exception.exception(event.body.Name, event.body.Name);
            // }
            this.tresci.zamowienia = new ListaZamowien(this.tresci, false);
        }
      }, (err: any) => {
        this.zamawiam = '';
        this.tresci.exception.exception(this.tresci.exception.error(err), err);
        if (err instanceof HttpErrorResponse) {

        }
      });

  }
  public getOrder(Id) {
    const body = {
      'Id': Id
    };
    const dane = serialize(body);
    // tslint:disable-next-line:max-line-length

    const request = new HttpRequest('POST',
        '/api/ActiveForm/GetOrder',
      dane,
      { headers: this.tresci.user.getHeaderJson() });
    this.tresci.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
          // this.szczegoly = event.body;
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
        }
      });
  }


  public wczytajZamowienieZmienna(id) {
    const body = {
      'Id': id
    };

    const dane = serialize(body);
    const request = new HttpRequest('POST',
        '/api/ActiveForm/GetParameters',
      dane,
      { headers: this.tresci.user.getHeaderJson() });
    this.tresci.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.szczegoly = event.body;

        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
        }
      });
  }

}
