<h2 mat-dialog-title id="edytuj" [translation]="TranslationEnum.Translation">
  {{'edytuj'|translate}}
</h2>
<div mat-dialog-content id="edytuj_jako_handlowiec" [translation]="TranslationEnum.Translation">
    {{'edytuj_jako_handlowiec'| translate}}
</div>
<div mat-dialog-actions>
  <div style="width:100%">
    <button mat-flat-button id="anuluj" class="float-right" [translation]="TranslationEnum.Translation" color="primary"
      mat-dialog-close>
      {{'anuluj'|translate}}
      <!-- <app-tlumacz [id]="'Anuluj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>
    <button mat-flat-button id="edytuj_tryb_klient" class="float-right mr-3" [translation]="TranslationEnum.Translation"
      color="primary" [mat-dialog-close]="true" (click)="edit(KindOper.EditClient)">
      {{'edytuj_tryb_klient'|translate}}
      <!-- <app-tlumacz [id]="'Zapisz_notatkę'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>
    <button mat-flat-button id="edytuj" class="float-right mr-3" [translation]="TranslationEnum.Translation"
      color="primary" [mat-dialog-close]="true" (click)="edit(KindOper.Edit)">
      {{'edytuj'|translate}}
      <!-- <app-tlumacz [id]="'Zapisz_notatkę'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>
  </div>
</div>
