
<ng-container *ngIf = "DevMode">
    
    <div class="DevMode_box">
        <div class="DevIcon">
            
        </div>
        <div class="DevMode">
        <span class="pole_devK">
            <span class="opis_k">Typ kontrolki</span>
            <span class="wartosc_k">{{control.ControlType}}</span>
        </span>
        <span class="pole_devK">
            <span class="opis_k">Nazwa kontrolki</span>
            <span class="wartosc_k">{{control.KeyName}}</span>
        </span>
        <span class="pole_devK">
            <span class="wartosc_k" (click) = "otworzDev()"><div class="info_dev"></div></span>
            <span class="pole_kontrolka_dev">
                
                
            </span>
        </span>
        <div class="wybierz_wartosc">
            <div class="wyboerz_wartosc_tresc">wybierz wartość:</div>
            <div class="opcje_Wartosci"> (<span *ngFor="let v of control.Values; let kk = index;" class="opcja" (click) = "zmienWartoscAdmin(v.Key, false)">{{v.Key}} - </span>)</div>
        </div>
    </div>
</div>
</ng-container>

<div class="ustawienie">
        
        <container-element [ngSwitch]="control.ControlType">
            <!-- the same view can be shown in more than one case -->
            <ng-container *ngSwitchCase="'NewLine'"><div class="NewLine"></div></ng-container>
            
            <ng-container *ngSwitchCase="'Label'">
                <div class="formatuj">
                    <div class="ng"><span class="Label_box">
                        <span  id="{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('' + control.KeyName.toLowerCase())|translate}}</span>
                        <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                    </span></div>
                    <div class="kon" class="Label"  [style.background-color]="control.BackgroundColour" [style.color]="control.ForegrandColour">
                        <div *ngIf="CzyLiczba(control.wybrany.DisplayValue)">{{control.wybrany.DisplayValue}}</div>
                        <div *ngIf="!CzyLiczba(control.wybrany.DisplayValue)"><span  id="{{control.KeyName.toLowerCase()}}_value" [translation]="TranslationEnum.TranslationForm">{{(control.KeyName.toLowerCase() + '_value')|translate}}</span></div>
                    </div>
                </div>
                
                
            </ng-container>
            <ng-container *ngSwitchCase="'Check'"><div class="Check poprawna{{control.poprawne}}">

                <input type = "checkbox" [(ngModel)]="checked" (change) = "changeCheck($event)" [disabled] = "!GrupaKrokow.CzyAktywny(i)">
                <span class="check_text"><span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information></span>
                
                
            </div></ng-container>
            <ng-container *ngSwitchCase="'Module'" ><div class="Module poprawna{{control.poprawne}}">
                    <div class="pola wyboru">
                        <div class="modul_wiersz" *ngFor="let kolumna of module.matrix; let x = index">
                            <ng-container *ngFor="let komorka of kolumna; let y = index">
                                <div *ngIf = "komorka || module.czySasiad(x, y)" class="modul_komorka komorka_{{komorka}}" 
                                (click)="module.set(y,x)">
                                    <span *ngIf="komorka">M{{module.getName(y,x)}} <span class="rodzaj_mod">({{module.getRodzaj(x, y)}})</span></span>
                                </div>
                                <div *ngIf = "!komorka && !module.czySasiad(x, y)" class="modul_komorka komorka_szara komorka_{{komorka}}">
                                    <span *ngIf="komorka"></span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    

                    <div class="wybrane_mod">wybrane moduły: {{module.getDisplay()}}</div>
                    <div class="wybrane_mod"> {{control.wybrany.Value}}</div>
                  
            </div></ng-container>

            

            
    
            
            <ng-container *ngSwitchCase="'Text'"><div class="Text poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
               
                <div class="kon">
                    <input  name="parametr" (change) = "changeText(control)" [disabled] = "!GrupaKrokow.CzyAktywny(i)" [(ngModel)]="control.wybrany.Value">
                </div>
                        
                 
            </div></ng-container>
    
    
             
            <ng-container *ngSwitchCase="'Number'"><div class="Number poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span> ({{getMinNumber()}} - {{getMaxNumber()}})
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="kon">
                    <input  name="parametr" (change) = "changeNumber(control)" [disabled] = "!GrupaKrokow.CzyAktywny(i)" [(ngModel)]="control.wybrany.Value">
                </div>
                        
                 
            </div></ng-container>
    
    
            <ng-container *ngSwitchCase="'VarianticVisualization'"><div class="VarianticVisualization poprawna{{control.poprawne}}">
                
            </div></ng-container>
            
    
            
            
            
            <ng-container *ngSwitchCase="'CheckNumber'"><div class="CheckNumber poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span> ({{getMinNumber()}} - {{getMaxNumber()}})
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                
                        
                 
                        <div class="CheckSelect_box kon">
                            <div class="CheckSelect_check">
                                <input type = "checkbox" [(ngModel)]="checked" (change) = "changeCheckNumber($event)" [disabled] = "!GrupaKrokow.CzyAktywny(i)">
                            </div>
                            <div class="CheckSelect_selkect">
                                <input  name="parametr" (change) = "changeNumber(control)" [disabled] = "!GrupaKrokow.CzyAktywny(i) && checked" [(ngModel)]="control.wybrany.Value">
                            </div>
                        </div>
            </div></ng-container>
    
            <ng-container *ngSwitchCase="'CheckSelect'"><div class="CheckSelect poprawna{{control.poprawne}} czyaktywny{{czyAktywnyCheckSelect()}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="CheckSelect_box con">
                    <div class="CheckSelect_check">
                        <input type = "checkbox" [(ngModel)]="checked" (change) = "changeCheckSelect($event)" [disabled] = "!GrupaKrokow.CzyAktywny(i)">
                    </div>
                    <div class="CheckSelect_selkect">
                        <form [formGroup]="form">
                            <mat-form-field>
                                <mat-select (selectionChange) = "changeSelect($event)" formControlName="state">
                                    <ng-container  *ngFor="let v of control.Values; let kk = index;">
                                        <mat-option [value]="v.Key">{{v.DisplayValue}}</mat-option>
                                    </ng-container>
                                  
                                </mat-select>
                              </mat-form-field>
                        </form>
                    </div>
                </div>
                
               
                
                
            </div></ng-container>

            <ng-container *ngSwitchCase="'CheckSelectMultiColumn'"><div class="CheckSelect poprawna{{control.poprawne}} czyaktywny{{czyAktywnyCheckSelect()}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="CheckSelect_box con">
                    <div class="CheckSelect_check">
                        <input type = "checkbox" [(ngModel)]="checked" (change) = "changeCheckSelectMulti($event)" [disabled] = "!GrupaKrokow.CzyAktywny(i)">
                    </div>
                    
                <div class="pole_wartosc_kim2" (click) = "otworzSelectMultiColumnCheck()"><span class="kolor"  *ngIf = "checked && control.wybrany.Kolor != null && control.wybrany.Kolor != ''" [style.background-color]="control.wybrany.Kolor" ></span>{{control.wybrany.DisplayValue}}</div>
            
                </div>
                
               
                
                
            </div></ng-container>


            
            <ng-container *ngSwitchCase="'Select'"><div class="Select poprawna{{control.poprawne}} czyaktywny{{czyAktywnySelect()}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="kon">
                    <form [formGroup]="form">
                        <mat-form-field>
                            <mat-select (selectionChange) = "changeSelect($event)" formControlName="state" width = "auto">
                                <ng-container  *ngFor="let v of control.Values; let kk = index;">
                                    <mat-option [value]="v.Key">{{v.DisplayValue}}</mat-option>
                                </ng-container>
                            
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                
                
            </div></ng-container>
            <ng-container *ngSwitchCase="'RadioImg'"><div class="RadioImg poprawna{{control.poprawne}} formatuj">
                <span class="Label_box">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="kon">
                    <ng-container *ngFor="let v of control.Values; let kk = index;">
                        <span class="foto_control zaznaczony_{{v.Key == control.wybrany.Value}} Disabled_{{v.Disabled}}"  (click) = "zmienWartosc(v.Key, v.Disabled)">
                            <div class="ok"></div>
                            <img class="foto"  src="{{server_url}}//api/ActiveForm/GetIcon?code={{system}}_{{control.KeyName}}_{{v.Key}}" [style.width.px]="control.PictureWidth">
                            <div class="wartosc">{{v.DisplayValue}}</div>
                        </span>
                    </ng-container>
                </div>
                
            </div></ng-container>
            
            <ng-container *ngSwitchCase="'Pdf'"><div class="Pdf poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="kon">
                    <a href="{{control.DefaultValue}}" target = "_blank"><div class="Pdf_tlo" [style.width.px]="control.PictureWidth" [style.height.px]="control.PictureWidth"></div></a>
                </div>
                
            </div></ng-container>
         
            <ng-container *ngSwitchCase="'SelectImg'"><div class="SelectImg poprawna{{control.poprawne}} czyaktywny{{czyAktywnySelect()}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="kon">
                    <form [formGroup]="form">
                        <mat-form-field>
                            <mat-select (selectionChange) = "changeSelect($event)" formControlName="state">
                                <ng-container  *ngFor="let v of control.Values; let kk = index;">
                                    <mat-option [value]="v.Key"><div class="select_wybor"><img class="foto"  src="{{server_url}}//api/ActiveForm/GetIcon?code={{v.Key}}" [style.width.px]="control.PictureWidth"><sapn class="select_text">{{v.DisplayValue}}</sapn></div></mat-option>
                                </ng-container>
                            
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                
                
            </div></ng-container>
    
            
            <ng-container *ngSwitchCase="'Img'"><div class="Img poprawna{{control.poprawne}}">
               
                    
                    <button [disabled]="!GrupaKrokow.CzyAktywny(i)" mat-flat-button  type="button" color="accent" id="label_{{control.KeyName.toLowerCase()}}" class="btn-sm float-right"
                      [translation]="TranslationEnum.TranslationForm" (click)="openImgDialog()">
                      {{('label_'+ control.KeyName.toLowerCase())|translate}}
                    </button>
               
                  
                  <ng-template #imgDialog>
                    <div mat-dialog-content>
                      <img [src]="src">
                    </div>
                  </ng-template>
    
    
    
            </div></ng-container>
            
            <ng-container *ngSwitchCase="'Kim'"><div class="Kim poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="pole_wartosc_kim kon" (click) = "otworzKim()">{{control.wybrany.Value}}</div>
            </div></ng-container>

            
            <ng-container *ngSwitchCase="'SelectMultiColumn'"><div class="SelectMultiColumn poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="pole_wartosc_kim kon" (click) = "otworzSelectMultiColumn()"><span class="kolor"  *ngIf = "control.wybrany.Kolor != null && control.wybrany.Kolor != ''" [style.background-color]="control.wybrany.Kolor" ></span>{{control.wybrany.DisplayValue}}</div>
            </div></ng-container>
            <ng-container *ngSwitchCase="'Fabric'"><div class="Fabric poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="pole_wartosc_kim kon" (click) = "otworzFabric()"><img *ngIf = "control.wybrany.Value != null && control.wybrany.Value != ''" src = "{{server_url}}//api/ActiveForm/GetIcon?code={{control.wybrany.Value}}" /> {{control.wybrany.DisplayValue}}</div>
            </div></ng-container>
            <ng-container *ngSwitchCase="'File'"><div class="File poprawna{{control.poprawne}} czyaktywny_{{GrupaKrokow.CzyAktywny(i)}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="file_box kon">
                    
                    <div class="pole_wartosc_kim">
                        <input   [disabled] = "!GrupaKrokow.CzyAktywny(i)"
                #input
                type="file"
                (change)="changeImg($event)"
                accept="image/png, image/jpeg, image/gif, image/bmp, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain"
              />
                        {{control.wybrany.DisplayValue}} <div class="icon_upload"><mat-icon>file_upload</mat-icon></div></div><div *ngIf = "checked && fileExist()" class="file_view" (click) = "previewFile();"><mat-icon>remove_red_eye</mat-icon></div></div>
                
            </div></ng-container>
            <ng-container *ngSwitchCase="'CheckFile'"><div class="CheckFile poprawna{{control.poprawne}} czyaktywny_{{GrupaKrokow.CzyAktywny(i) && checked}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                    <app-information [label]="control.KeyName" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
    
                <div class="CheckSelect_box kon">
                    <div class="CheckSelect_check">
                        <input type = "checkbox" [(ngModel)]="checked" (change) = "changeCheckFile($event)" [disabled] = "!GrupaKrokow.CzyAktywny(i)">
                    </div>
                    <div class="CheckSelect_selkect">
                        <div class="file_box">
                            <div class="pole_wartosc_kim">
                                <input  [disabled] = "!GrupaKrokow.CzyAktywny(i) && checked"
                #input
                type="file"
                (change)="changeImg($event)"
                accept="image/png, image/jpeg, image/gif, image/bmp, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain"
              />
                                {{control.wybrany.DisplayValue}} <div class="icon_upload"><mat-icon>file_upload</mat-icon></div></div><div *ngIf = "checked && fileExist()" class="file_view" (click) = "previewFile();"><mat-icon>remove_red_eye</mat-icon></div></div>
                    </div>
                </div>
    
                
                
            </div></ng-container>
            <ng-container *ngSwitchDefault>
                <div class="kontrolki poprawna{{control.poprawne}} formatuj">
                    <span class="Label_box ng">
                        <span *ngIf = "control.ControlType != 'Hidden'">UWAGA - Kontrolka nie zaimplementowana, proszę zgłosić do działu wsparcia! - </span><span  id="label_{{control.KeyName.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.KeyName.toLowerCase())|translate}}</span>
                        <app-information [label]="control.KeyName"  [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                    </span>
                    <div class="pole_wartosc kon"><input name="parametr" (change) = "FormData.ZmianaParametru(control)" [disabled] = "!GrupaKrokow.CzyAktywny(i)" [(ngModel)]="control.wybrany.Value" /></div>
        
                </div>
            </ng-container>
        </container-element>
        <ng-container *ngIf = "DevMode">
            <span class="Wartosc_akt">wartość: {{control.wybrany.Value}} Wyświetla: {{control.wybrany.DisplayValue}}</span>
            
        </ng-container>
    

</div>
   
    
