import { UserPermission } from './user-permission.model';
import { Contractor } from './contractor.model';

export class User {
  ContractorName: string;
  ContractorNumber: string;
  CanTranslate: boolean;
  IsModeService: boolean;
  IsTrader: boolean;
  IsFirst?: boolean;
  Name: string;
  Username: string;
  Contractors: Contractor[];
  Permissions: UserPermission[];
  ContractorSystems: string[];
  ContactPhone:string;
  ContactMail:string;
  EnableEWdt:boolean;
  ConfirmationEWdt:boolean;
  EnalbleWdtToConfirm:boolean;
  BlockingB2B:boolean;
  Country:string;
}
