
import { GrupaKrokow } from "./GrupaKrokow";
import { Supervisor } from "./Supervisor";

export class Modul {
    moduleNg:GrupaKrokow;
    pozycje:GrupaKrokow[] = [];
    moduleFooter:GrupaKrokow;
    zakonczono_pozycje:boolean = false;


    constructor(){
        this.moduleNg = new GrupaKrokow('ModuleHeader');
        this.moduleFooter = new GrupaKrokow('ModuleFooter'); 
    }
    
    GetNg(){
        var naglowki:String[] = [];
        


        this.pozycje.forEach(poz => {
            poz.kroki.forEach(k => {
                    k.Controlls.forEach(con => {
                        if(con.wybrany.Key != 'X' && con.DisplayInTablePosition){
                            //if(con.ControlType != "Hidden"){
                                // con.wybrany;
                                var jest = naglowki.find(x => x == con.wybrany.Key);
                                
                                if(jest == null){
                                        naglowki.push(con.wybrany.Key);
                                }
                            //}
                        }
                    });
                
            });
        });

        return naglowki;
    }
    UsunPozycje(numer:number, supervisor:Supervisor) {
        this.pozycje.splice(numer,1);
        
    }
    CzyNgPoprawny() {
        return this.moduleNg.CzyZakonczony();
    }
    CzyFtPoprawny() {
        return this.moduleFooter.CzyZakonczony();
    }
    CzyPozPoprawny() {
        var poprawne = true;
        this.pozycje.forEach(element => {
            if(!element.CzyZakonczony()){
                // console.log("pop", element, element.CzyZakonczony(), element.poprawnie_zakonczone, element.edytowane);
                poprawne = false;
            }
        });
        return poprawne && this.CzyNgPoprawny();
    }
}