import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TresciService } from './../tresci.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { TranslationEnum } from '../shared/enums/translation-enum';
import { NotificationsService } from '../notifications/notifications.service';
import { NotificationsModalComponent } from '../notifications/notifications-modal/notifications-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Contractor } from '../shared/models/user/contractor.model';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../shared/models/user/user.model';
import { UniversalDialogComponent } from '../shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from '../shared/models/dialog/dialog-data.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public TranslationEnum = TranslationEnum;
  //dialog: any;
  ok: boolean;
  private subscriptions: Subscription[] = [];
  EnableEWdt: boolean;
  BlockingB2B: boolean;
  constructor(public tresci: TresciService, public user: UserService,public dialog: MatDialog, private router: Router, private activeRoute: ActivatedRoute, private notificationService: NotificationsService, private cookieService: CookieService) {
    tresci.system = '';
    
    window['site'] = 'stale';
    localStorage.site = "stale";
    var user2 = JSON.parse(this.cookieService.get('_usi')) as User;
    this.EnableEWdt = user2.EnableEWdt;
    this.BlockingB2B = user2.BlockingB2B;
    if(user2.EnalbleWdtToConfirm){
      var message = "posiadasz_ewdt_do_potwierdzenia";
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: '',
        content: message,
        answerOk: true
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    }
    
    // console.log("user", user2);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit() {
    this.notificationService.getNotificationForUser();
    this.openNotificationsDialog();
        // setTimeout(() => {
          this.ok = true;
        // }, 500);
        
  }


  public reklamacje() {
    // window.location.href = "http://reklamacje.selt.com";
    window.open('http://reklamacje.selt.com', '_blank');
  }

  routeTo(site: string) {
    this.router.navigate([`${site}`]);
  }

  openNotificationsDialog(): void {
    const notificationsShowed = JSON.parse(sessionStorage.getItem('notificationsShowed'));

    const notificationsModalExist = this.dialog?.getDialogById('notifications-modal');

    this.subscriptions.push(this.notificationService.userNotifications.subscribe((x: any) => {
      if (!(this.notificationService.notificationsShowed || notificationsShowed) && x !== null && x.length > 0) {
        this.notificationService.notificationsShowed = true;
        sessionStorage.setItem('notificationsShowed', JSON.stringify(true));

        if (!notificationsModalExist) {
          let dialogRef: MatDialogRef<NotificationsModalComponent, any>;
          dialogRef = this.dialog.open(NotificationsModalComponent, {
            id: 'notifications-modal',
          });
        }
      }
    }));
  }
}


