<div class="m-2">

  <!-- <div class="d-inline-block mt-3 mr-3">
    <mat-form-field appearance="outline" class="w-160p">
      <mat-select [(value)]="filterColumn">
        <mat-option *ngFor="let item of filterColumns" [value]="item">{{item}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->

  <div class="d-inline-block mt-3 mr-3">
    <mat-form-field appearance="outline" class="w-190p" color="accent">
      <input matInput (keyup.enter)="applyFilter(input)" #input placeholder="B2B/2020/03962">
      <button *ngIf="input.value" mat-button matSuffix mat-icon-button class="d-inline-block"
        (click)="applyFilter(input)">
        <mat-icon>search</mat-icon>
      </button>
      <button *ngIf="input.value" mat-button matSuffix mat-icon-button class="d-inline-block"
        (click)="input.value='';applyFilter(input)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline" class="mt-3" color="accent">
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input readonly matStartDate formControlName="start">
      <input readonly matEndDate formControlName="end" (dateChange)="dateRangeChange($event)">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker" class="d-inline-block"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <div class="mw-100">

    <div class="loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      <div *ngIf="isRateLimitReached" id="wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę"
        class="rate-limit-reached" [translation]="TranslationEnum.Translation">
        {{'wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę'|translate}}
        <!-- <app-tlumacz [id]="'Wystąpił błąd połączenia. Proszę odświeżyć za minutę.'" [typ]="'stale'" [tresci]="tresci">
        </app-tlumacz> -->
      </div>
    </div>

    <div class="w-100">
      <table mat-table matSort multiTemplateDataRows [(dataSource)]="dataSource" matSortActive="ProformaFormat"
        matSortDisableClear matSortDirection="desc" class="w-100">

        <!-- ProformaFormat Column -->
        <ng-container matColumnDef="ProformaFormat">
          <th mat-header-cell *matHeaderCellDef  id="proforma_format"
            [translation]="TranslationEnum.Translation">
            {{'proforma_format'|translate}}
            <!-- <app-tlumacz [id]="'ProformaFormat'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </th>
          <td mat-cell *matCellDef="let element"> {{element.ProformaFormat}} </td>
        </ng-container>

        <!-- NumberOfOrders Column -->
        <ng-container matColumnDef="NumberOfOrders">
          <th mat-header-cell *matHeaderCellDef  id="liczba_zamówień"
            [translation]="TranslationEnum.Translation">
            {{'liczba_zamówień'|translate}}
            <!-- <app-tlumacz [id]="'Liczba zamówień'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </th>
          <td mat-cell *matCellDef="let element"> {{element.NumberOfOrders}} </td>
        </ng-container>

        <!-- ProformaDate Column -->
        <ng-container matColumnDef="ProformaDate">
          <th mat-header-cell *matHeaderCellDef  id="data_proformy"
            [translation]="TranslationEnum.Translation">
            {{'data_proformy'|translate}}
          </th>
          <td mat-cell *matCellDef="let element"> {{element.ProformaDate|date:'yyyy-MM-dd'}} </td>
        </ng-container>

        <!-- TotalCost Column -->
        <ng-container matColumnDef="ValueTotalNetto">
          <th mat-header-cell *matHeaderCellDef  id="cena_razem"
            [translation]="TranslationEnum.Translation">
            {{'cena_razem'|translate}}
            <!-- <app-tlumacz [id]="'Cena_razem'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </th>
          <td mat-cell *matCellDef="let element" style="color: #cd0000">
            <ng-container
              *ngIf="element.ValueTotalNetto !== null && element.Currency !== null && element.ValueTotalNetto > 0">
              {{element.ValueTotalNetto | PriceFormat}}&nbsp;
              {{element.Currency}}<br />
              {{element.ValueTotalBrutto | PriceFormat}}&nbsp;
              {{element.Currency}}
            </ng-container>
            <ng-container
              *ngIf="element.ValueTotalNetto !== null && element.Currency !== null && element.ValueTotalNetto === 0">
              ---
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="IsClickedOrder">
          <th mat-header-cell *matHeaderCellDef  id="kliknieto_zamow"
            [translation]="TranslationEnum.Translation">
            {{'kliknieto_zamow'|translate}}
          </th>
          <td mat-cell *matCellDef="let element" >
            <ng-container *ngIf="element.IsClickedOrder">
              <mat-icon style="color: green;">check</mat-icon>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="Printout">
          <th mat-header-cell *matHeaderCellDef id="wydruk" [translation]="TranslationEnum.Translation">
            {{'wydruk'|translate}}
            <!-- <app-tlumacz [id]="'wydruk'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </th>
          <td mat-cell *matCellDef="let element">
            <form ngNoForm [action]="tresci.server_url + 'api/ActiveForm/GetProformaPrintout?culture=' + Culture"
              method="POST" target="_blank">
              <input [hidden]="true" type="text" id="ProformaFormat" name="ProformaFormat"
                [value]="element.ProformaFormat">
              <button mat-flat-button id="proforma" class="btn-sm d-block mb-2"
                [translation]="TranslationEnum.Translation" color="primary" type="submit">
                {{'proforma'|translate}}
                <!-- <app-tlumacz [id]="'Proforma'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </button>
            </form>
          </td>
        </ng-container>

        <ng-container matColumnDef="Order">
          <th mat-header-cell *matHeaderCellDef id="zamów" [translation]="TranslationEnum.Translation">
            {{'zamów'|translate}}
            <!-- <app-tlumacz [id]="'zamów'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="element.CanOrder && !element.IsOlderThen14Days" mat-flat-button id="zamów" class="btn-sm d-block mb-2"
              [translation]="TranslationEnum.Translation" color="primary"
              (click)="openDialogOrder(element.ProformaNumber)">
              {{'zamów'|translate}}
              <!-- <app-tlumacz [id]="'zamów'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </button>
          </td>
        </ng-container>


        <!-- Remove Column -->
        <ng-container matColumnDef="Remove">
          <th mat-header-cell *matHeaderCellDef id="usunzam" [translation]="TranslationEnum.Translation">
            {{'usunzam'|translate}}
            <!-- <app-tlumacz [id]="'UsunZam'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="element.CanRemove" mat-icon-button color="accent"
              (click)="openDialogRemove(element.ProformaFormat)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>


        <ng-container matColumnDef="Expand">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'minw-50p'"></th>
          <td mat-cell *matCellDef="let element" class="text-right" [ngClass]="'minw-50p'">
            <mat-icon color="primary" [hidden]="element.PurchasePermit === 1" class="animate">
              {{ expandedElement !== element ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </mat-icon>
            <p *ngIf="element.PurchasePermit === 1" id="dostępny" [translation]="TranslationEnum.Translation">
              {{'dostępny'|translate}}
              <!-- <app-tlumacz [id]="'Dostępny'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <div class="element-detail" [ngClass]="element === expandedElement ? '' : 'h-0'"
              [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
              <table mat-table [dataSource]="element.Orders" class="mat-elevation-z8">

                <!-- B2BId Column -->
                <ng-container matColumnDef="B2BId">
                  <th mat-header-cell *matHeaderCellDef id="b2bid" [translation]="TranslationEnum.Translation">
                    {{'b2bid'|translate}}
                    <!-- <app-tlumacz [id]="'B2Bid'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row"> {{row.B2BId}} </td>
                </ng-container>

                <!-- Product Column -->
                <ng-container matColumnDef="Product">
                  <th mat-header-cell *matHeaderCellDef id="produkt" [translation]="TranslationEnum.Translation">
                    {{'produkt'|translate}}
                    <!-- <app-tlumacz [id]="'Produkt'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row" id="produkt_{{row.Produkt}}"
                    [translation]="TranslationEnum.Translation">
                    {{'produkt_' + row.Produkt|translate}}
                    <!-- <app-tlumacz [id]="'produkt_' + row.Product" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </td>
                </ng-container>

                <!-- System Column -->
                <ng-container matColumnDef="System">
                  <th mat-header-cell *matHeaderCellDef id="system" [translation]="TranslationEnum.Translation">
                    {{'system'|translate}}
                    <!-- <app-tlumacz [id]="'System'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row" id="system_{{row.System}}"
                    [translation]="TranslationEnum.Translation">
                    {{'system_' + row.System|translate}}
                    <!-- <app-tlumacz [id]="'system_' + row.System" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </td>
                </ng-container>

                <!-- System Column -->
                <ng-container matColumnDef="Quantity">
                  <th mat-header-cell *matHeaderCellDef id="ilość" [translation]="TranslationEnum.Translation">
                    {{'ilość'|translate}}
                    <!-- <app-tlumacz [id]="'Ilość'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row">{{row.Quantity}}</td>
                </ng-container>

                <!-- Applies Column -->
                <ng-container matColumnDef="Applies">
                  <th mat-header-cell *matHeaderCellDef id="dotyczy" [translation]="TranslationEnum.Translation">
                    {{'dotyczy'|translate}}
                    <!-- <app-tlumacz [id]="'Dotyczy'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row"> {{row.Applies}} </td>
                </ng-container>

                <!-- Description Column -->
                <ng-container matColumnDef="Description">
                  <th mat-header-cell *matHeaderCellDef id="uwagi" [translation]="TranslationEnum.Translation">
                    {{'uwagi'|translate}}
                    <!-- <app-tlumacz [id]="'Uwagi'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row"> {{row.Description}} </td>
                </ng-container>


                <!-- TotalCost Column -->
                <ng-container matColumnDef="TotalCost">
                  <th mat-header-cell *matHeaderCellDef id="cena_razem" [translation]="TranslationEnum.Translation">
                    {{'cena_razem'|translate}}
                    <!-- <app-tlumacz [id]="'Cena_razem'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row" style="color: #cd0000">
                    <ng-container *ngIf="row.ValueNetto !== null && row.Currency !== null && row.ValueNetto > 0">
                      {{row.ValueNetto | PriceFormat}}&nbsp;
                      {{row.Currency}}<br />
                      {{row.ValueBrutto | PriceFormat}}&nbsp;
                      {{row.Currency}}
                    </ng-container>
                    <ng-container *ngIf="row.ValueNetto !== null && row.Currency !== null && row.ValueNetto == 0">
                      ---
                    </ng-container>
                  </td>
                </ng-container>

                <!-- DeliveryCost Column -->
                <ng-container matColumnDef="DeliveryCostNetto">
                  <th mat-header-cell *matHeaderCellDef id="cena_dostawy" [translation]="TranslationEnum.Translation">
                    {{'cena_dostawy'|translate}}
                    <!-- <app-tlumacz [id]="'Cena_dostawy'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row" class="cena">
                    <ng-container
                      *ngIf="row.DeliveryCostBrutto !== null && row.Currency !== null && row.DeliveryCostBrutto > 0">
                      {{row.DeliveryCostNetto | PriceFormat}}&nbsp;
                      {{row.Currency}}<br />
                      {{row.DeliveryCostBrutto | PriceFormat}}&nbsp;
                      {{row.Currency}}
                    </ng-container>
                    <ng-container
                      *ngIf="row.DeliveryCostBrutto === null || row.Currency === null || row.DeliveryCostBrutto == 0">
                      ---
                    </ng-container>
                  </td>
                </ng-container>

                <!-- OrderStatusName Column -->
                <ng-container matColumnDef="OrderStatusName">
                  <th mat-header-cell *matHeaderCellDef id="status" [translation]="TranslationEnum.Translation">
                    {{'status'|translate}}
                    <!-- <app-tlumacz [id]="'Status'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.OrderStatusName !== null" id="{{row.OrderStatusName}}"
                      [translation]="TranslationEnum.Translation">
                      {{row.OrderStatusName|translate}}
                      <!-- <app-tlumacz [id]="row.OrderStatusName" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                      <span *ngIf="!row.IsActive" id="nieaktywne" [translation]="TranslationEnum.Translation">
                        <br />
                        {{'nieaktywne'|translate}}
                        <!-- <app-tlumacz [id]="'NieAktywne'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                      </span>
                      <span *ngIf="row.IsTechnologyError" id="technologyerror"
                        [translation]="TranslationEnum.Translation">
                        <br />
                        {{'technologyerror'|translate}}
                        <!-- <app-tlumacz [id]="'TechnologyError'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                      </span>
                      <div *ngIf="row.OrderStatusName == '3'" id="{{row.BlockingConditionName}}" class="napis_mini"
                        [translation]="TranslationEnum.Translation">
                        {{row.BlockingConditionName|translate}}
                        <!-- <app-tlumacz [id]="row.BlockingConditionName" [typ]="'stale'" [tresci]="tresci">
                        </app-tlumacz> -->
                      </div>
                    </span>
                  </td>
                </ng-container>


                <!-- OrderDate Column -->
                <ng-container matColumnDef="OrderDate">
                  <th mat-header-cell *matHeaderCellDef id="data_opracowania"
                    [translation]="TranslationEnum.Translation">
                    {{'data_opracowania'|translate}}
                    <!-- <app-tlumacz [id]="'Data_opracowania'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row"> {{row.OrderDate | date:'yyyy-MM-dd H:mm:ss'}} </td>
                </ng-container>

                <!-- OrderingName Column -->
                <ng-container matColumnDef="OrderingName">
                  <th mat-header-cell *matHeaderCellDef id="kto_opracował" [translation]="TranslationEnum.Translation">
                    {{'kto_opracował'|translate}}
                    <!-- <app-tlumacz [id]="'Kto_opracował'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row"> {{row.OrderingName}} </td>
                </ng-container>

                <!-- Note Column -->
                <ng-container matColumnDef="Note">
                  <th mat-header-cell *matHeaderCellDef id="notatki" class=" w-75p"
                    [translation]="TranslationEnum.Translation">
                    {{'notatki'|translate}}
                    <!-- <app-tlumacz [id]="'Notatki'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <button mat-icon-button color="accent" *ngIf="row.Note !== null && row.Note !== ''"
                      (click)="openOrderNoteDialog(row);">
                      <mat-icon>sticky_note_2</mat-icon>
                    </button>
                  </td>
                </ng-container>


                <!-- Printout Column -->
                <ng-container matColumnDef="Printout">
                  <th mat-header-cell *matHeaderCellDef id="notatki" class=" w-75p"
                    [translation]="TranslationEnum.Translation">
                    {{'wydruk'|translate}}
                    <!-- <app-tlumacz [id]="'wydruk'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <a *ngIf="!row.IsTechnologyError" mat-flat-button id="specyfikacja" class="btn-sm d-block mb-2"
                      [translation]="TranslationEnum.Translation" color="primary"
                      [href]="tresci.server_url + '/api/OrderRegister/GetSpecificationPrintout?guid=' + row.GuidId + '&culture=' + Culture"
                      target="_blank">
                      {{'specyfikacja'|translate}}
                      <!-- <app-tlumacz [id]="'Specyfikacja'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                    </a>
                    <a *ngIf="!row.IsTechnologyError" mat-flat-button id="wycena" class="btn-sm d-block mb-2"
                      [translation]="TranslationEnum.Translation" color="primary"
                      [href]="tresci.server_url + '/api/OrderRegister/GetValuationPrintout?guid=' + row.GuidId + '&culture=' + Culture"
                      target="_blank">
                      {{'wycena'|translate}}
                      <!-- <app-tlumacz [id]="'wycena'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="expandDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: expandDisplayedColumns;" class="child-row"></tr>
              </table>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="element-row"
          [class.expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element;">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row bg-white" *matNoDataRow>
          <td colspan="4" id="brak_danych_do_wyświetlenia" class="mat-cell" [translation]="TranslationEnum.Translation">
            {{'brak_danych_do_wyświetlenia'|translate}}
            <!-- <app-tlumacz [id]="'Brak danych do wyświetlenia'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </td>
        </tr>
      </table>
    </div>

    <!-- <mat-paginator [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100, 200]"
      showFirstLastButtons></mat-paginator> -->
  </div>
</div>
