import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { DeepClone } from './../../../shared/deep-clone/deep-clone';
import { FormControls, Parameter } from './../../../shared/models/form-model/models/form-controlls.model';
import { COMPONENTS } from './../../../shared/components/index';
import { BroadcastService } from './../../../shared/async-services/http/broadcast.service';
import { TranslationEnum } from './../../../shared/enums/translation-enum';
import { PositionModelComponent } from './../../../shared/models/form-model/models/component-edit.model';
import { DialogPosition } from 'src/app/shared/models/dialog/dialog-position.model';
import { ActiveFormType } from 'src/app/shared/models/form-model/models/active-form.model';
import { FormService } from 'src/app/shared/service/form.service';
import { AfterViewChecked, ComponentFactoryResolver, Renderer2, OnDestroy } from '@angular/core';
import { Component, Inject, OnInit, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { distinctUntilChanged, map, takeWhile, tap } from 'rxjs/operators';
import { HtmlControl, Validator } from 'src/app/shared/models/form-model/models/html-control.model';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { DialogPositionResult } from 'src/app/shared/models/dialog/dialog-position-result.model';
import _ from 'lodash';
import { Kim } from 'src/app/shared/models/form-model/kim.model';
import { TechnologiaResultInfoComponent } from 'src/app/technologiaResultInfo/technologiaResultInfo.component';
import { StepOptions } from 'src/app/shared/models/form-model/models/edit-step-options.model';
import { ComponentRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataSharingService } from 'src/app/shared/service/data-sharing.service';
import { KindOper } from 'src/app/shared/enums/kind-oper-enum';
import { TreeTechnologyComponent } from 'src/app/module-tree-technology/tree-technology/tree-technology.component';

@Component({
  selector: 'app-dialog-position',
  templateUrl: './dialog-position.component.html',
  styleUrls: ['./dialog-position.component.scss'],
})
export class DialogPositionComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('moduleForm', { read: ViewContainerRef, static: true }) entryModule: ViewContainerRef;
  
  private currentStep: string;
  private positionToEdit: PositionModelComponent;
  private columnNames: string[];
  private dataTable: Record<any, any> = {};
  private form: FormGroup;
  private controlListForEdit: { step: string; controls: HtmlControl[] }[];
  private broadcastErrorSubscription: Subscription;
  private errorAppeard: boolean;
  private erroHasApperd: boolean;
  private moduleType: string;
  private resultInfo: any;
  private deepClone: DeepClone;
  private isStepEditingSubscription: Subscription;

  container: HTMLElement;

  public TranslationEnum = TranslationEnum;
  public EndFormPosition: boolean;
  public ReturnData: DialogPositionResult;
  public ShowSpinnerVerify = false;

  protected DisabledSavePositionButton: boolean;

  @ViewChild(TechnologiaResultInfoComponent, { static: true }) technology;
  techVersion: number;
  loadPositionForVerify = false;
  errorSettingValue: Subscription;
  stopByErrorSettingValue: boolean;
  ShowTechnologyPermission: boolean;
  multiStepsError: boolean;
  multiStepsForEdit: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<DynamicFormComponent>,
    private resolver: ComponentFactoryResolver,
    private render: Renderer2,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: DialogPosition,
    private broadcastService: BroadcastService,
    private dataSharingService: DataSharingService,
    private fb: FormBuilder,
    private authorizationService: AuthorizationService,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({});
    this.positionToEdit = new PositionModelComponent();
    this.ReturnData = new DialogPositionResult();
    this.columnNames = new Array<string>();
    this.controlListForEdit = new Array<{ step: string; controls: HtmlControl[] }>();

    this.deepClone = new DeepClone(fb);
  }

  ngOnInit(): void {
    this.currentStep = this.data.CurrentStep;
    this.multiStepsForEdit = this.data.MultiStepsForEdit;
    if (this.data.KindOper === KindOper.Edit) {
      this.editPosition();
    } else if (this.data.KindOper === KindOper.Verify) {
      this.form = _.cloneDeep(this.data.Form);

      this.loadPositionForVerify = true;
      this.ShowSpinnerVerify = true;
      for (const control of this.data.ParametersForEdit) {
        this.form.removeControl(control.Key);
      }
      this.verifyPosition(this.form, this.data.PreviousPosition);
    } else {
      this.form = _.cloneDeep(this.data.Form);
      this.controlListForEdit.push({ step: this.currentStep, controls: this.data.Controls });
      if (this.data.PositionId === 1 || this.data.ManageModule.IsModule === false) {
        if (this.data.MultiStepsForEdit) {

          this.getMultiSteps(this.form);
        } else {
          this.moveToNextStep(this.form);
        }
      } else {
        if (this.data.MultiStepsForEdit) {
          this.getMultiStepsWithPreviousPosition(this.form, this.data.PreviousPosition);
        } else {
          this.moveToNextStepWithPosition(this.form, this.data.PreviousPosition,this.data.PositionId, this.data.Module); 
        }
      }
    }
    this.broadcastErrorSubscription = this.broadcastService.ErrorHttp.asObservable().subscribe((x) => {
      if (x !== null) {
        this.data.IsOpenByEdit = false;
        this.errorAppeard = true;
        this.erroHasApperd = true;
        this.dialogRef._containerInstance._config.backdropClass = 'cdk-overlay-dark-backdrop';
        this.dialogRef.removePanelClass('invisible');
        // this.dialogRef.disableClose = true;
      }
    });

    this.isStepEditingSubscription = this.dataSharingService.IsStepEditing.asObservable()
      .pipe(distinctUntilChanged())
      .subscribe((x) => {
        this.DisabledSavePositionButton = x;
      });

      this.errorSettingValue = this.dataSharingService.ErrorSettingValue.asObservable().subscribe((x) => {
      if (x !== '') {
        // this.modalAlertRequest(this.currentStep, x);
        this.data.IsOpenByEdit = false;
        this.stopByErrorSettingValue = true;
        this.ShowSpinnerVerify = false;
        this.dialogRef._containerInstance._config.backdropClass = 'cdk-overlay-dark-backdrop';
        this.dialogRef.removePanelClass('invisible');
      }
    });

    this.subscriptions.push(this.authorizationService.ShowTechnologyPermission.subscribe((x) => {
      this.ShowTechnologyPermission = x;
    }));
  }

  private editPosition() {
    this.positionToEdit = new PositionModelComponent();
    // this.positionToEdit = _.cloneDeep(this.data.PositionToEdit);
    this.positionToEdit = {
      Components: this.data.PositionToEdit.Components,
      Form: this.data.PositionToEdit.Form,
      Module: this.data.PositionToEdit.Module,
      ModuleType: this.data.PositionToEdit.ModuleType,
      PositionId: this.data.PositionToEdit.PositionId,
      Type: this.data.PositionToEdit.Type,
    };

    this.data.ParametersForEdit = [];

    this.form = this.deepClone.DeepCloneForm(this.data.PositionToEdit.Form);
    this.positionToEdit.Form = this.form;
    this.data.ParametersForEdit = this.deepClone.ParametersToEditForPostion;

    for (const component of this.positionToEdit.Components) {
      const factory = this.resolver.resolveComponentFactory(DynamicFormComponent);
      const componentRef = this.entryModule.createComponent(factory);
      let cloneComponentHtmlControls = JSON.parse(JSON.stringify(component.instance.HtmlControls));
      this.controlListForEdit.push({ step: component.instance.CurrentStep, controls: cloneComponentHtmlControls });
      this.currentStep = component.instance.CurrentStep;
      cloneComponentHtmlControls = this.setValuesForControlls(cloneComponentHtmlControls);
      this.setComponentRefInstances(componentRef, cloneComponentHtmlControls, this.form, this.data.PositionId, true);
    }
    this.createColumnNames();
  }

  closeDialog() {
    // this.form = new FormGroup({});
    this.dialogRef.close();
  }

  bindValidations(validations: Validator[]): ValidatorFn | null {
    if (validations.length > 0) {
      const validList: ValidatorFn[] = [];
      validations.forEach((valid) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  ngAfterViewInit(): void {
    // this.scroll.nativeElement.
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    this.broadcastErrorSubscription.unsubscribe();
    this.isStepEditingSubscription.unsubscribe();
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngAfterViewChecked() {}

  private createPosition() {
    const factory = this.resolver.resolveComponentFactory(DynamicFormComponent);
    const componentRef = this.entryModule.createComponent(factory);
    this.setComponentRefInstances(componentRef, this.data.Controls, this.form, this.data.PositionId, false);
    this.addPositionComponentToList(componentRef);
  }

  protected showTechnologyValuation() {
    this.dialog.open(TreeTechnologyComponent, {
      width: '1650px',
      maxWidth: '90%',
      disableClose: true,
      data: {
        FormGroupForResultInfo: this.form,
        StepForResultInfo: this.currentStep,
        IsValuation: true,
      },
    });
  }

  private setComponentRefInstances(componentRef: ComponentRef<DynamicFormComponent>, controls: HtmlControl[], form: FormGroup, position: number, editPosition: boolean): void {
    componentRef.instance.HtmlControls = controls;
    componentRef.instance.Form = form;
    componentRef.instance.Position = position;
    componentRef.instance.Type = ActiveFormType.Position;
    componentRef.instance.Module = this.data.Module;
    componentRef.instance.KindOper = this.data.KindOper;
    componentRef.instance.IsLoadByEdit = this.data.IsOpenByEdit;
    componentRef.instance.TechVersion = this.techVersion;
    componentRef.instance.ErrorAppeard = this.errorAppeard;
    componentRef.instance.VerifyPositionAndSet = this.loadPositionForVerify;
    componentRef.instance.MultiStepsForEdit = this.data.MultiStepsForEdit;
    componentRef.instance.IsEditPosition = editPosition;
    this.subscriptions.push(componentRef.instance.MoveToNextStep.subscribe((event: any) => {
      // this.form = _.cloneDeep(event.FormGroup);
      this.moveToNextStepOrEdit(event.FormGroup, event.StepOptions);
      // this.errorAppeard = false;
    }));
  }

  private addPositionComponentToList(componentRef: any) {
    this.positionToEdit.PositionId = this.data.PositionId;
    this.positionToEdit.Type = 2;
    this.positionToEdit.Module = this.data.Module;
    this.positionToEdit.ModuleType = ActiveFormType[ActiveFormType.Position];
    this.positionToEdit.Components.push(componentRef);
    this.positionToEdit.Form = this.form; // _.cloneDeep(this.form);
  }

  private getMultiStepsWithPreviousPosition(form: FormGroup, previousPosition: PositionModelComponent) {
    this.subscriptions.push(this.formService
      .getMultiStepsPositionWithPreviousPosition(form, this.data.ParametersForEdit, previousPosition)
      .pipe( 
        tap((technologyForm) => {
          // this.data.IsOpenByEdit = false;
          let i = 0;
          for (const step of technologyForm.Steps) {
            i++;
            if (this.multiStepsError === true) {
              break;
            }

            let isLastStepInSpecificType = false;
            if (i === technologyForm.Steps.length) {
              isLastStepInSpecificType = true;
              this.currentStep = step.Name;
              this.ReturnData.LastStep = step.Name;
            }
            this.data.Controls = this.formService.mapControlsToHtml(step.Controlls, step.Name, isLastStepInSpecificType);
            this.data.Controls = this.setValuesForControlls(this.data.Controls);
            this.currentStep = step.Name;
            this.controlListForEdit.push({ step: step.Name, controls: this.data.Controls });
            this.createPosition();
          }
        })
      )
      .subscribe());
  }

  private getMultiSteps(form: FormGroup) {
    this.subscriptions.push(this.formService
      .getMultiSteps(form, this.data.ParametersForEdit, ActiveFormType[ActiveFormType.Position])
      .pipe(
        tap((technologyForm) => {
          // this.data.IsOpenByEdit = false;
          let i = 0;
          for (const step of technologyForm.Steps) {
            i++;
            if (this.multiStepsError === true) {
              break;
            }

            let isLastStepInSpecificType = false;
            if (i === technologyForm.Steps.length) {
              isLastStepInSpecificType = true;
              this.currentStep = step.Name;
              this.ReturnData.LastStep = step.Name;
            }
            this.data.Controls = this.formService.mapControlsToHtml(step.Controlls, step.Name, isLastStepInSpecificType);
            this.data.Controls = this.setValuesForControlls(this.data.Controls);
            this.currentStep = step.Name;
            this.controlListForEdit.push({ step: step.Name, controls: this.data.Controls });
            this.createPosition();
          }
        })
      )
      .subscribe());
  }

  private moveToNextStepOrEdit(form: FormGroup, editStepOptions: StepOptions): void {
    if (this.stopByErrorSettingValue) {
      if (this.loadPositionForVerify === false) {
        this.data.MultiStepsForEdit = false;
        this.data.IsOpenByEdit = true;
      } else {
        this.data.IsOpenByEdit = false;
        this.ShowSpinnerVerify = true;
      }

      this.stopByErrorSettingValue = false;
      this.dataSharingService.ErrorSettingValue.next('');
    }
    // console.log('form, edit', form, editStepOptions);
    if (editStepOptions !== null && editStepOptions.IsMarkAsToEdit && editStepOptions.DeleteRestComponents) {
      this.editCurrentStep(editStepOptions);
      this.errorAppeard = false;
      if (this.data.PositionId === 1 || this.data.ManageModule.IsModule === false) {
        this.moveToNextStep(form);
      } else {
        this.moveToNextStepWithPosition(form, this.data.PreviousPosition,this.data.PositionId, this.data.Module);
      }
    } else if (editStepOptions === null) {
      // Tu było move to next step z subskrypcją
      if (this.data.PositionId === 1 || this.data.ManageModule.IsModule === false) {
        this.moveToNextStep(form);
      } else {
        this.moveToNextStepWithPosition(form, this.data.PreviousPosition,this.data.PositionId, this.data.Module);
      }
    } else {
      this.ReturnData.LastStep = this.currentStep;
    }
  }

  private createColumnNames(closedFromHtml?: boolean) {
    this.columnNames.push('pos');
    if (this.form.controls['Moduł'] !== undefined && this.form.controls['Moduł'].value.inputType !== 'hidden') {
      this.columnNames.push('modul_klucz');
    }
    this.dataTable['pos'] = this.data.PositionId;
    Object.keys(this.form.controls).forEach((key) => {
      if (this.form.controls[key].value !== null && this.form.controls[key].value.displayInTablePosition) {
        this.columnNames.push(this.form.controls[key].value.name.toLowerCase());
        if (key.toLowerCase() === 'moduł') {
          this.dataTable['modul_klucz'] = this.form.controls[key].value.value.Key;
        }
        if (this.form.controls[key].value.inputType === 'select') {
          this.dataTable[this.form.controls[key].value.name.toLowerCase()] = this.form.controls[key].value.value.DisplayValue;
        } else if (this.form.controls[key].value.inputType === 'text') {
          this.dataTable[this.form.controls[key].value.name.toLowerCase()] = this.form.controls[key].value.value;
        } else if (this.form.controls[key].value.inputType === 'number') {
          this.dataTable[this.form.controls[key].value.name.toLowerCase()] = this.form.controls[key].value.value;
        } else if (this.form.controls[key].value.inputType === 'kim') {
          this.dataTable[this.form.controls[key].value.name.toLowerCase()] = this.form.controls[key].value.value.DisplayValue;
        } else if (this.form.controls[key].value.inputType === 'checkselect') {
          if (this.form.controls[key].value.value === '') {
            this.dataTable[this.form.controls[key].value.name.toLowerCase()] = this.form.controls[key].value.value;
          } else {
            this.dataTable[this.form.controls[key].value.name.toLowerCase()] = this.form.controls[key].value.value.DisplayValue;
          }
        } else if (this.form.controls[key].value.inputType === 'check') {
          this.dataTable[this.form.controls[key].value.name.toLowerCase()] = this.form.controls[key].value.value.DisplayValue;
        } else if (this.form.controls[key].value.inputType === 'checknumber') {
          this.dataTable[this.form.controls[key].value.name.toLowerCase()] = this.form.controls[key].value.value;
        } else if (this.form.controls[key].value.inputType === 'hidden') {
          this.dataTable[this.form.controls[key].value.name.toLowerCase()] = this.form.controls[key].value.value.DisplayValue;
        }
      }
    });
    this.ReturnData.PositionsTab.Controls = this.dataTable;
    this.ReturnData.PositionsTab.ColumnNames = this.columnNames;
    this.ReturnData.PositionToEdit = this.positionToEdit;
    if (closedFromHtml === true) {
      // console.log('this.ReturnData', this.ReturnData);
      this.dialogRef.close(this.ReturnData);
    }
  }

  private verifyPosition(form: FormGroup, previousPositionForVerify?: PositionModelComponent): void {
    this.subscriptions.push(this.formService
      .verifyPosition(form, this.currentStep, previousPositionForVerify)
      .pipe(
        tap((technologyForm) => {
          this.createNextStep(technologyForm);
        })
      )
      .subscribe());
  }

  private moveToNextStep(form: FormGroup) {
    this.subscriptions.push(this.formService
      .moveToNextStep(form, this.currentStep)
      .pipe(
        tap((technologyForm) => {
          this.createNextStep(technologyForm);
        })
      )
      .subscribe());
  }

  moveToNextStepWithPosition(form: FormGroup, previousPosition: PositionModelComponent,PositionId, Module) {
    this.subscriptions.push(this.formService
      .moveToNextStepWithPosition(form, this.currentStep, previousPosition, PositionId, Module)
      .pipe(
        tap((technologyForm) => {
          this.createNextStep(technologyForm);
        })
      )
      .subscribe());
  }

  createNextStep(technologyForm: FormControls) {
    const stepToSend = this.currentStep;
    if (technologyForm.Step !== '') {
      this.currentStep = technologyForm.Step;

      this.techVersion = technologyForm.TechVersion;
      const controls = this.formService.mapControlsToHtml(technologyForm.Controlls, technologyForm.Step);
      let indexOfStepOnEditList = -1;
      if (this.controlListForEdit.find((x) => x.step === technologyForm.Step) === undefined) {
        this.controlListForEdit.push({ step: technologyForm.Step, controls: controls });
      } else {
        indexOfStepOnEditList = this.controlListForEdit.findIndex((x) => x.step === technologyForm.Step);
      }
      if (this.data.IsOpenByEdit || this.loadPositionForVerify || this.data.MultiStepsForEdit) {
        if (technologyForm.Type === ActiveFormType[ActiveFormType.Position]) {
          this.data.Controls = this.setValuesForControlls(controls);
        }
      } else {
        this.data.Controls = this.loadValueForControllsIfStepEdit(controls, technologyForm.Step);
        if (indexOfStepOnEditList !== -1) {
          this.controlListForEdit.splice(indexOfStepOnEditList, 1, { step: technologyForm.Step, controls: this.data.Controls });
        }
      }
      this.ReturnData.ModuleType = technologyForm.Type;
      this.ReturnData.LastStep = stepToSend; //technologyForm.Step;
      if (technologyForm.Type === ActiveFormType[ActiveFormType.Position]) {
        this.moduleType = technologyForm.Type;
        this.createPosition();
      } else if (this.data.IsOpenByEdit || this.loadPositionForVerify || this.data.MultiStepsForEdit) {
        this.createColumnNames();
        this.EndFormPosition = true;
        this.dialogRef.close(this.ReturnData);
      } else {
        // this.createColumnNames();
        this.EndFormPosition = true;
      }
    } else {
      this.EndFormPosition = true;
      this.ReturnData.LastStep = stepToSend;
      if (this.data.IsOpenByEdit || this.loadPositionForVerify || this.data.MultiStepsForEdit) {
        this.createColumnNames();
        this.dialogRef.close(this.ReturnData);
      }
    }
  }

  private editCurrentStep(editStepOptions: StepOptions) {
    const tempListForEdit = Array.from(this.controlListForEdit);
    const index = tempListForEdit.findIndex((x) => x.step === editStepOptions.StepForEdit);
    tempListForEdit.splice(0, index + 1);
    const tempListWithControlNames = new Array<string>();
    const tempListWithControlHeaderNames = new Array<string>();

    for (const headerControl of Object.keys(this.data.HeaderForm.controls)) {
      tempListWithControlHeaderNames.push(headerControl);
    }

    for (const listOfSteps of tempListForEdit) {
      for (const controlForStep of listOfSteps.controls) {
        tempListWithControlNames.push(controlForStep.name);
      }
    }

    const component = this.positionToEdit.Components.find((cmp) => cmp.instance.CurrentStep === editStepOptions.StepForEdit);
    const componentIndex = this.positionToEdit.Components.indexOf(component) + 1;

    Object.keys(this.form.controls).forEach((key) => {
      const controlInNextSteps = tempListWithControlNames.find((x) => x === key);
      const isHeaderControl = tempListWithControlHeaderNames.find((x) => x === key);
      if (controlInNextSteps === key && controlInNextSteps !== isHeaderControl) {
        this.form.removeControl(key);
        if (this.entryModule.get(componentIndex)) {
          this.entryModule.remove(componentIndex);
        }
      }
    });

    this.positionToEdit.Components.splice(componentIndex);
    this.EndFormPosition = false;
    this.dataTable = {};
    this.columnNames = [];
    this.data.KindOper = KindOper.Add;
    this.currentStep = editStepOptions.StepForEdit;
  }

  private loadValueForControllsIfStepEdit(htmlControls: HtmlControl[], step: string) {
    const controls = this.controlListForEdit.find((x) => x.step === step).controls;
    for (const htmlCtrl of htmlControls) {
      for (const control of controls) {
        if (htmlCtrl.name === control.name) {
          if (htmlCtrl.inputType !== 'hidden' && htmlCtrl.inputType !== 'kim' && htmlCtrl.inputType !== 'label' && htmlCtrl.inputType !== 'selectmulticolumn' && htmlCtrl.inputType !== 'checkselectmulticolumn') {
            if (htmlCtrl.options !== undefined && htmlCtrl.options.length !== 0 && control.value !== null) {
              if (htmlCtrl.options.find((x) => x.Key === control.value.Key) !== undefined) {
                htmlCtrl.value = htmlCtrl.options.find((x) => x.Key === control.value.Key);
              }
            } else if (htmlCtrl.optionsSelectMultiColumn !== undefined && htmlCtrl.optionsSelectMultiColumn?.length !== 0 && htmlCtrl.componentName !== 'Hidden') {
              htmlCtrl.value = control.optionsSelectMultiColumn.find((x: { Key: any }) => x.Key === control.value);
            } else {
              htmlCtrl.value = control.value;
            }
          } else if (htmlCtrl.inputType !== 'label') {
            htmlCtrl.value = control.value;
          }
        }
      }
    }

    return htmlControls;
  }

  private setValuesForControlls(htmlControlls: HtmlControl[]) {
    for (const control of htmlControlls) {
      const parameter = this.data.ParametersForEdit.find((x) => x.Key.toLowerCase() === control.name.toLowerCase());
      if (parameter !== undefined) {
        if (control.inputType !== 'hidden' && control.inputType !== 'label') {
          if (control.options !== undefined && control.options?.length !== 0) {
            const value = control.options.find((x) => x.Key === parameter.Value);
            control.value = value === undefined ? '' : value;
            if (this.multiStepsForEdit && control.value === '') {
              this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
              this.multiStepsError = true;
            }
          } else if (control.optionsSelectMultiColumn !== undefined && control.optionsSelectMultiColumn?.length !== 0) {
            const value = control.optionsSelectMultiColumn.find((x: { Key: any }) => x.Key === parameter.Value);
            control.value = value === undefined ? '' : value;
            if (this.multiStepsForEdit && control.value === '') {
              this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
              this.multiStepsError = true;
            }
          } else if (control.parameters !== undefined && control.parameters.length !== 0) {
            if (
              parameter.Value !== '' &&
              control.parameters.length !== 0 &&
              +control.parameters.find((x) => x.Key.toLowerCase() === 'min').Value <= +parameter.Value &&
              +parameter.Value <= +control.parameters.find((x) => x.Key.toLowerCase() === 'max').Value
            ) {
              control.value = parameter.Value;
            } else {
              control.value = '';
              if (!control.inputType.startsWith('check') && this.multiStepsForEdit && control.value === '') {
                this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
                this.multiStepsError = true;
              }
            }
          } else {
            control.value = parameter.Value;
          }
        }
      } else if (control.inputType !== 'hidden' && control.inputType !== 'label' && control.inputType !== 'kim') {
        // console.log('control', control)
        if (control.inputType.startsWith('check') && control.value !== '' && control.options !== undefined && control.options?.length !== 0) {
          const value = control.options.find((x) => x.Key === control.value);
          control.value = value === undefined ? '' : value;
        } else if (!control.inputType.startsWith('check')) {
          control.value = '';
          if (this.multiStepsForEdit && control.value === '' && control.inputType !== 'newline') {
            this.dataSharingService.ErrorSettingValue.next('blad_ust_wartosci');
            this.multiStepsError = true;
          }
        }
      }

      if (control.inputType === 'kim' && this.data.KimForEdit !== undefined && this.data.KimForEdit.length > 0) {
        const paramKim: Kim[] = [];
        for (const kim of this.data.KimForEdit) {
          paramKim.push(kim);
        }
        control.value = paramKim;
      }
    }

    return htmlControlls;
  }
}
