
import { Kroki } from './kroki';
import { Parametry } from './parametry';
export class Pozycja {
  public pozycja: Kroki;
  public edytowana = false;
  public poprawny = false;
  public kopiowana = false;
  public usun = false;
  constructor(tresci, pozycja, modul, FirstStep, ParametryPrzed, PreviewParameters = [], wczytujPierwszy = true) {
    this.pozycja = new Kroki(tresci, pozycja, modul, FirstStep, ParametryPrzed, PreviewParameters);
    if (wczytujPierwszy) {
      this.pozycja.wczytajKrok(0);
    }
  }
  public Wartosc(Key) {
    return this.pozycja.Wartosc(Key);
  }
  public WartoscKod(Key) {
    return this.pozycja.WartoscKod(Key);
  }
}

