import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-maintenance',
  templateUrl: './module-maintenance.component.html',
  styleUrls: ['./module-maintenance.component.scss']
})
export class ModuleMaintenanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
