import { Component, Input, OnInit } from '@angular/core';
import { TranslationEnum } from '../../shared/enums/translation-enum';
import { FormDataV2 } from '../form-model/FormDataV2';
import { DeliveryAddress } from 'src/app/shared/models/form/delivery-address.model';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { FormV2ServiceService } from '../form-v2-service.service';
import { Complaint } from 'src/app/shared/models/reklamacje/Complaint.model';
import { ErrorState } from 'src/app/shared/validations/error-class';
import { MatDialog } from '@angular/material/dialog';
import { AddAdressComponent } from './add-adress/add-adress.component';
import { Contractor } from 'src/app/shared/models/user/contractor.model';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { PermissionEnum } from 'src/app/shared/enums/permission-enum';

@Component({
  selector: 'app-form-delivery',
  templateUrl: './form-delivery.component.html',
  styleUrls: ['./form-delivery.component.scss']
})
export class FormDeliveryComponent implements OnInit {

  @Input() FormData: FormDataV2;
  public TranslationEnum = TranslationEnum;
  public deliveryAddressControl:FormControl;
  public complaintsControl:FormControl;
  public complaintsControlTyp:FormControl;
  complaint_check:boolean = false;
  matcher = new ErrorState();
  contractor: Contractor = new Contractor();
  CreateComplaintOrder: boolean = false;


  constructor(public translateService: TranslateService, private authorizationService: AuthorizationService, private formService: FormV2ServiceService,public dialog: MatDialog) {
    var CreateCon = this.authorizationService.hasPermission(PermissionEnum.CreateComplaintOrder);
    // console.log('CreateComplaintOrder', CreateCon);
    if(CreateCon != null){
      this.CreateComplaintOrder = true;
    } else {
      this.CreateComplaintOrder = false;
    }
  }

  
  
  ngOnInit(): void {
    this.contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
    // console.log("cont", this.contractor); 
    if(window['culture'] != null && window['culture'] != ''){
      this.translateService.currentLang = window['culture']; 
    }
    // console.log(this.FormData.delivery.AdressEdit);
    this.deliveryAddressControl = new FormControl({ value: '', disabled: !this.FormData.delivery.AdressEdit }, [
      Validators.required
    ]);
    this.complaintsControl = new FormControl({ value: '', disabled: !this.FormData.delivery.AdressEdit }, [
      Validators.required
    ]);
    this.complaintsControlTyp = new FormControl({ value: '', disabled: !this.FormData.delivery.AdressEdit }, [
      Validators.required
    ]);

    // console.log("dd", this.FormData.delivery);
    if( this.FormData.delivery.ComplaintType != null){
      this.complaint_check = true;
      this.zmien_check(this.FormData.delivery.ComplaintType);

    } else {
      this.complaint_check = false;
    }
    /* // console.log("del-in", this.FormData.delivery);
    if(this.FormData.delivery.ChosenAdresses.OtherTransportThenSelt && this.FormData.delivery.DeliveryMethod.Id == 3){
      // console.log("del-in2", this.FormData.delivery);
      this.FormData.delivery.ChosenAdresses.Id = 0;
    } */
  }
  public zmienAdres(adres){
    //if(this.delivery.czyAdres){
        //// console.log(adres);
        this.FormData.delivery.ChosenAdresses = adres;
    //}
}
public zmienReklamacje(reklamacja){
  //if(this.delivery.czyAdres){
      // console.log("rekl", reklamacja);
      this.FormData.delivery.ChosenComplaint = reklamacja;
  //}
}
public ZmienDelivery(numer){
  // console.log("dm",this.FormData.delivery.DeliveryMethods, numer);
  if(!this.FormData.delivery.DeliveryMethod.IsDeliveryAddressRequired && this.FormData.delivery.DeliveryMethods[numer].IsDeliveryAddressRequired){
    this.FormData.delivery.ChosenAdresses = new DeliveryAddress();
  }
  this.FormData.delivery.DeliveryMethod = this.FormData.delivery.DeliveryMethods[numer];
  if(numer == 1 && this.FormData.delivery != null && this.FormData.delivery.ChosenAdresses != null && this.FormData.delivery.ChosenAdresses.OtherTransportThenSelt ){
    this.FormData.delivery.ChosenAdresses = null;
  }
}

next(tresci, formService, adres){
  this.FormData.supervisor.auto = 0;
  this.FormData.supervisor.wczytywanieAuto = false;
  this.FormData.next(tresci, formService, adres, false, this.dialog);
}

  zmien_check(event){
    //// console.log(event, this.reklamacja_check);

    this.complaintsControlTyp = new FormControl({ value: '', disabled: !this.FormData.delivery.AdressEdit }, [
      Validators.required
    ]);

    // console.log("typ", event, this.FormData.delivery.AdressEdit);
    this.FormData.delivery.complaint_check = this.complaint_check;
    if(this.complaint_check && this.FormData.delivery.ComplaintTypes.length == 0) {
      
      this.getComplaintsTypes();
      
    } if(this.complaint_check) {
      
      this.getComplaintsTypes2();
      
    } else if(!this.complaint_check){
      this.FormData.delivery.ComplaintType = null;
      this.FormData.delivery.ChosenComplaint = null;
    }
  }
  zmien_TypReklamacji(event){
    // console.log("con typ", event);
    this.FormData.delivery.complaint_check = this.complaint_check;
    this.FormData.delivery.ChosenComplaint = null;
    this.FormData.delivery.ComplaintType = event;

    this.complaintsControl = new FormControl({ value: '', disabled: !this.FormData.delivery.AdressEdit }, [
      Validators.required
    ]);

    this.getComplaints(event.Id);
    
  }
  zmien_TypReklamacjiInit2(event){
    this.getComplaints2(event.Id);
  }
  zmien_TypReklamacjiInit(event){
    // console.log("con typ2", event);
    this.FormData.delivery.complaint_check = this.complaint_check;
    this.FormData.delivery.ComplaintType = event;

    this.complaintsControl = new FormControl({ value: '', disabled: !this.FormData.delivery.AdressEdit }, [
      Validators.required
    ]);

    this.getComplaints(event.Id);
    
  }
  getComplaintsTypes(){
    this.formService.GetComplaintTypes().subscribe(
      (complaints) => {
        this.FormData.delivery.ComplaintTypes = [];
        complaints.forEach(element => {
          this.FormData.delivery.ComplaintTypes.push(element);
          if(this.FormData.delivery.ComplaintType != null && element.Id == this.FormData.delivery.ComplaintType.Id){
            this.FormData.delivery.ComplaintType = element;
          }
        });
        // console.log("tt", this.FormData.delivery);
        if(this.FormData.delivery.ComplaintType != null){
          this.complaintsControlTyp = new FormControl({ value: this.FormData.delivery.ComplaintType, disabled: !this.FormData.delivery.AdressEdit }, [
            Validators.required
          ]);
         this. zmien_TypReklamacjiInit(this.FormData.delivery.ComplaintType);
        }
        
      }
    );
    
  }
  getComplaintsTypes2(){
   
        if(this.FormData.delivery.ComplaintType != null){
          this.complaintsControlTyp = new FormControl({ value: this.FormData.delivery.ComplaintType, disabled: !this.FormData.delivery.AdressEdit }, [
            Validators.required
          ]);
         this. zmien_TypReklamacjiInit2(this.FormData.delivery.ComplaintType);
        }
        
      
    
  }
  getComplaints2(id:Number){
    if(this.FormData.delivery.ChosenComplaint != null){
      this.complaintsControl = new FormControl({ value: this.FormData.delivery.ChosenComplaint, disabled: !this.FormData.delivery.AdressEdit }, [
        Validators.required
      ]);
    }
  }
  getComplaints(id:Number){
    this.formService.GetComplaintActiveFrom(id).subscribe(
      (complaints) => {
        // console.log(complaints);
          this.FormData.delivery.complaints = [];
        complaints.forEach(element => {
          this.FormData.delivery.complaints.push(element);
          if(this.FormData.delivery.ChosenComplaint != null && this.FormData.delivery.ChosenComplaint.Id == element.Id){
            this.FormData.delivery.ChosenComplaint = element;
          }
        });
        if(this.FormData.delivery.ChosenComplaint != null){
          this.complaintsControl = new FormControl({ value: this.FormData.delivery.ChosenComplaint, disabled: !this.FormData.delivery.AdressEdit }, [
            Validators.required
          ]);
        }
      }
    );
    
    /*
    var x = new Complaint();
    x.ComplaintNumber = "NUMER REKLAMACJI";
    x.B2BNumber = "B2B/test/123456789";
    x.ProductionNumber = "MA1024000001";
    this.FormData.delivery.complaints.push(x);*/
  }
  otworzAdres(){

    const data = {
      ContractorAddresses: this.FormData.delivery.ContractorAddresses
    };

    var dialogRef = this.dialog.open(AddAdressComponent, {
      maxWidth: Math.round(window.innerWidth * 0.6569),
      // maxHeight: Math.round(window.innerHeight * 0.9595),
      width: '100%',
      // height: '100%',
      disableClose: true,
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.formService.getDeliveryAddresses().
      subscribe((data) => {

        this.FormData.delivery.ContractorAddresses = data.ContractorAddresses;
        
        
      })
    });
  }

}
