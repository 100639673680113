import { SurchargesComponent } from '../surchargers/surcharges.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TechnologiaResultInfoComponent } from './../../technologiaResultInfo/technologiaResultInfo.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialFormRoutingModule } from './initial-form-routing.module';
import { InitialFormComponent } from './initial-form.component';
import { MaterialModule } from 'src/app/material/material.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomTranslateLoader } from 'src/app/shared/language-loader/CustomTranslateLoader';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { DeliveryModule } from '../delivery/delivery.module';


@NgModule({
  declarations: [
    InitialFormComponent,
    TechnologiaResultInfoComponent,
    SurchargesComponent
  ],
  imports: [
    CommonModule,
    InitialFormRoutingModule,
    MaterialModule,
    NgbAlertModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader, useClass: CustomTranslateLoader
      }
    }),
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    DeliveryModule,
  ]

})
export class InitialFormModule { }
