import { HttpClient, HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';
import { Uwagi } from './uwagi';
import { deserialize, serialize } from 'class-transformer';

export class ModuleContainer {
  public dane = [];
  constructor(Matrix) {
    this.dane = Matrix;
  }

}
export class Module {
  public Min;
  public Max;
  public Value;
  public Value_slider;
  public step = 5;
  public Slider = false;
  public KeyName;
  public uwagi: Uwagi;
  public Typ;
  public module_array = [];
  public wybrany = false;
  public matrix = [];
  public ilosc= 0;
    constructor(private id, uwagi, KeyName, Typ, private tresci, private wartosc, private numer_modulu) {
      this.uwagi = uwagi;
      this.KeyName = KeyName;
      this.Typ = Typ;
      try {
        let tmp;
        if (this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName) !== null) {
          wartosc = this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName);
        }
        tmp = deserialize(ModuleContainer, wartosc);
        this.wartosc = tmp.dane;
      } catch (e) {
        this.wartosc = [];
      }


      for (let i = 0; i < 20; i++) {
        const tmp = [];
        for (let j = 0; j < 20; j++) {
          tmp.push(false);
        }
        this.module_array.push(tmp);
      }

      for (let i = 0; i < this.wartosc.length; i++) {
        for (let j = 0; j < this.wartosc[i].length; j++) {
          this.module_array[j][i] = this.wartosc[i][j];
        }
      }

      this.aktualizujMatrix();
      //if (this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName) !== null) {
        // this.ustaw(this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName));
        //this.Value = parseInt(this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName), 10);
      //}

    }
    public aktualizujMatrix() {
      this.matrix = [];
      this.wybrany = false;
      for (let i = 0; i < this.getY() && i < 20; i++) {
        const tmp = [];
        for (let j = 0; j < this.getX() && j < 20; j++) {
          tmp.push(this.module_array[j][i]);
          if (this.module_array[j][i]) {
            this.wybrany = true;
          }
        }
        this.matrix.push(tmp);
      }



    }
    public poza() {
    }

    public getActual() {
      this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, serialize(new ModuleContainer(this.matrix)));
      this.zapisz_potrzebne();
      return serialize(new ModuleContainer(this.matrix));
    }

    public zapisz_potrzebne() {
      let licznik = 0;
      for (let i = 0; i < this.matrix.length; i++) {
        for (let j = 0; j < this.matrix[i].length; j++) {
          if (this.matrix[i][j]) {
            this.tresci.zamowienie.parametry_wybrane.add(-1, 'Modul_rodzaj_' + this.numer_modulu + '_' + licznik, this.getRodzaj(i, j) );
            licznik++;
          }
        }
      }
    }
    public getDisplay() {
      let licznik = 0;
      let tmp = '';
      for (let i = 0; i < this.matrix.length; i++) {
        for (let j = 0; j < this.matrix[i].length; j++) {
          if (this.matrix[i][j]) {
            tmp += 'M' + (licznik + 1) + '=' + this.getRodzaj(i, j) + ', ';
            licznik++;
          }
        }
      }
      return tmp;
    }
    public czySasiad(y, x){
      let gora = false;
      let prawo = false;
      let dol = false;
      let lewo = false;
      if (x > 0 && this.matrix[y][x - 1]) {
        lewo = true;
      }
      if (y > 0 && this.matrix[y - 1][x]) {
        gora = true;
      }
      if (this.matrix[y][x + 1]) {
        prawo = true;
      }
      if (this.matrix.length > y+2 && this.matrix[y + 1][x]) {
        dol = true;
      }
      if(this.ilosc == 0 || gora || prawo || dol || lewo){
        return true;
      } else {
        return false;
      }
    }
    public getRodzaj(y, x) {
      let gora = false;
      let prawo = false;
      let dol = false;
      let lewo = false;
      if (x > 0 && this.matrix[y][x - 1]) {
        lewo = true;
      }
      if (y > 0 && this.matrix[y - 1][x]) {
        gora = true;
      }
      if (this.matrix[y][x + 1]) {
        prawo = true;
      }
      if (this.matrix.length > y+2 && this.matrix[y + 1][x]) {
        dol = true;
      }
      if (!gora && !prawo && !dol && !lewo) {
        return '11';
      } else if (!gora && !prawo && dol && !lewo) {
        return 'G11';
      } else if (gora && !prawo && dol && !lewo) {
        return 'S11';
      } else if (gora && !prawo && !dol && !lewo) {
        return 'D11';
      } else if (!gora && prawo && !dol && !lewo) {
        return '1P';
      } else if (!gora && prawo && !dol && lewo) {
        return 'PP';
      } else if (!gora && !prawo && !dol && lewo) {
        return 'P1';
      } else if (!gora && prawo && dol && !lewo) {
        return 'G1P';
      } else if (!gora && prawo && dol && lewo) {
        return 'GPP';
      } else if (!gora && !prawo && dol && lewo) {
        return 'GP1';
      } else if (gora && prawo && dol && !lewo) {
        return 'S1P';
      } else if (gora && prawo && dol && lewo) {
        return 'SPP';
      } else if (gora && !prawo && dol && lewo) {
        return 'SP1';
      } else if (gora && prawo && !dol && !lewo) {
        return 'D1P';
      } else if (gora && prawo && !dol && lewo) {
        return 'DPP';
      } else if (gora && !prawo && !dol && lewo) {
        return 'DP1';
      }
      return 'X';
    }
    public set(x, y) {
      var typ = '';
      //if(this.module_array[x][y]){
      //  typ = this.getRodzaj(y,x);
      //} 
      //// console.log("typ", typ);
      this.module_array[x][y] = !this.module_array[x][y];
      if(this.module_array[x][y]){
        this.ilosc++ ;
      } else {
        this.ilosc--;
       
      }
      this.aktualizujMatrix();
      // this.tresci.zamowienie.zapisz_Parametry();
    }

    public getName(x, y) {
      let licz = 0;
      for (let i = 0; i < 20; i++) {
        for (let j = 0; j < 20; j++) {
          if (this.module_array[j][i]) {
            licz++;
          }
          if (x === j && y === i) {
            return licz;
          }
        }
      }
    }
    public getX() {
      if (this.Typ === 'V') {
        return 1;
      } else if (this.Typ === '2V') {
        return 2;
      } else {
        let max = 2;
        for (let i = 0; i < 20; i++) {
          for (let j = 0; j < 20; j++) {
            if (this.module_array[j][i] && j + 2 > max) {
              max = j + 2;
            }
          }
        }
        return max;
      }
    }
    public getY() {
      if (this.Typ === 'H') {
        return 1;
      } else if (this.Typ === '2H') {
        return 2;
      } else {
        let max = 2;
        for (let i = 0; i < 20; i++) {
          for (let j = 0; j < 20; j++) {
            if (this.module_array[j][i] && i + 2 > max) {
              max = i + 2;
            }
          }
        }
        return max;
      }
    }

}

