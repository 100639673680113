<h2 mat-dialog-title id="notatki" [translation]="TranslationEnum.Translation">
  {{'notatki'|translate}}
</h2>
<div mat-dialog-content>
  <textarea class="form-control" rows="5" [(ngModel)]="data.Description"></textarea>
</div>
<div mat-dialog-actions>
  <div style="width:100%">
    <button mat-flat-button id="anuluj" class="float-right" [translation]="TranslationEnum.Translation" color="primary"
      mat-dialog-close>
      {{'anuluj'|translate}}
      <!-- <app-tlumacz [id]="'Anuluj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>
    <button mat-flat-button id="zapisz_notatkę" class="float-right mr-3" [translation]="TranslationEnum.Translation"
      color="primary" [mat-dialog-close]="true" (click)="save()">
      {{'zapisz'|translate}}
      <!-- <app-tlumacz [id]="'Zapisz_notatkę'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>
  </div>
</div>
