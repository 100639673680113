
<h1 *ngIf="tresci.id == null" [innerHTML]="tresci.pole_zmienne('lista', tresci.zmiana_contentu) | safe: 'html'"></h1>
<h1 *ngIf="tresci.id != null" [innerHTML]="tresci.pole_zmienne('szczegóły_zamówienia', tresci.zmiana_contentu) | safe: 'html'"></h1>
<div class="archiwum">
  <table *ngIf="tresci.archiwumLista != null && tresci.id == null">
    <tr class="naglowek">
      <td [innerHTML]="tresci.pole_stale('lp') | safe: 'html'"></td>
      <td [innerHTML]="tresci.pole_stale('Status_płatności') | safe: 'html'"></td>
      <td [innerHTML]="tresci.pole_stale('Metoda_płatności') | safe: 'html'"></td>
      <td [innerHTML]="tresci.pole_stale('Cena_razem') | safe: 'html'"></td>
      <td [innerHTML]="tresci.pole_stale('Status') | safe: 'html'"></td>
      <td [innerHTML]="tresci.pole_stale('Numer_produkcyjny') | safe: 'html'"></td>
      <td [innerHTML]="tresci.pole_stale('Data_zamówienia') | safe: 'html'"></td>
      <td *ngIf="false" [innerHTML]="tresci.pole_stale('Szczegóły') | safe: 'html'"></td>
      <td class="last" [innerHTML]="tresci.pole_stale('Opcje') | safe: 'html'"></td>
    </tr>
    <tr  *ngFor="let zamowienie of tresci.archiwumLista.lista; let i = index">
      <td>{{i + 1}}.</td>
      <td >
        <span *ngIf="zamowienie.IsPaid !== null">
          <span [innerHTML]="tresci.pole_stale('zapłacono_' + zamowienie.IsPaid, tresci.zmiana_contentu) | safe: 'html'"></span>
        </span>
      </td>
      <td >
        <span *ngIf="zamowienie.PaymentMethod !== null" >
          <span [innerHTML]="tresci.pole_stale(zamowienie.PaymentMethod, tresci.zmiana_contentu) | safe: 'html'"></span>
        </span>
      </td>
      <td>
        <span *ngIf="zamowienie.ValueNetto !== null && zamowienie.Currency !== null && zamowienie.ValueNetto > 0">
            {{zamowienie.ValueNetto}}&nbsp;<span [innerHTML]="tresci.pole_stale(zamowienie.Currency, tresci.zmiana_contentu) | safe: 'html'"></span>
        </span></td>
      <td>
        <span  *ngIf="zamowienie.OrderStatusName !== null" [innerHTML]="tresci.pole_stale(zamowienie.OrderStatusName, tresci.zmiana_contentu) | safe: 'html'"></span>
      </td>
      <td>{{zamowienie.ProductionNumber}}</td>
      <td>{{zamowienie.OrderDate | date:'yyyy-MM-dd H:mm:ss'}}</td>
      <td *ngIf="false" class="button_td" [innerHTML]="tresci.pole_stale('zobacz', tresci.zmiana_contentu) | safe: 'html'" (click)="tresci.zmienStrone('Orders', 'Orders', zamowienie.Id)"></td>
      <td class="last">
      <span class="button_td" [innerHTML]="tresci.pole_stale('kopiuj', tresci.zmiana_contentu) | safe: 'html'" (click)="tresci.zmienStrone('NewOrder', zamowienie.CategoryProduct, zamowienie.Id)"></span>
      </td>
    </tr>
  </table>

  <table *ngIf="tresci.id != null && tresci.archiwumLista.szczegoly != null">
    <tr>
      <td [innerHTML]="tresci.pole_stale('status_płatności') | safe: 'html'"></td>
      <td [innerHTML]="tresci.pole_stale('metoda_płatności') | safe: 'html'"></td>
      <td [innerHTML]="tresci.pole_stale('cena_razem') | safe: 'html'"></td>
      <td [innerHTML]="tresci.pole_stale('status') | safe: 'html'"></td>
      <td [innerHTML]="tresci.pole_stale('edytowanie') | safe: 'html'"></td>
      <td [innerHTML]="tresci.pole_stale('kopiowanie') | safe: 'html'"></td>
    </tr>
    <tr>
      <td  [innerHTML]="tresci.pole_stale('zapłacono_' + tresci.zamowienia.szczegoly.IsPaid, tresci.zmiana_contentu) | safe: 'html'"></td>
      <td  [innerHTML]="tresci.pole_stale(tresci.zamowienia.szczegoly.PaymentMethod, tresci.zmiana_contentu) | safe: 'html'"></td>
      <td>{{tresci.zamowienia.szczegoly.ValueNetto}}&nbsp;<span [innerHTML]="tresci.pole_stale(tresci.zamowienia.szczegoly.Currency, tresci.zmiana_contentu) | safe: 'html'"></span></td>
      <td  [innerHTML]="tresci.pole_stale(tresci.zamowienia.szczegoly.OrderStatusName, tresci.zmiana_contentu) | safe: 'html'"></td>
      <td class="button_td" [innerHTML]="tresci.pole_stale('kopiuj', tresci.zmiana_contentu) | safe: 'html'" (click)="tresci.zmienStrone('NewOrder', tresci.zamowienia.szczegoly.CategoryProduct, tresci.zamowienia.szczegoly.Id)"></td>
    </tr>
  </table>
  <div *ngIf="tresci.id != null" class="button button_pod" [innerHTML]="tresci.pole_stale('wróć_do_listy', tresci.zmiana_contentu) | safe: 'html'" (click)="tresci.zmienStrone('Orders', 'Orders')"></div>
</div>
