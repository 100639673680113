<h2 mat-dialog-title>
  {{'kontakt' | translate}}
  <button mat-icon-button color="primary" class="float-right mr-3" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h2>
<div mat-dialog-content>
  <p>{{'w_razie_braku_loginu_i_hasla_prosimy_o_kontakt_pod'|translate}} <br>
    <a class="contact-data" href="mailto:bok@selt.com">e-mail: bok@selt.com </a> <br>
    <a class="contact-data" href="tel:77 550 39 33">tel. 77 550 39 33</a><br>
    {{'lub_ze_swoim' | translate}}
    <span class="contact-data"> {{'przedstawicielem_handlowym' | translate}}</span>.
  </p>
</div>
