<h1 id = "{{typ.toLowerCase()}}"  [translation]="TranslationEnum.TranslationForm">{{( typ.toLowerCase())|translate}}</h1>


<div class="container"  *ngIf = "wys == 'roz'">
    <button class="wroc" mat-icon-button color="primary" (click)="returnToStrefa()">
        <mat-icon>disabled_by_default</mat-icon>
      </button>
    <ng-container *ngFor="let grupa of grupy; let g = index;">
        <div class="grupa">
            <h2>{{grupa.nazwa_grupa_system_s}}</h2>
            <div class="grupa_dok">
                <ng-container *ngFor="let dok of linki; let m = index;">
                    <a  [href]="getLink(dok)"
                    rel="nofollow" target="_blank" rel="noopener"  *ngIf = "grupa.id_grupa_system == dok.id_grupy_systemow">
                        <div class="do_pobrania">
                        
                            {{getNazwa(dok)}}
                            <div class="strzalka"><mat-icon>save_alt</mat-icon></div>
                        </div>
                    </a>
                
                </ng-container>


            <ng-container *ngFor="let system of systemy; let s = index;">
                <ng-container *ngIf = "system.id_grupa_system == grupa.id_grupa_system">
                        <ng-container *ngFor="let dok of linki; let m = index;">
                            <a  [href]="getLink(dok)"
                            rel="nofollow" target="_blank" rel="noopener"  *ngIf = "system.id_system == dok.id_system">
                                <div class="do_pobrania">
                                
                                    {{getNazwa(dok)}}
                                    <div class="strzalka"><mat-icon>save_alt</mat-icon></div>
                                </div>
                            </a>
                        
                        </ng-container>
                </ng-container>
            </ng-container>
            </div>
        </div>
        
    </ng-container>


    
</div>
<div class="container"  *ngIf = "wys == 'poj'">
    <button class="wroc" mat-icon-button color="primary" (click)="returnToStrefa()">
        <mat-icon>disabled_by_default</mat-icon>
      </button>
        <ng-container *ngFor="let dok of linki; let m = index;">
            <a  [href]="getLink(dok)"
            rel="nofollow" target="_blank" rel="noopener">
                <div class="do_pobrania">
                
                    {{getNazwa(dok)}}
                    <div class="strzalka"><mat-icon>save_alt</mat-icon></div>
                </div>
            </a>
        
        </ng-container>
        


    
</div>

