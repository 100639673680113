import { TranslationEnum } from './../../shared/enums/translation-enum';
import { OrderRegisterStatuesQuantity } from '../../shared/models/order-register/order-register-statuses-quantity.model';
import { DataSharingService } from './../../shared/service/data-sharing.service';
import { AfterViewInit, Component, OnDestroy, OnInit, } from '@angular/core';
import { StatusEnum } from 'src/app/shared/enums/status-enum';
import { TresciService } from 'src/app/tresci.service';
import { OrderRegisterService } from '../order-register.service';
import { Contractor } from 'src/app/shared/models/user/contractor.model';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/models/user/user.model';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { PermissionEnum } from 'src/app/shared/enums/permission-enum';
import { AuthorizationService } from 'src/app/module-login/authorization.service';



@Component({
  selector: 'app-order-register',
  templateUrl: './order-register.component.html',
  styleUrls: ['./order-register.component.scss'],
})
export class OrderRegisterComponent implements OnInit, AfterViewInit, OnDestroy {
  public TranslationEnum = TranslationEnum;
  private subscriptions: Subscription[] = [];

  public StatuesQuantity: OrderRegisterStatuesQuantity;
  public StatusEnum = StatusEnum;
  public User: User;
  public EnableEWdt = false; 
  public selected_id = 0;
  status: StatusEnum = StatusEnum.Opracowywane; 
  date = new Date();
  re = /\//gi;
  dateFrom: string = new Date(this.date.getFullYear(), this.date.getMonth() - 1, 1).toLocaleString('en-ZA').slice(0, 10).replace(this.re, '-');
  dateTo: string = this.date.toISOString().slice(0, 10);
  IsMineOrders = false;
  ShowComplaints = false;
  refreshRegister = false;
  public IsTrader = false;

  private contractorNumber = '';
  zakladka: string;
  CreateComplaintOrder: boolean;
  BlockingB2B: boolean;

  constructor(private orderRegisterService: OrderRegisterService, private route: ActivatedRoute, private dataSharingService: DataSharingService, private cookieService: CookieService, private authorizationService: AuthorizationService) {
    this.StatuesQuantity = new OrderRegisterStatuesQuantity();
    const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
    this.User = JSON.parse(this.cookieService.get('_usi')) as User;
    this.contractorNumber = contractor.ContractorNumber;
    this.EnableEWdt = this.User.EnableEWdt;
    this.IsTrader = this.User.IsTrader;
    
    this.BlockingB2B = this.User.BlockingB2B;
    this.zakladka = this.route.snapshot.paramMap.get('zakladka');
    if(this.zakladka == 'ewdt'){
      this.selected_id = 7;
    }
    
      switch ( this.zakladka ) {
        case 'ewdt':
          this.selected_id = 7;
          break;
        case 'opracowywane':
          this.selected_id = 0;
          break;
        case 'zamowiono':
          this.selected_id = 1;
          break;
        case 'realizacje':
          this.selected_id = 2;
          break;
        case 'wstrzymano':
          this.selected_id = 3;
          break;
        case 'zakonczono':
          this.selected_id = 4;
          break;
        case 'anulowano':
          this.selected_id = 5;
          break;
        case 'platnosci':
          this.selected_id = 6;
          break;
      }
    
    
    var CreateCon = this.authorizationService.hasPermission(PermissionEnum.CreateComplaintOrder);
    // console.log('CreateComplaintOrder', CreateCon);
    if(CreateCon != null){
      this.CreateComplaintOrder = true;
    } else {
      this.CreateComplaintOrder = false;
    }
    // console.log('CreateComplaintOrder', this.CreateComplaintOrder);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((e) => (!e.closed ? e.unsubscribe() : null));
    this.subscriptions = [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataSharingService.contractorChanged.subscribe((value) => {
        if (value) {
          const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
          this.contractorNumber = contractor.ContractorNumber;
          this.refresh();
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.refresh();
  }

  refresh() {
    this.subscriptions.push(
      this.orderRegisterService.getOrderStatusesQuantity(this.contractorNumber, this.IsMineOrders, this.dateFrom, this.dateTo, this.ShowComplaints).subscribe((data) => (this.StatuesQuantity = data))
    );
  }

  setDate() {
    const date = new Date();
    this.dateTo = date.toISOString().slice(0, 10);
    const re = /\//gi;
    this.dateFrom = new Date(date.getFullYear(), date.getMonth() - 1, 1).toLocaleString('en-ZA').slice(0, 10).replace(re, '-');
  }
}
