<div [formGroup]="Group" style="font-size: 12px;">
  <label>
    <span id="label_{{Control.label}}" [translation]="TranslationEnum.TranslationForm">
      {{('label_'+ Control.label)|translate}}
    </span>
    <app-information [label]="Control.label" [notAffectOnOtherControl]='Control.notAffectToOtherControls' [fileInfoId]="Control.fileInfoId"></app-information>
  </label>
  <mat-checkbox #checkbox color="primary" class="d-inline mr-2"
    (change)="checkChanged($event);" ></mat-checkbox>
  <mat-form-field appearance="outline"  [disabled]="EnabledSelect" class="w-100" color="accent" (click)="openFabricDialog()">
    <img *ngIf="src !== ''" class="d-inline-block" [src]="src" height="20" width="40" class="mr-2">
    <input #input matInput [formControlName]="Control.name" [value-object]="Control" type="text" [readonly]='true' style="width: 60%;">
    <button [disabled]="input.disabled" mat-button type="button" color="accent" matPrefix mat-icon-button class="d-inline-block">
      <mat-icon>create</mat-icon>
    </button>
    <ng-container *ngFor="let validation of Control.validations;" ngProjectAs="mat-error">
      <mat-error *ngIf="Group.get(Control.name)?.hasError(validation.name)" id="{{validation.message}}"
      [translation]="TranslationEnum.TranslationForm">
      {{validation.message|translate}}
      </mat-error>
    </ng-container>
  </mat-form-field>
</div>
