import { Konfigurator } from './konfigurator';
export class Krok {
  public Nazwa;
  public Konfigurator: Konfigurator;
  public IsLastStep;
  public Type;
  public id = 0;
  public StepIsHidden: boolean;
  constructor(public Tech, Nazwa, Kontrolki, IsLastStep = false, http, typ, tresci,Type, id, Kroki, public modul, public pozycja,
              ParametryW = [], ParametryWKim = []) {
    this.id = id;
    this.Nazwa = Nazwa;
    this.Type = Type;
    this.Konfigurator = new Konfigurator(http, typ, tresci, Kontrolki, id, Kroki, ParametryW, ParametryWKim, this.modul, this.pozycja);
    this.IsLastStep = IsLastStep;
  }

  public czyUzupelnione() {
    return !this.Konfigurator.czyNieWybrany();
  }
  public Wartosc(Key) {
    return this.Konfigurator.Wartosc(Key);
  }
  public WartoscKod(Key) {
    return this.Konfigurator.WartoscKod(Key);
  }
  public sprawdz(Kontrolki) {
    return this.Konfigurator.sprawdz(Kontrolki);
  }
  public poprawny() {
    return this.Konfigurator.CzyPoprawny();
  }
}

