import { PurchasePermitsEnum } from './../../../shared/enums/purchase-permit.enum';
import { ModalForEnum } from '../../../shared/enums/modal-for-enum';
import { TresciService } from '../../../tresci.service';
import { Component, Inject, OnDestroy, } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderWarnings } from 'src/app/shared/models/orders/order-warnings/order-warnings.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderResponseDialogComponent } from './order-response-dialog/order-response-dialog.component';
import { Warning } from 'src/app/shared/models/orders/order-warnings/warning.model';
import { MatTableDataSource } from '@angular/material/table';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Subscription } from 'rxjs';



interface DialogData {
  tableData: OrderWarnings[];
  modalForEnum: ModalForEnum;
}

@Component({
  selector: 'app-order-warnings-dialog',
  templateUrl: './order-warnings-dialog.component.html',
  styleUrls: ['./order-warnings-dialog.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class OrderWarningsDialogComponent implements OnDestroy {

  public TranslationEnum = TranslationEnum;
  public PurchasePermitsEnum = PurchasePermitsEnum;
  private subscriptions: Subscription[] = [];


  modalForEnum = ModalForEnum;
  selection = new SelectionModel<any>(true, []);
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  displayedColumns: string[] = [
    'select', 'B2BId', 'Product', 'System', 'ValNetBrut', 'Expand'
  ];

  expandDisplayedColumns: string[] = [
    'WarningName', 'WarningDescription', 'ParameterName', 'ParameterValue'
  ];

  expandedElement: Warning | null;

  orderTerms: FormGroup;
  valuation = new FormControl(false, Validators.required);
  specification = new FormControl(false, Validators.required);
  proform = new FormControl(false, Validators.required);

  canOrder: boolean;

  constructor(public dialogRef: MatDialogRef<OrderWarningsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public tresci: TresciService, private fb: FormBuilder,
    public dialog: MatDialog) {

    this.dataSource.data = data.tableData;
    this.canOrder = data.tableData.some(x => x.PurchasePermit === 1 || x.PurchasePermit === 3);
    this.orderTerms = this.fb.group({
      valuation: this.valuation,
      specification: this.specification,
      proform: this.proform
    });
    this.masterToggle();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }



  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter(row => row.PurchasePermit !== 2);
    return numSelected === numRows.length;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.filter(row => row.PurchasePermit !== 2).forEach((row) => this.selection.select(row));
  }

  orderMany({ valid }: { valid: boolean }) {
    if (valid) {
      const ids = [];
      this.selection.selected.forEach((x) => {
        ids.push({ 'Id': x.OrderId });
      });

      this.subscriptions.push(this.tresci.OrderVerification(ids).subscribe(
        data => {
          const dialogRef = this.dialog.open(OrderResponseDialogComponent,
            {
              minWidth: '1200px',
              data: { data: data, modalForEnum: this.modalForEnum.OrderMany }
            });
            this.subscriptions.push(dialogRef.afterClosed().subscribe(x => this.dialogRef.close()));
        },
        err => {
          this.tresci.exception.exception(this.tresci.exception.error(err), err);
          this.tresci.exception.exception('Nie wysłano', err);
        }));
    }
  }

  payMany(modalForEnum: ModalForEnum) {
    const ids = [];
    this.selection.selected.forEach((x) => {
      ids.push({ 'Id': x.OrderId });
    });

    this.tresci.SaveOrderVerificationForProforma(ids).subscribe(
      data => {
        const dialogRef = this.dialog.open(OrderResponseDialogComponent,
          {
            minWidth: '1200px',
            data: { data: data, modalForEnum: this.modalForEnum.PayMany }
          });
          this.subscriptions.push(dialogRef.afterClosed().subscribe(x => this.dialogRef.close()));
      },
      err => {
        this.tresci.exception.exception(this.tresci.exception.error(err), err);
        this.tresci.exception.exception('Nie wysłano', err);
      });
  }
}
