import { SurchargeTypeEnum } from './../../shared/enums/surcharge-type-enum';
import { FormService } from 'src/app/shared/service/form.service';
import { Surcharge } from '../../shared/models/form/surcharge.model';
import { Component, ChangeDetectionStrategy, OnInit, inject, AfterViewInit, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { SurchargeControl } from 'src/app/shared/models/form/surcharge-control.model';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-surcharges',
  templateUrl: './surcharges.component.html',
  styleUrls: ['./surcharges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurchargesComponent implements AfterViewInit, OnDestroy  {

  @Input() Preview: boolean;

  public TranslationEnum = TranslationEnum;
  public SurchargesGroup: FormGroup;

  public Surcharges: Surcharge[];
  Surcharge: Surcharge = new Surcharge();
  Price = 0;

  public SurchargeTypeEnum = SurchargeTypeEnum;

  dataSource: MatTableDataSource<SurchargeControl> = new MatTableDataSource<SurchargeControl>();
  displayedColumns: string[] = [
    'Surcharge', 'Price', 'Type', 'Remove'
  ];
  private subscriptions: Subscription[] = [];

  constructor(private formService: FormService, private _changeDetectorRef: ChangeDetectorRef) {
    this.subscriptions.push(this.formService.getSurcharges().subscribe(x => { this.Surcharges = x; this.Surcharge = this.Surcharges[0]; }));

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngAfterViewInit(): void {
    this.formService.$Surcharges.subscribe(x => {if (x !== null) {
      this.dataSource = new MatTableDataSource(x);
      this._changeDetectorRef.detectChanges(); }});
  }

  addSurcharge(): void {
    const surchargeControl: SurchargeControl = {
      Id: 0,
      ActiveFormOrderId: this.formService.ActiveFormOrderId,
      SurchargeId: this.Surcharge.Id,
      Name: this.Surcharge.Name,
      Type: this.Surcharge.Type,
      Price: this.Price
    };

    this.formService.Surcharges.push(surchargeControl);
    this.dataSource.data = [];
    this.dataSource.data = this.formService.Surcharges;

  }

  removeSurcharge(surcharge: SurchargeControl): void {
    const index = this.formService.Surcharges.indexOf(surcharge);
    this.formService.Surcharges.splice(index, 1);
    this.dataSource.data = [];
    this.dataSource.data = this.formService.Surcharges;
  }

}
