import { Kim } from "src/app/shared/models/form-model/kim.model";
import { Parameter } from "src/app/shared/models/form-model/models/form-controlls.model";
import { FileControl } from "src/app/shared/models/form/file-control.model";
import { Delivery } from "./Delivery";
import { KimSave } from "./KimSave";
import { Order } from "./Order";


export class FormDataV2Save {

    Parameters:Parameter[] = [];
    Files:FileControl[] = [];
    public ParameterExtensions:KimSave[] = [];
    OrderSave:Order = new Order();
}