import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslationEnum } from '../../enums/translation-enum';

@Component({
  selector: 'app-universal-snack-bar',
  templateUrl: './universal-snack-bar.component.html',
  styleUrls: ['./universal-snack-bar.component.scss']
})
export class UniversalSnackBarComponent implements OnInit {
  public TranslationEnum = TranslationEnum;

  constructor(  public snackBarRef: MatSnackBarRef<UniversalSnackBarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: string) {
  }

  ngOnInit(): void {
  }

}
