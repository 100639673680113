<div [formGroup]="Group">
  <label>
    <span id="label_{{Control.label}}" [translation]="TranslationEnum.TranslationForm">
      {{('label_'+ Control.label)|translate}}
    </span>
    <app-information [label]="Control.label" [notAffectOnOtherControl]='Control.notAffectToOtherControls'
      [fileInfoId]="Control.fileInfoId"></app-information>
  </label>
  <mat-form-field appearance="outline" class="d-block w-100" color="accent">
    <mat-select #select [formControlName]="Control.name" [value-object]="Control">
      <mat-option *ngFor="let item of Control.options" [value]="item" class="border">
        <div class="mt-2 mb-2">
          <img height="50px" [src]="tresci.server_url + '/api/ActiveForm/GetIcon?code=' + item.Key">
          {{item.DisplayValue}}
        </div>
      </mat-option>
    </mat-select>
    <ng-container *ngFor="let validation of Control.validations;" ngProjectAs="mat-error">
      <mat-error *ngIf="Group.get(Control.name)?.hasError(validation.name)" id="{{validation.message}}"
        [translation]="TranslationEnum.TranslationForm">
        {{validation.message|translate}}
      </mat-error>
    </ng-container>
  </mat-form-field>
</div>
