<div [formGroup]="Group">
  <label>
    <span id="label_{{Control.label}}" [translation]="TranslationEnum.TranslationForm">
      {{('label_'+ Control.label)|translate}}
    </span>
    <app-information [label]="Control.label"  [notAffectOnOtherControl]='Control.notAffectToOtherControls' [fileInfoId]="Control.fileInfoId"></app-information>
  </label>
  <mat-radio-group [formControlName]="Control.name" [value-object]="Control" class="radio-group" ng-color="accent">
    <mat-radio-button *ngFor="let item of Control.options" [value]="item" class="radio-button">
      {{item.DisplayValue}}
    </mat-radio-button>
  </mat-radio-group>
</div>
