import { SafeUrl } from '@angular/platform-browser';

export class Kim {
  public ParameterKind: number;
  public ParameterKindName: string;
  public CategoryCode: string;
  public Index: string;
  public Name: string;
  public BasicPrice: number;
  public Discount: number;
  public Quantity: number;
  public PriceAfterDiscount: number;
  public Description: string;
  public Power: string;
  public OutputSignals: string;
  public ImageExists: boolean;

  public jest:boolean = false;

  public Filter1: boolean;
  public Filter2: boolean;
  public Filter3: boolean;
  public Filter4: boolean;
  public Filter5: boolean;
  public Filter6: boolean;
  public Filter7: boolean;
  public Filter8: boolean;
  public Filter9: boolean;
  public Filter10: boolean;
  public Filter11: boolean;
  public Filter12: boolean;
  public Filter13: boolean;
  public Filter14: boolean;
  public Filter15: boolean;
  public Filter16: boolean;
  public Filter17: boolean;
  public Filter18: boolean;
  public Filter19: boolean;
  public Filter20: boolean;
  public Filter21: boolean;
  public Filter22: boolean;
  public Filter23: boolean;
  public Filter24: boolean;
  public Filter2_1: boolean;
  public Filter2_2: boolean;
  public Filter2_3: boolean;
  public Filter2_4: boolean;
  public Filter2_5: boolean;
  public Filter2_6: boolean;
  public Filter2_7: boolean;
  public Filter2_8: boolean;
  public Filter2_9: boolean;
  public Filter2_10: boolean;
  public Key: string;
  public Position: number;
  public Module: number;
  public IsEnabled: boolean;
  public Type: number;

  public ImageSrc: SafeUrl;

  public constructor(v){
    this.ParameterKind           = v.ParameterKind     
    this.ParameterKindName       = v.ParameterKindName 
    this.CategoryCode            = v.CategoryCode      
    this.Index                   = v.Index             
    this.Name                    = v.Name              
    this.BasicPrice              = v.BasicPrice        
    this.Discount                = v.Discount          
    this.Quantity                = v.Quantity          
    this.PriceAfterDiscount      = v.PriceAfterDiscount
    this.Description             = v.Description       
    this.Power                   = v.Power             
    this.OutputSignals           = v.OutputSignals     
    this.ImageExists             = v.ImageExists       
    this.Filter1                 = v.Filter1           
    this.Filter2                 = v.Filter2           
    this.Filter3                 = v.Filter3           
    this.Filter4                 = v.Filter4           
    this.Filter5                 = v.Filter5           
    this.Filter6                 = v.Filter6           
    this.Filter7                 = v.Filter7           
    this.Filter8                 = v.Filter8           
    this.Filter9                 = v.Filter9           
    this.Filter10                = v.Filter10          
    this.Filter11                = v.Filter11          
    this.Filter12                = v.Filter12          
    this.Filter13                = v.Filter13          
    this.Filter14                = v.Filter14          
    this.Filter15                = v.Filter15          
    this.Filter16                = v.Filter16          
    this.Filter17                = v.Filter17          
    this.Filter18                = v.Filter18          
    this.Filter19                = v.Filter19          
    this.Filter20                = v.Filter20          
    this.Filter21                = v.Filter21          
    this.Filter22                = v.Filter22          
    this.Filter23                = v.Filter23          
    this.Filter24                = v.Filter24        
    this.Filter2_1               = v.Filter2_1       
    this.Filter2_2               = v.Filter2_2       
    this.Filter2_3               = v.Filter2_3       
    this.Filter2_4               = v.Filter2_4       
    this.Filter2_5               = v.Filter2_5       
    this.Filter2_6               = v.Filter2_6       
    this.Filter2_7               = v.Filter2_7       
    this.Filter2_8               = v.Filter2_8       
    this.Filter2_9               = v.Filter2_9       
    this.Filter2_10              = v.Filter2_10         
    this.Key                     = v.Key               
    this.Position                = v.Position          
    this.Module                  = v.Module            
    this.IsEnabled               = v.IsEnabled         
    this.Type                    = v.Type              
  }
}
