import { WdtStatusEnum } from './../shared/enums/wdt-status-enum';
import { OrderEstimate } from './../shared/models/order-register/order-estimate/order-estimate';
import { OrderRegisterStatuesQuantity } from './../shared/models/order-register/order-register-statuses-quantity.model';
import { MailWithOrder } from './../shared/models/order-register/order-list/mail-with-order.model';
import { ProformsListData } from '../shared/models/order-register/proforms-list/proforms-list-data.model';
import { OrderListData } from '../shared/models/order-register/order-list/order-list-data.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusEnum } from '../shared/enums/status-enum';
import { Observable } from 'rxjs';
import { Package } from '../shared/models/order-register/order-list/package.model';
import { TresciService } from '../tresci.service';
import { Country } from '../shared/models/order-register/order-estimate/country.model';
import { OrderVerify } from '../shared/models/orders/order-verify/order-verify.model';
import { Delivery } from '../shared/models/order-register/delivery-list/delivery.model';
import { serialize } from 'class-transformer';
import { PostBody } from '../shared/models/reklamacje/PostBody.model'
import { Complaints } from '../shared/models/reklamacje/Complaints.model'
import { Status } from '../shared/models/reklamacje/Status.model'
import { Complaint, Files } from '../shared/models/reklamacje/Complaint.model'
import { Claim } from '../shared/models/reklamacje/Claim.model';
import { Invoice } from '../shared/models/reklamacje/Invoice.model';
import { Raport } from '../shared/models/reklamacje/Raport.model';
import { PositionP } from '../shared/models/reklamacje/Position.model';
import { Position } from '../shared/models/reklamacje/Complaint.model';
import { Consultation } from '../shared/models/reklamacje/Consultation.model';

@Injectable({
  providedIn: 'root',
})
export class ReklamacjeService {
  public goToPage = 0;

  constructor(private httpClient: HttpClient, public tresci: TresciService) {}


  public GetPrintShippingList(language: string, id_reklamacji: Number): Observable<any> {
    var body:PostBody = new PostBody();
    body.Id = id_reklamacji;
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.httpClient.post<Blob>('api/Complaint/GetPrintShippingList?language=' + language, body, httpOptions);

  }
  public GetPrintLabel(language: string, id_reklamacji: Number, ilosc_etykiet: Number): Observable<any> {
    var body:PostBody = new PostBody();
    body.Id = id_reklamacji;
    body.PrintLabelCount = ilosc_etykiet;

    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.httpClient.post<Blob>('api/Complaint/GetPrintLabel?language=' + language, body, httpOptions);

  }

  public GetComplaintConsultation(id_reklamacji: Number): Observable<any[]> {
    var body:PostBody = new PostBody();
    body.Id = id_reklamacji;

    return this.httpClient.post<Consultation[]>('api/Complaint/GetComplaintConsultation', body);

  }
  public AddComplaintConsultation(language: string, reklamacja: Consultation): Observable<any> {
   

    return this.httpClient.post<Consultation>('api/Complaint/AddComplaintConsultation?language='+language, reklamacja);

  }
  public CancelComplaint(reklamacja: Number): Observable<any> {
     var body:PostBody = new PostBody();
     body.Id = reklamacja;
 
     return this.httpClient.post<any>('api/Complaint/CancelComplaint', body);
 
   }
   public UpdateComplaint(reklamacja: Complaint): Observable<any> {
      //var body:PostBody = new PostBody();
      //body.Id = reklamacja;
  
      return this.httpClient.post<any>('api/Complaint/UpdateComplaint', reklamacja);
  
    }

   public AddorUpdateComplaint(language: string, reklamacja: Complaint): Observable<any[]> {
     // var body:PostBody = new PostBody();
      // body.ContractorNumber = ContractorNumber;
  
      return this.httpClient.post<Complaints[]>('api/Complaint/AddorUpdateComplaint?language='+language, reklamacja);
  
    }
   public GetFile(language: string, id_plik: Number): Observable<any> {
    // var body:PostBody = new PostBody();
     // body.ContractorNumber = ContractorNumber;
     const httpOptions = {
      responseType: 'blob' as 'json'
    };
     return this.httpClient.get<Blob>('api/Complaint/GetFile?Id='+id_plik+'&Language='+language, httpOptions);
 
   }
   public AddFile(id_complaint: Number, files:Files[]): Observable<any> {
    // var body:PostBody = new PostBody();
     // body.ContractorNumber = ContractorNumber;
     return this.httpClient.post<any>('api/Complaint/AddFile?id='+id_complaint, files);
 
   }
  
  public GetContractorComplaints(ContractorNumber: string, Language: string): Observable<any[]> {
    var body:PostBody = new PostBody();
    body.ContractorNumber = ContractorNumber;
    body.Language = Language;

    return this.httpClient.post<Complaints[]>('api/Complaint/GetContractorComplaints', body);

  }
  public GetComplaintStatus(): Observable<any[]> {
    
    return this.httpClient.get<Status[]>('/api/Complaint/GetComplaintStatus');

  }
  public GetComplaint(numer: string, Language: string): Observable<any> {
    var body:PostBody = new PostBody();
    body.Id = Number.parseInt(numer);
    body.Language = Language;
    
    return this.httpClient.post<Complaint>('/api/Complaint/GetComplaint', body);

  }
  public GetComplaintClaims(Language: string): Observable<any[]> {
    var body:PostBody = new PostBody();
    body.Language = Language;
    
    return this.httpClient.post<Claim[]>('/api/Complaint/GetComplaintClaims', body);

  }
  
  public GetComplaintInvoices(ContractorNumber: string): Observable<any[]> {
    var body:PostBody = new PostBody();
    body.ContractorNumber = ContractorNumber;
    
    return this.httpClient.post<Invoice[]>('/api/Complaint/GetComplaintInvoices', body);

  }
  public GetComplaintReports(ProductionNumber: string, Language:string): Observable<any[]> {
    var body:PostBody = new PostBody();
    body.ProductionNumber = ProductionNumber;
    body.Language = Language;

    return this.httpClient.post<Raport[]>('/api/Complaint/GetComplaintReports', body);

  }
  public GetComplaintPositions(ProductionNumber: string): Observable<any[]> {
    var body:PostBody = new PostBody();
    body.ProductionNumber = ProductionNumber;

    return this.httpClient.post<PositionP[]>('/api/Complaint/GetComplaintPositions', body);

  }

  

  public getAttachments(id: number): Observable<any[]> {
    return this.httpClient.get<PositionP[]>('api/OrderRegister/GetFilesRegisterForOrder?orderId=' + id);
  }


}
