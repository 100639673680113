import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogData } from '../../models/dialog/dialog-data.model';

@Component({
  selector: 'app-preview-file-dialog',
  templateUrl: './preview-file-dialog.component.html',
  styleUrls: ['./preview-file-dialog.component.scss'],
})
export class PreviewFileDialogComponent implements OnInit {
  public Src;

  constructor(public dialogRef: MatDialogRef<PreviewFileDialogComponent>, public changeDetectorRef: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.createImageFromBlob(data);
  }

  ngOnInit(): void {}

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.Src = reader.result ?? '';
        this.changeDetectorRef.detectChanges();
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
