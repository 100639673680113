<h2 mat-dialog-title id="tkanina" [translation]="TranslationEnum.TranslationForm">
    {{'tkanina'|translate}}
  </h2>
<input (keyup)="applyFilter($event.target.value)">
<div class="fabrics">
<ng-container *ngFor="let v of values; let m = index;">

    <div class="fabric" *ngIf ="m >= site * max && m < (site + 1) * max" (click) = wybierz(v)>
        <div class="tresc">{{v.DisplayValue}} <br /> ({{v.Key}})</div>
        <div class="tylko_handlowiec" id="tylko_handlowiec" *ngIf = "v.IsEnableOnlyForTrader">Tkanina nie jest dostępna dla klienta, prawdopodobnie brak tkaniny!</div>
        <div class="img"><img  decoding="async" class="foto"  src="{{server_url}}//api/ActiveForm/GetIcon?code={{v.Key}}"></div>
    </div>
  </ng-container> 
</div>
<div class="licznik">
  <span class="next" *ngIf ="site > 0" (click) = "prevPage()"><</span> {{site + 1}} / {{ileStron()}} <span class="next" *ngIf ="site + 1 < ileStron()" (click) = "nextPage()">></span>
</div>