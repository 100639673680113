<h2 mat-dialog-title id="akcesoria_do_zlecenia" [translation]="TranslationEnum.TranslationForm">
  {{'akcesoria_do_zlecenia'|translate}}
</h2>
<div mat-dialog-content>


  <!-- Filtr -->
  <div class="d-inline-block mt-3 mr-3" *ngIf="systemsFilter.length > 0">
    <mat-form-field appearance="outline" class="w-160p" color="accent">
      <mat-select [(ngModel)]="systemFilter" (selectionChange)="applySelectFilter()">
        <mat-option *ngFor="let item of systemsFilter" [value]="item" id="{{item.Name}}"
          [translation]="TranslationEnum.TranslationForm">
          {{item.Name|translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="d-inline-block mt-3 mr-3" *ngIf="companysFilter.length > 0">
    <mat-form-field appearance="outline" class="w-160p" color="accent">
      <mat-select [(ngModel)]="companyFilter" (selectionChange)="applySelectFilter()">
        <mat-option *ngFor="let item of companysFilter" [value]="item" id="{{item.Name}}"
          [translation]="TranslationEnum.TranslationForm">
          {{item.Name|translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline" color="accent">
    <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter()" placeholder="AA">
    <button mat-button *ngIf="filterValue" matSuffix mat-icon-button class="d-inline-block"
      (click)="filterValue=''; applyFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- CategoryCode Column -->
    <ng-container matColumnDef="CategoryCode">
      <th mat-header-cell *matHeaderCellDef id="kod_katalogowy" [translation]="TranslationEnum.TranslationForm">
        {{'kod_katalogowy'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.CategoryCode}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef id="nazwa" [translation]="TranslationEnum.TranslationForm">
        {{'nazwa'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Name}} </td>
    </ng-container>

    <!-- BasicPrice Column -->
    <ng-container matColumnDef="BasicPrice">
      <th mat-header-cell *matHeaderCellDef id="cena_netto" [translation]="TranslationEnum.TranslationForm">
        {{'cena_netto'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.BasicPrice}} </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="Description">
      <th mat-header-cell *matHeaderCellDef id="opis" [translation]="TranslationEnum.TranslationForm">
        {{'opis'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Description}} </td>
    </ng-container>

    <!-- Power Column -->
    <ng-container matColumnDef="Power">
      <th mat-header-cell *matHeaderCellDef id="zasilanie" [translation]="TranslationEnum.TranslationForm">
        {{'zasilanie'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Power}} </td>
    </ng-container>

    <!-- OutputSignals Column -->
    <ng-container matColumnDef="OutputSignals">
      <th mat-header-cell *matHeaderCellDef id="sygnaly_wyjsciowe" [translation]="TranslationEnum.TranslationForm">
        {{'sygnaly_wyjsciowe'|translate}}
      </th>
      <td mat-cell *matCellDef="let row"> {{row.OutputSignals}} </td>
    </ng-container>

    <!-- Picture Column -->
    <ng-container matColumnDef="Picture">
      <th mat-header-cell *matHeaderCellDef id="obrazek" [translation]="TranslationEnum.TranslationForm">
        {{'obrazek'|translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.ImageExists">
          <button mat-icon-button color="accent">
            <mat-icon (mouseenter)="getImg(row)" [tooltipImg]="row.ImageSrc">image</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <!-- Quantity Column -->
    <ng-container matColumnDef="Quantity">
      <th mat-header-cell *matHeaderCellDef class="w-75p" id="ilosc" [translation]="TranslationEnum.TranslationForm">
        {{'ilosc'|translate}}
      </th>
      <td mat-cell *matCellDef="let row">
         <input matInput type="number" [(ngModel)]="row.Quantity" min="0" [max]="data.Max" (change)="checkIsMaxValue($event, row)"> <!--{{CanNotAddMoreThanOne? 1 : undefined}}" (change)="checkIsMaxValue($event, row)"> -->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4" id="brak_danych_do_wyswietlenia" [translation]="TranslationEnum.TranslationForm">
        {{'brak_danych_do_wyswietlenia'|translate}}
      </td>
    </tr>
  </table>

</div>

<div mat-dialog-actions>
  <div class="w-100">
    <button mat-flat-button id="akceptuj" class="btn-sm float-right" [translation]="TranslationEnum.Translation"
      color="primary" [mat-dialog-close]="true">
      {{'akceptuj'|translate}}
    </button>
  </div>
</div>
