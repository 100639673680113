import { CookieService } from 'ngx-cookie-service';
import { OrderRegisterService } from './../../order-register.service';
import { Component, Inject, OnDestroy,OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Delivery } from 'src/app/shared/models/order-register/delivery-list/delivery.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Contractor } from 'src/app/shared/models/user/contractor.model';
import { Subscription } from 'rxjs';
import { WDTconfirm } from 'src/app/shared/models/order-register/order-list/WDTconfirm';
import { User } from 'src/app/shared/models/user/user.model';

interface DialogDeliveryData {
  tableData: Delivery[];
  wdtPositions: any[];
}

@Component({
  selector: 'app-delivery-list-dialog',
  templateUrl: './delivery-list-dialog.component.html',
  styleUrls: ['./delivery-list-dialog.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DeliveryListDialogComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  public TranslationEnum = TranslationEnum;
  public Contractor: Contractor;
  private subscriptions: Subscription[] = [];

  displayedColumns: string[] = ['Lp', 'IndexProduct', 'ProductName', 'Proforma', 'OrderWdt', 'OrderId', 'Unit', 'Quantity', 'PackageDelivered', 'ValueNet', 'Net', 'Vat', 'ValueGross'];
  ConfirmationEWdt: boolean;


  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDeliveryData, private cookieService: CookieService, private orderRegisterService: OrderRegisterService, public dialogRef: MatDialogRef<DeliveryListDialogComponent>) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit(): void {
    this.Contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
    this.subscriptions.push(this.orderRegisterService.getWdtPositions(this.data.tableData).subscribe((x: Delivery[]) => {
      this.data.tableData = Array.from(x);
      // console.log(this.data.tableData);
    }));
    // console.log("cn", this.Contractor);
    this.ConfirmationEWdt = this.Contractor.ConfirmationEWdt;
    // this.GetWdtNotification();
    
  }

  confirmWdt(wdt: Delivery) {
    this.subscriptions.push(this.orderRegisterService.confirmWdt(wdt).subscribe((delivery: Delivery) => {
      const item = this.data.tableData.find((x) => (x.Id = delivery.Id));
      item.WhoConfirmedDelivery = delivery.WhoConfirmedDelivery;
      item.IsDeliveryConfirmed = delivery.IsDeliveryConfirmed;
      item.DateDeliveryConfirmation = delivery.DateDeliveryConfirmation;
      this.GetWdtNotification();
    }));
  }

  confirmAllWdt() {
    const filterTable = this.data.tableData.filter((x) => x.IsDeliveryConfirmed === false);
    if (filterTable.length > 0) {

      this.orderRegisterService.confirmAllWdt(filterTable).subscribe(() => {
        this.GetWdtNotification();
        this.dialogRef.close();

      });
    }
  }
  GetWdtNotification(){
    var user = JSON.parse(this.cookieService.get('_usi')) as User;
    if(!user.IsTrader){
      this.subscriptions.push(this.orderRegisterService.GetWdtNotification(this.Contractor.ContractorNumber).subscribe((wdt: WDTconfirm) => {
        var user = JSON.parse(this.cookieService.get('_usi')) as User;
        user.BlockingB2B = wdt.BlockingB2B;
        user.EnalbleWdtToConfirm = wdt.EnalbleWdtToConfirm;
        this.cookieService.set('_usi', JSON.stringify(user), { path: '/' });
        // console.log("user", user, wdt);
      }));
    }
      
    
  }
}
