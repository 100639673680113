<div [formGroup]="Group">
  <label>
    <span id="label_{{Control.label}}" [translation]="TranslationEnum.TranslationForm">
      {{('label_'+ Control.label)|translate}}
    </span>
    <app-information [label]="Control.label" [notAffectOnOtherControl]='Control.notAffectToOtherControls'  [fileInfoId]="Control.fileInfoId"></app-information>
  </label>
  <mat-radio-group #radioImg [formControlName]="Control.name" class="d-block w-100" [(ngModel)]="SelectedValue" [value-object]="Control">
    <mat-radio-button [disabled]="item.Disabled" *ngFor="let item of Control.options" [value]="item">
      <img class="d-block" style="margin-right: auto; margin-left: auto;" width="{{Control.pictureWidth}}"
        src="{{server_url + '/api/ActiveForm/GetIcon?code=' + system + '_' + Control.name + '_' + item.Key}}">
      {{item.DisplayValue}}
    </mat-radio-button>
  </mat-radio-group>
</div>

<div class="mat-form-field mat-form-field-appearance-outline d-block">
  <div class="mat-form-field-wrapper">
    <div class="mat-form-field-subscript-wrapper" ng-reflect-ng-switch="error">
      <div class="ng-tns-c117-13 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
        style="opacity: 1; transform: translateY(0%);">
        <ng-container *ngFor="let validation of Control.validations;" ngProjectAs="mat-error">
          <mat-error *ngIf="Group.get(Control.name)?.hasError(validation.name)" id="{{validation.message}}"
          [translation]="TranslationEnum.TranslationForm">
          {{validation.message|translate}}
          </mat-error>
        </ng-container>
      </div>
    </div>
  </div>
</div>
