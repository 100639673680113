import { Directive, ElementRef, OnInit, Input, Renderer2 } from '@angular/core';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { PermissionEnum } from '../enums/permission-enum';
import { TranslationEnum } from '../enums/translation-enum';

@Directive({
  selector: '[translation]'
})
export class TranslationDirective implements OnInit {
  @Input('translation') translation: TranslationEnum;

  constructor(private renderer: Renderer2, private el: ElementRef, private authorizationService: AuthorizationService) { }


  ngOnInit() {
    const permission = this.authorizationService.hasPermission(PermissionEnum.Translation);
    if (permission) {
      if (permission.Value === true) {
        if (this.translation === TranslationEnum.Translation) {
          this.renderer.addClass(this.el.nativeElement, 'editTranslation');
        } else if (this.translation === TranslationEnum.TranslationForm) {
          this.renderer.addClass(this.el.nativeElement, 'editTranslationForm');
        }
      }
    }
  }
}

