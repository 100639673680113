import { ListaZamowien } from '../../app/pomocnicze/listazamowien';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../app/user.service';
import { TresciService } from '../../app/tresci.service';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-archiwum',
  templateUrl: './archiwum.component.html',
  styleUrls: ['./archiwum.component.scss']
})
export class ArchiwumComponent implements OnInit {
  constructor(public tresci: TresciService, public user: UserService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.tresci.setJezyk(this.route.snapshot.paramMap.get('jezyk'));
    this.tresci.setFunkcja(this.route.snapshot.paramMap.get('system'));
    this.tresci.setId(this.route.snapshot.paramMap.get('id'));
    if (this.tresci.user.ContractorNumber != null) {
      this.tresci.archiwumLista = new ListaZamowien(this.tresci, true);
    }
  }

}
