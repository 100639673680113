import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { KindOper } from 'src/app/shared/enums/kind-oper-enum';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';

@Component({
  selector: 'app-order-edit-dialog',
  templateUrl: './order-edit-dialog.component.html',
  styleUrls: ['./order-edit-dialog.component.scss'],
})
export class OrderEditDialogComponent implements OnInit {
  public TranslationEnum = TranslationEnum;
  public KindOper = KindOper;

  constructor(public dialogRef: MatDialogRef<UniversalDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) {}

  ngOnInit(): void {}

  edit(kindOper: KindOper) {
    this.router.navigate([`${this.data.version}/${KindOper[kindOper]}/${this.data.row.Prefix}/${this.data.row.Id}`]);
  }
}
