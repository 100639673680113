import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DynamicFormComponent } from 'src/app/new-form/initial-form/dynamic-form/dynamic-form.component';
import { FormControlsService } from 'src/app/shared/components/form-controls.service';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Kim } from 'src/app/shared/models/form-model/kim.model';
import { FilterKim, KimDialogData } from 'src/app/shared/models/form/filter-kim';

@Component({
  selector: 'app-kim-control',
  templateUrl: './kim-control.component.html',
  styleUrls: ['./kim-control.component.scss']
})
export class KimControlComponent implements OnInit {
  public TranslationEnum = TranslationEnum;
  public dataSource: MatTableDataSource<Kim>;

  lista: Kim[] = [];

  public systemsFilter: FilterKim[] = [];
  public companysFilter: FilterKim[] = [];

  public systemFilter: FilterKim;
  public companyFilter: FilterKim;
  public tresc = '';

  public displayedColumns: string[] = [
    'CategoryCode', 'Name', 'BasicPrice', 'Description', 'Power', 'OutputSignals', 'Picture', 'Quantity'
  ];
  constructor(public dialogRef: MatDialogRef<DynamicFormComponent>, @Inject(MAT_DIALOG_DATA) public data: KimDialogData, public formControlsService: FormControlsService) {
    // console.log(data);
    this.dataSource = new MatTableDataSource<Kim>();
    this.lista = Array.from(data.Data);
    this.dataSource.data = Array.from(data.Data);
  }

  ngOnInit(): void {
    // console.log(this.data);
    this.formControlsService.getSystemFilterForKim().subscribe(x => {
      this.systemsFilter = x;
      if (x.length > 0) {
        this.systemFilter = x[0];
      }
    });
    this.formControlsService.getCompanyFilterForKim().subscribe(x => {
      this.companysFilter = x;
      if (x.length > 0) {
        this.companyFilter = x[0];
      }
    });
  }


/*  applySelectFilter(): void {
    let filteredData = Array.from(this.data.Data);
    if (this.systemFilter !== undefined && this.systemFilter.Klucz !== 'All') {
      filteredData = filteredData.filter(x => x['' + this.systemFilter.Klucz] === true);
    }
    if (this.companyFilter !== undefined && this.companyFilter.Klucz !== 'All') {
      filteredData = filteredData.filter(x => x['' + this.companyFilter.Klucz] === true);
    }
    this.dataSource.data = Array.from(filteredData);

    // this.applyFilter();
  }*/


  closeDialog(){
    this.dialogRef.close();
  }
  checkIsMaxValue(event, row) {
    // console.log(row);
    if(isNaN(row.Quantity)){
      row.Quantity = 0;
    } else {
      if(row.Quantity > this.data.Max){
        row.Quantity = this.data.Max;
      }
      if(row.Quantity < 0){
        row.Quantity = 0;
      }
    }
    // console.log(event)
  }
  
  applyFilter(tresc){
    this.tresc = tresc;
    console.log("filtr", this.companyFilter, this.systemFilter, this.lista);
    var val:Kim[] = [];
    this.lista.forEach(v => {
      if(v.Key.toLowerCase().includes(tresc.toLowerCase()) || v.CategoryCode.toLowerCase().includes(tresc.toLowerCase()) || v.Description.toLowerCase().includes(tresc.toLowerCase()) || v.OutputSignals.toLowerCase().includes(tresc.toLowerCase()) || v.Name.toLowerCase().includes(tresc.toLowerCase()) || v.Power.toLowerCase().includes(tresc.toLowerCase())){
        val.push(v);
      }
    });
    if (this.systemFilter !== undefined && this.systemFilter.Klucz !== 'All') {
      val = val.filter(x => x['' + this.systemFilter.Klucz] === true);
    }
    if (this.companyFilter !== undefined && this.companyFilter.Klucz !== 'All') {
      val = val.filter(x => x['' + this.companyFilter.Klucz] === true);
    }

    this.dataSource.data = val;
  }
}
