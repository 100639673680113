import { TresciService } from '../../../tresci.service';
import { Component, Inject, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../models/dialog/dialog-data.model';
import { TranslationEnum } from '../../enums/translation-enum';



@Component({
  selector: 'app-universal-dialog',
  templateUrl: './universal-dialog.component.html',
  styleUrls: ['./universal-dialog.component.scss']
})
export class UniversalDialogComponent {

  public TranslationEnum = TranslationEnum;

  constructor(public dialogRef: MatDialogRef<UniversalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }


}
