import { FormSet } from './../models/form-model/models/form-set.model';
import { ValidatorFn, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Parameter } from '../models/form-model/models/form-controlls.model';
import { HtmlControl, Validator } from '../models/form-model/models/html-control.model';

export class DeepClone {
  public ParametersToEditForPostion: Parameter[];

  // Private
  private formAfterClone: FormGroup;

  constructor(private fb: FormBuilder) {
    this.ParametersToEditForPostion = new Array<Parameter>();
    this.formAfterClone = this.fb.group({});
  }

  public DeepCloneForm(formForClone: FormGroup): FormGroup {
    for (const key of Object.keys(formForClone.controls)) {
      const control = formForClone.controls[key].value;
      if (control === null || control.name === undefined || control === '') {
        continue;
      }

      const htmlControl: HtmlControl = {
        componentName: control.componentName,
        currentStep: control.currentStep,
        displayInTablePosition: control.displayInTablePosition,
        inputType: control.inputType,
        isLastStepInSpecificType: control.isLastStepInSpecificType,
        isPrint: control.isPrint,
        label: control.label,
        name: control.name,
        notAffectToOtherControls: control.notAffectToOtherControls,
        pictureWidth: control.pictureWidth,
        width: control.width,
        fileInfoId: control.fileInfoId,
        module: control.module,
        parameters: control.parameters,
        position: control.position,
        technologyVersion: control.technologyVersion,
        type: control.type,
        validations: control.validations,
        value: control.value,
        required: control.required,
        translateCode: control.translateCode,
        backgroundColour: control.backgroundColour,
        foregrandColour: control.foregrandColour,
      };

      if (control.inputType.toLowerCase() === 'selectmulticolumn' || control.inputType.toLowerCase() === 'checkselectmulticolumn') {
        htmlControl.optionsSelectMultiColumn = control.optionsSelectMultiColumn;
      } else if (control.inputType.toLowerCase() === 'kim') {
        htmlControl.optionsKim = control.optionsKim;
      } else {
        htmlControl.options = control.options;
      }

      let paramForSettingValue: Parameter;
      if (control.value !== null && control.value !== undefined && control.value.Key) {
        paramForSettingValue = { Key: control.name, Value: control.value.Key, DisplayValue: control.value, Module: control.module, Position: control.position, Type: control.type };
      } else {
        paramForSettingValue = { Key: control.name, Value: control.value, DisplayValue: control.value, Module: control.module, Position: control.position, Type: control.type };
      }
      this.ParametersToEditForPostion.push(paramForSettingValue);

      const newHtmlControl = JSON.parse(JSON.stringify(htmlControl));
      let abstControl;
        if (control.validations) {
          abstControl = this.fb.control(newHtmlControl, this.bindValidations(control.validations));
        } else {
          abstControl = this.fb.control(newHtmlControl);
        }
      this.formAfterClone.addControl(key, abstControl);
      this.formAfterClone.controls[key].disable();
    }

    return this.formAfterClone;
  }

  // public CloneSet(formSet: FormSet):FormSet {
  //   const formSetAfterClone = new FormSet();

  //   formSetAfterClone.CurrentStepForSet = JSON.stringify(formSet.CurrentStepForSet);
  //   formSetAfterClone.LastStepBeforeFooter = JSON.stringify(formSet.LastStepBeforeFooter);
  //   formSetAfterClone.ModuleType = JSON.stringify(formSet.ModuleType);
  //   formSetAfterClone.PreviousStepForSet = JSON.stringify(formSet.PreviousStepForSet);
  //   formSetAfterClone. = JSON.stringify(formSet.ModuleType);


  // }

  private bindValidations(validations: Validator[]): ValidatorFn | null {
    if (validations.length > 0) {
      const validList: ValidatorFn[] = [];
      validations.forEach((valid) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }
}

    // for (const key of Object.keys(this.data.PositionToEdit.Form.controls)) {
    //   const control = this.data.PositionToEdit.Form.controls[key].value;

    //   if (control === null || control === '') {
    //     continue;
    //   }

    //   const htmlControl: HtmlControl = {
    //     componentName: control.componentName,
    //     currentStep:  control.currentStep,
    //     displayInTablePosition:  control.displayInTablePosition,
    //     inputType: control.inputType,
    //     isLastStepInSpecificType: control.isLastStepInSpecificType,
    //     isPrint: control.isPrint,
    //     label: control.label,
    //     name: control.name,
    //     notAffectToOtherControls: control.notAffectToOtherControls,
    //     pictureWidth: control.pictureWidth,
    //     width: control.width,
    //     fileInfoId: control.fileInfoId,
    //     module: control.module,
    //     parameters: control.parameters,
    //     position: control.position,
    //     technologyVersion: control.technologyVersion,
    //     type: control.type,
    //     validations: control.validations,
    //     value: control.value,
    //     required : control.required,
    //   };

    //   if (control.inputType.toLowerCase() === 'selectmulticolumn') {
    //     htmlControl.optionsSelectMultiColumn = control.optionsSelectMultiColumn;
    //   } else if (control.inputType.toLowerCase() === 'kim') {
    //     htmlControl.optionsKim = control.optionsKim;
    //   } else {
    //     htmlControl.options = control.options;
    //   }

    //   let paramForSettingValue: Parameter;
    //   if (control.value !== undefined && control.value.Key) {
    //     paramForSettingValue = { Key: control.name, Value: control.value.Key, DisplayValue: control.value, Module: control.module, Position: control.position, Type: control.type };
    //   } else {
    //     paramForSettingValue = { Key: control.name, Value: control.value, DisplayValue: control.value, Module: control.module, Position: control.position, Type: control.type };
    //   }
    //   this.data.ParametersForEdit.push(paramForSettingValue);

    //   const newHtmlControl = JSON.parse(JSON.stringify(htmlControl));
    //   let abstControl;
    //   if (control.validations) {
    //     abstControl = this.fb.control(newHtmlControl, this.bindValidations(control.validations));
    //   } else {
    //     abstControl = this.fb.control(newHtmlControl);
    //   }
    //   this.form.addControl(key, abstControl);
    //   this.form.controls[key].disable();
    // }

