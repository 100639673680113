import { Component, OnInit, Input, } from '@angular/core';
import { TresciService } from './../tresci.service';
import { TranslationEnum } from '../shared/enums/translation-enum';



@Component({
  selector: 'app-szczegoly',
  templateUrl: './szczegoly.component.html',
  styleUrls: ['./szczegoly.component.scss']
})
export class SzczegolyComponent implements OnInit {

  public TranslationEnum = TranslationEnum;

  @Input() tresci: TresciService;
  @Input() parametry;
  @Input() parametryKim;

  list: Array<any> = [];
  moduleInf = [];
  backToArray = [];
  selectedModule: any;

  constructor() { }

  ngOnInit() {
    this.getModuleList(this.parametry);
    this.showInfo(1);
  }



  getModuleList(parameter: any) {
    for (const module of parameter) {
      if (module.Module >= 1 && module.Position === 0) {
        this.list.push(module.Module);
      }
    }

    this.backToArray = Array.from(new Set(this.list));
  }

  showInfo(module: any) {
    this.moduleInf = this.parametry.filter(x => x.Module === module && x.Position === 0);
    this.selectedModule = module;

  }




}
