import { Component, Input, OnInit } from '@angular/core';
import { FormDataV2 } from 'src/app/form-v2/form-model/FormDataV2';
import { FormV2ServiceService } from '../form-v2-service.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/user.service';
import { TresciService } from 'src/app/tresci.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationEnum } from '../../shared/enums/translation-enum';
import { KrokStep } from '../form-model/StepKrok';
import { GrupaKrokow } from '../form-model/GrupaKrokow';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';

@Component({
  selector: 'app-form-step',
  templateUrl: './form-step.component.html',
  styleUrls: ['./form-step.component.scss']
})
export class FormStepComponent implements OnInit {
  @Input() GrupaKrokow: GrupaKrokow;
  @Input() FormData: FormDataV2;
  @Input() Poprzednie: boolean;
  @Input() module: number;
  @Input() position: number;
  @Input() typ: string;
  

  public TranslationEnum = TranslationEnum;
  constructor(private formService: FormV2ServiceService,private activatedRoute: ActivatedRoute,
    private translateService: TranslateService, public tresci: TresciService, public user: UserService,public dialog: MatDialog) {
        // console.log("dialog", dialog);

    }

  ngOnInit(): void {
     
  }
  Weryfikuj(){
    this.FormData.supervisor.skip = false;
    this.FormData.supervisor.byl_skip = true;
    this.FormData.supervisor.auto = 1;
    this.FormData.next(this.tresci, this.formService, this.typ, true, this.dialog)

  }
}
