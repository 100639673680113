

export class Order {
    
    Id:Number = 0;
    Username:string;
    ContractorNumber:string;
    ProductPrefix:string;
    OrderStatusId:Number;
    OrderDate:any;
    IsRemoved:boolean;
    Description:string;
    DeliveryMethodId:Number;
    DeliveryAddressId:Number;
    ManualDeliveryCost:boolean;
    CategoryProduct:string;
    IsBlocked:boolean;
    IsTrader:boolean;
    MultiStepsForEdit:boolean;
  ComplaintId: Number;
  ComplaintNumber: string;
  ChargeableRepairsNumber: string;
  ChargeableRepairsId: Number;
}