import { FileControl } from './../shared/models/form/file-control.model';
import { Sketch } from './sketch';
import { Kim } from './kim';

import { Select } from './select';
import { Input } from './input';
import { Uwagi } from './uwagi';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { deserialize, serialize } from 'class-transformer';
import { TresciService } from '../tresci.service';
import { Kroki } from './kroki';
import { Module } from './module';
import { Check } from './check';
import { Control } from '../shared/models/form/control.model';
import { take } from 'rxjs/operators';
declare var $: any;

export class Konfigurator {
  public kontrolki: Kontrolka[] = [];
  public PriceBrutto = 0;
  public PriceNetto = 0;
  public Vat = 0;
  public DeliveryDate;
  public RealizationDate;
  public system;
  public Currency = 'PLN';
  public wizualizacja = [];
  public wizualizacja_id = [];
  public pokaz = 'nie_pokazuj';
  public wczytuje = false;
  private wiz_tkanina = '';
  private wiz_kolor_konstr = '';
  private wiz_falbana = '';
  public typ = '';
  public daszek = '';
  public Tkanina = '';
  public Oswietlenie = '';
  public KolorPior = '';
  public KolorLameli = '';
  public rabat;
  public PriceAmountDiscount = 0;
  public PricePercentageDiscount = 0;
  public wczytywanie_wstepne = true;
  public token = '';
  public id = 0;
  public poprawny = false;
  public foto_id = null;
  public foto_list = [];
  public tmp_id = 0;
  public jest_label = false;
  public timeout = null;
  constructor(private http: HttpClient, typ, private tresci: TresciService, Kontrolki, id, private kroki: Kroki, private ParametryW, private ParametryWKim, public modul, public pozycja) {
    this.id = id;
    // constructor(private http: HttpClient, system, private sanitizer: DomSanitizer, typ, private tresci: TresciService) {
    this.typ = typ;
    this.wczytaj(Kontrolki);
    this.tresci.wizualizacja = [];
    this.tresci.foto_uwaga = false;
    // this.system = system;
    // this.wczytaj();
  }

  public Wartosc(Key) {
    let wartosc = null;
    this.kontrolki.forEach(element => {
      if (element.KeyName === Key) {
        wartosc = element.getDisplayValue();
      }
    });
    return wartosc;
  }
  public WartoscKod(Key) {
    let wartosc = null;
    this.kontrolki.forEach(element => {
      if (element.KeyName === Key) {
        wartosc = element.getValue();
      }
    });
    return wartosc;
  }

  public changeInput() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getLabels();
    }, 500);
  }
  public obliczCeny() {
    if (this.rabat != null) {
      if (this.rabat.AmountDiscount != null) {
        this.PriceAmountDiscount = Math.ceil(this.PriceBrutto - this.rabat.AmountDiscount);
      }
      if (this.rabat.PercentageDiscount != null) {
        this.PricePercentageDiscount = Math.ceil(this.PriceBrutto * (100 - this.rabat.PercentageDiscount) / 100);
      }
    }
  }
  public getHeaderJson() {
    const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' +
      this.token).set('Content-Type', 'application/json');
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }

  public getParams() {
    const dane: any[] = [];
    // dane.push({'Key': 'System', 'Value': this.system});
    this.kontrolki.forEach(element => {
      if (element.ControlType !== 'NewLine' && element.ControlType !== 'Foto' && element.ControlType !== 'Label') {
        dane.push({ 'Key': element.KeyName, 'Value': element.getValue(), 'Position': this.pozycja, 'Module': this.modul });
      }
    });
    return dane;
  }

  public wczytaj(kontrolki) {
    for (let index = 0; index < kontrolki.length; index++) {
      const element = kontrolki[index];
      if (index > 0) {
        this.kontrolki.push(new Kontrolka(kontrolki[index].ControlType, kontrolki[index].TranslateCode,
          kontrolki[index].PictureCode, kontrolki[index].DescriptionCode,
          kontrolki[index].KeyName, kontrolki[index].Parameters, kontrolki[index].Values,
          kontrolki[index].ValuesEn, kontrolki[index].ValuesDe, kontrolki[index].ValuesFr,
          kontrolki[index].ParameterPosition, this.id, this, this.ParametryW, kontrolki[index - 1].ControlType,
          this.tresci, this.kroki, this.ParametryWKim, this.modul, this.pozycja,
          kontrolki[index].IsPrint));
      } else {
        this.kontrolki.push(new Kontrolka(kontrolki[index].ControlType, kontrolki[index].TranslateCode,
          kontrolki[index].PictureCode, kontrolki[index].DescriptionCode,
          kontrolki[index].KeyName, kontrolki[index].Parameters, kontrolki[index].Values, kontrolki[index].
          ValuesEn, kontrolki[index].ValuesDe, kontrolki[index].ValuesFr, kontrolki[index].ParameterPosition,
          this.id, this, this.ParametryW, '', this.tresci, this.kroki, this.ParametryWKim, this.modul, this.pozycja,
          kontrolki[index].IsPrint));
      }
    }
  }
  public sprawdz(kontrolki) {
    let poprawny = true;
    if (this.kontrolki.length === kontrolki.length) {
      for (let index = 0; index < this.kontrolki.length; index++) {
        if (this.kontrolki[index].KeyName !== kontrolki[index].KeyName) {
          poprawny = false;
        } else {
          if (!this.kontrolki[index].sprawdzPoprawnosc(kontrolki[index].Values, kontrolki[index].Parameters)) {
            poprawny = false;
          }
        }
      }
    } else {
      poprawny = false;
    }
    return poprawny;
  }
  public CzyPoprawny() {
    return this.poprawny;
  }

  public aktualizuj_key(event) {

    if (event.keyCode === 13) {
      this.aktualizuj();
    }
  }


  public aktualizuj() {
    // this.kontrolki.forEach(element => {
    // element.change();
    // });
    this.tresci.zamowienie.zapisz_Parametry();
    this.getWiz2();

    //
    this.getLabels();
    //
    /*
    const dane: any[] = [];
    dane.push({'Key': 'System', 'Value': this.system});
    dane.push({'Key': 'TypSystemu', 'Value': this.typ});
    this.kontrolki.forEach(element => {
      dane.push({'Key': element.KeyName, 'Value': element.getValue()});
    });
     const request = new HttpRequest('POST',
       '/api/Configurator/GetConfigurator?typ=' + this.typ,
     dane,
    {headers: this.getHeaderJson()});
      this.http.request(request)
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.DownloadProgress:
              const loaded = Math.round(event.loaded / 1024);
              break;
            case HttpEventType.Response:
              for (let index = 0; index < event.body.length; index++) {
                const element = event.body[index];
                this.kontrolki[index].aktualizuj(event.body[index].ControlType,
                  event.body[index].KeyName, event.body[index].Parameters, event.body[index].Values);
              }
              this.getWycena();
              this.getWiz();
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
          }
        });
        */
  }
  public getWiz2(params_plus = []) {
    // this.tresci.wizualizacja = [];
    // this.tresci.wizualizacja_id = [];
    this.wizualizacja = [];
    this.wizualizacja_id = [];
    this.tmp_id++;
    const tmp_it_moj = this.tmp_id;
    let bylo = false;
    this.foto_list.forEach(foto => {
      bylo = true;
      this.getWiz(foto, params_plus, false, tmp_it_moj);
    });
    if (!bylo) {
      this.tresci.wizualizacja = [];
      this.tresci.wizualizacja_id = [];
    }
  }
  public getWiz(foto_id = null, params_plus = [], add = true, tmp_it_moj = null) {
    if (foto_id != null && add) {
      // this.foto_id = foto_id.id;
      this.foto_list.push(foto_id);
    }
    let wczytuj = true;
    /* const dane: any[] = [];
    this.kontrolki.forEach(element => {
        dane.push({'Key': element.KeyName, 'Value': element.getValue()});
    }); */

    const dane = this.kroki.getParamsSoft();
    params_plus.forEach(element => {
      dane.push({ Key: element.Key, Value: element.Value, DisplayValue: element.Value });
    });
    dane.push({ Key: 'id', Value: foto_id.id, DisplayValue: foto_id.id });
    dane.push({ Key: 'jezyk', Value: this.tresci.jezyk_id, DisplayValue: this.tresci.jezyk_id });
    dane.push({ Key: 'SystemID', Value: this.tresci.system, DisplayValue: this.tresci.system });
    // dane.push({'Key': 'TypSystemu', 'Value': this.typ});
    // tslint:disable-next-line:max-line-length
    // this.tresci.copyMessage(serialize(dane), 'Kopia parametrów dla wizualizacji (Render)', 1);
    if (wczytuj) {
      const request = new HttpRequest('POST',
          '/api/ActiveForm/GetImage?typ=' + this.typ + '&width=1080', // + $(window).width(),
        dane,
        { responseType: 'blob', headers: this.tresci.user.getHeaderJson() });
      this.http.request(request).pipe(/*take(1)*/)
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Response:
              const urlCreator = window.URL;

              if (tmp_it_moj == null || tmp_it_moj === this.tmp_id) {
                this.wizualizacja.push(this.tresci.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(event.body)));
                this.tresci.id_krok_wizual = this.id;
                this.wizualizacja_id.push(foto_id.pole);
                this.tresci.wizualizacja = this.wizualizacja;
                this.tresci.wizualizacja_id = this.wizualizacja_id;
              }

              // this.tresci.zwijanie = '';
              this.tresci.foto_uwaga = true;
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // this.tresci.wizualizacja = [];
            // this.tresci.wizualizacja = [];
            // this.tresci.foto_uwaga = false;
          }
        });
    }
  }
  public getLabels() {
    if (this.jest_label) {
      this.kroki.wczytajLabele(this.getParams(), this);
    }
  }
  public poza() {
    for (let index = 0; index < this.kontrolki.length; index++) {
      this.kontrolki[index].poza();
    }
  }
  public czyNieWybrany() {
    let niewybrany = false;
    this.kontrolki.forEach(element => {
      if (element.czyNieWybrany()) {
        niewybrany = true;
      }
    });
    return niewybrany;
  }
  public getWycena() {
    const dane: any[] = [];
    // dane.push({'Key': 'System', 'Value': this.system});
    // dane.push({'Key': 'TypSystemu', 'Value': this.typ});
    this.kontrolki.forEach(element => {
      dane.push({ 'Key': element.KeyName, 'Value': element.getValue() });
    });
    // this.tresci.copyMessage(serialize(dane), 'Kopia parametrów dla wyceny', 1);
    const request = new HttpRequest('POST',
        '/api/ActiveForm/GetValuation?typ=' + this.typ,
      dane,
      { headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.DownloadProgress:
            const loaded = Math.round(event.loaded / 1024);
            break;
          case HttpEventType.Response:
            this.PriceBrutto = Math.floor(event.body.PriceBrutto);
            this.PriceNetto = event.body.PriceNetto;
            this.DeliveryDate = event.body.DeliveryDate;
            this.RealizationDate = event.body.RealizationDate;
            this.Vat = event.body.Vat;
            this.obliczCeny();
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
        }
      });
  }
}

class Kontrolka {
  public select: Select;
  public selectModal: Select;
  public radio: Select;
  public slider;
  public InputNumber: Input;
  public InputText = '';
  public Kim: Kim;
  public module: Module;
  public ControlType: string;
  public KeyName: string;
  public TranslateCode: string;
  public PictureCode: string;
  public DescriptionCode: string;
  public Parameters: any;
  public ParameterPosition = 3;
  public uwagi: Uwagi = new Uwagi('');
  public hidden = '';
  public hiddenPL = '';
  public hiddenEN = '';
  public hiddenDE = '';
  public hiddenFR = '';
  public id = 0;
  public foto_id = null;
  public foto = null;
  public typ = 0;
  public nie_wybrany = true;
  public wyszukiwane = '';
  public IsPrint = false;
  public sketch: Sketch;
  public checkKontrolka = null;

  // ------------------- File -------------------
  public fileControl: FileControl = new FileControl();
  // ------------------- File -------------------

  constructor(ControlType, TranslateCode, PictureCode, DescriptionCode, KeyName, Parameters, public Values,
    public ValuesEn, public ValuesDe, public ValuesFr, ParameterPosition, id, private konfigurator: Konfigurator,
    private ParametryW, public prevControlType, public tresci: TresciService, private kroki: Kroki, private ParametryWKim,
    public modul, public pozycja, IsPrint) {
    this.id = id;
    this.ControlType = ControlType;
    this.IsPrint = IsPrint;
    this.KeyName = KeyName;
    this.TranslateCode = TranslateCode;
    this.PictureCode = PictureCode;
    this.DescriptionCode = DescriptionCode;
    this.Parameters = Parameters;
    this.ParameterPosition = ParameterPosition;


    if (this.ControlType === 'Komunikat' && !this.tresci.zamowienie.czyWczytuje()) {
      const t = JSON.parse(Parameters[0].Value);

      this.tresci.alert.wyswietl(t.Value, t.Text);
      this.kroki.pozycjaKrok = this.kroki.pozycjaKrok - 1;
      // this.kroki.dalej(this.kroki.pozycjaKrok - 1, false);
      // this.kroki.wczytajKrok(0);
    }



    if (this.Values != null && this.Values.length > 0) {
      this.typ = this.Values[0].Type;
    }

    if ((this.ControlType === 'InputNumber' || this.ControlType === 'SliderNumber'
      || this.ControlType === 'CheckInput') || this.ControlType === 'CheckNumber' && Parameters != null) {
      let min = 0;
      let max = 0;
      let value = 0;

      Parameters.forEach(element => {
        if (element.Key === 'Min') {
          min = element.Value;
        }
        if (element.Key === 'Max') {
          max = element.Value;
        }
        if (element.Key === 'Value') {
          value = element.Value;
          this.nie_wybrany = false;
        }
      });
      if (this.ControlType === 'InputNumber') {
        this.InputNumber = new Input(this.id, this.uwagi, this.KeyName, min, max, value, false, this.tresci, ControlType);
      } else if (this.ControlType === 'CheckInput') {
        this.InputNumber = new Input(this.id, this.uwagi, this.KeyName, min, max, value, true, this.tresci, ControlType, false);
      } else if (this.ControlType === 'CheckNumber') {
        this.InputNumber = new Input(this.id, this.uwagi, this.KeyName, min, max, value, true, this.tresci, ControlType, false);
      } else {
        this.InputNumber = new Input(this.id, this.uwagi, this.KeyName, min, max, value, true, this.tresci, ControlType);
      }
      ParametryW.forEach(Parametr => {
        if (Parametr.Key === this.KeyName) {
          this.tresci.zamowienie.parametry_wczytwane_licznik++;
          this.InputNumber.ustaw(Parametr.Value, this.ControlType);
          this.nie_wybrany = false;
          this.tresci.zamowienie.parametryWczytane.forEach(element => {
            if (element.Key === this.KeyName && element.Module === this.modul && element.Position === this.pozycja) {
              if (element.Ile == null) {
                element.Ile = 1;
              } else {
                element.Ile++;
              }
            }
          });
        }
      });
    } else if (this.ControlType === 'InputText') {
      if ((Parameters == null || Parameters[0] == null || Parameters[0].Value === 'N')
        && this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName) != null) {
        this.InputText = this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName);
      } else if (Parameters == null || Parameters[0] == null) {
        this.InputText = this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName);
      } else {
        this.InputText = Parameters[0].Value;
        // sessionStorage.setItem('konf_' + this.id + '_' + this.KeyName, Parameters[0].Value);
      }
      ParametryW.forEach(Parametr => {
        if (Parametr.Key === this.KeyName) {
          this.tresci.zamowienie.parametry_wczytwane_licznik++;
          this.InputText = Parametr.Value;
          this.nie_wybrany = false;
          this.tresci.zamowienie.parametryWczytane.forEach(element => {
            if (element.Key === this.KeyName && element.Module === this.modul && element.Position === this.pozycja) {
              if (element.Ile == null) {
                element.Ile = 1;
              } else {
                element.Ile++;
              }

            }
          });
        }
      });
      if (this.InputText === null) {
        this.InputText = '';
      }
      this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, this.InputText);
      this.nie_wybrany = false;

    } else if (this.ControlType === 'Label') {
      if (Parameters == null || Parameters[0] == null) {
        this.InputText = '';
      } else {
        this.InputText = Parameters[0].Value;
      }
      this.konfigurator.jest_label = true;
      this.kroki.wczytajLabele(this.konfigurator.getParams(), this.konfigurator);
      this.nie_wybrany = false;

    } else if (this.ControlType === 'CheckCombo') {
      this.select = new Select(this.id, this.tresci, false);
      this.select.wczytaj(this.uwagi, this.KeyName, Values, ValuesEn, ValuesDe, ValuesFr, Parameters, false, false);
      ParametryW.forEach(Parametr => {
        if (Parametr.Key === this.KeyName) {
          this.tresci.zamowienie.parametry_wczytwane_licznik++;
          this.select.ustaw(Parametr.Value, true, false);
          this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, Parametr.Value);
          this.nie_wybrany = false;
          this.tresci.zamowienie.parametryWczytane.forEach(element => {
            if (element.Key === this.KeyName && element.Module === this.modul && element.Position === this.pozycja) {
              if (element.Ile == null) {
                element.Ile = 1;
              } else {
                element.Ile++;
              }

            }
          });
        }
      });
    } else if (this.ControlType === 'Select' || this.ControlType === 'ComboFoto') {
      this.select = new Select(this.id, this.tresci);
      this.select.wczytaj(this.uwagi, this.KeyName, Values, ValuesEn, ValuesDe, ValuesFr, Parameters, false);
      ParametryW.forEach(Parametr => {
        if (Parametr.Key === this.KeyName) {
          this.tresci.zamowienie.parametry_wczytwane_licznik++;
          this.select.ustaw(Parametr.Value, true);
          this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, Parametr.Value);
          this.nie_wybrany = false;
          this.tresci.zamowienie.parametryWczytane.forEach(element => {
            if (element.Key === this.KeyName && element.Module === this.modul && element.Position === this.pozycja) {
              if (element.Ile == null) {
                element.Ile = 1;
              } else {
                element.Ile++;
              }

            }
          });
        }
      });
    } else if (this.ControlType === 'SelectImg') {
      this.select = new Select(this.id, this.tresci);
      this.select.wczytaj(this.uwagi, this.KeyName, Values, ValuesEn, ValuesDe, ValuesFr, Parameters, true);
      ParametryW.forEach(Parametr => {
        if (Parametr.Key === this.KeyName) {
          this.tresci.zamowienie.parametry_wczytwane_licznik++;
          this.select.ustaw(Parametr.Value, true);
          this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, Parametr.Value);
          this.nie_wybrany = false;
          this.tresci.zamowienie.parametryWczytane.forEach(element => {
            if (element.Key === this.KeyName && element.Module === this.modul && element.Position === this.pozycja) {
              if (element.Ile == null) {
                element.Ile = 1;
              } else {
                element.Ile++;
              }

            }
          });
        }
      });
    } else if (this.ControlType === 'SelectModal' || this.ControlType === 'Fabric' || this.ControlType === 'CheckFabric') {
      this.selectModal = new Select(this.id, this.tresci);
      this.selectModal.wczytaj(this.uwagi, this.KeyName, Values, ValuesEn, ValuesDe, ValuesFr, Parameters, true);
      ParametryW.forEach(Parametr => {
        if (Parametr.Key === this.KeyName) {
          this.tresci.zamowienie.parametry_wczytwane_licznik++;
          this.selectModal.ustaw(Parametr.Value, true);
          this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, Parametr.Value);
          this.nie_wybrany = false;
          this.tresci.zamowienie.parametryWczytane.forEach(element => {
            if (element.Key === this.KeyName && element.Module === this.modul && element.Position === this.pozycja) {
              if (element.Ile == null) {
                element.Ile = 1;
              } else {
                element.Ile++;
              }

            }
          });
        }
      });
    } else if (this.ControlType === 'Radio') {
      this.radio = new Select(this.id, this.tresci);
      this.radio.wczytaj(this.uwagi, this.KeyName, Values, ValuesEn, ValuesDe, ValuesFr, Parameters, false);
      ParametryW.forEach(Parametr => {
        if (Parametr.Key === this.KeyName) {
          this.tresci.zamowienie.parametry_wczytwane_licznik++;
          this.radio.ustaw(Parametr.Value, true);
          this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, Parametr.Value);
          this.nie_wybrany = false;
          this.tresci.zamowienie.parametryWczytane.forEach(element => {
            if (element.Key === this.KeyName && element.Module === this.modul && element.Position === this.pozycja) {
              if (element.Ile == null) {
                element.Ile = 1;
              } else {
                element.Ile++;
              }

            }
          });
        }
      });
    } else if (this.ControlType === 'Hidden') {
      if (Parameters[0].Value === 'N' && sessionStorage.getItem('konf_' + this.id + '_' + this.KeyName) != null) {
        this.hidden = sessionStorage.getItem('konf_' + this.id + '_' + this.KeyName);
      } else {
        this.hidden = Parameters[0].Value;
        if (Values != null && Values[0] != null) {
          this.hiddenPL = Values[0].Value;
          this.hiddenDE = Values[0].ValuesDe;
          this.hiddenEN = Values[0].ValuesEn;
          this.hiddenFR = Values[0].ValuesFr;
        } else {
          this.hiddenPL = Parameters[0].Value;
          this.hiddenDE = Parameters[0].Value;
          this.hiddenEN = Parameters[0].Value;
          this.hiddenFR = Parameters[0].Value;
        }
        sessionStorage.setItem('konf_' + this.id + '_' + this.KeyName, Parameters[0].Value);
      }
      ParametryW.forEach(Parametr => {
        if (Parametr.Key === this.KeyName) {
          this.tresci.zamowienie.parametry_wczytwane_licznik++;
          this.hidden = Parametr.Value;
          this.tresci.zamowienie.parametryWczytane.forEach(element => {
            if (element.Key === this.KeyName && element.Module === this.modul && element.Position === this.pozycja) {
              if (element.Ile == null) {
                element.Ile = 1;
              } else {
                element.Ile++;
              }

            }
          });
        }
      });
      this.nie_wybrany = false;
    } else if (this.ControlType === 'Foto') {
      if (this.KeyName === 'TrescUwagi') {
        this.InputText = Parameters[0].Value;
      } else {
        if (Parameters[0].Value === 'N' && sessionStorage.getItem('konf_' + this.id + '_' + this.KeyName) != null) {
          this.foto_id = sessionStorage.getItem('konf_' + this.id + '_' + this.KeyName);
          this.konfigurator.getWiz(this.foto_id);
        } else {
          this.foto_id = Parameters[0].Value;
          sessionStorage.setItem('konf_' + this.id + '_' + this.KeyName, Parameters[0].Value);
          this.foto = { 'pole': this.KeyName, 'id': Parameters[0].Value };
          this.konfigurator.getWiz(this.foto, this.konfigurator.getParams());

        }
      }
      this.nie_wybrany = false;

    } else if (this.ControlType === 'Kim') {
      this.Kim = new Kim(this.id, this.KeyName, this.Values, this.ValuesDe, this.ValuesEn, this.ValuesFr, this.tresci, this.ParametryWKim);
      this.nie_wybrany = false;
    } else if (this.ControlType === 'Sketch') {
      const param = this.kroki.getParams();
      konfigurator.getParams().forEach(element => {
        param.push(element);
      });
      this.sketch = new Sketch(this.tresci, param, this.KeyName);
      this.nie_wybrany = false;
    } else if (this.ControlType === 'Module') {
      let paramW = [];
      ParametryW.forEach(Parametr => {
        if (Parametr.Key === this.KeyName) {
          paramW = Parametr.Value;
        }
      });
      this.module = new Module(this.id, this.uwagi, this.KeyName, Parameters[0].Value, this.tresci, paramW, this.modul);
    } else if (this.ControlType === 'Check') {
      let paramW = null;
      ParametryW.forEach(Parametr => {
        if (Parametr.Key === this.KeyName) {
          paramW = Parametr.Value;
        }
      });
      this.checkKontrolka = new Check(this.id, this.uwagi, this.KeyName, this.ControlType, this.tresci, paramW);
      this.nie_wybrany = false;
    } else if (this.ControlType === 'File' || this.ControlType === 'CheckFile') {
      const fileId = sessionStorage.getItem('konf_' + this.id + '_' + this.KeyName + '_' + this.pozycja + '_' + this.modul);
      const file = this.tresci.zamowienie.fileControls.find(x => x.Id === fileId);
      const parametr = ParametryW.find((x: Control) => x.Key === this.KeyName);

      if (file !== undefined) {
        this.fileControl.Key = file.Key;
        this.fileControl.Id = file.Id;
        this.fileControl.FileName = file.FileName;
        this.fileControl.Position = file.Position;
        this.fileControl.Module = file.Module;
        this.fileControl.File = file.File;
        this.nie_wybrany = false;
      } else if (parametr !== undefined && this.tresci.komponent !== 'NewOrder') {
        this.tresci.zamowienie.parametry_wczytwane_licznik++;
        this.fileControl.Key = parametr.Key;
        this.fileControl.Id = parametr.Value;
        this.fileControl.FileName = parametr.DisplayValue;
        this.fileControl.Position = parametr.Position;
        this.fileControl.Module = parametr.Module;
        this.nie_wybrany = false;
        this.tresci.zamowienie.parametryWczytane.forEach(element => {
          if (element.Key === this.KeyName && element.Module === this.modul && element.Position === this.pozycja) {
            if (element.Ile == null) {
              element.Ile = 1;
            } else {
              element.Ile++;
            }
          }
        });
        if (parametr.Value === 'X') {
          this.fileControl.Check = false;
        } else {
          this.tresci.zamowienie.fileControls.push(this.fileControl);
        }
      } else {
        this.fileControl.Key = Parameters[0].Key;
        this.fileControl.Id = Parameters[0].Value;
        this.fileControl.FileName = Parameters[0].DisplayValue;
        this.fileControl.Position = Parameters[0].Position;
        this.fileControl.Module = Parameters[0].Module;
        this.nie_wybrany = true;
      }

      if (this.ControlType === 'CheckFile') {
        this.nie_wybrany = false;
      }

      sessionStorage.setItem('konf_' + this.id + '_' + this.KeyName + '_' + this.pozycja + '_' + this.modul, this.fileControl.Id);
    } else {
      this.nie_wybrany = false;
    }
  }

  public czyNieWybrany() {
    if ((this.ControlType === 'InputNumber' || this.ControlType === 'SliderNumber')) {
      return this.nie_wybrany;
    } else if (this.ControlType === 'CheckInput') {
      if (!this.InputNumber.check) {
        return false;
      } else {
        return false;
        // return this.nie_wybrany;
      }
    } else if (this.ControlType === 'CheckNumber') {
      if (!this.InputNumber.check) {
        return false;
      } else {
        return false;
        // return this.nie_wybrany;
      }
    } else if (this.ControlType === 'InputText') {
      return this.nie_wybrany;
    } else if (this.ControlType === 'Select' || this.ControlType === 'ComboFoto') {
      return this.select.czyNieWybrany();
    } else if (this.ControlType === 'CheckCombo') {
      return this.select.czyNieWybrany();
    } else if (this.ControlType === 'SelectImg') {
      return this.select.czyNieWybrany();
    } else if (this.ControlType === 'SelectModal' || this.ControlType === 'Fabric' || this.ControlType === 'CheckFabric') {
      return this.selectModal.czyNieWybrany();
    } else if (this.ControlType === 'Radio') {
      return this.nie_wybrany;
    } else if (this.ControlType === 'Module') {
      return !this.module.wybrany;
    } else if (this.ControlType === 'Hidden') {
      return this.nie_wybrany;
    } else if (this.ControlType === 'Foto') {
      return this.nie_wybrany;
    } else {
      return this.nie_wybrany;
    }
  }
  public InputTextChange() {
    this.nie_wybrany = false;
    this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, this.InputText);
    //sessionStorage.setItem('konf_' + this.id + '_' + this.KeyName, this.InputText);
    this.tresci.zamowienie.zapisz_Parametry();
  }
  public szukaj(event) {
    event.stopPropagation();
  }
  public change() {
    this.konfigurator.getWiz2();
  }

  public porownaj(string, Value, Key) {
    string = string.toUpperCase();
    Value = Value.toUpperCase();
    Key = Key.toUpperCase();
    let ok = false;
    if (Value.indexOf(string) != -1) {
      ok = true;
    }
    if (Key.indexOf(string) != -1) {
      ok = true;
    }
    return ok;
  }
  public sprawdzPoprawnosc(Values, Parameters) {
    let poprawny = false;
    if (Values != null) {
      Values.forEach(val => {
        if (val.Key === this.getValue()) {
          poprawny = true;
        }
      });
    } else {
      if (this.ControlType === 'InputNumber' || (this.ControlType === 'CheckInput' && this.InputNumber.check) ||
        (this.ControlType === 'CheckNumber' && this.InputNumber.check)) {
        let min = 0;
        let max = 1000000;
        Parameters.forEach(element => {
          if (element.Key === 'Min') {
            min = element.Value * 1;
          }
          if (element.Key === 'Max') {
            max = element.Value * 1;
          }
        });
        if (this.getValue() >= min && this.getValue() <= max) {
          poprawny = true;
        }
      } else if (this.ControlType === 'Hidden' || this.ControlType === 'Foto'
        || this.ControlType === 'NewLine' || this.ControlType === 'Label'
        || this.ControlType === 'Sketch' || this.ControlType === 'CheckInput' || this.ControlType === 'CheckNumber') {
        poprawny = true;
      }
    }
    if (this.ControlType === 'InputText') {
      if (this.getValue() === this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName)) {
        poprawny = true;
      }
    }
    if (!poprawny) {
    }
    if (this.ControlType === 'Kim' || this.ControlType === 'Hidden'
      || this.ControlType === 'Sketch' || this.ControlType === 'Module'
      || this.ControlType === 'ModuleView') {
      poprawny = true;
    }
    if (!poprawny) {
    }

    if (this.ControlType === 'Check') {
      poprawny = true;
    }
    return poprawny;
  }

  public aktualizuj(ControlType, KeyName, Parameters, Values, ParameterPosition) {
    this.nie_wybrany = false;
    this.tresci.zamowienie.zapisz_Parametry();
    if (this.KeyName === KeyName) {
      this.ControlType = ControlType;
      this.Parameters = Parameters;
      this.ParameterPosition = ParameterPosition;
      this.Values = Values;
      if ((this.ControlType === 'InputNumber' || this.ControlType === 'SliderNumber'
        || this.ControlType === 'CheckInput' || this.ControlType === 'CheckNumber') && Parameters != null) {
        let min = 0;
        let max = 0;
        let value = 0;
        Parameters.forEach(element => {
          if (element.Key === 'Min') {
            min = element.Value;
          }
          if (element.Key === 'Max') {
            max = element.Value;
          }
          if (element.Key === 'Value') {
            value = element.Value;
          }
        });
        if (this.ControlType === 'InputNumber') {
          this.InputNumber.aktualizuj(min, max, value, false);
        } else if (this.ControlType === 'CheckInput' || this.ControlType === 'CheckNumber') {
          this.InputNumber.aktualizuj(min, max, value, false);
        } else {
          this.InputNumber.aktualizuj(min, max, value, true);
        }
      } else if (this.ControlType === 'CheckCombo') {
        this.select.aktualizuj(Values);

      } else if (this.ControlType === 'Select' || this.ControlType === 'ComboFoto') {
        this.select.aktualizuj(Values);

      } else if (this.ControlType === 'SelectModal' || this.ControlType === 'Fabric' || this.ControlType === 'CheckFabric') {
        this.selectModal.aktualizuj(Values);
      } else if (this.ControlType === 'SelectImg') {
        this.select.aktualizuj(Values);
      } else if (this.ControlType === 'Radio') {
        this.radio.aktualizuj(Values);
      } else if (this.ControlType === 'Hidden') {
        this.hidden = Parameters[0].Value;
        if (Values != null && Values[0] != null) {
          this.hiddenPL = Values[0].Value;
          this.hiddenDE = Values[0].ValuesDe;
          this.hiddenEN = Values[0].ValuesEn;
          this.hiddenFR = Values[0].ValuesFr;
        } else {
          this.hiddenPL = Parameters[0].Value;
          this.hiddenDE = Parameters[0].Value;
          this.hiddenEN = Parameters[0].Value;
          this.hiddenFR = Parameters[0].Value;
        }
        sessionStorage.setItem('konf_' + this.id + '_' + this.KeyName, Parameters[0].Value);
      } else if (this.ControlType === 'Label') {
        this.InputText = Parameters[0].Value;
        this.kroki.wczytajLabele(this.konfigurator.getParams(), this.konfigurator);
      } else if (this.ControlType === 'InputText') {
        sessionStorage.setItem('konf_' + this.id + '_' + this.KeyName, this.InputText);
      } else if (this.ControlType === 'Foto') {
        sessionStorage.setItem('konf_' + this.id + '_' + this.KeyName, this.foto_id);
        // this.konfigurator.getWiz();
      } else if (this.ControlType === 'Kim') {
      } else if (this.ControlType === 'Module') {
      } else if (this.ControlType === 'ModuleView') {
      }
    }
  }

  public poza() {
    if (this.ControlType === 'Select' || this.ControlType === 'SelectImg') {
      this.select.poza();
    } else if (this.ControlType === 'SelectModal' || this.ControlType === 'Fabric' || this.ControlType === 'CheckFabric') {
      this.selectModal.poza();
    } else if (this.ControlType === 'Radio') {
      this.radio.poza();
    }
  }
  public getValue() {
    if (this.czyNieWybrany()) {
      return 'X';
    } else {
      if (this.ControlType === 'Select' || this.ControlType === 'SelectImg' || this.ControlType === 'ComboFoto') {
        return this.select.getActual();
      }
      if (this.ControlType === 'Check') {
        return this.checkKontrolka.getActual();
      }
      if (this.ControlType === 'CheckCombo') {
        return this.select.getActual();
      }
      if (this.ControlType === 'SelectModal' || this.ControlType === 'Fabric' || this.ControlType === 'CheckFabric') {
        return this.selectModal.getActual();
      }
      if (this.ControlType === 'InputNumber' || this.ControlType === 'SliderNumber' ||
        this.ControlType === 'CheckInput' || this.ControlType === 'CheckNumber') {
        return this.InputNumber.getActual();
      }
      if (this.ControlType === 'Radio') {
        return this.radio.getActual();
      }
      if (this.ControlType === 'Hidden') {
        return this.hidden;
      }
      if (this.ControlType === 'Label') {
        return this.InputText;
      }
      if (this.ControlType === 'Module') {
        return this.module.getActual();
      }
      if (this.ControlType === 'InputText') {
        if (this.InputText != null) {
          return this.InputText;
        } else {
          return '';
        }
      }
      if (this.ControlType === 'Sketch') {
        return '';
      }
      if (this.ControlType === 'File') {
        return this.fileControl.Id;
      }
    }

    return 'X';
  }
  public getDisplayValue(jezyk = null) {

    if (this.ControlType === 'CheckCombo') {
      return this.select.getActualDisplay(jezyk);
    }
    if (this.ControlType === 'Check') {
      return this.checkKontrolka.getActual();
    }
    if (this.ControlType === 'Select' || this.ControlType === 'SelectImg' || this.ControlType === 'ComboFoto') {
      return this.select.getActualDisplay(jezyk);
    }
    if (this.ControlType === 'SelectModal' || this.ControlType === 'Fabric' || this.ControlType === 'CheckFabric') {
      return this.selectModal.getActualDisplay(jezyk);
    }
    if (this.ControlType === 'InputNumber' || this.ControlType === 'SliderNumber' ||
      this.ControlType === 'CheckInput' || this.ControlType === 'CheckNumber') {
      return this.InputNumber.getActual();
      // return this.InputNumber.Value;
    }
    if (this.ControlType === 'Radio') {
      return this.radio.getActualDisplay(jezyk);
    }
    if (this.ControlType === 'Hidden') {
      if (jezyk == null) {
        jezyk = this.tresci.jezyk_id + '';
      }
      if (jezyk === '1') {
        return this.hiddenPL;
      } else if (jezyk === '3') {
        return this.hiddenDE;
      } else if (jezyk === '4') {
        return this.hiddenFR;
      } else {
        return this.hiddenEN;
      }
    }
    if (this.ControlType === 'Label') {
      return this.InputText;
    }
    if (this.ControlType === 'InputText') {
      if (this.InputText != null) {
        return this.InputText;
      } else {
        return '';
      }
    }
    if (this.ControlType === 'Kim') {
      return this.Kim.getDisplayValue();
    }
    if (this.ControlType === 'Module') {
      return this.module.getDisplay();
    }
    if (this.ControlType === 'File') {
      return this.fileControl.FileName;
    }
    if (this.ControlType === 'CheckFile') {
      return this.fileControl.FileName;
    }
    return 'X';
  }

  // ------------------- File -------------------
  fileChange(files: FileList, position, module) {
    const file = files.item(0);

    if (this.tresci.zamowienie.fileControls.some(x => x === this.fileControl)) {
      this.tresci.zamowienie.fileControls.splice(this.tresci.zamowienie.fileControls.indexOf(this.fileControl), 1);
    }

    this.fileControl.FileName = file.name;
    this.fileControl.Position = position;
    this.fileControl.Module = module;
    this.fileControl.File = file;

    this.tresci.zamowienie.fileControls.push(this.fileControl);
    this.nie_wybrany = false;
  }
  // ------------------- File -------------------
}
