<div class="p-3 d-block overflow-hidden">
  <h3 class="pl-2 mt-3 mb-1">
    <span id="dostawa" [translation]="TranslationEnum.TranslationForm">{{ 'dostawa' | translate }}</span>
    <app-information [label]="'sposob_dostawy'" [fileInfoId]="'plik_info_sposob_dostawy_' + FileInfoId"></app-information>
  </h3>

  <mat-button-toggle-group class="border-0 rounded-0 mt-3" [disabled]="IsEdit">
    <mat-button-toggle [checked]="DeliveryMethod.Id === 2" (change)="DeliveryMethod = DeliveryMethods[0]"  (click)="ZmienDelivery(0)" id="odbior_wlasny" [translation]="TranslationEnum.TranslationForm">
      <img #ownCollection class="d-block m-auto" height="50px" width="35px" src="../../../assets/images/own_collection_{{ DeliveryMethod.Id === 2 }}.svg" />
      {{ 'odbior_wlasny' | translate }}
    </mat-button-toggle>
    <mat-button-toggle [checked]="DeliveryMethod.Id === 3" (change)="DeliveryMethod = DeliveryMethods[1]" (click)="ZmienDelivery(1)" id="selt" [translation]="TranslationEnum.TranslationForm">
      <img #selt class="d-block m-auto" height="50px" width="30px" src="../../../assets/images/delivery_selt_{{ DeliveryMethod.Id === 3 }}.svg" />
      {{ 'selt' | translate }}
    </mat-button-toggle>
    <mat-button-toggle *ngIf="contractor.Country !== 'PL' || authService.User.value.IsTrader" (click)="ZmienDelivery(2)" [checked]="DeliveryMethod.Id === 4" (change)="DeliveryMethod = DeliveryMethods[2]" id="spedytor" [translation]="TranslationEnum.TranslationForm">
      <img #forwarder class="d-block m-auto" width="50px" src="../../../assets/images/forwarder_{{ DeliveryMethod.Id === 4 }}.svg" />
      {{ 'spedytor' | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>

  <mat-error  *ngIf="contractor.Country !== 'PL' && !authService.User.value.IsTrader && DeliveryMethod.Id === 4"  id="musisz_zmienic_sposob_dostawy" [translation]="TranslationEnum.TranslationForm">
    {{ 'musisz_zmienic_sposob_dostawy' | translate }}
  </mat-error>

  <ng-container *ngIf="DeliveryMethod.IsDeliveryAddressRequired">
    <h3 class="pl-2 mt-3 mb-2">
      <span id="adres_dostawy" [translation]="TranslationEnum.TranslationForm">
        {{ 'adres_dostawy' | translate }}
      </span>
      <app-information [label]="'adres_dostawy'" [fileInfoId]="'plik_info_adres_dostawy_' + FileInfoId"></app-information>
    </h3>

    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100" color="accent">
          <mat-select [formControl]="deliveryAddressControl" [errorStateMatcher]="matcher">
            <ng-container *ngFor="let item of DeliveryAddresses" >
              <mat-option [value]="item" *ngIf ="DeliveryMethod.Id !== 3 || !item.OtherTransportThenSelt">
                {{ item.Comment }}
                {{ item.Address }}
                {{ item.ZipCode }}
                {{ item.City }}
                {{ item.Country }}
                ({{ item.Zone }})</mat-option>
            </ng-container>
            
          </mat-select>
          <mat-error *ngIf="deliveryAddressControl.hasError('required')" id="musisz_wybrac_adres_dostawy" [translation]="TranslationEnum.TranslationForm">
            {{ 'musisz_wybrac_adres_dostawy' | translate }}
          </mat-error>
          <mat-error *ngIf="DeliveryAddresses.length <= 0" id="dodaj_nowy_adres_dostawy_w_celu_utworzenia_zamowienia" [translation]="TranslationEnum.TranslationForm">
            {{ 'dodaj_nowy_adres_dostawy_w_celu_utworzenia_zamowienia' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <button class="edytuj_adres" *ngIf = "DeliveryMethod.Id !== 3"  mat-flat-button color="primary" (click) = "otworzAdres()">
      <span id="zmien_adres"  [translation]="TranslationEnum.TranslationForm">
        {{ 'zmien_adres' | translate }}
       
      </span>
      <mat-icon >edit</mat-icon>
    </button>

  </ng-container>
  <div class="reklamacja" *ngIf = "CreateComplaintOrder">
    <input type="checkbox" [(ngModel)]="reklamacja_check"  [disabled]="IsEdit"
            (change)="zmien_check($event)" /> 
        <span id="czyreklamacja"   class="enable{{IsEdit}}" [translation]="TranslationEnum.TranslationForm">
          {{ 'czyreklamacja' | translate }}
        </span>
        <div class="wybor" *ngIf = "reklamacja_check">
              <div class="row">
                <div class="col-12">
              <mat-form-field appearance="outline" class="w-100" color="accent">
                <mat-select [formControl]="complaintsControl" [errorStateMatcher]="matcher">
                  <mat-option *ngFor="let item of complaints" [value]="item">
                    {{ item.ComplaintNumber }}
                    {{ item.ProductionNumber }}
                    {{ item.ShortDescriptionPl }}</mat-option>
                </mat-select>
                <mat-error *ngIf="complaintsControl.hasError('required')" id="musisz_wybrac_zlecenie_do_reklamacji" [translation]="TranslationEnum.TranslationForm">
                  {{ 'musisz_wybrac_zlecenie_do_reklamacji' | translate }}
                </mat-error>
                <mat-error *ngIf="complaints.length <= 0" id="brak_zlecen_do_reklamacji" [translation]="TranslationEnum.TranslationForm">
                  {{ 'brak_zlecen_do_reklamacji' | translate }}
                </mat-error>
              </mat-form-field>

            </div>
          </div>
        </div>
  </div>

  <div>
    <button mat-flat-button [hidden]="IsEdit" color="primary" class="float-right" id="Dalej" type="submit" (click)="submit()" [disabled]="Preview">
      <span id="dalej" [translation]="TranslationEnum.TranslationForm">
        {{ 'dalej' | translate }}
      </span>
      <mat-icon class="ml-1">forward</mat-icon>
    </button>
    <button mat-flat-button [hidden]="!IsEdit" type="button" color="primary" class="float-right" (click)="edit()" [disabled]="Preview">
      <span id="edytuj" [translation]="TranslationEnum.TranslationForm">
        {{ 'edytuj' | translate }}
      </span>
      <mat-icon class="ml-1">create</mat-icon>
    </button>
  </div>
  
</div>
