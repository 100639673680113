import { Parameter } from "src/app/shared/models/form-model/models/form-controlls.model";
import { TypEnum } from "./TypEnum";
import { KrokStep } from "./StepKrok";
import { GrupaKrokow } from "./GrupaKrokow";


export class Supervisor {
    public zakladka:Number = 0;
    public aktualnyTyp = TypEnum.Adres;
    public aktualny_mod = 0;
    public aktualna_poz = 0;

    public edycja_pozycji:boolean = false;
    public goToFooter:boolean = false;

    public auto:Number = 0;
    public wczytywanieAuto:boolean = false;
    public skip:boolean = false;

    
    prev_type: string;
    prev_mod: number;
    prev_poz: number;
    prev_numer: number;
    bez_zmian: boolean = false;
  byl_skip: boolean;
    prev_edit: boolean;


    public getAktualnyMod(){
        // if(this.aktualny_mod > this.moduly.length){
         //    this.aktualny_mod = this.moduly.length - 1;
        //}
        return this.aktualny_mod;
    }
    public getAktualnaPoz(){
        var ilosc = 0;
        //if(this.aktualna_poz > this.moduly[this.getAktualnyMod()].pozycje.length){
        //    this.aktualna_poz = this.moduly[this.getAktualnyMod()].pozycje.length;
        //}
        return this.aktualna_poz;
    }
    public ZakonczPozycje(numer:Number){
        this.edycja_pozycji = false;
    }
    public WyjdzZEdycji(){
        this.edycja_pozycji = false;
    }
    public EdytujPozycje(numer:number){
        this.aktualna_poz = numer+1;
        this.edycja_pozycji = true;
        this.aktualnyTyp = TypEnum.Position;
    }
    

    
    public SetAktualnyTyp(typ:string){
        this.aktualnyTyp = typ;
        if(this.aktualnyTyp == TypEnum.Adres){
            this.zakladka = 0;
        } else if(this.aktualnyTyp == TypEnum.Header){
            this.zakladka = 0;
        } else if(this.aktualnyTyp == TypEnum.ModuleHeader){
            this.zakladka = 1;
        }  else if(this.aktualnyTyp == TypEnum.Position){
            this.zakladka = 1;
        }  else if(this.aktualnyTyp == TypEnum.ModuleFooter){
            this.zakladka = 1;
        }  else if(this.aktualnyTyp == TypEnum.Footer){
            if(this.goToFooter){
                this.zakladka = 2;
            } 
        } 
    }
    public nastepnyTyp(){
        if(this.aktualnyTyp == TypEnum.Adres){
            this.aktualnyTyp = TypEnum.Header;
            this.zakladka = 0;
        } else if(this.aktualnyTyp == TypEnum.Header){
            this.aktualnyTyp = TypEnum.ModuleHeader;
            this.aktualny_mod = 1;
            this.zakladka = 1;
        } else if(this.aktualnyTyp == TypEnum.ModuleHeader){
            this.aktualnyTyp = TypEnum.Position;
            this.zakladka = 1;
        }  else if(this.aktualnyTyp == TypEnum.Position){
            //this.aktualnyTyp = TypEnum.ModuleFooter;
           // this.zakladka = 1;
        }  else if(this.aktualnyTyp == TypEnum.ModuleFooter){
            //this.aktualnyTyp = TypEnum.Footer;
            //this.zakladka = 2;
        } 
    }
    public JakiTyp(krok:KrokStep){
        this.aktualny_mod = krok.Module.valueOf();
        this.aktualna_poz = krok.Position.valueOf();
        if(krok.Type == TypEnum.Header){
            this.aktualnyTyp = TypEnum.Header;
            this.zakladka = 0;
        } else if(krok.Type == TypEnum.ModuleHeader){
            this.aktualnyTyp = TypEnum.ModuleHeader;
            this.zakladka = 1;
        } else if(krok.Type == TypEnum.Position){
            this.aktualnyTyp = TypEnum.Position;
            this.zakladka = 1;
        }  else if(krok.Type == TypEnum.ModuleFooter){
            this.aktualnyTyp = TypEnum.ModuleFooter;
            this.zakladka = 1;
        }  else if(krok.Type == TypEnum.Footer){
            this.aktualnyTyp = TypEnum.Footer;
            // this.zakladka = 2;
        } 
    }

    public ZakonczGrupeKrokow( actualSteps:GrupaKrokow){
        setTimeout(()=> {
            actualSteps.edytowane = false;
            actualSteps.poprawnie_zakonczone = true;
        }, 0);
        

    }

    public ModulePositionForGetStep(krok:KrokStep, actualSteps:GrupaKrokow){
        /*if(this.aktualnyTyp == krok.Type){
            krok.Module = this.aktualny_mod;
            krok.Position = this.aktualna_poz;
            return true;
        } else*/
        if(this.aktualnyTyp == TypEnum.Header && krok.IsModule){
            if(krok.Type == TypEnum.ModuleHeader){
                krok.Module= 1;
                actualSteps.edytowane = false;
                actualSteps.poprawnie_zakonczone = true;
                return true;
            } else {
                // console.log("Wczytuje się niewłaściwy typ kroku (powinien być ModuleHeader, a jest "+krok.Type+")");
                return false;
            }
        } else if(this.aktualnyTyp == TypEnum.Header && !krok.IsModule){
            if(krok.Type == TypEnum.Position){
                krok.Module= 1;
                krok.Position= 1;
                actualSteps.edytowane = false;
                actualSteps.poprawnie_zakonczone = true;
                return true;
            } else {
                // console.log("Wczytuje się niewłaściwy typ kroku (powinien być Position, a jest "+krok.Type+")");
                return false;
            }
        } else if(this.aktualnyTyp == TypEnum.ModuleHeader){
            if(krok.Type == TypEnum.Position){
                krok.Position= 1;
                krok.Module= this.aktualny_mod;
                actualSteps.edytowane = false;
                actualSteps.poprawnie_zakonczone = true;
                return true;
            } else {
                // console.log("Wczytuje się niewłaściwy typ kroku (powinien być Position, a jest "+krok.Type+")");
                return false;
            }
        } else if(this.aktualnyTyp == TypEnum.Position && krok.IsModuleFooter){
            if(krok.Type == TypEnum.ModuleFooter){
                krok.Position= 0;
                krok.Module= this.aktualny_mod;
                actualSteps.edytowane = false;
                actualSteps.poprawnie_zakonczone = true;
                return false;
            } else {
                // console.log("Wczytuje się niewłaściwy typ kroku (powinien być ModuleFooter, a jest "+krok.Type+")");
                return false; 
            }
        } else if(this.aktualnyTyp == TypEnum.Position && !krok.IsModuleFooter && krok.IsFooter){
            if(krok.Type == TypEnum.Footer){ 
                krok.Position= 0;
                krok.Module= 0;
                actualSteps.edytowane = false;
                actualSteps.poprawnie_zakonczone = true;
                return false;
            } else {
                // console.log("Wczytuje się niewłaściwy typ kroku (powinien być Footer, a jest "+krok.Type+")");
                return false;
            }
        } else if(this.aktualnyTyp == TypEnum.Position && !krok.IsModuleFooter && !krok.IsFooter){
                return false;
        }  else if(this.aktualnyTyp == TypEnum.ModuleFooter && krok.IsFooter){
            if(krok.Type == TypEnum.Footer){
                krok.Position= 0;
                krok.Module= 0;
                actualSteps.edytowane = false;
                actualSteps.poprawnie_zakonczone = true;
                return true;
            } else {
                // console.log("Wczytuje się niewłaściwy typ kroku (powinien być Footer, a jest "+krok.Type+")");
                return false;
            }
        }  else if(this.aktualnyTyp == TypEnum.ModuleFooter && !krok.IsFooter){
                return false;
        }
    }
}