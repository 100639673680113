import { Contractor } from './shared/models/user/contractor.model';
import { CookieService } from 'ngx-cookie-service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { TresciService } from './tresci.service';
import { HttpHeaders } from '@angular/common/http';
import { ExceptionsMenagerService } from './exceptions-menager.service';
import { Router } from '@angular/router';
import { serialize } from 'class-transformer';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { Dictionary } from './pomocnicze/Dictionary/Dictionary';
import { ISurchargeType } from './pomocnicze/Interfaces/ISurchargeType';
import { ClientInfo } from './shared/models/client/client-info.model';
import { ICountry } from './pomocnicze/Interfaces/ICountry';
import { IRabateMainInfo } from './pomocnicze/Interfaces/IRabateMainInfo';
import { Invoice } from './pomocnicze/Interfaces/IInvoice';
import { IInvoiceDebts } from './pomocnicze/Interfaces/IInvoiceDebts';
import { Observable, Subscription } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  public token = '';
  public zalogowany = false;
  public login: string;
  public haslo = '';
  public IsFirst = null;
  private subscriptions: Subscription[] = [];

  public IsAdmin = false;
  public editTranslation = '';

  public IsTrader = null;
  public IsPermission = false;
  public KontrahentList = [];
  public Faktury = [];
  public ContractorNumber = null;
  public ContractorName = null;
  public Name = null;
  public dostawa;
  public dostawa_otwarte = false;
  public dostawa_id = 0;
  public dostawa_id_adres = 0;
  public dostawa_otwarte_adres = false;
  public dostawa_aktywne = true;
  public chron_dost = false;
  public chron_adres = false;
  public idDostawaTmp = null;
  public idAdresTmp = null;
  public Picture = null;
  public isModeService = false;
  public przetwarzanie = false;
  public przetwarzanieSpec = false;
  // public KlienciAsync: ClientVm;
  public ClientRootObject: ClientInfo[];
  public ValuationPdf: any;
  public SpecificationPdf: any;
  public ListOfDebts: IInvoiceDebts[];
  public ListOfInvoices: Invoice[];
  public ListOfSurchargeType: ISurchargeType[];
  // public UserPermissions: IUserPermission[] = [{ Key: '', Id: 0 }];
  public UserPermissionDictionary: Dictionary<number, string>[] = [];
  public IsAddSection: any;
  public IsTranslation: boolean;
  public IsGenerateValuation: any;
  public IsEditOrder: any;
  public PackgesInfo: any;
  public _country: ICountry[];
  EnableEWdt: any;
  // public UserDictionary: Dictionary<number, string> = {Key: 0, Value: ''};


  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private http: HttpClient, private tresci: TresciService, private exception: ExceptionsMenagerService,
              private cookieService: CookieService) {
    this.tresci.user = this;
    this.tresci.poWczytaniu();
    this.token = this.cookieService.get('_token');
    this.Picture = {};
    if (this.token != null && this.token !== '' && this.token !== 'null') {
      this.zalogowany = true;
      // this.pobierzPrawa();
    }
    // .log('token:', this.token);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  public getHeader() {
    const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' +
      this.token).set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }
  public getHeaderJson() {
    const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' +
      this.token).set('Content-Type', 'application/json');
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }

  public getHeaderFile() {
    const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' +
      this.token);
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }
  public poza() {
    if (!this.chron_dost) {
      this.dostawa_otwarte = false;
    }
    this.chron_dost = false;
    if (!this.chron_adres) {
      this.dostawa_otwarte_adres = false;
    }
    this.chron_adres = false;
  }
  public zamknij_dostawa(id) {
    if (this.dostawa_aktywne) {
      this.dostawa_id = id;
      this.dostawa_otwarte = false;
      this.chron_dost = true;
      this.tresci.zamowienie.parametry_wybrane.add('zlecenie', 'dostawa_rodzaj', id);

      this.tresci.zapisz('zamowieninie_dostawa_' + this.tresci.system, this.dostawa.DeliveryMethods[id].Id);
    }
  }
  public zamknij_dostawa_adres(id) {
    if (this.dostawa_aktywne) {
      this.dostawa_id_adres = id;
      this.dostawa_otwarte_adres = false;
      this.chron_adres = true;
      this.tresci.zamowienie.parametry_wybrane.add('zlecenie', 'dostawa_adres', id);
      this.tresci.zapisz('zamowieninie_adres_' + this.tresci.system, this.dostawa.ContractorAdresses[id].Id);
    }
  }
  public ustawDostawa(idDostawa, idAdres) {
    this.idDostawaTmp = idDostawa;
    this.idAdresTmp = idAdres;
    if (this.dostawa != null && this.dostawa.DeliveryMethods != null) {
      for (let index = 0; index < this.dostawa.DeliveryMethods.length; index++) {
        if (this.dostawa.DeliveryMethods[index].Id.toString() === this.idDostawaTmp.toString()
          || this.dostawa.DeliveryMethods[index].Name.toString() === this.idDostawaTmp.toString()) {
          this.dostawa_id = index;
        }
      }
      if (this.idAdresTmp != null) {
        for (let index = 0; index < this.dostawa.ContractorAdresses.length; index++) {
          if (this.dostawa.ContractorAdresses[index].Id.toString() === this.idAdresTmp.toString()) {
            this.dostawa_id_adres = index;
          }
        }
      }
    }

  }

  public pobierzObrazekDoAkcesorii(index: string) {
    const request = new HttpRequest('GET', '/api/ActiveForm/GetIndexImage?index=' + index,
      {},
      { responseType: 'blob', headers: this.getHeader() });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.Picture = this.tresci.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(event.body));
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.Picture = null;
        }
      });
  }

  public zapisz_notatke(id, notatka) {
    const body = {
      'Id': id,
      'Description': notatka
    };
    const dane = serialize(body);
    const request = new HttpRequest('POST',
        '/api/ActiveForm/UpdateNote',
      dane,
      { headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.exception.exception_successful('zapisano notatkę poprawnie', 'zapisano notatkę poprawnie', 'mini');
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.exception.exception(this.exception.error(err), err);
        }
      });
  }

  public zmienHaslo(password) {

    const body = {
      'Password': password
    };
    const dane = serialize(body);
    const request = new HttpRequest('POST',   '/api/Account/ChangePassword',
      dane, { headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.exception.exception_successful('Hasło zostało zmienione', 'Hasło zostało zmienione', 'mini');
          this.router.navigate(['Home']);
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.exception.exception(this.exception.error(err), err);
      }
    });
  }

  public pobierzDaneFaktur(DateTo, DateFrom) {
    const body = {
      'ContractorNumber': this.ContractorNumber,
      'DateTo': DateTo,
      'DateFrom': DateFrom
    };

    const dane = serialize(body);
    const request = new HttpRequest('POST',   '/api/Invoice/GetInvoices',
      dane, { headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.ListOfInvoices = <Invoice[]>event.body;
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.exception.exception(this.exception.error(err), err);
      }
    });
  }

  public getDebts() {
    this.ListOfDebts = null;
    const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor
    const body = {
      'ContractorNumber': contractor.ContractorNumber
    };
    const dane = serialize(body);
    const request = new HttpRequest('POST',   '/api/Debt/GetDebts',
      dane, { headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.ListOfDebts = <IInvoiceDebts[]>event.body;

          for (let index = 0; index < this.ListOfDebts.length; index++) {
            const element = this.ListOfDebts[index];
            if (element.DaysAfterPaymentDate > 0) {
              element.AfterPayment = true;
            } else {
              element.AfterPayment = false;
            }
          }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.exception.exception(this.exception.error(err), err);
      }
    });
  }


  public pobierzKlientowKontrahenta(contractorNumber): Observable<ClientInfo[]> {
    const body = {
      'ContractorNumber': contractorNumber
    };
    const dane = serialize(body);
    return this.http.post<ClientInfo[]>('/api/Contractor/GetContractorClients', dane);
  }

  async pobierzKraje(): Promise<ICountry[]> {
    const url =   '/api/Additives/GetCountries?cultureId=' + this.tresci.jezyk_id;
    return await this.http.get(url, { headers: this.getHeader() }).toPromise()
      .then(data => this._country = <ICountry[]>data);
  }

  async dodajEdytujKlientaKontrahenta(clientVm: ClientInfo) {
    const body = {
      'Id': clientVm.Id,
      'ContractorNumber': clientVm.ContractorNumber,
      'Name': clientVm.Name,
      'Address': clientVm.Address,
      'City': clientVm.City,
      'NIP': clientVm.NIP,
      'Country': clientVm.Country,
      'ContractorClientProfitMargins': clientVm.ContractorClientProfitMargins,
      'ZipCode': clientVm.ZipCode
    };
    const dane = serialize(body);
    const request = new HttpRequest('POST',   '/api/Contractor/AddorUpdateContractorClient',
      dane, { headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.exception.exception_successful('Zapisano pomyślnie', 'Zapisano pomyślnie', 'mini');
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.exception.exception(this.exception.error(err), err);
      }
    });
  }



  async dodajEdytujKlientaKontrahentaTest(clientVm: ClientInfo) {
    const body = {
      'Id': clientVm.Id,
      'ContractorNumber': clientVm.ContractorNumber,
      'Name': clientVm.Name,
      'Address': clientVm.Address,
      'City': clientVm.City,
      'NIP': clientVm.NIP,
      'Country': clientVm.Country,
      'ContractorClientProfitMargins': clientVm.ContractorClientProfitMargins,
      'ZipCode': clientVm.ZipCode
    };
    const dane = serialize(body);
    const url =   '/api/Contractor/AddorUpdateContractorClient';
    await this.http.post(url, dane, { headers: this.getHeaderJson() }).toPromise();
  }

  deleteRabate(id: number) {
    const body = {
      'Id': id
    };
    const dane = serialize(body);
    const request = new HttpRequest('POST',   '/api/Contractor/RemoveContractorClientProfitMargin',
      dane, { headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.exception.exception_successful('Rabat usunięto pomyślnie', 'Rabat usunięto pomyślnie', 'mini');
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.exception.exception(this.exception.error(err), err);
      }
    });
  }


  usunKlientaKontrahenta(clientId: any) {
    const body = {
      'Id': clientId
    };

    const dane = serialize(body);
    const request = new HttpRequest('POST',   '/api/Contractor/RemoveContractorClient',
      dane, { headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.exception.exception_successful('Usunięto pomyślnie', 'Usunięto pomyślnie', 'mini');
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.exception.exception(this.exception.error(err), err);
      }
    });
  }

  public calculateValuation(rabateMainInfo: IRabateMainInfo) {
    this.przetwarzanie = true;
    this.ValuationPdf = null;
    const dane = serialize(rabateMainInfo);
    const request = new HttpRequest('POST',
        '/api/ActiveForm/GetValuationContractorClientPrint?cultureId=' + this.tresci.jezyk_id,
      dane,
      { responseType: 'blob', headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.ValuationPdf = this.tresci.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(event.body));
            this.przetwarzanie = false;
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.ValuationPdf = null;
        }
      });
  }

  public getSpecification(rabateMainInfo: IRabateMainInfo) {
    this.przetwarzanieSpec = true;
    this.SpecificationPdf = null;
    const dane = serialize(rabateMainInfo);
    const request = new HttpRequest('POST',
        '/api/ActiveForm/GetSpecificationContractorClientPrint?cultureId=' + this.tresci.jezyk_id,
      dane,
      { responseType: 'blob', headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.SpecificationPdf = this.tresci.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(event.body));
            this.przetwarzanieSpec = false;
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.SpecificationPdf = null;
        }
      });
  }

  public getSurcharges() {
    const request = new HttpRequest('GET',
        '/api/Additives/GetSurcharges?system=' + this.tresci.getPrefix(),
      {},
      { headers: this.getHeader() });
    this.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.ListOfSurchargeType = <ISurchargeType[]>event.body;
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.exception.exception(this.exception.error(err), err);
      }
    });
  }

  public changeContractor(contractor: Contractor) {
    this.ContractorNumber = contractor.ContractorNumber;
    this.ContractorName = contractor.ContractorName;
    // console.log("con1", contractor);
    sessionStorage.setItem('_slc', JSON.stringify(contractor)); 
  }

  public zmienKontrahenta(KontrahentList, id = null) {
    // //zmieniono na changeContractor
    // let ustawiono = false;
    // if (id == null) {
    //   const wybrany = sessionStorage.getItem('KontrahWybrany');
    //   KontrahentList.forEach(element => {
    //     if (element.ContractorNumber === wybrany) {
    //       this.ContractorNumber = element.ContractorNumber;
    //       this.ContractorName = element.ContractorName;
    //       ustawiono = true;
    //     }
    //   });
    //   id = 0;
    // }
    // if (!ustawiono) {
    //   this.ContractorNumber = KontrahentList[id].ContractorNumber;
    //   this.ContractorName = KontrahentList[id].ContractorName;
    //   sessionStorage.setItem('KontrahWybrany', KontrahentList[id].ContractorNumber);
    //   // sessionStorage.setItem('KontrahWybranyName', this.KontrahentList[id].ContractorName);
    // }
    // this.tresci.zamowienia = new ListaZamowien(this.tresci, false);
    // // this.tresci.archiwumLista = new ListaZamowien(this.tresci, true);
    // this.pobierzDostawe();
  }

  public pobierzDostawe() {

    if (this.ContractorNumber != null) {
      const body = {
        'NumerContractor': this.ContractorNumber
      };
      const dane = serialize(body);
      const request = new HttpRequest('POST',
          '/api/Contractor/GetContractorAddresses',
        dane,
        { headers: this.getHeaderJson() });
      this.http.request(request).pipe(/*take(1)*/)
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Response:
              this.dostawa = event.body;

              this.dostawa_id = 0;
              this.dostawa_id_adres = 0;
              if (this.dostawa != null) {
                if (this.dostawa.DeliveryMethods != null) {
                  for (let index = 0; index < this.dostawa.DeliveryMethods.length; index++) {
                    if (this.dostawa.DeliveryMethods[index].IsDefault) {
                      this.dostawa_id = index;
                    }
                  }
                }
              }
              if (this.idDostawaTmp != null) {
                if (this.dostawa != null && this.dostawa.DeliveryMethods != null) {
                  for (let index = 0; index < this.dostawa.DeliveryMethods.length; index++) {
                    if (this.dostawa.DeliveryMethods[index].Id.toString() === this.idDostawaTmp.toString()
                      || this.dostawa.DeliveryMethods[index].Name.toString() === this.idDostawaTmp.toString()) {
                      this.dostawa_id = index;
                    }
                  }
                  if (this.idAdresTmp != null) {
                    for (let index = 0; index < this.dostawa.ContractorAdresses.length; index++) {
                      if (this.dostawa.ContractorAdresses[index].Id.toString() === this.idAdresTmp.toString()) {
                        this.dostawa_id_adres = index;
                      }
                    }
                  }
                }
              }
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.exception.exception(this.exception.error(err), err);
          }
        });
    }
  }

  public getPackages(productionNumber: string) {
    const body = {
      'ProductionNumber': productionNumber
    };
    const dane = serialize(body);
    const request = new HttpRequest('POST',   '/api/ActiveForm/GetPackage',
      dane, { headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.PackgesInfo = event.body;
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.exception.exception(this.exception.error(err), err);
      }
    });
  }

  public wyloguj() {
    this.token = '';
    // window.location.reload();
    // this.tresci.usun('token');
    this.cookieService.set('_token', null, { path: '/' });
    sessionStorage.clear(); // delete('_usi');
    this.cookieService.deleteAll(); // delete('_usi');
    this.tresci.usun('user_name');
    this.login = '';
    this.haslo = '';
    this.zalogowany = false;
    localStorage.setItem('info', '');
    this.tresci.usun_cookies();

  }

  public SendMailToClient(mailToClientInterface: any) {

    const dane = serialize(mailToClientInterface);
    const request = new HttpRequest('POST',   '/api/ActiveForm/SendEmailContractor',
      dane, { headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.exception.exception_successful('Wysłano', 'Wysłano', 'mini');
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.exception.exception(this.exception.error(err), err);
      }
    });
  }

  generateValuation(Id: string) {
    const body = {
      'Id': Id
    };
    const dane = serialize(body);
    const request = new HttpRequest('POST',   '/api/ActiveForm/GenerateValuation',
      dane, { headers: this.getHeaderJson() });
    this.http.request(request).pipe(/*take(1)*/).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.exception.exception(this.exception.error(err), err);
      }
    });
  }
}
