import { Order } from 'src/app/shared/models/orders/order.model';
import { Parameter } from './../models/form-model/models/form-controlls.model';
import { DeliveryEdit } from './../models/form/delivery-edit.model';
import { catchError } from 'rxjs/operators';
import { SurchargeControl } from './../models/form/surcharge-control.model';
import { Contractor } from './../models/user/contractor.model';
import { DeliveryMethod } from './../models/form/delivery-method.model';
import { DeliveryAddress } from './../models/form/delivery-address.model';
import { TresciService } from './../../tresci.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { FormControlMapper } from './../mappers/form-control.mapper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { ActiveForm, ActiveFormType } from '../models/form-model/models/active-form.model';
import { FormControls, TechnologyControl } from '../models/form-model/models/form-controlls.model';
import { HtmlControl } from '../models/form-model/models/html-control.model';
import { SaveForm } from '../models/form-model/models/save-form.model';
import { serialize } from 'class-transformer';
import { TranslateService } from '@ngx-translate/core';
import { Kim } from '../models/form-model/kim.model';
import { PositionModelComponent } from '../models/form-model/models/component-edit.model';
import { FileControl } from '../models/form/file-control.model';
import { FormSet } from '../models/form-model/models/form-set.model';
import { EditForm } from '../models/form/edit-form.model';
import { Surcharge } from '../models/form/surcharge.model';
import { SystemEnum } from '../enums/system-enum';
import { Console } from 'console';
import { BroadcastService } from '../async-services/http/broadcast.service';
import * as _ from 'lodash';
import { Complaint } from '../models/reklamacje/Complaint.model';
import { MatSort } from '@angular/material/sort';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  public DeliveryMethod: DeliveryMethod = null;
  public DeliveryAddress: DeliveryAddress = null;

  public Files: FileControl[] = [];
  public Surcharges: SurchargeControl[] = [];
  public $Surcharges: BehaviorSubject<SurchargeControl[]>;

  public RemoveControlsFromGroup: HtmlControl[] = [];

  private formControlMapper: FormControlMapper;
  public ProductPrefix: string;
  private system: string;
  public token;
  private controlsSumUp: { key: string; value: number }[] = [];
  public ActiveFormOrderId = 0;
  public $DeliveryAddress: BehaviorSubject<DeliveryEdit>;
  public IsDelivery = false;
  public Complaint:Complaint = null;
  public FilesToRemove: FileControl[] = [];
  public CopiedOrderId: number = null;
  OrderId: number;
  IsTrader: boolean;
  public TempKimOptions: Kim[] = [];

  constructor(
    private httpClient: HttpClient,
    private broadcastService: BroadcastService,
    public tresci: TresciService,
    private translateService: TranslateService,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.formControlMapper = new FormControlMapper();
    this.token = this.cookieService.get('_token');
    this.$Surcharges = new BehaviorSubject<SurchargeControl[]>(null);
    this.$DeliveryAddress = new BehaviorSubject<DeliveryEdit>(null);
  }

  public getHeader() {
    const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.token).set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }

  public getHeaderJson() {
    const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.token).set('Content-Type', 'application/json');
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }

  public getHeaderFile() {
    const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }

  public getFirstStep(): Observable<FormControls> {
    const data = {
      ContractorNumber: JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber, // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
      ProductPrefix: this.ProductPrefix,
      Culture: this.translateService.currentLang,
      IsCache: false,
      IsTrader: this.tresci.IsTrader,
      IsComplaint: this.Complaint == null ? false: true,
    };
    
    return this.httpClient.post<FormControls>('api/ActiveForm/GetStep', data, {
      headers: this.getHeaderJson(),
    });
  }

  public getOrderParametersForEdit(id: number): Observable<EditForm> {
    const data = {
      Id: id,
      IsTrader: this.tresci.IsTrader,
      IsComplaint: this.Complaint == null ? false: true,
    };
    return this.httpClient.post<EditForm>('api/ActiveForm/GetParameters', data, {
      headers: this.getHeaderJson(),
    });
  }

  public getResultInfoForStep(formGroup: FormGroup, step: string, isValuation: boolean, Parametry:Parameter[] = null, ProductPrefix = null, IsTrader = null ): Observable<FormControls> {
    const activeForm: ActiveForm = new ActiveForm();
    activeForm.ContractorNumber = JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber; // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
    if(ProductPrefix == null){
      activeForm.ProductPrefix = this.ProductPrefix;
    } else {
      activeForm.ProductPrefix = ProductPrefix;
    }
    
    activeForm.Culture = this.translateService.currentLang;
    if(IsTrader == null){
      activeForm.IsTrader = this.tresci.IsTrader;
    } else {
      activeForm.IsTrader = IsTrader;
    }
    activeForm.IsComplaint = this.Complaint == null ? false: true;
    activeForm.CurrentStep = step;
    activeForm.IsCache = true;
    if(Parametry == null){
      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];
        if (control.value !== null && control.value.inputType !== 'newline' && control.value.inputType !== 'render') {
          if (
            (control.value.options !== undefined && control.value.options?.length !== 0) ||
            (control.value.optionsSelectMultiColumn !== undefined && control.value.optionsSelectMultiColumn?.length !== 0)
          ) {
            if (control.value.value.Key !== undefined) {
              activeForm.Parameters.push({
                Key: key,
                Value: control.value.value.Key,
              });
            }
          } else {
            activeForm.Parameters.push({
              Key: key,
              Value: control.value.value,
            });
          }
        }
      });
    } else {
      Parametry.forEach(element => {
        activeForm.Parameters.push({
          Key: element.Key,
          Value: element.Value,
        });
      });
      
    }
    
    if (!isValuation) {
      return this.httpClient.post<FormControls>('api/ActiveForm/GetStepResultInfo', activeForm /*, { headers: this.getHeaderJson() }*/);
    } else {
      return this.httpClient.post<FormControls>('api/ActiveForm/GetValuationResultInfo', activeForm /*, { headers: this.getHeaderJson() }*/);
    }
  }

  public getFirstMultiSteps(parameters: Parameter[]) {
    const data = {
      Parameters: parameters,
      ContractorNumber: JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber, // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
      ProductPrefix: this.ProductPrefix,
      Culture: this.translateService.currentLang,
      IsCache: true,
      IsTrader: this.tresci.IsTrader,
      IsComplaint: this.Complaint == null ? false: true,
      Type: 'Header',
      OrderId: this.OrderId,
    };

    return this.httpClient.post<FormControls>('api/ActiveForm/GetMultiSteps', data, {
      headers: this.getHeaderJson(),
    });
  }

  public getMultiSteps(form: FormGroup, parameters: Parameter[], type: string) {
    const activeForm: ActiveForm = new ActiveForm();
    activeForm.ContractorNumber = JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber, // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
    activeForm.ProductPrefix = this.ProductPrefix;
    activeForm.Culture = this.translateService.currentLang;
    // activeForm.CurrentStep = step;
    activeForm.IsCache = true;
    activeForm.Type = type;
    activeForm.Parameters = Array.from(parameters);
    activeForm.OrderId = this.OrderId;
    activeForm.IsTrader = this.tresci.IsTrader;
    activeForm.IsComplaint = this.Complaint == null ? false: true;

    const formForSend = _.cloneDeep(form); //form

    // this.RemoveControlsFromGroup.forEach((control) => {
    //   formForSend.removeControl(control.name);
    // });

    this.RemoveControlsFromGroup.forEach((control) => { 
      if (
        formForSend.controls[control.name] !== undefined &&
        ((formForSend.controls[control.name].value === null && control.module === 0) || formForSend.controls[control.name].value.module === control.module)
      ) {
        formForSend.removeControl(control.name);
      }
    });

    for (const key of Object.keys(formForSend.controls)) {
      const control = formForSend.controls[key];
      if (control.value.inputType !== 'newline' && control.value.inputType !== 'kim') {
        if (
          (control.value.options !== undefined && control.value.options?.length !== 0) ||
          (control.value.optionsSelectMultiColumn !== undefined && control.value.optionsSelectMultiColumn?.length !== 0)
        ) {
          if (control.value.value === undefined) {
            this.broadcastService.ErrorHttp.next(`Wartość kontrolki jest nie właściwa ${control.value.name}`);
            break;
          } else if (control.value.value.Key !== undefined) {
            activeForm.Parameters.push({
              Key: key,
              Value: control.value.value.Key,
            });
          }
        } else {
          activeForm.Parameters.push({
            Key: key,
            Value: control.value.value,
          });
        }
      }
    }

    return this.httpClient.post<FormControls>('api/ActiveForm/GetMultiSteps', activeForm, {
      headers: this.getHeaderJson(),
    });
  }

  public getMultiStepsFooter(formSet: FormSet, parameters: Parameter[], type: string) {
    const activeForm: ActiveForm = new ActiveForm();
    (activeForm.ContractorNumber = JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber), (activeForm.ProductPrefix = this.ProductPrefix);
    activeForm.Culture = this.translateService.currentLang;
    // activeForm.CurrentStep = step;
    activeForm.IsCache = true;
    activeForm.Type = type;
    activeForm.Parameters = Array.from(parameters);
    activeForm.OrderId = this.OrderId;
    activeForm.IsTrader = this.tresci.IsTrader;
    activeForm.IsComplaint = this.Complaint == null ? false: true;

    const formForSend = _.cloneDeep(formSet.SetForm); // formSet.SetForm; // _.cloneDeep(formSet.SetForm);
    const positionForm = _.cloneDeep(formSet.SetPositionsComponents);
 // formSet.SetPositionsComponents; // _.cloneDeep(formSet.SetPositionsComponents);

     this.RemoveControlsFromGroup.forEach((control) => {
       if (
         formForSend.controls[control.name] !== undefined &&
         ((formForSend.controls[control.name].value === null && control.module === 0) || formForSend.controls[control.name].value.module === control.module)
       ) {
         formForSend.removeControl(control.name);
       }
     });

    Object.keys(formForSend.controls).forEach((key) => {
      const setControl = formForSend.controls[key];
      if (key.toLowerCase() === 'jezykdokumentu') {
      }
      if (setControl.value.type !== ActiveFormType.Position) {
        if (setControl.value.inputType !== 'newline' && setControl.value.inputType !== 'checkfile') {
          if (
            (setControl.value.options !== undefined && setControl.value.options.length !== 0) ||
            (setControl.value.optionsSelectMultiColumn !== undefined && setControl.value.optionsSelectMultiColumn?.length !== 0)
          ) {
            activeForm.Parameters.push({
              Key: key,
              Value: setControl.value.value.Key,
              Position: setControl.value.position,
              DisplayValue: setControl.value.value.DisplayValue,
              DisplayValueEn: setControl.value.value.DisplayValueEn,
              DisplayValueDe: setControl.value.value.DisplayValueDe,
              DisplayValueFr: setControl.value.value.DisplayValueFr,
              DisplayValuePl: setControl.value.value.DisplayValuePl,
              DisplayValueCs: setControl.value.value.DisplayValueCs,
              Module: setControl.value.module,
              Type: setControl.value.type,
              IsPrint: setControl.value.isPrint,
            });
          } else {

            activeForm.Parameters.push({
              Key: key,
              Value: setControl.value.value,
              Position: setControl.value.position,
              DisplayValue: setControl.value.value,
              DisplayValueEn: setControl.value.value,
              DisplayValueDe: setControl.value.value,
              DisplayValueFr: setControl.value.value,
              DisplayValuePl: setControl.value.value,
              DisplayValueCs: setControl.value.value,
              Module: setControl.value.module,
              Type: setControl.value.type,
              IsPrint: setControl.value.isPrint,
            });
          }
        }
      }
    });

    for (const iterator of positionForm) {
      this.RemoveControlsFromGroup.forEach((control) => {
        if (
          iterator.Form.controls[control.name] !== undefined &&
          ((iterator.Form.controls[control.name].value === null && control.module === 0) ||
          (iterator.Form.controls[control.name].value.module === control.module && iterator.Form.controls[control.name].value.position === control.position))
        ) {
          iterator.Form.removeControl(control.name);
        }
      });

      for (const key of Object.keys(iterator.Form.controls)) {
        const control = iterator.Form.controls[key];
        if (control.value !== null && control.value.type === ActiveFormType.Position) {
          if (control.value.inputType !== 'newline' && control.value.inputType !== 'checkfile' && control.value.inputType !== 'kim') {
            if (
              (control.value.options !== undefined && control.value.options.length !== 0) ||
              (control.value.optionsSelectMultiColumn !== undefined && control.value.optionsSelectMultiColumn?.length !== 0)
            ) {
              activeForm.Parameters.push({
                Key: key,
                Value: control.value.value.Key,
                Position: control.value.position,
                DisplayValue: control.value.value.DisplayValue,
                DisplayValueEn: control.value.value.DisplayValueEn,
                DisplayValueDe: control.value.value.DisplayValueDe,
                DisplayValueFr: control.value.value.DisplayValueFr,
                DisplayValuePl: control.value.value.DisplayValuePl,
                DisplayValueCs: control.value.value.DisplayValueCs,
                Module: control.value.module,
                Type: control.value.type,
                IsPrint: control.value.isPrint,
              });
            } else {
              activeForm.Parameters.push({
                Key: key,
                Value: control.value.value,
                Position: control.value.position,
                DisplayValue: control.value.value,
                DisplayValueEn: control.value.value,
                DisplayValueDe: control.value.value,
                DisplayValueFr: control.value.value,
                DisplayValuePl: control.value.value,
                DisplayValueCs: control.value.value,
                Module: control.value.module,
                Type: control.value.type,
                IsPrint: control.value.isPrint,
              });
            }
          }
        }
      }
    }

    for (const controlSum of this.controlsSumUp) {
      activeForm.Parameters.push({
        Key: controlSum.key,
        Value: controlSum.value,
      });
    }

    return this.httpClient.post<FormControls>('api/ActiveForm/GetMultiSteps', activeForm, {
      headers: this.getHeaderJson(),
    });
  }

  public getMultiStepsPositionWithPreviousPosition(form: FormGroup, parameters: Parameter[], previousPosition: PositionModelComponent) {
    const activeForm: ActiveForm = new ActiveForm();
    (activeForm.ContractorNumber = JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber), // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
      (activeForm.ProductPrefix = this.ProductPrefix);
    activeForm.Culture = this.translateService.currentLang;
    // activeForm.CurrentStep = step;
    activeForm.IsCache = true;
    activeForm.Type = 'Position';
    activeForm.Parameters = Array.from(parameters);
    activeForm.OrderId = this.ActiveFormOrderId;
    activeForm.IsTrader = this.tresci.IsTrader;
    activeForm.IsComplaint = this.Complaint == null ? false: true;

    const formForSend = _.cloneDeep(form); //form

    // this.RemoveControlsFromGroup.forEach((control) => {
    //   formForSend.removeControl(control.name);
    // });
    // console.log(this.RemoveControlsFromGroup);
    this.RemoveControlsFromGroup.forEach((control) => {
      if (
        formForSend.controls[control.name] !== undefined &&
        ((formForSend.controls[control.name].value === null && control.module === 0) || formForSend.controls[control.name].value.module === control.module)
      ) {
        formForSend.removeControl(control.name);
      }
    });

    for (const key of Object.keys(formForSend.controls)) {
      const control = formForSend.controls[key];
      if (control.value.inputType !== 'newline' && control.value.inputType !== 'kim') {
        if (
          (control.value.options !== undefined && control.value.options?.length !== 0) ||
          (control.value.optionsSelectMultiColumn !== undefined && control.value.optionsSelectMultiColumn?.length !== 0)
        ) {
          if (control.value.value === undefined) {
            this.broadcastService.ErrorHttp.next(`Wartość kontrolki jest nie właściwa ${control.value.name}`);
            break;
          } else if (control.value.value.Key !== undefined) {
            activeForm.Parameters.push({
              Key: key,
              Value: control.value.value.Key,
            });
          }
        } else {
          activeForm.Parameters.push({
            Key: key,
            Value: control.value.value,
          });
        }
      }
    }

    Object.keys(previousPosition.Form.controls).forEach((key) => {
      const prevPos = previousPosition.Form.controls[key];
      if (prevPos.value !== null && prevPos.value.type === ActiveFormType.Position) {
        if (
          ((prevPos.value.options !== undefined && prevPos.value.options.length !== 0) ||
            (prevPos.value.optionsSelectMultiColumn !== undefined && prevPos.value.optionsSelectMultiColumn?.length !== 0)) &&
          activeForm.PreviousPositionParameters !== undefined
        ) {
          activeForm.PreviousPositionParameters.push({
            Key: key,
            Value: prevPos.value.value.Key,
          });
        } else if (prevPos.value.inputType !== 'kim' && prevPos.value.inputType !== 'newline' && prevPos.value.inputType !== 'checkfile') {
          activeForm.PreviousPositionParameters.push({
            Key: key,
            Value: prevPos.value.value,
          });
        }
      }
    });

    return this.httpClient.post<FormControls>('api/ActiveForm/GetMultiSteps', activeForm, {
      headers: this.getHeaderJson(),
    });
  }

  public moveToNextStepWithPosition(form: FormGroup, step: string, previousPosition: PositionModelComponent,PositionId, Module) {
    const activeForm: ActiveForm = new ActiveForm();
    activeForm.ContractorNumber = JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber, // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
    activeForm.ProductPrefix = this.ProductPrefix;
    activeForm.Culture = this.translateService.currentLang;
    activeForm.CurrentStep = step;
    activeForm.IsCache = true;
    activeForm.OrderId = this.OrderId;
    activeForm.IsTrader = this.tresci.IsTrader;
    activeForm.IsComplaint = this.Complaint == null ? false: true; 

    const formSend = _.cloneDeep(form);
    this.RemoveControlsFromGroup.forEach((control) => {
      if((control.position == 0 && control.module == 0) || (control.position == PositionId && control.module == Module) || (control.position == 0 && control.module == Module)){
        formSend.removeControl(control.name); 
      }
      
      
    });

    Object.keys(formSend.controls).forEach((key) => {
      const control = form.controls[key];
      if (control.value !== null && (control.value.type === ActiveFormType.ModuleHeader || control.value.type === ActiveFormType.Header || control.value.type === ActiveFormType.Position)) {
        if (
          (control.value.options !== undefined && control.value.options.length !== 0) ||
          (control.value.optionsSelectMultiColumn !== undefined && control.value.optionsSelectMultiColumn?.length !== 0)
        ) {
          activeForm.Parameters.push({
            Key: key,
            Value: control.value.value.Key,
          });
        } else if (control.value.inputType !== 'kim' && control.value.inputType !== 'newline') {
          activeForm.Parameters.push({
            Key: key,
            Value: control.value.value,
          });
        }
      }
    });

    Object.keys(previousPosition.Form.controls).forEach((key) => {
      const prevPos = previousPosition.Form.controls[key];
      if (prevPos.value !== null && prevPos.value.type === ActiveFormType.Position) {
        if (
          ((prevPos.value.options !== undefined && prevPos.value.options.length !== 0) ||
            (prevPos.value.optionsSelectMultiColumn !== undefined && prevPos.value.optionsSelectMultiColumn?.length !== 0)) &&
          activeForm.PreviousPositionParameters !== undefined
        ) {
          activeForm.PreviousPositionParameters.push({
            Key: key,
            Value: prevPos.value.value.Key,
          });
        } else if (prevPos.value.inputType !== 'kim' && prevPos.value.inputType !== 'newline' && prevPos.value.inputType !== 'checkfile') {
          activeForm.PreviousPositionParameters.push({
            Key: key,
            Value: prevPos.value.value,
          });
        }
      }
    });

    return this.httpClient.post<FormControls>('api/ActiveForm/GetStep', activeForm, { headers: this.getHeaderJson() });
  }

  public verifyPosition(form: FormGroup, step: string, previousPosition?: PositionModelComponent): Observable<FormControls> {
    const activeForm: ActiveForm = new ActiveForm();
    activeForm.ContractorNumber = JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber;
    activeForm.ProductPrefix = this.ProductPrefix;
    activeForm.Culture = this.translateService.currentLang;
    activeForm.CurrentStep = step;
    activeForm.IsCache = true;
    activeForm.IsTrader = this.tresci.IsTrader;
    activeForm.IsComplaint = this.Complaint == null ? false: true;

    this.RemoveControlsFromGroup.forEach((control) => {
      form.removeControl(control.name);
    });

    Object.keys(form.controls).forEach((key) => {
      const control = form.controls[key];
      if (control.value !== null) {
        if (control.value.type === ActiveFormType.ModuleHeader || control.value.type === ActiveFormType.Header || control.value.type === ActiveFormType.Position) {
          if (
            (control.value.options !== undefined && control.value.options.length !== 0) ||
            (control.value.optionsSelectMultiColumn !== undefined && control.value.optionsSelectMultiColumn?.length !== 0)
          ) {
            activeForm.Parameters.push({
              Key: key,
              Value: control.value.value.Key,
            });
          } else if (control.value.inputType !== 'kim' && control.value.inputType !== 'newline' && control.value.inputType !== 'checkfile') {
            activeForm.Parameters.push({
              Key: key,
              Value: control.value.value,
            });
          }
        }
      }
    });
    if (previousPosition !== null && previousPosition !== undefined) {
      Object.keys(previousPosition.Form.controls).forEach((key) => {
        const prevPos = previousPosition.Form.controls[key];
        if (prevPos.value.type === ActiveFormType.Position) {
          if (
            ((prevPos.value.options !== undefined && prevPos.value.options.length !== 0) ||
              (prevPos.value.optionsSelectMultiColumn !== undefined && prevPos.value.optionsSelectMultiColumn?.length !== 0)) &&
            activeForm.PreviousPositionParameters !== undefined
          ) {
            activeForm.PreviousPositionParameters.push({
              Key: key,
              Value: prevPos.value.value.Key,
            });
          } else if (prevPos.value.inputType !== 'kim' && prevPos.value.inputType !== 'newline' && prevPos.value.inputType !== 'checkfile' && activeForm.PreviousPositionParameters !== undefined) {
            activeForm.PreviousPositionParameters.push({
              Key: key,
              Value: prevPos.value.value,
            });
          }
        }
      });
    }
    return this.httpClient.post<FormControls>('api/ActiveForm/GetStep', activeForm);
  }

  public moveToNextStepForModuleFooter(formSet: FormSet, step: string): Observable<FormControls> {
    const activeForm: ActiveForm = new ActiveForm();
    activeForm.ContractorNumber = JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber, // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
    activeForm.ProductPrefix = this.ProductPrefix;
    activeForm.Culture = this.translateService.currentLang;
    activeForm.CurrentStep = step;
    activeForm.IsCache = true;
    activeForm.OrderId = this.OrderId;
    activeForm.IsTrader = this.tresci.IsTrader;
    activeForm.IsComplaint = this.Complaint == null ? false: true;

    const formForSend = _.cloneDeep(formSet.SetForm);
    const positionForm = _.cloneDeep(formSet.SetPositionsComponents);

    this.RemoveControlsFromGroup.forEach((control) => {
      if (formForSend.controls[control.name] !== undefined && ((formForSend.controls[control.name].value === null && control.module === 0) || formForSend.controls[control.name].value.module === control.module)) {
        formForSend.removeControl(control.name);
      }
    });
    Object.keys(formForSend.controls).forEach((key) => {
      const setControl = formForSend.controls[key];
      if (key.toLowerCase() === 'jezykdokumentu') {
      }
      if (setControl.value.type !== ActiveFormType.Position) {
        if (setControl.value.inputType !== 'newline' && setControl.value.inputType !== 'checkfile') {
          if (
            (setControl.value.options !== undefined && setControl.value.options.length !== 0) ||
            (setControl.value.optionsSelectMultiColumn !== undefined && setControl.value.optionsSelectMultiColumn?.length !== 0)
          ) {
            activeForm.Parameters.push({
              Key: key,
              Value: setControl.value.value.Key,
              Position: setControl.value.position,
              DisplayValue: setControl.value.value.DisplayValue,
              DisplayValueEn: setControl.value.value.DisplayValueEn,
              DisplayValueDe: setControl.value.value.DisplayValueDe,
              DisplayValueFr: setControl.value.value.DisplayValueFr,
              DisplayValuePl: setControl.value.value.DisplayValuePl,
              DisplayValueCs: setControl.value.value.DisplayValueCs,
              Module: setControl.value.module,
              Type: setControl.value.type,
              IsPrint: setControl.value.isPrint,
            });
          } else {
            activeForm.Parameters.push({
              Key: key,
              Value: setControl.value.value,
              Position: setControl.value.position,
              DisplayValue: setControl.value.value,
              DisplayValueEn: setControl.value.value,
              DisplayValueDe: setControl.value.value,
              DisplayValueFr: setControl.value.value,
              DisplayValuePl: setControl.value.value,
              DisplayValueCs: setControl.value.value,
              Module: setControl.value.module,
              Type: setControl.value.type,
              IsPrint: setControl.value.isPrint,
            });
          }
        }
      }
    });

    for (const iterator of positionForm) {
      this.RemoveControlsFromGroup.forEach((control) => {
        if (
          iterator.Form.controls[control.name] !== undefined &&
          ((iterator.Form.controls[control.name].value === null && control.module === 0) ||
            (iterator.Form.controls[control.name].value.module === control.module && iterator.Form.controls[control.name].value.position === control.position))
        ) {
          iterator.Form.removeControl(control.name);
        }
      });

      for (const key of Object.keys(iterator.Form.controls)) {
        const control = iterator.Form.controls[key];
        if (control.value !== null && control.value.type === ActiveFormType.Position) {
          if (control.value.inputType !== 'newline' && control.value.inputType !== 'checkfile' && control.value.inputType !== 'kim') {
            if (
              (control.value.options !== undefined && control.value.options.length !== 0) ||
              (control.value.optionsSelectMultiColumn !== undefined && control.value.optionsSelectMultiColumn?.length !== 0)
            ) {
              activeForm.Parameters.push({
                Key: key,
                Value: control.value.value.Key,
                Position: control.value.position,
                DisplayValue: control.value.value.DisplayValue,
                DisplayValueEn: control.value.value.DisplayValueEn,
                DisplayValueDe: control.value.value.DisplayValueDe,
                DisplayValueFr: control.value.value.DisplayValueFr,
                DisplayValuePl: control.value.value.DisplayValuePl,
                DisplayValueCs: control.value.value.DisplayValueCs,
                Module: control.value.module,
                Type: control.value.type,
                IsPrint: control.value.isPrint,
              });
            } else {
              activeForm.Parameters.push({
                Key: key,
                Value: control.value.value,
                Position: control.value.position,
                DisplayValue: control.value.value,
                DisplayValueEn: control.value.value,
                DisplayValueDe: control.value.value,
                DisplayValueFr: control.value.value,
                DisplayValuePl: control.value.value,
                DisplayValueCs: control.value.value,
                Module: control.value.module,
                Type: control.value.type,
                IsPrint: control.value.isPrint,
              });
            }
          }
        }
      }
    }

    for (const controlSum of this.controlsSumUp) {
      activeForm.Parameters.push({
        Key: controlSum.key,
        Value: controlSum.value,
      });
    }
    return this.httpClient.post<FormControls>('api/ActiveForm/GetStep', activeForm, { headers: this.getHeaderJson() });
  }

  public moveToNextStep(form: FormGroup, step: string): Observable<FormControls> {
    const activeForm: ActiveForm = new ActiveForm();
    (activeForm.ContractorNumber = JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber), // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
      (activeForm.ProductPrefix = this.ProductPrefix);
    activeForm.Culture = this.translateService.currentLang;
    activeForm.CurrentStep = step;
    activeForm.IsCache = true;
    activeForm.OrderId = this.OrderId;
    activeForm.IsTrader = this.tresci.IsTrader;
    activeForm.IsComplaint = this.Complaint == null ? false: true;

    const formForSend =  _.cloneDeep(form);

    this.RemoveControlsFromGroup.forEach((control) => {
      // console.log(formForSend, formForSend.controls[control.name], control);
      if (formForSend.controls[control.name] !== undefined && ((formForSend.controls[control.name].value === null && control.module === 0) || formForSend.controls[control.name].value.module === control.module)) {
        // usuniete formForSend.removeControl(control.name);
      }
    });



    for (const key of Object.keys(formForSend.controls)) {
      const control = formForSend.controls[key];
      if (control.value !== null && control.value.inputType !== 'newline' && control.value.inputType !== 'kim') {
        if (
          (control.value.options !== undefined && control.value.options?.length !== 0) ||
          (control.value.optionsSelectMultiColumn !== undefined && control.value.optionsSelectMultiColumn?.length !== 0)
        ) {
          if (control.value.value === undefined) {
            this.broadcastService.ErrorHttp.next(`Wartość kontrolki jest nie właściwa ${control.value.name}`);
            break;
          } else if (control.value.value.Key !== undefined) {
            activeForm.Parameters.push({
              Key: key,
              Value: control.value.value.Key,
            });
          }
        } else {
          activeForm.Parameters.push({
            Key: key,
            Value: control.value.value,
          });
        }
      }
    }

    return this.httpClient.post<FormControls>('api/ActiveForm/GetStep', activeForm, { headers: this.getHeaderJson() }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public mergeControlsAndSave(saveForm: SaveForm, technologyVersion: number) {
    const activeForm: ActiveForm = new ActiveForm();
    (activeForm.ContractorNumber = JSON.parse(sessionStorage.getItem('_slc')).ContractorNumber), // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
      (activeForm.ProductPrefix = this.ProductPrefix);
    activeForm.CategoryProduct = SystemEnum[this.ProductPrefix];
    activeForm.Culture = this.translateService.currentLang;
    activeForm.IsCache = true;
    activeForm.DeliveryMethodId = this.DeliveryMethod.Name;
    activeForm.DeliveryAddressId = this.DeliveryAddress?.Id;
    activeForm.DeliveryAddress = this.DeliveryAddress?.Address;
    activeForm.DeliveryCity = this.DeliveryAddress?.City;
    activeForm.DeliveryZipCode = this.DeliveryAddress?.ZipCode;
    activeForm.DeliveryCountry = this.DeliveryAddress?.Country;
    activeForm.Id = this.ActiveFormOrderId;
    activeForm.Surcharges = this.Surcharges;
    activeForm.TechnologyVersion = technologyVersion;
    activeForm.IsTrader = this.tresci.IsTrader;
    activeForm.IsComplaint = this.Complaint == null ? false: true;

    
      saveForm.MainComponents.forEach((mainCmp) => {
        Object.keys(mainCmp.Form.controls).forEach((key) => {
          const mainControl = mainCmp.Form.controls[key]; 
          if (mainControl.value !== null && mainControl.value.inputType !== undefined && mainControl.value.inputType !== 'newline') {
            
            if (mainControl.value.inputType !== null && (mainControl.value.inputType.toLowerCase() === 'checkselectmulticolumn' || mainControl.value.inputType.toLowerCase() === 'selectmulticolumn')) {
              activeForm.Parameters.push({
                Key: key,
                Value: mainControl.value.value.Key,
                Position: 0,
                DisplayValue: mainControl.value.value.DisplayValue,
                DisplayValueEn: mainControl.value.value.DisplayValue,
                DisplayValueDe: mainControl.value.value.DisplayValue,
                DisplayValueFr: mainControl.value.value.DisplayValue,
                DisplayValuePl: mainControl.value.value.DisplayValue,
                DisplayValueCs: mainControl.value.value.DisplayValue,
                Module: 0,
                Type: mainCmp.Type,
                IsPrint: mainControl.value.isPrint,
              });
            } else if (
              (mainControl.value.options !== undefined && mainControl.value.options?.length !== 0 && mainControl.value.inputType !== 'checkselect') ||
              (mainControl.value.optionsSelectMultiColumn !== undefined && mainControl.value.optionsSelectMultiColumn?.length !== 0)
            ) {
              activeForm.Parameters.push({
                Key: key,
                Value: mainControl.value.value.Key,
                Position: 0,
                DisplayValue: mainControl.value.value.DisplayValue,
                DisplayValueEn: mainControl.value.value.DisplayValueEn,
                DisplayValueDe: mainControl.value.value.DisplayValueDe,
                DisplayValueFr: mainControl.value.value.DisplayValueFr,
                DisplayValuePl: mainControl.value.value.DisplayValuePl,
                DisplayValueCs: mainControl.value.value.DisplayValueCs,
                Module: 0,
                Type: mainCmp.Type,
                IsPrint: mainControl.value.isPrint,
              });
            } else if (mainControl.value.inputType !== 'kim' && mainControl.value.inputType !== 'checkselect' && mainControl.value.inputType !== 'checkfile') {
              activeForm.Parameters.push({
                Key: key,
                Value: mainControl.value.value,
                Position: 0,
                Module: 0,
                DisplayValue: mainControl.value.value,
                DisplayValueEn: mainControl.value.value,
                DisplayValueDe: mainControl.value.value,
                DisplayValueFr: mainControl.value.value,
                DisplayValuePl: mainControl.value.value,
                DisplayValueCs: mainControl.value.value,
                Type: mainCmp.Type,
                IsPrint: mainControl.value.isPrint,
              });
            } else if (mainControl.value.inputType === 'kim') {
              if (mainControl.value.value !== undefined && mainControl.value.value !== 'X') {
                for (const iterator of mainControl.value.value as Kim[]) {
                  iterator.Module = 0;
                  iterator.Position = 0;
                  iterator.Type = mainCmp.Type;
                  activeForm.ParameterExtensions.push(iterator);
                }
              }
            } else if (mainControl.value.inputType === 'checkfile' && mainControl.value.value !== null) {
              activeForm.Parameters.push({
                Key: key,
                Value: mainControl.value.value.Value,
                Position: 0,
                Module: 0,
                DisplayValue: mainControl.value.value.DisplayValue,
                DisplayValueEn: mainControl.value.value.DisplayValue,
                DisplayValueDe: mainControl.value.value.DisplayValue,
                DisplayValueFr: mainControl.value.value.DisplayValue,
                DisplayValuePl: mainControl.value.value.DisplayValue,
                DisplayValueCs: mainControl.value.value.DisplayValue,
                Type: mainCmp.Type,
                IsPrint: mainControl.value.isPrint,
              });
            } else if (mainControl.value.inputType === 'checkselect' && mainControl.value.value !== undefined) {
              activeForm.Parameters.push({
                Key: key,
                Value: mainControl.value.value.Key,
                Position: 0,
                DisplayValue: mainControl.value.value.DisplayValue,
                DisplayValueEn: mainControl.value.value.DisplayValueEn,
                DisplayValueDe: mainControl.value.value.DisplayValueDe,
                DisplayValueFr: mainControl.value.value.DisplayValueFr,
                DisplayValuePl: mainControl.value.value.DisplayValuePl,
                DisplayValueCs: mainControl.value.value.DisplayValueCs,
                Module: 0,
                Type: mainCmp.Type,
                IsPrint: mainControl.value.isPrint,
              });
            }
          }
          sessionStorage.removeItem(key);
        });
      });
    

    saveForm.SetsComponents.forEach((setCmp) => {
      Object.keys(setCmp.SetForm.controls).forEach((key) => { 
        const headerControl = setCmp.SetForm.controls[key];
        if (headerControl.value !== null && (headerControl.value.type === ActiveFormType.ModuleHeader || headerControl.value.type === ActiveFormType.ModuleFooter)) {
          if (headerControl.value.inputType !== 'newline') {
            if (headerControl.value.inputType.toLowerCase() === 'checkselectmulticolumn' || headerControl.value.inputType.toLowerCase() === 'selectmulticolumn') {
              activeForm.Parameters.push({
                Key: key, 
                Value: headerControl.value.value.Key,
                Position: headerControl.value.position,
                DisplayValue: headerControl.value.value.DisplayValue,
                DisplayValueEn: headerControl.value.value.DisplayValue,
                DisplayValueDe: headerControl.value.value.DisplayValue,
                DisplayValueFr: headerControl.value.value.DisplayValue,
                DisplayValuePl: headerControl.value.value.DisplayValue,
                DisplayValueCs: headerControl.value.value.DisplayValue,
                Module: setCmp.GetModuleNr(),
                Type: headerControl.value.type,
                IsPrint: headerControl.value.isPrint,
              });
            } else if (
              (headerControl.value.options !== undefined && headerControl.value.options.length !== 0) ||
              (headerControl.value.optionsSelectMultiColumn !== undefined && headerControl.value.optionsSelectMultiColumn?.length !== 0)
            ) {
              activeForm.Parameters.push({
                Key: key,
                Value: headerControl.value.value.Key,
                Position: headerControl.value.position,
                DisplayValue: headerControl.value.value.DisplayValue,
                DisplayValueEn: headerControl.value.value.DisplayValueEn,
                DisplayValueDe: headerControl.value.value.DisplayValueDe,
                DisplayValueFr: headerControl.value.value.DisplayValueFr,
                DisplayValuePl: headerControl.value.value.DisplayValuePl,
                DisplayValueCs: headerControl.value.value.DisplayValueCs,
                Module: setCmp.GetModuleNr(),
                Type: headerControl.value.type,
                IsPrint: headerControl.value.isPrint,
              });
            } else {
              activeForm.Parameters.push({
                Key: key,
                Value: headerControl.value.value,
                Position: headerControl.value.position,
                DisplayValue: headerControl.value.value,
                DisplayValueEn: headerControl.value.value,
                DisplayValueDe: headerControl.value.value,
                DisplayValueFr: headerControl.value.value,
                DisplayValuePl: headerControl.value.value,
                DisplayValueCs: headerControl.value.value,
                Module: setCmp.GetModuleNr(),
                Type: headerControl.value.type,
                IsPrint: headerControl.value.isPrint,
              });
            }
          }
        }
      });
      for (const posCmp of setCmp.SetPositionsComponents) {
        activeForm.Parameters.push({
          Key: 'Numermoduł',
          Value: setCmp.GetModuleNr(),
          Position: posCmp.PositionId,
          Module: setCmp.GetModuleNr(),
          Type: ActiveFormType.Position,
        });
        activeForm.Parameters.push({
          Key: 'Numerpozycji',
          Value: posCmp.PositionId,
          Position: posCmp.PositionId,
          Module: setCmp.GetModuleNr(),
          Type: ActiveFormType.Position,
        });
        Object.keys(posCmp.Form.controls).forEach((key) => {
          const posControl = posCmp.Form.controls[key];
          if (posControl.value !== null && posControl.value.type === ActiveFormType.Position) {
            if (posControl.value.inputType != null && (posControl.value.inputType.toLowerCase() === 'checkselectmulticolumn' || posControl.value.inputType.toLowerCase() === 'selectmulticolumn')) {
              activeForm.Parameters.push({
                Key: key,
                Value: posControl.value.value.Key,
                Position: posControl.value.position,
                DisplayValue: posControl.value.value.DisplayValue,
                DisplayValueEn: posControl.value.value.DisplayValue,
                DisplayValueDe: posControl.value.value.DisplayValue,
                DisplayValueFr: posControl.value.value.DisplayValue,
                DisplayValuePl: posControl.value.value.DisplayValue,
                DisplayValueCs: posControl.value.value.DisplayValue,
                Module: setCmp.GetModuleNr(),
                Type: posControl.value.type,
                IsPrint: posControl.value.isPrint,
              });
            } else if (
              ((posControl.value.options !== undefined && posControl.value.options.length !== 0) ||
                (posControl.value.optionsSelectMultiColumn !== undefined && posControl.value.optionsSelectMultiColumn?.length !== 0)) &&
              posControl.value.inputType !== 'kim' &&
              posControl.value.inputType !== 'checkselect'
            ) {
              activeForm.Parameters.push({
                Key: key,
                Value: posControl.value.value.Key,
                Position: posControl.value.position,
                DisplayValue: posControl.value.value.DisplayValue,
                DisplayValueEn: posControl.value.value.DisplayValueEn,
                DisplayValueDe: posControl.value.value.DisplayValueDe,
                DisplayValueFr: posControl.value.value.DisplayValueFr,
                DisplayValuePl: posControl.value.value.DisplayValuePl,
                DisplayValueCs: posControl.value.value.DisplayValueCs,
                Module: setCmp.GetModuleNr(),
                Type: posControl.value.type,
                IsPrint: posControl.value.isPrint,
              });
            } else if (posControl.value.inputType !== 'kim' && posControl.value.inputType !== 'checkselect' && posControl.value.inputType !== 'checkfile' && posControl.value.inputType !== 'newline') {
              activeForm.Parameters.push({
                Key: key,
                Value: posControl.value.value,
                Position: posControl.value.position,
                DisplayValue: posControl.value.value,
                DisplayValueEn: posControl.value.value,
                DisplayValueDe: posControl.value.value,
                DisplayValueFr: posControl.value.value,
                DisplayValuePl: posControl.value.value,
                DisplayValueCs: posControl.value.value,
                Module: setCmp.GetModuleNr(),
                Type: posControl.value.type,
                IsPrint: posControl.value.isPrint,
              });
            } else if (posControl.value.inputType === 'kim') {
              if (posControl.value.value !== undefined && posControl.value.value !== 'X') {
                for (const iterator of posControl.value.value as Kim[]) {
                  iterator.Module = setCmp.GetModuleNr();
                  iterator.Position = posCmp.PositionId;
                  iterator.Type = posCmp.Type;
                  activeForm.ParameterExtensions.push(iterator);
                }
              }
            } else if (posControl.value.inputType === 'checkselect' && posControl.value.value !== undefined) {
              activeForm.Parameters.push({
                Key: key,
                Value: posControl.value.value.Key,
                Position: posControl.value.position,
                DisplayValue: posControl.value.value.DisplayValue,
                DisplayValueEn: posControl.value.value.DisplayValueEn,
                DisplayValueDe: posControl.value.value.DisplayValueDe,
                DisplayValueFr: posControl.value.value.DisplayValueFr,
                DisplayValuePl: posControl.value.value.DisplayValuePl,
                DisplayValueCs: posControl.value.value.DisplayValueCs,
                Module: setCmp.GetModuleNr(),
                Type: posControl.value.type,
                IsPrint: posControl.value.isPrint,
              });
            }
          }
        });
      }
    });
    this.checkParam(activeForm);
    if(this.Complaint != null){
      activeForm.ComplaintId = this.Complaint.Id;
      activeForm.ComplaintNumber = this.Complaint.ComplaintNumber;
    } else {
      activeForm.ComplaintId = null;
      activeForm.ComplaintNumber = null;
    }
    activeForm.CopiedOrderId = this.CopiedOrderId;

    const dane = serialize(activeForm);
    const formData: FormData = new FormData();
    for (const file of this.Files) {
      if (file.File !== undefined) {
        if (activeForm.Parameters.findIndex((x) => x.Key === file.Key) > 0) {
          formData.append(file.Id, file.File, file.FileName);
        }
      }
    }
    formData.append('ActiveFormOrderVm', dane);
    //formData.append('ComplaintId',  serialize(this.Complaint.Id));
    //formData.append('ComplaintNumber', this.Complaint.ComplaintNumber);
     

    return this.httpClient.post<any>('api/ActiveForm/AddorUpdateActiveFormOrderWithFiles', formData, { headers: this.getHeaderFile() });
  }
  public checkParam(activeForm:ActiveForm){
    if(this.ProductPrefix == 'FS' ||  this.ProductPrefix == 'FR'){
      activeForm.Parameters.forEach(element => {
        if(element.Module > 0 && element.Position > 0){
          
          if(Number(element.Value)){
            this.setParam(activeForm, element);
          }
        }
      });
      // console.log(activeForm.Parameters);
    }
    
  }

 
  public setParam(activeForm:ActiveForm, element:Parameter){
    var suma:number = 0;
    var min:number = undefined;
    var max:number = undefined;
    
      activeForm.Parameters.forEach(element2 => {
        if(element2.Module == element.Module && element2.Position > 0){
          if(element2.Key == element.Key){
            suma += element2.Value*1;
            if(min == undefined || element2.Value < min){
              min = element2.Value*1;
            }
            if(max == undefined ||element2.Value > max){
              max = element2.Value*1;
            }
          }
        }
      });
      var byla_suma = false;
      var byl_min = false;
      var byl_max = false;

      activeForm.Parameters.forEach(element2 => {
        if(element2.Module == element.Module && element2.Position == 0){
          
          if(element2.Key == "suma_" + element.Key){
            byla_suma = true;
            element2.Value = suma;
          }
          if(element2.Key == "min_" + element.Key){
            byl_min = true;
            element2.Value = min;
          }
          if(element2.Key == "max_" + element.Key){
            byl_max = true;
            element2.Value = max;
          }
        }
      });
      if(!byla_suma){
        var sum = new Parameter();
        sum.Module = element.Module;
        sum.Position = 0;
        sum.Value = suma;
        sum.DisplayValue = suma;
        sum.DisplayValueCs = suma;
        sum.DisplayValueDe = suma;
        sum.DisplayValueEn = suma;
        sum.DisplayValueFr = suma;
        sum.DisplayValuePl = suma;
        sum.Key = "suma_" + element.Key;
        sum.Type = 3;
        sum.IsPrint = false;
        activeForm.Parameters.push(sum);
      }
      if(!byl_min){
        var _min = new Parameter();
        _min.Module = element.Module;
        _min.Position = 0;
        _min.Value = min;
        _min.DisplayValue = min;
        _min.DisplayValueCs = min;
        _min.DisplayValueDe = min;
        _min.DisplayValueEn = min;
        _min.DisplayValueFr = min;
        _min.DisplayValuePl = min;
        _min.Key = "min_" + element.Key;
        _min.Type = 3;
        _min.IsPrint = false;
        activeForm.Parameters.push(_min);
      }
      if(!byl_max){
        var _max = new Parameter();
        _max.Module = element.Module;
        _max.Position = 0;
        _max.Value = max;
        _max.DisplayValue = max;
        _max.DisplayValueCs = max;
        _max.DisplayValueDe = max;
        _max.DisplayValueEn = max;
        _max.DisplayValueFr = max;
        _max.DisplayValuePl = max;
        _max.Key = "max_" + element.Key;
        _max.Type = 3;
        _max.IsPrint = false;
        activeForm.Parameters.push(_max);
      }
      
  }

  public mapControlsToHtml(technologyControls: TechnologyControl[], step: string, isLastStepInSpecificType = false): HtmlControl[] {
    const controlConfigList = new Array<HtmlControl>();
    technologyControls.forEach((technologyControl) => {
      const control = this.formControlMapper.mapToHtml(technologyControl, step, isLastStepInSpecificType);
      controlConfigList.push(control);
    });
    return controlConfigList;
  }

  public getDeliveryAddresses(): Observable<any> {
    const contractor: Contractor = JSON.parse(sessionStorage.getItem('_slc'));
    // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
    return this.httpClient.post<any>('api/Contractor/GetContractorAddresses', contractor.ContractorNumber, { headers: this.getHeaderJson() });
  }

  public GetComplaintActiveFrom(): Observable<any> {
    const contractor: Contractor = JSON.parse(sessionStorage.getItem('_slc'));

    // JSON.parse(this.cookieService.get('_slc')).ContractorNumber,
    return this.httpClient.post<any>('api/Complaint/GetComplaintActiveFrom', contractor, { headers: this.getHeaderJson() });
  }
  public GetComplaintActiveFrom2(
  ): Observable<any> {
    
    const contractor: Contractor = JSON.parse(sessionStorage.getItem('_slc'));
    var sort: MatSort = new MatSort();
    sort.active = 'ComplaintNumber';
    sort.direction = 'desc';
    var filterColumn = '';
    var filterrTerm = '';
    var dateTo = formatDate(Date.now(),'yyyy-MM-dd',"en-US"); // Date.now();
    var dateFrom = formatDate(new Date("2000-01-01"),'yyyy-MM-dd',"en-US"); //new Date("2000-01-01");
    return this.httpClient.get<any>(
      'api/OrderRegister/GetComplaintOrders?contractorNumber=' + contractor.ContractorNumber
      +
        '&searchColumn=' +
        filterColumn +
        '&searchTerm=' +
        filterrTerm +
        '&columnKeySort=' +
        sort.active +
        '&kindSort=' +
        sort.direction +
        '&pageNumber=' +
        1 +
        '&itemPerPage=' +
        100000 +
        '&dateFrom=' +
        dateFrom +
        '&dateTo=' +
        dateTo +
        '&isMineOrders=' +
        false
        
    );
  }
  

  public getSurcharges(): Observable<Surcharge[]> {
    return this.httpClient.get<Surcharge[]>('/api/Additives/GetSurcharges?system=' + window['site'] + '&culture=' + window['culture']);
  }
}
