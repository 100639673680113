import { HttpClient, HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';
import { Uwagi } from './uwagi';

export class Input {
  public Min;
  public Max;
  public Value;
  public Value_slider;
  public step = 5;
  public Slider = false;
  public KeyName;
  public uwagi: Uwagi;
    constructor(private id, uwagi, KeyName, Min, Max, Value, Slider, private tresci, public controlType, public check = true) {
      this.uwagi = uwagi;
      this.KeyName = KeyName;
      this.Min = Min * 1;
      this.Max = Max * 1;
      this.Value = Value;
      this.Slider = Slider;
      // if (sessionStorage.getItem('konf_' + this.id + '_'  + KeyName) != null) {
      //   this.Value = parseInt(sessionStorage.getItem('konf_' + this.id + '_'  + KeyName), 10);
      // }
      if (this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName) !== null) {
        // this.ustaw(this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName));
        this.Value = parseInt(this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName), 10);
      }
      if (this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName + '_check') !== null) {
        this.check = this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName + '_check');
      }
      this.sprawdz(this.Value);
      this.Value_slider = this.NaSlider(this.Value);

    }
    public zmien_check() {
      this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName + '_check', this.check);
    }
    public aktualizuj(Min, Max, Value, Slider) {
      this.Min = Min * 1;
      this.Max = Max * 1;
       this.Slider = Slider;
       this.sprawdz(this.Value);
       this.Value_slider = this.NaSlider(this.Value);
    }
    public zmienVal() {
      this.Value = this.SliderNa(this.Value_slider);
    }
    private SliderNa(wartosc) {
      return Math.round((this.Min * 1 + wartosc / 5000 * (this.Max - this.Min)) / this.step) * this.step;
    }
    private NaSlider(wartosc) {
      return (wartosc - this.Min) / (this.Max - this.Min) * 5000;
    }
    public getActual() {

      if (this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName + '_check') !== null) {
        this.check = this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName + '_check');
      }
      if (!this.check && this.controlType === 'CheckInput') {
        return 'X';
      } else if (!this.check && this.controlType === 'CheckNumber') {
        return 0;
      }
      let val = this.Value;
      if (this.Value < this.Min * 1) {
        val = '';
      } else if (this.Value > this.Max * 1) {
        val = '';
      }
      return val;
    }
    public zamknij() {
    }
    public zmien(event) {
      if (isNaN(event.value) || event.value === '') {
        this.Value = '';
        event.value  = '';
        this.Value_slider = this.NaSlider(this.Value);
        this.uwagi.zmien('Wartość nie jest liczbą!');
      } else if (event.value < this.Min * 1) {
        this.Value = '';
        event.value  = '';
        this.Value_slider = this.NaSlider(this.Value);
        this.uwagi.zmien('Minimalna wartość to ' + this.Min);
      } else if (event.value > this.Max * 1) {
        this.Value = '';
        event.value  = '';
        this.Value_slider = this.NaSlider(this.Value);
        this.uwagi.zmien('Maksymalna wartość to ' + this.Max);
      } else {
        this.Value = event.value;
        this.Value_slider = this.NaSlider(this.Value);
      }
      // sessionStorage.setItem('konf_' + this.id + '_'  + this.KeyName, this.Value.toString());
      this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, this.Value.toString());
      this.zmien_check();
    }

    public zmien_key(event) {
      if (event.keyCode === 13) {
        this.zmien(event.target);
      }
    }
    public ustaw(Val, controlType) {
      if (Val === 'X') {
        this.check = false;
      } else {
        this.check = true;
      }

      if (Val === '0' && controlType === 'CheckNumber') {
        this.check = false;
      } else {
        this.check = true;
      }
      this.zmien_check();
      this.Value = Val;

      this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, this.Value.toString());
      this.zmien_check();
      this.sprawdz(Val);
    }
    public sprawdz(value) {
      if (isNaN(value)) {
        this.Value = '';
      } else if (value < this.Min) {
        this.Value = '';
      } else if (value > this.Max) {
        this.Value = '';
      } else {
        this.Value = value;
      }
      if(this.Value !== '') {
      this.Value = Math.floor(this.Value);
      }
      sessionStorage.setItem('konf_' + this.id + '_'  + this.KeyName, this.Value.toString());
      this.Value_slider = this.NaSlider(this.Value);
    }
    public zmieniono_slider() {
      this.Value = this.SliderNa(this.Value_slider);
      sessionStorage.setItem('konf_' + this.id + '_'  + this.KeyName, this.Value.toString());
    }
    public poza() {
    }
}

