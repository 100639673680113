<nav class="navbar fixed-top navbar-expand" style="background-color: #002d45">
  <div class="container">
    <a class="navbar-brand mr-5" [routerLink]="'/Home'">
      <img height="100px" src="../../assets/images/LogoSeltSVG.svg" alt="SELT" />
    </a>
    <h1 *ngIf="ShowWarning" class="app-dev">WERSJA TESTOWA!!</h1>
    <ul class="navbar-nav">
      <li class="nav-item" [routerLink]="'/Home'">
        <button mat-icon-button class="nav-link">
          <mat-icon>home</mat-icon>
        </button>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item" role="button" (click)="openContractorChooseDialog()">
        <div class="nav-link">
          <div class="d-inline-block">
            <mat-icon class="align-middle" style="color: #1bcf14; font-size: 0.7em">circle</mat-icon>
          </div>
          <div class="d-inline-block">
            <p class="m-0">{{ Contractor.ContractorName }}</p>
            <p class="m-0" style="font-size: 0.8em">{{ User.Name }}</p>
          </div>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto mr-4">
      <li class="nav-item">
        <app-notification></app-notification>
      </li>
      <li class="nav-item" title="Tłumaczenie strony">
        <button mat-icon-button class="nav-link" *ngIf="Translate" [permission]="PermissionEnum.Translation">
          <mat-icon>translate</mat-icon>
        </button>
      </li>
      <li class="nav-item" title="Technologia">
        <button mat-icon-button class="nav-link" *ngIf="User.IsTrader" [color]="getColor()"
          (click)="changeTechnologyShow()">
          <mat-icon>perm_data_setting</mat-icon>
        </button>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item" [routerLinkActive]="'active'" [routerLink]="'/Orders'"
        (mouseenter)="systemsTrigger.closeMenu()">
        <!-- Step 4: Close the menu when a link is clicked. -->
        <a class="nav-link" role="button" id="zamówienia" [translation]="TranslationEnum.Translation">
          {{ 'zamówienia' | translate }}
        </a>
      </li>
      <li ngbDropdown class="nav-item widoczne_{{!BlockingB2B}}" [routerLinkActive]="'active'" 
        (mouseenter)="systemsTrigger.openMenu(); languagesTrigger.closeMenu()">
        <a class="nav-link" role="button"  [matMenuTriggerFor]="systems" #systemsTrigger="matMenuTrigger" id="zamów"
          [translation]="TranslationEnum.Translation" (click)="routeTo('Systems')" [routerLink]="'/Systems'">
          {{ 'zamów' | translate }}</a>
        <mat-menu #systems="matMenu" [hasBackdrop]="false">
          <span (mouseleave)="systemsTrigger.closeMenu()">
            <ng-container  *ngFor="let system of SystemsList">
              
                <button *ngIf = "!IsTrader" mat-menu-item (click)="routeToNewOrder(system)">
                  <span id="{{ 'system_' + system }}" [translation]="TranslationEnum.Translation">
                    {{ 'system_' + system | translate }}
                  </span>
                </button> 

                <button *ngIf = "IsTrader" mat-menu-item (click)="routeToNewOrder2(system)">
                  <span id="{{ 'system_' + system }}" [translation]="TranslationEnum.Translation">
                    {{ 'system_' + system | translate }}
                  </span>
                </button>
                
                <span *ngIf = "false && IsTrader" class="new_ordeer_box">
                  <button *ngIf = "IsTrader" class="new_order" (click)="routeToNewOrder2(system)">
                    experimental
                  </button>
                </span>
               
              
              
              
            </ng-container>
            
            
          </span>
        </mat-menu>
      </li>
<!--
      <li class="nav-item" [routerLinkActive]="'active'" [routerLink]="'/Reklamacje'"
        (mouseenter)="systemsTrigger.closeMenu()">
        <a class="nav-link" role="button" id="reklamacje" [translation]="TranslationEnum.Translation">
          {{ 'reklamacje' | translate }}
        </a>
      </li> -->
      <li ngbDropdown class="nav-item" (mouseenter)="systemsTrigger.closeMenu(); languagesTrigger.openMenu()">
        
        
          
        
     
        <a class="nav-link" role="button" [matMenuTriggerFor]="languages" #languagesTrigger="matMenuTrigger"
          [id]="this.translateService.currentLang" [translation]="TranslationEnum.Translation">
          <span class="flagabox"><span class="flagawybrana flaga flaga{{this.translateService.currentLang}}"></span></span>{{ this.translateService.currentLang | translate }}</a>
        <mat-menu #languages="matMenu" [hasBackdrop]="false">
          <span (mouseleave)="languagesTrigger.closeMenu()">
            <button mat-menu-item *ngFor="let lang of this.translateService.langs" (click)="changeLanguage(lang)">
              <mat-icon class="flaga flaga{{lang}}"></mat-icon>
              <span [id]="lang" [translation]="TranslationEnum.Translation">
                {{ lang | translate }}
              </span>
            </button>
          </span>
        </mat-menu>
      </li>

      <li class="nav-item" (mouseenter)="languagesTrigger.closeMenu()">
        <button mat-icon-button class="nav-link" (click)="logOut()">
          <mat-icon>logout</mat-icon>
        </button>
      </li>
    </ul>
  </div>
</nav>
