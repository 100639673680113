import { Files } from "./Complaint.model";

export class Consultation {
    ParentId:number;
    ComplaintId: Number; 					
    Context:string;	
    Login:string;		
    Name:string;	
    File:string;
    Size:number;
    Type:string;
    IsFromCustomer:boolean;
    AttachmentId:number;
    Attachment:Files;
   }