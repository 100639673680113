import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {

  TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
