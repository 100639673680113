<h2 mat-dialog-title id="paczki" [translation]="TranslationEnum.Translation">
  {{'paczki'|translate}}
  <!-- <app-tlumacz [id]="'Paczki'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
</h2>
<div mat-dialog-content>
  <table mat-table [dataSource]="data" class="mat-elevation-z8 mt-3 mb-3">

    <!-- LastPackageDate Column -->
    <ng-container matColumnDef="LastPackageDate">
      <th mat-header-cell *matHeaderCellDef id="dataostatniejpaczki" [translation]="TranslationEnum.Translation">
        {{'dataostatniejpaczki'|translate}}
        <!-- <app-tlumacz [id]="'DataOstatniejPaczki'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row"> {{row.LastPackageDate | date:'yyyy-MM-dd H:mm:ss'}} </td>
    </ng-container>

    <!-- PackageDate Column -->
    <ng-container matColumnDef="PackageDate">
      <th mat-header-cell *matHeaderCellDef id="packagedate" [translation]="TranslationEnum.Translation">
        {{'packagedate'|translate}}
        <!-- <app-tlumacz [id]="'PackageDate'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row"> {{row.PackageDate | date:'yyyy-MM-dd H:mm:ss'}} </td>
    </ng-container>

    <!-- PackageNumber Column -->
    <ng-container matColumnDef="PackageNumber">
      <th mat-header-cell *matHeaderCellDef id="packagenumber" [translation]="TranslationEnum.Translation">
        {{'packagenumber'|translate}}
        <!-- <app-tlumacz [id]="'PackageNumber'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row"> {{row.PackageNumber}} </td>
    </ng-container>

    <!-- ProductionNumber Column -->
    <ng-container matColumnDef="ProductionNumber">
      <th mat-header-cell *matHeaderCellDef id="productionnumber" [translation]="TranslationEnum.Translation">
        {{'productionnumber'|translate}}
        <!-- <app-tlumacz [id]="'ProductionNumber'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row"> {{row.ProductionNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="Weight">
      <th mat-header-cell *matHeaderCellDef id="weight" [translation]="TranslationEnum.Translation">
        {{'weight'|translate}}
        <!-- <app-tlumacz [id]="'TimeFromPacking'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Weight}} </td>
    </ng-container>

    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef id="type" [translation]="TranslationEnum.Translation">
        {{'type'|translate}}
        <!-- <app-tlumacz [id]="'Type'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </th>
      <td mat-cell *matCellDef="let row"> {{row.Type}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <div class="w-100">
    <button mat-flat-button id="ok" class="btn-sm float-right" [translation]="TranslationEnum.Translation" color="primary"
      mat-dialog-close>
      {{'ok'|translate}}</button>
  </div>
</div>
