import { FilterKim, KimDialogData } from '../../../../models/form/filter-kim';
import { FormControlsService } from './../../../form-controls.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Kim } from 'src/app/shared/models/form-model/kim.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-kim-dialog',
  templateUrl: './kim-dialog.component.html',
  styleUrls: ['./kim-dialog.component.scss']
})
export class KimDialogComponent implements OnInit, OnDestroy {

  public TranslationEnum = TranslationEnum;

  imgSrc: SafeUrl;
  filterValue: string;
  private subscriptions: Subscription[] = [];

  public dataSource: MatTableDataSource<Kim>;

  public displayedColumns: string[] = [
    'CategoryCode', 'Name', 'BasicPrice', 'Description', 'Power', 'OutputSignals', 'Picture', 'Quantity'
  ];

  public systemsFilter: FilterKim[] = [];
  public companysFilter: FilterKim[] = [];

  public systemFilter: FilterKim;
  public companyFilter: FilterKim;

  public CanNotAddMoreThanOne: boolean;


  constructor(public dialogRef: MatDialogRef<KimDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: KimDialogData, public formControlsService: FormControlsService,
    public sanitizer: DomSanitizer) {
    this.dataSource = new MatTableDataSource<Kim>();
    this.dataSource.data = Array.from(data.Data);
    this.CanNotAddMoreThanOne = data.IsPossition; // window['site'] === 'MA';
    
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit() {
    this.subscriptions.push(this.formControlsService.getSystemFilterForKim().subscribe(x => {
      this.systemsFilter = x;
      if (x.length > 0) {
        this.systemFilter = x[0];
      }
    }));
    this.subscriptions.push(this.formControlsService.getCompanyFilterForKim().subscribe(x => {
      this.companysFilter = x;
      if (x.length > 0) {
        this.companyFilter = x[0];
      }
    }));

  }



  applySelectFilter(): void {
    let filteredData = Array.from(this.data.Data);
    if (this.systemFilter !== undefined && this.systemFilter.Klucz !== 'All') {
      filteredData = filteredData.filter(x => x['' + this.systemFilter.Klucz] === true);
    }
    if (this.companyFilter !== undefined && this.companyFilter.Klucz !== 'All') {
      filteredData = filteredData.filter(x => x['' + this.companyFilter.Klucz] === true);
    }
    this.dataSource.data = Array.from(filteredData);

    this.applyFilter();
  }

  applyFilter(): void {
    if (this.filterValue !== undefined) {
      this.dataSource.filter = this.filterValue.trim().toLowerCase();
    }
  }

  getImg(kim: Kim): void {
    if (kim.ImageSrc === undefined) {
      this.subscriptions.push(this.formControlsService.getImgForKim(kim.Index).subscribe(data => {
        this.createImageFromBlob(data, kim);
      }, error => {
        kim.ImageSrc = '';
      }));
    }

  }

  createImageFromBlob(image: Blob, kim: Kim) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      kim.ImageSrc = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }


  checkIsMaxValue(event: any, row: any) {
    const min = 0;
    let max = 600;
    if (this.data.IsPossition) {
      max = 1;
    }
    if (event.target.value !== '' && +event.target.value >= min && +event.target.value <= max) {
      const value = +event.target.value;
      row.Quantity = +value;

    } else {

      row.Quantity = +max;
    }
  }


}
