import { KindOper } from './../../shared/enums/kind-oper-enum';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { User } from 'src/app/shared/models/user/user.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.scss']
})
export class SystemsComponent implements AfterViewInit, OnDestroy {

  public TranslationEnum = TranslationEnum;
  private subscriptions: Subscription[] = [];

  private list = [];
  IsTrader: boolean;

  constructor(authorizationService: AuthorizationService, private router: Router) {
    this.IsTrader = window['isTrader'];
    this.subscriptions.push(authorizationService.User.asObservable().subscribe((user: User) => {
      if (user) {
        if(user.BlockingB2B){
          this.list = [];
        } else if (user.IsTrader) {
          this.list = ['MA', 'MAD', 'PG', 'MP', 'MPD', 'FS', 'FSD', 'FR', 'FRD', 'WGS', 'MV', 'MVD', 'SB'];
        } else {
          this.list = user.ContractorSystems;
          // console.log("sys", user.ContractorSystems);
        }
      }
    }));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngAfterViewInit(): void {
    this.list.forEach(system => {
      const list = document.getElementsByClassName(system);
      for (let i = 0; i < list.length; i++) {
        (<HTMLFormElement>list[i]).style.display = 'block';
      }
    });
  }

  routeToNewOrder(system: string): void {
    if(this.IsTrader){
      this.router.navigate([`NOrder/${KindOper[KindOper.Add]}/${system}`]);
    } else {
      this.router.navigate([`Order/${KindOper[KindOper.Add]}/${system}`]);
    }
    
  }

}
