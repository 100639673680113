import { Component, Inject, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Package } from 'src/app/shared/models/order-register/order-list/package.model';
import { MatTableDataSource } from '@angular/material/table';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';



@Component({
  selector: 'app-order-package-dialog',
  templateUrl: './order-package-dialog.component.html',
  styleUrls: ['./order-package-dialog.component.scss']
})
export class OrderPackageDialogComponent  {

  public TranslationEnum = TranslationEnum;

  dataSource: MatTableDataSource<Package> = new MatTableDataSource<Package>();

  displayedColumns: string[] = [
    'LastPackageDate', 'PackageDate', 'PackageNumber', 'ProductionNumber', 'Weight', 'Type'
  ];

  constructor(public dialogRef: MatDialogRef<OrderPackageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Package[]) {
    this.dataSource.data = data;
  }


}
