


<div class="container"  *ngIf="!Loading">
  <div class="row">
    <h1 id="panel_użytkownika" [translation]="TranslationEnum.Translation">
      {{'panel_użytkownika'|translate}}
      <!-- <app-tlumacz [id]="'Panel użytkownika'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </h1>
  </div>
  <div class="margin-top">
    <div class="row">
      <div class="col-md-3">
        <div class="btn-group">
          <button id="szczegóły_konta" [translation]="TranslationEnum.Translation"
            (click)="menuUserPanel('editAccount')">
            {{'szczegóły_konta'|translate}}
            <!-- <app-tlumacz [id]="'Szczegóły konta'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </button>
          <button id="zmień_hasło" [translation]="TranslationEnum.Translation"
            (click)="menuUserPanel('changePassword')">
            {{'zmień_hasło'|translate}}
            <!-- <app-tlumacz [id]="'Zmień hasło'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </button>
          <button *ngIf = "false" id="rabaty2023" [translation]="TranslationEnum.Translation"
            (click)="GetComplain(2023)">
            {{'rabaty2023'|translate}}
            <!-- <app-tlumacz [id]="'Zmień hasło'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </button>
          <button id="rabaty2024" [translation]="TranslationEnum.Translation"
            (click)="GetComplain(2024)">
            {{'rabaty2024'|translate}}
            <!-- <app-tlumacz [id]="'Zmień hasło'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </button>
          <!-- <button id="myclients" [translation]="TranslationEnum.Translation" (click)="menuUserPanel('myClients')">
            {{'myclients'|translate}}
          </button> -->
          <!-- <button>Zmień hasło</button> -->
        </div>
      </div>
      <div class="col-md-9" *ngIf="contractor != null && isAccount">
        <div class="margin-right">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label id="imię" [translation]="TranslationEnum.Translation">
                  {{'imię'|translate}}
                  <!-- <app-tlumacz [id]="'Imię'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </label>
                <input class="form-control" disabled value="{{contractor.FirstName}}">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label id="nazwisko" [translation]="TranslationEnum.Translation">
                  {{'nazwisko'|translate}}
                  <!-- <app-tlumacz [id]="'Nazwisko'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </label>
                <input class="form-control" disabled value="{{contractor.LastName}}">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label id="nazwa_firmy" [translation]="TranslationEnum.Translation">
                  {{'nazwa_firmy'|translate}}
                  <!-- <app-tlumacz [id]="'Nazwa firmy'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </label>
                <input class="form-control" disabled value="{{contractor.CompanyName}}">
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label id="adresy_dostaw" [translation]="TranslationEnum.Translation">
                  {{'adresy_dostaw'|translate}}
                  <!-- <app-tlumacz [id]="'Adresy dostaw'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </label>
                <div class="ContractorAddresses">
                  <div *ngFor="let adres of ContractorAddresses; let i = index;" class="adres" >
                    {{adres.Address}} {{adres.ZipCode}} {{adres.City}} {{adres.Zone}}
                  </div>
                </div>
                <select *ngIf="this.tresci.user.dostawa != null" class="form-control">
                  <ng-container *ngFor="let shipping of this.tresci.user.dostawa.ContractorAdresses; let i = index">
                    <option value="i">{{shipping.Adress}} {{shipping.City}}</option>
                  </ng-container>
                </select>
                <!-- <label id="adresy_dostaw_dod_adresy" class="infoLabel" [translation]="TranslationEnum.Translation">
                  {{'adresy_dostaw_dod_adresy'|translate}}
                </label> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label id="handlowiec_imie" [translation]="TranslationEnum.Translation">
                  {{'handlowiec_imie'|translate}}
                  <!-- <app-tlumacz [id]="'Handlowiec_Imie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </label>
                <input class="form-control" disabled value="{{contractor.TraderName}}">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label id="handlowiec_kontakt" [translation]="TranslationEnum.Translation">
                  {{'handlowiec_kontakt'|translate}}
                  <!-- <app-tlumacz [id]="'Handlowiec_Kontakt'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </label>
                <input class="form-control" disabled value="{{contractor.TraderEmail}}">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label id="vatnip" [translation]="TranslationEnum.Translation">
                  {{'vatnip'|translate}}
                  <!-- <app-tlumacz [id]="'Handlowiec_Imie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </label>
                <input class="form-control" disabled value="{{contractor.Nip}}">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label id="konto" [translation]="TranslationEnum.Translation">
                  {{'konto'|translate}}
                  <!-- <app-tlumacz [id]="'Handlowiec_Imie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </label>
                <input class="form-control" disabled value="{{contractor.Konto}}">
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-9" *ngIf="contractor != null && changePassword">
        <!-- *ngIf=!hideOption> -->
        <div class="row">
          <form #f="ngForm" [matchValue]="['password', 'confirmPassword']">
            <div class="form-group">
              <label for="password" id="nowe_hasło" [translation]="TranslationEnum.Translation">
                {{'nowe_hasło'|translate}}
                <!-- <app-tlumacz [id]="'Nowe hasło'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <input type="password" class="form-control" [(ngModel)]="model.password" #password="ngModel"
                name="password" [ngClass]="{ 'is-invalid': f.touched && password.invalid}" required minlength="8">
              <div *ngIf="f.touched && password.invalid" class="invalid-feedback">
                <div *ngIf="password?.errors.required" id="hasło_jest_wymagane"
                  [translation]="TranslationEnum.Translation">
                  {{'hasło_jest_wymagane'|translate}}
                  <!-- <app-tlumacz [id]="'Hasło jest wymagane'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </div>
                <div *ngIf="password?.errors.minlength" id="hasło_musi_się_składać_przynajmnie_z_8_znaków"
                  [translation]="TranslationEnum.Translation">
                  {{'hasło_musi_się_składać_przynajmnie_z_8_znaków'|translate}}
                  <!-- <app-tlumacz [id]="'Hasło musi się składać przynajmniej z 8 znaków'" [typ]="'stale'"
                    [tresci]="tresci"></app-tlumacz> -->
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="confirmPassword" id="potwierdź_hasło" [translation]="TranslationEnum.Translation">
                {{'potwierdź_hasło'|translate}}
                <!-- <app-tlumacz [id]="'Potwierdź hasło'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <input type="password" class="form-control" [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel"
                name="confirmPassword" [class.is-invalid]="f.touched && confirmPassword.invalid" required>
              <div *ngIf="f.touched && confirmPassword.invalid" class="invalid-feedback">
                <div *ngIf="confirmPassword?.errors.required" id="potwierdź_hasło"
                  [translation]="TranslationEnum.Translation">
                  {{'potwierdź_hasło'|translate}}
                  <!-- <app-tlumacz [id]="'Potwierdź hasło'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </div>
                <div *ngIf="confirmPassword?.errors.matchValueError" id="hasła_muszą_się_zgadzać"
                  [translation]="TranslationEnum.Translation">
                  {{'hasła_muszą_się_zgadzać'|translate}}
                  <!-- <app-tlumacz [id]="'Hasła muszą się zgadzać'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </div>
              </div>
            </div>
            <span id="zmień_hasło" class="dodajBtn" [translation]="TranslationEnum.Translation" type="submit"
              (click)="onSubmit(f)">
              {{'zmień_hasło'|translate}}
              <!-- <app-tlumacz [id]="'Zmień hasło'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </span>
          </form>
        </div>
        <ng-container *ngIf="hideOption">
          <div class="password" *ngIf="contractor != null && changePassword">
            <h1 id="hasło_zostało_zmienione" class="passwordText" [translation]="TranslationEnum.Translation">
              {{'hasło_zostało_zmienione'|translate}}
              <!-- <app-tlumacz [id]="'Hasło zostało zmienione'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </h1>
          </div>
        </ng-container>
      </div>
      <div class="col-md-9" *ngIf="myClients">
        <div class="klienci">
          <table>
            <tr class="naglowek">
              <td id="nazwaklienta" [translation]="TranslationEnum.Translation">
                {{'nazwaklienta'|translate}}
                <!-- <app-tlumacz [id]="'NazwaKlienta'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </td>
              <td id="szczegoly" [translation]="TranslationEnum.Translation">
                {{'szczegoly'|translate}}
                <!-- <app-tlumacz [id]="'Szczegoly'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </td>
              <td id="edytuj" class="" [translation]="TranslationEnum.Translation">
                {{'edytuj'|translate}}
                <!-- <app-tlumacz [id]="'Edytuj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </td>
              <td id="usun" [translation]="TranslationEnum.Translation">
                {{'usun'|translate}}
                <!-- <app-tlumacz [id]="'Usun'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </td>
            </tr>
            <tr class="pozycja" *ngFor="let client of listContractors">
              <td class="lewa_pozycja">
                <div class="ramka_td"><span>{{client.Name}}</span></div>
              </td>
              <td>
                <div class="ramka_td"><span class="zobacz ikona"
                    (click)="menuModal(content,'previewClient', client)"></span></div>
              </td>
              <td>
                <div class="ramka_td"><span class="edytujTable ikona"
                    (click)="menuModal(content,'editClient', client)"></span></div>
              </td>
              <td class="prawa_pozycja">
                <div class="ramka_td"><span class="ikona usun" (click)="checkPosToDel(client.Id);"></span></div>
                <div class="potwierdzenie" *ngIf="isClick && clientToDel===client.Id"
                  (click)="$event.stopPropagation();">
                  <div class="tresc_p" id="czy_na_pewno_usunac_pozycje" [translation]="TranslationEnum.Translation">
                    {{'czy_na_pewno_usunac_pozycje'|translate}}
                    <!-- <app-tlumacz [id]="'Czy_na_pewno_usunac_pozycje'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </div>
                  <div class="buttony_p">
                    <div id="tak" class="tak" [translation]="TranslationEnum.Translation"
                      (click)="deleteClient(client.Id); $event.stopPropagation();">
                      {{'tak'|translate}}
                      <!-- <app-tlumacz [id]="'Tak'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                    </div>
                    <div id="nie" class="nie" [translation]="TranslationEnum.Translation"
                      (click)="isClick = false; $event.stopPropagation();">
                      {{'nie'|translate}}
                      <!-- <app-tlumacz [id]="'Nie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="dodaj">
          <span id="dodajklienta" class="dodajBtn" [translation]="TranslationEnum.Translation"
            (click)="menuModal(content,'addClient')">
            {{'dodajklienta'|translate}}
            <!-- <app-tlumacz [id]="'DodajKlienta'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </span>
        </div>
      </div>
    </div>
  </div>

  <ng-template #content let-modal>
    <form #clientForm="ngForm" (ngSubmit)="addOrUpdate(clientForm)">
      <div class="modal-header">
        <h4 class="modal-title" id="{{modalTittle}}" [translation]="TranslationEnum.Translation">
          {{modalTittle|translate}}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label id="numerkontrahenta" [translation]="TranslationEnum.Translation">
                {{'numerkontrahenta'|translate}}
                <!-- <app-tlumacz [id]="'NumerKontrahenta'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <input name="number" type="text" class="form-control" disabled value="{{ClientVm.ContractorNumber}}"
                [(ngModel)]="ClientVm.ContractorNumber">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label id="nip" [translation]="TranslationEnum.Translation">
                {{'nip'|translate}}
                <!-- <app-tlumacz [id]="'NIP'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <input name="nip" type="text" class="form-control" [disabled]="isPreview" value="{{ClientVm.NIP}}"
                [(ngModel)]="ClientVm.NIP">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label id="nazwakontrahenta" [translation]="TranslationEnum.Translation">
                {{'nazwakontrahenta'|translate}}
                <!-- <app-tlumacz [id]="'NazwaKontrahenta'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <input name="name" type="text" class="form-control" [disabled]="isPreview" value="{{ClientVm.Name}}"
                [(ngModel)]="ClientVm.Name" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label id="miasto" [translation]="TranslationEnum.Translation">
                {{'miasto'|translate}}
                <!-- <app-tlumacz [id]="'Miasto'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <input name="city" type="text" class="form-control" [disabled]="isPreview" value="{{ClientVm.City}}"
                [(ngModel)]="ClientVm.City">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label id="adres" [translation]="TranslationEnum.Translation">
                {{'adres'|translate}}
                <!-- <app-tlumacz [id]="'Adres'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <input name="adress" class="form-control" [disabled]="isPreview" value="{{ClientVm.Address}}"
                [(ngModel)]="ClientVm.Address">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label id="kodpocztowy" [translation]="TranslationEnum.Translation">
                {{'kodpocztowy'|translate}}
                <!-- <app-tlumacz [id]="'KodPocztowy'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <input name="postCode" class="form-control" [disabled]="isPreview" value="{{ClientVm.ZipCode}}"
                [(ngModel)]="ClientVm.ZipCode">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label id="kraj" [translation]="TranslationEnum.Translation">
                {{'kraj'|translate}}
                <!-- <app-tlumacz [id]="'Kraj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <select type="select" class="form-control">
                <ng-container *ngFor="let country of CountryList">
                  <option value="product.SystemName" (click)="ClientVm.Country=country.Id"
                    [selected]="country.Id === ClientVm.Country">{{country.Name}}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>

        <div class="modalTable">
          <div class="table-scroll">
            <table>
              <tr class="naglowek">
                <td id="systemnazwa" [translation]="TranslationEnum.Translation">
                  {{'systemnazwa'|translate}}
                  <!-- <app-tlumacz [id]="'SystemNazwa'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </td>
                <td id="rabatsys" [translation]="TranslationEnum.Translation">
                  {{'rabatsys'|translate}}
                  <!-- <app-tlumacz [id]="'RabatSys'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </td>
                <td id="rabatel" [translation]="TranslationEnum.Translation">
                  {{'rabatel'|translate}}
                  <!-- <app-tlumacz [id]="'RabatEl'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </td>
                <td id="rabatakce" [translation]="TranslationEnum.Translation">
                  {{'rabatakce'|translate}}
                  <!-- <app-tlumacz [id]="'RabatAkce'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </td>
                <td id="usun" [translation]="TranslationEnum.Translation">
                  {{'usun'|translate}}
                  <!-- <app-tlumacz [id]="'Usun'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </td>
              </tr>
              <tr class="pozycja" *ngFor="let rabate of ClientVm.ContractorClientProfitMargins">
                <td class="lewa_pozycja">
                  <div class="ramka_td"><span>{{rabate.SystemDisplay}}</span></div>
                </td>
                <td>
                  <div class="ramka_td">{{rabate.SystemDiscount}}</div>
                </td>
                <td>
                  <div class="ramka_td">{{rabate.ElectricityDiscount}}</div>
                </td>
                <td>
                  <div class="ramka_td">{{rabate.AccessoryDiscount}}</div>
                </td>
                <td class="prawa_pozycja">
                  <div class="ramka_td"><span class="ikona usun" (click)="deleteRabate(rabate);"></span></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="dodajModal" *ngIf="!isPreview">
          <span id="dodajrabat" class="dodajBtn" [translation]="TranslationEnum.Translation" (click)="addRabateClick = true;">
            {{'dodajrabat'|translate}}
            <!-- <app-tlumacz [id]="'DodajRabat'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </span>
        </div>
        <div class="dodajModal" *ngIf="!isPreview">
          <span id="zapisz" class="dodajBtn" [translation]="TranslationEnum.Translation" type="submit"
            (click)="addOrUpdate(ClientVm)">
            {{'zapisz'|translate}}
            <!-- <app-tlumacz [id]="'Zapisz'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </span>
        </div>
        <div class="dodajModal">
          <span id="zamknij" class="dodajBtn" [translation]="TranslationEnum.Translation"
            (click)="modal.dismiss('Cancel'); addRabateClick = false;">
            {{'zamknij'|translate}}
            <!-- <app-tlumacz [id]="'Zamknij'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </span>
        </div>
      </div>
    </form>
    <div class="addRabate" *ngIf="addRabateClick">
      <form #rabateForm="ngForm" (ngSubmit)="addOrUpdate(rabateForm)">
        <div class="row">
          <div class="addRabateHeader">
            <h4 id="dodajrabat" class="addRabateTitle" [translation]="TranslationEnum.Translation">
              {{'dodajrabat'|translate}}
              <!-- <app-tlumacz [id]="'DodajRabat'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label id="system" [translation]="TranslationEnum.Translation">
                {{'system'|translate}}
                <!-- <app-tlumacz [id]="'System'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <select name="system" type="select" class="form-control" value="{{ContractorClientRabatesVm.SystemName}}"
                [(ngModel)]="ContractorClientRabatesVm.SystemName">
                <ng-container *ngFor="let product of tresci.ProductVmList">
                  <option value="{{product.SystemName}}">{{product.DisplayName}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label id="rabatsys" [translation]="TranslationEnum.Translation">
                {{'rabatsys'|translate}}
                <!-- <app-tlumacz [id]="'RabatSys'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <input type="number" name="SystemDiscount" class="form-control" min="0"
                value="{{ContractorClientRabatesVm.SystemDiscount}}"
                [(ngModel)]="ContractorClientRabatesVm.SystemDiscount">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label id="rabatel" [translation]="TranslationEnum.Translation">
                {{'rabatel'|translate}}
                <!-- <app-tlumacz [id]="'RabatEl'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <input type="number" name="ElectricityDiscount" class="form-control" min="0"
                value="{{ContractorClientRabatesVm.ElectricityDiscount}}"
                [(ngModel)]="ContractorClientRabatesVm.ElectricityDiscount">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label id="rabatakce" [translation]="TranslationEnum.Translation">
                {{'rabatakce'|translate}}
                <!-- <app-tlumacz [id]="'RabatAkce'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </label>
              <input type="number" name="AccessoryDiscount" class="form-control" min="0"
                value="{{ContractorClientRabatesVm.AccessoryDiscount}}"
                [(ngModel)]="ContractorClientRabatesVm.AccessoryDiscount">
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="addRabateFooter" *ngIf="addRabateClick">
      <div class="dodajModal">
        <span id="dodaj" class="dodajBtn" [translation]="TranslationEnum.Translation"
          (click)="addRabateToList(ContractorClientRabatesVm)">
          {{'dodaj'|translate}}
          <!-- <app-tlumacz [id]="'Dodaj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </span>
      </div>
    </div>
  </ng-template>
