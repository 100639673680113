import { Component, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-tree-technology-list',
  templateUrl: './tree-technology-list.component.html',
  styleUrls: ['./tree-technology-list.component.scss'],
})
export class TreeTechnologyListComponent implements OnInit {
  @Input() ResultInfo: any;
  @Input() ParamsOnWeb: any;
  public FilterColumns: string[];
  public FilterColumn: any;
  private objectWithKeys: any;
  private filterTerm: string;
  private tableBeforeFilter: any[];

  constructor() {
    this.FilterColumns = new Array<string>();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (this.ResultInfo !== undefined){
      this.objectWithKeys = this.ResultInfo[0];
      this.tableBeforeFilter = Array.from(this.ResultInfo);
      for (const key of Object.keys(this.objectWithKeys)) {
        if (key.toLowerCase() !== 'pozycja') {
          this.FilterColumns.push(key);
        }
      }
      this.FilterColumn = this.FilterColumns[0];
    }
  }

  applyFilter(event: any): void {
    this.filterTerm = event.value;
    if (event.value !== '') {
      this.ResultInfo = this.ResultInfo.filter((x) => x[this.FilterColumn].toLowerCase().includes(this.filterTerm.toLowerCase()));
    } else {
      this.ResultInfo = this.tableBeforeFilter;
    }
  }

  applyFilterParamsOnWeb(event: any): void {

    this.ParamsOnWeb.includes(event.value.toLowerCase());
  }
}
