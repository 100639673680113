import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({
  name: 'styleP'
})
export class StylePPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(html: any, styleSelector: any, styleValue: any): SafeHtml {
    const style = ` style = "${styleSelector}: ${styleValue};"`;
    let indexPosition = html.indexOf('<P>');
    let newHtml = html;
    if (indexPosition !== -1) {
      indexPosition++;
      indexPosition++;
      newHtml = [html.slice(0, indexPosition), style, html.slice(indexPosition)].join('');
      while (indexPosition !== -1) {
        indexPosition = newHtml.indexOf('<P>', indexPosition);
        if (indexPosition !== -1) {
          indexPosition++;
          indexPosition++;
          newHtml = [newHtml.slice(0, indexPosition), style, newHtml.slice(indexPosition)].join('');
        }
      }
    }
    return this.sanitizer.bypassSecurityTrustHtml(newHtml);
  }

}
