export const daneStron = [
  // {
  //   'id': '500',
  //   'path': 'Home',
  //   'path2': 'Home',
  //   'data': 'Home',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '520',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': true
  // },
  // {
  //   'id': '1',
  //   'path': 'Orders',
  //   'path2': 'Orders',
  //   'data': 'Zamówienia',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '0',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': true
  // },
  {
    'id': '20502',
    'path': 'Archives',
    'path2': 'Archives',
    'data': 'Archiwum',
    'podmenu': false,
    'klik': true,
    'parent': '520',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': false,
    'WidocznePartner': true
  },
  {
    'id': '2',
    'path': 'Kategorie',
    'path2': 'Kategorie',
    'data': 'zamów',
    'podmenu': true,
    'klik': true,
    'parent': '0',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': false,
    'WidocznePartner': true
  },
  // {
  //   'id': '3',
  //   'path': 'Logowanie',
  //   'path2': 'Login',
  //   'data': 'Logowanie',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '0',
  //   'widoczny': true,
  //   'zalogowany': false,
  //   'WidocznePartner': true
  // },
  {
    'id': '201',
    'path': 'Add',
    'path2': 'Markiza',
    'data': 'ma',
    'podmenu': false,
    'klik': true,
    'parent': '2',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': false,
    'WidocznePartner': true,
    'Handlowiec': false
  },
  {
    'id': '201',
    'path': 'Add',
    'path2': 'MarkizaDetale',
    'data': 'mad',
    'podmenu': false,
    'klik': true,
    'parent': '2',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': false,
    'WidocznePartner': false,
    'Handlowiec': true
  },
  {
    'id': '202',
    'path': 'Add',
    'path2': 'Pergola',
    'data': 'pg',
    'podmenu': false,
    'klik': true,
    'parent': '2', // '2',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': true,
    'WidocznePartner': false,
    'Handlowiec': true
  },
  {
    'id': '202',
    'path': 'Add',
    'path2': 'PergolaV',
    'data': 'mp',
    'podmenu': false,
    'klik': true,
    'parent': '2',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': false,
    'WidocznePartner': false,
    'Handlowiec': true
  },

  {
    'id': '203',
    'path': 'Add',
    'path2': 'Fasadowe',
    'data': 'fs',
    'podmenu': false,
    'klik': true,
    'parent': '2',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': false,
    'WidocznePartner': false,
    'Handlowiec': true
  },
  {
    'id': '208',
    'path': 'Add',
    'path2': 'FasadoweDetale',
    'data': 'fsd',
    'podmenu': false,
    'klik': true,
    'parent': '2',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': false,
    'WidocznePartner': false,
    'Handlowiec': true
  },
  {
    'id': '204',
    'path': 'Add',
    'path2': 'Refleksol',
    'data': 'fr',
    'podmenu': false,
    'klik': true,
    'parent': '2',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': true,
    'WidocznePartner': false,
    'Handlowiec': true
  },
  // {
  //   'id': '205',
  //   'path': 'Add',
  //   'path2': 'Sunbreaker',
  //   'data': 'Sunbreaker',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '2',
  //   'widoczny': false,
  //   'zalogowany': false,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false,
  //   'Handlowiec': true
  // },
  {
    'id': '206',
    'path': 'Add',
    'path2': 'GlassSystem',
    'data': 'wgs',
    'podmenu': false,
    'klik': true,
    'parent': '2', // '2',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': false,
    'WidocznePartner': false,
    'Handlowiec': true
  },
  {
    'id': '20401',
    'path': 'Add',
    'path2': 'Veranda',
    'data': 'mv',
    'podmenu': false,
    'klik': true,
    'parent': '2',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': false,
    'WidocznePartner': false,
    'Handlowiec': true
  },
  {
    'id': '210',
    'path': 'Add',
    'path2': 'Test',
    'data': 'test',
    'podmenu': false,
    'klik': true,
    'parent': '2',
    'widoczny': true,
    'zalogowany': true,
    'WersjaRozszerzona': false,
    'WidocznePartner': false,
    'Handlowiec': true
  },
    // {
  //   'id': '20502',
  //   'path': 'Add',
  //   'path2': 'PergolaVDetale',
  //   'data': 'PergolaV Detale',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '2',
  //   'widoczny': false,
  //   'zalogowany': false,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false,
  //   'Handlowiec': true
  // },
  // {
  //   'id': '20201',
  //   'path': 'Add',
  //   'path2': 'Pergola',
  //   'data': 'SOLID',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '202',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20202',
  //   'path': 'Add',
  //   'path2': 'Pergola',
  //   'data': 'SB400',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '202',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20203',
  //   'path': 'Add',
  //   'path2': 'Pergola',
  //   'data': 'SB500',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '202',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20204',
  //   'path': 'Add',
  //   'path2': 'Pergola',
  //   'data': 'PergolaV',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '202',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20101',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'Italia',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20102',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'ItaliaP',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20103',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'ItaliaK',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20104',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'Malta',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20105',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'SilverPlus',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20106',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'Australia',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20107',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'Jamaica',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20108',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'Palladio',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20109',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'Dakar',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20110',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'Bora',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20111',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'Casablanca',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20112',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'Corsica',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20113',
  //   'path': 'Add',
  //   'path2': 'Markiza',
  //   'data': 'Giant',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '201',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20301',
  //   'path': 'Add',
  //   'path2': 'Fasadowe',
  //   'data': 'C50',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '203',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20302',
  //   'path': 'Add',
  //   'path2': 'Fasadowe',
  //   'data': 'C50SLIM',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '203',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20303',
  //   'path': 'Add',
  //   'path2': 'Fasadowe',
  //   'data': 'C80',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '203',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20306',
  //   'path': 'Add',
  //   'path2': 'Fasadowe',
  //   'data': 'C80BOX',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '203',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20307',
  //   'path': 'Add',
  //   'path2': 'Fasadowe',
  //   'data': 'Z90',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '203',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20309',
  //   'path': 'Add',
  //   'path2': 'Fasadowe',
  //   'data': 'Z90HF',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '203',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20310',
  //   'path': 'Add',
  //   'path2': 'Fasadowe',
  //   'data': 'Z90BOX',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '203',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'R103',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'R120',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'R125',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'R76',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'R85',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'R90',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'MR120',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },

  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'VerandaDetale',
  //   'data': 'VerandaDetale',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '2',
  //   'widoczny': false,
  //   'zalogowany': false,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false,
  //   'Handlowiec': true
  // },
  // {
  //   'id': '207',
  //   'path': 'Add',
  //   'path2': 'Handlowy',
  //   'data': 'Handlowy',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '2', // '2',
  //   'widoczny': false,
  //   'zalogowany': false,
  //   'WersjaRozszerzona': false,
  //   'WidocznePartner': false,
  //   'Handlowiec': true
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'RXL',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'RXS',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'RXXL',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'ZiiiP120',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'ZiiiP95',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20401',
  //   'path': 'Add',
  //   'path2': 'Refleksol',
  //   'data': 'ZiiiPBOX',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '204',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20501',
  //   'path': 'Add',
  //   'path2': 'Sunbreaker',
  //   'data': 'S210',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '205',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },
  // {
  //   'id': '20501',
  //   'path': 'Add',
  //   'path2': 'Sunbreaker',
  //   'data': 'S300',
  //   'podmenu': false,
  //   'klik': true,
  //   'parent': '205',
  //   'widoczny': true,
  //   'zalogowany': true,
  //   'WersjaRozszerzona': true,
  //   'WidocznePartner': false
  // },


];
