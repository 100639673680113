import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()

export class IsLoggedGuard implements CanActivate {
  constructor(private router: Router, private cookieService: CookieService) { }

  canActivate(): boolean {
    if (this.cookieService.check('_token')) {
      this.router.navigate(['Home']);
      return false;
    } else {
      return true;
    }
  };

}
