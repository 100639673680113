import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { URLLoader } from './url-loader';
import { VisualizationDialogComponent } from './visualization-dialog/visualization-dialog.component';

@Component({
  selector: 'app-visualization',
  templateUrl: './visualization.component.html',
  styleUrls: ['./visualization.component.scss'],
})
export class VisualizationComponent implements OnInit, AfterViewInit, OnDestroy {
  public Control: HtmlControl;
  public Group: FormGroup;
  private a;
  private subscriptions: Subscription[] = [];

  constructor(public dialog: MatDialog) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // this.formControlsService.getApiVisual().subscribe(x => {
    //   // console.log('x', x)
    // });
    // setTimeout(() => {
    //   // console.log('success', success, this.a);
    // }, 500);

    // playerDOM.variantic.player.layoutData;
  }

  openVisualizationDialog() {
    // console.log('Control', this.Control)
    if (this.Group.get(this.Control.name)?.enabled) {
      const dialogRef = this.dialog.open(VisualizationDialogComponent, { minWidth: '80vh', data: { FromGroupForVisualization: this.Group } });
      this.subscriptions.push(dialogRef.afterClosed().subscribe());
    }
  }
}
