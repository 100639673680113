import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Delivery } from 'src/app/shared/models/order-register/delivery-list/delivery.model';
import { OrderRegisterService } from '../../order-register.service';

interface DialogDeliveryData {
  tableData: Delivery[];
  wdtPositions: any[];
}


@Component({
  selector: 'app-delivery-list-new-dialog',
  templateUrl: './delivery-list-new-dialog.component.html',
  styleUrls: ['./delivery-list-new-dialog.component.scss'],
})
export class DeliveryListNewDialogComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  public TranslationEnum = TranslationEnum;
  private subscriptions: Subscription[] = [];

  displayedColumns: string[] = ['InvoiceNumber', 'WdtNumber', 'OrdersNumber', 'DeliveryDate', 'ValueNettoBrutto', 'Confirmed', 'ShortDeliveryAddress', 'Expand'];

  expandDisplayedColumns: string[] = ['OrderWdt', 'Sheet', 'PackageDelivered', 'PackageDeliveredDate'];

  expandedElement: Delivery[] | null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDeliveryData, private orderRegisterService: OrderRegisterService) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit(): void {
    this.subscriptions.push(this.orderRegisterService.getWdtPositions(this.data.tableData).subscribe((x: Delivery[]) => {
      this.data.wdtPositions = [];

      for (const delivery of x) {
        this.data.wdtPositions = this.data.wdtPositions.concat(delivery.WdtPosList);
      }
    }));
  }

  elementToExpandClicked(element: Delivery) {
    if (this.expandedElement?.includes(element) === true) {
      this.expandedElement = this.expandedElement.filter((x) => {
        return x !== element;
      });
      // console.log('expandedElement', this.expandedElement);
    } else {
      this.expandedElement.push(element);
    }
  }

  confirmWdt() {
    // this.subscriptions.push(this.orderRegisterService.confirmWdt(this.data.tableData).subscribe());
  }
}


