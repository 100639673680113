<h2 mat-dialog-title>
  Wyniki technologii {{this.TechName}}
  <button mat-icon-button color="primary" class="float-right mr-3" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content>
  <mat-progress-spinner *ngIf="IsLoading" class="mr-auto ml-auto" color="primary" mode="indeterminate"> </mat-progress-spinner>
  <div class="d-flex">
    <button mat-icon-button *ngIf="!IsLoading" [attr.aria-label]="'Expand all'" (click)="expandAll()">
      <mat-icon>{{ IsExpandAll ? 'remove_circle_outline' : 'add_circle_outline' }}</mat-icon>
    </button>
    <mat-tree [dataSource]="DataSource" [treeControl]="TreeControl" class="example-tree overflow-auto h-100" style="width: 30%">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li class="mat-tree-node">
          <button mat-icon-button disabled></button>
          <button class="btn btn-sm" (click)="selectNode(node)">{{ node.name }} ({{ node.elapsedTime }} ms)</button>
        </li>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li>
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{ TreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </button>
            <span style="cursor: pointer" (click)="selectNode(node)">{{ node.name }} ({{ node.elapsedTime }} ms)</span>
            <span *ngIf="(node.scriptKind === 'Technology' && node.elapsedTime > 500) || (node.scriptKind !== 'Technology' && node.elapsedTime > 30)" class="icon"></span>
          </div>
          <ul [class.example-tree-invisible]="!TreeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
    <div style="width: 70%" class="overflow-auto">
      <mat-tab-group *ngIf="!IsLoading" class="ml-auto">
        <mat-tab label="Błędy" *ngIf="ResultInfo.KompilacjaBledy"><app-tree-technology-list [ParamsOnWeb]="ResultInfo.KompilacjaBledy"></app-tree-technology-list></mat-tab>
        <mat-tab label="Wyniki" *ngIf="ResultInfo.WynikiExist"><app-tree-technology-list [ResultInfo]="ResultInfo.WynikiTab"></app-tree-technology-list></mat-tab>
        <mat-tab label="Parametry" *ngIf="ResultInfo.Parametry"><app-tree-technology-list [ParamsOnWeb]="ResultInfo.Parametry"></app-tree-technology-list></mat-tab>
        <mat-tab label="Parametry wyliczeniowe" *ngIf="ResultInfo.ParametryWyliczeniowe"
          ><app-tree-technology-list [ParamsOnWeb]="ResultInfo.ParametryWyliczeniowe"></app-tree-technology-list
        ></mat-tab>
        <mat-tab label="Parametry z programu" *ngIf="ResultInfo.ParametryZProgramu"><app-tree-technology-list [ParamsOnWeb]="ResultInfo.ParametryZProgramu"></app-tree-technology-list></mat-tab>
        <mat-tab label="Parametry tabelaryczne" *ngIf="ResultInfo.ParametryTabelaryczne !== undefined && ResultInfo.ParametryTabelaryczne.length > 0"
          ><app-tree-technology-list [ParamsOnWeb]="ResultInfo.ParametryTabelaryczne"></app-tree-technology-list
        ></mat-tab>
      </mat-tab-group>
    </div>
  </div>
</mat-dialog-content>
