
<div class="menu">

<mat-tab-group *ngIf="!add" (selectedTabChange)="zmienKarte($event)" animationDuration="0ms" class="d-inline">
    <mat-tab>
        <ng-template mat-tab-label>
          <span id="reklamacje_b" [translation]="TranslationEnum.Translation">
            {{'reklamacje_b'|translate}}
          </span>
        </ng-template>
     
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
          <span id="konsultacje_b" [translation]="TranslationEnum.Translation">
            {{'konsultacje_b'|translate}}
          </span>
        </ng-template>
     
    </mat-tab>
  </mat-tab-group>
</div>
<div *ngIf="pokaz_kons" class="pole_box">
    <div class="wiadomsci">
        <ng-container  *ngFor="let kon of konsultacje; let i = index;">
            <div class="komentarz_nad komentarz_nad_{{kon.Login == User.Username}}" *ngIf="kon.ParentId">
                
                <span id = "komentarz_do2" [translation]="TranslationEnum.Translation">{{'komentarz_do2'|translate}}</span>
                <ng-container  *ngFor="let kom of konsultacje; let j = index;">
                    <div class="komentaz_do_box2" *ngIf="kom.Id == kon.ParentId">{{kom.Login}} {{kom.Date | date:'yyyy-MM-dd H:mm:ss'}} "{{kom.Context}}"</div>
                </ng-container>
            </div>
            <div class="wiadomosc od_{{kon.Login == User.Username}}" >
                
                <div class="podpis">{{kon.Login}} {{kon.Date | date:'yyyy-MM-dd H:mm:ss'}}</div>
                <div class="context" *ngIf="!kon.AttachmentId" (click) = "dodajKomentarz(kon.Id)">{{kon.Context}}</div>
                <div class="contextplik" *ngIf="kon.AttachmentId"  (click) = "pokazPLikKonsultacje(kon.AttachmentId)">{{kon.Context}}</div>
                
            </div>
        </ng-container>
        
    </div>
    <div class="pole_wys">
        <div class="komentarz" *ngIf="nowa_konsultacja.ParentId && nowa_konsultacja.ParentId != ''"  (click) = "dodajKomentarz(null)">
            <span id = "komentarz_do" [translation]="TranslationEnum.Translation">{{'komentarz_do'|translate}}</span>
            <ng-container  *ngFor="let kon of konsultacje; let i = index;">
                <div class="komentaz_do_box" *ngIf="kon.Id == nowa_konsultacja.ParentId">{{kon.Login}} {{kon.Date | date:'yyyy-MM-dd H:mm:ss'}} "{{kon.Context}}"</div>
            </ng-container>
            <span class="zamknij_komentarz">X</span>
        </div>
        <div class="podzielona_wys"> 
            <mat-form-field  appearance="fill" >
                <input type="text" matInput [value] = "nowa_konsultacja.Context" (change)="ZmienKons($event)"> 
            </mat-form-field>
            
            <form #filekons>
                <div class="button" id="dodajplik" (click) = "NacisnijDodajPlik2()" [translation]="TranslationEnum.Translation">{{'dodajplik'|translate}}</div>
                <input id = "fileformInput2" type="file" class="file-input"  (change)="onFileSelected2($event)" #fileUpload>
            </form>
                        
            <div class="button"  (click) = "WyslijKonsultacje()" id = "wyslij_wiadomosc" [translation]="TranslationEnum.Translation">{{'wyslij_wiadomosc'|translate}}</div>
        </div>
        
    </div>
    <div class="rek-tresc email_dodanie" *ngIf="complaint.ContactMail || add">
                    
        <div class="podnaglowek"><span id = "contactmail" [translation]="TranslationEnum.Translation">{{'contactmail'|translate}}</span><span class="nadicon"><mat-icon>info_outline</mat-icon><div class="pole_info" id = "contactmailopis" [translation]="TranslationEnum.Translation">{{'contactmailopis'|translate}}</div></span></div>
        

       
        <div class = "PoleEmail">
            <div *ngFor="let email of adresy_emial; let i = index;" class = "emiale">
                {{email}} <span class="usunEmial" (click) = "usunEmail(i)"  >X</span>
            </div>
        </div>
        <div class = "emiale_dod">
            <mat-form-field  appearance="fill" class="miganie3_{{complaint.ContactMail==''}}" >
                <input  type="email" width="200" (keyup.enter)= "dodajEmail()"  matInput [(ngModel)]="email_tmp" [value]="email_tmp" (change)="ZmienEmail($event)"  >
            </mat-form-field>
            

            <span class="dodajEmial" id = "dodajemial"  (click) = "dodajEmail()"  [translation]="TranslationEnum.Translation" >{{'dodajemial'|translate}}</span>
        </div>
         
    </div>
    
</div>
<div *ngIf="!pokaz_kons" class="pole_box">
    <h1  *ngIf="complaint"><span id = "reklamacje_numer" [translation]="TranslationEnum.Translation">{{'reklamacje_numer'|translate}}</span> {{complaint.ComplaintNumber}}</h1>
    <div class="loading-shade" *ngIf="IsLoadingResults || IsRateLimitReached">
        <mat-spinner *ngIf="IsLoadingResults"></mat-spinner>
        <div *ngIf="IsRateLimitReached" id="wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę"
          class="rate-limit-reached" [translation]="TranslationEnum.Translation">
          {{'wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę'|translate}}
          <!-- <app-tlumaczt [id]="'Wystąpił błąd połączenia. Proszę odświeżyć za minutę.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </div>
      </div>
      <div class="rek-nad" *ngIf="complaint">
            <div class="rek-box">
                <div id = "reklamacja_dotyczy" class="rek-ng" [translation]="TranslationEnum.Translation">{{'reklamacja_dotyczy'|translate}}</div>
                
                <div class="rek-tresc">
                    <div class="podnaglowek"><span id = "fakturazlecenie" [translation]="TranslationEnum.Translation">{{'fakturazlecenie'|translate}}</span><span class="nadicon"><mat-icon>info_outline</mat-icon><div class="pole_info" id = "fakturazlecenieopis" [translation]="TranslationEnum.Translation">{{'fakturazlecenieopis'|translate}}</div></span></div>
                    <div *ngIf="invoices">
                        <mat-select *ngIf="false" [(ngModel)]="invoice_wybrane" (selectionChange)="ZmienFakture($event)"  [readonly]="!add" class="miganie_{{invoice_wybrane==null}}">
                            <mat-option *ngFor="let invoice of invoices" [value]="invoice">
                            {{invoice.Date | date:'yyyy-MM-dd'}} - {{invoice.InvoiceNumber}} - {{invoice.ProductionNumber}} - {{invoice.B2BNumber}} - {{invoice.OrderDetails}}
                            </mat-option>
                        </mat-select>
                        <div class="JakSelect click{{add}} miganie_{{complaint.InvoiceNumber==null}}" (click) = "ZmienFaktureButton()" >
                            <span *ngIf="complaint.InvoiceNumber">{{complaint.Date | date:'yyyy-MM-dd'}} - {{complaint.InvoiceNumber}} - {{complaint.ProductionNumber}} - {{complaint.B2BNumber}} - {{complaint.OrderDetails}}</span>
                            <span *ngIf="!complaint.InvoiceNumber" id = "wybierzfakture" [translation]="TranslationEnum.Translation">{{'wybierzfakture'|translate}}</span>
                            
                        </div>
                       
                    </div>
                </div>
                <div class="rek-tresc" *ngIf="complaint.InvoiceNumber">
                    <div id = "nrfaktury" [translation]="TranslationEnum.Translation">{{'nrfaktury'|translate}}</div>
                    <div class="JakSelect">
                        {{complaint.InvoiceNumber}}
                    </div>
                </div>
                <div class="rek-tresc" *ngIf="complaint.ProductionNumber">
                    <div id = "zlecenienr" [translation]="TranslationEnum.Translation">{{'zlecenienr'|translate}}</div>
                    <div class="JakSelect">
                        {{complaint.ProductionNumber}}
                    </div>
                </div>
                <div class="rek-tresc" *ngIf="complaint.B2BNumber">
                    <div id = "numerb2b" [translation]="TranslationEnum.Translation">{{'numerb2b'|translate}}</div>
                    <div class="JakSelect">
                        {{complaint.B2BNumber}}
                    </div>
                </div>
                <div class="rek-tresc" *ngIf="complaint.OrderDetails">
                    <div id = "krotkiopis" [translation]="TranslationEnum.Translation">{{'krotkiopis'|translate}}</div>
                    <div class="JakSelect">
                        {{complaint.OrderDetails}}
                    </div>
                </div>
    
                
            </div>
            <div class="rek-box">
                <div id = "danekontaktowe" class="rek-ng" [translation]="TranslationEnum.Translation">{{'danekontaktowe'|translate}}</div>
                <div class="rek-tresc" *ngIf="complaint.ContactName">
                    <div id = "contactname" [translation]="TranslationEnum.Translation">{{'contactname'|translate}}</div>
                    <mat-form-field  appearance="fill" >
                        <input type="text"  (change)="ZmienName($event)" matInput [value]="complaint.ContactName" [readonly]="!add">
                    </mat-form-field>
                </div>
                <div class="rek-tresc" *ngIf="complaint.ContactMail || add">
                    
                    <div class="podnaglowek"><span id = "contactmail" [translation]="TranslationEnum.Translation">{{'contactmail'|translate}}</span><span class="nadicon"><mat-icon>info_outline</mat-icon><div class="pole_info" id = "contactmailopis" [translation]="TranslationEnum.Translation">{{'contactmailopis'|translate}}</div></span></div>
                    <mat-form-field  appearance="fill" *ngIf = "false" >
                        <input type="text" matInput [value]="complaint.ContactMail" [readonly]="!add">
                    </mat-form-field>
                    <div class = "PoleEmail">
                        <div *ngFor="let email of adresy_emial; let i = index;" class = "emiale">
                            {{email}} <span class="usunEmial" (click) = "usunEmail(i)"  >X</span>
                        </div>
                    </div>
                    <div class = "emiale_dod">
                        <mat-form-field  appearance="fill" class="miganie3_{{complaint.ContactMail==''}}" >
                            <input  type="email" (keyup.enter)= "dodajEmail()"  matInput [(ngModel)]="email_tmp" [value]="email_tmp" (change)="ZmienEmail($event)"  >
                        </mat-form-field>
                        
     
                        <span class="dodajEmial" id = "dodajemial" (click) = "dodajEmail()"  [translation]="TranslationEnum.Translation" >{{'dodajemial'|translate}}</span>
                    </div>
                     
                </div>
                <div class="rek-tresc" *ngIf="complaint">
                    <div id = "contactphone" [translation]="TranslationEnum.Translation">{{'contactphone'|translate}}</div>
                    <mat-form-field  appearance="fill" class="miganie3_{{complaint.ContactPhone == null || complaint.ContactPhone.trim() == ''}}" >
                        <input type="text"  matInput [value]="complaint.ContactPhone" (change)="ZmienPhone($event)" [readonly]="!add">
                    </mat-form-field>
                </div>
            </div>
            <div class="rek-box"> 
                <div id = "opisreklamacji" class="rek-ng" [translation]="TranslationEnum.Translation">{{'opisreklamacji'|translate}}</div>
                
                <div class="rek-tresc" *ngIf="complaint.ShortDescription || add">
                    <div class="podnaglowek"><span id = "shortdescription" [translation]="TranslationEnum.Translation">{{'shortdescription'|translate}}</span><span class="nadicon"><mat-icon>info_outline</mat-icon><div class="pole_info" id = "shortdescriptionopis" [translation]="TranslationEnum.Translation">{{'shortdescriptionopis'|translate}}</div></span></div>
                    <form>
                        <mat-form-field  appearance="fill" class="miganie3_{{complaint.ShortDescription==''}}">
                            <input type="text" matInput [value]="complaint.ShortDescription" (change)="ZmienShort($event)"    [readonly]="!add">
                        </mat-form-field>
                    </form>
                </div>
                <div class="rek-tresc" *ngIf="complaint.LongDescription || add">
                    <div class="podnaglowek"><span id = "longdescription" [translation]="TranslationEnum.Translation">{{'longdescription'|translate}}</span><span class="nadicon"><mat-icon>info_outline</mat-icon><div class="pole_info" id = "longdescriptionopis" [translation]="TranslationEnum.Translation">{{'longdescriptionopis'|translate}}</div></span></div>
                    <mat-form-field  appearance="fill"  *ngIf="complaint.LongDescription || add" class="miganie3_{{complaint.LongDescription==''}}">
                            
                        <textarea  matInput [value]="complaint.LongDescription" (change)="ZmienLong($event)" [readonly]="!add"></textarea>
                    </mat-form-field>
                </div>
                <div class="rek-tresc" >
                    <div class="podnaglowek"><span id = "positions" [translation]="TranslationEnum.Translation">{{'positions'|translate}}</span><span class="nadicon"><mat-icon>info_outline</mat-icon><div class="pole_info" id = "positionsopis" [translation]="TranslationEnum.Translation">{{'positionsopis'|translate}}</div></span></div>
                    <div id="addposition" *ngIf="add && positions" class="buttonmini miganie2_{{complaint.Positions.length == 0}}"  (click)="DodajPozycje();" [translation]="TranslationEnum.Translation">{{'addposition'|translate}}</div>
                    <div *ngFor="let poz of complaint.Positions; let i = index;"class = "JakSelect">{{poz.ReportName}}
                        &nbsp;<span id = "moduł_faktury" [translation]="TranslationEnum.Translation">{{'moduł_faktury'|translate}}</span>&nbsp;<span>{{poz.Module}}</span>
                        &nbsp;<span id = "pozycja_faktury" [translation]="TranslationEnum.Translation">{{'pozycja_faktury'|translate}}</span>&nbsp;<span>{{poz.Position}}</span>
                        &nbsp;<span id = "ilosc_na_fakturze" [translation]="TranslationEnum.Translation">{{'ilosc_na_fakturze'|translate}}</span>&nbsp;<span>{{poz.QuantityComplaint}}</span>
                        <span  *ngIf="add" class="usunplik" (click)="UsunPoz(i)">X</span>
                    </div>
                </div>
                <div class="rek-tresc" >
                    <div class="podnaglowek"><span id = "files" [translation]="TranslationEnum.Translation">{{'files'|translate}}</span><span class="nadicon"><mat-icon>info_outline</mat-icon><div class="pole_info" id = "filesopis" [translation]="TranslationEnum.Translation">{{'filesopis'|translate}}</div></span></div>
                    
                    <form #fileform *ngIf="add">
                        <div class="button" id="dodajplik" (click) = "NacisnijDodajPlik()" [translation]="TranslationEnum.Translation">{{'dodajplik'|translate}}</div>
                        <input id = "fileformInput" type="file" multiple class="file-input"  (change)="onFileSelected($event)" #fileUpload>
                    </form>
                    <div class="warrning_pliak" *ngIf="complaint.IsTrader && complaint.TraderOrder && complaint.Files.length == 0" id = "co_najmneij_jeden_plik" [translation]="TranslationEnum.Translation">{{'co_najmneij_jeden_plik'|translate}}</div>
                    <div class="plik_box" *ngFor="let poz of complaint.Files; let i = index;" >
                        <span class="plik" (click)="PokazPlik(poz)">{{poz.Name}}</span><span  *ngIf="poz.add_button == true" class="usunplik" (click)="UsunPlik(i)">X</span>
                    </div>
                    <div class="button" id="dodaj_pliki" *ngIf="pokaz_button_dodaj" [translation]="TranslationEnum.Translation" (click) = "DodajPliki();">
                        {{'dodaj_pliki'|translate}}
                    </div>
                    
                </div>
            </div>
            <div class="rek-box">
                <div id = "roszczenie" class="rek-ng" [translation]="TranslationEnum.Translation">{{'roszczenie'|translate}}</div>
                
                <div class="rek-tresc" *ngIf="claims">
                    <div class="podnaglowek"><span id = "claimname" [translation]="TranslationEnum.Translation">{{'claimname'|translate}}</span><span class="nadicon"><mat-icon>info_outline</mat-icon><div class="pole_info" id = "claimnameopis" [translation]="TranslationEnum.Translation">{{'claimnameopis'|translate}}</div></span></div>
                    
                    <div *ngIf="claims">
                        <mat-select [(ngModel)]="complaint.ClaimId" (selectionChange)="ZmienRoszczenie($event)"  [readonly]="!add"class="miganie_{{complaint.ClaimId==null}}">
                            <mat-option *ngFor="let cl of claims" [value]="cl.Id">
                               {{cl.Name}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div *ngIf="claims && complaint.ClaimIsTextEnabled" id = "claimopis" [translation]="TranslationEnum.Translation">{{'claimopis'|translate}}</div>
                    <div *ngIf="claims">
                        <mat-form-field  appearance="fill"  *ngIf="complaint.ClaimIsTextEnabled">
                            
                            <textarea  matInput [value]="complaint.ClaimContent"  (change)="ZmienClaim($event)" [readonly]="!add"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="notka">
                    <div class="ng_notka" id = "ngnotka" [translation]="TranslationEnum.Translation">{{'ngnotka'|translate}}</div>
                    <div class="tresc_notka" id = "trescnotka" [translation]="TranslationEnum.Translation">{{'trescnotka'|translate}}</div>
                </div>
            </div>
            <div class="button" *ngIf = "add" id="zapiszrap"  [translation]="TranslationEnum.Translation" (click) = "ZapiszRap();">
                {{'zapiszrap'|translate}}
            </div>
      </div>
      

      <div class="pliki_box" *ngIf="complaint && complaint.IsPrintLabelEnable"> 
        <div id = "pliki_do_pobrania" class="rek-ng" [translation]="TranslationEnum.Translation">{{'pliki_do_pobrania'|translate}}</div>
            <div class="button" id="listaprzewozowa"  [translation]="TranslationEnum.Translation" (click) = "PobierzListePrzew();">
                {{'listaprzewozowa'|translate}}
            </div>
        <div class="pliki_ilosc">
            
            <div class="button" id="etykiety"  [translation]="TranslationEnum.Translation" (click) = "PobierzEtykiety();">
                {{'etykiety'|translate}}
            </div>
            <div class="ilosc_box">
                <span id="ile_etykiet" [translation]="TranslationEnum.Translation">{{'ile_etykiet'|translate}}</span>
                <form>
                    <mat-form-field  appearance="fill">
                        <input type="number" matInput [value]="ilosc_etykiet" (change)="ZmienIloscEtykiet($event)" >
                    </mat-form-field>
                </form>
            </div>
           
        </div>
       
      </div>

      <div class="decyzja_box" *ngIf="complaint && complaint.DecisionName && complaint.DecisionName != ''"> 
        <div id = "decision" class="rek-ng" [translation]="TranslationEnum.Translation">{{'decision'|translate}}</div>
        <div class="naglowek_decyzji">
            {{complaint.DecisionName}}
        </div>
        <div class="tresc_decyzji">
            {{complaint.DecisionContext}}
        </div>
      </div>

      <div class="popup" *ngIf="dodaje_poz || dodaje_rap || dodaje_fak" (click) = "zamknijOkno()">
        
    
        <div class="pole_popup" (click) = "nieZamykajOkna($event)">
            <div *ngIf="add && dodaje_fak" >
                <div class="pole_Szukaj">
                    <span id = "filter"  [translation]="TranslationEnum.Translation">{{'filter'|translate}}</span>
                    <mat-form-field appearance="standard">
                        <input matInput (keyup)="applyFilter($event)" #input>
                    </mat-form-field>
                </div>
            </div>
            <div class="pole_popup2">
            <div *ngIf="raports && dodaje_rap">
               
                    <mat-select [(ngModel)]="dodawany_raport[0].Id"  class="miganie_{{dodawany_raport[0].Id==null}}" (selectionChange)="ZmienRaport($event,1)">
                        <ng-container *ngFor="let rap of raports" >
                            <mat-option *ngIf="rap.ParentId==null" [value]="rap.Id">
                            {{rap.Name}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                    <div *ngIf="dodawany_raport[0].Id && dodawana_pozycja.MaxPoz > 1">
                        <mat-select [(ngModel)]="dodawany_raport[1].Id" (selectionChange)="ZmienRaport($event, 2)" class="miganie_{{dodawany_raport[1].Id==null}}">
                            <ng-container *ngFor="let rap of raports" >
                                <mat-option *ngIf="rap.ParentId==dodawany_raport[0].Id" [value]="rap.Id">
                                {{rap.Name}} 
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </div>
                    <div *ngIf="dodawany_raport[1].Id && dodawana_pozycja.MaxPoz > 2">
                        <mat-select [(ngModel)]="dodawany_raport[2].Id" (selectionChange)="ZmienRaport($event, 3)" class="miganie_{{dodawany_raport[2].Id==null}}">
                            <ng-container *ngFor="let rap of raports" >
                                <mat-option *ngIf="rap.ParentId==dodawany_raport[1].Id" [value]="rap.Id">
                                {{rap.Name}} 
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </div>
                    <div class="button" id="dodajrap" *ngIf="dodawana_pozycja && this.dodawana_pozycja.ReportName" [translation]="TranslationEnum.Translation" (click) = "DodajRaport();">
                        {{'dodajrap'|translate}}
                    </div>
            </div>
            <div *ngIf="positions && dodaje_poz">
                <table mat-table [dataSource]="positions">
                    <ng-container matColumnDef="Id">
                        <th mat-header-cell *matHeaderCellDef> <span id="id_lp" [translation]="TranslationEnum.Translation">{{'id_lp'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="System">
                        <th mat-header-cell *matHeaderCellDef> <span id="system" [translation]="TranslationEnum.Translation">{{'system'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.System}} </td>
                    </ng-container>
                    <ng-container matColumnDef="OrderLp">
                        <th mat-header-cell *matHeaderCellDef> <span id="orderlp" [translation]="TranslationEnum.Translation">{{'orderlp'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.OrderLp}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ItemLp">
                        <th mat-header-cell *matHeaderCellDef> <span id="itemlp" [translation]="TranslationEnum.Translation">{{'itemlp'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.ItemLp}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ModuleLp">
                        <th mat-header-cell *matHeaderCellDef> <span id="modulelp" [translation]="TranslationEnum.Translation">{{'modulelp'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.ModuleLp}} </td>
                    </ng-container>
                    <ng-container matColumnDef="OrderNo">
                        <th mat-header-cell *matHeaderCellDef> <span id="orderno" [translation]="TranslationEnum.Translation">{{'orderno'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.OrderNo}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Poz">
                        <th mat-header-cell *matHeaderCellDef> <span id="numerpoz" [translation]="TranslationEnum.Translation">{{'numerpoz'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.Poz}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Width">
                        <th mat-header-cell *matHeaderCellDef> <span id="width" [translation]="TranslationEnum.Translation">{{'width'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.Width}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Height">
                        <th mat-header-cell *matHeaderCellDef> <span id="height" [translation]="TranslationEnum.Translation">{{'height'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.Height}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ConstructionColor">
                        <th mat-header-cell *matHeaderCellDef> <span id="constructioncolor" [translation]="TranslationEnum.Translation">{{'constructioncolor'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.ConstructionColor}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Fabric">
                        <th mat-header-cell *matHeaderCellDef> <span id="fabric" [translation]="TranslationEnum.Translation">{{'fabric'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.Fabric}} </td>
                    </ng-container>
                    <ng-container matColumnDef="FabricName">
                        <th mat-header-cell *matHeaderCellDef> <span id="fabricname" [translation]="TranslationEnum.Translation">{{'fabricname'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.FabricName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Drive">
                        <th mat-header-cell *matHeaderCellDef> <span id="silnik" [translation]="TranslationEnum.Translation">{{'silnik'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.Drive}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Quantity">
                        <th mat-header-cell *matHeaderCellDef> <span id="quantity" [translation]="TranslationEnum.Translation">{{'quantity'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.Quantity}} </td>
                    </ng-container>
                    <ng-container matColumnDef="QuantityComplaint">
                        <th mat-header-cell *matHeaderCellDef>
                            <span class="buttonmini" *ngIf="positions[0].QuantityComplaint == 0" id = "zaznacz_wszystko" (click) = "wszystkiePozycje()" [translation]="TranslationEnum.Translation"> {{'zaznacz_wszystko'|translate}}</span>
                            <span class="buttonmini" *ngIf="positions[0].QuantityComplaint > 0" id = "odznacz_wszystko" (click) = "zadnePozycje()" [translation]="TranslationEnum.Translation"> {{'odznacz_wszystko'|translate}}</span>
                            <br /><span id="quantitycomplaint" [translation]="TranslationEnum.Translation">{{'quantitycomplaint'|translate}}</span> 
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-form-field>
                                <input
                                  type="number"
                                  min="0" 
                                  max="{{element.Quantity}}" 
                                  class="form-control"
                                  matInput
                                  [(ngModel)]="element.QuantityComplaint"
                                  (change)="zmienIlosc(element, element.Quantity)">
                              </mat-form-field>
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="button" id="dodajpoz" [translation]="TranslationEnum.Translation" (click) = "ZakonczPozycje();">
                    {{'dodajpoz'|translate}}
                </div>
            </div>
            <div *ngIf="add && dodaje_fak" class="wybierzfak">
               
                <table mat-table [dataSource]="invoicesDataSource">
                    <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef> <span id="date" [translation]="TranslationEnum.Translation">{{'date'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element" (click) = 'ustawFak(element)'> {{element.Date | date:'yyyy-MM-dd'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="InvoiceNumber">
                        <th mat-header-cell *matHeaderCellDef> <span id="invoicenumber" [translation]="TranslationEnum.Translation">{{'invoicenumber'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element" (click) = 'ustawFak(element)'> {{element.InvoiceNumber}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ProductionNumber">
                        <th mat-header-cell *matHeaderCellDef> <span id="productionnumber" [translation]="TranslationEnum.Translation">{{'productionnumber'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element" (click) = 'ustawFak(element)'> {{element.ProductionNumber}} </td>
                    </ng-container>
                    <ng-container matColumnDef="B2BNumber">
                        <th mat-header-cell *matHeaderCellDef> <span id="b2bnumber" [translation]="TranslationEnum.Translation">{{'b2bnumber'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element" (click) = 'ustawFak(element)'> {{element.B2BNumber}} </td>
                    </ng-container>
                    <ng-container matColumnDef="OrderDetails">
                        <th mat-header-cell *matHeaderCellDef> <span id="orderdetails" [translation]="TranslationEnum.Translation">{{'orderdetails'|translate}}</span> </th>
                        <td mat-cell *matCellDef="let element" (click) = 'ustawFak(element)'> {{element.OrderDetails}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsInvoice"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsInvoice;"></tr>
                </table>
            </div>
        </div>
    </div>
       </div>
</div>







