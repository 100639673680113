<label>
  <span id="label_{{Control.label}}" [translation]="TranslationEnum.TranslationForm">
    {{('label_'+ Control.label)|translate}}
  </span>
  <app-information [label]="Control.label" [fileInfoId]="Control.fileInfoId"></app-information>
</label>
<div class="w-100">
  <mat-checkbox #checkbox [(ngModel)]="EnabledSelect" color="primary" class="d-inline mr-2"
    (change)="checkChanged(); select.value = '';" [disabled]="select.disabled"></mat-checkbox>

  <div [formGroup]="Group" class="d-inline">
    <mat-form-field appearance="outline" class="w-auto-100"
      [ngClass]="{'mat-select-disabled': !EnabledSelect , 'readonly': !EnabledSelect}">
      <mat-select #select [formControlName]="Control.name" class="d-inline" [value-object]="Control">
        <mat-option *ngFor="let item of Control.options" [disabled]="item.Disabled" [value]="item" class="border">
          {{item.DisplayValue}}
        </mat-option>
      </mat-select>

      <ng-container *ngFor="let validation of Control.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="Group.get(Control.name)?.hasError(validation.name) && EnabledSelect"
          id="{{validation.message}}" [translation]="TranslationEnum.TranslationForm">
          {{validation.message|translate}}
        </mat-error>
      </ng-container>
    </mat-form-field>
  </div>
</div>
