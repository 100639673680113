import { DeliveryAddress } from "src/app/shared/models/form/delivery-address.model";
import { DeliveryMethod } from "src/app/shared/models/form/delivery-method.model";
import { Complaint } from "src/app/shared/models/reklamacje/Complaint.model";
import { ComplaintType } from "./ComplaintType";



export class Delivery {
    
    AdressEdit:boolean = true;
    complaint_check:boolean = false;

    DeliveryMethod: DeliveryMethod = new DeliveryMethod();
    DeliveryMethods: DeliveryMethod[] = [];
    ContractorAddresses: DeliveryAddress[] = [];
    ChosenAdresses: DeliveryAddress ;
    complaints: Complaint[] = [];
    ChosenComplaint: Complaint;
    ComplaintType: ComplaintType;
    ComplaintTypes: ComplaintType[] = [];
    constructor(){

    }
}