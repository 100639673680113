<h2 mat-dialog-title id="tkanina" [translation]="TranslationEnum.TranslationForm">
  {{'tkanina'|translate}}
</h2>
<div mat-dialog-content>

  <mat-form-field appearance="outline" color="accent">
    <input matInput (keyup)="applyFilter($event.target.value)">
  </mat-form-field>

  <div class="example-container mat-elevation-z8">
    <div class="example-table-container">
      <table mat-table [dataSource]="dataSource" matSort class="row">
        <!-- Name Column -->
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row" class='m-card-title tkanina_wybrana{{row.IsEnableOnlyForTrader}}'>
            {{row.Value}} <br/>
            ({{row.Key}})
            <div class="tylko_handlowiec" id="tylko_handlowiec" *ngIf = "row.IsEnableOnlyForTrader">Tkanina nie jest dostępna dla klienta, prawdopodobnie brak tkaniny!</div>
          </td>
        </ng-container>

        <!-- Picture Column -->
        <ng-container matColumnDef="Picture">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Picture </th>
          <td mat-cell *matCellDef="let row" class='m-card-content tkaninaP_wybrana{{row.IsEnableOnlyForTrader}}'>
            <img mat-card-image src="{{tresci.server_url}}/api/ActiveForm/GetIcon?code={{row.Key}}" width="100%"
              onerror="this.onerror = null; this.src='../../../../../../assets/images/NoFabricFound.jpg'" loading="lazy">
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="col-2 m-3 d-inline-block" [mat-dialog-close]="row"></tr>
      </table>
    </div>
    <mat-paginator [length]="dataSource.data.length" [pageSize]="15" [pageSizeOptions]="[15, 30, 60, 120, 180]" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
