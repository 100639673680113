
export class ParametryWybrane {
  public Parametry: Parametr[] = [];
  constructor() {
  }
  public add(id, KeyName, Wartosc) {
    let byl_id = false;
    this.Parametry.forEach(Param => {
      if (Param.id === id) {
        byl_id = true;
        Param.add(KeyName, Wartosc);
      }
    });
    if (!byl_id) {
      this.Parametry.push(new Parametr(id));
      this.Parametry[this.Parametry.length - 1].add(KeyName, Wartosc);
    }
  }
  public get(id, KeyName) {
    let wartosc = null;
    this.Parametry.forEach(Param => {
      if (Param.id === id) {
        wartosc = Param.get(KeyName);
      }
    });
    return wartosc;
  }
}
export class Parametr {
  public KeyNames = [];
  public Wartosci = [];
  constructor(public id) {
  }
  public add(KeyName, Wartosc) {
    let bylo = false;
    for (let index = 0; index < this.KeyNames.length; index++) {
      if (this.KeyNames[index] === KeyName) {
        bylo = true;
        this.Wartosci[index] = Wartosc;
      }
    }
    if (!bylo) {
      this.KeyNames.push(KeyName);
      this.Wartosci.push(Wartosc);
    }
  }
  public get(KeyName) {
    let wartosc = null;
    for (let index = 0; index < this.KeyNames.length; index++) {
      if (this.KeyNames[index] === KeyName) {
        wartosc = this.Wartosci[index];
      }
    }
    return wartosc;
  }
}

