import { TresciService } from '../../../../tresci.service';
import { Component, ElementRef, OnInit, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { FabricDialogComponent } from '../fabric/fabric-dialog/fabric-dialog.component';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { take } from 'rxjs/operators';
import { FormService } from 'src/app/shared/service/form.service';



@Component({
  selector: 'app-checkfabric',
  templateUrl: './checkfabric.component.html',
  styleUrls: ['./checkfabric.component.scss']
})
export class CheckFabricComponent implements OnInit, AfterViewInit {

  public Control: HtmlControl;
  public Group: FormGroup;
  @ViewChild('input') input: ElementRef;
  public src: string;

  public TranslationEnum = TranslationEnum;
  public EnabledSelect: boolean = false;

  constructor(private formService: FormService, private fb: FormBuilder,public dialog: MatDialog, public tresci: TresciService) { }

  ngOnInit(): void {

    this.src = '';
    this.Group.get(this.Control.name).setValue(this.Control.value);

    this.Control.options.forEach(element => {
      if (element.Key === this.Control.value) {
        this.Control.value = element; 
        this.Group.get(this.Control.name).setValue(element);
        
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.Control.value === '') {
      this.Control.required = false; 
      this.Group.get(this.Control.name).setValue(this.Control.value);
      this.Group.get(this.Control.name).clearValidators();
        this.Control.value = '';
        this.Group.get(this.Control.name).setErrors(null);
        this.Group.get(this.Control.name).updateValueAndValidity();
    }
  }



  openFabricDialog() {
    if(this.EnabledSelect){
      const dialogRef = this.dialog.open(FabricDialogComponent, { data: this.Control.options, width: '1320px' });
      dialogRef.afterClosed().pipe(/*take(1)*/).subscribe(result => {
        if (result !== undefined) {
          this.Control.value = result;
          this.input.nativeElement.value = result.Value;
          const event = new Event('change');
          this.input.nativeElement.dispatchEvent(event);
          this.src = this.tresci.server_url + '/api/ActiveForm/GetIcon?code=' + result.Key;
        }
  
      });
    }
    
  }

  checkChanged(event:any): void {
    const control = this.formService.RemoveControlsFromGroup.find((x) => x.name === this.Control.name && x.module === this.Control.module && x.position === this.Control.position);
    let index: number;
    if (control !== null) {
      index = this.formService.RemoveControlsFromGroup.indexOf(control);
    }
    this.EnabledSelect = event.checked;
    // console.log("check", this.EnabledSelect, event.checked);
    if (this.EnabledSelect) {
      this.Control.required = true; 
      const controlSelect = this.fb.control(this.Control);
      this.Group.addControl(this.Control.name, controlSelect);

      this.Group.get(this.Control.name).setValidators(Validators.required);
      this.Group.controls[this.Control.name].setValue('');
      this.Group.get(this.Control.name).updateValueAndValidity();

      if (index !== null && index >= 0) {
        this.formService.RemoveControlsFromGroup.splice(index, 1);
      }
      
    } else if (!this.EnabledSelect) {
      // this.Group.get(this.Control.name).setValue('');
      this.Control.required = false; 
      this.Control.value = '';
      this.input.nativeElement.value = '';
      this.src = '';
      this.Group.get(this.Control.name).clearValidators();
      this.Group.get(this.Control.name).setErrors(null);
      this.Group.get(this.Control.name).updateValueAndValidity();
      if (index < 0) {
        this.formService.RemoveControlsFromGroup.push(this.Control);
      }
    }

  }

}
