import { OrderResponse } from '../../../../shared/models/orders/order-response/order-response.model';
import { OrderResponseHeader } from '../../../../shared/models/orders/order-response/order-response-header.model';
import { Component, Inject, } from '@angular/core';
import { TresciService } from 'src/app/tresci.service';
import { ModalForEnum } from 'src/app/shared/enums/modal-for-enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';



interface DialogData {
  data: OrderResponseHeader;
  modalForEnum: ModalForEnum;
}

@Component({
  selector: 'app-order-response-dialog',
  templateUrl: './order-response-dialog.component.html',
  styleUrls: ['./order-response-dialog.component.scss']
})
export class OrderResponseDialogComponent   {

  public TranslationEnum = TranslationEnum;
  public Culture = '';

  modalForEnum = ModalForEnum;
  dataSource: MatTableDataSource<OrderResponse> = new MatTableDataSource<OrderResponse>();

  displayedColumns: string[] = [
    'B2BId', 'Product', 'System', 'ValNetBrut', 'Ordered'
  ];

  constructor(public dialogRef: MatDialogRef<OrderResponseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: DialogData, public tresci: TresciService) {
    this.dataSource.data = dataDialog.data.OrderedStatusList;
    this.Culture = window['culture'];
  }


}
