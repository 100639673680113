import { Component, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { SelectMultiColumnDialogComponent } from './select-multi-column-dialog/select-multi-column-dialog.component';



@Component({
  selector: 'app-select-multi-column',
  templateUrl: './select-multi-column.component.html',
  styleUrls: ['./select-multi-column.component.scss']
})


export class SelectMultiColumnComponent implements AfterViewInit, OnDestroy {
  @ViewChild('input') input: ElementRef;

  public TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;
  private subscriptions: Subscription[] = [];

  constructor(public dialog: MatDialog) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngAfterViewInit(): void {
    if (this.Control.value === '') {
      this.Group.get(this.Control.name)?.setValue('');
    }
  }

  openSelectMultiColumnDialog(): void {
    if (this.Group.get(this.Control.name)?.enabled) {
      const dialogRef = this.dialog.open(SelectMultiColumnDialogComponent, { minWidth: '80vh', data: { ControlName: this.Control.label, Data: this.Control.optionsSelectMultiColumn } });
      this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.Control.value = result;
          
          this.input.nativeElement.value = result.DisplayValue;
          const event = new Event('change');
          this.input.nativeElement.dispatchEvent(event);
        }
      }));
    }
  }

}
