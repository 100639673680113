import { HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';

import { Component, OnInit, Input } from '@angular/core';
import { TresciService } from './../tresci.service';
import {AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-tlumacz',
  templateUrl: './tlumacz.component.html',
  styleUrls: ['./tlumacz.component.scss']
})
export class TlumaczComponent implements OnInit {
  @Input() id;
  @Input() typ;
  @Input() tresci: TresciService;
  @ViewChild('kratka') pole: ElementRef;
  public generate_id = 0;
  public otwarte = false;
  public plik: Plik;
  public offset = '-20px';
  public offsetH = '0px';
  public offset1 = 0;
  public usunok = false;
  @ViewChild('pole_tresc') pole_tresc: ElementRef;
  constructor() {
  }

  ngOnInit() {
    var x = 1; 
  }
  public drag() {
    this.generate_id = this.tresci.newGenId();
    if (this.typ === 'info' && this.tresci.czy_pelne(this.id, 'bezpustych') && this.plik == null) {
      this.getFile();
    }
  }
  public usunTyp() {
    if (this.usunok) {
      return 'Czy na pewno chcesz usunąć plik?';
    } else {
      return 'Usun Plik';
    }
  }
  public zapisz() {
    this.tresci.zapisz_pole(this.id, this.pole_tresc.nativeElement.innerHTML, this.typ, null);
  }
  public fileChangeEvent(fileInput: any) {
    let id_jezyk = '_' + this.tresci.jezyk_id;
    if (id_jezyk === '_1') {
      id_jezyk = '';
    }
    const id = 'info_plik_' + this.id + '_' + this.tresci.system + id_jezyk;
    let image = fileInput.target.files[0] as File;
    // this.tresci.copyMessage(image.name.toString());
    let fileReader: FileReader = new FileReader();
    let self = this;
    let im;
    let tresci = this.tresci;
    this.plik = new Plik(this.tresci, this.id);
    const plik = this.plik;
    fileReader.onloadend = function(x) {
      im = fileReader.result;
      plik.add(im);
      // const urlCreator = window.URL;
      // plik.url = urlCreator.createObjectURL(im);
      let name = image.name.split('.');
      plik.type = name[name.length - 1];
      tresci.dodaj_plik(id, im, name[name.length - 1]);

    };
    fileReader.readAsDataURL(image);
  }
  public getFile(jezyk_podstawowy = false) {
    // http://api.b2b.selt.com/api/StorageFile/Get?id=2292
    let id_jezyk = '_' + this.tresci.jezyk_id;
    if (id_jezyk === '_1' || jezyk_podstawowy) {
      id_jezyk = '';
    }
    const request = new HttpRequest('GET',
       '/api/StorageFile/Get?id=' + 'info_plik_' + this.id + '_' + this.tresci.system + id_jezyk,
     {},
    {responseType: 'blob', headers: this.tresci.user.getHeader()});
    this.tresci.http.request(request).pipe(/*take(1)*/)
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Response:
              const urlCreator = window.URL;
              this.plik = new Plik(this.tresci, this.id);
              this.plik.tresc = this.tresci.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(event.body));
              this.plik.url = urlCreator.createObjectURL(event.body);
              this.plik.type = event.body.type;
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // this.tresci.exception.exception(this.tresci.exception.error(err), err);
            if (id_jezyk !== '') {
              this.getFile(true);
            }
          }
        });
  }
  public usunPlik() {
    if (this.usunok) {
      this.plik = null;
      let id_jezyk = '_' + this.tresci.jezyk_id;
      if (id_jezyk === '_1') {
        id_jezyk = '';
      }
      this.tresci.usun_plik('info_plik_' + this.id + '_' + this.tresci.system + id_jezyk);
    }
    this.usunok = true;
  }


  public getPosition(event) {
    let offsetLeft = 0;
    let el = event.srcElement;
    let szerokosc_kontrolki = 0;
    const width = el.scrollWidth;

    while (el) {
        if (el != null && el.attributes.length > 1 && el.attributes[1].value.indexOf('rozmieszczenie_k2') !== -1) {
          szerokosc_kontrolki = el.clientWidth;
        }
        if (el != null && el.attributes.length > 1 && el.attributes[1].value.indexOf('kontrolki2') !== -1) {
          offsetLeft =  el.offsetLeft;
        }
        el = el.parentElement;
    }
    if (offsetLeft + szerokosc_kontrolki < width) {
      this.offset = (offsetLeft + szerokosc_kontrolki - width) + 'px;';
    } else {
      this.offset = '0px';
    }
    return this.offset;
  }

  public getPosition2() {
    let offsetLeft = 0;
    let szerokosc_kontrolki = 0;
    if (this.pole != null) {
      let el = this.pole.nativeElement;
      const width = el.scrollWidth;
      szerokosc_kontrolki = el.clientWidth;
      while (el) {
          if (el != null && el.attributes.length > 1 && (el.attributes[1].value.indexOf('rozmieszczenie_k2') !== -1 || el.attributes[1].value.indexOf('rozmieszczenie_k') !== -1)) {
            szerokosc_kontrolki = el.clientWidth;
          }
          if (el != null && el.attributes.length > 1 && el.attributes[1].value.indexOf('kontrolka') !== -1) {
            offsetLeft =  el.offsetLeft;
          }
          el = el.parentElement;
      }
      if (offsetLeft + szerokosc_kontrolki < width) {
        this.offset = (offsetLeft + szerokosc_kontrolki - width) + 'px';
      } else {
        this.offset = '-20px';
      }
    }

    return this.offset;
  }

  public getPositionH() {
    let offsetLeft = 0;
    let wysokosc_kontrolki = 0;
    if (this.pole != null) {
      let el = this.pole.nativeElement;
      const width = el.scrollHeight;
      wysokosc_kontrolki = el.clientHeight;
      while (el) {
          if (el != null && el.attributes.length > 1 && (el.attributes[1].value.indexOf('rozmieszczenie_k2') !== -1 || el.attributes[1].value.indexOf('rozmieszczenie_k') !== -1)) {
            wysokosc_kontrolki = el.clientHeight;
          }
          if (el != null && el.attributes.length > 1 && el.attributes[1].value.indexOf('kontrolka') !== -1) {
            offsetLeft =  el.offsetLeft;
          }
          el = el.parentElement;
      }
      if (offsetLeft + wysokosc_kontrolki < width) {
        this.offsetH = (offsetLeft + wysokosc_kontrolki - width) + 'px';
      } else {
        this.offsetH = '-20px';
      }
    }

    return this.offsetH;
  }
  public getOffset() {
    this.getPosition2();
    // this.pole
    if (this.pole != null) {
      // this.pole.nativeElement.style.right = this.offset;
    }
    return this.offset;
  }
}
class Plik  {
  tresc = null;
  type = null;
  url = null;
  constructor(private tresci: TresciService, private id) {
  }
  public add(tresc) {
    this.tresc = tresc;
  }
  public czyImage() {
    if (this.type == null) {
      return false;
    }
    if (this.type.indexOf('png') !== -1 || this.type.indexOf('jpg') !== -1 || this.type.indexOf('jpeg') !== -1 || this.type.indexOf('gif') !== -1 || this.type.indexOf('PNG') !== -1 || this.type.indexOf('JPG') !== -1 || this.type.indexOf('JPEG') !== -1 || this.type.indexOf('GIF') !== -1 || this.type.indexOf('image') !== -1) {
      return true;
    } else {
      return false;
    }
  }
  public getAdress() {
    return this.tresci.sanitizer.bypassSecurityTrustUrl(this.url);
  }
  public getUrl() {
    let id_jezyk = '_' + this.tresci.jezyk_id;
    if (id_jezyk === '_1') {
      id_jezyk = '';
    }
    return   '/api/StorageFile/Get?id=' + 'info_plik_' + this.id + '_' + this.tresci.system + id_jezyk;
  }
}
