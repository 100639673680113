<div class="container">
  <div class="kafelki">
    <div class="kafelka" *ngIf="!BlockingB2B" (click)="routeTo('Systems')">
      <div class="foto foto1"></div>
      <div id="złóż_zamówienie" class="tresc" [translation]="TranslationEnum.Translation">{{'złóż_zamówienie'|translate}}
      </div>
    </div>
    <div class="kafelka" *ngIf="!BlockingB2B" (click)="routeTo('Orders')">
      <div class="foto foto2"></div>
      <div id="lista_zamówień" class="tresc" [translation]="TranslationEnum.Translation">{{'lista_zamówień'|translate}}</div>
    </div>
    <div class="kafelka" *ngIf = "EnableEWdt" (click)="routeTo('Orders/ewdt')">
      <div class="foto foto6"></div>
      <div id="ewdt" class="tresc" [translation]="TranslationEnum.Translation">{{'ewdt'|translate}}</div>
    </div>
    <!-- <div class="kafelka" (click)="tresci.zmienStrone('Zaplata','Zaplata')">
      <div class="foto foto3"></div>
      <div id="rachunki" class="tresc" [translation]="TranslationEnum.Translation">{{'rachunki'|translate}}</div>
    </div> -->
    <div class="kafelka" (click)="routeTo('Reklamacje')">
      <div class="foto foto4"></div>
      <div id="reklamacja" class="tresc" [translation]="TranslationEnum.Translation">{{'reklamacja'|translate}}</div>
    </div> 
    <div class="kafelka" (click)="routeTo('Invoices')">
      <div class="foto foto5"></div>
      <div id="faktury" class="tresc" [translation]="TranslationEnum.Translation">{{'faktury'|translate}}</div>
    </div>
    <div class="kafelka" (click)="routeTo('Strefa')">
      <div class="foto foto7"></div>
      <div id="strefa" class="tresc" [translation]="TranslationEnum.Translation">{{'strefa'|translate}}</div>
    </div>
  </div>
</div>
