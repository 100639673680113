import { Proform } from '../../shared/models/order-register/proforms-list/proform.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ViewChild, AfterViewInit, Input, Output, EventEmitter, OnInit, OnChanges, AfterViewChecked, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TresciService } from 'src/app/tresci.service';
import { OrderNoteDialogComponent } from '../order-list/order-note-dialog/order-note-dialog.component';
import { Order } from 'src/app/shared/models/orders/order.model';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { PaymentConfirmDialogComponent } from './payment-confirm-dialog/payment-confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { OrderRegisterService } from '../order-register.service';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, takeWhile } from 'rxjs/operators';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from 'src/app/shared/service/data-sharing.service';
import { Contractor } from 'src/app/shared/models/user/contractor.model';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-proforms-list',
  templateUrl: './proforms-list.component.html',
  styleUrls: ['./proforms-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProformsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public TranslationEnum = TranslationEnum;

  public Culture: string;
  public pastDate: Date = new Date();
  public pastDateFromat: string;
  public myCurrentDate: Date = new Date();

  columnsToDisplay: string[] = ['ProformaFormat', 'NumberOfOrders', 'ProformaDate', 'ValueTotalNetto', 'IsClickedOrder', 'Printout', 'Order', 'Remove', 'Expand'];

  expandDisplayedColumns: string[] = [
    'B2BId',
    'Product',
    'System',
    'Quantity',
    'Applies',
    'Description',
    'TotalCost',
    'DeliveryCostNetto',
    'OrderStatusName',
    'OrderDate',
    'OrderingName',
    'Note',
    'Printout',
  ];
  dataSource: MatTableDataSource<Proform>;
  // data: Proform[] = [];
  expandedElement: Proform | null;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // filterTerm = '';
  // filterColumns: string[] = ['ProformaFormat'];
  // filterColumn: string = this.filterColumns[0];
  // @Output() filterChanged = new EventEmitter<{ value: string }>();

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  @Input() dateFrom: string = null;
  @Input() dateTo: string = null;
  @Output() dateChange = new EventEmitter<{ value: string }>();

  @Input() isMineOrders = false;

  @Input() refresh = false;
  @Output() refreshEmiter = new EventEmitter<{}>();

  proformaHref: any;
  private contractorNumber = '';
  private subscriptions: Subscription[] = [];

  constructor(
    private _adapter: DateAdapter<any>,
    private orderRegisterService: OrderRegisterService,
    public dialog: MatDialog,
    public tresci: TresciService,
    private _changeDetectorRef: ChangeDetectorRef,
    translationService: TranslateService,
    private dataSharingService: DataSharingService,
    public datePipe: DatePipe
  ) {
    this.Culture = window['culture'];
    this.subscriptions.push(translationService.onLangChange.subscribe(() => {
      this.Culture = window['culture'];
      this._adapter.setLocale(this.Culture);
      this._changeDetectorRef.detectChanges();
    }));
    const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
    this.contractorNumber = contractor.ContractorNumber;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit() {
    this.range.patchValue({
      start: this.dateFrom,
      end: this.dateTo,
    });
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.dataSharingService.contractorChanged.subscribe((x) => {
      if (x) {
        const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
        this.contractorNumber = contractor.ContractorNumber;
        this.refreshEmiter.emit({
          value: true,
        });
      }
    }));

    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    // merge(this.sort.sortChange, this.paginator.page, this.filterChanged, this.refreshEmiter, this.dateChange)
    this.subscriptions.push(merge(this.sort.sortChange, this.refreshEmiter, this.dateChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          // return this.orderRegisterService.getProformsWithOrders(this.contractorNumber, this.filterColumn, this.filterTerm,
          //   this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize,
          //   this.dateFrom, this.dateTo,
          //   this.isMineOrders);
          return this.orderRegisterService.getProformsWithOrders(this.contractorNumber, '', '', this.sort.active, this.sort.direction, 0, 10000, this.dateFrom, this.dateTo, this.isMineOrders);
        }),
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.TotalCount;
          return data.Proforms;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.dataSource = new MatTableDataSource(data))));

    // this.labelsMatPaginator();
  }

  ngOnChanges() {
    this.refreshEmiter.emit({
      value: true,
    });
  }

  // applyFilter(event: any): void {
  //   this.filterTerm = event.value.trim().toLowerCase();
  //   //this.paginator.pageIndex = 0;
  //   this.filterChanged.emit({
  //     value: this.filterTerm
  //   });
  // }

  applyFilter(input: HTMLInputElement) {
    const filterValue = input.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  dateRangeChange(event: Event): void {
    if (this.range.controls['start'].value && this.range.controls['end'].value) {
      this.dateFrom = this.range.controls['start'].value.toISOString().slice(0, 10);
      this.dateTo = this.range.controls['end'].value.toISOString().slice(0, 10);
      this.dateChange.emit({
        value: '',
      });
    }
  }

  openDialogOrder(proformaNumber: NumberConstructor) {
    let dialogRef: MatDialogRef<PaymentConfirmDialogComponent, any>;

    dialogRef = this.dialog.open(PaymentConfirmDialogComponent, { data: proformaNumber });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.isLoadingResults = true;
        this.refreshEmiter.emit({
          value: true,
        });
      }
    }));
  }

  openDialogRemove(proforma: string) {
    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
    const data: DialogData = {
      title: '',
      content: 'czy_na_pewno_chcesz_usunac_pozycje',
      answerOk: false,
    };
    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.isLoadingResults = true;
        this.subscriptions.push(this.tresci.removeNrProformy(proforma).subscribe(() => {
          this.refreshEmiter.emit({
            value: true,
          });
        }));
      }
    }));
  }

  openOrderNoteDialog(order) {
    this.dialog.open(OrderNoteDialogComponent, {
      width: '550px',
      data: order,
    });
  }

  // labelsMatPaginator(): void {
  //   this.paginator._intl.itemsPerPageLabel = '';
  //   this.paginator._intl.firstPageLabel = '';
  //   this.paginator._intl.previousPageLabel = '';
  //   this.paginator._intl.nextPageLabel = '';
  //   this.paginator._intl.lastPageLabel = '';
  //   this.paginator._intl.getRangeLabel = rangeLabel;
  // }
}


// const rangeLabel = (page: number, pageSize: number, length: number) => {
//   if (length === 0 || pageSize === 0) { return `0 / ${length}`; }

//   length = Math.max(length, 0);

//   const startIndex = page * pageSize;

//   const endIndex = startIndex < length ?
//     Math.min(startIndex + pageSize, length) :
//     startIndex + pageSize;

//   return `${startIndex + 1} - ${endIndex} / ${length}`;
// };
