
export class Invoice {
    Year:Number;					// Rok faktury
    Date:any;				// Data wystawienia faktury
    InvoiceNumber:string;		// Faktura
    ProductionNumber:string;	// Numer zlecenia
    ContractorNumber:string;	// Numer kontrahenta
    OrderDetails:string;		// Dotyczy
    B2BNumber:string;			// Numer B2B
    TraderOrder:boolean;
}