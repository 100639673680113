import { ViewContainerRef, ViewRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
export class ModelComponentEdit {
  public ModuleType: string;
  public Components: {Component: any, Step: string }[] = [{Component: null, Step: ''}];
  public Form: FormGroup;
  public Module?: number;
  public Type?: number;
}


export class PositionModelComponent {
  public ModuleType: string;
  public Type: number;
  public Module: number;
  public Form: FormGroup;
  public Components: any[] = [];
  public PositionId: number;
}
