import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DirectivesService {


  public OverlayRef: OverlayRef;

  constructor() {
  }

  public close() {
    if (this.OverlayRef !== undefined) {
      this.OverlayRef.detach();
    }
  }

}
