import { CookieService } from 'ngx-cookie-service';
import { DataSharingService } from 'src/app/shared/service/data-sharing.service';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, concatMap, finalize, map, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { OrderRegisterService } from '../order-register.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { StatusEnum } from 'src/app/shared/enums/status-enum';
import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Delivery } from 'src/app/shared/models/order-register/delivery-list/delivery.model';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Contractor } from 'src/app/shared/models/user/contractor.model';
import { FormGroup, FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { ModalForEnum } from 'src/app/shared/enums/modal-for-enum';
import { OrderWarningsDialogComponent } from '../order-list/order-warning-dialog/order-warnings-dialog.component';
import { TresciService } from 'src/app/tresci.service';
import { DeliveryListDialogComponent } from './delivery-list-dialog/delivery-list-dialog.component';
import { WdtStatusEnum } from 'src/app/shared/enums/wdt-status-enum';
import { User } from 'src/app/shared/models/user/user.model';
import { PermissionEnum } from 'src/app/shared/enums/permission-enum';
import { AuthorizationService } from 'src/app/module-login/authorization.service';

@Component({
  selector: 'app-delivery-list',
  templateUrl: './delivery-list.component.html',
  styleUrls: ['./delivery-list.component.scss'],
})
export class DeliveryListComponent implements OnInit, AfterViewInit, OnDestroy {
  public TranslationEnum = TranslationEnum;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() status: StatusEnum;
  @Input() dateFrom: string = null;
  @Input() dateTo: string = null;
  @Input() isMineOrders = false;
  @Input() refresh = false;

  @Output() filterChanged = new EventEmitter<{ value: string }>();
  @Output() dateChange = new EventEmitter<{ value: string }>();
  @Output() radioStatusChange = new EventEmitter<{ value: WdtStatusEnum }>();
  @Output() refreshEmit = new EventEmitter<{}>();

  private subscriptions: Subscription[] = [];
  resultsLength = 0;
  IsLoadingResults = true;
  IsRateLimitReached = false;
  Culture = '';
  private contractorNumber = '';
  public ConfirmationEWdt:boolean = false;
  public Selection = new SelectionModel<Delivery>(true, []);
  dataSource: MatTableDataSource<Delivery>;
  columnsToDisplay: string[] = ['Select', 'InvoiceNumber', 'WdtNumber', 'OrdersNumber', 'UpsNo', 'DeliveryDate', 'Confirmed', 'WhoConfirmed', 'ConfirmationDate', 'ShortDeliveryAddress', 'PrintoutWDT'];

  // RADIO BUTTONS //
  optionsForRadios = [
    { Value: WdtStatusEnum.Unconfirmed, Display: 'niepotwierdzone', CheckedOnStart: true },
    { Value: WdtStatusEnum.Confirmed, Display: 'potwierdzone', CheckedOnStart: false },
    { Value: WdtStatusEnum.All, Display: 'wszystkie', CheckedOnStart: false },
  ];
  radioCheckedOption: WdtStatusEnum;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  CreateComplaintOrder: any;

  constructor(
    private orderRegisterService: OrderRegisterService,
    private dataSharingService: DataSharingService,
    public tresci: TresciService,
    public dialog: MatDialog,
    private cookieService: CookieService,
    private authorizationService: AuthorizationService
  ) {
    const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
    this.contractorNumber = contractor.ContractorNumber;
    this.radioCheckedOption = WdtStatusEnum.Unconfirmed;
    this.Culture = cookieService.get('lang');
    var User = JSON.parse(this.cookieService.get('_usi')) as User;
    // console.log("cn2",contractor);
    this.ConfirmationEWdt = contractor.ConfirmationEWdt;
    var CreateCon = this.authorizationService.hasPermission(PermissionEnum.CreateComplaintOrder);
    // console.log('CreateComplaintOrder', CreateCon);
    if(CreateCon != null){
      this.CreateComplaintOrder = true;
    } else {
      this.CreateComplaintOrder = false;
    }
    // console.log('CreateComplaintOrder', this.CreateComplaintOrder);
  }
  getCulture(){
    return this.cookieService.get('lang');
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit() {
    this.range.patchValue({
      start: this.dateFrom,
      end: this.dateTo,
    });

    this.subscriptions.push(this.dataSharingService.contractorChanged.subscribe((x) => {
      if (x) {
        const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
        this.contractorNumber = contractor.ContractorNumber;
        this.refreshEmit.emit({
          value: true,
        });
      }
    }));
  }

  ngAfterViewInit(): void {
    // this.orderRegisterService.getWdts(this.contractorNumber).subscribe();
    this.subscriptions.push(merge(this.sort.sortChange, this.refreshEmit, this.dateChange, this.radioStatusChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.IsLoadingResults = true;
          // console.log('radioCheckedOption', this.radioCheckedOption);
          if(this.radioCheckedOption == 0){
            this.dateFrom = "2000-01-01";
            this.dateTo = "2100-01-01";
          }
          
          return this.orderRegisterService.getWdts(this.sort.active, this.sort.direction, this.contractorNumber, this.dateFrom, this.dateTo, this.radioCheckedOption);
        }),
        map((data) => {
          this.IsLoadingResults = false;
          this.IsRateLimitReached = false;
          this.resultsLength = data.length;
          return data;
        }),
        catchError(() => {
          this.IsLoadingResults = false;
          this.IsRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.dataSource = new MatTableDataSource(data))));
  }

  selectedIdsList(id?: number): number[] {
    const ids = [];
    if (id !== undefined) {
      ids.push(id);
    } else if (this.Selection.selected.length > 0) {
      this.Selection.selected.forEach((x) => {
        ids.push(x.Id);
      });
    }
    return ids;
  }

  selectAtLeastOneDialog() {
    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
    const data: DialogData = {
      title: '',
      content: 'zaznacz_chociaz_jedna_pozycje',
      answerOk: true,
    };
    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
  }

  checkOrderAvailability() {
    let dialogRef: MatDialogRef<DeliveryListDialogComponent, any>;

    if (this.Selection.selected.length <= 0) {
      this.selectAtLeastOneDialog();
    } else {
      const ids = this.selectedIdsList();
      if (ids.length > 0) {
        dialogRef = this.dialog.open(DeliveryListDialogComponent, {
          data: { tableData: this.Selection.selected },
          minWidth: '1200px',
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
          this.Selection.clear();
          this.refreshEmit.emit({
            value: true,
          });
        }));
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.Selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected >= numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.Selection.clear();
    } else {
      this.Selection.clear();
      this.dataSource.data.forEach((row:Delivery) => {
        if(!row.IsDeliveryConfirmed && row.IsPossibleConfirm){
          this.Selection.select(row);
        }

      });
    }
  }
  applyFilter(input: HTMLInputElement) {
    const filterValue = input.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  dateRangeChange(event: Event): void {
    if (this.range.controls['start'].value && this.range.controls['end'].value) {
      this.dateFrom = this.range.controls['start'].value.toISOString().slice(0, 10);
      this.dateTo = this.range.controls['end'].value.toISOString().slice(0, 10);
      this.dateChange.emit({
        value: '',
      });
    }
  }

  radioChange(option): void {
    this.radioCheckedOption = option.Value;
    this.radioStatusChange.emit({ value: option.Value });
  }
}

