export enum SystemEnum {
  'MA' = 'Markiza',
  'MAD' = 'MarkizaDetale',
  'MV' = 'Veranda',
  'MVD' = 'VerandaDetale',
  'PG' = 'Pergola',
  'FS' = 'Fasadowe',
  'FR' = 'Refleksol',
  'SB' = 'Sunbreaker',
  'MP' = 'PergolaV',
  'MPD' = 'PergolaVDetale',
  'WGS' = 'GlassSystem',
  'HA' = 'Handlowy',
  'TEST' = 'Test',
  'FSD' = 'FasadoweDetale',
}
