import { ActiveFormType } from './../../../models/form-model/models/active-form.model';
import { FormService } from './../../../service/form.service';
import { KimDialogData } from './../../../models/form/filter-kim';
import { KimDialogComponent } from './kim-dialog/kim-dialog.component';
import { Component, AfterViewChecked, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { Kim } from 'src/app/shared/models/form-model/kim.model';
import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, takeWhile } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-kim',
  templateUrl: './kim.component.html',
  styleUrls: ['./kim.component.scss'],
})
export class KimComponent implements OnInit, AfterViewInit, OnDestroy {
  public TranslationEnum = TranslationEnum;

  public Control: HtmlControl;
  public Group: FormGroup;
  private subscriptions: Subscription[] = [];

  @ViewChild('textarea') textarea: ElementRef;

  constructor(public dialog: MatDialog, private formService: FormService) {}
  ngOnDestroy(): void {
    if (this.Control.type !== ActiveFormType.Position)  {
      this.formService.TempKimOptions = Array.from(this.Control.optionsKim);
    }
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit(): void {
    if (this.formService.TempKimOptions.length > 0 && this.Control.type !== ActiveFormType.Position) {
      if (this.formService.TempKimOptions.some((x) => x.Quantity > this.Control.parameters.find((x) => x.Key === 'Max').Value)) {
        for (const element of this.formService.TempKimOptions.filter((x) => x.Quantity > this.Control.parameters.find((x) => x.Key === 'Max').Value)) {
          element.Quantity = this.Control.parameters.find((x) => x.Key === 'Max').Value;
        }
      }
      this.Control.optionsKim = Array.from(this.formService.TempKimOptions);
      this.Control.value = new Array<Kim>();
      for (const choosen of this.formService.TempKimOptions.filter((x) => x.Quantity > 0)) {
        this.Control.value.push(choosen);
      }
    } else if (this.Control.type === ActiveFormType.Position) {

      if (this.Control.optionsKim.filter((x) => x.Quantity > 0).length > 0) {
        this.Control.value = new Array<Kim>();
        for (const choosen of this.Control.optionsKim.filter((x) => x.Quantity > 0)) {
          this.Control.value.push(choosen);
        }

      }
    }
  }

  ngAfterViewInit() {
    if (this.Control.value === '') {
      this.Group.get(this.Control.name).setValue(this.Control.value);
    }
  }

  openKimDialog() {
    const kimDialogData: KimDialogData = {
      Data: this.Control.optionsKim,
      IsPossition: this.Control.position !== 0 ? true : false,
      Max: this.Control.parameters.find((x) => x.Key === 'Max').Value,
    };

    const dialogRef = this.dialog.open(KimDialogComponent, { data: kimDialogData, width: '1320px', disableClose: true });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
      const chosen = this.Control.optionsKim.filter((x) => x.Quantity !== 0);
      this.Control.optionsKim.filter((x) => x.Quantity !== 0);
      this.Control.value = new Array<Kim>();
      if (result === true) {
        for (const item of chosen) {
          this.Control.value.push(item);
        }
        const event = new Event('change');
        this.textarea.nativeElement.dispatchEvent(event, this.textarea, this.Control.value);
        // this.Group.get(this.Control.name).setValue(this.Control);
      }
    }));
  }
}
