import { FormControlsService } from './../../../form-controls.service';
import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { SelectMultiColumnDialogData } from 'src/app/shared/models/form-model/models/select-multi-column-dialog-data.model';

@Component({
  selector: 'app-select-multi-column-dialog',
  templateUrl: './select-multi-column-dialog.component.html',
  styleUrls: ['./select-multi-column-dialog.component.scss']
})
export class SelectMultiColumnDialogComponent implements OnInit {
  public TranslationEnum = TranslationEnum;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public DataSource = new MatTableDataSource<any>();
  public DisplayedColumns: string[];
  public ControlLabel: string;

  constructor(public dialogRef: MatDialogRef<SelectMultiColumnDialogComponent>, @Inject(MAT_DIALOG_DATA) data: SelectMultiColumnDialogData, public controlsService: FormControlsService) {
    this.DataSource.data = data.Data;

    if (this.DataSource.data.length > 0) {
      this.DisplayedColumns = Object.keys(data.Data[0]).filter(key => key !== 'Key');
    }
    this.ControlLabel = data.ControlName;
  }

  ngOnInit(): void {
    this.DataSource.paginator = this.paginator;
    this.DataSource.sort = this.sort;
  }

  applyFilter(input: HTMLInputElement): void {
    const filterValue = input.value;
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }

  choose(row: any): void {
    this.dialogRef.close(row); 
  }

}
