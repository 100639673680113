<div class="konfigurator konfigurator_{{tresci.zwijanie}}">

	<div class="container">
		<div>
			<h1 *ngIf="tresci.system_wybrany != pusty ">
				<app-tlumacz [id]="tresci.system_wybrany" [typ]="'zmienne'" [tresci]="tresci"></app-tlumacz>
			</h1>
			<h1 *ngIf="tresci.system_wybrany == pusty ">
				<app-tlumacz [id]="tresci.system" [typ]="'zmienne'" [tresci]="tresci"></app-tlumacz>
			</h1>
		</div>
		<div class="wczytywanie" *ngIf="tresci.zamowienie != null && tresci.zamowienie.czyWczytuje()">
			<div>
				<app-tlumacz [id]="'Wczytywanie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> {{tresci.zamowienie.ileProcent()}}%
			</div>
			<div>
				<div *ngIf="tresci.zamowienie.istnieja_zapisane" class="wyczysc" (click)="wyczysc();">
					<app-tlumacz [id]="'Wyczyść dane'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
				</div>

			</div>
			<div class="problem" *ngIf="tresci.problem_wczytywanie">
				<app-tlumacz [id]="'Problem z wczytywaniem'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
		</div>
		<div>
			<div style="margin-bottom: 20px; width: 170px; margin-left: auto;" *ngIf="tresci.zamowienie.czyWczytuje() && tresci.zamowienie.isLoaded && tresci.DevMode">
				<div class="technology" (click)=" showTechnology()">
					<app-tlumacz [id]="'Technologia'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
				</div>
				<app-technologiaresultinfo #technology [Kroki]="tresci.zamowienie.naglowek"></app-technologiaresultinfo>
			</div>

			<div *ngIf="tresci.zamowienie != null && !tresci.zamowienie.czyWczytuje()">
				<div class="menu_gorne">
					<div>
						<div class="menu_graf">
							<div class="lewe_menu_graf header_{{tresci.zakladka === 0}}">
								<div class="zakladka_header_graf zakladka_{{tresci.zakladka === 0}}" (click)="tresci.zakladka = 0; tresci.zmien_zapisz();">
									<h1>
										<app-tlumacz [id]="'naglowek'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
									</h1>
								</div>
							</div>
							<div class="lewe_menu_graf nieaktywne header_{{tresci.zakladka === 1}}" (click)="tresci.zakladka = 1; tresci.zmien_zapisz();">
								<div class="zakladka_header_graf zakladka_{{tresci.zakladka === 1}}">
									<h1>
										<app-tlumacz [id]="'dane_modułów'" [typ]="'zmienne'" [tresci]="tresci"></app-tlumacz>
									</h1>
								</div>
							</div>
							<div *ngIf="tresci.zamowienie.IsFooter && tresci.zamowienie.czyStopkaPelna()" class="lewe_menu_graf nieaktywne header_{{tresci.zakladka === 2}}" (click)="tresci.zakladka = 2; tresci.zmien_zapisz();">
								<div class="zakladka_header_graf zakladka_{{tresci.zakladka === 2}}">
									<h1>
										<app-tlumacz [id]="'dane_stopki'" [typ]="'zmienne'" [tresci]="tresci"></app-tlumacz>
									</h1>
								</div>
							</div>
							<div class="lewe_menu_graf nieaktywne header_{{tresci.zakladka === 3}}" *ngIf="tresci.user.ListOfSurchargeType !== null" (click)="tresci.zakladka = 3; tresci.zmien_zapisz();">
								<div class="zakladka_header_graf zakladka_{{tresci.zakladka === 3}}">
									<h1>
										<app-tlumacz [id]="'modul_doplaty'" [typ]="'zmienne'" [tresci]="tresci"></app-tlumacz>
									</h1>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div *ngIf="tresci.zamowienie.istnieja_zapisane" class="wyczysc" (click)="wyczysc();">
							<app-tlumacz [id]="'Wyczyść dane'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
						</div>
						<div *ngIf="!tresci.zamowienie.istnieja_zapisane" class="wyczysc" (click)="przywroc();">
							<app-tlumacz [id]="'Przywróć dane'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
						</div>
					</div>
				</div>

				<div class="konfigurator_form">
					<div class="pole_aktywne poleA_{{tresci.zakladka === 0}} ">
						<div class="dostawa_box dostawa_box_{{tresci.user.dostawa_aktywne}}" *ngIf="tresci.komponent !== OrderDesktop && tresci.user.dostawa != null">
							<h2>
								| <span class="pole_adres">
									<app-tlumacz [id]="'Dostawa'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
									<app-tlumacz class="info_text" [id]="'info_sposob_dostawy'" [typ]="'info'" [tresci]="tresci"></app-tlumacz>
								</span>
							</h2>
							<div class="dostawa_icon">
								<div *ngFor="let value of tresci.user.dostawa.DeliveryMethods; let i = index" class="dostawa dostawa_{{value.Id}} dostawa_wybrany_{{i === tresci.user.dostawa_id}}" (click)="tresci.user.zamknij_dostawa(i);">
                  <ng-container *ngIf="isTraderForShipping(value)">
                    <div class="foto_dostawa"></div>
									  <div>
									  	<app-tlumacz [id]="value.Name" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                    </div>
                  </ng-container>
								</div>
							</div>
							<div class="pole_dostawy" *ngIf="tresci.user.dostawa.DeliveryMethods[tresci.user.dostawa_id].IsDeliveryAddress">
								<h2>
									| <span class="pole_adres">
										<app-tlumacz [id]="'adres_dostawy'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
										<app-tlumacz class="info_text" [id]="'info_adres_dostawy'" [typ]="'info'" [tresci]="tresci"></app-tlumacz>
									</span>
								</h2>
								<div class="select select_z_AdresDostawy">
									<!--<div class="pole_wybrane" (click)="kontr.select.otworz($event)"><div  class="obraz" [ngStyle]="{'background-image': 'url(' + 'https://api.sklep.selt.com' + '/api/Configurator/GetIcon?code=' + kontr.select.pole_wybrane.Key + ')'}" *ngIf="kontr.select.fotoBox"></div><div class="nazwa"><div class="pole_nazwa_select">{{kontr.select.getActualDisplay()}}</div></div></div>-->
									<div *ngFor="let value of tresci.user.dostawa.ContractorAdresses; let i = index">
										<div *ngIf="i === tresci.user.dostawa_id_adres" class="pole_wybrane" (click)="tresci.user.dostawa_otwarte_adres = !tresci.user.dostawa_otwarte_adres; tresci.user.chron_adres = true;">

											<div class="nazwa">
												<div class="pole_nazwa_select">{{value.Name}} {{value.Adress}} {{value.PostalCode}} {{value.City}} {{value.Country}} ({{value.Zone}}) </div>
											</div>
										</div>
									</div>
									<div class="pole_przewijane pole_przewijane_{{tresci.user.dostawa_otwarte_adres}}">
										<div class="pole_lista act_{{value.IsEnable}} wybrane_{{value.wybrane}}" *ngFor="let value of tresci.user.dostawa.ContractorAdresses; let i = index" (click)="tresci.user.zamknij_dostawa_adres(i);">
											<div class="nazwa">{{value.Name}} {{value.Adress}} {{value.PostalCode}} {{value.City}} {{value.Country}} ({{value.Zone}})</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="dalej" *ngIf="tresci.user.dostawa_aktywne === true" (click)="dalej_dostawa()">
							<app-tlumacz [id]="'dalej'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
						</div>
						<div class="edytuj" *ngIf="tresci.user.dostawa_aktywne === false && tresci.komponent !== OrderDesktop" (click)="tresci.user.dostawa_aktywne = true">
							<app-tlumacz [id]="'edytuj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
						</div>
						<div class="both"></div>

						<app-konfiguratorform *ngIf="tresci.user.dostawa_aktywne === false" [Kroki]="tresci.zamowienie.naglowek"></app-konfiguratorform>
					</div>
				</div>
				<div class="pole_box">
					<div class="folder">
						<div class="folder_prawo">
							<div class="srodek">
								<div class="pole_aktywne poleA_{{tresci.zakladka === 1}}">
									<div class="moduly modul_{{tresci.zamowienie.IsModule}}" *ngIf="tresci.zamowienie.czyNaglowekGotowy()">
										<div class="pole_modul">
											<div class="modul_menu_poziome" *ngIf="tresci.zamowienie.IsModule">
												<div class="grup_module" *ngFor="let modul of tresci.zamowienie.grupa_modul; let i = index">
													<div class="pole_modul_menu aktywny_{{tresci.zamowienie.CurrentModule == i}}" (click)="tresci.zamowienie.zmien_modul(i); tresci.zamowienie.wyb_mod = false;">
														<div class="modul_menu blad_{{modul.poprawny}}" *ngIf="tresci.zamowienie.IsModule">
															<div class="nazwa" (click)="tresci.zamowienie.wyb_mod = !tresci.zamowienie.wyb_mod;">
																<span>
																	<app-tlumacz [id]="'grupamodul'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																</span>&nbsp;
																<span>{{i + 1}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="dodaj" *ngIf="tresci.zamowienie.IsModule" (click)="tresci.zamowienie.dodajModul();">
													+ <span>
														<app-tlumacz [id]="'dodaj_modul'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
													</span>
												</div>
											</div>
											<div class="jasno_szare_pole">
												<div class="modul_pokaz" *ngIf="tresci.zamowienie.IsModule">
													<div *ngFor="let modul of tresci.zamowienie.grupa_modul; let i = index">
														<div class="modul blad_{{modul.poprawny}}" *ngIf="i == tresci.zamowienie.CurrentModule && tresci.zamowienie.IsModule">
															<div>
																<div class="prev_mod" *ngIf="i > 0" (click)="tresci.zamowienie.zmien_modul(i - 1)"></div>
															</div>
															<div class="nazwa" (click)="tresci.zamowienie.wyb_mod = !tresci.zamowienie.wyb_mod;">
																<span>
																	<app-tlumacz [id]="'grupamodul'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																</span>&nbsp;
																<span>{{i + 1}}/{{tresci.zamowienie.grupa_modul.length}}</span>&nbsp;
																<span>({{modul.pozycja.length}} <span>
																		<app-tlumacz [id]="'systemow'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																	</span>)</span>
															</div>
															<div>
																<div class="dodaj" *ngIf="tresci.zamowienie.IsModule" (click)="tresci.zamowienie.dodajModul();">
																	<span>
																		<app-tlumacz [id]="'dodaj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																	</span>
																</div>
															</div>
															<div>
																<div class="usun" *ngIf="i > 0">
																	<span (click)="tresci.zamowienie.usunModul(i);">
																		<app-tlumacz [id]="'usun'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																	</span>
																</div>
															</div>
															<div>
																<div class="next_mod" *ngIf="i < tresci.zamowienie.grupa_modul.length - 1" (click)="tresci.zamowienie.zmien_modul(i + 1)"></div>
															</div>
														</div>
													</div>
													<div class="grupa_zbior">
														<div class="grupa_zbior2">
															<div *ngFor="let modul of tresci.zamowienie.grupa_modul; let i = index" class="widac_{{tresci.zamowienie.wyb_mod}} grupa_mod">
																<div class="modul2 blad_{{modul.poprawny}}" (click)="tresci.zamowienie.zmien_modul(i); tresci.zamowienie.wyb_mod = false;">
																	<div class="nazwa">
																		<span>
																			<app-tlumacz [id]="'grupamodul'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																		</span>&nbsp;
																		<span>{{i + 1}}/{{tresci.zamowienie.grupa_modul.length}}</span>&nbsp;
																		<span>({{modul.pozycja.length}} <span>
																				<app-tlumacz [id]="'systemow'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																			</span>)</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div *ngFor="let modul of tresci.zamowienie.grupa_modul; let i = index">

													<div *ngIf="i == tresci.zamowienie.CurrentModule">
														<app-konfiguratorform [Kroki]="modul.naglowekModul"></app-konfiguratorform>
														<h1 *ngIf="false">
															<app-tlumacz [id]="'pozycje'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
														</h1>
														<div class="moduly_poz" *ngIf="modul.naglowekModul.Last">
															<div class="dane_pozycji">
																<div class="ideki">
																	<table border="0">
																		<tr class="naglowek" *ngIf="modul.table_prop.length > 0 && modul.pozycja.length > 0">
																			<td></td>
																		</tr>
																		<tr class="tr_przerwa">
																			<td></td>
																		</tr>
																		<tr class="pozycje lewa_pozycja poprawna_{{pozycja.pozycja.poprawny}}" *ngFor="let pozycja of modul.pozycja; let j = index" (click)="modul.ustawAktywna(j)">
																			<td class="m_id">
																				<div class="ramka_td">{{j + 1}}. </div>
																			</td>
																		</tr>
																	</table>
																</div>
																<div class="tresci_pozycji">
																	<table border="0">
																		<tr class="naglowek" *ngIf="modul.table_prop.length > 0 && modul.pozycja.length > 0">
																			<td *ngFor="let tab of modul.table_prop; let x = index">
																				<div>
																					<app-tlumacz [id]="'label_t_' + tab.Key" [typ]="'form'" [tresci]="tresci"></app-tlumacz>
																				</div>
																			</td>
																			<td class="uzupelnienie"></td>
																		</tr>
																		<tr class="tr_przerwa">
																			<td></td>
																		</tr>
																		<tr class="pozycje srodkowa_pozycja poprawna_{{pozycja.pozycja.poprawny}}" *ngFor="let pozycja of modul.pozycja; let j = index" (click)="modul.ustawAktywna(j)">
																			<td *ngFor="let tab of modul.table_prop; let x = index">
																				<div class="ramka_td">
																					<div>{{pozycja.Wartosc(tab.Key)}}</div>
																				</div>
																			</td>
																			<td class="uzupelnienie">
																				<div class="ramka_td"></div>
																			</td>
																		</tr>
																	</table>

																</div>
																<div class="usuniecie">
																	<table border="0">
																		<tr class="naglowek" *ngIf="modul.table_prop.length > 0 && modul.pozycja.length > 0">
																			<td>
																			</td>
																			<td>
																			</td>
																		</tr>
																		<tr class="tr_przerwa">
																			<td></td>
																		</tr>
																		<tr class="pozycje prawa_pozycja poprawna_{{pozycja.pozycja.poprawny}}" *ngFor="let pozycja of modul.pozycja; let j = index" (click)="modul.ustawAktywna(j)">

																			<td class="edytuj_td">
																				<div class="ramka_td" title="edytuj">
																				</div>
																			</td>
																			<td class="kopiuj_td">
																				<div class="ramka_td" title="kopiuj" (click)="modul.kopiujPozycje(j); $event.stopPropagation();">
																				</div>
																			</td>
																			<td class="usun_td">
																				<div class="ramka_td" title="usuń" (click)="pozycja.usun = true; $event.stopPropagation();">
																					<span class="usun_span">
																						X
																					</span>
																				</div>
																				<div class="potwierdzenie" *ngIf="pozycja.usun" (click)="$event.stopPropagation();">
																					<div class="tresc_p">
																						<app-tlumacz [id]="'Czy_na_pewno_usunac_pozycje'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																					</div>
																					<div class="buttony_p">
																						<div class="questionAnswers" (click)="modul.usunPozycje(j); $event.stopPropagation();">
																							<app-tlumacz [id]="'Tak'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																						</div>
																						<div class="questionAnswers" (click)="pozycja.usun = false; $event.stopPropagation();">
																							<app-tlumacz [id]="'Nie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																						</div>
																					</div>
																				</div>
																			</td>
																		</tr>
																	</table>
																</div>
															</div>
															<table border="1" *ngIf="false">
																<tr class="naglowek" *ngIf="modul.table_prop.length > 0 && modul.pozycja.length > 0">
																	<td></td>
																	<td *ngFor="let tab of modul.table_prop; let x = index">
																		<div>
																			<app-tlumacz [id]="'label_' + tab.Key" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																		</div>
																	</td>
																	<td>
																	</td>
																</tr>
																<tr class="tr_przerwa">
																	<td></td>
																</tr>
																<tr class="pozycje  poprawna_{{pozycja.pozycja.poprawny}}" *ngFor="let pozycja of modul.pozycja; let j = index" (click)="modul.ustawAktywna(j)">
																	<td class="m_id">{{j + 1}}. </td>
																	<td *ngFor="let tab of modul.table_prop; let x = index">
																		<div>{{pozycja.Wartosc(tab.Key)}}</div>
																	</td>
																	<td (click)="modul.usunPozycje(j)">
																		usuń
																	</td>
																</tr>
															</table>

														</div>
														<div class="pozycjaModululPlus" *ngIf="modul.naglowekModul.Last && (modul.pozycja.length === 0 || (modul.pozycja[modul.pozycja.length - 1].WartoscKod('Moduł') !== 'P1' && modul.pozycja[modul.pozycja.length - 1].WartoscKod('Moduł') !== 'DP1' && modul.pozycja[modul.pozycja.length - 1].WartoscKod('Moduł') !== '11'))">
															<ng-component *ngIf="false">
																<div class="dodaj_pozycje" (click)="modul.dodajPozycje()" *ngIf="czyPozycja(modul)">+ <span>
																		<app-tlumacz [id]="'Dodaj_pozycje'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
																	</span></div>
															</ng-component>

														</div>
													</div>
												</div>
											</div>
                    </div>
                    <span *ngFor="let modul of tresci.zamowienie.grupa_modul; let i = index">
                      <div class="buttons-container" *ngIf="i == tresci.zamowienie.CurrentModule">
                        <span *ngIf="modul.pozycja.length !== 0 && (tresci.ManagePositions.IsCustomModularity || modul.pozycja[modul.pozycja.length - 1].WartoscKod('Moduł') === 'P1') ">
                          <div class="add-position" style="margin-right: 10px;" *ngIf="modul.naglowekModul.Last && (modul.pozycja.length !== 0 || tresci.ManagePositions.IsCustomModularity)">
                            <app-tlumacz [id]="'Zakoncz dodawanie pozycji'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                          </div>
                        </span>
                        <span *ngIf="(i == tresci.zamowienie.CurrentModule && tresci.zakladka === 1) && (tresci.ManagePositions.IsModuleProduct || modul.pozycja.length === 0) || tresci.ManagePositions.IsCustomModularity ">
                          <div class="add-position" *ngIf="modul.naglowekModul.Last && (modul.pozycja.length === 0 || tresci.ManagePositions.IsCustomModularity || (modul.pozycja[modul.pozycja.length - 1].WartoscKod('Moduł') !== 'P1' && modul.pozycja[modul.pozycja.length - 1].WartoscKod('Moduł') !== 'DP1' && modul.pozycja[modul.pozycja.length - 1].WartoscKod('Moduł') !== '11')) && czyPozycja(modul)" (click)="modul.dodajPozycje()">
                            <app-tlumacz [id]="'Dodaj następną pozycję'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
                          </div>
                        </span>
                      </div>
                    </span>
									</div>
									<div class="stopka_modul" *ngIf="tresci.zamowienie.IsModuleFooter && tresci.zamowienie.czyStopka()">
										<div *ngFor="let modul of tresci.zamowienie.grupa_modul; let i = index">
											<div *ngIf="i == tresci.zamowienie.CurrentModule && modul.naglowekModul.Last && modul.pozycja.length > 0">
												<app-konfiguratorform [Kroki]="modul.stopkaModul"></app-konfiguratorform>
											</div>
									</div>
								</div>
								<div class="pole_aktywne poleA_{{tresci.zakladka === 3}}">
									<div class="rowSubcharge" *ngIf="tresci.user.IsTrader">
										<div class="col-md-4">
											<div class="form-group">
												<label>
													<app-tlumacz [id]="'Dopłata'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
												</label>
												<select type="select" class="form-control" [selectedIndex]='0' [(ngModel)]='SelectSurcharge'>
													<ng-container *ngFor="let doplata of tresci.user.ListOfSurchargeType">
														<option *ngIf="this.tresci.jezyk === 'deu'" [ngValue]='doplata' name="doplata">{{doplata.NameDe}}</option>
                            <option *ngIf="this.tresci.jezyk === 'pol'" [ngValue]='doplata' name="doplata">{{doplata.Name}}</option>
														<option *ngIf="this.tresci.jezyk === 'eng'" [ngValue]='doplata' name="doplata">{{doplata.NameEn}}</option>
													</ng-container>
												</select>
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label>
													<app-tlumacz [id]="'Kwota'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
												</label>
												<input name="price" type="number" step="0.01" class="form-control" min="0" value="0" [(ngModel)]="Surcharge.Price">
											</div>
										</div>
									</div>
									<div id="doplatyMenu" *ngIf="tresci.user.IsTrader">
										<span class="button" (click)="addSurcharge()">
											<app-tlumacz [id]="'Dodaj_doplate'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
										</span>
									</div>
									<div id="doplatyTable" *ngIf="ListOfSurcharges.length !== 0">
										<table>
											<tr class="naglowek">
												<td class="naglowekModal-td">
													<app-tlumacz [id]="'Dopłata'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
												</td>
												<td class="naglowekModal-td">
													<app-tlumacz [id]="'Kwota'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
												</td>
												<td class="naglowekModal-td">
													<app-tlumacz [id]="'Rodzaj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
												</td>
												<td class="naglowekModal-td" *ngIf="tresci.user.IsTrader">
													<app-tlumacz [id]="'Usun'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
												</td>
											</tr>
											<tr class="pozycja" *ngFor="let surcharge of ListOfSurcharges">
												<td class="lewa_pozycja ">
													<ng-container *ngIf="this.tresci.jezyk === 'pol'">
														<div class="ramkaModalTd">{{surcharge.Name}}</div>
													</ng-container>
													<ng-container *ngIf="this.tresci.jezyk === 'deu'">
														<div class="ramkaModalTd">{{surcharge.NameDe}}</div>
                          </ng-container>
                          <ng-container *ngIf="this.tresci.jezyk === 'eng'">
														<div class="ramkaModalTd">{{surcharge.NameEn}}</div>
													</ng-container>
												</td>
												<td class="modalTd">
													<div class="ramkaModalTd">{{surcharge.Price}}</div>
												</td>
												<td class="prawa_pozycja" *ngIf="!tresci.user.IsTrader">
													<div class="ramkaModalTd">
														<ng-container *ngIf="surcharge.SurchargeType === 1">
															<app-tlumacz [id]="'Dopłata'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
														</ng-container>
														<ng-container *ngIf="surcharge.SurchargeType === 2">
															<app-tlumacz [id]="'Rabat'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
														</ng-container>
													</div>
												</td>
												<td class="modalTd" *ngIf="tresci.user.IsTrader">
													<div class="ramkaModalTd">
														<ng-container *ngIf="surcharge.SurchargeType === 1">
															<app-tlumacz [id]="'Dopłata'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
														</ng-container>
														<ng-container *ngIf="surcharge.SurchargeType === 2">
															<app-tlumacz [id]="'Rabat'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
														</ng-container>
													</div>
												</td>
												<td class="prawa_pozycja " *ngIf="tresci.user.IsTrader">
													<div class="ramkaModalTd"><span class="ikona usun" (click)="deleteSurcharge(surcharge)"></span></div>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="tresci.zamowienie.stopka != null" class="pole_aktywne poleA_{{tresci.zakladka === 2}} ">
					<app-konfiguratorform *ngIf="tresci.user.dostawa_aktywne === false" [Kroki]="tresci.zamowienie.stopka"></app-konfiguratorform>
				</div>

				<div *ngIf="false" class="pole_menu_poziome">
					<div class="zakladka_headers">
						<div class="zakladka_header zakladka_{{tresci.zakladka === 0}}" (click)="tresci.zakladka = 0;">
							<h1>
								<app-tlumacz [id]="'naglowek'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
							</h1>
						</div>
						<div class="zakladka_header zakladka_{{tresci.zakladka === 1}}" (click)="tresci.zakladka = 1;">
							<h1>
								<app-tlumacz [id]="'dane_modułów'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
							</h1>
						</div>
						<div class="zakladka_header zakladka_{{tresci.zakladka === 2}}" (click)="tresci.zakladka = 2;">
							<h1>
								<app-tlumacz [id]="'dane_stopki'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
							</h1>
						</div>
					</div>
				</div>

				<div *ngFor="let modul of tresci.zamowienie.grupa_modul; let i = index">
					<div class="pozycja_edit" *ngIf="i == tresci.zamowienie.CurrentModule">
						<div *ngIf="modul.naglowekModul.Last">
							<div *ngFor="let pozycja of modul.pozycja; let j = index">
								<div *ngIf="pozycja.edytowana">
									<div class="pozycja_edit2">
										<div class="pozycja_tlo container">
											<div class="nag_pozycja">
												<h2>
													| <span>
														<app-tlumacz [id]="'pozycja_pola'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
													</span>
													<span class="red" *ngIf="tresci.zamowienie.IsModule">| <span>
															<app-tlumacz [id]="'Grupa'" [typ]="'zmienne'" [tresci]="tresci"></app-tlumacz>
														</span><span>&nbsp;{{i + 1}}</span></span>
													| <span>
														<app-tlumacz [id]="'Pozycja'" [typ]="'zmienne'" [tresci]="tresci"></app-tlumacz>
													</span><span>&nbsp;{{j + 1}}</span>
												</h2>
											</div>
											<app-konfiguratorform [Kroki]="pozycja.pozycja"></app-konfiguratorform>
											<!-- <div *ngIf="pozycja.pozycja.poprawny && pozycja.pozycja.Last" class="button" (click)="pozycja.edytowana = false; tresci.zmien_zapisz();"> -->
                        <div *ngIf="pozycja.pozycja.poprawny && pozycja.pozycja.Last" class="button" (click)="pozycja.edytowana = false; modul.ustawStopke();tresci.zmien_zapisz();">
                        <!-- " modul.zapiszPozycje();" > -->
												<app-tlumacz [id]="'Akceptuj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
											</div>
											<div class="loading" *ngIf="false && pozycja.pozycja.Last"></div>
											<div class="button" *ngIf="false" (click)="modul.usunPozycje(j)">
												<app-tlumacz [id]="'Usuń'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
											</div>
											<div *ngIf="false && !pozycja.pozycja.Last" class="button nieaktywny">
												<app-tlumacz [id]="'Przetwarzanie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
											</div>
											<div class="usun_pozycje" (click)="modul.usunPozycje(j)">
												<app-tlumacz [id]="'Usuń'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="zamow_osobno" *ngIf="!tresci.zamowienie.czyWczytuje() && (!tresci.zamowienie.IsFooter || tresci.zakladka === 2)">
			<div class="zamow" *ngIf="!tresci.zamowienie.trwa_zamawianie && tresci.zamowienie.czySkonczonyMod() && tresci.zamowienie.czyStopkaPelna() && this.tresci.zamowienie.czyPoprawny() && this.tresci.zamowienie.czySkonczone()" (click)="tresci.zakladka = 3">
				<app-tlumacz [id]="'Dalej'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
		</div>
		<div class="zamow_osobno" *ngIf="!tresci.zamowienie.czyWczytuje() && (!tresci.zamowienie.IsFooter || tresci.zakladka === 3)">
			<div class="zamow" *ngIf="!tresci.zamowienie.trwa_zamawianie && tresci.komponent == NewOrder && this.tresci.zamowienie.czyPoprawny() && this.tresci.zamowienie.czySkonczone()" (click)="this.tresci.zamowienie.zamow(false, ListOfSurcharges)">
				<app-tlumacz [id]="'zamow'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
			<div class="zamow" *ngIf="!tresci.zamowienie.trwa_zamawianie && tresci.komponent == Order && this.tresci.zamowienie.czyPoprawny() && this.tresci.zamowienie.czySkonczone()" (click)="this.tresci.zamowienie.zamow(true, ListOfSurcharges)">
				<app-tlumacz [id]="'zmień'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
			<div class="zamow" *ngIf="!tresci.zamowienie.trwa_zamawianie && tresci.komponent == OrderDesktop && this.tresci.zamowienie.czyPoprawny() && this.tresci.zamowienie.czySkonczone()" (click)="this.tresci.zamowienie.WpiszParametry()">
				<app-tlumacz [id]="'Zapisz Parametry'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
			<div class="zamow" *ngIf="!tresci.zamowienie.trwa_zamawianie && false && tresci.komponent == OrderDesktop && !this.tresci.zamowienie.czyPoprawny()" (click)="zamknij_okno()">
				<app-tlumacz [id]="'Wyjdź'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
			<div class="zamow" *ngIf="tresci.zamowienie.trwa_zamawianie">
				<app-tlumacz [id]="'Trwa przetwarzanie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
			<div class="zamow" *ngIf="tresci.zamowienie.IsModule && tresci.zakladka === 1 && tresci.zamowienie.czySkonczonyMod()" (click)="tresci.zamowienie.dodajModul()">
				<app-tlumacz [id]="'Dodaj następny moduł'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
		</div>
		<div class="zamow_osobno" *ngIf="!tresci.zamowienie.czyWczytuje() && (tresci.zamowienie.IsFooter && tresci.zakladka === 1)">
			<div class="zamow" *ngIf="tresci.zamowienie.czySkonczonyMod() && tresci.zamowienie.czyStopkaPelna()" (click)="tresci.zakladka = 2">
				<app-tlumacz [id]="'Dalej'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
			<div class="zamow" *ngIf="!tresci.zamowienie.czyStopkaPelna() && !tresci.zamowienie.trwa_zamawianie && tresci.komponent == NewOrder && this.tresci.zamowienie.czyPoprawny() && this.tresci.zamowienie.czySkonczone()" (click)="this.tresci.zamowienie.zamow(false, ListOfSurcharges)">
        <app-tlumacz [id]="'zamow'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
			<div class="zamow" *ngIf="!tresci.zamowienie.czyStopkaPelna() && !tresci.zamowienie.trwa_zamawianie && tresci.komponent == Order && this.tresci.zamowienie.czyPoprawny() && this.tresci.zamowienie.czySkonczone()" (click)="this.tresci.zamowienie.zamow(true, ListOfSurcharges)">
				<app-tlumacz [id]="'zmień'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
			<div class="zamow" *ngIf="!tresci.zamowienie.trwa_zamawianie && tresci.komponent == OrderDesktop && this.tresci.zamowienie.czyPoprawny() && this.tresci.zamowienie.czySkonczone()" (click)="this.tresci.zamowienie.WpiszParametry()">
				<app-tlumacz [id]="'Zapisz Parametry'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
			<div class="zamow" *ngIf="tresci.zamowienie.IsModule && tresci.zamowienie.czySkonczonyMod()" (click)="tresci.zamowienie.dodajModul()">
				<app-tlumacz [id]="'Dodaj następny moduł'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
			</div>
		</div>
		<div class="konfigurator_w" *ngIf="false">

		</div>
		<div class="prawa_strona">
			<div class="zwin" (click)="tresci.zwijanie = 'mini'">
			</div>
			<div class="wroc" (click)="tresci.zwijanie = ''">
				<div class="uwaga2" *ngIf="tresci.wizualizacja.length > 0 && tresci.id_krok_wizual === tresci.aktualne_id"></div>
			</div>
			<div class="opisy">
				<div class="nazwa">
					<h1>
						<app-tlumacz [id]="tresci.system" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
					</h1>
				</div>
				<div class="kroki_foto">
					<div class="pole">
						<span *ngIf="false">
							<div class="krok_numer" *ngFor="let krok of tresci.zamowienie.naglowek; let i = index">{{i + 1}}</div>
						</span>
					</div>
				</div>
				<div class="foto">
					<div *ngIf="tresci.wizualizacja != null &&  tresci.id_krok_wizual === tresci.aktualne_id">
						<div *ngFor="let foto of tresci.wizualizacja; let i = index" class="{{tresci.wizualizacja_id[i]}}">
							<img [src]="foto">
						</div>
					</div>
				</div>
				<div class="specyfikacje">
					<div class="cena">
						<div *ngIf="tresci.zamowienie.cenaSuma() > 0">
							<span>
								<app-tlumacz [id]="'cena'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
							</span>&nbsp;<span>{{tresci.zamowienie.cenaSuma()}}</span>
							<span class="cena_waluta">
								<app-tlumacz [id]="tresci.Currency" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
							</span>
						</div>
					</div>
					<div class="zamow" *ngIf="!tresci.zamowienie.trwa_zamawianie && tresci.komponent == NewOrder && tresci.zamowienie.czyPoprawny()" (click)="tresci.zamowienie.zamow(false, ListOfSurcharges)">
						<app-tlumacz [id]="'zamow'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
					</div>
					<app-tlumacz [id]="'zmień'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
				</div>
				<div class="zamow" *ngIf="!tresci.zamowienie.trwa_zamawianie && tresci.komponent == Order && tresci.zamowienie.czyPoprawny()" (click)="tresci.zamowienie.zamow(true, ListOfSurcharges)">

					<div class="zamow" *ngIf="!tresci.zamowienie.trwa_zamawianie && tresci.komponent == OrderDesktop && tresci.zamowienie.czyPoprawny()" (click)="tresci.zamowienie.WpiszParametry()">
						<app-tlumacz [id]="'Zapisz Parametry'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
					</div>
					<div class="zamow" *ngIf="!tresci.zamowienie.trwa_zamawianie && false && tresci.komponent == OrderDesktop && !tresci.zamowienie.czyPoprawny()" (click)="zamknij_okno()">
						<app-tlumacz [id]="'Wyjdź'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
					</div>
					<div class="zamow" *ngIf="tresci.zamowienie.trwa_zamawianie">
						<app-tlumacz [id]="'Trwa przetwarzanie'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz>
					</div>
				</div>
			</div>
		</div>
	</div>
