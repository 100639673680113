<div [hidden]="!TranslationLoaded|async">
  <div style="background-color: #cd0000;">
    <div class="d-flex">
      <h1 class="p-2 ml-auto mr-auto w-100 text-white">
        <span id="{{SystemName}}" [translation]="TranslationEnum.TranslationForm">{{SystemName|translate}}</span>
      </h1>
      <p class="pr-3 text-white ml-auto mt-auto" style="font-size: 12px;">F{{TechVersion}}/W{{ValuationVersion}}</p>
    </div>
  </div>
  <div *ngIf="CreatedForEditFormAndLoading || IsSaving"
    class="mt-5 d-flex justify-content-center align-items-center bg-white">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
  <div *ngIf="CreatedForEditFormAndLoading" class="text-center mt-5">
    <span id="ladowanie" [translation]="TranslationEnum.TranslationForm">
      {{'ladowanie'|translate}}
    </span>
    <span id="{{this.LoadingName}}" [translation]="TranslationEnum.TranslationForm">
      {{this.LoadingName|translate}}
    </span>
    <span>
      {{this.SetNumber}}
    </span>
  </div>
  <div [hidden]="CreatedForEditFormAndLoading || IsSaving" class="container" style="position: relative;">
    <div id="alter" class="scroll mt-3 ">
      <ngb-alert *ngIf="alertMessage" (close)="alertMessage = ''" type="success">{{ alertMessage }}</ngb-alert>
    </div>
    <button class="float-right " style="position: sticky; top: 118px; z-index: 999; padding-top: 10px;" mat-icon-button color="primary" (click)="returnToOrders()">
      <mat-icon>disabled_by_default</mat-icon>
    </button>
    <mat-tab-group mat-align-tabs="start" dynamicHeight [selectedIndex]="MainSelected.value"
      (selectedIndexChange)="moveToSets($event)" animationDuration="0ms">
      <mat-tab [disabled]="StepIsInEdit || BlockTabsAndSaveForm">
        <ng-template mat-tab-label>
          <span id="naglowek" [translation]="TranslationEnum.TranslationForm">
            {{'naglowek'|translate}}
          </span>
        </ng-template>
        <app-delivery [Preview]="Preview"></app-delivery>
        <div [hidden]="this.formService.DeliveryMethod===null">
          <template #headerForm></template>
        </div>

        <div *ngIf="IsLoading && this.formService.DeliveryMethod !== null"
          class="d-flex justify-content-center align-items-center bg-white">
          <mat-progress-spinner color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </div>
        <div class="p-3 text-right" *ngIf="DataSource.data.length > 0 && this.formService.DeliveryMethod !== null">
          <button mat-flat-button [hidden]="this.EditHeader || StepIsInEdit" color="primary" (click)="moveToSetsByButton()">
            <span id='dalej' [translation]="TranslationEnum.TranslationForm">
              {{'dalej' | translate}}
            </span>
             <mat-icon class="ml-1">forward</mat-icon>
          </button>
        </div>
      </mat-tab>
      <mat-tab [disabled]="StopByErrorSettingValue || this.EditHeader || StepIsInEdit || BlockTabsAndSaveForm">
        <ng-template mat-tab-label>
          <span id="dane_modułów" [translation]="TranslationEnum.TranslationForm">
            {{'dane_modułów' | translate}}
          </span>
          <!-- <mat-icon [hidden]='!ErrorForSets' class="mr-2">error_outline</mat-icon> -->
        </ng-template>
        <div class="mt-3 mb-3 w-100" *ngIf='!ManageModule.IsModule'>
          <button class="activeAnimation verify-button" *ngIf="ShowVerifyMessage" mat-flat-button [disabled]='Preview || StepIsInEdit'
            id="weryfikuj_zestaw" (click)="verifySet()" [translation]="TranslationEnum.TranslationForm">
            {{'weryfikuj_zestaw' | translate}}
          </button>
        </div>

        <mat-tab-group class="mb-3 mt-3" *ngIf="ManageModule.IsModule" [selectedIndex]="this.ModuleSelected.value"
          (selectedIndexChange)="loadModuleSetPositions($event)">
          <mat-tab [disabled]="StepIsInEdit || BlockTabsAndSaveForm" *ngFor="let tab of tabs; let index = index">
            <ng-template mat-tab-label>
              <mat-icon [hidden]='!tab.isError' class="mr-2 activeAnimation">error_outline</mat-icon>
              <span id='zestaw' [translation]="TranslationEnum.TranslationForm">{{'zestaw' | translate}} </span>
              <span class="ml-1"> {{index+1}}</span>
            </ng-template>
          </mat-tab>
          <mat-tab disabled>
            <ng-template mat-tab-label>
              <button mat-icon-button [disabled]="!ShowAddNewModule || Preview || StepIsInEdit" color="accent" (click)="addTab()" >
                <mat-icon>add_circle_outline</mat-icon>
              </button>
            </ng-template>
          </mat-tab>
        </mat-tab-group>

        <div class="mb-3 w-100" *ngIf='ManageModule.IsModule'>
          <button mat-flat-button class="mr-2" color="primary" [disabled]='Preview || StepIsInEdit' (click)="deleteSetMessageDialog()"
            id="usun_zestaw" [translation]="TranslationEnum.TranslationForm">
            {{'usun_zestaw' | translate}}
          </button>
          <button class="activeAnimation verify-button mr-2" [hidden]="!tabs[ModuleSelected.value].isError" mat-flat-button [disabled]='Preview || StepIsInEdit'
            id="weryfikuj_zestaw" (click)="verifySet()" [translation]="TranslationEnum.TranslationForm">
            {{'weryfikuj_zestaw' | translate}}
          </button>
          <button class="activeAnimation verify-button" [hidden]="!tabs[ModuleSelected.value].isError" mat-flat-button [disabled]='Preview || StepIsInEdit'
            id="weryfikuj_wszystkie_zestawy" (click)="verifySets()" [translation]="TranslationEnum.TranslationForm">
            {{'weryfikuj_wszystkie_zestawy' | translate}}
          </button>
           <button color="primary" *ngIf="ShowAddNewModule && !tabs[ModuleSelected.value].isError" mat-flat-button [disabled]='Preview || StepIsInEdit'
            id="kopiuj_zestaw" onclick="document.body.style.cursor = 'wait'" (click)="copySet()" [translation]="TranslationEnum.TranslationForm">
            {{'kopiuj_zestaw' | translate}}
          </button>
        </div>

        <div *ngIf="ShowSpinnerVerify"
          class="mt-5 justify-content-center align-items-center overlay-spinner">
          <mat-progress-spinner color="primary" mode="indeterminate">
          </mat-progress-spinner>

          <span *ngIf="ShowSpinnerVerify" id="weryfikacja" [translation]="TranslationEnum.TranslationForm">
            {{'weryfikacja'|translate}}
          </span>
        </div>
          <template #moduleHeaderForm></template>
          <ng-container *ngIf="DataSource.data.length > 0">
          <div class="w-100 overflow-auto possitions">
            <table [hidden]="HiddenTableOfPositions" mat-table [dataSource]="DataSource" class="w-100 mt-5">
              <ng-container *ngFor="let column of DynamicColumns">
                <ng-container matColumnDef="{{column}}">
                  <th mat-header-cell *matHeaderCellDef id="label_t_{{column}}"
                    [translation]="TranslationEnum.TranslationForm">
                    {{('label_t_' + column)|translate}}
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                </ng-container>
              </ng-container>

              <ng-container  matColumnDef='edit'>
                <th mat-header-cell *matHeaderCellDef id="edytuj" [translation]="TranslationEnum.TranslationForm">
                  {{'edytuj'|translate}}
                </th>
                <td mat-cell [ngClass]="{'background-icon': FinishPosition === false && Preview === false}" *matCellDef="let row">
                  <button mat-icon-button [ngClass]="{'color-icon': (FinishPosition === false && Preview === false)}"  color="accent" [disabled]='FinishPosition || Preview'
                    (click)="editPosition(row)">
                    <mat-icon>create</mat-icon>
                  </button>
              </ng-container>
              <ng-container  matColumnDef='delete'>
                <th mat-header-cell *matHeaderCellDef id="usun" [translation]="TranslationEnum.TranslationForm">
                  {{'usun'|translate}}
                </th>
                <td mat-cell [ngClass]="{'background-icon': (FinishPosition === false && Preview === false)}"  *matCellDef="let row">
                  <button mat-icon-button [ngClass]="{'color-icon': (FinishPosition === false && Preview === false)}" [disabled]='FinishPosition || Preview' (click)='deletePosition(row)' color="accent">
                    <mat-icon >delete_outline</mat-icon>
                  </button>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="['edit', 'delete'].concat(DisplayedColumns)"></tr>
              <tr mat-row *matRowDef="let row; columns:['edit', 'delete'].concat(DisplayedColumns);"></tr>
            </table>
          </div>
          <div class="text-left mt-3">
            <button mat-flat-button color="primary"
              *ngIf="FinishPosition === false && IsAddNewPosition && IsLastRight === false" class="mr-3"
              (click)="openPositionDialog()" id="dodaj_pozycje" [translation]="TranslationEnum.TranslationForm"
              [disabled]="Preview || StepIsInEdit">
              {{'dodaj_pozycje'|translate}}
            </button>
            <button mat-flat-button color="primary" *ngIf='IsLastRight || ShowEndPosition' class="mr-3"
              (click)="manageFooterAndFinishPosition()" id="{{ManageAddingPositionText}}"
              [translation]="TranslationEnum.TranslationForm" [disabled]="Preview || StepIsInEdit">
              {{ManageAddingPositionText|translate}}
            </button>
            <button mat-flat-button color="primary" class="mr-3" *ngIf="ShowAddNewModule && !ManageModule.IsFooter" (click)="addTab()"
              id="dodaj_zestaw" [translation]="TranslationEnum.TranslationForm" [disabled]="Preview || StepIsInEdit">
              {{'dodaj_zestaw'|translate}}
            </button>
            <button mat-flat-button color="primary" *ngIf="ShowMoveToFooter && FinishPosition && !ManageModule.IsModule"
              (click)="moveToFooter()" id="dalej" [translation]="TranslationEnum.TranslationForm" [disabled]="Preview || StepIsInEdit">
              {{'dalej'|translate}}
            </button>
            <!-- <div class="text-center mt-3 mb-3">
              <button *ngIf="!ManageModule.IsFooter && (EndForm && !ErrorForSets)" mat-flat-button color="primary" (click)="save()"
                id="zapisz_zamowienie" [translation]="TranslationEnum.TranslationForm" [disabled]="Preview || StepIsInEdit">
                {{'zapisz_zamowienie'|translate}}
              </button>
            </div> -->
          </div>
          </ng-container>
          <template #moduleFooterForm></template>

          <div class="d-flex p-3">
            <div class="ml-auto mr-auto">
            <button mat-flat-button color="primary" class="mr-3" *ngIf="ShowAddNewModule && ManageModule.IsFooter" (click)="addTab()"
                id="dodaj_zestaw" [translation]="TranslationEnum.TranslationForm" [disabled]="Preview || StepIsInEdit">
                {{'dodaj_zestaw'|translate}}
            </button>
            <button *ngIf="ShowAddNewModule && !ErrorForSets" mat-flat-button color="primary" class="mr-2" (click)="quickSave()"
              id="zapisz_tymczasowo" [translation]="TranslationEnum.TranslationForm" [disabled]="Preview || StepIsInEdit">
              {{'zapisz_tymczasowo'|translate}}</button>
              <button *ngIf="!ManageModule.IsFooter && (EndForm && !ErrorForSets)" mat-flat-button color="primary" (click)="save()"
                id="zapisz_zamowienie" [translation]="TranslationEnum.TranslationForm" [disabled]="Preview || StepIsInEdit">
                {{'zapisz_zamowienie'|translate}}
              </button>
            <!-- <button color="primary"  mat-flat-button *ngIf="ShowAddNewModule && !tabs[ModuleSelected.value].isError" [disabled]='Preview || StepIsInEdit'
            id="kopiuj_zestaw" onclick="document.body.style.cursor = 'wait'" (click)="copySet()" [translation]="TranslationEnum.TranslationForm">
            {{'kopiuj_zestaw' | translate}}
          </button> -->
            </div>
            <div>

              <button mat-flat-button color="primary" *ngIf="ShowAddNewModule && ManageModule.IsFooter" (click)="moveToFooter()"
                id="dalej" [translation]="TranslationEnum.TranslationForm" [disabled]="Preview || StepIsInEdit">
                {{'dalej'|translate}}
               <mat-icon class="ml-1">forward</mat-icon>
              </button>
            </div>
          </div>
      </mat-tab>

      <mat-tab [disabled]="StepIsInEdit || BlockTabsAndSaveForm" *ngIf='ManageModule.IsFooter'>
        <ng-template mat-tab-label>
          <span id="dane_stopki" [translation]="TranslationEnum.TranslationForm">
            {{'dane_stopki' | translate}}
          </span>
        </ng-template>

        <template #footerForm></template>
        <div class="text-center mt-3 mb-3">
          <button *ngIf="EndForm && !ErrorForSets" mat-flat-button color="primary" (click)="save()"
            id="zapisz_zamowienie" [translation]="TranslationEnum.TranslationForm" [disabled]="Preview || StepIsInEdit">
            {{'zapisz_zamowienie'|translate}}</button>
        </div>
      </mat-tab>
      <!-- <mat-tab [disabled]="StepIsInEdit || BlockTabsAndSaveForm" *ngIf="this.User.IsTrader">
        <ng-template mat-tab-label>
          <span id="modul_doplaty" [translation]="TranslationEnum.TranslationForm">
            {{'modul_doplaty'|translate}}
          </span>
        </ng-template>
        <app-surcharges [Preview]="Preview"></app-surcharges>
        <div class="p-3" *ngIf="SetsForVerificationLength > 0">
          <span id="do_weryfikacji_pozostalo"
            [translation]="TranslationEnum.TranslationForm">{{'do_weryfikacji_pozostalo' | translate}}</span>
            {{this.SetsForVerificationLength}}/{{this.tabs.length}}
        </div>
        <div class="text-center mt-3 mb-3" *ngIf="this.User.IsTrader">
          <button *ngIf="EndForm && !ErrorForSets" mat-flat-button color="primary" (click)="save()" id="zapisz_zamowienie"
            [translation]="TranslationEnum.TranslationForm" [disabled]="Preview || StepIsInEdit || BlockTabsAndSaveForm">
            {{'zapisz_zamowienie'|translate}}</button>
        </div>
      </mat-tab> -->
    </mat-tab-group>
  </div>
  <app-technologiaresultinfo #technology [ResultInfo]="this.resultInfo"></app-technologiaresultinfo>
</div>
