import { ManagePositions } from './../shared/models/form/manage-positions.model';
import { MatTableDataSource } from '@angular/material/table';
import { Zamowienie } from './../pomocnicze/zamowienie';
import { TresciService } from './../tresci.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { deserialize, serialize } from 'class-transformer';
import { TechnologiaResultInfoComponent } from '../technologiaResultInfo/technologiaResultInfo.component';
import { type } from 'os';
import { ISurchargeType } from '../pomocnicze/Interfaces/ISurchargeType';
import { ISurcharge } from '../pomocnicze/Interfaces/ISurcharge';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-konfigurator',
  templateUrl: './konfigurator.component.html',
  styleUrls: ['./konfigurator.component.scss'],
})

export class KonfiguratorComponent implements OnInit, OnDestroy {
  public NewOrder = 'NewOrder';
  public OrderDesktop = 'OrderDesktop';
  public Order = 'Order';
  public pusty = '';
  public isPreviewTech = false;
  public SelectSurcharge: ISurchargeType = { Id: '', Name: '', NameDe: '', NameEn: '', NameFr: '', Type: null };
  public Surcharge: ISurcharge = { Name: '', NameDe: '', NameEn: '', NameFr: '', SurchargeId: '', Price: '', SurchargeType: null };
  public ListOfSurcharges: ISurcharge[] = [];
  public IsAfterPositions: boolean;
  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  private subscriptions: Subscription[] = [];

  @ViewChild(TechnologiaResultInfoComponent, {static: true}) technology;

  constructor(public tresci: TresciService, public user: UserService, private route: ActivatedRoute) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit() {
    this.tresci.setJezyk(this.route.snapshot.paramMap.get('jezyk'));
    this.tresci.setFunkcja(this.route.snapshot.paramMap.get('system'));
    this.tresci.setId(this.route.snapshot.paramMap.get('id'));
    this.tresci.zamowienie = new Zamowienie(this.tresci);
    this.tresci.user.getSurcharges();

    this.subscriptions.push(this.tresci.$IsAfterPositions.subscribe( x => {
      this.IsAfterPositions = x;
    }));

    if (this.tresci.id == null || this.tresci.komponent === 'OrderDesktop') {

      if (this.tresci.odczytaj('zamowneinie_' + this.tresci.system) === ''
        || this.tresci.odczytaj('zamowneinie_' + this.tresci.system) === null) {
        setTimeout(() => this.tresci.zamowienie.naglowek.wczytajKrok(0), 5000);
      } else {
        this.tresci.zamowienie.wczytajZamowienieZLoc();
        this.tresci.user.dostawa_aktywne = false;
      }
    } else {
      this.tresci.zamowienie.wczytajZamowienie(this.tresci.id);
    }
    if (this.tresci.komponent === 'OrderDesktop') {
      this.tresci.user.dostawa_aktywne = false;
    }
    this.wczytaj();
  }

  public wczytaj() {

    setTimeout(() => {
      if (this.tresci.user.ListOfSurchargeType !== null && this.tresci.user.ListOfSurchargeType.length !== 0) {
        this.SelectSurcharge = this.tresci.user.ListOfSurchargeType[0];
      }
      if (this.tresci.zamowienie.ListOfSurcharge && this.tresci.zamowienie.ListOfSurcharge.length !== 0) {
        for (let index = 0; index < this.tresci.zamowienie.ListOfSurcharge.length; index++) {
          const element = this.tresci.zamowienie.ListOfSurcharge[index];
          this.ListOfSurcharges.push(element);
        }
      }
    }, 800);
  }

  public zamknij_okno() {
    window.close();
  }
  public wyczysc() {
    if (this.tresci.zamowienie != null) {
      this.tresci.zamowienie.wyczysc_Parametry();
    }
    window.location.reload();
  }
  public przywroc() {
    if (this.tresci.zamowienie != null) {
      this.tresci.zamowienie.przywroc_parametry();
    }
  }
  public zmien_zakladke() {
    if (this.tresci.zakladka === 0) {
      this.tresci.zakladka = 1;
    } else {
      this.tresci.zakladka = 0;
    }
    this.tresci.zmien_zapisz();
  }
  public wczytaj_formularz() {

  }
  public czyPozycja(modul) {
    if (modul.pozycja.length === 0) {
      modul.dodajPozycje();
    }
    return true;
  }

  public showTechnology() {
    this.isPreviewTech = true;
    this.technology.showTechnology(0);
  }
  public dalej_dostawa() {
    if (!this.tresci.user.dostawa.DeliveryMethods[this.tresci.user.dostawa_id].IsDeliveryAddress) {
      this.tresci.user.dostawa_aktywne = false;
    } else if (this.tresci.user.dostawa.DeliveryMethods[this.tresci.user.dostawa_id].IsDeliveryAddress &&
      this.tresci.user.dostawa.ContractorAdresses.length > 0) {
      this.tresci.user.dostawa_aktywne = false;
    } else {
      this.tresci.exception.pokaz_message('Uzupełnij dane dostawy', 'Uzupełnij dane dostawy');
      this.tresci.exception.exception(
        'Uzupełnij dane dostawy', ''
      );
    }
  }

  addSurcharge() {
    this.Surcharge.SurchargeId = this.SelectSurcharge.Id;
    this.Surcharge.Name = this.SelectSurcharge.Name;
    this.Surcharge.NameDe = this.SelectSurcharge.NameDe;
    this.Surcharge.NameEn = this.SelectSurcharge.NameEn;
    this.Surcharge.NameFr = this.SelectSurcharge.NameFr;
    this.Surcharge.SurchargeType = this.SelectSurcharge.Type;
    this.ListOfSurcharges.push(this.Surcharge);
    this.Surcharge = {
      Name: '',
      NameDe: '',
      NameEn: '',
      NameFr: '',
      SurchargeId: '',
      Price: '',
      SurchargeType: null
    };
  }

  isTraderForShipping(value) {
    if (value.Id === 4 && !this.tresci.user.IsTrader) {
      return false;
    }
    return true;
  }

  deleteSurcharge(surcharge: ISurcharge) {
    const index = this.ListOfSurcharges.indexOf(surcharge);
    this.ListOfSurcharges.splice(index, 1);
  }
}
